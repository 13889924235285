import { Action } from "@ngrx/store";
import { SalonReport, EmployeeReport, ServiceReport, LongitudinalReport } from "../../kernel/models";

export enum Types {
  INITIALIZE_AND_LOAD_DASHBOARD_REPORTS = "[Analytics] Initialize And Load Dashboard Reports",
  LOAD_DASHBOARD_REPORTS = "[Analytics] Load Dashboard Reports",
  LOAD_DASHBOARD_REPORTS_SUCCESS = "[Analytics] Load Dashboard Reports Success",
  LOAD_DASHBOARD_REPORTS_FAIL = "[Analytics] Load Dashboard Reports Fail",
  UPDATE_DASHBOARD_DATE_RANGE = "[Analytics] Update Dashboard Date Range",
  LOAD_CURRENT_SUCCESS = "[SalonReport] Load Current Success",
  LOAD_EMPLOYEE_REPORTS_SUCCESS = "[Employee Report] Load All Employee Reports Success",
  LOAD_SERVICE_REPORTS_SUCCESS = "[Service Report] Load All Service Report Success",
  LOAD_LONGITUDINAL_REPORT_SUCCESS = "[Longitudinal Report] Load One Success",
  UPDATE_DATE_RANGE = "[Analytics] Update Date Range",
  INITIALIZE_AND_LOAD_EMPLOYEE_REPORTS = "[Analytics] Initialize And Load Employee Report",
  LOAD_EMPLOYEE_REPORTS = "[Analytics] Load Employee Reports",
  LOAD_EMPLOYEE_REPORT_SUCCESS = "[Analytics] Load Employee Report Success",
  CHANGE_AGGREGATION = "[Analytics] Change Aggregation",
  LOAD_PRODUCT_REPORTS = "[Analytics] Load Product Reports",
  LOAD_PRODUCT_REPORTS_SUCCESS = "[Analytics] Load Product Reports Success",
  LOAD_PRODUCT_REPORTS_FAIL = "[Analytics] Load Product Reports Fail",
  DOWNLOAD_EMPLOYEE_PERFORMANCE_REPORT = "[Analytics] Download Employee Performance Report",
  DOWNLOAD_SERVICE_REPORT = "[Analytics] Download Service Report",
  DOWNLOAD_SERVICES_SUMMARY_REPORT = "[Analytics] Download Services Summary Report",
}

export class InitializeAndLoadDashboardReports implements Action {
  public readonly type = Types.INITIALIZE_AND_LOAD_DASHBOARD_REPORTS;

  constructor(public payload: { aggregatedBy: string }) {}
}

export class LoadDashboardReports implements Action {
  public readonly type = Types.LOAD_DASHBOARD_REPORTS;

  constructor(public payload: { startDate: number; endDate: number; aggregatedBy: string }) {}
}

export class LoadDashboardReportsSuccess implements Action {
  public readonly type = Types.LOAD_DASHBOARD_REPORTS_SUCCESS;
}

export class LoadDashboardReportsFail implements Action {
  public readonly type = Types.LOAD_DASHBOARD_REPORTS_FAIL;

  constructor(public payload: { error: string }) {}
}

export class UpdateDashboardDateRange implements Action {
  public readonly type = Types.UPDATE_DASHBOARD_DATE_RANGE;

  constructor(public payload: { startDate: number; endDate: number }) {}
}

export class LoadCurrentSuccess implements Action {
  public readonly type = Types.LOAD_CURRENT_SUCCESS;

  constructor(public payload: SalonReport) {}
}

export class LoadEmployeeReportsSuccess implements Action {
  public readonly type = Types.LOAD_EMPLOYEE_REPORTS_SUCCESS;

  constructor(public payload: EmployeeReport[]) {}
}

export class LoadServiceReportsSuccess implements Action {
  public readonly type = Types.LOAD_SERVICE_REPORTS_SUCCESS;

  constructor(public payload: ServiceReport[]) {}
}

export class LoadLongitudinalReportSuccess implements Action {
  public readonly type = Types.LOAD_LONGITUDINAL_REPORT_SUCCESS;

  constructor(public entity: LongitudinalReport) {}
}

export class UpdateDateRange implements Action {
  public readonly type = Types.UPDATE_DATE_RANGE;

  constructor(public payload: { startDate: number; endDate: number }) {}
}

export class LoadProductReports implements Action {
  public readonly type = Types.LOAD_PRODUCT_REPORTS;

  constructor(public payload: { startDate: number; endDate: number }) {}
}

export class InitializeAndLoadEmployeeReports implements Action {
  public readonly type = Types.INITIALIZE_AND_LOAD_EMPLOYEE_REPORTS;

  constructor(public payload: { employeeId: string; aggregatedBy: string }) {}
}

export class LoadEmployeeReports implements Action {
  public readonly type = Types.LOAD_EMPLOYEE_REPORTS;

  constructor(public payload: { employeeId: string; startDate: number; endDate: number; aggregatedBy: string }) {}
}

export class LoadEmployeeReportSuccess implements Action {
  public readonly type = Types.LOAD_EMPLOYEE_REPORT_SUCCESS;

  constructor(public payload: EmployeeReport) {}
}

export class ChangeAggregation implements Action {
  public readonly type = Types.CHANGE_AGGREGATION;

  constructor(public payload: { aggregatedBy: string }) {}
}

export class DownloadEmployeePerformanceReport implements Action {
  public readonly type = Types.DOWNLOAD_EMPLOYEE_PERFORMANCE_REPORT;
}

export class DownloadServiceReport implements Action {
  public readonly type = Types.DOWNLOAD_SERVICE_REPORT;

  constructor(public payload?: { showExcludedServices: boolean }) {}
}

export class DownloadServicesSummaryReport implements Action {
  public readonly type = Types.DOWNLOAD_SERVICES_SUMMARY_REPORT;
}

export type Actions =
  | InitializeAndLoadDashboardReports
  | LoadDashboardReports
  | LoadDashboardReportsSuccess
  | LoadDashboardReportsFail
  | UpdateDashboardDateRange
  | LoadCurrentSuccess
  | LoadEmployeeReportsSuccess
  | LoadServiceReportsSuccess
  | LoadLongitudinalReportSuccess
  | UpdateDateRange
  | InitializeAndLoadEmployeeReports
  | LoadEmployeeReports
  | LoadEmployeeReportSuccess
  | ChangeAggregation;
