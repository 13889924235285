import { NgModule } from "@angular/core";
import { SharedModule } from "../../kernel/shared";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
  faArrowLeft,
  faCirclePlus,
  faCloudUploadAlt,
  faDroplet,
  faEllipsisV,
  faListRadio,
  faListTree,
} from "@fortawesome/pro-solid-svg-icons";

import { KernelServicesModule } from "../../kernel";
import { ManufacturersSharedModule } from "../+manufacturers";
import { ProductCategoriesSharedModule } from "../+product-categories";

import { ImportProductsDialogComponent, ProductReplacementFieldComponent, SelectProductsGridComponent } from "./components";
import { ProductsSharedModule } from "../+products/products-shared.module";
import { SalonProductsSharedModule } from "../+salon-products/salon-products-shared.module";
import { MatStepperModule } from "@angular/material/stepper";
import { ImportSalonProductEffects } from "./store/import-salon-products.effects";
import { featureKey as importSalonProductsKey, reducer as importSalonProductsReducer } from "./store/import-salon-products.reducer";
import { featureKey as productSelectionKey, reducer as productSelectionReducer } from "./store/product-selection.reducer";
import { MatChipsModule } from "@angular/material/chips";
import { ProductSelectionEffects } from "./store/product-selection.effects";
import { ProductSelectionDialogComponent } from "./components/product-selection-dialog";
import { ProductSelectionDialogService } from "./services/product-selection.service";
import { ConfirmDialogModule } from "app/kernel/shared/rocket-ui/+confirm-dialog/confirm-dialog.module";
import { DialogModule } from "app/+components/+dialog";

@NgModule({
  imports: [
    DialogModule,
    SharedModule,
    KernelServicesModule,
    ManufacturersSharedModule,
    ProductCategoriesSharedModule,
    ProductsSharedModule,
    SalonProductsSharedModule,
    StoreModule.forFeature(importSalonProductsKey, importSalonProductsReducer),
    StoreModule.forFeature(productSelectionKey, productSelectionReducer),
    EffectsModule.forFeature([ImportSalonProductEffects, ProductSelectionEffects]),
    MatStepperModule,
    MatChipsModule,
    ConfirmDialogModule,
  ],
  declarations: [
    ImportProductsDialogComponent,
    SelectProductsGridComponent,
    ProductSelectionDialogComponent,
    ProductReplacementFieldComponent,
  ],
  exports: [ProductReplacementFieldComponent],
  providers: [ProductSelectionDialogService],
})
export class ProductSelectionModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faArrowLeft, faEllipsisV, faCloudUploadAlt, faCirclePlus, faDroplet, faListTree, faListRadio);
  }
}
