<div class="search-panel">
  <div class="manufacturer-search">
    <search-input
      placeholder="Search Manufacturers..."
      cdkFocusInitial
      (filter)="searchedByName.emit($event)"></search-input>
  </div>
  <div>
    <ng-content select="[filters]"></ng-content>
  </div>
</div>
<div
  *ngIf="filteredManufacturers.length; else emptySearch"
  class="manufacturers-container">
  <div class="manufacturers-container-panel">
    <manufacturer-card
      *ngFor="let manufacturer of filteredManufacturers; trackBy: trackByFn"
      class="manufacturers-container-card"
      [manufacturer]="manufacturer"
      (clicked)="manufacturerSelected.emit(manufacturer)">
      <mat-chip
        footer
        *ngIf="options.showPricingIncomplete && manufacturer.isPricingIncomplete"
        class="label incomplete-pricing">
        Incomplete pricing
      </mat-chip>
      <mat-chip
        footer
        *ngIf="options.showNumProducts && manufacturer.numProductsImported"
        class="label incomplete-pricing">
        {{ manufacturer.numProductsImported }} Products Imported
      </mat-chip>
    </manufacturer-card>
  </div>
</div>
<ng-template #emptySearch>
  <div class="empty-state-wrapper">
    <empty-state-message
      title="No manufacturers found"
      icon="magnifying-glass">
      <ng-content select="[noFoundManufacturersHint]"></ng-content>
    </empty-state-message>
  </div>
</ng-template>
