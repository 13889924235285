import { createAction, union } from "@ngrx/store";

const actionPrefix = "[LogRocket]";
const name = (actionName: string) => `${actionPrefix} ${actionName}`;

export const init = createAction(name("Init"));

const all = union({
  init,
});

export type Actions = typeof all;
