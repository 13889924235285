import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { trackByIdFn } from "../../../../kernel/util";
import { ManufacturerVm } from "../../models/manufacturer-view.model";

@Component({
  selector: "manufacturers-list",
  templateUrl: "./manufacturers-list.component.html",
  styleUrls: ["./manufacturers-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturersListComponent {
  @Input()
  public filteredManufacturers: ManufacturerVm[];
  @Input() public options: {
    showPricingIncomplete: boolean;
    showNumProducts: boolean;
  } = {
    showNumProducts: false,
    showPricingIncomplete: true,
  };

  @Output()
  public searchedByName = new EventEmitter<string>(true);
  @Output()
  public manufacturerSelected = new EventEmitter<ManufacturerVm>(true);

  public trackByFn = trackByIdFn;
}
