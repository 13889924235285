<div class="tree-container">
  <div class="tree">
    <div
      class="node"
      *ngFor="let grouping of productGroupings; trackBy: trackByGroup">
      <div class="node-name">
        <span>
          <ng-container *ngIf="!grouping.category.isRoot && (grouping.category.rootCategory | isDefined)">
            {{ grouping.category.rootCategory.name }} >
          </ng-container>
          {{ grouping.category.name }}
        </span>
        <div class="node-controls">
          <ng-container *ngIf="!disableSelectAll">
            <button
              *ngIf="!grouping.alreadyImported && !grouping.allSelected"
              (click)="selectProducts.emit(grouping.products)"
              mat-stroked-button
              class="button outline secondary small">
              Select All
            </button>
            <button
              *ngIf="!grouping.alreadyImported && grouping.allSelected"
              (click)="deselectProducts.emit(grouping.products)"
              mat-stroked-button
              class="button outline secondary small">
              Deselect All
            </button>
          </ng-container>
        </div>
      </div>
      <div class="product-grid">
        <div
          class="product-item"
          matTooltip="Product already imported"
          [matTooltipDisabled]="!productVM.inactive"
          *ngFor="let productVM of grouping.products; trackBy: trackById"
          (click)="onToggleProduct(productVM)"
          [class.disabled]="productVM.inactive">
          <mat-checkbox
            [checked]="productVM.selected"
            [disabled]="productVM.inactive"></mat-checkbox>
          <colored-dot
            [color]="productVM.product.hexColorCode"
            size="large"></colored-dot>
          <span class="product-name">
            {{ productVM.product.name }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="productGroupings | isEmpty">
    <empty-state-message
      title="No products found"
      icon="magnifying-glass">
    </empty-state-message>
  </div>
</div>
