import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit, OnDestroy } from "@angular/core";
import { MeasurementUnit, SalonProductModule } from "@getvish/model";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { CategoryPricing } from "../common";
import { Subscription } from "rxjs";
import { roundToTwoDecimals } from "app/kernel/util";

@Component({
  selector: "set-category-pricing-component",
  templateUrl: "set-category-pricing.component.html",
  styleUrls: ["set-category-pricing.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetCategoryPricingComponent implements OnInit, OnDestroy {
  @Input() public categoryId: string;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public currency: string;
  @Output() public close: EventEmitter<void>;
  @Output() public save: EventEmitter<CategoryPricing>;

  public form: UntypedFormGroup;

  private _wholesaleSubscription: Subscription;
  private _markupSubscription: Subscription;
  private _retailSubscription: Subscription;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
    this.close = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this._createForm();
  }

  public ngOnDestroy(): void {
    if (this._wholesaleSubscription != null) {
      this._wholesaleSubscription.unsubscribe();
    }

    if (this._markupSubscription != null) {
      this._markupSubscription.unsubscribe();
    }

    if (this._retailSubscription != null) {
      this._retailSubscription.unsubscribe();
    }
  }

  public saveForm(data, categoryId: string): void {
    if (this.form.valid) {
      const updated: CategoryPricing = { categoryId, ...data };
      updated.markup = data.markupPercent != null ? data.markupPercent / 100 : updated.markup;
      delete (updated as any).markupPercent;

      // because the container size will be in the local unit of measurement for this user
      // we need to convert it back to the base unit that the server will understand before saving it
      // this probably isn't my favorite place to do this but it's the best I've got right now?
      const containerSizeConvertedToBase = this.measurementUnit.convertToBase(updated.containerSize);

      const updatedWithBaseMeasurementUnit = {
        ...updated,
        containerSize: containerSizeConvertedToBase,
      };

      this.save.emit(updatedWithBaseMeasurementUnit);
    }
  }

  private _createForm(): void {
    this.form = this._fb.group({
      wholesalePrice: [null, Validators.required],
      markupPercent: [null, Validators.required],
      retailPrice: [null, Validators.required],
      containerSize: [null, Validators.required],
    });

    this._wholesaleSubscription = this.form.controls["wholesalePrice"].valueChanges.subscribe((wholesalePrice) => {
      this.form.patchValue(
        {
          retailPrice: roundToTwoDecimals(
            SalonProductModule.getRetailPrice({
              ...this.form.value,
              markup: this.form.value.markupPercent != null ? this.form.value.markupPercent / 100 : undefined,
              wholesalePrice,
            })
          ),
        },
        { emitEvent: false }
      );
    });

    this._markupSubscription = this.form.controls["markupPercent"].valueChanges.subscribe((markupPercent) => {
      this.form.patchValue(
        {
          retailPrice: roundToTwoDecimals(
            SalonProductModule.getRetailPrice({ ...this.form.value, markup: markupPercent != null ? markupPercent / 100 : undefined })
          ),
        },
        { emitEvent: false }
      );
    });

    this._retailSubscription = this.form.controls["retailPrice"].valueChanges.subscribe((retailPrice) => {
      const markupPercent =
        retailPrice > 0 && this.form.value.wholesalePrice > 0
          ? ((retailPrice - this.form.value.wholesalePrice) / this.form.value.wholesalePrice) * 100
          : 0;

      this.form.patchValue({ markupPercent: roundToTwoDecimals(markupPercent) }, { emitEvent: false });
    });
  }
}
