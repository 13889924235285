import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Manufacturer, Product } from "@getvish/model";
import { Subject } from "rxjs";
import { ProductSelectionDialogService } from "../../services/product-selection.service";

export interface ProductReplacement {
  products: Array<ProductSelection>;
}

export interface ProductSelection extends Product {
  manufacturer: Manufacturer;
  ratio: number;
}

@Component({
  selector: "product-replacement",
  templateUrl: "product-replacement-field.component.html",
  styleUrls: ["product-replacement-field.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: ProductReplacementFieldComponent }],
})
export class ProductReplacementFieldComponent
  implements AfterViewInit, OnDestroy, MatFormFieldControl<Array<ProductReplacement>>, ControlValueAccessor
{
  private static nextId = 0;

  private _disabled = false;
  private _focused = false;
  private _touched = false;
  private _placeholder = "";
  private _required = false;
  private _value: Array<ProductReplacement>;

  stateChanges = new Subject<void>();

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  get value(): Array<ProductReplacement> {
    return this._value;
  }
  set value(value: Array<ProductReplacement>) {
    this._value = value;
    this.stateChanges.next();
    this.onChange(value);
  }

  @Output()
  public select: EventEmitter<Location>;

  @HostBinding("attr.aria-describedby")
  describedBy = "";

  @HostBinding()
  id = `product-replacement-${ProductReplacementFieldComponent.nextId++}`;

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @ViewChild("ps")
  eleRef: ElementRef;

  controlType = "product-replacement";

  get empty() {
    return false;
  }

  get errorState(): boolean {
    return this._touched && !this._focused && this.ngControl.control != null ? !this.ngControl.control.valid : false;
  }

  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(" ");
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== "input") {
      this.focusMonitor.focusVia(this.eleRef.nativeElement, "mouse");
    }
  }

  writeValue(value: Array<ProductReplacement> | null): void {
    this.value = value;
  }

  onChange(_: Array<ProductReplacement>): void {}

  registerOnChange(onChange: (value: Array<ProductReplacement> | null) => void): void {
    this.onChange = onChange;
  }

  onTouched(): void {}

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  constructor(
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private _cd: ChangeDetectorRef,
    private _productSelectionDlgSvc: ProductSelectionDialogService
  ) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.select = new EventEmitter(true);
  }

  public ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe((focusOrigin) => {
      this.focused = focusOrigin != null;
      this._touched = true;
    });
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  public getIngredientTypeText(product: ProductSelection) {
    return [product.manufacturer?.name, product.category?.name].filter((s) => s != null).join(" - ");
  }

  public addReplacement(): void {
    this.addProducts();
  }

  public addProducts(replacmentIdx?: number): void {
    this._productSelectionDlgSvc
      .show({
        saveButtonText: "Add Products",
        mode: "ALL",
        type: "PRODUCTS",
      })
      .subscribe((result) => {
        if (result?.salonProducts != null) {
          this.value = [...(this.value ?? [])];

          const products = [
            ...(this.value[replacmentIdx]?.products ?? []),
            ...result.salonProducts
              .filter((product) => !(this.value[replacmentIdx]?.products ?? []).some((p) => p._id === product._id))
              .map((product) => ({ ...product, manufacturer: result.manufacturer, ratio: 1 })),
          ];

          if (replacmentIdx != null) {
            this.value[replacmentIdx] = { ...this.value[replacmentIdx] };
            this.value[replacmentIdx].products = products;
          } else {
            this.value.push({ products });
          }
        }
        this.value = this.value.filter((p) => p.products.length > 0);
        this._cd.markForCheck();
      });
  }

  public deleteProduct(replacmentIdx: number, product: ProductSelection): void {
    this.value = [...this.value];
    this.value[replacmentIdx] = { ...this.value[replacmentIdx] };
    this.value[replacmentIdx].products = this.value[replacmentIdx].products.filter((p) => p._id !== product._id);

    if (this.value[replacmentIdx].products.length === 0) {
      this.value.splice(replacmentIdx, 1);
    }

    this._cd.markForCheck();
  }

  public productRatioChanged(replacmentIdx: number, product: ProductSelection, event) {
    this.value = [...this.value];
    this.value[replacmentIdx] = { ...this.value[replacmentIdx] };
    this.value[replacmentIdx].products = this.value[replacmentIdx].products.map((p) =>
      p._id === product._id ? { ...p, ratio: Number(event.target.value) } : p
    );

    this._cd.markForCheck();
  }

  public replacementTrackBy(idx: number) {
    return idx;
  }

  public productSelectionTrackBy(idx: number, productSelection: ProductSelection) {
    return productSelection._id;
  }
}
