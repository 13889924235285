import { createAction, props } from "@ngrx/store";
import { DiscontinuedProduct, Product } from "@getvish/model";
import { ProductReplacement } from "app/+product/+product-selection/components";

export const init = createAction("[Discontinue Product] Init", props<{ productId: string }>());
export const initFail = createAction("[Discontinue Product] Init Fail", props<{ error: Error }>());
export const initSuccess = createAction(
  "[Discontinue Product] Init Success",
  props<{ product: Product; discontinuedProduct?: DiscontinuedProduct; replacements?: ProductReplacement[] }>()
);
export const navigate = createAction("[Discontinue Product] Navigate", props<{ product: Product }>());
export const discontinue = createAction(
  "[Discontinue Product] Discontinue",
  props<{ product: Product; date: Date; replacements: ProductReplacement[]; discontinuedProduct?: DiscontinuedProduct }>()
);
export const discontinueFail = createAction("[Discontinue Product] Discontinue Fail", props<{ error: Error }>());
export const discontinueSuccess = createAction(
  "[Discontinue Product] Discontinue Success",
  props<{ discontinuedProduct: DiscontinuedProduct }>()
);
export const deleteDiscontinue = createAction("[Discontinue Product] Delete", props<{ discontinuedProduct: DiscontinuedProduct }>());
export const deleteDiscontinueFail = createAction("[Discontinue Product] Delete Fail", props<{ error: Error }>());
export const deleteDiscontinueSuccess = createAction("[Discontinue Product] Delete Success");
