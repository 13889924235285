import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { AppState } from "../../kernel";
import { map, mergeMap, catchError, switchMap, withLatestFrom } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { saveAs } from "file-saver";
import { SalonReportService, EmployeeReportService, ServiceReportService, LongitudinalReportService } from "../services";
import { LongitudinalReport } from "../../kernel/models";
import { option } from "fp-ts";
import { getStartDate, getEndDate, getEmployeeId, getAggregatedBy } from "./analytics.selectors";
import { CurrencyService } from "../../+salon-config/services";
import { AnalyticsService } from "../services/analytics.service";

import * as analyticsActions from "./analytics.actions";
import { ServiceReportDownloaderService } from "../services/service-report-downloader.service";
import { EmployeeReportDownloaderService } from "../services/employee-report-downloader.service";

@Injectable()
export class AnalyticsEffects {
  public initializeAndLoadDashboardReports$ = createEffect(() =>
    this._actions$.pipe(
      ofType<analyticsActions.InitializeAndLoadDashboardReports>(analyticsActions.Types.INITIALIZE_AND_LOAD_DASHBOARD_REPORTS),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getStartDate)), this._store.pipe(select(getEndDate))),
      switchMap(([{ aggregatedBy }, startDate, endDate]) =>
        this._analyticsService
          .loadSalonConfigAndDateRange(startDate, endDate)
          .pipe(mergeMap(({ startDate, endDate }) => [new analyticsActions.LoadDashboardReports({ startDate, endDate, aggregatedBy })]))
      )
    )
  );

  public loadDashboardReports$ = createEffect(() =>
    this._actions$.pipe(
      ofType<analyticsActions.LoadDashboardReports>(analyticsActions.Types.LOAD_DASHBOARD_REPORTS),
      map((action) => action.payload),
      switchMap((payload) => {
        const { startDate, endDate, aggregatedBy } = payload;

        const fetchSalonReport$ = this._salonReportService.fetchForDateRange(startDate, endDate);
        const fetchLongitudinalReport$ = this._longitudinalReportService.fetchForDateRange(startDate, endDate, aggregatedBy);
        const fetchEmployeeReports$ = this._employeeReportService.fetchForDateRange(startDate, endDate);
        const fetchServiceReports$ = this._serviceReportService.fetchForDateRange(startDate, endDate);

        return forkJoin([fetchSalonReport$, fetchLongitudinalReport$, fetchEmployeeReports$, fetchServiceReports$]).pipe(
          mergeMap(([currentSalonReport, longitudinalReport, employeeReports, serviceReports]) => [
            new analyticsActions.LoadCurrentSuccess(option.toUndefined(currentSalonReport)),
            new analyticsActions.LoadLongitudinalReportSuccess(option.toUndefined(longitudinalReport)),
            new analyticsActions.LoadEmployeeReportsSuccess(employeeReports),
            new analyticsActions.LoadServiceReportsSuccess(serviceReports),
            new analyticsActions.LoadDashboardReportsSuccess(),
          ]),
          catchError((error) => of(new analyticsActions.LoadDashboardReportsFail({ error: error.message })))
        );
      })
    )
  );

  public initializeAndLoadEmployeeReports$ = createEffect(() =>
    this._actions$.pipe(
      ofType<analyticsActions.InitializeAndLoadEmployeeReports>(analyticsActions.Types.INITIALIZE_AND_LOAD_EMPLOYEE_REPORTS),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getStartDate)), this._store.pipe(select(getEndDate))),
      switchMap(([{ employeeId, aggregatedBy }, startDate, endDate]) => {
        return this._analyticsService
          .loadSalonConfigAndDateRange(startDate, endDate)
          .pipe(
            mergeMap(({ startDate, endDate }) => [
              new analyticsActions.LoadEmployeeReports({ employeeId, aggregatedBy, startDate, endDate }),
            ])
          );
      })
    )
  );

  public loadEmployeeReports$ = createEffect(() =>
    this._actions$.pipe(
      ofType<analyticsActions.LoadEmployeeReports>(analyticsActions.Types.LOAD_EMPLOYEE_REPORTS),
      map((action) => action.payload),
      switchMap((payload) => {
        const { employeeId, startDate, endDate, aggregatedBy } = payload;

        const fetchEmployeeReport$ = this._employeeReportService.fetchForEmployeeInDateRange(employeeId, startDate, endDate);

        const fetchLongitudinalReport$ = this._longitudinalReportService.fetchForEmployeeInDateRange(
          employeeId,
          startDate,
          endDate,
          aggregatedBy
        );

        const fetchSummary$ = this._salonReportService.fetchForDateRange(startDate, endDate, [employeeId]);

        return forkJoin([fetchEmployeeReport$, fetchLongitudinalReport$, fetchSummary$]).pipe(
          mergeMap(([employeeReport, longitudinalReport, salonReport]) => [
            new analyticsActions.LoadEmployeeReportSuccess(option.toUndefined(employeeReport)),
            new analyticsActions.LoadLongitudinalReportSuccess(option.toUndefined(longitudinalReport)),
            new analyticsActions.LoadCurrentSuccess(option.toUndefined(salonReport)),
          ])
        );
      })
    )
  );

  public updateAggregation$ = createEffect(() =>
    this._actions$.pipe(
      ofType<analyticsActions.ChangeAggregation>(analyticsActions.Types.CHANGE_AGGREGATION),
      map((action) => action.payload.aggregatedBy),
      withLatestFrom(this._store.pipe(select(getStartDate)), this._store.pipe(select(getEndDate)), this._store.pipe(select(getEmployeeId))),
      switchMap(([aggregatedBy, startDate, endDate, employeeId]) =>
        this._longitudinalReportService
          .fetchForEmployeeInDateRange(employeeId, startDate, endDate, aggregatedBy)
          .pipe(
            mergeMap((result: option.Option<LongitudinalReport>) => [
              new analyticsActions.LoadLongitudinalReportSuccess(option.toUndefined(result)),
            ])
          )
      )
    )
  );

  public updateDateRangeDashboard$ = createEffect(() =>
    this._actions$.pipe(
      ofType<analyticsActions.UpdateDashboardDateRange>(analyticsActions.Types.UPDATE_DASHBOARD_DATE_RANGE),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getAggregatedBy))),
      map(([{ startDate, endDate }, aggregatedBy]) => new analyticsActions.LoadDashboardReports({ startDate, endDate, aggregatedBy }))
    )
  );

  public updateDateRange$ = createEffect(() =>
    this._actions$.pipe(
      ofType<analyticsActions.UpdateDateRange>(analyticsActions.Types.UPDATE_DATE_RANGE),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getEmployeeId)), this._store.pipe(select(getAggregatedBy))),
      map(
        ([{ startDate, endDate }, employeeId, aggregatedBy]) =>
          new analyticsActions.LoadEmployeeReports({ employeeId, startDate, endDate, aggregatedBy })
      )
    )
  );

  public downloadEmployeePerformanceReport$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<analyticsActions.DownloadEmployeePerformanceReport>(analyticsActions.Types.DOWNLOAD_EMPLOYEE_PERFORMANCE_REPORT),
        withLatestFrom(this._store.pipe(select(getStartDate)), this._store.pipe(select(getEndDate))),
        switchMap(([_, startDate, endDate]) =>
          this._employeeReportDownloaderService
            .downloadEmployeesReport(startDate, endDate)
            .pipe(map((response) => saveAs(response, "vish-employee-performance-report.xlsx")))
        )
      ),
    { dispatch: false }
  );

  public downloadServiceReport$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<analyticsActions.DownloadServiceReport>(analyticsActions.Types.DOWNLOAD_SERVICE_REPORT),
        withLatestFrom(this._store.pipe(select(getStartDate)), this._store.pipe(select(getEndDate))),
        switchMap(([action, startDate, endDate]) =>
          this._serviceReportDownloaderService
            .downloadServiceReport(startDate, endDate, action.payload?.showExcludedServices)
            .pipe(map((response) => saveAs(response, "vish-service-report.xlsx")))
        )
      ),
    { dispatch: false }
  );

  public downloadServicesSummaryReport$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<analyticsActions.DownloadServicesSummaryReport>(analyticsActions.Types.DOWNLOAD_SERVICES_SUMMARY_REPORT),
        withLatestFrom(
          this._store.pipe(select(getEmployeeId)),
          this._store.pipe(select(getStartDate)),
          this._store.pipe(select(getEndDate))
        ),
        switchMap(([_, employeeId, startDate, endDate]) =>
          this._employeeReportDownloadService
            .downloadSingleEmployeeReport(employeeId, startDate, endDate)
            .pipe(map((response) => saveAs(response, "vish-services-summary-report.xlsx")))
        )
      ),
    { dispatch: false }
  );

  constructor(
    private _salonReportService: SalonReportService,
    private _employeeReportService: EmployeeReportService,
    private _employeeReportDownloadService: EmployeeReportDownloaderService,
    private _serviceReportService: ServiceReportService,
    private _longitudinalReportService: LongitudinalReportService,
    private _currencyService: CurrencyService,
    private _analyticsService: AnalyticsService,
    private _employeeReportDownloaderService: EmployeeReportDownloaderService,
    private _serviceReportDownloaderService: ServiceReportDownloaderService,
    private _store: Store<AppState>,
    private _actions$: Actions
  ) {}
}
