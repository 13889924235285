<page
  [pageTitle]="(selectedManufacturer$ | async)?.name ? 'Manage Products: ' + (selectedManufacturer$ | async)?.name : 'Manage Products'"
  [showBreadcrumbs]="true"
  [progressText]="(loading$ | async) ? 'Loading...' : undefined">
  <header-controls
    *ngIf="selectedManufacturer$ | async as manufacturer"
    page-header>
    <button
      *ngIf="isRootUser$ | async"
      class="button outline"
      (click)="showMasterPricing(manufacturer)">
      Master Pricing
    </button>
    <mat-menu #controlsMenu="matMenu">
      <button
        mat-menu-item
        (click)="downloadSheet(manufacturer)">
        Download Spreadsheet
      </button>
      <button
        mat-menu-item
        (click)="uploadSheet()">
        Upload Spreadsheet
      </button>
      <button
        mat-menu-item
        (click)="setMarkup()">
        Set Markup
      </button>
    </mat-menu>
    <button
      stopPropagation
      [matMenuTriggerFor]="controlsMenu"
      class="button transparent">
      <fa-icon icon="ellipsis-v"></fa-icon>
    </button>
  </header-controls>
  <section
    class="content-section"
    *ngIf="(loading$ | async) === false"
    page-body>
    <div class="products-filters">
      <div class="name-search">
        <search-input
          placeholder="Search for a category or a product"
          [searchFilter]="searchFilter$ | async"
          (filter)="setFilter($event)"></search-input>
      </div>
      <div class="available-filters">
        <mat-select
          [value]="flagFilters$ | async"
          (selectionChange)="setFlagFilters($event.value)"
          placeholder="Filters"
          panelWidth=""
          multiple>
          <mat-option
            *ngFor="let filter of availableFilters"
            [value]="filter.key">
            {{ filter.val }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <breadcrumbs-line
      class="breadcrumbs-line"
      [items]="breadcrumbsItems$ | async"
      (itemSelected)="popToCategory($event._id)">
      <div class="breadcrumb-options">
        <mat-menu #categoryOrderMenu="matMenu">
          <button
            (click)="changeCategoryOrder()"
            mat-menu-item>
            Change Category Order
          </button>
        </mat-menu>
        <button
          [matMenuTriggerFor]="categoryOrderMenu"
          class="button transparent">
          <fa-icon icon="ellipsis-v"></fa-icon>
        </button>
        <span
          class="breadcrumb-item"
          (click)="clearSelectedCategories()"
          >All products</span
        >
      </div>
    </breadcrumbs-line>
    <div class="products-details-section">
      <products-categories-list
        class="categories-filter"
        [productCategories]="productCategories$ | async"
        [doSort]="false"
        (categorySelected)="pushCategory($event)"></products-categories-list>
      <salon-products-grouped-list
        class="product-grid"
        [products]="salonProducts$ | async"
        [productCategories]="allProductCategories$ | async"
        [measurementUnit]="measurementUnit$ | async"
        [currency]="currency$ | async"
        (editProductClicked)="editProduct($event)"
        (deactivateCategoryClicked)="setCategoryInactive($event)"
        (reactivateCategoryClicked)="setCategoryActive($event)"
        (setCategoryPricingClicked)="setCategoryPricing($event)"
        (setCategoryMarkupClicked)="setMarkup($event)"
        (placeSalonInteractiveOrderClicked)="placeSalonInteractiveOrder($event)"></salon-products-grouped-list>
    </div>
  </section>
</page>
