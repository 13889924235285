import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ManufacturersContainer, EditManufacturerContainer } from "./containers";

export const routes = [
  { path: "", component: ManufacturersContainer },
  { path: "new", component: EditManufacturerContainer, outlet: "panel" },
  { path: "edit/:id", component: EditManufacturerContainer, outlet: "panel" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class ManufacturersRoutingModule {}
