import { Appointment, AppointmentStatus, ServiceDescription } from "@getvish/model";
import { EntityService, HttpRepositoryFactory, JsonObject, PagedResult } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { delay, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerService } from "../../+customers/services";
import { EmployeeService } from "../../+employees/services";
import { AppointmentServiceChange, AppointmentVM } from "../models/appointment";
import { ServiceDescriptionService } from "app/+service-menu/services";
import { toUndefined } from "fp-ts/lib/Option";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";

@Injectable()
export class AppointmentService extends EntityService<Appointment> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _customerService: CustomerService,
    private _employeeService: EmployeeService,
    private _serviceDescriptionService: ServiceDescriptionService
  ) {
    super(repositoryFactory, { entityKey: "appointments" });
  }

  public findForDateRange(
    startDate: number,
    endDate: number,
    additionalCriteria?: JsonObject
  ): Observable<{ appointments: PagedResult<AppointmentVM> }> {
    const criteria = {
      date: { $gte: startDate, $lte: endDate },
      status: { $ne: AppointmentStatus.CANCELED },
      ...(additionalCriteria ?? {}),
    };

    return this.find(criteria, { date: 1 }).pipe(
      mergeMap((appointments) => this.makeAppointmentVMs(appointments.records).pipe(map((records) => ({ ...appointments, records })))),
      mergeMap((appointments) => {
        // short-circuit and explicitly return here because
        // the call to #forkJoin below won't emit if either @getCustomers$ or @getEmployees$
        // are empty arrays, which means the Observable returned by this method will never emit, which doesn't make sense from the caller's perspective
        // so, in the case that there are 0 appointments we don't need to fetch any Customers or Employees, anyways,
        // so we'll just explicitly return here
        // https://github.com/ReactiveX/rxjs/issues/2816
        if (appointments.records.length === 0) {
          return of({
            appointments,
          });
        } else {
          return this.hydrateAppointments(appointments.records).pipe(map((records) => ({ appointments: { ...appointments, records } })));
        }
      })
    );
  }

  public findNotCancelledByIds(ids: string[]): Observable<Appointment[]> {
    const uniqueIds = uniq(ids);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) => this._findNotCancelledByIds(_ids));

    return isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(flatten));
  }

  public _findNotCancelledByIds(ids: string[]): Observable<Appointment[]> {
    return this.find({ _id: { $in: ids }, status: { $ne: AppointmentStatus.CANCELED } }).pipe(map((response) => response.records));
  }

  private hydrateAppointments(appointments: Appointment[]): Observable<Appointment[]> {
    const customerIds = appointments.map((appointment) => appointment.customerId);
    const employeeIds = appointments.map((appointment) => appointment.employeeId);

    const getCustomers$ = this._customerService.findByIds(customerIds);
    const getEmployees$ = this._employeeService.findByIds(employeeIds);

    return forkJoin([getCustomers$, getEmployees$]).pipe(
      map(([customers, employees]) => {
        const _customers = customers.reduce((acc, c) => {
          acc[c._id] = c;
          return acc;
        }, {});
        const _employees = employees.reduce((acc, e) => {
          acc[e._id] = e;
          return acc;
        }, {});

        appointments.forEach((a) => {
          a.customer = _customers[a.customerId];
          a.employee = _employees[a.employeeId];
        });

        return appointments;
      })
    );
  }

  public loadAppointmentChangesData(appointments: AppointmentVM[]): Observable<{ serviceDescriptions: ServiceDescription[] }> {
    const serviceIds = appointments.reduce((acc, appointment) => {
      appointment.changes?.forEach((change) => {
        if (change.type === "service") {
          const serviceChange = change as AppointmentServiceChange;

          acc.add(serviceChange.fromServiceId);
          acc.add(serviceChange.toServiceId);
        }
      });

      return acc;
    }, new Set<string>());

    return serviceIds.size === 0
      ? of({ serviceDescriptions: [] })
      : this._serviceDescriptionService.findByIds(Array.from(serviceIds)).pipe(
          map((serviceDescriptions) => {
            return {
              serviceDescriptions,
            };
          })
        );
  }

  public findAppointmentVMById(id: string): Observable<AppointmentVM> {
    return this.findByIdOrDie(id).pipe(
      switchMap((appointment) => {
        const getCustomer$ = this._customerService.findById(appointment.customerId);
        const getEmployee$ = this._employeeService.findById(appointment.employeeId);

        return forkJoin([getCustomer$, getEmployee$]).pipe(
          map(([customer, employee]) => ({
            ...appointment,
            customer: toUndefined(customer),
            employee: toUndefined(employee),
          }))
        );
      }),
      switchMap((appointment) => this.makeAppointmentVMs([appointment]).pipe(map((appointments) => appointments[0])))
    );
  }

  private makeAppointmentVMs(appointments: Appointment[]): Observable<AppointmentVM[]> {
    // TODO this will need to be done properly once we have a way to get the data from the backend

    return of(appointments);

    // return of(
    //   appointments.map((appointment) => ({
    //     ...appointment,
    //     changes:
    //       appointment.status !== AppointmentStatus.CANCELED && appointment.status !== AppointmentStatus.CHECKED_OUT
    //         ? [
    //             {
    //               type: "service",
    //               fromServiceId: "0757bf60-6e19-499b-8757-5c3c215db9a3",
    //               toServiceId: "fecdb412-92d3-425a-b947-197eb87ce0f0",
    //             } as AppointmentServiceChange,
    //           ]
    //         : [],
    //   }))
    // );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public markChangesAsResolved(appointmentIds: string[]): Observable<void> {
    return of(undefined).pipe(delay(1000));
  }
}
