import { NgModule } from "@angular/core";
import { SharedModule } from "../kernel/shared";
import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { SlideoutPanelModule } from "../kernel/shared/rocket-ui/slideout-panel";

import {
  SalonReportService,
  EmployeeReportService,
  ServiceReportService,
  LongitudinalReportService,
  ManufacturerReportService,
  ProductCategoryReportService,
} from "./services";
import { KernelServicesModule } from "../kernel/services/kernel-services.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { ManufacturersSharedModule } from "../+product/+manufacturers";

import { analyticsReducer } from "./store/analytics.reducer";
import { productReportReducer } from "./store/product-reports.reducer";
import { AnalyticsEffects } from "./store/analytics.effects";
import { AnalyticsRoutingModule } from "./analytics-routing.module";
import {
  AnalyticsContainer,
  EmployeeReportContainer,
  ProductsReportContainer,
  ManufacturerReportContainer,
  ProductReportDashboardContainer,
} from "./containers";
import {
  DashboardComponent,
  EmployeeReportComponent,
  LongitudinalReportComponent,
  EmployeePerformanceTableComponent,
  ServiceReportTableComponent,
  SummaryReportComponent,
  ProductReportComponent,
  ManufacturerReportComponent,
  ProductReportDashboardComponent,
  ProductReportTableComponent,
} from "./components";
import { ProductReportsEffects } from "./store/product-reports.effects";
import { ProductReportService } from "./services/product-report.service";
import { ProductsSharedModule } from "app/+product/+products/products-shared.module";
import { ProductCategoriesSharedModule } from "app/+product/+product-categories";
import { SalonProductsSharedModule } from "app/+product/+salon-products";
import { EmployeeServicesModule } from "app/+employees/employee-services.module";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faCalendarDay, faBalanceScale, faCloudDownloadAlt, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { AnalyticsService } from "./services/analytics.service";
import { ServiceReportDownloaderService } from "./services/service-report-downloader.service";
import { EmployeeReportDownloaderService } from "./services/employee-report-downloader.service";
import { ProductReportDownloaderService } from "./services/product-report-downloader.service";
import { CardModule, DataTableModule, PageModule } from "app/+components";
import { EmployeeReportDownloaderLocalService } from "./services/employee-report-downloader-local.service";
import { ServiceReportDownloaderLocalService } from "./services/service-report-downloader-local.service";
import { PerformedServiceListContainer } from "./containers/performed-service-list.container";
import { PerformedServiceListEffects } from "./store/performed-service-list.effects";
import {
  featureKey as performedServiceListFeatureKey,
  reducer as performedServiceListReducer,
} from "./store/performed-service-list.reducer";
import { PerformedServiceListService } from "./services/performed-service-list.service";
import { FrontDeskSharedModule } from "app/+front-desk/front-desk-shared.module";

@NgModule({
  declarations: [
    AnalyticsContainer,
    DashboardComponent,
    EmployeeReportContainer,
    ProductsReportContainer,
    ManufacturerReportContainer,
    EmployeeReportComponent,
    LongitudinalReportComponent,
    EmployeePerformanceTableComponent,
    ServiceReportTableComponent,
    SummaryReportComponent,
    ProductReportComponent,
    ManufacturerReportComponent,
    ProductReportDashboardComponent,
    ProductReportDashboardContainer,
    ProductReportTableComponent,
    PerformedServiceListContainer,
  ],
  imports: [
    CardModule,
    DataTableModule,
    SharedModule,
    HttpClientModule,
    KernelServicesModule,
    AnalyticsRoutingModule,
    ManufacturersSharedModule,
    SlideoutPanelModule,
    NgxChartsModule,
    ProductCategoriesSharedModule,
    SalonProductsSharedModule,
    PageModule,
    ProductsSharedModule,
    EmployeeServicesModule.forRoot(),
    FrontDeskSharedModule,
    StoreModule.forFeature("analytics", analyticsReducer),
    StoreModule.forFeature("productReport", productReportReducer),
    StoreModule.forFeature(performedServiceListFeatureKey, performedServiceListReducer),
    EffectsModule.forFeature([AnalyticsEffects, ProductReportsEffects, PerformedServiceListEffects]),
  ],
  providers: [
    SalonReportService,
    EmployeeReportService,
    {
      provide: EmployeeReportDownloaderService,
      useClass: EmployeeReportDownloaderLocalService,
    },
    ServiceReportService,
    LongitudinalReportService,
    ProductReportService,
    ManufacturerReportService,
    ProductCategoryReportService,
    ProductReportDownloaderService,
    AnalyticsService,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ServiceReportDownloaderService,
      useClass: ServiceReportDownloaderLocalService,
    },
    PerformedServiceListService,
  ],
})
export class AnalyticsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCalendarDay, faCloudDownloadAlt, faChevronRight, faBalanceScale);
  }
}
