import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HTTP_URL } from "../../kernel/services/common";

@Injectable()
export class ExportUsageReportService {
  private _endpointUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string
  ) {
    this._endpointUrl = `${this._httpUrl}/usageReport`;
  }

  public generateSalonUsageReport(slug: string, authToken: string): Observable<void> {
    const headers = { "X-Salon-Slug": slug, "X-Auth-Token": authToken };

    return this._http.post<void>(this._endpointUrl, null, { headers });
  }
}
