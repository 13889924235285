import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../kernel";
import { Observable, combineLatest, Subscription } from "rxjs";
import { Salon } from "@getvish/model";
import * as MigrateSalonActions from "../store/migrate-salon.actions";
import * as fromMigrateSalon from "../store/migrate-salon.selectors";
import { map } from "rxjs/operators";
import { and, isNil, not } from "ramda";

@Component({
  selector: "migrate-salon-container",
  templateUrl: "migrate-salon.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MigrateSalonContainer implements OnDestroy {
  public salon$: Observable<Salon>;
  public loading$: Observable<boolean>;
  public migrating$: Observable<boolean>;
  public success$: Observable<boolean>;
  public error$: Observable<string>;
  public shouldRenderForm$: Observable<boolean>;

  public salonIdSubscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.loading$ = this._store.pipe(select(fromMigrateSalon.getLoading));
    this.migrating$ = this._store.pipe(select(fromMigrateSalon.getMigrating));
    this.success$ = this._store.pipe(select(fromMigrateSalon.getSuccess));
    this.salon$ = this._store.pipe(select(fromMigrateSalon.getSalon));
    this.error$ = this._store.pipe(select(fromMigrateSalon.getError));
    this.shouldRenderForm$ = combineLatest([this.loading$, this.error$]).pipe(map(([loading, error]) => and(not(loading), isNil(error))));

    const salonId$ = _route.paramMap.pipe(map((params) => params.get("id")));

    this.salonIdSubscription = salonId$.pipe(map((salonId) => new MigrateSalonActions.Load({ salonId }))).subscribe((action) => {
      this._store.dispatch(action);
    });
  }

  public close(): void {
    this._store.dispatch(new MigrateSalonActions.Done());
  }

  public cancel(): void {
    this._store.dispatch(new MigrateSalonActions.Cancel());
  }

  public done(): void {
    this._store.dispatch(new MigrateSalonActions.Done());
  }

  public migrate(salon: Salon): void {
    this._store.dispatch(new MigrateSalonActions.Migrate({ salon }));
  }

  public ngOnDestroy(): void {
    this.salonIdSubscription.unsubscribe();
  }
}
