<div class="categories-container">
  <div
    *ngFor="let category of sortedCategories; trackBy: trackByFn"
    class="category"
    [class.selected]="category.selected && category.terminal"
    (click)="categorySelected.emit(category)">
    <span>{{ category.name }}</span>
    <fa-icon
      icon="chevron-right"
      size="xs"></fa-icon>
  </div>
</div>
