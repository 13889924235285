<dialog-contents [dialogTitle]="data.title">
  <div class="confirm-message">
    {{ data.message }}
  </div>
  <div
    class="button-container"
    dialog-footer>
    <button
      *ngIf="data.cancelText"
      (click)="cancelClicked()"
      class="button secondary">
      {{ data.cancelText }}
    </button>
    <button
      *ngIf="data.actionText"
      (click)="actionClicked()"
      class="button primary">
      {{ data.actionText }}
    </button>
  </div>
</dialog-contents>
