import { Injectable } from "@angular/core";
import { Salon } from "@getvish/model";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { either, option } from "fp-ts";
import { of, pipe } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import * as routerActions from "../../kernel/store/actions/router.actions";
import * as snackbarActions from "../../kernel/store/actions/snackbar.actions";
import { SalonService } from "../services";
import * as actions from "./revoke-restore-access.actions";

@Injectable()
export class RevokeRestoreAccessEffects {
  public navigateToRevoke$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateToRevoke),
      map(({ salonId }) =>
        routerActions.go({ path: ["/salons", { outlets: { panel: `revoke/${salonId}` } }], extras: { queryParamsHandling: "merge" } })
      )
    )
  );

  public navigateToRestore$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateToRestore),
      map(({ salonId }) =>
        routerActions.go({ path: ["/salons", { outlets: { panel: `restore/${salonId}` } }], extras: { queryParamsHandling: "merge" } })
      )
    )
  );

  public load$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.load),
      map((action) => action.salonId),
      switchMap((salonId) =>
        this._salonService.findById(salonId).pipe(
          map(
            option.fold<Salon, Action>(
              () => actions.loadFail({ error: new Error("Salon not found") }),
              (salon) => actions.loadSuccess({ salon })
            )
          )
        )
      )
    )
  );

  public revokeAccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.revokeAccess),
      map((action) => action.salon._id),
      switchMap((id) =>
        this._salonService.revokeAccess(id).pipe(
          mergeMap(
            pipe(
              either.fold<HttpError, void, Action[]>(
                (error) => [actions.revokeAccessFail({ error: new Error(`Revoke access failed with code: ${error.code}`) })],
                () => [actions.revokeAccessSuccess()]
              )
            )
          ),
          catchError((error) => of(actions.revokeAccessFail({ error })))
        )
      )
    )
  );

  public restoreAccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.restoreAccess),
      map((action) => action.salon._id),
      switchMap((id) =>
        this._salonService.restoreAccess(id).pipe(
          mergeMap(
            pipe(
              either.fold<HttpError, void, Action[]>(
                (error) => [actions.restoreAccessFail({ error: new Error(`Restore access failed with code: ${error.code}`) })],
                () => [actions.restoreAccessSuccess()]
              )
            )
          ),
          catchError((error) => of(actions.restoreAccessFail({ error })))
        )
      )
    )
  );

  public revokeAccessSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.revokeAccessSuccess),
      mergeMap(() => {
        return [new snackbarActions.Info({ message: "Successfully Revoked Access" }), actions.close()];
      })
    )
  );

  public restoreAccessSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.restoreAccessSuccess),
      mergeMap(() => {
        return [new snackbarActions.Info({ message: "Successfully Restored Access" }), actions.close()];
      })
    )
  );

  public close$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.close),
      map(() => routerActions.go({ path: ["/salons"], extras: { queryParamsHandling: "merge" } }))
    )
  );

  constructor(
    private _salonService: SalonService,
    private _actions$: Actions
  ) {}
}
