import { createReducer, on } from "@ngrx/store";

import * as actions from "./merge-products.actions";
import { ProductSelection } from "./product.reducer";
import { MergeProductsSuccess } from "../services";

export interface MergeProductsState {
  primary: ProductSelection;
  saving: boolean;
  error: Error;
  result: MergeProductsSuccess;
}

export const initialState: MergeProductsState = {
  primary: undefined,
  saving: false,
  error: undefined,
  result: undefined,
};

export const reducer = createReducer(
  initialState,
  on(actions.init, () => ({ ...initialState })),
  on(actions.updatePrimary, (state, { product }) => ({
    ...state,
    primary: product,
  })),
  on(actions.merge, (state) => ({
    ...state,
    saving: true,
  })),
  on(actions.mergeProductsSuccess, (state, { result }) => ({
    ...state,
    saving: false,
    result,
  })),
  on(actions.mergeProductsFail, (state, { error }) => ({
    ...state,
    saving: false,
    error,
  }))
);

export const featureKey = "mergeProducts";
