<h4>
  Upload <span class="highlight">{{ title }} Data</span>
</h4>
<div *ngIf="report | isEmpty">
  <p>
    Merge the spreadsheet data exported in the previous step with the POS provider's data, save the {{ title }} sheet as a .CSV file and
    upload it for parsing
  </p>

  <div class="upload-file-container">
    <div *ngIf="!loading; else uploadingElement">
      <input
        id="file"
        name="file"
        type="file"
        (change)="onFileChange($event)" />
      <label
        for="file"
        id="upload-csv-label"
        ><fa-icon icon="cloud-upload-alt"></fa-icon
      ></label>
    </div>
    <ng-template #uploadingElement>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-template>
  </div>
</div>

<div *ngIf="report | isDefined">
  <alert-component
    type="success"
    *ngIf="report.success">
    File successfully uploaded
  </alert-component>
</div>

<div class="controls">
  <button
    (click)="next.emit()"
    [disabled]="loading"
    class="button primary">
    <span>Next</span>
  </button>
</div>
