import { createReducer, on } from "@ngrx/store";
import { Notification } from "../models";
import { loadNotifications, loadNotificationsFail, loadNotificationsSuccess, markAsReadSuccess } from "./notifications.actions";

export interface NotificationsState {
  notifications: Notification[];
  loading: boolean;
  error: Error;
}

const initialState: NotificationsState = {
  notifications: [],
  loading: false,
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(loadNotifications, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadNotificationsSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    loading: false,
  })),
  on(loadNotificationsFail, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(markAsReadSuccess, (state, { notifications }) => ({
    ...state,
    notifications: state.notifications.map((notification) =>
      notifications.some((n) => notification.id === n.id) ? { ...notification, isRead: true } : notification
    ),
  }))
);

export const featureKey = "notifications";
