import { createSelector, createFeatureSelector } from "@ngrx/store";
import { MigrateSalonState } from "./migrate-salon.reducer";

/*** SELECTORS ***/
export const getState = createFeatureSelector<MigrateSalonState>("migrateSalon");
export const getSalon = createSelector(getState, (state) => state.salon);
export const getMigrating = createSelector(getState, (state) => state.migrating);
export const getSuccess = createSelector(getState, (state) => state.success);
export const getError = createSelector(getState, (state) => state.error);
export const getLoading = createSelector(getState, (state) => state.loading);
