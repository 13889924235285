import { Component, ChangeDetectionStrategy, Input, OnChanges, Output, EventEmitter } from "@angular/core";
import { EmployeeReport, getComplianceRatio } from "../../kernel/models";
import { MeasurementUnit } from "@getvish/model";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { go } from "app/kernel/store/actions/router.actions";
import { pipe } from "fp-ts/lib/function";
import { filter, sortWith } from "ramda";

@Component({
  selector: "employee-performance-table",
  templateUrl: "./employee-performance-table.component.html",
  styleUrls: ["./employee-performance-table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePerformanceTableComponent implements OnChanges {
  @Input() public reports: EmployeeReport[];
  @Input() public take?: number;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public currency: string;
  @Input() public enableComplianceTracking: boolean;

  @Output() public navigateToMixed = new EventEmitter<string>();
  @Output() public navigateToUnmixed = new EventEmitter<string>();

  public _reports: EmployeeReport[];

  public getComplianceRatio = getComplianceRatio;

  constructor(private _store: Store<AppState>) {}

  public ngOnChanges(): void {
    const drivingKey = this.enableComplianceTracking ? "numMixableServices" : "numServicesPerformed";
    const sortKeys = this.enableComplianceTracking ? ["numMixableServices", "numMixedServices"] : ["numServicesPerformed"];

    this._reports = pipe(
      this.reports,
      filter<EmployeeReport>((report) => report.summary[drivingKey] > 0),
      sortWith(sortKeys.map((key) => (a, b) => b.summary[key] - a.summary[key]))
    );
  }

  public viewEmployeeReport(employeeId): void {
    this._store.dispatch(go({ path: [`/insights/employee/${employeeId}`] }));
  }
}
