<div
  *ngIf="shouldRender"
  class="page-navigation-container">
  <ul class="page-list">
    <li
      *ngFor="let page of pages"
      [ngClass]="{ active: page.isActive }">
      <button
        (click)="selectPage(page.value)"
        class="button outline secondary page {{ page.class }}"
        [disabled]="!page.isEnabled">
        <fa-icon
          *ngIf="page.icon"
          [icon]="['fas', page.icon]"
          class="icon"></fa-icon>
        <span>{{ page.label }}</span>
      </button>
    </li>
  </ul>
</div>
