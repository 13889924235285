import { Component, Input, ChangeDetectionStrategy, OnChanges, Output, EventEmitter } from "@angular/core";
import { getComplianceRatio, ServiceReport } from "../../kernel/models";
import { MeasurementUnit } from "@getvish/model";
import { filter, includes, map, not, sortWith } from "ramda";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

@Component({
  selector: "service-report-table",
  templateUrl: "./service-report-table.component.html",
  styleUrls: ["./service-report-table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceReportTableComponent implements OnChanges {
  @Input() public reports: ServiceReport[];
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public take?: number;
  @Input() public showExcludedServices: boolean;
  @Input() public currency: string;
  @Input() public enableComplianceTracking: boolean;

  @Output() public navigateToMixed = new EventEmitter<string>();
  @Output() public navigateToUnmixed = new EventEmitter<string>();

  public _reports: ServiceReport[];

  public getComplianceRatio = getComplianceRatio;

  public ngOnChanges(): void {
    const sortKeys = this.enableComplianceTracking ? ["numMixableServices", "numMixedServices"] : ["numServicesPerformed"];

    this._reports = pipe(
      this.reports,
      filter<ServiceReport>((report) => {
        if (this.enableComplianceTracking) {
          if (this.showExcludedServices) {
            return report.summary.numMixableServices > 0 || report.flags?.includes("EXCLUDE_FROM_ANALYTICS");
          } else {
            return report.summary.numMixableServices > 0;
          }
        } else {
          return report.summary.numServicesPerformed > 0;
        }
      }),
      map((report) => {
        if (this.enableComplianceTracking && this.showExcludedServices) {
          return {
            ...report,
            summary: {
              ...report.summary,
              numMixableServices: report.summary.numServices,
              numMixedServices: report.summary.numServicesPerformed,
            },
          };
        }

        return report;
      }),
      filter<ServiceReport>((report) => this._filterExcludedService(this.showExcludedServices, report)),
      sortWith(sortKeys.map((key) => (a, b) => b.summary[key] - a.summary[key]))
    );
  }

  private _filterExcludedService(showExcludedServices: boolean, report: ServiceReport): boolean {
    if (showExcludedServices !== true) {
      return pipe(
        option.fromNullable(report.flags),
        option.map((flags) => not(includes("EXCLUDE_FROM_ANALYTICS", flags))),
        option.getOrElse(() => true)
      );
    } else {
      return true;
    }
  }
}
