import { Action } from "@ngrx/store";
import { PagingMetadata, JsonObject } from "@getvish/stockpile";
import { Device } from "@getvish/model";

export enum Types {
  LOAD_ALL = "[Device] Load All",
  LOAD_ALL_SUCCESS = "[Device] Load All Success",
  LOAD_ALL_FAIL = "[Device] Load All Fail",
  REMOVE = "[Device] Remove",
  REMOVE_SUCCESS = "[Device] Remove Success",
  REMOVE_FAIL = "[Device] Remove Fail",
  TOGGLE_SHOW_DISABLED = "[Device] Toggle Show Disabled",
}

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;

  constructor(public payload: { criteria?: JsonObject; sort?: JsonObject; page?: number; limit?: number }) {}
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public payload: Device[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class Remove implements Action {
  public readonly type = Types.REMOVE;

  constructor(public payload: { id: string }) {}
}

export class RemoveSuccess implements Action {
  public readonly type = Types.REMOVE_SUCCESS;

  constructor(public payload: string) {}
}

export class RemoveFail implements Action {
  public readonly type = Types.REMOVE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class ToggleShowDisabled implements Action {
  public readonly type = Types.TOGGLE_SHOW_DISABLED;
}

export type Actions = LoadAll | LoadAllSuccess | LoadAllFail | Remove | RemoveSuccess | RemoveFail | ToggleShowDisabled;
