import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { range, toLower, or, isNil, isEmpty, complement } from "ramda";
import { Employee, AppointmentModule, Appointment, Customer } from "@getvish/model";
import { Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { setMinutes, setHours, format, getTime } from "date-fns";
import { Observable, Subscription } from "rxjs";
import { startWith, map, filter } from "rxjs/operators";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { isUuidValidator } from "../../kernel/validators";
import { validate } from "uuid";
import { zonedTimeToUtc } from "date-fns-tz/fp";
import { getStartOfDayInTimeZone } from "app/kernel/util/zoned-time-utils";

type FormData = {
  customerId: string;
  employeeId: string;
  hour: number;
  minute: number;
  ampm: string;
  date: Date;
};

interface MinuteOption {
  value: number;
  display: string;
}

const isNotValidUuid = complement(validate);
const longerThan = (length: number) => (value: string) => value.length > length;

@Component({
  selector: "schedule-appointment",
  templateUrl: "schedule-appointment.component.html",
  styleUrls: ["schedule-appointment.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleAppointmentComponent implements OnDestroy, OnInit {
  @Input() public employees: Employee[];
  @Input() public customers: Customer[];
  @Input() public error: string;
  @Input() public saving: boolean;
  @Input() public searchFilter: string;
  @Input() public timeZone: string;
  @Output() public close: EventEmitter<void>;
  @Output() public cancel: EventEmitter<void>;
  @Output() public save: EventEmitter<Appointment>;
  @Output() public searchCustomer: EventEmitter<string>;

  public hourOptions: string[];
  public minuteOptions: MinuteOption[];
  public form: UntypedFormGroup;
  public minDate: Date;
  public filteredEmployees: Observable<Employee[]>;
  public customerControlSubscription: Subscription;

  public INTERVAL = 1000 * 60 * 5; // five minutes in milliseconds
  public MILLISECONDS_IN_HOUR = 3600000;
  public NUM_INTERVALS_IN_HOUR = this.MILLISECONDS_IN_HOUR / this.INTERVAL;
  public MILLISECONDS_IN_MINUTE = 60000;

  public filterValue: string | null;

  constructor(private _fb: UntypedFormBuilder) {
    const toMinutes = (milliseconds: number) => milliseconds / this.MILLISECONDS_IN_MINUTE;

    this.hourOptions = range(1, 13).map((value) => value.toString());

    // because we want to display the minutes input as they'd usually appear on a clock, with a leading 0
    // we'll convert the options so they have both a value a "display" value to be rendered in the UI
    this.minuteOptions = range(0, this.NUM_INTERVALS_IN_HOUR)
      .map((value) => value * toMinutes(this.INTERVAL))
      .map((value) => ({ value, display: this.padMinutes(value) }));

    this.close = new EventEmitter(true);
    this.cancel = new EventEmitter(true);
    this.save = new EventEmitter(true);
    this.searchCustomer = new EventEmitter(true);

    this._initForm();
  }

  public ngOnInit(): void {
    this.minDate = getStartOfDayInTimeZone(this.timeZone);

    this._initForm();

    this.filteredEmployees = this.form.controls["employeeId"].valueChanges.pipe(
      filter(isNotValidUuid),
      startWith(""),
      map((value) => this._filterEmployees(this.employees, value))
    );

    this.customerControlSubscription = this.form.controls["customerId"].valueChanges
      .pipe(filter(longerThan(2)), filter(isNotValidUuid))
      .subscribe((value: string) => this.searchCustomer.emit(value));
  }

  public submit(data: FormData): void {
    const { hour, minute, ampm, date } = data;

    // this date will have been parsed from the UI, but will be in the time zone of the user's browser
    // so we'll want to adjust it so it represents the chosen time _in the time zone of the salon_
    const parsedDateZoned = this._parsePartialDate(hour, minute, ampm, date);
    const utcDate = getTime(zonedTimeToUtc(this.timeZone)(parsedDateZoned));

    const appointment = AppointmentModule.ap(data.customerId, data.employeeId, utcDate);

    this.save.emit(appointment);
  }

  public displayEmployee =
    (employees: Employee[]) =>
    (employeeId?: string): string | null => {
      const employee = employees.find((employee) => employee._id === employeeId);

      return pipe(
        option.fromNullable(employee),
        option.map((employee) => `${employee.firstName} ${employee.lastName}`),
        option.toNullable
      );
    };

  public displayCustomer =
    (customers: Customer[]) =>
    (customerId?: string): string | null => {
      const customer = customers.find((employee) => employee._id === customerId);

      return pipe(
        option.fromNullable(customer),
        option.map((customer) => `${customer.firstName} ${customer.lastName}`),
        option.toNullable
      );
    };

  public ngOnDestroy(): void {
    this.customerControlSubscription.unsubscribe();
  }

  private _filterEmployees(employees: Employee[], filter: string): Employee[] {
    // if the filter is null/undefined or otherwise empty, we don't need to do anything else
    // just return the entire list of employees
    if (or(isNil(filter), isEmpty(filter))) {
      return employees;
    }

    // split the terms by whitespace so we test against each term in the query, but no whitespace _in_ a term
    const terms = filter.split(/\s+/g);

    // match names according to the following criteria:
    // for each term in the filter string, test whether
    // first name starts with that term, or
    // last name starts with that term,
    const regexps = terms.map((term) => new RegExp(`^${term}`, "i"));

    return employees.filter((employee) => regexps.some((regexp) => or(regexp.test(employee.firstName), regexp.test(employee.lastName))));
  }

  public padMinutes(value: number): string {
    return value.toString().padStart(2, "0");
  }

  private _initForm(): void {
    const currentDate = new Date();
    const currentMinute = currentDate.getMinutes();
    const currentHour12h =
      currentDate.getHours() > 12 // if the current time is in the afternoon
        ? currentDate.getHours() - 12 // subtract 12 so we get the 12h time instead of 24h
        : currentDate.getHours(); // we're still in the AM, so we can just safely return 24h time

    const ampm = format(currentDate, "a");

    this.form = this._fb.group({
      customerId: [undefined, Validators.compose([Validators.required, isUuidValidator])],
      employeeId: [undefined, Validators.compose([Validators.required, isUuidValidator])],
      hour: [currentHour12h, Validators.required],
      minute: [currentMinute, Validators.required],
      ampm: [ampm, Validators.required],
      date: [currentDate],
    });
  }

  private _parsePartialDate(hour: number | string, minute: number | string, ampm: string, initialDate: Date = new Date()): Date {
    // because the incoming @hour and @minute values are coming from a form control where the user can just "type stuff in" they may be strings or numbers
    // TODO is we continue to use these time-selector components we could refactor this out properly so it's packaged up as part of the component
    // and use a ValueControlAccessor so this kind of stuff is hidden away neatly
    const parsedHour = parseInt(hour.toString(), 10);
    const parsedMinute = parseInt(minute.toString(), 10);

    // kk so dates are weird, and when the hour is 12, mathematically it should actually be 0
    // so then on the next line we can safely add 12 to get the correct 24hr time (0-23) if user
    // has selected 'PM' (i.e. 1pm == 13hr on the 24 hour clock)
    // BUT, if the @hour === 12 and @ampm is 'AM', the hour should be 0 on the 24 hour clock
    const adjustedHour = parsedHour === 12 ? 0 : parsedHour;
    const hour24h = toLower(ampm) === "am" ? adjustedHour : adjustedHour + 12;

    const date = setHours(setMinutes(initialDate.getTime(), parsedMinute), hour24h);

    return date;
  }
}
