import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { back } from "../../../kernel/store/actions/router.actions";
import { SetCategoryPricing } from "../store/salon-product.actions";
import { CategoryPricing } from "../common";
import { MeasurementUnit } from "@getvish/model";
import { AppState } from "app/kernel";

import * as fromSalonConfig from "../../../+salon-config/store/salon-config.selectors";

@Component({
  selector: "set-category-pricing-container",
  templateUrl: "set-category-pricing.container.html",
})
export class SetCategoryPricingContainer {
  public categoryId$: Observable<string>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public currency$: Observable<string>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.categoryId$ = _route.paramMap.pipe(map((params) => params.get("id")));
    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
  }

  public save(pricing: CategoryPricing): void {
    this._store.dispatch(new SetCategoryPricing({ pricing }));
  }

  public close(): void {
    this._store.dispatch(back());
  }
}
