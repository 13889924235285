<div
  class="slideout-panel"
  @slideOutFromRight>
  <header>
    <h1>
      <ng-content select="[panel-header]"></ng-content>
    </h1>
    <button
      *ngIf="closeable"
      class="close-button"
      (click)="close.emit()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </header>
  <div>
    <ng-content select="[panel-body]"></ng-content>
  </div>
</div>
