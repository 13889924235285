import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Salon } from "@getvish/model";
import { ServerConfig } from "app/kernel/models/server-config";
import { SERVER_CONFIG } from "app/kernel/services/common";
import { Observable } from "rxjs";
import { CsvUploadFileType } from "../store/import-salon-data.actions";

@Injectable()
export class UploadSalonDataService {
  private _endpointUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(SERVER_CONFIG) serverConfig: ServerConfig
  ) {
    this._endpointUrl = `${serverConfig.httpProtocol}://${serverConfig.hostname}:${serverConfig.port}/api`;
  }

  public initializeSession(salon: Salon, emailAddress: string, authToken: string): Observable<void> {
    return this.http.post<void>(
      `${this._endpointUrl}/import/session`,
      { emailAddress: emailAddress },
      {
        headers: {
          "X-Salon-Id": salon._id,
          "X-Auth-Token": authToken,
        },
      }
    );
  }

  public runSession(salon: Salon, authToken: string): Observable<void> {
    return this.http.post<void>(
      `${this._endpointUrl}/import/session/run`,
      {},
      {
        headers: {
          "X-Salon-Id": salon._id,
          "X-Auth-Token": authToken,
        },
      }
    );
  }

  public uploadCsvFile(file: File, salon: Salon, authToken: string, type: CsvUploadFileType): Observable<void> {
    return this.http.post<void>(`${this._endpointUrl}/import/csv/${type}`, file, {
      headers: {
        "X-Salon-Id": salon._id,
        "X-Auth-Token": authToken,
      },
    });
  }
}
