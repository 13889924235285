import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "colored-dot",
  templateUrl: "colored-dot.component.html",
  styleUrls: ["colored-dot.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColoredDotComponent {
  @Input() public color: string;
  @Input() public size: "small" | "medium" | "large" | "xlarge";
}
