<page
  [pageTitle]="'Manage Products: ' + ((selectedManufacturer$ | async)?.name ?? '')"
  [showBreadcrumbs]="true"
  [progressText]="(saving$ | async) ? 'Saving...' : undefined"
  *ngIf="selectedManufacturerId$ | async as manufacturerId">
  <header-controls
    *ngIf="currentTabIndex === 0"
    page-header>
    <button
      class="button outline small"
      (click)="newCategory(null, manufacturerId)">
      New Category
    </button>
    <mat-menu #controlsMenu="matMenu">
      <button
        mat-menu-item
        (click)="changeRootCategoryOrder()">
        Change Category Order
      </button>
      <button
        mat-menu-item
        (click)="collapseAll()">
        Collapse All
      </button>
      <button
        mat-menu-item
        (click)="expandAll()">
        Expand All
      </button>
    </mat-menu>
    <button
      stopPropagation
      [matMenuTriggerFor]="controlsMenu"
      class="button transparent">
      <fa-icon icon="ellipsis-v"></fa-icon>
    </button>
  </header-controls>
  <header-controls
    *ngIf="currentTabIndex === 1"
    page-header>
    <search-input
      placeholder="Search Products..."
      [searchFilter]="filter$ | async"
      (filter)="setFilter($event)"></search-input>
    <button
      matTooltip="Merge Selected Products"
      class="button outline secondary"
      [disabled]="(selectedProducts$ | async).length <= 1"
      (click)="mergeProducts()">
      <fa-icon icon="code-merge"></fa-icon>
    </button>
    <button
      class="button outline small"
      (click)="newProduct(null, manufacturerId)">
      New Product
    </button>
  </header-controls>
  <card page-body>
    <mat-tab-group
      card-body
      (selectedTabChange)="tabChange($event)">
      <mat-tab label="Categories">
        <ng-template
          #categoryMenu
          let-categoryNode="category">
          <mat-menu #controlsMenu="matMenu">
            <button
              mat-menu-item
              (click)="editCategory(categoryNode)">
              Edit
            </button>
            <button
              mat-menu-item
              (click)="newCategory(categoryNode)">
              Add Subcategory
            </button>
            <button
              mat-menu-item
              (click)="newProduct(categoryNode, manufacturerId)">
              Add Product
            </button>
            <button
              *ngIf="categoryNode.children?.length !== 0"
              mat-menu-item
              (click)="changeCategoryOrder(categoryNode)">
              Change Category Order
            </button>
            <button
              *ngIf="categoryNode.products?.length !== 0"
              mat-menu-item
              (click)="changeProductOrder(categoryNode)">
              Change Product Order
            </button>
          </mat-menu>
          <button
            stopPropagation
            [matMenuTriggerFor]="controlsMenu"
            class="button transparent">
            <fa-icon icon="ellipsis-v"></fa-icon>
          </button>
        </ng-template>
        <ng-template
          #productList
          let-products="products">
          <product-categories-product-list
            [products]="products"
            [discontinuedProducts]="discontinuedProducts$ | async"
            (select)="editProduct($event)"
            (discontinue)="discontinueProduct($event)">
          </product-categories-product-list>
        </ng-template>
        <product-categories
          [loading]="loading$ | async"
          [categories]="categories$ | async"
          [products]="products$ | async"
          [categoryMenuTemplate]="categoryMenu"
          [activeCategoryIds]="activeCategoryIds$ | async"
          [productListTemplate]="productList"
          (addProduct)="newProduct($event, manufacturerId)"
          (addCategory)="newCategory($event)"
          (editProduct)="editProduct($event)"
          (edit)="editCategory($event)"
          (toggleCategory)="toggleCategory($event)">
        </product-categories>
      </mat-tab>
      <mat-tab
        *ngLet="categories$ | async as categories"
        label="Products">
        <products-list
          *ngLet="selectedManufacturer$ | async as selectedManufacturer"
          [loading]="productsLoading$ | async"
          [products]="filteredProducts$ | async"
          [discontinuedProducts]="discontinuedProducts$ | async"
          [selectedProducts]="selectedProducts$ | async"
          [rootCategoryMap]="rootCategoryMap$ | async"
          (edit)="editProduct($event)"
          (select)="selectProduct($event, selectedManufacturer, categories)"
          (clearSelections)="clearSelections()"
          (discontinue)="discontinueProduct($event)"></products-list>
      </mat-tab>
    </mat-tab-group>
  </card>
</page>
