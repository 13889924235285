<div
  class="content"
  *ngIf="loading === false; else loadingElement">
  <div
    class="summary-text"
    *ngIf="appointments?.length === 1">
    <h4>Appointment Updated</h4>
    <p>
      This appointment originated from your POS system but has been updated in Vish. Please review the changes below, update the appointment
      in your POS system accordingly, and then mark the alert as resolved.
    </p>
  </div>
  <div
    class="summary-text"
    *ngIf="appointments?.length > 1">
    <h4>Appointments Updated</h4>
    <p>
      These appointments originated from your POS system but have been updated in Vish. Please review the changes below, update the
      appointments in your POS system accordingly, and then mark the alerts as resolved.
    </p>
  </div>
  <hr />
  <div class="changes">
    <appointment-changes
      *ngFor="let appointment of appointments"
      [appointment]="appointment"
      [serviceDescriptions]="serviceDescriptions"
      [expanded]="expandedAppointmentId === appointment._id"
      [timeZone]="timeZone"
      (expand)="setExpandedAppointmentId(appointment._id)">
    </appointment-changes>
  </div>
  <div class="controls">
    <button
      (click)="resolve.emit(appointments)"
      class="button primary">
      <span>Mark As Resolved</span>
    </button>
  </div>
</div>
<ng-template #loadingElement>
  <large-loading-indicator></large-loading-indicator>
</ng-template>
