<div *ngIf="error">
  <div class="page-content">
    <alert-component type="danger">
      <h4>An Error Has Occured</h4>

      <p>{{ error }}</p>
    </alert-component>
  </div>
</div>

<div *ngIf="!error">
  <form
    [formGroup]="form"
    (submit)="submit(form.value)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Choose a date</mat-label>
      <input
        matInput
        formControlName="date"
        [min]="minDate"
        [matDatepicker]="picker" />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      id="customer"
      class="full-width">
      <mat-label>Client</mat-label>
      <input
        type="text"
        matInput
        formControlName="customerId"
        [matAutocomplete]="customerAuto" />
      <mat-autocomplete
        #customerAuto="matAutocomplete"
        [displayWith]="displayCustomer(customers)">
        <mat-option
          *ngFor="let customer of customers"
          [value]="customer._id">
          <span>{{ customer.firstName }} {{ customer.lastName }}</span>
          <span class="customer-subtitle">{{ customer.mobilePhone || customer.homePhone || customer.email }}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('customerId').hasError('required')">Required</mat-error>
      <mat-error *ngIf="form.get('customerId').hasError('isUuid')">Choose a Client</mat-error>
    </mat-form-field>

    <mat-form-field
      id="employee"
      class="full-width">
      <mat-label>Employee</mat-label>
      <input
        type="text"
        matInput
        formControlName="employeeId"
        [matAutocomplete]="employeeAuto" />
      <mat-autocomplete
        #employeeAuto="matAutocomplete"
        [displayWith]="displayEmployee(employees)">
        <mat-option
          *ngFor="let employee of filteredEmployees | async"
          [value]="employee._id">
          {{ employee.firstName }} {{ employee.lastName }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('employeeId').hasError('required')">Required</mat-error>
      <mat-error *ngIf="form.get('employeeId').hasError('isUuid')">Choose an Employee</mat-error>
    </mat-form-field>

    <div id="date-fields-container">
      <mat-form-field id="hour">
        <mat-label>Hour</mat-label>
        <input
          formControlName="hour"
          [matAutocomplete]="hourAutocomplete"
          matInput />
        <mat-autocomplete #hourAutocomplete="matAutocomplete">
          <mat-option
            *ngFor="let hour of hourOptions"
            [value]="hour">
            {{ hour }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field id="minute">
        <mat-label>Minute</mat-label>
        <input
          formControlName="minute"
          [matAutocomplete]="minuteAutoComplete"
          matInput />
        <mat-autocomplete
          #minuteAutoComplete="matAutocomplete"
          [displayWith]="padMinutes">
          <mat-option
            *ngFor="let minute of minuteOptions"
            [value]="minute.value">
            {{ minute.display }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field id="period">
        <mat-select formControlName="ampm">
          <mat-option value="AM"> AM </mat-option>
          <mat-option value="PM"> PM </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="controls">
    <button
      type="button"
      class="button outline"
      [disabled]="saving"
      (click)="cancel.emit()">
      <span>Cancel</span>
    </button>

    <button
      type="button"
      class="button primary"
      [disabled]="form.invalid"
      (click)="submit(form.value)">
      <span *ngIf="!saving; else loadingIndicator">Save</span>
      <ng-template #loadingIndicator>
        <fa-icon
          *ngIf="saving"
          [icon]="['fas', 'circle-notch']"
          [spin]="true">
        </fa-icon>
      </ng-template>
    </button>
  </div>
</div>
