import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { getComplianceRatio, Metrics } from "../../kernel/models";

@Component({
  selector: "summary-report",
  templateUrl: "summary-report.component.html",
  styleUrls: ["summary-report.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryReportComponent {
  @Input() public metrics: Metrics;
  @Input() public currency: string;
  @Input() public enableComplianceTracking: boolean;

  public getComplianceRatio = getComplianceRatio;
}
