import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as elementResizeDetectorMaker from "element-resize-detector";
import { Subscription } from "rxjs";

@Injectable()
export class ResizeService {
  private resizeDetector: any;
  private dlgSubscription?: Subscription;

  constructor(private dialog: MatDialog) {
    this.resizeDetector = elementResizeDetectorMaker({ strategy: "scroll" });
  }

  addResizeEventListener(element: HTMLElement, handler: () => void, fireOnDialogOpen = false) {
    this.resizeDetector.listenTo(element, handler);

    if (fireOnDialogOpen) {
      const dialog = this.getClosestDialog(element);

      if (dialog != null) {
        this.dlgSubscription = dialog.afterOpened().subscribe(() => {
          handler();

          // It's not necessarily true that everything in the DOM is "correct" after the dialog
          // claims that it's opened, so this hack attempts to work around that for now.
          setTimeout(handler, 100);
        });
      }
    }
  }

  removeResizeEventListener(element: HTMLElement) {
    this.resizeDetector.uninstall(element);

    if (this.dlgSubscription != null) {
      this.dlgSubscription.unsubscribe();
    }
  }

  public getClosestDialog(element: HTMLElement) {
    let parent = element.parentElement;
    while (parent && !parent.classList.contains("mat-mdc-dialog-container")) {
      parent = parent.parentElement;
    }

    return parent ? this.dialog.openDialogs.find((dialog) => dialog.id === parent?.id) : null;
  }
}
