import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, TemplateRef, OnChanges } from "@angular/core";
import { ProductCategoryNode, sortCategoryNodesByOrderFn } from "../common";
import { includes, sort, isNil } from "ramda";
import { pipe } from "fp-ts/pipeable";
import { option } from "fp-ts";
import { SalonProduct } from "@getvish/model";

@Component({
  selector: "recursive-categories-list",
  templateUrl: "recursive-categories-list.component.html",
  styleUrls: ["recursive-categories-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecursiveCategoriesListComponent implements OnChanges {
  @Input() public categoryNodes: ProductCategoryNode[];
  @Input() public activeCategoryIds: string[];
  @Input() public categoryMenuTemplate: TemplateRef<any>;
  @Input() public productListTemplate: TemplateRef<any>;
  @Output() public select: EventEmitter<ProductCategoryNode>;
  @Output() public addCategory: EventEmitter<ProductCategoryNode>;
  @Output() public addProduct: EventEmitter<ProductCategoryNode>;
  @Output() public editProduct: EventEmitter<SalonProduct>;
  @Output() public toggle: EventEmitter<ProductCategoryNode>;

  public _categoryNodes: ProductCategoryNode[];

  constructor() {
    this.select = new EventEmitter(true);
    this.addProduct = new EventEmitter(true);
    this.addCategory = new EventEmitter(true);
    this.editProduct = new EventEmitter(true);
    this.toggle = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    if (isNil(this.categoryNodes)) {
      return;
    }

    this._categoryNodes = sort(sortCategoryNodesByOrderFn, this.categoryNodes);
  }

  public isActive(category: ProductCategoryNode, activeCategoryIds?: string[]): boolean {
    return includes(
      category._id,
      pipe(
        option.fromNullable(activeCategoryIds),
        option.getOrElse(() => [])
      )
    );
  }

  public trackByFn(index: number, item: ProductCategoryNode) {
    return item._id;
  }
}
