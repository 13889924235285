import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconName } from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: "empty-state-message",
  templateUrl: "empty-state-message.component.html",
  styleUrls: ["empty-state-message.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateMessageComponent {
  @Input() public title: string;
  @Input() public icon: IconName;
}
