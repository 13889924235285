import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "date-range",
  templateUrl: "date-range.component.html",
  styleUrls: ["date-range.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent {
  @Input() public startDate: Date | number;
  @Input() public endDate: Date | number;
  @Input() public timeZone: string;

  public zonedStartDate: Date;
  public zonedEndDate: Date;

  public label: string;
}
