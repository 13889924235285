import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { mergeMap, switchMap, catchError, map } from "rxjs/operators";
import { loadForSalons, loadForSalonsSuccess, loadForSalonsFail } from "./salon-api-version.actions";
import { of } from "rxjs";
import { SalonApiVersionService } from "app/kernel";

@Injectable()
export class SalonApiVersionEffects {
  public init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadForSalons),
      map(({ salons }) => salons.map((salon) => salon._id)),
      switchMap((salonIds) => this._salonApiVersionService.findForSalonIds(salonIds)),
      mergeMap((salonApiVersions) => [loadForSalonsSuccess({ salonApiVersions })]),
      catchError((error) => of(loadForSalonsFail({ error })))
    )
  );

  constructor(
    private _salonApiVersionService: SalonApiVersionService,
    private _actions$: Actions
  ) {}
}
