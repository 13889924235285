import { pipe } from "ramda";
import { startOfDay, endOfDay, getTime } from "date-fns";
import { addDays, subDays } from "date-fns/fp";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz/fp";
import { isSameDay } from "date-fns";

/**
 * dates in Javascript/the browser are, well, weird
 * if you naively created a Date you'll get back a date/time, but it will be created
 * given the current time/date of the user's browser, in their browser's time zone
 * but if we want to render dates in some other time zone, we'll need to do a bit of work
 * this function takes a Date object, convert it to some given time zone
 * and then return a pair of timestamps representing a start and end date for some range of days
 * but will represent the local time in whatever time zone has been provided, instead
 * of the time zone of the user's browser
 *
 */
export const getUtcDateRangeDaysFromZonedTime = (
  timeZone: string,
  numDays: number,
  endDate: Date = new Date()
): { startDate: number; endDate: number } => {
  const currentZonedDate = utcToZonedTime(timeZone)(endDate);

  const endDateUtc = pipe(endOfDay, zonedTimeToUtc(timeZone), getTime)(currentZonedDate);

  const startDateUtc = pipe(startOfDay, subDays(numDays), zonedTimeToUtc(timeZone), getTime)(currentZonedDate);

  return {
    startDate: startDateUtc,
    endDate: endDateUtc,
  };
};

export const getStartOfDayInTimeZone = (timeZone: string, date: Date = new Date()) =>
  pipe(startOfDay, zonedTimeToUtc(timeZone))(utcToZonedTime(timeZone)(date));

export const getEndOfDayInTimeZone = (timeZone: string, date: Date = new Date()) =>
  pipe(endOfDay, zonedTimeToUtc(timeZone))(utcToZonedTime(timeZone)(date));

export const getNextDayInTimeZone = (timeZone: string, date: Date = new Date()) =>
  pipe(utcToZonedTime(timeZone), startOfDay, addDays(1), zonedTimeToUtc(timeZone))(date);

export const getTodayInTimeZone = (timeZone: string) => getDateInTimeZone(timeZone, new Date());

export const isTodayInTimeZoneFromUTC = (date: Date | number, timeZone: string): boolean => {
  const todayInTimeZone = pipe(utcToZonedTime(timeZone), startOfDay)(new Date());

  return isSameDay(todayInTimeZone, utcToZonedTime(timeZone)(date));
};

export const getDateInTimeZone = (timeZone: string, date: Date) =>
  pipe(utcToZonedTime(timeZone), startOfDay, zonedTimeToUtc(timeZone))(date);
