import { Injectable } from "@angular/core";
import {
  ProductAllowance,
  ProductAllowanceBlueprint,
  ProductCategoryModule,
  SalonProduct,
  ServiceDescription,
  TargetWeightType,
} from "@getvish/model";
import { Option, fold, fromNullable, none, some, toUndefined } from "fp-ts/Option";
import { Observable, forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { v4 as uuid } from "uuid";

import { ProductService } from "app/+product/+products/services";

import { ProductAllowanceBlueprintVM, ProductAllowanceProduct, ProductAllowanceVM } from "app/kernel/models/product-allowance";

import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { ManufacturerService } from "app/+product/+manufacturers/services";
import { ProductCategoryService } from "app/+product/+product-categories/services";
import { SalonProductService } from "app/+product/+salon-products";
import { Either } from "fp-ts/lib/Either";
import { flatten, splitEvery, uniq } from "ramda";
import { calculateProductAllowanceTotal } from "../utils";

@Injectable()
export class ProductAllowanceService extends EntityService<ProductAllowance> {
  public constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _productService: ProductService,
    private _salonProductService: SalonProductService,
    private _manufacturerService: ManufacturerService,
    private _productCategoryService: ProductCategoryService
  ) {
    super(repositoryFactory, { entityKey: "productAllowances" });
  }

  public findByServiceMenuItemIds(ids: string[]): Observable<ProductAllowance[]> {
    const uniqueIds = uniq(ids);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((ids) => {
      const criteria = { serviceMenuItemId: { $in: ids } };

      return this.find(criteria).pipe(map((result) => result.records));
    });

    return forkJoin(requests).pipe(map(flatten));
  }

  public getProductAllowance(serviceDescription: Option<ServiceDescription>): Observable<Option<ProductAllowanceVM>> {
    return fold<ServiceDescription, Observable<Option<ProductAllowanceVM>>>(
      () => of(none),
      (sd) => this._getProductAllowanceVM(sd)
    )(serviceDescription);
  }

  public getProductAllowances(serviceDescriptions: ServiceDescription[]): Observable<{ [serviceId: string]: ProductAllowanceVM }> {
    if (!serviceDescriptions.length) {
      return of({});
    }

    return this.findByServiceMenuItemIds(serviceDescriptions.map((sd) => sd._id)).pipe(
      switchMap((blueprints) => {
        return this._getBlueprintProducts(blueprints).pipe(
          map((products) =>
            blueprints.reduce((acc, bp) => {
              const blueprints = this._convertBlueprintBowls(bp.blueprints, products);

              if (blueprints != null) {
                acc[bp.serviceMenuItemId] = { blueprints, isActive: bp.isActive };
              }

              return acc;
            }, {})
          )
        );
      })
    );
  }

  private _getProductAllowanceVM(serviceDescription: ServiceDescription): Observable<Option<ProductAllowanceVM>> {
    return this.findOne({ serviceMenuItemId: serviceDescription._id }).pipe(
      switchMap((productAllowance) =>
        fold(
          () => of(none),
          (pa: ProductAllowance) =>
            this._getBlueprintProducts([pa]).pipe(
              map((products) => {
                const blueprints = this._convertBlueprintBowls(pa.blueprints, products);

                return blueprints.length ? some({ blueprints, isActive: pa.isActive }) : none;
              })
            )
        )(productAllowance)
      )
    );
  }

  private _getBlueprintProducts(blueprints: ProductAllowance[]) {
    const productIds = blueprints.reduce((acc, bp) => {
      bp.blueprints?.forEach((bowl) => {
        bowl.ingredients
          .reduce((_acc, i) => [..._acc, i.productId], [])
          .filter((id) => id !== null)
          .forEach((id) => acc.add(id));
      });

      return acc;
    }, new Set<string>());

    const products$ =
      productIds.size === 0
        ? of({ records: [] })
        : this._productService.find({
            _id: { $in: [...productIds] },
          });

    const salonProducts$ =
      productIds.size === 0
        ? of({ records: [] })
        : this._salonProductService.find({
            productId: { $in: [...productIds] },
          });

    return forkJoin([products$, salonProducts$]).pipe(
      map(([products, salonProducts]) => ({
        products,
        salonProducts: salonProducts.records.reduce((acc, sp) => ({ ...acc, [sp.productId]: sp }), {}),
      })),
      map(({ products, salonProducts }) =>
        products.records.reduce((acc, p) => ({ ...acc, [p._id]: { ...p, ...salonProducts[p._id] } }), {})
      ),
      switchMap((products) => this._populateManufacturersAndCategories(products))
    );
  }

  private _populateManufacturersAndCategories(products: { [key: string]: ProductAllowanceProduct }) {
    if (!Object.keys(products).length) {
      return of({});
    }

    const { manufacturerIds } = Object.values(products).reduce(
      (acc, p) => {
        if (p.manufacturerId) {
          acc.manufacturerIds.add(p.manufacturerId);
        }

        return acc;
      },
      { manufacturerIds: new Set<string>(), categoryIds: new Set<string>() }
    );

    const categories$ = this._productCategoryService.find({
      manufacturerId: { $in: [...manufacturerIds] },
    });
    const manufacturers$ = this._manufacturerService.find({
      _id: { $in: [...manufacturerIds] },
    });

    return forkJoin([categories$, manufacturers$]).pipe(
      map(([categories, manufacturers]) => ({
        categories: categories.records.reduce((acc, c) => ({ ...acc, [c._id]: c }), {}),
        manufacturers: manufacturers.records.reduce((acc, m) => ({ ...acc, [m._id]: m }), {}),
      })),
      map(({ categories, manufacturers }) =>
        Object.entries(products).reduce(
          (acc, [id, p]) => ({
            ...acc,
            [id]: {
              ...p,
              manufacturer: p.manufacturerId ? manufacturers[p.manufacturerId] : undefined,
              category: p.categoryId ? categories[p.categoryId] : undefined,
              rootCategory: toUndefined(
                ProductCategoryModule.getRootCategory(fromNullable(categories[p.categoryId]), Object.values(categories))
              ),
            },
          }),
          {}
        )
      )
    );
  }

  private _convertBlueprintBowls(
    blueprints: ProductAllowanceBlueprint[],
    products: { [key: string]: ProductAllowanceProduct }
  ): ProductAllowanceBlueprintVM[] {
    return blueprints
      .map((b) => ({
        _id: uuid(),
        pigments: b.ingredients
          .filter((i) => i.targetWeightMetadata?._type === TargetWeightType.ABSOLUTE)
          .map((i) => ({
            product: products[i.productId],
            weight: i.targetWeightMetadata,
            type: i.metadata.productType,
          }))
          .filter((i) => i.product != null),
        developers: b.ingredients
          .filter((i) => i.targetWeightMetadata?._type === TargetWeightType.RATIO)
          .map((i) => ({
            product: products[i.productId],
            weight: i.targetWeightMetadata,
            type: i.metadata.productType,
          }))
          .filter((i) => i.product != null),
      }))
      .filter((b) => b.pigments?.length || b.developers?.length);
  }

  public salonProductsToPAProduct(salonProducts: SalonProduct[]): Observable<Option<ProductAllowanceProduct[]>> {
    if (!salonProducts?.length) {
      return of(none);
    }

    const productIds = salonProducts?.map((sp) => sp.productId);
    const products$ = this._productService.find({
      _id: { $in: [...productIds] },
    });

    return forkJoin([products$, of(salonProducts)]).pipe(
      map(([products, salonProducts]) => ({
        products,
        salonProducts: salonProducts.reduce((acc, sp) => ({ ...acc, [sp.productId]: sp }), {}),
      })),
      map(({ products, salonProducts }) =>
        products.records.reduce(
          (acc, p) => ({
            ...acc,
            [p._id]: { ...p, ...salonProducts[p._id] },
          }),
          {}
        )
      ),
      switchMap((products) => this._populateManufacturersAndCategories(products)),
      map((products) => some(Object.values(products) as ProductAllowanceProduct[]))
    );
  }

  public saveProductAllowance(
    productAllowance: ProductAllowanceVM,
    serviceDescriptions: ServiceDescription[]
  ): Observable<Either<Error, ProductAllowance>[]> {
    const blueprints = productAllowance?.blueprints?.map((bp) => ({
      _id: bp._id ?? uuid(),
      ingredients: [...(bp.pigments ?? []), ...(bp.developers ?? [])].map((i) => ({
        productId: i.product.productId,
        targetWeightMetadata: i.weight,
        metadata: {
          productType: i.type,
        },
      })),
    }));

    const allowance = calculateProductAllowanceTotal(productAllowance);

    return this.findByServiceMenuItemIds(serviceDescriptions.map((sd) => sd._id)).pipe(
      map((blueprints) => blueprints.reduce((acc, bp) => ({ ...acc, [bp.serviceMenuItemId]: bp._id }), {})),
      map((serviceBlueprints) =>
        serviceDescriptions.reduce(
          (acc, sd) => {
            if (serviceBlueprints[sd._id]) {
              acc.updates.push({
                _id: serviceBlueprints[sd._id],
                serviceMenuItemId: sd._id,
                blueprints,
                allowance,
              });
            } else {
              acc.inserts.push({
                serviceMenuItemId: sd._id,
                blueprints,
                allowance,
              });
            }

            return acc;
          },
          { inserts: [], updates: [] }
        )
      ),
      switchMap((operations) =>
        forkJoin([...operations.inserts.map((i) => this.insert(i)), ...operations.updates.map((u) => this.update(u))])
      )
    );
  }
}
