import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductCategory } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { back } from "app/kernel/store/actions/router.actions";
import { isDefined } from "app/kernel/util";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

import * as ProductCategoryActions from "../store/product-category.actions";
import * as fromProductCategory from "../store/product-category.selectors";

@Component({
  selector: "edit-product-category-container",
  templateUrl: "edit-product-category.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProductCategoryContainer {
  public productCategory$: Observable<ProductCategory>;
  public productCategories$: Observable<ProductCategory[]>;
  public selectedParentCategoryId$: Observable<string>;
  public newMetadata$: Observable<{ manufacturerId?: string; parentCategoryId?: string }>;
  public manufacturerId$: Observable<string>;
  public parentCategoryId$: Observable<string>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute,
    _router: Router
  ) {
    this.productCategories$ = _store.pipe(select(fromProductCategory.getAll));
    this.newMetadata$ = _store.pipe(select(fromProductCategory.getNewMetadata));

    this.manufacturerId$ = this.newMetadata$.pipe(map((data) => data?.manufacturerId));
    this.parentCategoryId$ = this.newMetadata$.pipe(map((data) => data?.parentCategoryId));

    this.productCategory$ = _route.paramMap.pipe(
      map((params) => params.get("id")),
      mergeMap((id) => (isDefined(id) ? _store.pipe(select(fromProductCategory.get(id))) : of(new ProductCategory())))
    );
  }

  public save(productCategory: ProductCategory): void {
    this._store.dispatch(ProductCategoryActions.addOrUpdate(productCategory));
  }

  public close(): void {
    this._store.dispatch(back());
  }
}
