<dialog-contents
  dialogTitle="Change Category Order"
  (close)="close()">
  <div
    class="controls"
    dialog-controls>
    <mat-menu #moreOptionsMenu="matMenu">
      <button
        (click)="sortAlphabetical()"
        mat-menu-item>
        Sort Alphabetical
      </button>
      <button
        (click)="sortReverseAlphabetical()"
        mat-menu-item>
        Sort Reverse Alphabetical
      </button>
    </mat-menu>
    <button
      mat-icon-button
      [mat-menu-trigger-for]="moreOptionsMenu"
      class="button transparent">
      <fa-icon icon="ellipsis-v"></fa-icon>
    </button>
  </div>
  <div class="content">
    <span>Drag and drop the categories below to the desired order.</span>
    <list-orderer (orderChanged)="orderChanged($event)">
      <list-orderer-item
        *ngFor="let item of items"
        [id]="item.id"
        [text]="item.text">
      </list-orderer-item
    ></list-orderer>
  </div>
  <div
    class="footer-buttons"
    dialog-footer>
    <button
      class="button small"
      (click)="save()">
      Save
    </button>
    <button
      class="button small outline secondary"
      (click)="close()">
      Cancel
    </button>
  </div>
</dialog-contents>
