import { Component, Input, OnChanges, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { ReportStatistic } from "../../kernel/models/report-statistic";
import { map, take, isNil, sortBy, reverse, pipe, head, filter, not } from "ramda";
import { MeasurementUnit } from "@getvish/model";
import { ManufacturerReport, manufacturerReportIsEmpty } from "../../kernel/models";
import { colorScheme } from "../common";
import { getDateInTimeZone, getTodayInTimeZone } from "app/kernel/util/zoned-time-utils";

type ChartData = { name: string; value: number };

@Component({
  selector: "manufacturer-report",
  templateUrl: "manufacturer-report.component.html",
  styleUrls: ["manufacturer-report.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerReportComponent implements OnChanges, OnInit {
  @Input() public manufacturerReport: ManufacturerReport;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public loading: boolean;
  @Input() public startDate: number;
  @Input() public endDate: number;
  @Input() public currency: string;
  @Input() public timeZone: string;

  @Output() public selectManufacturer: EventEmitter<string>;
  @Output() public updateFilters: EventEmitter<{ startDate: number; endDate: number }>;

  public data: ChartData[] = [];
  public filteredStatistics: ReportStatistic[];
  public selected: ReportStatistic;

  public colorScheme = colorScheme;

  public minDate: Date;
  public maxDate: Date;
  public reportHasData: boolean;

  constructor() {
    this.updateFilters = new EventEmitter(true);
    this.selectManufacturer = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.minDate = getDateInTimeZone(this.timeZone, new Date(2000, 0, 1));
    this.maxDate = getTodayInTimeZone(this.timeZone);
  }

  public ngOnChanges(): void {
    const report = this.manufacturerReport;

    if (isNil(report)) {
      return;
    }

    const sortItems = sortBy((item: ReportStatistic) => item.metrics.wholesaleCostDollars);
    const reverseItems = (items: ReportStatistic[]) => reverse(items);
    const takeFive = (items: ReportStatistic[]) => take(5)(items);
    const isUsed = (item: ReportStatistic) => item.metrics.usedAmountGrams > 0;

    const mapWithUsageRatio = (total: number) => (_statistics: ReportStatistic[]) =>
      map((item: ReportStatistic) => {
        const usageRatio = total > 0 ? item.metrics.wholesaleCostDollars / total : 0;

        const updatedMetrics = {
          ...item.metrics,
          usageRatio,
        };

        return {
          ...item,
          metrics: updatedMetrics,
        };
      })(_statistics);

    const totalUsage = report.manufacturerStatistics.reduce((acc, curr) => {
      return acc + curr.metrics.wholesaleCostDollars;
    }, 0);

    const sortedStatistics = pipe(sortItems, reverseItems, takeFive, mapWithUsageRatio(totalUsage))(report.manufacturerStatistics);
    this.data = this._mapStatisticsToChartData(sortedStatistics);
    this.filteredStatistics = pipe(filter(isUsed), mapWithUsageRatio(totalUsage))(report.manufacturerStatistics);
    this.selected = head(this.filteredStatistics);

    this.reportHasData = not(manufacturerReportIsEmpty(this.manufacturerReport));
  }

  public onSelect(event: ChartData): void {
    const selectedName = event.name;
    this.selected = this.filteredStatistics.find((item) => item.entityName === selectedName);
  }

  public visitManufacturer(manufacturerId: string): void {
    this.selectManufacturer.emit(manufacturerId);
  }

  public updateDateRange(event: { startDate: number; endDate: number }): void {
    this.updateFilters.emit(event);
  }

  private _mapStatisticsToChartData(statistics: ReportStatistic[]): ChartData[] {
    return statistics.map((statistic) => ({ name: statistic.entityName, value: statistic.metrics.wholesaleCostDollars }));
  }
}
