import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { MeasurementUnit } from "@getvish/model";
import { ProductCategoryVM } from "app/+product/+salon-products/common";
import { ProductSelectionVM } from "../../models/product-selection-vm";

import * as R from "ramda";

@Component({
  selector: "select-products-grid",
  templateUrl: "./select-products-grid.component.html",
  styleUrls: ["./select-products-grid.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectProductsGridComponent implements OnChanges {
  @Input() public products: ProductSelectionVM[];
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public currency: string;
  @Input() public disableSelectAll: boolean = false;
  @Input() public showInactive: boolean = true;

  @Output() public toggleProduct: EventEmitter<ProductSelectionVM>;
  @Output() public selectProducts: EventEmitter<ProductSelectionVM[]>;
  @Output() public deselectProducts: EventEmitter<ProductSelectionVM[]>;

  public productGroupings: Array<{
    category: ProductCategoryVM;
    products: ProductSelectionVM[];
    allSelected: boolean;
    alreadyImported: boolean;
  }>;

  public trackByGroup = (_: number, value: { category: ProductCategoryVM; products: ProductSelectionVM[] }): string => value.category._id;
  public trackById = (_: number, value: ProductSelectionVM): string => value.product._id;

  constructor() {
    this.toggleProduct = new EventEmitter(true);
    this.selectProducts = new EventEmitter(true);
    this.deselectProducts = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    const uniqueCategories = R.uniq(R.flatten(this.products.map((product) => product.availableCategories)));

    const sortFunc = (c1?: { order?: number; name: string }, c2?: { order?: number; name: string }) => {
      const s = (c1?.order ?? Number.MAX_SAFE_INTEGER) - (c2?.order ?? Number.MAX_SAFE_INTEGER);

      if (s === 0) {
        return (c1?.name ?? "").localeCompare(c2?.name ?? "", undefined, { sensitivity: "base" });
      }

      return s;
    };

    const sortedCategories = R.sort((c1: ProductCategoryVM, c2: ProductCategoryVM) => {
      const s = sortFunc(c1.rootCategory, c2.rootCategory);

      if (s === 0) {
        return sortFunc(c1, c2);
      }

      return s;
    }, uniqueCategories);

    this.productGroupings = sortedCategories
      .map((category) => {
        const productsForCategory = R.sort(
          (p1: ProductSelectionVM, p2: ProductSelectionVM) => {
            return sortFunc(p1.product, p2.product);
          },
          this.products.filter((productVM) => productVM.product.categoryId == category._id && (this.showInactive || !productVM.inactive))
        );

        return {
          category,
          products: productsForCategory,
          allSelected: productsForCategory.every((product) => product.selected || product.inactive),
          alreadyImported: productsForCategory.every((product) => product.inactive),
        };
      })
      .filter((g) => g.products.length);
  }

  public onToggleProduct(product: ProductSelectionVM): void {
    if (R.not(product.inactive)) {
      this.toggleProduct.emit(product);
    }
  }
}
