import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { ActionReducerMap } from "@ngrx/store";

import * as app from "./app.reducer";
import * as common from "./common.reducer";
import * as ui from "./ui.reducer";
import * as auth from "../../../+auth/store/auth.reducer";
import * as customer from "../../../+customers/store/customer.reducer";
import * as employee from "../../../+employees/store/employee.reducer";
import * as salon from "../../../+salons/store/salon.reducer";
import * as salonConfig from "../../../+salon-config/store/salon-config.reducer";
import * as device from "../../../+devices/store/device.reducer";
import * as websocket from "../reducers/websocket.reducer";
import * as serviceMenu from "../../../+service-menu/store/service-menu.reducer";
import * as admin from "./admin.reducer";
import * as currentTenant from "./current-tenant.reducer";

export interface AppState {
  [app.featureKey]: app.State;
  common: common.State;
  routerReducer: RouterReducerState;
  ui: ui.UiState;
  auth: auth.State;
  customers: customer.CustomerState;
  employees: employee.EmployeeState;
  salons: salon.SalonState;
  salonConfig: salonConfig.SalonConfigState;
  devices: device.DeviceState;
  serviceMenu: serviceMenu.ServiceMenuState;
  websocket: websocket.WebsocketState;
  admin: admin.AdminState;
  currentTenant: currentTenant.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [app.featureKey]: app.reducer,
  common: common.reducer,
  routerReducer,
  ui: ui.uiReducer,
  auth: auth.reducer,
  customers: customer.customerReducer,
  employees: employee.employeeReducer,
  salons: salon.salonReducer,
  salonConfig: salonConfig.salonConfigReducer,
  devices: device.deviceReducer,
  serviceMenu: serviceMenu.serviceMenuReducer,
  websocket: websocket.websocketReducer,
  admin: admin.reducer,
  currentTenant: currentTenant.reducer,
};
