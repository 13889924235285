import { Appointment, Order } from "@getvish/model";
import { Action, createAction, props, union } from "@ngrx/store";
import { AppointmentVM } from "../models/appointment";

export enum Types {
  GENERATE_SUCCESS = "[Order] Generate Success",
  GENERATE_FAIL = "[Order] Generate Fail",
  CHECKOUT_SUCCESS = "[Order] Checkout Success",
  NAVIGATE_TO_ADJUST_TOTAL = "[Order] Navigate to Adjust Total",
  SET_ADJUST_TOTAL_SUCCESS = "[Order] Set Adjust Total Success",
  REACTIVATE_SUCCESS = "[Order] Reactivate Success",
  RESOLVE_SUCCESS = "[Order] Resolve Success",
}

export const navigateToOrder = createAction("[Order] Navigate to Order", props<{ appointment: Appointment }>());

export const generate = createAction("[Order] Generate", props<{ appointment: Appointment }>());

export const load = createAction("[Order] Load", props<{ id: string }>());

export const loadFail = createAction("[Order] Load Fail", props<{ error: string }>());

export const loadSuccess = createAction("[Order] Load Success", props<{ payload: Order }>());

export const generateOrLoadForAppointment = createAction("[Order] Generate or Load for Appointment", props<{ appointmentId: string }>());

export const checkout = createAction("[Order] Checkout", props<{ order: Order }>());

export const checkoutFail = createAction("[Order] Checkout Fail", props<{ error: Error }>());

export const done = createAction("[Order] Done");

export class GenerateSuccess implements Action {
  public readonly type = Types.GENERATE_SUCCESS;

  constructor(public payload: { appointment: AppointmentVM; order: Order }) {}
}

export class GenerateFail implements Action {
  public readonly type = Types.GENERATE_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class CheckoutSuccess implements Action {
  public readonly type = Types.CHECKOUT_SUCCESS;

  constructor(public payload: Order) {}
}

export const reactivate = createAction("[Order] Reactivate", props<{ order: Order }>());

export const reactivateFail = createAction("[Order] Reactivate Fail", props<{ error: Error }>());

export class ReactivateSuccess implements Action {
  public readonly type = Types.REACTIVATE_SUCCESS;

  constructor(public payload: Order) {}
}

const actions = union({
  navigateToOrder,
  generate,
  generateOrLoadForAppointment,
  checkout,
  done,
  reactivate,
  reactivateFail,
  load,
  loadFail,
  loadSuccess,
});

export type Actions = typeof actions | GenerateFail | GenerateSuccess | CheckoutSuccess | ReactivateSuccess;
