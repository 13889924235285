import { Injectable } from "@angular/core";
import { EntityService, HttpRepositoryFactory, JsonObject, PagedResult } from "@getvish/stockpile";
import { ProductCategory } from "@getvish/model";
import { Observable, catchError, forkJoin, map, of } from "rxjs";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";
import { Either, left, right } from "fp-ts/lib/Either";

@Injectable()
export class ProductCategoryService extends EntityService<ProductCategory> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "productCategories" });
  }

  public findForManufacturer(manufacturerId: string): Observable<PagedResult<ProductCategory>> {
    const criteria = { manufacturerId };

    return this.find(criteria);
  }

  public findForManufacturers(manufacturerIds: string[], criteria?: JsonObject): Observable<ProductCategory[]> {
    const uniqueIds = uniq(manufacturerIds);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) =>
      this.find({ manufacturerId: { $in: _ids }, ...(criteria ?? {}) }).pipe(map((result) => result.records))
    );

    return isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(flatten));
  }

  public updateMany(productCategories: ProductCategory[]): Observable<Either<Error, ProductCategory[]>> {
    return forkJoin(productCategories.map((pc) => this.updateOrDie(pc))).pipe(
      map(() => right(productCategories)),
      catchError((e) => of(left(e)))
    );
  }
}
