import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, OnInit } from "@angular/core";
import { SalonReport, EmployeeReport, ServiceReport, LongitudinalReport, salonReportIsEmpty } from "../../kernel/models";
import { MeasurementUnit } from "@getvish/model";
import { not } from "ramda";
import { getDateInTimeZone, getTodayInTimeZone } from "app/kernel/util/zoned-time-utils";

@Component({
  selector: "dashboard",
  styleUrls: ["./dashboard.component.less"],
  templateUrl: "./dashboard.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnChanges, OnInit {
  @Input() public salonReport: SalonReport;
  @Input() public employeeReports: EmployeeReport[];
  @Input() public serviceReports: ServiceReport[];
  @Input() public longitudinalReport: LongitudinalReport;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public aggregatedBy: string;
  @Input() public loading: boolean;
  @Input() public error: string;
  @Input() public startDate: number;
  @Input() public endDate: number;
  @Input() public currency: string;
  @Input() public locale: string;
  @Input() public timeZone: string;
  @Input() public enableComplianceTracking: boolean;

  @Output() public aggregation: EventEmitter<string>;
  @Output() public downloadEmployeePerformanceReport: EventEmitter<void>;
  @Output() public downloadServiceReport: EventEmitter<{ showExcludedServices: boolean }>;
  @Output() public updateDateRange: EventEmitter<{ startDate: number; endDate: number }>;
  @Output() public navigateToUnmixed: EventEmitter<{ employeeId?: string; serviceId?: string }>;
  @Output() public navigateToMixed: EventEmitter<{ employeeId?: string; serviceId?: string }>;

  public showExcludedServices: boolean;

  public minDate: Date;
  public maxDate: Date;
  public reportHasData: boolean;

  constructor() {
    this.aggregation = new EventEmitter();
    this.downloadEmployeePerformanceReport = new EventEmitter(true);
    this.downloadServiceReport = new EventEmitter(true);
    this.updateDateRange = new EventEmitter();
    this.navigateToUnmixed = new EventEmitter();
    this.navigateToMixed = new EventEmitter();
  }

  public ngOnInit(): void {
    this.maxDate = getTodayInTimeZone(this.timeZone);
    this.minDate = getDateInTimeZone(this.timeZone, new Date(2000, 0, 1));
  }

  public ngOnChanges(): void {
    this.reportHasData = not(salonReportIsEmpty(this.salonReport));
  }

  public toggleShowExcludedServices(): void {
    this.showExcludedServices = !this.showExcludedServices;
  }

  public isSalonReport(salonReport: SalonReport): boolean {
    return salonReport ? salonReport.employeeIds.length > 0 : false;
  }

  public updateDates(event: { startDate: number; endDate: number }): void {
    this.updateDateRange.emit(event);
  }
}
