import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, filter, map, Observable, of, startWith, Subject, Subscription, switchMap } from "rxjs";
import { Notification } from "../models";
import { flatten } from "ramda";

export interface NotificationsWithProvider {
  providerId?: string;
  notifications: Notification[];
}

@Injectable()
export abstract class NotificationService {
  private subscription?: Subscription;
  private notificationObservers: Observable<NotificationsWithProvider>[] = [];

  private notifications$: Subject<Notification[]> = new BehaviorSubject<Notification[]>([]);

  public getAllNotifications(): Observable<Notification[]> {
    return this.notifications$;
  }

  public registerNotificationObservable(providerId: string, notifications$: Observable<Notification[]>): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }

    this.notificationObservers.push(
      notifications$.pipe(
        filter((notifications) => notifications != null),
        map((notifications) => ({ providerId, notifications: notifications.map((notification) => ({ ...notification, providerId })) })),
        startWith({ notifications: [] })
      )
    );

    this.subscription = combineLatest(this.notificationObservers)
      .pipe(
        map(flatten),
        switchMap((notificationsWithProviders) => this.preprocessNotifications(notificationsWithProviders)),
        map((notificationsWithProviders) =>
          notificationsWithProviders.map((notificationsWithProvider) => notificationsWithProvider.notifications)
        ),
        map(flatten)
      )
      .subscribe((notifications) => {
        this.notifications$.next(notifications);
      });
  }

  protected preprocessNotifications(notificationsWithProviders: NotificationsWithProvider[]): Observable<NotificationsWithProvider[]> {
    return of(notificationsWithProviders);
  }

  public abstract markAsRead(notifications: Notification[]): Observable<void>;
}
