import { NgModule, ModuleWithProviders, Optional, SkipSelf } from "@angular/core";
import { WindowService, LocalStorage, KernelServicesModule } from "./services";
import { CoreStoreModule } from "./store";
import { SalonApiVersionModule } from "./+salon-api-version/salon-api-version.module";
import { TenantEntitlementsModule } from "./+tenant-entitlements/tenant-entitlements.module";

@NgModule({
  declarations: [],
  imports: [CoreStoreModule.forRoot(), KernelServicesModule.forRoot(), SalonApiVersionModule, TenantEntitlementsModule],
})
export class KernelModule {
  public static forRoot(): ModuleWithProviders<KernelModule> {
    return {
      ngModule: KernelModule,
      providers: [WindowService, LocalStorage],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: KernelModule) {
    if (parentModule) {
      throw new Error("KernelModule is already loaded. Import it in the main AppModule only");
    }
  }
}
