import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorage {
  private _localStorage = localStorage;

  public setItem(key: string, value: any): any {
    return this._localStorage.setItem(key, value);
  }

  public getItem(key: string): any {
    return this._localStorage.getItem(key);
  }

  public removeItem(key: string): any {
    return this._localStorage.removeItem(key);
  }
}
