import { createReducer, on } from "@ngrx/store";

import * as actions from "./edit-services.actions";

export interface EditServicesState {
  saving: boolean;
}

const initialState: EditServicesState = {
  saving: false,
};

export const editServicesReducer = createReducer(
  initialState,
  on(actions.editServices, (state) => ({ ...state, saving: true })),
  on(actions.editServicesSuccess, (state) => ({ ...state, saving: false }))
);
