import { Injectable } from "@angular/core";
import { Action, Store, select } from "@ngrx/store";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { tap, map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { fold } from "fp-ts/Either";
import { LoginResponse } from "../../kernel/models";
import { go } from "../../kernel/store/actions/router.actions";
import { AuthService } from "../services";
import { getSlug } from "../../kernel/store/reducers/common.reducer";
import { AppState } from "../../kernel";
import { navigateHome } from "../../kernel/store/actions/app.actions";

import * as O from "fp-ts/Option";
import * as auth from "./auth.actions";
import * as ResetPasswordActions from "../../+reset-password/store/reset-password.actions";

@Injectable()
export class AuthEffects {
  public init$ = createEffect(() =>
    this._actions$.pipe(
      ofType<auth.Init>(auth.Types.INIT),
      map(() => new auth.CheckForAuthToken())
    )
  );

  public checkForAuthToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType<auth.CheckForAuthToken>(auth.Types.CHECK_FOR_AUTH_TOKEN),
      switchMap(() =>
        this._authService.getAuthToken().pipe(
          map(
            O.fold<string, Action>(
              () => new auth.NavigateToLogin(),
              (authToken) => new auth.LoginFromAuthToken({ authToken })
            )
          )
        )
      )
    )
  );

  public navigateToLoginComponent$ = createEffect(() =>
    this._actions$.pipe(ofType<auth.NavigateToLogin>(auth.Types.NAVIGATE_TO_LOGIN), mapTo(go({ path: ["/login"] })))
  );

  public login$ = createEffect(() =>
    this._actions$.pipe(
      ofType<auth.Login>(auth.Types.LOGIN),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getSlug))),
      switchMap(([payload, slug]) =>
        this._authService.login(payload.username, payload.password, slug).pipe(
          map(
            fold<string, LoginResponse, Action>(
              (reason) => new auth.LoginFail({ reason }),
              (payload) => new auth.LoginSuccess({ authToken: payload.authToken, user: payload.user, salon: payload.tenant })
            )
          )
        )
      )
    )
  );

  public loginFromAuthToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType<auth.LoginFromAuthToken>(auth.Types.LOGIN_FROM_AUTH_TOKEN),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getSlug))),
      switchMap(([{ authToken }, slug]) =>
        this._authService.loginFromAuthToken(authToken, slug).pipe(
          map(
            fold<string, LoginResponse, Action>(
              (reason) => new auth.LoginFromAuthTokenFail({ reason }),
              (response) =>
                new auth.LoginFromAuthTokenSuccess({ authToken: response.authToken, user: response.user, salon: response.tenant })
            )
          )
        )
      )
    )
  );

  public loginFromAuthTokenFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<auth.LoginFromAuthTokenFail>(auth.Types.LOGIN_FROM_AUTH_TOKEN_FAIL),
      switchMap(() => this._authService.clearLoginData().pipe(map(() => new auth.NavigateToLogin())))
    )
  );

  public loginSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<auth.LoginSuccess>(auth.Types.LOGIN_SUCCESS),
      map((action) => action.payload),
      switchMap((payload) => this._authService.handleLoginSuccess(payload.user, payload.authToken).pipe(map(() => navigateHome())))
    )
  );

  public loginFromAuthTokenSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<auth.LoginFromAuthTokenSuccess>(auth.Types.LOGIN_FROM_AUTH_TOKEN_SUCCESS),
        map((action) => action.payload),
        switchMap(({ authToken, user }) => this._authService.handleLoginSuccess(user, authToken))
      ),
    { dispatch: false }
  );

  public logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(auth.Types.LOGOUT),
      tap(() => this._authService.logout()),
      mapTo(new auth.NavigateToLogin())
    )
  );

  public forgotPassword$ = createEffect(() =>
    this._actions$.pipe(
      ofType(auth.Types.FORGOT_PASSWORD),
      map(() => new ResetPasswordActions.Navigate())
    )
  );

  constructor(
    private _authService: AuthService,
    private _actions$: Actions,
    private _store: Store<AppState>
  ) {}
}
