import { createAction, props } from "@ngrx/store";
import { Notification } from "../models";

export const loadNotifications = createAction("[Notifications] Load Notifications");

export const loadNotificationsSuccess = createAction(
  "[Notifications] Load Notifications Success",
  props<{ notifications: Notification[] }>()
);

export const loadNotificationsFail = createAction("[Notifications] Load Notifications Fail", props<{ error: Error }>());

export const markAsRead = createAction("[Notifications] Mark as Read", props<{ notifications: Notification[] }>());
export const markAsReadSuccess = createAction("[Notifications] Mark as Read Success", props<{ notifications: Notification[] }>());
