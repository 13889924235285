<mat-radio-button
  *ngIf="radio; else checkbox"
  class="cell-minimal-width cell-minimal-padding"
  [checked]="checked"
  [disabled]="disabled"
  (change)="_onChange()"></mat-radio-button>

<ng-template #checkbox>
  <mat-checkbox
    class="cell-minimal-width cell-minimal-padding"
    [checked]="checked"
    [disabled]="disabled"
    (click)="_onChange()"></mat-checkbox>
</ng-template>
