import { User } from "@getvish/model";

import * as auth from "./auth.actions";

export const featureKey = "auth";

export interface State {
  sessionId: string;
  salonId: string;
  authToken: string;
  error: string;
  loggingInFromAuthToken: boolean;
  loggingIn: boolean;
  user: User;
}

const initialState: State = {
  sessionId: undefined,
  salonId: undefined,
  authToken: undefined,
  error: undefined,
  loggingInFromAuthToken: undefined,
  loggingIn: false,
  user: undefined,
};

export function reducer(state: State = initialState, action: auth.Actions): State {
  switch (action.type) {
    case auth.Types.LOGIN: {
      return {
        ...state,
        loggingIn: true,
        error: null,
        user: null,
      };
    }

    case auth.Types.LOGIN_SUCCESS: {
      const loggingIn = false;
      const error = undefined;
      const user = action.payload.user;
      const authToken = action.payload.authToken._id;

      return {
        ...state,
        loggingIn,
        error,
        user,
        authToken,
      };
    }

    case auth.Types.LOGIN_FROM_AUTH_TOKEN_SUCCESS: {
      const loggingIn = false;
      const error = undefined;
      const user = action.payload.user;
      const authToken = action.payload.authToken._id;
      const loggingInFromAuthToken = false;

      return {
        ...state,
        loggingIn,
        error,
        user,
        authToken,
        loggingInFromAuthToken,
      };
    }

    case auth.Types.LOGIN_FAIL: {
      const error = action.payload.reason;
      const loggingIn = false;

      return {
        ...state,
        error,
        loggingIn,
      };
    }

    case auth.Types.LOGIN_FROM_AUTH_TOKEN: {
      const loggingInFromAuthToken = true;

      return {
        ...state,
        loggingInFromAuthToken,
      };
    }

    case auth.Types.AUTH_TOKEN_NOT_FOUND: {
      const loggingInFromAuthToken = false;

      return {
        ...state,
        loggingInFromAuthToken,
      };
    }

    case auth.Types.LOGIN_FROM_AUTH_TOKEN_FAIL: {
      const loggingInFromAuthToken = false;
      const error = action.payload.reason;

      return {
        ...state,
        loggingInFromAuthToken,
        error,
      };
    }

    case auth.Types.LOAD_SETTINGS_SUCCESS: {
      const authToken = action.payload.authToken;

      return {
        ...state,
        authToken,
      };
    }

    default: {
      return state;
    }
  }
}
