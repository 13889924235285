import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  EditSalonProductContainer,
  SalonProductManagementContainer,
  SalonProductsContainer,
  SetCategoryPricingContainer,
} from "./containers";
import { SetMarkupContainer } from "./containers/set-markup.container";
import { UploadSalonProductSpreadsheetContainer } from "./containers/upload-salon-product-spreadsheet.container";

export const routes = [
  { path: "", component: SalonProductsContainer, data: { breadcrumb: { label: "Products" } } },
  {
    path: "manufacturer/:manufacturerId",
    component: SalonProductManagementContainer,
    data: { breadcrumb: { label: "Manage" } },
  },
  { path: "manufacturer/markup", component: SetMarkupContainer, outlet: "panel" },
  { path: "new", component: EditSalonProductContainer, outlet: "panel" },
  { path: "edit/:id", component: EditSalonProductContainer, outlet: "panel" },
  { path: "category/:id/pricing", component: SetCategoryPricingContainer, outlet: "panel" },
  { path: "category/:id/markup", component: SetMarkupContainer, outlet: "panel" },
  { path: "upload", component: UploadSalonProductSpreadsheetContainer, outlet: "panel" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalonProductsRoutingModule {}
