<div
  *ngIf="!loading; else loadingElement"
  class="categories-container">
  <recursive-categories-list
    *ngIf="categories | notEmpty; else emptyState"
    [categoryNodes]="categoryNodes"
    [categoryMenuTemplate]="categoryMenuTemplate"
    [activeCategoryIds]="activeCategoryIds"
    [productListTemplate]="productListTemplate"
    (select)="select.emit($event)"
    (toggle)="toggleCategory.emit($event)"
    (addCategory)="addCategory.emit($event)"
    (addProduct)="addProduct.emit($event)"
    (editProduct)="editProduct.emit($event)"
    (select)="edit.emit($event)">
  </recursive-categories-list>
  <ng-template #emptyState>
    <empty-state-message
      title="No product categories"
      icon="tags">
      <p>We could not find any product categories, adjust the filter to view data</p>
    </empty-state-message>
  </ng-template>
</div>
<ng-template #loadingElement>
  <large-loading-indicator></large-loading-indicator>
</ng-template>
