import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { getDateInTimeZone, getTodayInTimeZone } from "app/kernel/util/zoned-time-utils";
import { endOfDay, getTime, startOfDay, subDays } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { isNil } from "ramda";

@Component({
  selector: "date-picker",
  templateUrl: "date-picker.component.html",
  styleUrls: ["date-picker.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnChanges, OnInit {
  @Input() public startDate: number;
  @Input() public endDate: number;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public timeZone: string;
  @Output() public update: EventEmitter<{ startDate: number; endDate: number }>;

  public _minDate: Date;
  public _maxDate: Date;

  public startControlDate: Date;
  public endControlDate: Date;

  constructor() {
    this.update = new EventEmitter(true);
  }

  public ngOnInit(): void {
    // I'm not really sure how sensible this is - but it was here before
    // so I'll leave it for the moment
    // basically if the caller doesn't provide a @minDate
    // we'll just set some defaults
    this._minDate = isNil(this.minDate) ? getDateInTimeZone(this.timeZone, new Date(2016, 0, 1)) : this.minDate;

    this._maxDate = isNil(this.maxDate) ? getTodayInTimeZone(this.timeZone) : this.maxDate;
  }

  public ngOnChanges(): void {
    // dates coming into this component should already be in UTC/have been converted to a particular time zone if necessary before they got here
    // or otherwise we can't really make any assumptions about what to do with those dates
    // (example: if the date we're consuming in this component was provided as a UTC timestamp, we'll want to respect that, assuming someone has asked for a specific timestamp)
    // BUT, because these controls will be consumed by Angular Material DatePicker components
    // we'll need to adjust the times, because by default they seem to expect Dates in the current time zone of the user's browser
    this.startControlDate = utcToZonedTime(new Date(this.startDate), this.timeZone);
    this.endControlDate = utcToZonedTime(new Date(this.endDate), this.timeZone);
  }

  public changeDateRange(aggregatedBy: string): void {
    const startDate = getTime(this._getStartDate(aggregatedBy));
    const endDate = this._getEndDate();

    this.update.emit({ startDate, endDate });
  }

  private _getEndDate(): number {
    const currentDateZoned = utcToZonedTime(new Date(), this.timeZone);
    const endOfToday = zonedTimeToUtc(endOfDay(currentDateZoned), this.timeZone);

    return getTime(endOfToday);
  }

  private _getStartDate(dateRange: string): Date {
    const currentDateZoned = utcToZonedTime(new Date(), this.timeZone);
    const startOfDayUtc = zonedTimeToUtc(startOfDay(currentDateZoned), this.timeZone);

    switch (dateRange) {
      case "TODAY": {
        return startOfDayUtc;
      }
      case "THIS_WEEK": {
        return subDays(startOfDayUtc, 7);
      }
      case "30_DAYS": {
        return subDays(startOfDayUtc, 30);
      }
      case "PREVIOUS_YEAR": {
        return subDays(startOfDayUtc, 365);
      }
      default: {
        throw new Error(`Unknown date range provided: ${dateRange}`);
      }
    }
  }

  private updateDateRange(startDate: number, endDate: number): void {
    this.update.emit({ startDate: startDate, endDate: endDate });
  }

  public updateStartDate(date: Date): void {
    // the output from the date picker component will be zoned to the time zone of the user's browser
    // so we'll want to convert it to the time zone of the salon before emitting it
    const startDateUtc = zonedTimeToUtc(startOfDay(date), this.timeZone);

    this.updateDateRange(getTime(startDateUtc), this.endDate);
  }

  public updateEndDate(date: Date): void {
    // the output from the date picker component will be zoned to the time zone of the user's browser
    // so we'll want to convert it to the time zone of the salon before emitting it
    const endOfDayUtc = zonedTimeToUtc(endOfDay(date), this.timeZone);

    this.updateDateRange(this.startDate, getTime(endOfDayUtc));
  }
}
