<div>
  <form
    *ngIf="form != null"
    [formGroup]="form"
    (submit)="saveForm(form.value, product)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name" />
      <mat-error *ngIf="form.get('name').hasError('required')">Required</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>HEX Color Code</mat-label>
      <input
        matInput
        formControlName="hexColorCode" />
      <colored-dot
        matSuffix
        [color]="form.controls.hexColorCode.value">
      </colored-dot>
      <mat-error *ngIf="form.get('hexColorCode').hasError('required')">Required</mat-error>
      <mat-error *ngIf="form.get('hexColorCode').hasError('validHexColorCode')">Invalid hex code (must have 6 digits)</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Category</mat-label>
      <mat-select formControlName="categoryId">
        <mat-option
          *ngFor="let category of _categories"
          [ngClass]="'level-' + category.level"
          [value]="category._id">
          {{ category.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('categoryId').hasError('required')"> At least one category is required </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Order</mat-label>
      <input
        matInput
        formControlName="order"
        type="number" />
    </mat-form-field>
  </form>

  <div
    *ngIf="form != null"
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value, product)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Save
    </button>
  </div>
</div>
