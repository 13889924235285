import { createSelector, createFeatureSelector } from "@ngrx/store";
import { IntegrationsState } from "./integrations.reducer";
import { pipe } from "fp-ts/lib/function";
import { fold, fromNullable } from "fp-ts/lib/Option";
import { SalonSoftwareIntegration } from "app/kernel/models/salon-software-provider";

export const getState = createFeatureSelector<IntegrationsState>("integrations");

export const getSelectedIntegration = createSelector(getState, (state) => state.selectedIntegration);
export const getIntegrations = createSelector(getState, (state) => {
  return pipe(
    state,
    fromNullable,
    fold(
      () => [] as SalonSoftwareIntegration[],
      (state) => state.integrations
    )
  );
});
export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
