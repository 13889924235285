import { Component, Directive, ContentChildren, QueryList, Input, Output, EventEmitter, OnChanges } from "@angular/core";

interface InputType {
  type: string;
}

interface TextInputType extends InputType {
  type: "text";
}

interface NumberInputType extends InputType {
  type: "number";
  min?: number;
  max?: number;
}

@Directive({
  selector: "text-cell-text",
})
export class TextDirective implements OnChanges {
  @Input()
  inputType?: TextInputType | NumberInputType;
  @Input()
  enableEditing?: boolean = true;
  @Input()
  value: string | number;
  @Input()
  title?: string;
  @Input()
  className?: string;
  @Input()
  tooltip?: string;
  @Output()
  public action: EventEmitter<any>;
  @Output()
  public editingChanged: EventEmitter<boolean>;

  public classNames;

  constructor() {
    this.action = new EventEmitter();
    this.editingChanged = new EventEmitter();
  }

  public ngOnChanges(): void {
    const classes = ["text"];
    if (this.className != null) {
      classes.push(this.className);
    }

    if (this.inputType == null && this.action.observers.length) {
      classes.push("clickable");
    }

    this.classNames = classes.join(" ");
  }

  public onClick(): void {
    this.action.emit();
  }

  public onEdit(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    switch (this.inputType.type) {
      case "number":
        this.action.emit(Number(value));
        break;
      default:
        this.action.emit(value);
    }
  }

  public onEditingChanged(): void {
    this.editingChanged.emit(true);
  }
}
@Component({
  selector: "text-cell",
  templateUrl: "text-cell.component.html",
  styleUrls: ["text-cell.component.less"],
})
export class TextCellComponent {
  @ContentChildren(TextDirective)
  public values: QueryList<TextDirective>;
}
