import { createSelector, createFeatureSelector, createReducer, on } from "@ngrx/store";
import { Salon } from "@getvish/model";
import { Actions, loadAvailableTenantsSuccess, setCurrentTenant } from "../actions/current-tenant.actions";

export const featureKey = "currentTenant";

export interface State {
  currentTenantId: string;
  tenants: Salon[];
}

const initialState: State = {
  currentTenantId: undefined,
  tenants: [],
};

export const reducer = createReducer<State, Actions>(
  initialState,
  on(loadAvailableTenantsSuccess, (state, { tenants }) => ({
    ...state,
    tenants,
  })),
  on(setCurrentTenant, (state, { tenant }) => ({
    ...state,
    currentTenantId: tenant._id,
  }))
);

export const getState = createFeatureSelector<State>(featureKey);
export const getCurrentTenantId = createSelector(getState, (state) => state.currentTenantId);
export const getTenants = createSelector(getState, (state) => state.tenants);
export const getCurrentTenant = createSelector(getCurrentTenantId, getTenants, (currentTenantId, salons) =>
  salons.find((salon) => salon._id === currentTenantId)
);
