<div
  class="dialog-contents"
  [progressIndicator]="progressText">
  <!-- Header -->
  <div class="title">
    <span>{{ dialogTitle }}</span>
    <div class="controls">
      <ng-content select="[dialog-controls]"></ng-content>
      <button
        *ngIf="close.observed"
        mat-icon-button
        class="button transparent small"
        (click)="dismiss()">
        <fa-icon icon="times"></fa-icon>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div
    class="content"
    *ngIf="!error; else errorElement"
    cdkScrollable>
    <ng-content></ng-content>
  </div>

  <!-- Footer -->
  <div class="footer">
    <ng-content select="[dialog-footer]"></ng-content>
  </div>

  <!-- Error -->
  <ng-template #errorElement>
    <alert-component type="danger">
      <h4>An Error Has Occured</h4>

      <p>{{ error }}</p>
    </alert-component>
  </ng-template>
</div>
