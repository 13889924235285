import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { trackByIdFn } from "../../../../kernel/util";

@Component({
  selector: "breadcrumbs-line",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent<T extends { _id: string; name: string }> {
  @Input()
  public items: T[];

  @Output()
  public itemSelected = new EventEmitter<T>(true);

  public trackByFn = trackByIdFn;
}
