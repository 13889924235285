import { Injectable } from "@angular/core";
import { NotificationService } from "app/+notifications/services";
import { filter, map, take } from "rxjs";
import { sortBy } from "ramda";
import { AppState } from "app/kernel";
import { go } from "app/kernel/store/actions/router.actions";
import { Store } from "@ngrx/store";
import { getAppointmentsToday } from "../store/appointment.selectors";
import { loadCurrentAppointments } from "../store/appointment.actions";
import * as fromCurrentTenant from "app/kernel/store/reducers/current-tenant.reducer";

@Injectable()
export class FrontDeskNotificationsService {
  private _notificationsEnabled = false;

  constructor(
    private _notificationsService: NotificationService,
    private _store: Store<AppState>
  ) {}

  public enableNotifications() {
    if (this._notificationsEnabled) {
      return;
    }

    this._store
      .select(fromCurrentTenant.getCurrentTenant)
      .pipe(
        filter((tenant) => tenant !== undefined),
        take(1)
      )
      .subscribe(() => {
        this._store.dispatch(loadCurrentAppointments());

        this._notificationsService.registerNotificationObservable(
          "front-desk",
          this._store.select(getAppointmentsToday).pipe(
            map((appointments) => {
              const changedAppointments = appointments.filter((appointment) => appointment.changes?.length > 0);

              if (changedAppointments.length === 0) {
                return undefined;
              }

              const title = changedAppointments.length === 1 ? "Appointment Modified" : "Appointments Modified";
              const description =
                changedAppointments.length === 1
                  ? "There is an appointment which has had services modified."
                  : `There are ${changedAppointments.length} appointments which have had services modified.`;

              const mostRecentAppointment = sortBy((a) => -a.date, changedAppointments)[0];

              return [
                {
                  id: `appointment-changed-${mostRecentAppointment._id}`,
                  icon: "calendar-day",
                  title,
                  description,
                  date: new Date(mostRecentAppointment.date),
                  action: () => this._store.dispatch(go({ path: ["/front-desk"] })),
                },
              ];
            })
          )
        );
      });
  }
}
