import { Component, ChangeDetectionStrategy, ContentChildren, QueryList, Directive, EventEmitter, Input, Output } from "@angular/core";

@Directive({
  selector: "menu-cell-item",
})
export class MenuItemDirective {
  @Input()
  title: string;
  @Output()
  public action: EventEmitter<void>;

  constructor() {
    this.action = new EventEmitter();
  }

  public onClick(): void {
    this.action.emit();
  }
}
@Component({
  selector: "menu-cell",
  templateUrl: "menu-cell.component.html",
  styleUrls: ["menu-cell.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCellComponent {
  @ContentChildren(MenuItemDirective)
  public items: QueryList<MenuItemDirective>;
}
