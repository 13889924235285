import { Injectable } from "@angular/core";
import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Manufacturer } from "@getvish/model";

@Injectable()
export class ManufacturerService extends EntityService<Manufacturer> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "manufacturers" });
  }
}
