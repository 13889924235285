import { Injectable } from "@angular/core";
import { DiscontinuedProduct, Product } from "@getvish/model";
import { EntityService, HttpRepositoryFactory, HttpRequestHandler, JsonObject } from "@getvish/stockpile";
import { ProductReplacement } from "app/+product/+product-selection/components";
import { Either } from "fp-ts/lib/Either";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class DiscontinuedProductService extends EntityService<DiscontinuedProduct> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler
  ) {
    super(repositoryFactory, { entityKey: "products/discontinued" });
  }

  public findByProductIds(productIds: string[]): Observable<DiscontinuedProduct[]> {
    return this._findByChunks(productIds, (ids) => ({
      productId: { $in: ids },
    }));
  }

  private _findByChunks(ids: string[], criteriaFunc: (ids: string[]) => JsonObject) {
    const uniqueIds = uniq(ids);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) => this.find(criteriaFunc(_ids)).pipe(map((result) => result.records)));

    return isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(flatten));
  }

  public discontinue(
    product: Product,
    discontinuedDate: Date,
    replacements: ProductReplacement[],
    discontinuedProduct?: DiscontinuedProduct
  ): Observable<Either<Error, DiscontinuedProduct>> {
    const payload: DiscontinuedProduct = {
      ...discontinuedProduct,
      productId: product._id,
      discontinuedDate: discontinuedDate.getTime(),
      replacements: replacements?.map((replacement) => ({
        items: replacement.products.map((p) => ({
          replacementProductId: p._id,
          portion: p.ratio,
        })),
      })),
    };

    if (payload._id == null) {
      return this.insert(payload);
    } else {
      return this.update(payload);
    }
  }

  public deleteDiscontinue(discontinuedProduct: DiscontinuedProduct): Observable<Either<Error, string>> {
    return this.remove(discontinuedProduct._id);
  }
}
