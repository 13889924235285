<slideout-panel
  (close)="close()"
  [progressIndicator]="(saving$ | async) === true ? 'Saving...' : undefined">
  <div
    *ngIf="(product$ | async)?._id | isNil"
    panel-header>
    New Product
  </div>
  <div
    *ngIf="(product$ | async)?._id | isDefined"
    panel-header>
    Edit Product
  </div>

  <edit-product-component
    [product]="product$ | async"
    [categories]="categories$ | async"
    [manufacturerId]="manufacturerId$ | async"
    [newMetadata]="newMetadata$ | async"
    (save)="save($event)"
    (close)="close()"
    panel-body>
  </edit-product-component>
</slideout-panel>
