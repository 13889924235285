import { Component, Input, ChangeDetectionStrategy, OnInit } from "@angular/core";

@Component({
  selector: "colored-icon",
  template: `
    <span class="stack size-{{ size }} num-chars-{{ numChars }}">
      <span class="circle stack-2x {{ color }}-background"></span>
      <strong class="stack-1x stack-text inverse">{{ label }}</strong>
    </span>
  `,
  styleUrls: ["colored-icon.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColoredIconComponent implements OnInit {
  @Input() public title: string;
  @Input() public size = "2x";
  @Input() public numChars = 1;
  @Input() public color: string;
  public label: string;

  public ngOnInit(): void {
    // call #getLabel to populate @label
    this.label = this.getLabel(this.numChars);

    // color is available as an input parameter
    // but if no color was explicitly specified then ask #getColor for one
    this.color = !this.color ? this.getColor(this.label) : this.color;
  }

  public getLabel(numChars: number): string {
    // remove any characters from input that aren't alphanumeric
    const onlyAlphanumericInput = this.title.replace(/[^a-z0-9]/gi, "");
    // return the first [numChars] characters in the title provided as input
    return this.title ? onlyAlphanumericInput.substr(0, numChars).toUpperCase() : "";
  }

  /*
   * maps ASCII code of char input to a color
   * 0-9: turquoise
   * A-F: turquoise
   * E-H: emerald
   * I-L: peteriver
   * M-P: wisteria
   * Q-T: sunflower
   * U-Z: pumpkin
   */
  public getColor(char: string): string {
    // convert the char to a ASCII integer code
    const charCode = char.charCodeAt(0);

    const color = function (_charCode) {
      if (_charCode < 69) {
        return "turquoise";
      } else if (_charCode < 73) {
        return "emerald";
      } else if (_charCode < 77) {
        return "pumpkin";
      } else if (_charCode < 81) {
        return "wisteria";
      } else if (_charCode < 85) {
        return "sunflower";
      } else {
        return "peteriver";
      }
    };

    return color(charCode);
  }
}
