import { NgModule, ModuleWithProviders } from "@angular/core";
import { SharedModule } from "../kernel/shared";
import { ResetPasswordRoutingModule } from "./reset-password-routing.module";
import { EffectsModule } from "@ngrx/effects";
import { KernelServicesModule } from "../kernel/services";

import { ResetPasswordService } from "./services";
import { ResetPasswordEffects } from "./store";
import { StoreModule } from "@ngrx/store";
import { reducer as resetPasswordReducer } from "./store/reset-password.reducer";
import { ConfirmCodeContainer, ForgotPasswordContainer, SetPasswordContainer } from "./containers";
import { ConfirmCodeFormComponent, ForgotPasswordFormComponent } from "./components";
import { ResetPasswordRootContainer } from "./containers/reset-password-root.container";
import { ResetPasswordFormComponent } from "./components/reset-password-form.component";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faLock, faAt } from "@fortawesome/pro-solid-svg-icons";

@NgModule({
  imports: [SharedModule, KernelServicesModule, ResetPasswordRoutingModule],
  declarations: [
    ResetPasswordRootContainer,
    ForgotPasswordContainer,
    ForgotPasswordFormComponent,
    ConfirmCodeContainer,
    ConfirmCodeFormComponent,
    SetPasswordContainer,
    ResetPasswordFormComponent,
  ],
})
export class ResetPasswordModule {
  public static forRoot(): ModuleWithProviders<ResetPasswordModule> {
    return {
      ngModule: RootResetPasswordModule,
      providers: [ResetPasswordService],
    };
  }
}

@NgModule({
  imports: [
    ResetPasswordModule,
    ResetPasswordRoutingModule,
    StoreModule.forFeature("resetPassword", resetPasswordReducer),
    EffectsModule.forFeature([ResetPasswordEffects]),
  ],
})
export class RootResetPasswordModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faAt, faLock);
  }
}
