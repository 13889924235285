<div
  *ngIf="includeSeparator"
  class="separator"></div>
<div class="colorIcon">
  <colored-dot
    [color]="ingredient.product.hexColorCode"
    size="xlarge"></colored-dot>
</div>
<div class="description">
  <div class="productName">
    {{ ingredient.product.name }}
  </div>
  <div class="productInfo">
    <span
      class="ingredientType"
      *ngIf="getIngredientTypeText()">
      {{ getIngredientTypeText() }}
    </span>
    <span
      class="ingredientCostPerMeasure"
      *ngIf="getCostPerMeasure()">
      {{ getCostPerMeasure() * measurementUnit.convertToBase(1) | currency: currency : "symbol-narrow" }}/{{ measurementUnit.abbreviation }}
    </span>
  </div>
</div>
<div class="weight">
  <weight-selector
    [weight]="getWeight()"
    [type]="weightType"
    [measurementUnit]="measurementUnit"
    [options]="getAmountOptions()"
    [allowCustom]="true"
    [step]="getStep()"
    (weightChange)="setWeight($event)"></weight-selector>
</div>
<div class="cost">{{ getCost() | currency: currency : "symbol-narrow" }}</div>
<div class="controls">
  <span class="delete">
    <fa-icon
      icon="trash"
      (click)="delete.emit()"></fa-icon>
  </span>
</div>
