import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CurrentSalonService, CurrentTenantService } from "app/kernel/services/current-tenant.service";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import {
  init,
  loadAvailableSalons,
  tenantNotFound,
  currentTenantExists,
  ensureCurrentTenantExists,
  loadAvailableTenantsSuccess,
} from "../actions/current-tenant.actions";

@Injectable()
export class CurrentTenantEffects {
  public init$ = createEffect(() =>
    this.actions.pipe(
      ofType(init),
      map(({ slug }) => ensureCurrentTenantExists({ slug }))
    )
  );

  public ensureCurrentTenantExists$ = createEffect(() =>
    this.actions.pipe(
      ofType(ensureCurrentTenantExists),
      switchMap(({ slug }) =>
        this.currentTenantService
          .tenantExists(slug)
          .pipe(map((doesCurrentTenantExist) => (doesCurrentTenantExist === true ? currentTenantExists({ slug }) : tenantNotFound())))
      )
    )
  );

  public currentTenantExists$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(currentTenantExists),
        tap(({ slug }) => this.currentSalonService.setCurrentSalonSlug(slug))
      ),
    { dispatch: false }
  );

  public loadAvailableSalons$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadAvailableSalons),
      switchMap(({ user }) =>
        this.currentTenantService.getAvailableTenantsForUser(user).pipe(mergeMap((tenants) => [loadAvailableTenantsSuccess({ tenants })]))
      )
    )
  );

  constructor(
    private actions: Actions,
    private currentTenantService: CurrentTenantService,
    private currentSalonService: CurrentSalonService
  ) {}
}
