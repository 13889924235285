import { TenantEntitlement } from "app/kernel/models/tenant-entitlement";
import { loadForSalons, loadForSalonsFail, loadForSalonsSuccess } from "./tenant-entitlements.actions";
import { createReducer, on } from "@ngrx/store";

export interface TenantEntitlementsState {
  loading: boolean;
  entitlements: TenantEntitlement[];
  error: string;
}

const initialState: TenantEntitlementsState = {
  loading: false,
  entitlements: [],
  error: undefined,
};

export const tenantEntitlementsReducer = createReducer(
  initialState,
  on(loadForSalons, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadForSalonsSuccess, (state, { entitlements }) => ({
    ...state,
    loading: false,
    entitlements,
  })),
  on(loadForSalonsFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  }))
);

export const tenantEntitlementsFeatureKey = "tenantEntitlements";
