import { Observable, of } from "rxjs";

export interface Currency {
  value: string;
  label: string;
}

export class CurrencyService {
  public getCurrencies(): Observable<Currency[]> {
    const currencies = [
      { value: "CAD", label: "CAD" },
      { value: "USD", label: "USD" },
      { value: "GBP", label: "GBP" },
      { value: "AUD", label: "AUD" },
      { value: "EUR", label: "EUR" },
      { value: "NZD", label: "NZD" },
      { value: "QAR", label: "QAR" },
      { value: "MXN", label: "MXN" },
      { value: "HTG", label: "HTG" },
      { value: "AED", label: "AED" },
      { value: "ZAR", label: "ZAR" },
    ];

    return of(currencies);
  }
}
