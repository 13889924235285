import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { SalonConfig } from "@getvish/model";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { fold, fromNullable } from "fp-ts/lib/Option";

@Injectable()
export class SalonConfigService extends EntityService<SalonConfig> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "salonConfigs" });
  }

  public findOrDie(): Observable<SalonConfig> {
    return this.findOne({}).pipe(
      mergeMap(
        fold(
          () => throwError(() => "No SalonConfig found"),
          (salonConfig) => of(salonConfig)
        )
      )
    );
  }

  public fetchSalonTimeZone(): Observable<string> {
    return this.findOrDie().pipe(
      mergeMap((salonConfig) => {
        return fold<string, Observable<string>>(
          () => throwError(() => "No Time Zone configured"),
          (timeZone) => of(timeZone)
        )(fromNullable(salonConfig.timeZone));
      })
    );
  }
}
