<slideout-panel (close)="close()">
  <div panel-header>Edit Services</div>
  <div panel-body>
    <edit-services
      [services]="selectedRecords$ | async"
      [categories]="serviceCategories$ | async"
      [saving]="saving$ | async"
      (save)="save($event)">
    </edit-services>
  </div>
</slideout-panel>
