import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { option } from "fp-ts";
import { of } from "rxjs";
import { map, mapTo, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { ProductAllowanceService, ServiceDescriptionService } from "../services/";

import { AppState } from "app/kernel";
import { ProductAllowanceVM } from "app/kernel/models/product-allowance";
import * as routerActions from "app/kernel/store/actions/router.actions";
import * as SnackbarActions from "app/kernel/store/actions/snackbar.actions";
import { separate } from "fp-ts/lib/Array";
import { isEmpty } from "ramda";
import * as actions from "./product-allowance-calculator.actions";
import * as fromProductAllowance from "./product-allowance-calculator.selectors";
import * as serviceMenuActions from "./service-menu.actions";

@Injectable()
export class PACalculatorEffects {
  public loadData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.LoadData>(actions.Types.LOAD_DATA),
      map((action) => action.payload),
      switchMap(({ ids }) => {
        const serviceDescriptions$ = this._serviceDescriptionService.findByIds(ids);

        return serviceDescriptions$.pipe(
          switchMap((records) => {
            if (records.length !== ids.length) {
              return of(new actions.LoadDataFail({ error: new Error("Service Not Found") }));
            }

            return this._productAllowanceService.getProductAllowances(records).pipe(
              map((paByService) => Object.values(paByService)),
              map((productAllowances) => (productAllowances.length ? option.some(productAllowances[0]) : option.none)),
              map(
                (productAllowance) =>
                  option.fold(
                    () => (records.length === 1 && records[0].productAllowance ? {} : { blueprints: [{ pigments: [], developers: [] }] }),
                    (productAllowance) => productAllowance
                  )(productAllowance) as ProductAllowanceVM
              ),
              mergeMap((productAllowance) => [
                new actions.LoadDataSuccess({
                  services: records,
                  productAllowance,
                }),
              ])
            );
          })
        );
      })
    )
  );

  public saveServices$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.SaveService>(actions.Types.SAVE_SERVICE),
      withLatestFrom(this._store.select(fromProductAllowance.getServices), this._store.select(fromProductAllowance.getProductAllowance)),
      switchMap(([_, services, productAllowance]) => this._productAllowanceService.saveProductAllowance(productAllowance, services)),
      map(separate),
      map(({ left }) => {
        if (!isEmpty(left)) {
          return new actions.SaveServiceFail({ errors: left });
        }

        return new actions.SaveServiceSuccess();
      })
    )
  );

  public saveServiceSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.SaveServiceSuccess>(actions.Types.SAVE_SERVICE_SUCCESS),
      mergeMap(() => [
        new SnackbarActions.Info({ message: "Product allowance saved successfully." }),
        new serviceMenuActions.ClearSelectedServices(),
        routerActions.go({ path: ["/service-menu"] }),
      ])
    )
  );

  public saveServiceError$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.SaveServiceFail>(actions.Types.SAVE_SERVICE_FAIL),
      mapTo(new SnackbarActions.Info({ message: "There was an error while saving the service." }))
    )
  );

  constructor(
    private _serviceDescriptionService: ServiceDescriptionService,
    private _productAllowanceService: ProductAllowanceService,
    private _actions$: Actions,
    private _store: Store<AppState>
  ) {}
}
