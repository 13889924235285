import { Manufacturer } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { Action } from "@ngrx/store";
import { isNil } from "ramda";

export enum Types {
  LOAD_ALL = "[Manufacturer] Load All",
  LOAD_ALL_SUCCESS = "[Manufacturer] Load All Success",
  LOAD_ALL_FAIL = "[Manufacturer] Load All Fail",
  NAVIGATE_TO_PAGE = "[Manufacturer] Navigate to Page",
  UPDATE_SORT = "[Manufacturer] Update Sort",
  NEW = "[Manufacturer] New",
  ADD = "[Manufacturer] Add",
  ADD_SUCCESS = "[Manufacturer] Add Success",
  ADD_FAIL = "[Manufacturer] Add Fail",
  SAVE = "[Manufacturer] Save",
  EDIT = "[Manufacturer] Edit",
  UPDATE = "[Manufacturer] Update",
  UPDATE_SUCCESS = "[Manufacturer] Update Success",
  UPDATE_FAIL = "[Manufacturer] Update Fail",
  LOAD_BY_ID = "[Manufacturer] Load Manufacturer By Id",
  LOAD_SUCCESS = "[Manufacturer] Load Manufacturer Success",
  LOAD_FAIL = "[Manufacturer] Load Manufacturer Fail",
  SET_SEARCH_FILTER = "[Manufacturer] Set search filter",
  SET_ADMIN_FILTER = "[Manufacturer] Set admin filter",
  SET_ADMIN_SORT = "[Manufacturer] Set admin sort",
}

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;

  constructor(
    public payload: {
      criteria?: JsonObject;
      sort?: JsonObject;
      page?: number;
      limit?: number;
    }
  ) {}
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public payload: Manufacturer[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class NavigateToPage implements Action {
  public readonly type = Types.NAVIGATE_TO_PAGE;

  constructor(public payload: { page: number }) {}
}

export class UpdateSort implements Action {
  public readonly type = Types.UPDATE_SORT;

  constructor(public payload: JsonObject) {}
}

export class New implements Action {
  public readonly type = Types.NEW;
}

export class Add implements Action {
  public readonly type = Types.ADD;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class AddSuccess implements Action {
  public readonly type = Types.ADD_SUCCESS;

  constructor(public payload: Manufacturer) {}
}

export class AddFail implements Action {
  public readonly type = Types.ADD_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class Update implements Action {
  public readonly type = Types.UPDATE;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class UpdateSuccess implements Action {
  public readonly type = Types.UPDATE_SUCCESS;

  constructor(public payload: Manufacturer) {}
}

export class UpdateFail implements Action {
  public readonly type = Types.UPDATE_FAIL;

  constructor(public payload: { error: Error }) {}
}

export const addOrUpdate = (manufacturer: Manufacturer) =>
  isNil(manufacturer._id) ? new Add({ manufacturer }) : new Update({ manufacturer });

export class Edit implements Action {
  public readonly type = Types.EDIT;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class SetSearchFilter implements Action {
  public readonly type = Types.SET_SEARCH_FILTER;

  constructor(public payload: { searchFilter: string }) {}
}
export class SetAdminFilter implements Action {
  public readonly type = Types.SET_ADMIN_FILTER;

  constructor(public payload: { filter: string }) {}
}

export class SetAdminSort implements Action {
  public readonly type = Types.SET_ADMIN_SORT;

  constructor(public payload: JsonObject) {}
}

export class LoadManufacturerById implements Action {
  public readonly type = Types.LOAD_BY_ID;

  constructor(public payload: { manufacturerId: string }) {}
}

export class LoadManufacturerSuccess implements Action {
  public readonly type = Types.LOAD_SUCCESS;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class LoadManufacturerFail implements Action {
  public readonly type = Types.LOAD_FAIL;

  constructor(public payload: { error: Error }) {}
}

export type Actions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | NavigateToPage
  | UpdateSort
  | New
  | Add
  | AddSuccess
  | AddFail
  | Edit
  | Update
  | UpdateSuccess
  | UpdateFail
  | SetSearchFilter
  | SetAdminFilter
  | LoadManufacturerById
  | LoadManufacturerSuccess
  | LoadManufacturerFail;
