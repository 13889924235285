import { Injectable } from "@angular/core";
import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Formula } from "@getvish/model";
import { Observable, forkJoin, map, of } from "rxjs";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";

@Injectable()
export class FormulaService extends EntityService<Formula> {
  public controllerKey: string;

  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "formulas" });
  }

  public findForPerformedServiceIds(performedServiceIds: string[]): Observable<Formula[]> {
    const uniqueIds = uniq(performedServiceIds);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) => this._findForPerformedServiceIds(_ids));

    return isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(flatten));
  }

  public _findForPerformedServiceIds(performedServiceIds: string[]): Observable<Formula[]> {
    return this.find({ performedServiceId: { $in: performedServiceIds } }).pipe(map((result) => result.records));
  }
}
