import { AppointmentStatus } from "@getvish/model";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { isTodayInTimeZoneFromUTC } from "app/kernel/util/zoned-time-utils";
import { fromNullable } from "fp-ts/Option";
import { isNil, or, toLower } from "ramda";
import { AppointmentState } from "./appointment.reducer";

import * as fromSalonConfig from "app/+salon-config/store/salon-config.selectors";

/*** HELPERS ***/
type HasName = { firstName: string; lastName: string };
const nameIncludesFilter = (user: HasName, filter: string) =>
  or(toLower(user?.firstName ?? "").includes(toLower(filter)), toLower(user?.lastName ?? "").includes(toLower(filter)));

/*** SELECTORS ***/
export const getAppointmentState = createFeatureSelector<AppointmentState>("appointments");
export const getLoading = createSelector(getAppointmentState, (state) => state.loading);
export const getDeleting = createSelector(getAppointmentState, (state) => state.deleting);
export const getResolving = createSelector(getAppointmentState, (state) => state.resolving);
export const getResolveLoading = createSelector(getAppointmentState, (state) => state.resolveLoading);
export const getResolveServiceDescriptions = createSelector(getAppointmentState, (state) => state.resolveServiceDescriptions);
export const getResolveAppointments = createSelector(getAppointmentState, (state) => state.resolveAppointments);
export const getPaging = createSelector(getAppointmentState, (state) => fromNullable(state.paging));
export const getSearchFilter = createSelector(getAppointmentState, (state) => state.searchFilter);
export const getSelected = createSelector(getAppointmentState, (state) => state.selected);
export const getStatusFilter = createSelector(getAppointmentState, (state) => state.statusFilter);
export const getAlertsOnly = createSelector(getAppointmentState, (state) => state.alertsOnly);
export const getAppointments = createSelector(getAppointmentState, (state) => state.records);

export const getAppointmentsToday = createSelector(getAppointments, fromSalonConfig.getSalonTimeZone, (appointments, timeZone) =>
  appointments
    .filter((appointment) => isTodayInTimeZoneFromUTC(appointment.date, timeZone))
    .filter((appointment) => appointment.status !== AppointmentStatus.CANCELED)
);

export const getFilteredAppointmentsByStatus = createSelector(getAppointmentsToday, getStatusFilter, (appointments, filter) =>
  appointments.filter((appointment) => appointment.status === filter || filter === undefined)
);

export const getSearchFilteredAppointments = createSelector(getFilteredAppointmentsByStatus, getSearchFilter, (appointments, filter) =>
  appointments.filter(
    (appointment) => isNil(filter) || nameIncludesFilter(appointment.customer, filter) || nameIncludesFilter(appointment.employee, filter)
  )
);

export const getAppointment = (id: string) =>
  createSelector(getAppointments, (appointments) => appointments.find((appointment) => appointment._id === id));
