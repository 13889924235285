import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { BillingPortalSessionService } from "app/+salons/services/billing-portal.service";
import { KernelServicesModule } from "app/kernel/services";
import { CurrencyService, MeasurementUnitConversionService, SalonConfigService, TimezoneService } from "./services";
import { SalonConfigEffects } from "./store/salon-config.effects";
import { salonConfigReducer } from "./store/salon-config.reducer";
import { EmployeeService } from "app/+employees/services";

@NgModule({
  imports: [
    KernelServicesModule,
    StoreModule.forFeature("salonConfig", salonConfigReducer),
    EffectsModule.forFeature([SalonConfigEffects]),
  ],
  providers: [
    BillingPortalSessionService,
    CurrencyService,
    MeasurementUnitConversionService,
    SalonConfigService,
    TimezoneService,
    EmployeeService,
  ],
})
export class SalonConfigSharedModule {}
