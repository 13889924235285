import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../../kernel/store";
import { SalonConfig } from "@getvish/model";
import { Observable } from "rxjs";
import { Currency } from "app/+salon-config/services";

import * as SalonConfigActions from "../../store/salon-config.actions";
import * as fromSalonConfig from "../../store/salon-config.selectors";
import * as fromAuth from "../../../+auth/store/auth.selectors";
import { Timezone } from "../../../kernel/models/timezone";

@Component({
  selector: "salon-settings-container",
  templateUrl: "salon-settings.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalonSettingsContainer {
  public salonConfig$: Observable<SalonConfig>;
  public timeZones$: Observable<Timezone[]>;
  public currencies$: Observable<Currency[]>;
  public isRoot$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.salonConfig$ = _store.pipe(select(fromSalonConfig.getSalonConfig));
    this.timeZones$ = _store.pipe(select(fromSalonConfig.getTimeZones));
    this.currencies$ = _store.pipe(select(fromSalonConfig.getCurrencies));
    this.isRoot$ = _store.pipe(select(fromAuth.isRoot));
  }

  public save(config: SalonConfig): void {
    this._store.dispatch(new SalonConfigActions.SaveSalonConfig({ config }));
  }
}
