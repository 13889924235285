<div class="mixing-container">
  <div
    class="mixing-element"
    [ngStyle]="{ 'clip-path': 'url(#' + clipPathId + ')' }"
    [ngClass]="mixingContainerConfig.svgType">
    <div
      class="fragment"
      *ngFor="let e of colorEntries; trackBy: trackById"
      [ngStyle]="{ height: e.percentage * 100 + '%', backgroundColor: e.color }"></div>
    <div
      class="pill-outer"
      *ngIf="mixingContainerVM.overlayText | toUndefined">
      <div class="pill-inner">
        <div class="pill-content">
          {{ mixingContainerVM.overlayText | toUndefined }}
        </div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
