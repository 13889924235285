import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Product } from "@getvish/model";
import { EntityService, HttpRepositoryFactory, HttpRequestHandler, JsonObject, RktError } from "@getvish/stockpile";
import { AuthStorageService } from "app/+auth/services";
import { WindowService } from "app/kernel";
import { HTTP_URL } from "app/kernel/services/common";
import { either } from "fp-ts";
import { Either, left, right } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import { flatten, isEmpty, splitEvery, uniq } from "ramda";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
interface MergeProductsPayload {
  primaryProductId: string;
  dependentProductIds: string[];
}
export interface MergeProductsSuccess {
  product: Product;
  numFormulasUpdated: number;
  numProductsDeleted: number;
}

@Injectable()
export class ProductService extends EntityService<Product> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string,
    private _windowService: WindowService,
    private _authStorage: AuthStorageService,
    private _requestHandler: HttpRequestHandler
  ) {
    super(repositoryFactory, { entityKey: "products" });
  }

  public findAll(): Observable<Product[]> {
    return this.find().pipe(map((x) => x.records));
  }

  public findByManufacturerIds(manufacturerIds: string[]): Observable<Product[]> {
    return this._findByChunks(manufacturerIds, (ids) => ({
      manufacturerId: { $in: ids },
    }));
  }

  public findByCategoryIds(categoryIds: string[]): Observable<Product[]> {
    return this._findByChunks(categoryIds, (ids) => ({
      categoryIds: { $in: ids },
    }));
  }

  private _findByChunks(ids: string[], criteriaFunc: (ids: string[]) => JsonObject) {
    const uniqueIds = uniq(ids);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((_ids) => this.find(criteriaFunc(_ids)).pipe(map((result) => result.records)));

    return isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(flatten));
  }

  update(product: Product): Observable<Either<Error, Product>> {
    const headers = new HttpHeaders({
      "X-Salon-Slug": this._windowService.tenantPathName,
      "X-Auth-Token": this._authStorage.getAuthToken(),
    });

    return this._http.put<Product>(`${this._httpUrl}/products/${product._id}`, product, { headers }).pipe(
      map((response) => right<RktError, Product>(response)),
      catchError((error: HttpErrorResponse) =>
        of(left<RktError, Product>(new RktError(error.message, error.message, error.status, error.error)))
      )
    );
  }

  public updateMany(products: Product[]): Observable<Either<Error, Product[]>> {
    return forkJoin(products.map((pc) => this.updateOrDie(pc))).pipe(
      map(() => right(products)),
      catchError((e) => of(left(e)))
    );
  }

  public mergeProducts(primary: Product, records: Product[]): Observable<Either<Error, MergeProductsSuccess>> {
    const customerIds = records.map((record) => record._id).filter((id) => id !== primary._id);

    const payload: MergeProductsPayload = {
      primaryProductId: primary._id,
      dependentProductIds: customerIds,
    };

    return this._requestHandler.post<MergeProductsSuccess>(`products/merge`, payload).pipe(
      map((response) =>
        pipe(
          response,
          either.mapLeft((fail) => new Error(fail.payload["reason"]))
        )
      ),
      catchError((error) => {
        console.error("error was: ", error);
        return of(either.left(new Error(error.toString())));
      })
    );
  }
}
