import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { ServiceCategoryService, ServiceDescriptionService } from "../services/";
import { SalonConfigService } from "../../+salon-config/services";
import { switchMap, mergeMap, map, mapTo } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { ServiceDescription } from "@getvish/model";
import { isDefined } from "../../kernel/util";
import { option } from "fp-ts";
import { either } from "fp-ts";
import { Action, Store } from "@ngrx/store";
import { isNil, not } from "ramda";

import * as actions from "./edit-service.actions";
import * as salonConfigActions from "../../+salon-config/store/salon-config.actions";
import * as serviceMenuActions from "./service-menu.actions";
import * as routerActions from "../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../kernel/store/actions/snackbar.actions";

import { AppState } from "app/kernel";

@Injectable()
export class EditServiceEffects {
  public navigateTo$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Navigate>(actions.Types.NAVIGATE),
      map((action) => (not(isNil(action.payload)) ? `edit/${action.payload.serviceDescriptionId}` : "new")),
      map((fragment) =>
        routerActions.go({
          path: ["/service-menu", { outlets: { panel: fragment } }],
          extras: { queryParamsHandling: "merge" },
        })
      )
    )
  );

  public loadData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.LoadData>(actions.Types.LOAD_DATA),
      map((action) => action.payload),
      switchMap(({ id }) => {
        const fetchServiceDescription$ = isDefined(id)
          ? this._serviceDescriptionService.findById(id)
          : of(option.some(new ServiceDescription()));
        const fetchSalonConfig$ = this._salonConfigService.findOne({});
        const fetchServiceCategories$ = this._serviceCategoryService.find({});

        return forkJoin([fetchServiceDescription$, fetchSalonConfig$, fetchServiceCategories$]).pipe(
          mergeMap(([serviceDescription, salonConfig, serviceCategories]) => [
            new serviceMenuActions.LoadServiceCategoriesSuccess(serviceCategories.records),
            new salonConfigActions.LoadSalonConfigSuccess(option.toUndefined(salonConfig)),
            option.fold<ServiceDescription, Action>(
              () => new actions.LoadDataFail({ error: new Error("Service Not Found") }),
              (serviceDescription) => new actions.LoadDataSuccess(serviceDescription)
            )(serviceDescription),
          ])
        );
      })
    )
  );

  public add$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Add>(actions.Types.ADD),
      map((action) => action.payload.serviceDescription),
      switchMap((payload) =>
        this._serviceDescriptionService.insert(payload).pipe(
          mergeMap(
            either.fold<Error, ServiceDescription, Action[]>(
              (error) => [new actions.Fail({ errors: error })],
              (serviceDescription) => [
                new actions.AddSuccess(serviceDescription),
                new serviceMenuActions.UpdateServiceDescriptionsSuccess([serviceDescription]),
              ]
            )
          )
        )
      )
    )
  );

  public addSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddSuccess>(actions.Types.ADD_SUCCESS),
      map(() => new actions.Close())
    )
  );

  public update$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.EDIT),
      map((action: actions.Edit) => action.payload.serviceDescription),
      switchMap((payload) =>
        this._serviceDescriptionService.update(payload).pipe(
          mergeMap(
            either.fold<Error, ServiceDescription, Action[]>(
              (error) => [new actions.Fail({ errors: error })],
              (serviceDescription) => [
                new actions.EditSuccess(serviceDescription),
                new serviceMenuActions.UpdateServiceDescriptionsSuccess([serviceDescription]),
              ]
            )
          )
        )
      )
    )
  );

  public editSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.AddSuccess>(actions.Types.EDIT_SUCCESS),
      map(() => new actions.Close())
    )
  );

  public close$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Close>(actions.Types.CLOSE),
      map(() => routerActions.go({ path: ["/service-menu"], extras: { queryParamsHandling: "merge" } }))
    )
  );

  public showSnackbarOnSaveSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.ADD_SUCCESS, actions.Types.EDIT_SUCCESS),
      mapTo(new SnackbarActions.Info({ message: "Service saved successfully" }))
    )
  );

  public showNotificationOnSaveFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.FAIL),
      mapTo(new SnackbarActions.Info({ message: "There was an error while saving the service" }))
    )
  );

  constructor(
    private _serviceDescriptionService: ServiceDescriptionService,
    private _serviceCategoryService: ServiceCategoryService,
    private _salonConfigService: SalonConfigService,
    private _actions$: Actions,
    private _store: Store<AppState>
  ) {}
}
