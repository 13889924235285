import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Appointment, AppointmentStatus } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AppState } from "../../kernel";
import { navigate } from "../store/schedule-appointment.actions";

import * as fromSalonConfig from "app/+salon-config/store/salon-config.selectors";

import * as actions from "../store/appointment.actions";
import * as fromAppointments from "../store/appointment.selectors";
import { AppointmentVM } from "../models/appointment";

@Component({
  selector: "appointments",
  templateUrl: "appointments.container.html",
  styleUrls: ["appointments.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentsContainer {
  public timeZone$: Observable<string>;
  public appointments$: Observable<AppointmentVM[]>;
  public filter$: Observable<string>;
  public alertsOnly$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public deleting$: Observable<boolean>;
  public resolving$: Observable<boolean>;
  public limit: number;
  public tabs: Array<{ label: string; filter: AppointmentStatus | null; fn: () => void }>;
  public selectedTabIndex$: Observable<number>;
  public hasChangedAppointments$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    _store.dispatch(actions.loadCurrentAppointments());

    this.filter$ = _store.pipe(select(fromAppointments.getSearchFilter));
    this.alertsOnly$ = _store.pipe(select(fromAppointments.getAlertsOnly));
    this.loading$ = _store.pipe(select(fromAppointments.getLoading));
    this.deleting$ = _store.pipe(select(fromAppointments.getDeleting));
    this.resolving$ = _store.pipe(select(fromAppointments.getResolving));
    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);

    this.appointments$ = combineLatest([_store.select(fromAppointments.getSearchFilteredAppointments), this.alertsOnly$]).pipe(
      map(([appointments, alertsOnly]) => {
        if (alertsOnly) {
          return appointments.filter((appointment) => appointment.changes?.length > 0);
        }

        return appointments;
      })
    );

    this.hasChangedAppointments$ = this.appointments$.pipe(
      map((appointments) => appointments.some((appointment) => appointment.changes?.length > 0))
    );

    this.tabs = [
      { label: "Active", filter: AppointmentStatus.ACTIVE, fn: () => this.setStatusFilter(AppointmentStatus.ACTIVE) },
      { label: "Completed", filter: AppointmentStatus.COMPLETED, fn: () => this.setStatusFilter(AppointmentStatus.COMPLETED) },
      { label: "Checked Out", filter: AppointmentStatus.CHECKED_OUT, fn: () => this.setStatusFilter(AppointmentStatus.CHECKED_OUT) },
      { label: "All", filter: undefined, fn: () => this.setStatusFilter(undefined) },
    ];

    this.selectedTabIndex$ = _store.pipe(select(fromAppointments.getStatusFilter)).pipe(
      map((statusFilter) => this.tabs.find((tab) => tab.filter === statusFilter)),
      map((tab) => this.tabs.indexOf(tab))
    );
  }

  public updateFilter(filter: string): void {
    this._store.dispatch(new actions.SetSearchFilter({ filter }));
  }

  public toggleAlertsOnly(): void {
    this._store.dispatch(new actions.ToggleAlertsOnly());
  }

  public select(appointment: Appointment): void {
    this._store.dispatch(actions.select({ appointment }));
  }

  public delete(appointment: Appointment): void {
    this._store.dispatch(actions.Delete({ appointment }));
  }

  public resolve(appointment: AppointmentVM): void {
    this._store.dispatch(new actions.ShowResolve([appointment]));
  }

  public resolveAll(): void {
    this.appointments$
      .pipe(
        map((appointments) => appointments.filter((appointment) => appointment.changes?.length > 0)),
        take(1)
      )
      .subscribe((appointments) => {
        this._store.dispatch(new actions.ShowResolve(appointments));
      });
  }

  public tabChanged(index: number): void {
    this.tabs[index].fn();
  }

  public setStatusFilter(status: AppointmentStatus): void {
    this._store.dispatch(new actions.SetStatusFilter({ status }));
  }

  public new(): void {
    this._store.dispatch(navigate());
  }

  public getProgressText(): Observable<string | undefined> {
    return combineLatest([this.loading$, this.deleting$, this.resolving$]).pipe(
      map(([loading, deleting, resolving]) => {
        if (loading) {
          return "Loading...";
        } else if (deleting) {
          return "Deleting...";
        } else if (resolving) {
          return "Resolving...";
        }

        return undefined;
      })
    );
  }
}
