<div class="content">
  <h1>Seems like you're lost...</h1>
  <div class="image-wrapper">
    <img
      src="assets/images/error-graphic.png"
      alt="Page Not Found Image" />
  </div>
  <div class="message-wrapper">
    <h2>The URL you have entered does not exist</h2>
    <p>There doesn't seem to be an active salon at the address you're trying to visit</p>
    <p>
      If you're really lost please contact client support at
      <a href="mailto: support@getvish.com">support&#64;getvish.com</a> or
      <a href="tel:1-888-419-6391">1-888-419-6391</a>
    </p>
  </div>
</div>
