type APP_MODE =
  | "SINGLE_TENANT"
  | "MULTI_TENANT"
  | "ADMIN"
  | "FORGOT_PASSWORD"
  | "BOOKER-OAUTH"
  | "SQUARE-OAUTH"
  | "SALON-INTERACTIVE-OAUTH"
  | "INVENTORY-INTEGRATION";

export class AppMode {
  constructor(public value: APP_MODE) {}
}
