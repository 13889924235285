<mat-card (click)="select.emit(appointment)">
  <div class="time-container">
    <span class="time-label">
      <span *ngIf="appointment.date | isToday: timeZone">
        {{ appointment.date | zonedDate: timeZone : "h:mm a" }}
      </span>
      <span *ngIf="!(appointment.date | isToday: timeZone)">
        {{ appointment.date | zonedDate: timeZone : "MMM d, y, h:mm a" }}
      </span>
    </span>
  </div>
  <div
    class="employee-info-container"
    (click)="select.emit(appointment)">
    <span class="employee-title"> Customer </span>
    <div class="employee-name-wrapper">
      <button
        *ngIf="appointment.changes?.length > 0"
        matTooltip="One or more services have been updated for this appointment. Please update the appointment in your POS system accordingly."
        class="button transparent important">
        <fa-icon icon="circle-exclamation"></fa-icon>
      </button>
      <span class="employee-name"> {{ appointment.customer | fullName | default: "-" }}</span>
    </div>
  </div>
  <div
    class="employee-info-container"
    (click)="select.emit(appointment)">
    <span class="employee-title"> Employee </span>
    <span class="employee-name"> {{ appointment.employee | fullName | default: "-" }}</span>
  </div>
  <div class="flex-center">
    <appointment-status-label [status]="appointment.status"></appointment-status-label>
  </div>
  <div
    class="controls"
    stopPropagation>
    <mat-menu #controlsMenu="matMenu">
      <button
        mat-menu-item
        *ngIf="appointment.changes?.length > 0"
        (click)="resolve.emit(appointment)">
        Resolve Alert
      </button>
      <button
        mat-menu-item
        *ngIf="isScheduled(appointment)"
        (click)="delete.emit(appointment)">
        Delete
      </button>
    </mat-menu>
    <button
      [matMenuTriggerFor]="controlsMenu"
      *ngIf="appointment.changes?.length > 0 || isScheduled(appointment)"
      class="button transparent">
      <fa-icon icon="ellipsis-v"></fa-icon>
    </button>
  </div>
</mat-card>
