import { createFeatureSelector, createSelector } from "@ngrx/store";
import { hasSearchTerm } from "app/+product/common/utils";
import { equals } from "ramda";
import { getManufacturer } from "../../+manufacturers/store/manufacturer.selectors";
import { ProductState } from "./product.reducer";

/*** SELECTORS ***/
export const getState = createFeatureSelector<ProductState>("products");

export const getLoading = createSelector(getState, (state) => state.loading);

export const getCriteria = createSelector(getState, (state) => state.criteria);
export const getPaging = createSelector(getState, (state) => state.paging);
export const getSearchFilter = createSelector(getState, (state) => state.searchFilter);
export const getAll = createSelector(getState, (state) => state.records);

export const getFilteredProducts = createSelector(getAll, getSearchFilter, (products, filter) => {
  const bySearchTerm = hasSearchTerm(filter);

  return products.filter(bySearchTerm);
});

export const getOne = (id: string) => createSelector(getAll, (records) => records.find((_record) => equals(id, _record._id)));

export const getNewMetadata = createSelector(getState, (state) => state.newMetadata);

export const getSelectedManufacturerId = createSelector(getState, (state) => state.selectedManufacturerId);

export const getSelectedManufacturer = createSelector(getSelectedManufacturerId, (selectedManufacturerId) =>
  getManufacturer(selectedManufacturerId)
);

export const getSaving = createSelector(getState, (state) => state.saving);
export const getError = createSelector(getState, (state) => state.error);

export const getSelected = createSelector(getState, (state) => state.selectedRecords);

export const getDiscontinuedProducts = createSelector(getState, (state) => state.discontinuedProducts);
export const getDiscontinuedProduct = (id: string) => createSelector(getState, (state) => state.discontinuedProducts[id]);
