import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../kernel/store";
import { combineLatest, Observable, take } from "rxjs";
import { SalonReport, EmployeeReport, ServiceReport, LongitudinalReport } from "../../kernel/models";
import {
  getSalonReport,
  getEmployeeReports,
  getServiceReports,
  getLongitudinalReport,
  getAggregatedBy,
  getLoading,
  getError,
  getStartDate,
  getEndDate,
} from "../store/analytics.selectors";
import { getMeasurementUnitOrDefault } from "../../+salon-config/store/salon-config.selectors";
import { MeasurementUnit } from "@getvish/model";

import * as analytics from "../store/analytics.actions";
import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";
import { go } from "app/kernel/store/actions/router.actions";

@Component({
  selector: "analytics-container",
  templateUrl: "analytics.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsContainer {
  public salonReport$: Observable<SalonReport>;
  public employeeReports$: Observable<EmployeeReport[]>;
  public serviceReports$: Observable<ServiceReport[]>;
  public longitudinalReport$: Observable<LongitudinalReport>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public aggregatedBy$: Observable<string>;
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public startDate$: Observable<number>;
  public endDate$: Observable<number>;
  public currency$: Observable<string>;
  public locale$: Observable<string>;
  public timeZone$: Observable<string>;
  public enableComplianceTracking$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.salonReport$ = _store.pipe(select(getSalonReport));
    this.employeeReports$ = _store.pipe(select(getEmployeeReports));
    this.serviceReports$ = _store.pipe(select(getServiceReports));
    this.longitudinalReport$ = _store.pipe(select(getLongitudinalReport));
    this.measurementUnit$ = _store.pipe(select(getMeasurementUnitOrDefault));
    this.aggregatedBy$ = _store.pipe(select(getAggregatedBy));
    this.loading$ = _store.pipe(select(getLoading));
    this.error$ = _store.pipe(select(getError));
    this.startDate$ = _store.pipe(select(getStartDate));
    this.endDate$ = _store.pipe(select(getEndDate));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.locale$ = _store.pipe(select(fromSalonConfig.getLocale));
    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);
    this.enableComplianceTracking$ = _store.select(fromSalonConfig.getEnableComplianceTracking);

    this._store.dispatch(new analytics.InitializeAndLoadDashboardReports({ aggregatedBy: "DAILY" }));
  }

  public updateDateRange(event: { startDate: number; endDate: number }): void {
    const { startDate, endDate } = event;

    this._store.dispatch(new analytics.UpdateDashboardDateRange({ startDate, endDate }));
  }

  public updateAggregation(aggregatedBy: string): void {
    this._store.dispatch(new analytics.ChangeAggregation({ aggregatedBy }));
  }

  public downloadEmployeePerformanceReport(): void {
    this._store.dispatch(new analytics.DownloadEmployeePerformanceReport());
  }

  public downloadServiceReport({ showExcludedServices }): void {
    this._store.dispatch(new analytics.DownloadServiceReport({ showExcludedServices }));
  }

  public navigateToUnmixed({ employeeId, serviceId }: { employeeId?: string; serviceId?: string }): void {
    combineLatest([this.startDate$, this.endDate$])
      .pipe(take(1))
      .subscribe(([startDate, endDate]) => {
        this._store.dispatch(
          go({ path: ["/insights/performed-services"], query: { startDate, endDate, employeeId, serviceId, subset: "unmixed" } })
        );
      });
  }

  public navigateToMixed({ employeeId, serviceId }: { employeeId?: string; serviceId?: string }): void {
    combineLatest([this.startDate$, this.endDate$])
      .pipe(take(1))
      .subscribe(([startDate, endDate]) => {
        this._store.dispatch(
          go({ path: ["/insights/performed-services"], query: { startDate, endDate, employeeId, serviceId, subset: "mixed" } })
        );
      });
  }
}
