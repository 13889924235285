import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ServiceCategory, ServiceDescription } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";

import * as EditServicesActions from "../store/edit-services.actions";
import * as fromEditServices from "../store/edit-services.selectors";
import * as fromServiceMenu from "../store/service-menu.selectors";

@Component({
  selector: "edit-services-container",
  templateUrl: "edit-services.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditServicesContainer {
  public saving$: Observable<boolean>;
  public selectedRecords$: Observable<ServiceDescription[]>;
  public serviceCategories$: Observable<ServiceCategory[]>;

  constructor(private _store: Store<AppState>) {
    this.saving$ = this._store.pipe(select(fromEditServices.getSaving));

    this.selectedRecords$ = _store.pipe(select(fromServiceMenu.getSelectedRecords));
    this.serviceCategories$ = _store.pipe(select(fromServiceMenu.getServiceCategories));
  }

  public save(partial: Partial<ServiceDescription>): void {
    this._store.dispatch(EditServicesActions.editServices({ partial }));
  }

  public close(): void {
    this._store.dispatch(EditServicesActions.close());
  }
}
