import { Action } from "@ngrx/store";
import { PagingMetadata, JsonObject } from "@getvish/stockpile";
import { Salon } from "@getvish/model";
import { isDefined } from "../../kernel/util";
import { pipe } from "fp-ts/lib/function";
import { option } from "fp-ts";

export enum Types {
  SEARCH = "[Salon] Search",
  UPDATE_SORT = "[Salon] Update Sort",
  LOAD_ALL = "[Salon] Load All",
  LOAD_ALL_SUCCESS = "[Salon] Load All Success",
  LOAD_ALL_FAIL = "[Salon] Load All Fail",
  NAVIGATE_TO_PAGE = "[Salon] Navigate to Page",
  ADD = "[Salon] Add",
  ADD_SUCCESS = "[Salon] Add Success",
  ADD_FAIL = "[Salon] Add Fail",
  UPDATE = "[Salon] Update",
  UPDATE_SUCCESS = "[Salon] Update Success",
  UPDATE_FAIL = "[Salon] Update Fail",
  NAVIGATE_EDIT_SALON = "[Salon] Navigate to Edit Salon",
  NAVIGATE_DELETE_SALON = "[Salon] Navigate Delete Salon",
  RETROACTIVELY_GENERATE_ORDERS = "[Salon] Retroactively Generate Orders",
  RETROACTIVELY_GENERATE_ORDERS_SUCCESS = "[Salon] Retroactively Generate Orders Success",
  RETROACTIVELY_GENERATE_ORDERS_FAIL = "[Salon] Retroactively Generate Orders Fail",
  EXPORT_FORMULAS = "[Salon] Export Formulas",
  EXPORT_FORMULAS_FAIL = "[Salon] Export Formulas Fail",
  EXPORT_USAGE_REPORT = "[Salon] Export Usage Report",
  EXPORT_USAGE_REPORT_FAIL = "[Salon] Export Usage Report Fail",
}

export class Search implements Action {
  public readonly type = Types.SEARCH;

  constructor(public payload: { filter: string }) {}
}

export class UpdateSort implements Action {
  public readonly type = Types.UPDATE_SORT;

  constructor(public payload: JsonObject) {}
}

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;

  constructor(public payload: { filter?: string; sort?: JsonObject; page?: number; limit?: number }) {
    this.payload.sort = pipe(
      option.fromNullable(payload.sort),
      option.fold(
        () => ({ name: 1 }),
        (sort) => sort
      )
    );
  }
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public payload: Salon[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class NavigateToPage implements Action {
  public readonly type = Types.NAVIGATE_TO_PAGE;

  constructor(public payload: { page: number }) {}
}

export class Add implements Action {
  public readonly type = Types.ADD;

  constructor(public payload: { salon: Salon }) {}
}

export class AddSuccess implements Action {
  public readonly type = Types.ADD_SUCCESS;

  constructor(public payload: Salon) {}
}

export class AddFail implements Action {
  public readonly type = Types.ADD_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class Update implements Action {
  public readonly type = Types.UPDATE;

  constructor(public payload: { salon: Salon }) {}
}

export class UpdateSuccess implements Action {
  public readonly type = Types.UPDATE_SUCCESS;

  constructor(public payload: Salon) {}
}

export class UpdateFail implements Action {
  public readonly type = Types.UPDATE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class NavigateEditSalon implements Action {
  public readonly type = Types.NAVIGATE_EDIT_SALON;

  constructor(public payload?: { salonId: string }) {}
}

export class NavigateDeleteSalon implements Action {
  public readonly type = Types.NAVIGATE_DELETE_SALON;

  constructor(public payload?: { salonId: string }) {}
}

export const addOrUpdate = (payload: { salon: Salon }) => (isDefined(payload.salon._id) ? new Update(payload) : new Add(payload));

export class RetroactivelyGenerateOrders implements Action {
  public readonly type = Types.RETROACTIVELY_GENERATE_ORDERS;

  constructor(public payload: { salon: Salon }) {}
}

export class RetroactivelyGenerateOrdersSuccess implements Action {
  public readonly type = Types.RETROACTIVELY_GENERATE_ORDERS_SUCCESS;
}

export class RetroactivelyGenerateOrdersFail implements Action {
  public readonly type = Types.RETROACTIVELY_GENERATE_ORDERS_FAIL;
}

export class ExportFormulas implements Action {
  public readonly type = Types.EXPORT_FORMULAS;

  constructor(public payload: { slug: string }) {}
}

export class ExportFormulasFail implements Action {
  public readonly type = Types.EXPORT_FORMULAS_FAIL;
}

export class ExportUsageReport implements Action {
  public readonly type = Types.EXPORT_USAGE_REPORT;
}

export class ExportUsageReportFail implements Action {
  public readonly type = Types.EXPORT_USAGE_REPORT_FAIL;
}

export type Actions =
  | Search
  | UpdateSort
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Add
  | AddSuccess
  | Update
  | UpdateSuccess
  | NavigateEditSalon
  | NavigateDeleteSalon
  | RetroactivelyGenerateOrders
  | RetroactivelyGenerateOrdersSuccess
  | RetroactivelyGenerateOrdersFail
  | ExportFormulas
  | ExportFormulasFail
  | ExportUsageReport
  | ExportUsageReportFail;
