import { AbstractControl } from "@angular/forms";
import { isNil } from "ramda";

export const isValidSlug = (control: AbstractControl) => {
  const value = control.value;
  const invalidSlug = RegExp(/[^a-zA-Z0-9_-]+/g);

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined
  } else {
    return invalidSlug.test(value) ? { isValidSlug: true } : null;
  }
};
