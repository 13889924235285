import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { range, not, isNil } from "ramda";

interface Page {
  value: number;
  label: string;
  isEnabled: boolean;
  icon?: IconName;
  class?: string;
  isActive?: boolean;
}

@Component({
  selector: "paging",
  templateUrl: "paging.component.html",
  styleUrls: ["paging.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagingComponent implements OnChanges {
  @Input() public count: number;
  @Input() public currPage: number;
  @Input() public limit: number;
  @Input() public numPagesToShow: number = 5;
  @Output() public changePage: EventEmitter<number>;

  public shouldRender: boolean;
  public pages: Page[];

  constructor() {
    this.changePage = new EventEmitter(true);
  }

  ngOnChanges(): void {
    this.pages = this._generatePages(this.count, this.limit, this.currPage, this.numPagesToShow);
    this.shouldRender = this.calculateTotalPages(this.count, this.limit) > 1;
  }

  public selectPage(page: number): void {
    // so apparently the (click) event on buttons doesn't get disabled, even when you set the button to [disabled]="true"
    // so we'll just have to do a quick check here to make sure that @page is defined before we
    // go ahead and emit it
    if (not(isNil(page))) {
      this.changePage.emit(page);
    }
  }

  private _getPageRange(currPage: number, totalPages: number, numPagesToShow: number): number[] {
    // calculate how many pages to show on "either side" of the current page
    // i.e. if we have 5 pages and we're on page 3, we'll want to render: 1 2 _3_ 4 5
    const offset = Math.floor(numPagesToShow / 2);

    // if subtracting the offset from the current page less than 1, we'll start at the first page
    const lowerBound = Math.max(1, currPage - offset);
    // if adding the offset to the current page exceeds the number of total pages
    // then we'll use the last page as the upper bound
    const upperBound = Math.min(totalPages, currPage + offset);

    // @NOTE we're always adding + 1 to the upperbound of the range because #range is exclusive
    if (lowerBound === 1) {
      // we're starting at the first page
      // so we'll either show @numPagesToShow
      // or, if we don't have enough pages, we'll just take until @totalPages
      return range(1, Math.min(totalPages, numPagesToShow) + 1);
    } else if (upperBound === totalPages) {
      // if we're at the upper bound limit, then take the @upperBound and subtract the number of pages we want to render
      // unless subtracting @numPagesToShow would be < 1 (i.e. 0 or negative, which is impossible)
      // then just take 1 as the lower bound, here
      return range(Math.max(1, upperBound - numPagesToShow), upperBound + 1);
    } else {
      // in every other case just take the range from @lowerBound --> @upperBound, just render all pages
      return range(lowerBound, upperBound + 1);
    }
  }

  private calculateTotalPages(count: number, limit: number): number {
    return Math.ceil(count / limit);
  }

  private _generatePages(count: number, limit: number, currentPage: number, _numPagesToShow: number): Page[] {
    const totalPages = this.calculateTotalPages(count, limit);
    const pageRange = this._getPageRange(this.currPage, totalPages, this.numPagesToShow);

    const nextPage = {
      value: currentPage < totalPages ? currentPage + 1 : undefined,
      label: "Next Page",
      isEnabled: currentPage < totalPages,
      isActive: false,
      icon: "angle-right",
      class: "next-page",
    };

    const prevPage = {
      value: currentPage > 1 ? currentPage - 1 : undefined,
      label: "Prev Page",
      isEnabled: currentPage > 1,
      isActive: false,
      icon: "angle-left",
      class: "prev-page",
    };

    const numberedPages = pageRange.map((pageNumber) => {
      return { value: pageNumber, label: pageNumber.toString(), isEnabled: true, isActive: pageNumber === currentPage };
    });

    return [prevPage, ...numberedPages, nextPage];
  }
}
