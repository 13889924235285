<div class="content">
  <h2>Choose a new password</h2>
  <p>Verification code confirmed. Please enter a new password below.</p>

  <div class="form-wrapper">
    <form
      [formGroup]="form"
      (submit)="submit(form.value)">
      <mat-form-field class="full-width">
        <mat-label>Choose a password</mat-label>
        <input
          matInput
          formControlName="password"
          type="password" />
        <mat-error *ngIf="form.get('password').hasError('required')">Required</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Confirm password</mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          type="password" />
        <mat-error *ngIf="form.get('confirmPassword').hasError('required')">Required</mat-error>
      </mat-form-field>

      <mat-error *ngIf="form.errors?.equals && (form.touched || form.dirty)">
        <alert-component type="danger"> Passwords do not match </alert-component>
      </mat-error>

      <div class="controls">
        <button
          [disabled]="form.invalid"
          type="submit"
          class="button primary">
          Update password
        </button>
      </div>
    </form>
  </div>
</div>
