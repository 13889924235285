import { Salon } from "@getvish/model";
import { JsonObject } from "@getvish/stockpile";
import { createAction, props } from "@ngrx/store";

export const navigate = createAction("[Import Employees] Navigate");

export const searchSalons = createAction("[Admin] Search Salons", (filter: string, sort: JsonObject = { name: 1 }) => ({ filter, sort }));

export const searchSalonsSuccess = createAction("[Admin] Search Salons Success", props<{ salons: Salon[] }>());

export const clearSalonFilter = createAction("[Admin] Clear Salon Filter");

export const searchSalonsFail = createAction("[Admin] Search Salons Fail", props<{ error: string }>());
