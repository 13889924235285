import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl, UntypedFormControl, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatFormFieldControl } from "@angular/material/form-field";
import { longerThan } from "app/kernel";
import { Subject, Subscription, debounceTime, filter, switchMap, take, tap, zip } from "rxjs";
import { Location } from "../models/location";
import { LocationSuggestion } from "../models/location-suggestion";
import { LocationAutocompleteService } from "../services/location-autocomplete.service";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { FocusMonitor } from "@angular/cdk/a11y";

@Component({
  selector: "location-autocomplete",
  templateUrl: "location-autocomplete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: LocationAutocompleteComponent }],
})
export class LocationAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {
  public formControl: UntypedFormControl;
  public searching = false;
  public locationSuggestions: Array<LocationSuggestion>;

  private static nextId = 0;

  private _disabled = false;
  private _focused = false;
  private _touched = false;
  private _placeholder = "";
  private _required = false;
  private _value: string;
  private _locationControlSubscription: Subscription;

  stateChanges = new Subject<void>();

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
    this.stateChanges.next();
    this.onChange(value);
  }

  @Output()
  public select: EventEmitter<Location>;

  @HostBinding("attr.aria-describedby")
  describedBy = "";

  @HostBinding()
  id = `location-autocomplete-${LocationAutocompleteComponent.nextId++}`;

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @ViewChild("input")
  inputRef: ElementRef<HTMLInputElement>;

  controlType = "location-autocomplete";

  get empty() {
    return this.value == null || this.value.length <= 0;
  }

  get errorState(): boolean {
    return this._touched && this.ngControl.control != null ? !this.ngControl.control.valid : false;
  }

  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(" ");
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== "input") {
      this.focusMonitor.focusVia(this.inputRef.nativeElement, "mouse");
    }
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  onChange(_: string): void {}

  registerOnChange(onChange: (value: string | null) => void): void {
    this.onChange = onChange;
  }

  onTouched(): void {}

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  constructor(
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private _cd: ChangeDetectorRef,
    private _locationAutocompleteService: LocationAutocompleteService
  ) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.select = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.formControl = new UntypedFormControl(this.value, [Validators.required, longerThan(2)]);

    this._locationControlSubscription = zip(this.formControl.valueChanges, this.formControl.statusChanges)
      .pipe(
        tap(([value]) => {
          this.value = value;
        }),
        filter(([_, status]) => status === "VALID"),
        debounceTime(250),
        switchMap(([value]) => {
          this.searching = true;
          this._cd.markForCheck();
          return this._locationAutocompleteService.getSuggestions(value);
        })
      )
      .subscribe((locationSuggestions: Array<LocationSuggestion>) => {
        this.searching = false;
        this.locationSuggestions = locationSuggestions;
        this._cd.markForCheck();
      });
  }

  public ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe((focusOrigin) => {
      this.focused = focusOrigin != null;
      this._touched = true;
    });
  }

  public ngOnDestroy(): void {
    if (this._locationControlSubscription != null) {
      this._locationControlSubscription.unsubscribe();
      this._locationControlSubscription = null;
    }

    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  public displayLocationSuggestion(locationSuggestion: LocationSuggestion | string): string {
    if (typeof locationSuggestion === "string") {
      return locationSuggestion;
    }

    return locationSuggestion?.text?.split(",")?.[0];
  }

  public selectLocationSuggestion(event: MatAutocompleteSelectedEvent): void {
    const locationSuggestion = event.option.value as LocationSuggestion;

    if (locationSuggestion != null) {
      this._locationAutocompleteService
        .getLocation(locationSuggestion.id)
        .pipe(take(1))
        .subscribe((location: Location) => {
          this.select.emit(location);
        });
    }
  }
}
