import { Injectable } from "@angular/core";
import { Manufacturer } from "@getvish/model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { either } from "fp-ts";
import { fold } from "fp-ts/Option";
import { of } from "rxjs";
import { catchError, debounceTime, map, mapTo, mergeMap, switchMap } from "rxjs/operators";
import { ManufacturerService } from "../services";

import * as RouterActions from "../../../kernel/store/actions/router.actions";
import * as ManufacturerActions from "./manufacturer.actions";

@Injectable()
export class ManufacturerEffects {
  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.LoadAll>(ManufacturerActions.Types.LOAD_ALL),
      map((action) => action.payload),
      switchMap((payload) =>
        this._manufacturerService.find(payload.criteria, payload.sort, payload.page, payload.limit).pipe(
          mergeMap((result) => [new ManufacturerActions.LoadAllSuccess(result.records, result.paging)]),
          catchError((reason) => of(new ManufacturerActions.LoadAllFail({ errors: reason })))
        )
      )
    )
  );

  public navigateToPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.NavigateToPage>(ManufacturerActions.Types.NAVIGATE_TO_PAGE),
      map((action) => action.payload.page),
      map((page) => RouterActions.go({ path: ["/product/manufacturers"], query: { page }, extras: { queryParamsHandling: "merge" } }))
    )
  );

  public new$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ManufacturerActions.Types.NEW),
      mapTo(
        RouterActions.go({
          path: ["/product/manufacturers", { outlets: { panel: "new" } }],
          extras: { queryParamsHandling: "merge" },
        })
      )
    )
  );

  public add$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.Add>(ManufacturerActions.Types.ADD),
      map((action) => action.payload.manufacturer),
      switchMap((manufacturer) =>
        this._manufacturerService.insert(manufacturer).pipe(
          mergeMap(
            either.fold<globalThis.Error, Manufacturer, Action[]>(
              (error) => [new ManufacturerActions.AddFail({ error })],
              (updatedManufacturer) => [new ManufacturerActions.AddSuccess(updatedManufacturer)]
            )
          )
        )
      )
    )
  );

  public addOrUpdateSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ManufacturerActions.Types.ADD_SUCCESS, ManufacturerActions.Types.UPDATE_SUCCESS),
      mapTo(RouterActions.go({ path: ["/product/manufacturers"], extras: { queryParamsHandling: "merge" } }))
    )
  );

  public edit$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.Edit>(ManufacturerActions.Types.EDIT),
      map((action) => action.payload.manufacturer),
      map((manufacturer) => manufacturer._id),
      map((manufacturerId) =>
        RouterActions.go({
          path: ["/product/manufacturers", { outlets: { panel: `edit/${manufacturerId}` } }],
          extras: { queryParamsHandling: "merge" },
        })
      )
    )
  );

  public update$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.Update>(ManufacturerActions.Types.UPDATE),
      map((action) => action.payload.manufacturer),
      switchMap((manufacturer) =>
        this._manufacturerService.update(manufacturer).pipe(
          mergeMap(
            either.fold<globalThis.Error, Manufacturer, Action[]>(
              (error) => [new ManufacturerActions.UpdateFail({ error })],
              (updatedManufacturer) => [new ManufacturerActions.UpdateSuccess(updatedManufacturer)]
            )
          )
        )
      )
    )
  );

  public loadById$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.LoadManufacturerById>(ManufacturerActions.Types.LOAD_BY_ID),
      map(({ payload }) => payload.manufacturerId),
      switchMap((manufacturerId: string) =>
        this._manufacturerService.findById(manufacturerId).pipe(
          mergeMap(
            fold<Manufacturer, Action[]>(
              () => [
                new ManufacturerActions.LoadManufacturerFail({
                  error: new Error("Manufacturer not found"),
                }),
              ],
              (manufacturer) => [
                new ManufacturerActions.LoadManufacturerSuccess({
                  manufacturer,
                }),
              ]
            )
          )
        )
      )
    )
  );

  public handleFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.SetAdminFilter>(ManufacturerActions.Types.SET_ADMIN_FILTER),
      debounceTime(500),
      map((action) => action.payload),
      map((payload) =>
        RouterActions.go({ path: [], query: { filter: payload.filter, page: undefined }, extras: { queryParamsHandling: "merge" } })
      )
    )
  );

  public handleSort$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ManufacturerActions.SetAdminFilter>(ManufacturerActions.Types.SET_ADMIN_SORT),
      map((action) => action.payload),
      map((payload) =>
        RouterActions.go({ path: [], query: { sort: JSON.stringify(payload), page: undefined }, extras: { queryParamsHandling: "merge" } })
      )
    )
  );

  constructor(
    private _manufacturerService: ManufacturerService,
    private _actions$: Actions
  ) {}
}
