import { HttpRepositoryFactory, EntityService } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { SalonApiVersion } from "app/kernel/models/salon-api-version";
import { map } from "rxjs/operators";
import { flatten, splitEvery, uniq } from "ramda";
import { option } from "fp-ts";

@Injectable()
export class SalonApiVersionService extends EntityService<SalonApiVersion> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "apiVersions" });
  }

  public findForSalonIds(salonIds: string[]): Observable<SalonApiVersion[]> {
    const uniqueIds = uniq(salonIds);
    const idChunks = splitEvery(40, uniqueIds);

    const requests = idChunks.map((ids) => {
      const criteria = { salonId: { $in: ids } };

      return this.find(criteria).pipe(map((result) => result.records));
    });

    return salonIds.length === 0 ? of([]) : forkJoin(requests).pipe(map(flatten));
  }

  public findForSalonId(salonId: string): Observable<option.Option<SalonApiVersion>> {
    return this.findOne({ salonId });
  }
}
