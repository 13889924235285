<slideout-panel (close)="close()">
  <div panel-header>Edit Product</div>

  <edit-salon-product-component
    *ngIf="product$ | async | isDefined"
    [product]="product$ | async"
    [measurementUnit]="measurementUnit$ | async"
    [currency]="currency$ | async"
    (save)="save($event)"
    (close)="close()"
    panel-body>
  </edit-salon-product-component>
</slideout-panel>
