import { Injectable } from "@angular/core";
import { NotificationService, NotificationsWithProvider } from "./notification.service";
import { filter, forkJoin, map, mergeMap, Observable, of } from "rxjs";
import { ReactiveIDBDatabase, ReactiveIDBObjectStore } from "@creasource/reactive-idb";
import { isNil } from "ramda";
import { Notification } from "../models";

type NotificationRecord = {
  id: string;
  providerId: string;
  created: Date;
  received: Date;
  read: boolean;
};

@Injectable()
export class IdbNotificationService extends NotificationService {
  private db$?: Observable<ReactiveIDBDatabase>;

  constructor() {
    super();

    this.db$ = window.indexedDB
      ? ReactiveIDBDatabase.create({ name: "notifications", schema: [{ version: 1, stores: ["notifications"] }] })
      : undefined;

    this.clearStaleNotifications();
  }

  protected override preprocessNotifications(
    notificationsWithProviders: NotificationsWithProvider[]
  ): Observable<NotificationsWithProvider[]> {
    if (isNil(this.db$)) {
      return of(notificationsWithProviders);
    }

    return this.db$.pipe(
      mergeMap((db) => db.transaction$(["notifications"], "readwrite")),
      map((transaction) => transaction.objectStore<NotificationRecord>("notifications")),
      mergeMap((store) =>
        this._syncWithDb(notificationsWithProviders, store).pipe(
          mergeMap((notificationsWithProviders) =>
            this._purgeMissingNotifications(notificationsWithProviders, store).pipe(map(() => notificationsWithProviders))
          )
        )
      )
    );
  }

  private _purgeMissingNotifications(
    notificationsWithProviders: NotificationsWithProvider[],
    store: ReactiveIDBObjectStore<NotificationRecord>
  ): Observable<void> {
    if (notificationsWithProviders.length === 0) {
      return of(undefined);
    }

    return store.getAll$().pipe(
      mergeMap((records) =>
        forkJoin(
          notificationsWithProviders.map((notificationsWithProvider) => {
            const providerId = notificationsWithProvider.providerId;
            const notifications = notificationsWithProvider.notifications;

            const providerRecords = records.filter((record) => record.providerId === providerId);

            const recordsToDelete = providerRecords.filter(
              (record) => !notifications.some((notification) => notification.id === record.id)
            );

            if (recordsToDelete.length) {
              return forkJoin(recordsToDelete.map((record) => store.delete$(record.id)));
            } else {
              return of(undefined);
            }
          })
        )
      ),
      map(() => undefined)
    );
  }

  private _syncWithDb(
    notificationsWithProviders: NotificationsWithProvider[],
    store: ReactiveIDBObjectStore<NotificationRecord>
  ): Observable<NotificationsWithProvider[]> {
    if (notificationsWithProviders.length === 0) {
      return of([]);
    }

    return forkJoin(
      notificationsWithProviders.map((notificationsWithProvider) =>
        (notificationsWithProvider.notifications?.length ?? 0) === 0
          ? of(notificationsWithProvider)
          : forkJoin(
              notificationsWithProvider.notifications.map((notification) =>
                store.get$(notification.id).pipe(
                  mergeMap((record) =>
                    isNil(record)
                      ? store
                          .add$(
                            {
                              id: notification.id,
                              providerId: notification.providerId,
                              created: new Date(),
                              received: new Date(),
                              read: false,
                            },
                            notification.id
                          )
                          .pipe(map(() => record))
                      : store.put$({ ...record, received: new Date() }, notification.id).pipe(map(() => record))
                  ),
                  map((record) => ({
                    ...notification,
                    date: notification.date ?? record?.created ?? new Date(),
                    isRead: record?.read ?? false,
                  }))
                )
              )
            ).pipe(map((notifications) => ({ ...notificationsWithProvider, notifications })))
      )
    );
  }

  public override markAsRead(notifications: Notification[]): Observable<void> {
    if (isNil(this.db$)) {
      return of(undefined);
    }

    return this.db$.pipe(
      mergeMap((db) => db.transaction$(["notifications"], "readwrite")),
      map((transaction) => transaction.objectStore<NotificationRecord>("notifications")),
      mergeMap((store) =>
        forkJoin(
          notifications.map((notification) =>
            store
              .get$(notification.id)
              .pipe(
                mergeMap((record) =>
                  isNil(record)
                    ? store.add$(
                        { id: notification.id, providerId: notification.providerId, created: new Date(), received: new Date(), read: true },
                        notification.id
                      )
                    : store.put$({ ...record, received: new Date(), read: true }, notification.id)
                )
              )
          )
        )
      ),
      map(() => undefined)
    );
  }

  private clearStaleNotifications(): void {
    if (isNil(this.db$)) {
      return;
    }

    this.db$
      .pipe(
        mergeMap((db) => db.transaction$(["notifications"], "readwrite")),
        map((transaction) => transaction.objectStore<NotificationRecord>("notifications")),
        mergeMap((store) =>
          store.getAll$().pipe(
            map((records) => records.filter((record) => record.received < new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))),
            filter((records) => records.length > 0),
            mergeMap((records) => forkJoin(records.map((record) => store.delete$(record.id))))
          )
        )
      )
      .subscribe();
  }
}
