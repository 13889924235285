import { Directive, Input, HostBinding } from "@angular/core";

@Directive({
  selector: "[slideoutRevealPanel]",
})
export class SlideoutRevealPanelDirective {
  @Input()
  public set slideoutRevealPanel(value: boolean) {
    this._panelOpen = value;
    this._panelClosed = !value;
  }

  @HostBinding("class.slideout-panel-open")
  public _panelOpen = false;

  @HostBinding("class.slideout-panel-closed")
  public _panelClosed = true;
}
