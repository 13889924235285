import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { HTTP_URL } from "../../kernel/services/common";
import { WindowService } from "app/kernel";
import { AuthStorageService } from "app/+auth/services";

@Injectable()
export class ProductReportDownloaderService {
  constructor(
    private _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string,
    private _windowService: WindowService,
    private _authStorage: AuthStorageService
  ) {}

  public downloadReport(salonId: string, manufacturerId: string, startDate: number, endDate: number): Observable<Blob> {
    const params = new HttpParams()
      .set("manufacturerId", manufacturerId)
      .set("salonId", salonId)
      .set("startDate", startDate.toString())
      .set("endDate", endDate.toString());

    const headers = new HttpHeaders({
      "X-Salon-Slug": this._windowService.tenantPathName,
      "X-Auth-Token": this._authStorage.getAuthToken(),
    });

    return this._http.get(`${this._httpUrl}/productReports/excel`, { params, responseType: "blob", headers });
  }
}
