import { NgModule } from "@angular/core";
import { SharedModule } from "app/kernel/shared";

import { BowlComponent, DropletComponent, GenericMixingContainerComponent } from "./components";

@NgModule({
  imports: [SharedModule],
  declarations: [BowlComponent, DropletComponent, GenericMixingContainerComponent],
  exports: [BowlComponent, DropletComponent],
})
export class MixingContainerModule {}
