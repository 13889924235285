import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Manufacturer, SalonManufacturer } from "@getvish/model";
import { ListOrdererItem } from "app/+components/+list-orderer/components/list-orderer.component";
import { pipe } from "fp-ts/lib/function";
import * as R from "ramda";
import * as O from "fp-ts/Option";

const getOrDefaultOrder = (order: number | undefined) => O.getOrElse(() => Number.MAX_SAFE_INTEGER)(O.fromNullable(order));

const sortSalonManufacturers = (manufacturers: Manufacturer[]) => (m1: SalonManufacturer, m2: SalonManufacturer) => {
  const ret = getOrDefaultOrder(m1.order) - getOrDefaultOrder(m2.order);

  if (ret === 0) {
    const n1 = manufacturers.find((m) => m._id === m1.manufacturerId).name;
    const n2 = manufacturers.find((m) => m._id === m2.manufacturerId).name;

    return n1.localeCompare(n2, undefined, { sensitivity: "base" });
  }

  return ret;
};

@Component({
  selector: "manufacturer-order-dialog",
  templateUrl: "./manufacturer-order-dialog.component.html",
  styleUrls: ["./manufacturer-order-dialog.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerOrderDialogComponent {
  public items: ListOrdererItem[];
  private salonManufacturers: SalonManufacturer[];

  constructor(
    private _matDialogRef: MatDialogRef<ManufacturerOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      manufacturers: Manufacturer[];
      salonManufacturers: SalonManufacturer[];
    }
  ) {
    this.salonManufacturers = data.salonManufacturers;

    this.items = pipe(
      data.salonManufacturers,
      R.uniqBy((salonManufacturer) => salonManufacturer.manufacturerId),
      R.sort(sortSalonManufacturers(data.manufacturers)),
      R.map((salonManufacturer) => ({
        id: salonManufacturer._id,
        manufacturerId: salonManufacturer.manufacturerId,
        text: data.manufacturers.find((m) => m._id === salonManufacturer.manufacturerId).name,
      }))
    );
  }

  public orderChanged(items: ListOrdererItem[]) {
    this.items = items.map((item) => ({
      ...(this.items.find((i) => i.id === item.id) ?? []),
      ...item,
    }));
  }

  public save(): void {
    const changes = this.salonManufacturers.reduce((acc, m) => {
      const idx = this.items.findIndex((i) => i["manufacturerId"] === m.manufacturerId);

      if (m.order !== idx) {
        acc.push({ ...m, order: idx });
      }

      return acc;
    }, []);

    this._matDialogRef.close(changes.length === 0 ? undefined : changes);
  }

  public close(): void {
    this._matDialogRef.close();
  }

  public sortAlphabetical() {
    this.items = R.sortBy((i) => i.text, this.items);
  }

  public sortReverseAlphabetical() {
    this.items = R.reverse(R.sortBy((i) => i.text, this.items));
  }
}
