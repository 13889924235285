import { Action } from "@ngrx/store";
import { SalonConfig } from "@getvish/model";
import { Currency } from "../services";
import { Timezone } from "../../kernel/models/timezone";
import { PagedResult } from "@getvish/stockpile";
import { Salon } from "app/+salons/models";

export enum Types {
  LOAD_CURRENT_SALON = "[Salon Config] Load Current Salon",
  LOAD_CURRENT_SALON_SUCCESS = "[Salon Config] Load Current Salon Success",
  LOAD_CURRENT_SALON_FAIL = "[Salon Config] Load Current Salon Fail",
  SAVE_SALON = "[Salon Config] Save Salon",
  SAVE_SALON_SUCCESS = "[Salon Config] Save Salon Success",
  SAVE_SALON_FAIL = "[Salon Config] Save Salon Fail",
  LOAD_SALON_CONFIG = "[Salon Config] Load Salon Config",
  LOAD_SALON_CONFIG_SUCCESS = "[Salon Config] Load Salon Config Success",
  LOAD_SALON_CONFIG_FAIL = "[Salon Config] Load Salon Config Fail",
  SAVE_SALON_CONFIG = "[Salon Config] Save Salon Config",
  SAVE_SALON_CONFIG_SUCCESS = "[Salon Config] Save Salon Config Success",
  SAVE_SALON_CONFIG_FAIL = "[Salon Config] Save Salon Config Fail",
  LOAD_MANUFACTURERS = "[Salon Config] Load Manufacturers",
  LOAD_MANUFACTURERS_SUCCESS = "[Salon Config] Load Manufacturers Success",
  ADD_PROVIDER = "[Salon Config] Add Provider",
  LOAD_TIMEZONES_SUCCESS = "[Salon Config] Load Timezones Success",
  LOAD_TIMEZONES_FAIL = "[Salon Config] Load Timezones Fail",
  LOAD_CURRENCIES_SUCCESS = "[Salon Config] Load Currencies Success",
  MANAGE_PAYMENT_METHODS = "[Salon Cofig] Manage Payment Methods",
}

export class LoadCurrentSalon implements Action {
  public readonly type = Types.LOAD_CURRENT_SALON;

  constructor(public salonConfig?: SalonConfig) {}
}

export class LoadCurrentSalonSuccess implements Action {
  public readonly type = Types.LOAD_CURRENT_SALON_SUCCESS;

  constructor(public payload: Salon) {}
}

export class LoadCurrentSalonFail implements Action {
  public readonly type = Types.LOAD_CURRENT_SALON_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class SaveSalon implements Action {
  public readonly type = Types.SAVE_SALON;

  constructor(public payload: { salon: Salon }) {}
}

export class SaveSalonSuccess implements Action {
  public readonly type = Types.SAVE_SALON_SUCCESS;

  constructor(public payload: Salon) {}
}

export class SaveSalonFail implements Action {
  public readonly type = Types.SAVE_SALON_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class LoadSalonConfig implements Action {
  public readonly type = Types.LOAD_SALON_CONFIG;
}

export class LoadSalonConfigSuccess implements Action {
  public readonly type = Types.LOAD_SALON_CONFIG_SUCCESS;

  constructor(public payload: SalonConfig) {}
}

export class LoadSalonConfigFail implements Action {
  public readonly type = Types.LOAD_SALON_CONFIG_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class SaveSalonConfig implements Action {
  public readonly type = Types.SAVE_SALON_CONFIG;

  constructor(public payload: { config: SalonConfig }) {}
}

export class SaveSalonConfigSuccess implements Action {
  public readonly type = Types.SAVE_SALON_CONFIG_SUCCESS;

  constructor(public payload: SalonConfig) {}
}

export class SaveSalonConfigFail implements Action {
  public readonly type = Types.SAVE_SALON_CONFIG_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class LoadTimezonesSuccess implements Action {
  public readonly type = Types.LOAD_TIMEZONES_SUCCESS;

  constructor(public payload: PagedResult<Timezone>) {}
}

export class LoadTimezonesFail implements Action {
  public readonly type = Types.LOAD_TIMEZONES_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class LoadCurrenciesSuccess implements Action {
  public readonly type = Types.LOAD_CURRENCIES_SUCCESS;

  constructor(public payload: { currencies: Currency[] }) {}
}

export class ManagePaymentMethods implements Action {
  public readonly type = Types.MANAGE_PAYMENT_METHODS;
}

export type Actions =
  | LoadCurrentSalon
  | LoadCurrentSalonSuccess
  | LoadCurrentSalonFail
  | SaveSalon
  | SaveSalonSuccess
  | SaveSalonFail
  | LoadSalonConfig
  | LoadSalonConfigSuccess
  | LoadSalonConfigFail
  | SaveSalonConfig
  | SaveSalonConfigSuccess
  | SaveSalonConfigFail
  | LoadTimezonesSuccess
  | LoadTimezonesFail
  | LoadCurrenciesSuccess
  | ManagePaymentMethods;
