import { Appointment, Employee } from "@getvish/model";
import { createReducer, on } from "@ngrx/store";

import * as actions from "./schedule-appointment.actions";

export interface State {
  employees: Employee[];
  loading: boolean;
  saving: boolean;
  selected: Appointment;
  selectedCustomerId: string;
  filter: string;
  error: string;
}

const initialState: State = {
  employees: [],
  loading: false,
  saving: false,
  selected: undefined,
  selectedCustomerId: undefined,
  filter: undefined,
  error: undefined,
};

export const reducer = createReducer<State, actions.Actions>(
  initialState,
  on(actions.load, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.loadSuccess, (state, action) => ({
    ...state,
    loading: false,
    employees: action.employees,
  })),
  on(actions.searchCustomers, (state, { filter }) => ({
    ...state,
    filter,
  })),
  on(actions.schedule, (state, { appointment }) => ({
    ...state,
    selectedCustomerId: appointment.customerId,
    saving: true,
  })),
  on(actions.fail, (state, { error }) => ({
    ...state,
    error: error.message,
  })),
  on(actions.close, actions.cancel, actions.success, () => ({
    ...initialState,
  }))
);
