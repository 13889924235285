import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Observable, of } from "rxjs";

const DEFAULT_CONFIG: MatSnackBarConfig = { duration: 2500 };
@Injectable()
export class SnackbarService {
  constructor(private _snackbar: MatSnackBar) {}

  public info(message: string, config: MatSnackBarConfig = DEFAULT_CONFIG): Observable<MatSnackBarRef<SimpleSnackBar>> {
    return of(this._snackbar.open(message, undefined, config));
  }

  public prompt(message: string, action: string, config: MatSnackBarConfig = DEFAULT_CONFIG): Observable<MatSnackBarRef<SimpleSnackBar>> {
    return of(this._snackbar.open(message, action, config));
  }

  public dismiss(): Observable<void> {
    return of(this._snackbar.dismiss());
  }
}
