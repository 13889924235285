<ng-container>
  <mat-menu #controlsMenu="matMenu">
    <button
      *ngFor="let menuItem of items"
      mat-menu-item
      (click)="menuItem.onClick()">
      {{ menuItem.title }}
    </button>
  </mat-menu>
  <button
    stopPropagation
    [matMenuTriggerFor]="controlsMenu"
    class="button transparent cell-minimal-width">
    <fa-icon icon="ellipsis-v"></fa-icon>
  </button>
</ng-container>
