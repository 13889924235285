import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, catchError, switchMap, mergeMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { AppointmentService } from "../services";
import { either } from "fp-ts";
import { Action, Store } from "@ngrx/store";
import { FrontDeskService } from "../services/front-desk.service";

import * as routerActions from "../../kernel/store/actions/router.actions";
import * as actions from "./appointment.actions";
import * as fromAppointment from "../store/appointment.selectors";
import * as salesActions from "./order.actions";
import { AppState } from "app/kernel";

@Injectable()
export class AppointmentEffects {
  public navigateToAppointmentsList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigateToAppointmentsList),
      mergeMap(() => [actions.loadCurrentAppointments(), routerActions.go({ path: ["/front-desk", { outlets: { panel: null } }] })])
    )
  );

  public loadCurrentAppointments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.loadCurrentAppointments),
      switchMap(() => {
        return this._frontDeskService.loadAppointmentsForToday().pipe(
          mergeMap(({ appointments }) => [new actions.LoadCurrentAppointmentsSuccess(appointments.records, appointments.paging)]),
          catchError((reason) => {
            return of(actions.loadCurrentAppointmentsFail({ error: reason }));
          })
        );
      })
    )
  );

  public select$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.select),
      map((action) => action.appointment),
      map((appointment) => salesActions.navigateToOrder({ appointment }))
    )
  );

  public delete$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Delete),
      map((action) => action.appointment._id),
      switchMap((id) =>
        this._appointmentService.remove(id).pipe(
          mergeMap(
            either.fold<Error, string, Action[]>(
              (fail) => [actions.DeleteFail({ error: fail })],
              () => [new actions.DeleteSuccess(id)]
            )
          ),
          catchError((error) => of(actions.DeleteFail({ error })))
        )
      )
    )
  );

  public showResolve$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.SHOW_RESOLVE),
      map(() => routerActions.go({ path: ["/front-desk", { outlets: { panel: "resolve" } }], extras: { skipLocationChange: true } }))
    )
  );

  public loadResolve$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.LOAD_RESOLVE),
      withLatestFrom(this._store.select(fromAppointment.getResolveAppointments)),
      switchMap(([_, appointments]) =>
        this._appointmentService.loadAppointmentChangesData(appointments).pipe(
          map(({ serviceDescriptions }) => new actions.LoadResolveSuccess(serviceDescriptions)),
          catchError((error) => of(actions.LoadResolveFail({ error })))
        )
      )
    )
  );

  public closeResolve$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.CloseResolve),
      map(() => routerActions.go({ path: ["/front-desk", { outlets: { panel: null } }] }))
    )
  );

  public resolve$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Resolve),
      map((action) => action.appointments.map((appointment) => appointment._id)),
      switchMap((ids) =>
        this._appointmentService.markChangesAsResolved(ids).pipe(
          map(() => new actions.ResolveSuccess(ids)),
          catchError((error) => of(actions.ResolveFail({ error })))
        )
      )
    )
  );

  constructor(
    private _appointmentService: AppointmentService,
    private _frontDeskService: FrontDeskService,
    private _actions$: Actions,
    private _store: Store<AppState>
  ) {}
}
