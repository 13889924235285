<div class="content">
  <h2>Password updated successfully</h2>

  <p>Your password has been updated successfully. You can now log in as usual using your new password.</p>

  <div class="controls">
    <button
      (click)="navigateToLogin()"
      type="button"
      class="button primary">
      Go to login
    </button>
  </div>
</div>
