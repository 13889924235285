<div
  class="page-content"
  [progressIndicator]="progressText">
  <!-- Header -->
  <div class="page-header-outer">
    <div
      class="page-header"
      id="page-header">
      <div class="title">
        <h1 *ngIf="pageTitle">
          <span class="content">{{ pageTitle }}</span>
        </h1>
        <ng-content select="[page-subtitle]"></ng-content>
      </div>
      <ng-content select="[page-header]"></ng-content>
    </div>
    <!-- Breadcrmbs -->
    <xng-breadcrumb
      *ngIf="showBreadcrumbs"
      separator=">"></xng-breadcrumb>
  </div>

  <!-- Content -->
  <div
    class="main-content"
    id="main-content">
    <section>
      <ng-content
        *ngIf="!error; else errorElement"
        select="[page-body]"></ng-content>
    </section>
  </div>

  <!-- Footer -->
  <div class="page-footer">
    <ng-content select="[page-footer]"></ng-content>
  </div>

  <!-- Error -->
  <ng-template #errorElement>
    <alert-component type="danger">
      <h4>An Error Has Occured</h4>

      <p>{{ error }}</p>
    </alert-component>
  </ng-template>
</div>
