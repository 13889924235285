import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "colored-icon-cell",
  templateUrl: "colored-icon-cell.component.html",
  styleUrls: ["colored-icon-cell.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColoredIconCellComponent {
  @Input()
  public title: string;

  @Input()
  public numChars?: number;
}
