<autocomplete-search-input [isSearching]="searching">
  <input
    #input
    type="text"
    placeholder="Search for locations..."
    matInput
    [formControl]="formControl"
    [matAutocomplete]="locationAuto" />
</autocomplete-search-input>
<mat-autocomplete
  #locationAuto="matAutocomplete"
  [displayWith]="displayLocationSuggestion"
  (optionSelected)="selectLocationSuggestion($event)">
  <mat-option
    *ngFor="let locationSuggestion of locationSuggestions"
    [value]="locationSuggestion">
    <span>{{ locationSuggestion.text }}</span>
  </mat-option>
  <mat-option
    *ngIf="locationSuggestions?.length === 0 && !searching"
    disabled>
    <span>No results found</span>
  </mat-option>
</mat-autocomplete>
