import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Manufacturer } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { prop } from "ramda";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { go } from "../../../kernel/store/actions/router.actions";
import { isDefined } from "../../../kernel/util";

import * as ManufacturerActions from "../store/manufacturer.actions";
import * as fromManufacturer from "../store/manufacturer.selectors";

@Component({
  selector: "edit-manufacturer-container",
  templateUrl: "edit-manufacturer.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditManufacturerContainer {
  public manufacturer$: Observable<Manufacturer>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.manufacturer$ = _route.params.pipe(
      map((params) => prop("id", params)),
      mergeMap((id) => (isDefined(id) ? _store.pipe(select(fromManufacturer.getManufacturer(id))) : of(new Manufacturer())))
    );
  }

  public save(manufacturer: Manufacturer): void {
    this._store.dispatch(ManufacturerActions.addOrUpdate(manufacturer));
  }

  public close(): void {
    this._store.dispatch(go({ path: ["/product/manufacturers"], extras: { queryParamsHandling: "merge" } }));
  }
}
