import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./auth.reducer";
import { isRoot as isRootUser } from "../services/auth.service";
import { isNil, not } from "ramda";

export const getAuthState = createFeatureSelector<State>("auth");
export const getError = createSelector(getAuthState, (state) => state.error);
export const getUser = createSelector(getAuthState, (state) => state.user);
export const getLoggingIn = createSelector(getAuthState, (state) => state.loggingIn);
export const getLoggingInFromAuthToken = createSelector(getAuthState, (state) => state.loggingInFromAuthToken);
export const getAuthToken = createSelector(getAuthState, (state) => state.authToken);

export const getLoggedIn = createSelector(getUser, (user) => not(isNil(user)));

export const isRoot = createSelector(getUser, isRootUser);
