import { Salon, User } from "@getvish/model";
import { createAction, props, union } from "@ngrx/store";
import { AuthToken } from "app/kernel/models";

export const init = createAction("[Multi-Tenant Login] Init");

export const checkHasExistingLoginData = createAction("[Multi-Tenant Login] Check Has Existing Login Data");

export const hasExistingLoginData = createAction("[Multi-Tenant Login] Has Existing Login Data", props<{ authToken: string }>());

export const loadAvailableTenants = createAction("[Multi-Tenant Login] Load Available Tenants", props<{ authToken: string }>());

export const navigate = createAction("[Multi-Tenant Login] Navigate");

export const logIn = createAction("[Multi-Tenant Login] Log In", props<{ username: string; password: string }>());

export const loginSuccess = createAction(
  "[Multi-Tenant Login] Login Success",
  props<{ user: User; authToken: AuthToken; tenants: Salon[] }>()
);

export const logInFromAuthToken = createAction("[Multi-Tenant Login] Login From Auth Token", props<{ authToken: string }>());

export const loginFromAuthTokenSuccess = createAction(
  "[Multi-Tenant Login] Login From Auth Token Success",
  props<{ user: User; authToken: AuthToken; tenants: Salon[] }>()
);

export const loginFromAuthTokenFail = createAction("[Multi-Tenant Login] Login From Auth Token Fail");

export const loginFail = createAction("[Multi-Tenant Login] Login Fail", props<{ reason: string }>());

export const cancel = createAction("[Multi-Tenant Login] Cancel");

export const selectSalon = createAction("[Multi-Tenant Login] Select Salon", props<{ salon: Salon }>());

export const forgotPassword = createAction("[Multi-Tenant Login] Forgot Password");

export const navigateToSelectTenant = createAction("[Multi-Tenant Login] Navigate To Select Tenant");

const all = union({
  init,
  checkHasExistingLoginData,
  hasExistingLoginData,
  loadAvailableTenants,
  navigate,
  logIn,
  logInFromAuthToken,
  loginFromAuthTokenSuccess,
  loginFromAuthTokenFail,
  loginSuccess,
  loginFail,
  cancel,
  selectSalon,
  forgotPassword,
  navigateToSelectTenant,
});

export type Actions = typeof all;
