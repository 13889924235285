<div
  class="tree-container"
  [progressIndicator]="isSaving ? 'Saving...' : undefined">
  <div class="tree">
    <div
      class="node"
      *ngFor="let grouping of productGroupings; trackBy: trackByGroup">
      <div class="node-name">
        <span>
          <ng-container *ngIf="!grouping.category.isRoot && (grouping.category.rootCategory | isDefined)">
            {{ grouping.category.rootCategory.name }} >
          </ng-container>
          {{ grouping.category.name }}
        </span>
        <div class="node-controls">
          <button
            *ngIf="grouping.hasActive"
            mat-stroked-button
            class="button outline secondary small"
            (click)="deactivateCategoryClicked.emit(grouping.category)">
            Deactivate
          </button>
          <button
            *ngIf="grouping.allInactive"
            mat-stroked-button
            class="button outline secondary small"
            (click)="reactivateCategoryClicked.emit(grouping.category)">
            Activate
          </button>
          <button
            mat-stroked-button
            class="button outline secondary small"
            (click)="setCategoryPricingClicked.emit(grouping.category)">
            Set Pricing
          </button>
          <mat-menu #productMenu="matMenu">
            <!-- <button
              (click)="changeProductOrder(grouping.products)"
              mat-menu-item>
              Change Product Order
            </button> -->
            <button
              mat-menu-item
              (click)="setCategoryMarkupClicked.emit(grouping.category)">
              Set Markup
            </button>
          </mat-menu>
          <button
            [matMenuTriggerFor]="productMenu"
            class="button transparent">
            <fa-icon icon="ellipsis-v"></fa-icon>
          </button>
        </div>
      </div>
      <div class="product-grid">
        <div class="product-grid-header product-grid-item">Product</div>
        <div class="product-grid-header product-grid-item">Wholesale</div>
        <div class="product-grid-header product-grid-item">Markup</div>
        <div class="product-grid-header product-grid-item">Retail</div>
        <div class="product-grid-header product-grid-item">Container size</div>
        <div class="product-grid-header product-grid-item"></div>
        <ng-container *ngFor="let product of grouping.products">
          <div
            class="product-grid-cell product-grid-item product-name-cell"
            [ngClass]="{
              'product-grid-cell-inactive': product.flags | includes: 'INACTIVE',
            }"
            (click)="editProductClicked.emit(product)">
            <colored-dot
              [color]="product.hexColorCode"
              size="large"
              class="color-marker"></colored-dot>
            <span>
              {{ product.name }}
            </span>
          </div>
          <div
            class="product-grid-cell product-grid-item"
            [ngClass]="{
              'product-grid-cell-inactive': product.flags | includes: 'INACTIVE',
            }">
            {{ product.wholesalePrice | currency: currency }}
          </div>
          <div
            class="product-grid-cell product-grid-item"
            [ngClass]="{
              'product-grid-cell-inactive': product.flags | includes: 'INACTIVE',
            }">
            {{ (product.markup | isNil) ? "" : (product.markup | percent) }}
          </div>
          <div
            class="product-grid-cell product-grid-item"
            [ngClass]="{
              'product-grid-cell-inactive': product.flags | includes: 'INACTIVE'
            }">
            {{ product.retailPrice | currency: currency }}
          </div>
          <div
            class="product-grid-cell product-grid-item"
            [ngClass]="{
              'product-grid-cell-inactive': product.flags | includes: 'INACTIVE',
            }">
            {{ product.containerSize | localMeasurementUnit: measurementUnit }}
          </div>
          <div
            class="product-grid-cell product-grid-item edit-cell"
            [ngClass]="{
              'product-grid-cell-inactive': product.flags | includes: 'INACTIVE',
            }">
            <div class="labels-container">
              <div
                *ngIf="product.pricingIncomplete"
                class="label pricing-incomplete">
                Pricing Incomplete
              </div>
              <div
                *ngIf="product.flags | includes: 'INACTIVE'"
                class="label inactive">
                Inactive
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="productGroupings | isEmpty">
    <empty-state-message
      title="No products found"
      icon="magnifying-glass">
    </empty-state-message>
  </div>
</div>
