import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "selection-cell",
  templateUrl: "selection-cell.component.html",
  styleUrls: ["selection-cell.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionCellComponent {
  @Input()
  checked: boolean;
  @Input()
  radio: boolean = false;
  @Input()
  disabled: boolean = false;
  @Output()
  public change: EventEmitter<void>;

  constructor() {
    this.change = new EventEmitter();
  }

  public _onChange(): void {
    this.change.emit();
  }
}
