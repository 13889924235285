import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Input, OnDestroy } from "@angular/core";
import { MatInput } from "@angular/material/input";
import { isNil, not } from "ramda";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "autocomplete-search-input",
  templateUrl: "autocomplete-search-input.component.html",
  styleUrls: ["search-input.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteSearchInputComponent implements AfterViewInit, OnDestroy {
  @ContentChild(MatInput)
  public inputElement: MatInput;

  @Input()
  isSearching = false;

  public showClearElement$ = new BehaviorSubject(false);

  private _valueChangesSubscription;

  constructor(private _cd: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this._valueChangesSubscription = this.inputElement.ngControl.valueChanges.subscribe((value) => this._handleValue(value));

    this._handleValue(this.inputElement.ngControl.value);
  }

  public ngOnDestroy(): void {
    if (this._valueChangesSubscription != null) {
      this._valueChangesSubscription.unsubscribe();
      this._valueChangesSubscription = null;
    }
  }

  public clearSearch(): void {
    this.inputElement.focus();
    this.inputElement.ngControl.reset();
  }

  private _handleValue(value: any): void {
    this.showClearElement$.next(not(isNil(value) || (typeof value === "string" && value.length <= 0)));
    this._cd.detectChanges();
  }
}
