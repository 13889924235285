import { Salon } from "@getvish/model";
import { createAction, props, union } from "@ngrx/store";
import { TenantEntitlement } from "app/kernel/models/tenant-entitlement";

export const loadForSalons = createAction("[Tenant Entitlements] Load For Salons", props<{ salons: Salon[] }>());

export const loadForSalonsSuccess = createAction(
  "[Tenant Entitlements] Load For Salons Success",
  props<{ entitlements: TenantEntitlement[] }>()
);

export const loadForSalonsFail = createAction("[Tenant Entitlements] Load For Salons Fail", props<{ error: string }>());

const actions = union({
  loadForSalons,
  loadForSalonsSuccess,
  loadForSalonsFail,
});

export type Actions = typeof actions;
