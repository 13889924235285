import { createFeatureSelector, createSelector } from "@ngrx/store";
import { hasCategorySearchTerm, hasSearchTerm } from "app/+product/common/utils";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { ImportSalonProductsState, featureKey } from "./import-salon-products.reducer";

/*** SELECTORS ***/
export const getState = createFeatureSelector<ImportSalonProductsState>(featureKey);

export const getSearchFilter = createSelector(getState, (state) => state.searchFilter);

export const getLoading = createSelector(getState, (state) => state.loading);

export const getSaving = createSelector(getState, (state) => state.saving);

export const getImportStep = createSelector(getState, (state) => state.importStep);

export const getSelectedManufacturer = createSelector(getState, (state) => state.selectedManufacturer);

export const getSelectedCategories = createSelector(getState, (state) => state.selectedProductCategories);

export const getSelectedProductGroups = createSelector(getState, (state) => state.selectedProducts);

export const getManufacturers = createSelector(getState, (state) => state.manufacturers);

export const getFilteredManufacturers = createSelector(getManufacturers, getSearchFilter, (manufacturers, searchFilter) =>
  manufacturers.filter(hasSearchTerm(searchFilter))
);

export const getSelectedProducts = createSelector(getSelectedProductGroups, getManufacturers, (groups, manufacturers) =>
  Object.keys(groups).map((manufacturerId) => ({
    products: groups[manufacturerId],
    manufacturer: manufacturers.find((manufacturer) => manufacturer._id === manufacturerId),
  }))
);

export const getPreviousStep = createSelector(getState, (state) => state.previousStep);
export const hasSeenConfirm = createSelector(getState, (state) => state.hasSeenConfirm);

export const getFlagFilters = createSelector(getState, (state) => state.flagFilters);

export const getAvailableProducts = createSelector(getState, (state) => state.availableProducts);

export const getSalonProducts = createSelector(getState, (state) => state.salonProducts);

export const getAvailableCategories = createSelector(getState, (state) => state.availableCategories);

export const getFilteredProducts = createSelector(
  getAvailableProducts,
  getAvailableCategories,
  getSearchFilter,
  (products, categories, searchFilter) =>
    products.filter((p) => hasSearchTerm(searchFilter)(p) || hasCategorySearchTerm(searchFilter, categories)(p))
);

export const getProductsForSelectedManufacturer = createSelector(
  getSelectedManufacturer,
  getSelectedProductGroups,
  (manufacturer, selectedProductsGroups) =>
    pipe(
      option.fromNullable(manufacturer),
      option.chain((manufacturer) => option.fromNullable(selectedProductsGroups[manufacturer._id])),
      option.getOrElse(() => [])
    )
);
