import { NgModule } from "@angular/core";

import { CardModule, DataTableModule, MixingContainerModule, PageModule } from "../+components";
import { SharedModule } from "../kernel/shared";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ServiceMenuRoutingModule } from "./service-menu-routing.module";
import { ProductAllowanceService, ServiceDescriptionService, ServiceCategoryService, ServiceMenuDownloaderService } from "./services";
import { ProductService } from "app/+product/+products/services";
import { SalonProductService } from "app/+product/+salon-products";
import { ProductCategoryService } from "app/+product/+product-categories/services";
import { ManufacturerService } from "app/+product/+manufacturers/services";
import {
  EditServiceDescriptionContainer,
  EditServicesContainer,
  ProductAllowanceCalculatorContainer,
  ServiceMenuListContainer,
} from "./containers";
import { ServiceDescriptionFormComponent, EditServicesComponent } from "./components";

import { serviceMenuReducer } from "./store/service-menu.reducer";
import { editServiceReducer } from "./store/edit-service.reducer";
import { editServicesReducer } from "./store/edit-services.reducer";
import { paCalculatorReducer } from "./store/product-allowance-calculator.reducer";
import { ServiceMenuEffects } from "./store/service-menu.effects";
import { EditServiceEffects } from "./store/edit-service.effects";
import { EditServicesEffects } from "./store/edit-services.effects";
import { PACalculatorEffects } from "./store/product-allowance-calculator.effects";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
  faCalculator,
  faClock,
  faCircleNotch,
  faEllipsisV,
  faEyeSlash,
  faPenToSquare,
  faTrash,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { BreadcrumbService } from "xng-breadcrumb";
import {
  PACBlueprintComponent,
  PACBlueprintIngredientComponent,
  PACBlueprintSummaryComponent,
  WeightSelectorComponent,
} from "./components/product-allowance-calculator";
import { ProductSelectionDialogService } from "app/+product/+product-selection/services/product-selection.service";
import { SalonProductCategoryService } from "app/+product/+salon-products/services/salon-product-category.service";
import { ProductAllowanceCellComponent, ProductAllowanceSummaryCellComponent } from "./components/cell-renderers";
import { NotificationsModule } from "app/+notifications/notifications.module";
import { ServiceMenuNotificationsService } from "./services/service-menu-notifications.service";
import { AnalyticsModule } from "app/+analytics";
import { ServiceComplianceAnomaliesService } from "./services/service-compliance-anomalies.service";

@NgModule({
  imports: [
    SharedModule,
    ServiceMenuRoutingModule,
    StoreModule.forFeature("serviceMenu", serviceMenuReducer),
    StoreModule.forFeature("editService", editServiceReducer),
    StoreModule.forFeature("editServices", editServicesReducer),
    StoreModule.forFeature("paCalculator", paCalculatorReducer),
    EffectsModule.forFeature([ServiceMenuEffects, EditServiceEffects, EditServicesEffects, PACalculatorEffects]),
    CardModule,
    DataTableModule,
    MixingContainerModule,
    PageModule,
    AnalyticsModule,
  ],
  declarations: [
    EditServiceDescriptionContainer,
    ProductAllowanceCalculatorContainer,
    ServiceMenuListContainer,
    PACBlueprintComponent,
    PACBlueprintIngredientComponent,
    PACBlueprintSummaryComponent,
    ServiceDescriptionFormComponent,
    WeightSelectorComponent,
    EditServicesContainer,
    EditServicesComponent,
    ProductAllowanceCellComponent,
    ProductAllowanceSummaryCellComponent,
  ],
  providers: [
    ManufacturerService,
    ProductAllowanceService,
    ProductService,
    ProductCategoryService,
    SalonProductService,
    SalonProductCategoryService,
    ServiceDescriptionService,
    ServiceCategoryService,
    ServiceMenuDownloaderService,
    ProductSelectionDialogService,
    BreadcrumbService,
    ServiceComplianceAnomaliesService,
  ],
})
export class ServiceMenuModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCalculator, faClock, faCircleNotch, faEllipsisV, faEyeSlash, faPenToSquare, faTrash, faXmark);
  }

  public static forRoot() {
    return {
      ngModule: ServiceMenuModule,
      imports: [NotificationsModule.forRoot()],
      providers: [ServiceMenuNotificationsService],
      exports: [ServiceMenuNotificationsService],
    };
  }
}
