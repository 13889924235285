import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AnalyticsContainer,
  EmployeeReportContainer,
  ProductsReportContainer,
  ManufacturerReportContainer,
  ProductReportDashboardContainer,
} from "./containers";
import { PerformedServiceListContainer } from "./containers/performed-service-list.container";
import { OrderPanelContainer } from "app/+front-desk/containers";

export const routes: Routes = [
  { path: "", component: AnalyticsContainer, data: { breadcrumb: { label: "Insights" } } },
  { path: "products/:manufacturerId", component: ProductsReportContainer },
  { path: "products", component: ManufacturerReportContainer },
  { path: "dashboard/:manufacturerId", component: ProductReportDashboardContainer },
  { path: "employee/:id", component: EmployeeReportContainer },
  {
    path: "performed-services",
    component: PerformedServiceListContainer,
    data: { breadcrumb: { label: "Performed Services" } },
  },
  { path: "order/:id", component: OrderPanelContainer, outlet: "panel" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
