import { Injectable } from "@angular/core";
import { HttpError } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { either } from "fp-ts";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { go } from "../../kernel/store/actions/router.actions";
import { OrderService } from "../services/order.service";
import { getModule } from "./order.selectors";

import * as appointmentActions from "./appointment.actions";
import * as orderActions from "./order.actions";
import { Order } from "@getvish/model";
import { AppointmentService } from "../services";

@Injectable()
export class OrderEffects {
  public navigateToTicketComponent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.navigateToOrder),
      map((action) => action.appointment._id),
      map((id) => go({ path: ["/front-desk", { outlets: { panel: `order/${id}` } }] }))
    )
  );

  public navigateBack$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.done),
      withLatestFrom(this._store.pipe(select(getModule))),
      map(([_, module]) => {
        if (module === "front-desk") {
          return go({ path: ["/front-desk"] });
        } else if (module === "performed-services") {
          return go({
            path: ["/insights", { outlets: { panel: null } }],
            extras: { queryParamsHandling: "preserve" },
          });
        } else {
          return go({ path: ["/sales"], extras: { queryParamsHandling: "preserve" } });
        }
      })
    )
  );

  public generateOrLoadForAppointment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.generateOrLoadForAppointment),
      map((action) => action.appointmentId),
      switchMap((id) =>
        forkJoin([this._appointmentService.findAppointmentVMById(id), this._orderService.fetchOrGenerateOrderForAppointment(id)]).pipe(
          mergeMap(([appointment, { order }]) => [new orderActions.GenerateSuccess({ appointment, order })]),
          catchError((error) => of(new orderActions.GenerateFail({ error })))
        )
      )
    )
  );

  public load$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.load),
      map((action) => action.id),
      switchMap((id) =>
        this._orderService.loadDataForOrder(id).pipe(
          mergeMap(({ order }) => [orderActions.loadSuccess({ payload: order })]),
          catchError((error: Error) => of(orderActions.loadFail({ error: error.message })))
        )
      )
    )
  );

  public checkout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.checkout),
      map((action) => action.order),
      switchMap((order) =>
        this._orderService.checkout(order).pipe(
          mergeMap(
            either.fold<HttpError, Order, Action[]>(
              (error) => [
                orderActions.checkoutFail({
                  error: new Error(error.payload.toString()),
                }),
              ],
              (success) => [new orderActions.CheckoutSuccess(success), appointmentActions.loadCurrentAppointments()]
            )
          ),
          catchError(() =>
            of(
              orderActions.checkoutFail({
                error: new Error(`Didn't get a response from the server`),
              })
            )
          )
        )
      )
    )
  );

  public reactivate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(orderActions.reactivate),
      map((action) => action.order),
      switchMap((order) =>
        this._orderService.reactivate(order).pipe(
          mergeMap(
            either.fold<HttpError, Order, Action[]>(
              (error) => [
                orderActions.reactivateFail({
                  error: new Error(error.payload.toString()),
                }),
              ],
              (order) => [new orderActions.ReactivateSuccess(order), appointmentActions.loadCurrentAppointments()]
            )
          ),
          catchError(() =>
            of(
              orderActions.reactivateFail({
                error: new Error(`Did not get a response from the server`),
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private _orderService: OrderService,
    private _appointmentService: AppointmentService,
    private _store: Store<AppState>,
    private _actions$: Actions
  ) {}
}
