import { Salon } from "@getvish/model";

import * as deleteSalon from "./delete-salon.actions";

export interface DeleteSalonState {
  salon: Salon;
  deleting: boolean;
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: DeleteSalonState = {
  salon: undefined,
  deleting: false,
  success: undefined,
  error: undefined,
  loading: undefined,
};

export function deleteSalonReducer(state: DeleteSalonState = initialState, action: deleteSalon.Actions): DeleteSalonState {
  switch (action.type) {
    case deleteSalon.Types.LOAD: {
      const loading = true;

      return {
        ...initialState,
        loading,
      };
    }

    case deleteSalon.Types.LOAD_SUCCESS: {
      const loading = false;
      const salon = action.payload.salon;

      return {
        ...state,
        loading,
        salon,
      };
    }

    case deleteSalon.Types.LOAD_FAIL: {
      const loading = false;
      const error = action.payload.error.message;

      return {
        ...state,
        loading,
        error,
      };
    }

    case deleteSalon.Types.DELETE: {
      const deleting = true;

      return {
        ...state,
        deleting,
      };
    }

    case deleteSalon.Types.SUCCESS: {
      const deleting = false;
      const success = true;

      return {
        ...state,
        deleting,
        success,
      };
    }

    case deleteSalon.Types.DONE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
