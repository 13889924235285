import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AppointmentStatus } from "@getvish/model";

@Component({
  selector: "appointment-status-label",
  templateUrl: "appointment-status-label.component.html",
  styleUrls: ["appointment-status-label.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentStatusLabelComponent {
  @Input() status: AppointmentStatus;
}
