<slideout-panel (close)="close()">
  <div panel-header>Edit Product Category</div>

  <edit-product-category-component
    *ngIf="productCategory$ | async | isDefined"
    [productCategory]="productCategory$ | async"
    [productCategories]="productCategories$ | async"
    [parentCategoryId]="parentCategoryId$ | async"
    [manufacturerId]="manufacturerId$ | async"
    (save)="save($event)"
    (close)="close()"
    panel-body>
  </edit-product-category-component>
</slideout-panel>
