import { Component, ChangeDetectionStrategy } from "@angular/core";
import { AppState } from "../../kernel/store";
import { Store, select } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { EmployeeReport, LongitudinalReport, SalonReport } from "../../kernel/models";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { mergeMap, map, take } from "rxjs/operators";
import {
  getEmployeeReport,
  getLongitudinalReport,
  getAggregatedBy,
  getSalonReport,
  getStartDate,
  getEndDate,
} from "../store/analytics.selectors";
import {
  ChangeAggregation,
  DownloadServicesSummaryReport,
  UpdateDateRange,
  InitializeAndLoadEmployeeReports,
} from "../store/analytics.actions";
import { MeasurementUnit } from "@getvish/model";
import { getMeasurementUnitOrDefault } from "../../+salon-config/store/salon-config.selectors";

import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";
import { go } from "app/kernel/store/actions/router.actions";

@Component({
  selector: "employee-report-container",
  templateUrl: "employee-report.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeReportContainer {
  public employeeId$: Observable<string>;
  public employeeReport$: Observable<EmployeeReport>;
  public longitudinalReport$: Observable<LongitudinalReport>;
  public summary$: Observable<SalonReport>;
  public aggregatedBy$: Observable<string>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public startDate$: Observable<number>;
  public endDate$: Observable<number>;
  public currency$: Observable<string>;
  public locale$: Observable<string>;
  public timeZone$: Observable<string>;
  public enableComplianceTracking$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.employeeId$ = _route.paramMap.pipe(map((params: ParamMap) => params.get("id")));
    this.aggregatedBy$ = _store.pipe(select(getAggregatedBy));
    this.measurementUnit$ = _store.pipe(select(getMeasurementUnitOrDefault));

    this.employeeReport$ = this.employeeId$.pipe(mergeMap((employeeId) => _store.pipe(select(getEmployeeReport(employeeId)))));

    this.longitudinalReport$ = _store.pipe(select(getLongitudinalReport));
    this.summary$ = _store.pipe(select(getSalonReport));
    this.startDate$ = _store.pipe(select(getStartDate));
    this.endDate$ = _store.pipe(select(getEndDate));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.locale$ = _store.pipe(select(fromSalonConfig.getLocale));
    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);
    this.enableComplianceTracking$ = _store.select(fromSalonConfig.getEnableComplianceTracking);

    this.employeeId$
      .pipe(
        map((employeeId) => new InitializeAndLoadEmployeeReports({ employeeId, aggregatedBy: "DAILY" })),
        take(1)
      )
      .subscribe((action) => this._store.dispatch(action));
  }

  public updateDateRange(event: { startDate: number; endDate: number }): void {
    const { startDate, endDate } = event;

    this._store.dispatch(new UpdateDateRange({ startDate, endDate }));
  }

  public changeAggregation(aggregatedBy: string): void {
    this._store.dispatch(new ChangeAggregation({ aggregatedBy }));
  }

  public downloadServicesSummaryReport(): void {
    this._store.dispatch(new DownloadServicesSummaryReport());
  }

  public navigateToUnmixed(serviceId: string): void {
    combineLatest([this.startDate$, this.endDate$, this.employeeId$])
      .pipe(take(1))
      .subscribe(([startDate, endDate, employeeId]) => {
        this._store.dispatch(
          go({ path: ["/insights/performed-services"], query: { startDate, endDate, employeeId, serviceId, subset: "unmixed" } })
        );
      });
  }

  public navigateToMixed(serviceId: string): void {
    combineLatest([this.startDate$, this.endDate$, this.employeeId$])
      .pipe(take(1))
      .subscribe(([startDate, endDate, employeeId]) => {
        this._store.dispatch(
          go({ path: ["/insights/performed-services"], query: { startDate, endDate, employeeId, serviceId, subset: "mixed" } })
        );
      });
  }
}
