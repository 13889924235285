<div
  class="content"
  [ngClass]="{ disabled }">
  <nav>
    <div *ngFor="let section of navLinks">
      <h3>
        <span class="content">{{ section.label }}</span>
      </h3>
      <ul>
        <a
          *ngFor="let link of section.links"
          [routerLink]="link.path"
          [matTooltip]="link.label"
          matTooltipPosition="after"
          [matTooltipDisabled]="isExpanded"
          tooltipClass="tooltip">
          <li>
            <fa-icon [icon]="link.icon"></fa-icon>
            <span class="nav-menu-label">
              {{ link.label }}
              <span
                *ngIf="evaluateBadge(link.badge) | async as badge"
                class="badge mat-badge-content mat-badge-active"
                >{{ badge > 99 ? "99+" : badge }}</span
              >
            </span>
          </li>
        </a>
      </ul>
    </div>
  </nav>
</div>
