import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducer as salonApiVersionReducer, salonApiVersionFeatureKey } from "./store/salon-api-version.reducer";
import { SalonApiVersionEffects } from "./store/salon-api-version.effects";
import { SalonApiVersionService } from "../services";
import { SharedModule } from "../shared";
import { TenantApiVersionCheckEffects } from "./store/tenant-api-version-check.effects";
import { environment } from "../../../environments/environment";
import { TenantApiVersionCheckService } from "./services/tenant-api-version-check.service";

const effectsImports: any[] = [SalonApiVersionEffects];

// only import TenantApiVersionCheck effects when in production mode. No need to check/reroute tenants while in dev mode
if (environment.production === true) {
  effectsImports.push(TenantApiVersionCheckEffects);
}

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature(salonApiVersionFeatureKey, salonApiVersionReducer),
    EffectsModule.forFeature(effectsImports),
  ],
  providers: [SalonApiVersionService, TenantApiVersionCheckService],
})
export class SalonApiVersionModule {}
