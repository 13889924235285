<ng-container *ngFor="let text of values">
  <input
    *ngIf="text.inputType != null && text.enableEditing; else display"
    [value]="text.value"
    (input)="text.onEdit($event)"
    [type]="text.inputType.type"
    [min]="$any(text.inputType)?.min"
    [max]="$any(text.inputType)?.max" />

  <ng-template #display>
    <div [ngClass]="{ 'text-with-edit': text.inputType | isDefined }">
      <span
        [ngClass]="text?.classNames"
        (click)="text.onClick()"
        [attr.title]="text.title"
        [matTooltip]="text.tooltip">
        {{ text.value }}
      </span>
      <fa-icon
        *ngIf="text.inputType != null"
        title="Enable Editing"
        icon="pencil"
        (click)="text.onEditingChanged()"></fa-icon>
    </div>
  </ng-template>
</ng-container>
