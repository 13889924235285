import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { Observable, zip, Subscription, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Salon } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../kernel";
import { not, isNil } from "ramda";
import { SalonApiVersion } from "app/kernel/models/salon-api-version";

import * as fromSalon from "../store/salon.selectors";
import * as fromSalonApiVersion from "../../kernel/+salon-api-version/store/salon-api-version.selector";
import * as fromTenantEntitlements from "../../kernel/+tenant-entitlements/store/tenant-entitlements.selector";
import * as SalonActions from "../store/salon.actions";
import * as EditSalonActions from "../store/edit-salon.actions";
import * as DeleteSalonActions from "../store/delete-salon.actions";
import * as AppActions from "../../kernel/store/actions/app.actions";
import * as ImportSalonDataActions from "../store/import-salon-data.actions";
import * as MigrateSalonActions from "../store/migrate-salon.actions";
import * as RevokeRestoreAccessActions from "../store/revoke-restore-access.actions";

import { getParamMap } from "../../kernel/store/selectors/router.selectors";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Paging } from "app/+components";
import { JsonObject } from "@getvish/stockpile";
import { EntitlementState, TenantEntitlement } from "app/kernel/models";
export interface SalonVm extends Salon {
  apiVersion: "V1" | "V2";
  entitlementState?: EntitlementState;
}

@Component({
  selector: "salons-container",
  templateUrl: "salons.container.html",
  styleUrls: ["salons.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalonsContainer implements OnDestroy {
  public loading$: Observable<boolean>;
  public filter$: Observable<string>;
  public apiVersions$: Observable<SalonApiVersion[]>;
  public limit: number;

  private urlParamsSubscription: Subscription;

  public salonVms$: Observable<SalonVm[]>;
  public paging$: Observable<Paging>;
  public sort$: Observable<{ [key: string]: 1 | -1 }>;

  constructor(private _store: Store<AppState>) {
    this.limit = 50;

    this.apiVersions$ = _store.select(fromSalonApiVersion.getSalonApiVersions);
    const entitlements$ = _store.select(fromTenantEntitlements.getEntitlements);
    const salons$ = _store.select(fromSalon.getSalons);

    const getEntitlementState = (salon: Salon, entitlements: TenantEntitlement[]): EntitlementState => {
      if (entitlements.some((entitlement) => entitlement.tenantId === salon._id && entitlement.state === "Active")) {
        return "Active";
      }

      return "Inactive";
    };

    const entitlementsLoading$ = _store.select(fromTenantEntitlements.getLoading);

    this.salonVms$ = combineLatest([salons$, this.apiVersions$, entitlements$, entitlementsLoading$]).pipe(
      map(([salons, apiVersions, entitlements, entitlementsLoading]) =>
        salons.map(
          (salon) =>
            ({
              ...salon,
              apiVersion: apiVersions.find((version) => version.salonId === salon._id)?.apiVersion,
              entitlementState: entitlementsLoading ? "Active" : getEntitlementState(salon, entitlements),
            }) as SalonVm
        )
      )
    );

    const count$ = _store.select(fromSalon.getPaging).pipe(
      map((paging) =>
        pipe(
          paging,
          option.map((paging) => paging.count),
          option.getOrElse(() => 0)
        )
      )
    );
    this.loading$ = combineLatest([
      _store.select(fromSalon.getLoading),
      _store.select(fromSalonApiVersion.getLoading),
      entitlementsLoading$,
    ]).pipe(
      map(
        ([salonsLoading, salonApiVersionsLoading, entitlementsLoading]) => salonsLoading || salonApiVersionsLoading || entitlementsLoading
      )
    );

    this.filter$ = this._store.pipe(select(fromSalon.getFilter));

    const currPage$ = this._store.pipe(select(getParamMap)).pipe(
      map((params) => (not(isNil(params)) ? params.get("page") : undefined)),
      map((page) => (not(isNil(page)) ? Number(page) : 1))
    );

    this.paging$ = combineLatest([count$, currPage$]).pipe(
      map(([count, currentPage]) => {
        return { count, currentPage, limit: this.limit, changePage: this.navigateToPage.bind(this) };
      })
    );

    const queryParamFilter$ = this._store.pipe(
      select(getParamMap),
      map((params) => (not(isNil(params)) ? params.get("filter") : undefined))
    );

    const queryParamSort$ = this._store.pipe(
      select(getParamMap),
      map((params) => (params?.get("sort") != null ? JSON.parse(params.get("sort")) : undefined))
    );

    this.urlParamsSubscription = zip(currPage$, queryParamFilter$, queryParamSort$)
      .pipe(map(([page, filter, sort]) => new SalonActions.LoadAll({ page, limit: this.limit, filter, sort })))
      .subscribe((action) => this._store.dispatch(action));

    this.sort$ = this._store.pipe(select(fromSalon.getSort)) as Observable<{ [key: string]: 1 | -1 }>;
  }

  public visit(salon: Salon): void {
    this._store.dispatch(AppActions.changeSalon({ salon }));
  }

  public generateOrders(salon: Salon): void {
    this._store.dispatch(new SalonActions.RetroactivelyGenerateOrders({ salon }));
  }

  public newSalon(): void {
    this._store.dispatch(new EditSalonActions.NavigateEditSalon());
  }

  public edit(salon: Salon): void {
    this._store.dispatch(new EditSalonActions.NavigateEditSalon({ salon }));
  }

  public navigateToPage(page: number): void {
    this._store.dispatch(new SalonActions.NavigateToPage({ page }));
  }

  public delete(salon: Salon): void {
    this._store.dispatch(new DeleteSalonActions.Navigate({ salon }));
  }

  public importData(salon: Salon): void {
    this._store.dispatch(new ImportSalonDataActions.Navigate({ salon }));
  }

  public updateFilter(filter: string): void {
    this._store.dispatch(new SalonActions.Search({ filter }));
  }

  public updateSort(sort: JsonObject): void {
    this._store.dispatch(new SalonActions.UpdateSort(sort));
  }

  public exportFormulas(salon: Salon): void {
    this._store.dispatch(new SalonActions.ExportFormulas({ slug: salon.slug }));
  }

  public exportUsageReport(): void {
    this._store.dispatch(new SalonActions.ExportUsageReport());
  }

  public migrateToV2(salon: Salon): void {
    this._store.dispatch(new MigrateSalonActions.Navigate({ salon }));
  }

  public revokeAccess(salon: Salon): void {
    this._store.dispatch(RevokeRestoreAccessActions.navigateToRevoke({ salonId: salon._id }));
  }

  public restoreAccess(salon: Salon): void {
    this._store.dispatch(RevokeRestoreAccessActions.navigateToRestore({ salonId: salon._id }));
  }

  public ngOnDestroy(): void {
    this.urlParamsSubscription.unsubscribe();
  }
}
