import { APP_BASE_HREF } from "@angular/common";
import { Injectable, NgModule } from "@angular/core";
import { PreloadingStrategy, ROUTES, Route, RouterModule } from "@angular/router";
import { chain, fromNullable, getOrElse } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { and, or } from "ramda";
import { Observable, of } from "rxjs";
import { ErrorsModule, routes as errorRoutes } from "./+errors";
import { AppMode } from "./kernel/models/app-mode";
import { WindowService } from "./kernel/services/window.service";

type CustomRoutes = CustomRoute[];

interface CustomRoute extends Route {
  data?: {
    admin?: boolean;
    tenant?: boolean;
    breadcrumb?: any;
  };
}

export function getBaseHrefFactory(appMode: AppMode, window: WindowService): string {
  if (appMode.value === "MULTI_TENANT" || appMode.value === "BOOKER-OAUTH" || appMode.value === "INVENTORY-INTEGRATION") {
    return "/";
  } else {
    return window.tenantPath;
  }
}

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private _appMode: AppMode) {}

  public preload(route: Route, loadRoute: () => Observable<any>): Observable<any> {
    const isAdminSite = this._appMode.value === "ADMIN";
    const isTenantSite = !isAdminSite;

    const isAdminRoute = pipe(
      fromNullable(route.data),
      chain((data) => fromNullable(data.admin)),
      getOrElse(() => false)
    );

    const isTenantRoute = pipe(
      fromNullable(route.data),
      chain((data) => fromNullable(data.tenant)),
      getOrElse(() => false)
    );

    const shouldLoad = or(and(isAdminSite, isAdminRoute), and(isTenantRoute, isTenantSite));

    return shouldLoad ? loadRoute() : of(null);
  }
}

const routes: CustomRoutes = [
  {
    path: "login",
    loadChildren: () => import("./+auth/auth.module").then((m) => m.AuthModule),
    data: {
      admin: true,
      tenant: true,
    },
  },
  {
    path: "forgot-password",
    loadChildren: () => import("./+reset-password/reset-password.module").then((m) => m.ResetPasswordModule),
    data: {
      admin: true,
      tenant: true,
    },
  },
  {
    path: "clients",
    loadChildren: () => import("./+customers/customers.module").then((m) => m.CustomersModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "employees",
    loadChildren: () => import("./+employees/employees.module").then((m) => m.EmployeesModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "front-desk",
    loadChildren: () => import("./+front-desk/front-desk.module").then((m) => m.FrontDeskModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "sales",
    loadChildren: () => import("./+sales/sales.module").then((m) => m.SalesModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "salons",
    loadChildren: () => import("./+salons/salons.module").then((m) => m.SalonsModule),
    data: {
      admin: true,
      tenant: false,
    },
  },
  {
    path: "devices",
    loadChildren: () => import("./+devices/devices.module").then((m) => m.DevicesModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "salon-config",
    loadChildren: () => import("./+salon-config/salon-config.module").then((m) => m.SalonConfigModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "service-menu",
    loadChildren: () => import("./+service-menu/service-menu.module").then((m) => m.ServiceMenuModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "insights",
    loadChildren: () => import("./+analytics/analytics.module").then((m) => m.AnalyticsModule),
    data: {
      admin: false,
      tenant: true,
    },
  },
  {
    path: "inventory",
    loadChildren: () => import("./+inventory/inventory.module").then((m) => m.InventoryModule),
    data: {
      admin: true,
      tenant: true,
    },
  },
  {
    path: "product",
    loadChildren: () => import("./+product/product.module").then((m) => m.ProductModule),
    data: {
      admin: true,
      tenant: true,
      breadcrumb: { skip: true },
    },
  },
  {
    path: "integrations",
    loadChildren: () => import("./+integrations/integrations.module").then((m) => m.IntegrationsModule),
    data: {
      admin: false,
      tenant: true,
      breadcrumb: { label: "Integrations" },
    },
  },
  ...errorRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot([], { preloadingStrategy: CustomPreloadingStrategy }), ErrorsModule],
  exports: [RouterModule],
  providers: [
    CustomPreloadingStrategy,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHrefFactory,
      deps: [AppMode, WindowService],
    },
    {
      provide: ROUTES,
      useFactory: (appMode: AppMode) => {
        // basically if there's no salon slug then we should show the "multi-tenant login" component
        // which allows the user to log in to some account and then
        // choose to which tenant they would like to navigate (of course, given the tenants which they are a member)
        // but because we'll issue a window redirect (and thus an app reload) when the user choose a tenant
        // we don't need to load any other modules, just this one
        if (appMode.value === "MULTI_TENANT") {
          return [
            {
              path: "",
              loadChildren: () => import("./+multi-tenant-login/multi-tenant-login.module").then((m) => m.MultiTenantLoginModule),
              data: {
                admin: false,
                tenant: false,
              },
            },
          ];
        }

        // this is a special mode just for completing Booker's OAuth process
        // so the only thing that we need to load in this case is the BookerOAuthModule
        if (appMode.value === "BOOKER-OAUTH") {
          return [
            {
              path: "",
              loadChildren: () => import("./+booker-oauth/booker-oauth.module").then((m) => m.BookerOAuthModule),
              data: {
                admin: false,
                tenant: true,
              },
            },
          ];
        }

        // this is a special mode just for completing Square's OAuth process
        // so the only thing that we need to load in this case is the SquareOAuthModule
        if (appMode.value === "SQUARE-OAUTH") {
          return [
            {
              path: "",
              loadChildren: () => import("./+square-oauth/square-oauth.module").then((m) => m.SquareOAuthModule),
              data: {
                admin: false,
                tenant: true,
              },
            },
          ];
        }

        // this is a special mode just for completing SalonInteractive's OAuth process
        // so the only thing that we need to load in this case is the SOAuthModule
        if (appMode.value === "SALON-INTERACTIVE-OAUTH") {
          return [
            {
              path: "",
              loadChildren: () =>
                import("./+salon-interactive-oauth/salon-interactive-oauth.module").then((m) => m.SalonInteractiveOAuthModule),
              data: {
                admin: false,
                tenant: true,
              },
            },
          ];
        }

        if (appMode.value === "FORGOT_PASSWORD") {
          return [
            {
              path: "forgot-password",
              loadChildren: () => import("./+reset-password/reset-password.module").then((m) => m.ResetPasswordModule),
              data: {
                admin: true,
                tenant: true,
              },
            },
          ];
        }

        if (appMode.value === "INVENTORY-INTEGRATION") {
          return [
            {
              path: "inventory-integration",
              loadChildren: () => import("./+inventory-integration/inventory-integration.module").then((m) => m.InventoryIntegrationModule),
            },
          ];
        }

        if (appMode.value === "ADMIN") {
          routes.push({
            path: "",
            redirectTo: "salons",
            pathMatch: "full",
          });
          routes.push({
            path: "home",
            redirectTo: "salons",
            pathMatch: "full",
          });
        } else {
          routes.push({
            path: "",
            redirectTo: "insights",
            pathMatch: "full",
          });
          routes.push({
            path: "home",
            redirectTo: "insights",
            pathMatch: "full",
          });
        }

        return routes;
      },
      multi: true,
      deps: [AppMode],
    },
  ],
})
export class AppRoutingModule {}
