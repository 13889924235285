import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ProductCategory } from "@getvish/model";
import { sort } from "ramda";
import { trackByIdFn } from "../../../../kernel/util";
import { ProductCategoryVM } from "../../common";

@Component({
  selector: "products-categories-list",
  templateUrl: "./products-categories-list.component.html",
  styleUrls: ["./products-categories-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsCategoriesListComponent implements OnChanges {
  @Input() public productCategories: ProductCategoryVM[];
  @Input() public doSort = true;
  @Output() public categorySelected: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>(true);

  public trackByFn = trackByIdFn;
  public sortedCategories: ProductCategoryVM[];

  public ngOnChanges(): void {
    if (this.doSort) {
      this.sortedCategories = sort((c1: ProductCategoryVM, c2: ProductCategoryVM) => {
        const s = (c1.order ?? Number.MAX_SAFE_INTEGER) - (c2.order ?? Number.MAX_SAFE_INTEGER);

        if (s === 0) {
          return c1.name.localeCompare(c2.name, undefined, { sensitivity: "base" });
        }

        return s;
      }, this.productCategories);
    } else {
      this.sortedCategories = this.productCategories;
    }
  }
}
