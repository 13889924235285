import {
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Directive({
  selector: "column-icon",
})
export class ColumnIconDirective {
  @Input()
  public icon: IconProp;

  @Input()
  public url?: string;

  @Output()
  public action: EventEmitter<void>;

  constructor() {
    this.action = new EventEmitter();
  }

  public onClick(): void {
    this.action.emit();
  }
}

@Directive({
  selector: "column-icons",
})
export class ColumnIconsDirective {
  @ContentChildren(ColumnIconDirective)
  public values: QueryList<ColumnIconDirective>;
}

@Directive({
  selector: "column-sort",
})
export class ColumnSortDirective {
  @Input()
  public direction?: 1 | -1;

  @Output() public change: EventEmitter<{ direction: 1 | -1 }>;

  constructor() {
    this.change = new EventEmitter();
  }

  public updateSort(direction: 1 | -1): void {
    this.change.emit({ direction });
  }
}

@Directive({
  selector: "[filterRenderer]",
})
export class FilterRendererDirective {}

@Directive({
  selector: "column-filter",
})
export class ColumnFilterDirective {
  @Input()
  public value?: any;

  @ContentChild(TemplateRef)
  public filterRenderer: TemplateRef<any>;
}

@Directive({
  selector: "column",
})
export class ColumnDirective implements OnChanges {
  @Input()
  public id: string;

  @Input()
  public title?: string;

  @Input()
  public showByDefault?: boolean = true;

  @Input()
  public showInColumnManager?: boolean;

  @Input()
  public hidden?: boolean;

  @Input()
  public sticky?: boolean;

  @ContentChild(ColumnFilterDirective)
  public filter: ColumnFilterDirective;

  @ContentChild(ColumnSortDirective)
  public sort: ColumnSortDirective;

  @ContentChild(ColumnIconsDirective)
  public icons: ColumnIconsDirective;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.showByDefault?.currentValue === false) {
      if (this.showInColumnManager == null) {
        this.showInColumnManager = true;
      }
    }
  }
}

@Directive({
  selector: "columns",
})
export class ColumnsDirective {
  @ContentChildren(ColumnDirective)
  public values: QueryList<ColumnDirective>;
}
