<div
  class="list-orderer"
  (cdkDropListDropped)="drop($event)"
  cdkDropList>
  <div
    class="list-orderer-item"
    cdkDrag
    cdkDragBoundary=".list-orderer"
    [class]="item.classes?.join(' ')"
    *ngFor="let item of _items">
    <fa-icon [icon]="['fas', 'grip-lines']"></fa-icon>
    <ng-template *ngTemplateOutlet="item.prefix"></ng-template>
    <span>{{ item.text }}</span>
  </div>
</div>
