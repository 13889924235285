import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import * as actions from "../store/order.actions";
import * as fromOrder from "../store/order.selectors";
import * as appointmentActions from "../store/appointment.actions";
import { ServiceDescription } from "@getvish/model";
import { AppointmentVM } from "../models/appointment";
import * as fromSalonConfig from "app/+salon-config/store/salon-config.selectors";
import * as fromAppointments from "../store/appointment.selectors";

@Component({
  selector: "order-panel",
  templateUrl: "order-panel.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPanelContainer {
  public module$: Observable<string>;
  public header$: Observable<string>;

  public timeZone$: Observable<string>;
  public loading$: Observable<boolean>;
  public resolveAppointments$: Observable<AppointmentVM[]>;
  public resolveServiceDescriptions$: Observable<ServiceDescription[]>;

  public showResolve: boolean = false;

  constructor(
    private _store: Store<AppState>,
    private _cd: ChangeDetectorRef
  ) {
    this.module$ = _store.pipe(select(fromOrder.getModule));
    this.header$ = this.module$.pipe(map((module) => (module === "front-desk" ? "Appointment Details" : "Order Details")));
  }

  public setShowResolve(showResolve: boolean): void {
    this.showResolve = showResolve;

    if (this.showResolve) {
      this._store
        .pipe(select(fromOrder.getSelectedAppointment))
        .pipe(take(1))
        .subscribe((appointment) => {
          this._store.dispatch(new appointmentActions.LoadResolve([appointment]));
        });

      this.timeZone$ = this._store.select(fromSalonConfig.getSalonTimeZone);
      this.loading$ = this._store.select(fromAppointments.getResolveLoading);
      this.resolveAppointments$ = this._store.select(fromAppointments.getResolveAppointments);
      this.resolveServiceDescriptions$ = this._store.select(fromAppointments.getResolveServiceDescriptions);
    }
  }

  public resolve(appointments: AppointmentVM[]) {
    this._store.dispatch(appointmentActions.Resolve({ appointments }));

    this._store
      .select(fromAppointments.getResolving)
      .pipe(
        filter((resolving) => resolving === false),
        take(1)
      )
      .subscribe(() => {
        this.showResolve = false;
        this._cd.markForCheck();
      });
  }

  public close(): void {
    if (this.showResolve) {
      this.showResolve = false;
    } else {
      this._store.dispatch(actions.done());
    }
  }
}
