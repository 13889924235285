import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MeasurementUnit, MeasurementUnitType, SalonProductModule, TargetWeightMetadata, TargetWeightType } from "@getvish/model";
import { ProductAllowanceBlueprintIngredientVM } from "app/kernel/models/product-allowance";

@Component({
  selector: "pac-blueprint-ingredient",
  templateUrl: "ingredient.component.html",
  styleUrls: ["ingredient.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PACBlueprintIngredientComponent {
  @Input() public ingredient: ProductAllowanceBlueprintIngredientVM;
  @Input() public weightType: TargetWeightType;
  @Input() public totalProductWeight: number;
  @Input() public includeSeparator: boolean;
  @Input() public currency: string;
  @Input() public measurementUnit: MeasurementUnit;
  @Output() public ingredientChange: EventEmitter<ProductAllowanceBlueprintIngredientVM>;
  @Output() public delete: EventEmitter<void>;

  public constructor() {
    this.ingredientChange = new EventEmitter<ProductAllowanceBlueprintIngredientVM>();
    this.delete = new EventEmitter<void>();
  }

  public getIngredientTypeText() {
    return [this.ingredient.product.manufacturer?.name, this.ingredient.product.category?.name].filter((s) => s != null).join(" - ");
  }

  public getCostPerMeasure() {
    const retailPrice = SalonProductModule.getRetailPrice(this.ingredient.product);
    const containerSize = this.ingredient.product.containerSize;

    if (!retailPrice || !containerSize) {
      return null;
    }

    return retailPrice / containerSize;
  }

  public getAmountOptions() {
    let values;

    if (this.weightType === TargetWeightType.ABSOLUTE) {
      if (this.measurementUnit.type === MeasurementUnitType.OUNCES) {
        values = [0.5, 1, 2];
      } else {
        values = [15, 30, 60];
      }
    } else {
      values = [1, 1.5, 2];
    }

    return values.map((v) => ({ value: v, _type: this.weightType }));
  }

  public getStep() {
    if (this.weightType === TargetWeightType.ABSOLUTE) {
      if (this.measurementUnit.type === MeasurementUnitType.OUNCES) {
        return 1;
      }

      return 5;
    }

    return 0.25;
  }

  public getCost() {
    const costPerMeasure = this.getCostPerMeasure();

    if (costPerMeasure == null) {
      return 0;
    }

    const weightValue = this.ingredient.weight.value;

    if (!weightValue) {
      return 0;
    }

    if (this.ingredient.weight._type === TargetWeightType.ABSOLUTE) {
      return costPerMeasure * weightValue;
    }

    if (!this.totalProductWeight) {
      return 0;
    }

    return costPerMeasure * (this.totalProductWeight * weightValue);
  }

  public getWeight() {
    if (this.ingredient.weight?._type === TargetWeightType.ABSOLUTE) {
      return {
        value: this.measurementUnit.convertFromBase(this.ingredient.weight.value),
        _type: this.ingredient.weight._type,
      };
    }

    return this.ingredient.weight;
  }

  public setWeight(weight: TargetWeightMetadata) {
    this.ingredientChange.emit({
      ...this.ingredient,
      weight:
        weight._type === TargetWeightType.ABSOLUTE
          ? {
              value: this.measurementUnit.convertToBase(weight.value),
              _type: weight._type,
            }
          : weight,
    });
  }
}
