import { NgModule } from "@angular/core";
import { SharedModule } from "../kernel/shared";
import { SalonsRoutingModule } from "./salons-routing.module";
import { SalonsContainer, EditSalonContainer, DeleteSalonContainer, ImportSalonDataContainer, MigrateSalonContainer } from "./containers";
import { EditSalonComponent, DeleteSalonComponent, UploadCsvComponent, MigrateSalonComponent } from "./components";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { SalonsSharedModule } from "./salons-shared.module";

import { salonReducer } from "./store/salon.reducer";
import { SalonEffects } from "./store/salon.effects";
import { editSalonReducer } from "./store/edit-salon.reducer";
import { reducer as importSalonDataReducer } from "./store/import-salon-data.reducer";
import { EditSalonEffects } from "./store/edit-salon.effects";
import { deleteSalonReducer } from "./store/delete-salon.reducer";
import { DeleteSalonEffects } from "./store/delete-salon.effects";
import { migrateSalonReducer } from "./store/migrate-salon.reducer";
import { MigrateSalonEffects } from "./store/migrate-salon.effects";
import { reducer as revokeRestoreAccessReducer, featureKey as revokeRestoreAccessFeatureKey } from "./store/revoke-restore-access.reducer";
import {
  ExportFormulaSpreadsheetService,
  ExportUsageReportService,
  ExportDataSpreadsheetService,
  UploadSalonDataService,
} from "./services";
import { faCircleNotch, faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { ImportSalonDataEffects } from "./store/import-salon-data.effects";
import { CardModule, DataTableModule, PageModule, ProgressIndicatorModule } from "app/+components";
import { RevokeRestoreAccessContainer } from "./containers/revoke-restore-access.container";
import { RevokeRestoreAccessEffects } from "./store/revoke-restore-access.effects";

@NgModule({
  imports: [
    CardModule,
    DataTableModule,
    PageModule,
    SharedModule,
    SalonsRoutingModule,
    StoreModule.forFeature("salons", salonReducer),
    StoreModule.forFeature("editSalon", editSalonReducer),
    EffectsModule.forFeature([
      SalonEffects,
      EditSalonEffects,
      DeleteSalonEffects,
      ImportSalonDataEffects,
      MigrateSalonEffects,
      RevokeRestoreAccessEffects,
    ]),
    StoreModule.forFeature("deleteSalon", deleteSalonReducer),
    StoreModule.forFeature("importSalonData", importSalonDataReducer),
    StoreModule.forFeature("migrateSalon", migrateSalonReducer),
    StoreModule.forFeature(revokeRestoreAccessFeatureKey, revokeRestoreAccessReducer),
    SalonsSharedModule,
    ProgressIndicatorModule,
  ],
  declarations: [
    SalonsContainer,
    EditSalonContainer,
    EditSalonComponent,
    DeleteSalonContainer,
    DeleteSalonComponent,
    ImportSalonDataContainer,
    MigrateSalonContainer,
    MigrateSalonComponent,
    UploadCsvComponent,
    RevokeRestoreAccessContainer,
  ],
  providers: [ExportFormulaSpreadsheetService, ExportUsageReportService, ExportDataSpreadsheetService, UploadSalonDataService],
})
export class SalonsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faEllipsisV, faCircleNotch);
  }
}
