<div id="container">
  <div *ngIf="errors">
    <alert-component type="danger">
      <b>Something went wrong during upload:</b>
      <p>
        {{ errors }}
      </p>
    </alert-component>
  </div>
  <div *ngIf="success">
    <alert-component type="success">
      <b>Spreadsheet uploaded successfully</b>
    </alert-component>
  </div>
  <div *ngIf="!loading; else uploading">
    <ng-container *ngIf="success; else fileUpload">
      <button
        (click)="close.emit()"
        class="button primary">
        <span>Done</span>
      </button>
    </ng-container>
    <ng-template #fileUpload>
      <input
        id="file"
        name="file"
        type="file"
        (change)="onFileChange($event)" />
      <label
        for="file"
        id="upload-csv-label"
        ><fa-icon icon="cloud-upload-alt"></fa-icon
      ></label>
    </ng-template>
  </div>

  <ng-template #uploading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</div>
