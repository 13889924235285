import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";

import { Manufacturer, SalonProduct } from "@getvish/model";

import { ProductSelectionDialogComponent } from "../components/product-selection-dialog";
import { ProductSelectionMode, ProductSelectionType } from "../store/product-selection.actions";
import { JsonObject } from "@getvish/stockpile";

type ProductSelectionDialogData = {
  mode?: ProductSelectionMode;
  type?: ProductSelectionType;
  saveButtonText?: string;
  disableSelectAll?: boolean;
};

@Injectable()
export class ProductSelectionDialogService {
  constructor(private _matDialog: MatDialog) {}

  public show(data: ProductSelectionDialogData): Observable<{ salonProducts: SalonProduct[]; manufacturer: Manufacturer }> {
    const dialogRef = this._matDialog.open<
      ProductSelectionDialogComponent,
      JsonObject,
      { salonProducts: SalonProduct[]; manufacturer: Manufacturer }
    >(ProductSelectionDialogComponent, {
      data,
      disableClose: true,
      width: "80vw",
      height: "95vh",
      maxHeight: "95vh",
      panelClass: "dlg-no-padding-pane",
    });

    return dialogRef.afterClosed();
  }
}
