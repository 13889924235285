import { Injectable } from "@angular/core";
import { map, merge, Observable, toArray } from "rxjs";
import { TenantEntitlement } from "app/kernel/models";
import { HttpRequestHandler } from "@getvish/stockpile";
import { fold } from "fp-ts/lib/Option";
import { flatten } from "ramda";

@Injectable()
export class TenantEntitlementsService {
  constructor(private _httpRequestHandler: HttpRequestHandler) {}

  public findForSalonIds(salonIds: string[]): Observable<TenantEntitlement[]> {
    return merge(...salonIds.map((salonId) => this.findForSalonId(salonId)), 5).pipe(toArray(), map(flatten));
  }

  public findForSalonId(salonId: string): Observable<TenantEntitlement[]> {
    return this._httpRequestHandler.get<TenantEntitlement[]>(`salons/${salonId}/entitlements`).pipe(
      map(
        fold(
          () => [],
          (entitlements) => entitlements
        )
      )
    );
  }
}
