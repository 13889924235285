import { Injectable } from "@angular/core";
import { LocalStorage } from "./local-storage.service";

@Injectable()
export class GlobalSettingsService {
  constructor(private _localStorage: LocalStorage) {}

  public setSalonId(salonId: string): void {
    this._localStorage.setItem("salonId", salonId);
  }
}
