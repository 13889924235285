import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProductCategory, SalonProductCategory } from "@getvish/model";
import { ListOrdererItem } from "app/+components/+list-orderer/components/list-orderer.component";
import { pipe } from "fp-ts/lib/function";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

@Component({
  selector: "product-category-order-dialog",
  templateUrl: "./product-category-order-dialog.component.html",
  styleUrls: ["./product-category-order-dialog.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCategoryOrderDialogComponent {
  public items: ListOrdererItem[];
  private productCategories: ProductCategory[];
  private salonProductCategories?: SalonProductCategory[];

  constructor(
    private _matDialogRef: MatDialogRef<ProductCategoryOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      productCategories: ProductCategory[];
      salonProductCategories?: SalonProductCategory[];
    }
  ) {
    this.productCategories = data.productCategories;
    this.salonProductCategories = data.salonProductCategories;

    this.items = pipe(
      data.productCategories,
      R.map((productCategory) => {
        const salonProductCategory = data.salonProductCategories?.find((spc) => spc.productCategoryId === productCategory._id);

        return {
          id: uuid(),
          productCategory,
          salonProductCategory,
          text: productCategory.name,
        };
      }),
      R.sort((i1, i2) => {
        const order1 = i1["salonProductCategory"]?.order ?? i1["productCategory"].order ?? Number.MAX_SAFE_INTEGER;
        const order2 = i2["salonProductCategory"]?.order ?? i2["productCategory"].order ?? Number.MAX_SAFE_INTEGER;

        const ret = order1 - order2;

        if (ret === 0) {
          const n1 = i1["productCategory"].name;
          const n2 = i2["productCategory"].name;

          return n1.localeCompare(n2, undefined, { sensitivity: "base" });
        }

        return ret;
      })
    );
  }

  public orderChanged(items: ListOrdererItem[]) {
    this.items = items.map((item) => ({
      ...(this.items.find((i) => i.id === item.id) ?? []),
      ...item,
    }));
  }

  public save(): void {
    const changes = this.productCategories.reduce((acc, pc) => {
      const idx = this.items.findIndex((i) => i["productCategory"]._id === pc._id);

      if (R.isNil(this.salonProductCategories)) {
        if (pc.order !== idx) {
          acc.push({ ...pc, order: idx });
        }
      } else {
        const spcs = this.salonProductCategories.filter((spc) => spc.productCategoryId === pc._id);

        if (R.isEmpty(spcs)) {
          acc.push({
            productCategoryId: pc._id,
            order: idx,
          });
        } else {
          acc.push(
            ...spcs
              .filter((spc) => spc.order !== idx)
              .map((spc) => ({
                ...spc,
                order: idx,
              }))
          );
        }
      }

      return acc;
    }, []);

    this._matDialogRef.close(changes);
  }

  public close(): void {
    this._matDialogRef.close();
  }

  public sortAlphabetical() {
    this.items = R.sortBy((i) => i.text, this.items);
  }

  public sortReverseAlphabetical() {
    this.items = R.reverse(R.sortBy((i) => i.text, this.items));
  }
}
