import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { HTTP_URL } from "../../kernel/services/common";

@Injectable()
export class ExportFormulaSpreadsheetService {
  private _endpointUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string
  ) {
    this._endpointUrl = `${this._httpUrl}/formula/export`;
  }

  public downloadFormulaSpreadsheet(slug: string, authToken: string): Observable<Blob> {
    const headers = { "X-Salon-Slug": slug, "X-Auth-Token": authToken };

    return this._http.get(this._endpointUrl, { headers, responseType: "blob" });
  }
}
