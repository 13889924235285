import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { initialize, initializeFail, initializeSuccess } from "./performed-service-list.actions";
import { catchError, map, of, switchMap } from "rxjs";
import { PerformedServiceListService } from "../services/performed-service-list.service";

@Injectable()
export class PerformedServiceListEffects {
  public initialize$ = createEffect(() =>
    this._actions$.pipe(
      ofType(initialize),
      switchMap(({ payload }) =>
        this._performedServiceListService.findPerformedServicesForDateRange(
          payload.startDate,
          payload.endDate,
          payload.employeeId,
          payload.serviceId,
          payload.subset
        )
      ),
      map(({ performedServices, appointments }) => initializeSuccess({ performedServices, appointments })),
      catchError((error) => of(initializeFail({ error })))
    )
  );

  constructor(
    private _performedServiceListService: PerformedServiceListService,
    private _actions$: Actions
  ) {}
}
