import { createAction, props, union } from "@ngrx/store";
import { Salon, SalonConfig } from "@getvish/model";
import { HttpError } from "@getvish/stockpile";

export const init = createAction("[App] Init");

export const initializeTenantUrlConfig = createAction("[App] Initialize Tenant Url Config", props<{ slug: string; pathname: string }>());

export const changeSalon = createAction("[App] Change Salon", (payload: { salon: Salon }) => ({ slug: payload.salon.slug }));

export const changeSalonBySlug = createAction("[App] Change Salon By Slug", props<{ slug: string }>());

export const sendHandshake = createAction("[App] Send Handshake", props<{ salonId: string; sessionId: string }>());

export const syncEventSubscriptionFail = createAction("[App] Sync Event Subscription Fail", props<{ error: HttpError }>());

export const navigateHome = createAction("[App] Navigate Home");

export const loadSalonConfig = createAction("[App] Load Salon Config");

export const loadSalonConfigSuccess = createAction("[App] Load Salon Config Success", props<{ salonConfig: SalonConfig }>());

export const loadSalonConfigForbidden = createAction("[App] Load Salon Config Forbidden");

export const loadSalonConfigFail = createAction("[App] Load Salon Config Fail");

export const loadingStarted = createAction("[App] Loading Started");
export const loadingComplete = createAction("[App] Loading Complete");

const actions = union({
  init,
  initializeTenantUrlConfig,
  changeSalon,
  changeSalonBySlug,
  sendHandshake,
  syncEventSubscriptionFail,
  navigateHome,
  loadSalonConfig,
  loadSalonConfigSuccess,
  loadSalonConfigForbidden,
  loadSalonConfigFail,
  loadingStarted,
  loadingComplete,
});

export type Actions = typeof actions;
