<div>
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value, manufacturer)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name" />
      <mat-error *ngIf="form.get('name').hasError('required')">Required</mat-error>
    </mat-form-field>
  </form>

  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value, manufacturer)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Save
    </button>
  </div>
</div>
