import { createFeatureSelector, createSelector } from "@ngrx/store";
import { fromNullable } from "fp-ts/Option";
import { isDefined } from "../../kernel/util";
import { EmployeeState } from "./employee.reducer";

/*** SELECTORS ***/
export const getState = createFeatureSelector<EmployeeState>("employees");
// export const getRecords = createSelector(getState, (state) => state.records);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getSaving = createSelector(getState, (state) => state.saving);
export const getRestoring = createSelector(getState, (state) => state.restoring);
export const getPaging = createSelector(getState, (state) => fromNullable(state.paging));
export const getCriteria = createSelector(getState, (state) => state.criteria);
export const getSort = createSelector(getState, (state) => state.sort);
export const getFilter = createSelector(getState, (state) => state.filter);
export const getSelected = createSelector(getState, (state) => state.selectedRecords);
export const getTableFilter = createSelector(getState, (state) => state.tableFilter);
export const getShowDeleted = createSelector(getState, (state) => state.showDeleted);
export const getEmployees = createSelector(getState, (state) => (isDefined(state.records) ? state.records : []));
