import { AbstractControl } from "@angular/forms";
import { complement, isNil } from "ramda";
import { validate } from "uuid";

export const isUuidValidator = (control: AbstractControl) => {
  const value = control.value;
  const isNotValidUuid = complement(validate);

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined
  } else if (isNotValidUuid(value)) {
    return { isUuid: true };
  }
};
