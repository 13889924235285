import { NgModule } from "@angular/core";
import { SharedModule } from "app/kernel/shared";
import { MapComponent } from "./components/map.component";

@NgModule({
  declarations: [MapComponent],
  imports: [SharedModule],
  exports: [MapComponent],
})
export class MapModule {}
