import { Action } from "@ngrx/store";
import { Salon } from "@getvish/model";

export enum Types {
  NAVIGATE = "[Delete Salon] Navigate",
  LOAD = "[Delete Salon] Load",
  LOAD_SUCCESS = "[Delete Salon] Load Success",
  LOAD_FAIL = "[Delete Salon] Load Fail",
  DELETE = "[Delete Salon] Delete",
  SUCCESS = "[Delete Salon] Success",
  FAIL = "[Delete Salon] Fail",
  CLOSE = "[Delete Salon] Close ",
  CANCEL = "[Delete Salon] Cancel",
  DONE = "[Delete Salon] Done",
}

export class Load implements Action {
  public readonly type = Types.LOAD;

  constructor(public payload: { salonId: string }) {}
}

export class LoadSuccess implements Action {
  public readonly type = Types.LOAD_SUCCESS;

  constructor(public payload: { salon: Salon }) {}
}

export class LoadFail implements Action {
  public readonly type = Types.LOAD_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class Navigate implements Action {
  public readonly type = Types.NAVIGATE;

  public payload: { salonId: string };

  constructor(payload: { salon: Salon }) {
    const salonId = payload.salon._id;

    this.payload = { salonId };
  }
}

export class Delete implements Action {
  public readonly type = Types.DELETE;

  public payload: { salonId: string };

  constructor(payload: { salon: Salon }) {
    const salonId = payload.salon._id;

    this.payload = { salonId };
  }
}

export class Success implements Action {
  public readonly type = Types.SUCCESS;
}

export class Fail implements Action {
  public readonly type = Types.FAIL;

  constructor(public payload: { error: Error }) {}
}

export class Close implements Action {
  public readonly type = Types.CLOSE;
}

export class Cancel implements Action {
  public readonly type = Types.CANCEL;
}

export class Done implements Action {
  public readonly type = Types.DONE;
}

export type Actions = Load | LoadSuccess | LoadFail | Navigate | Delete | Success | Fail | Close | Cancel | Done;
