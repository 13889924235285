import { NgModule } from "@angular/core";
import { SharedModule } from "app/kernel/shared";
import { OrderComponent, OrderLinesComponent, AppointmentStatusLabelComponent } from "./components";
import { OrderContainer, OrderPanelContainer } from "./containers";
import { ProgressIndicatorModule } from "app/+components";
import { ResolveAppointmentsContainer } from "./containers/resolve-appointments.container";
import { AppointmentChangesComponent } from "./components/appointment-changes.component";
import { ResolveAppointmentsComponent } from "./components/resolve-appointments.component";

@NgModule({
  imports: [SharedModule, ProgressIndicatorModule],
  declarations: [
    OrderPanelContainer,
    OrderContainer,
    OrderComponent,
    OrderLinesComponent,
    AppointmentStatusLabelComponent,
    ResolveAppointmentsContainer,
    ResolveAppointmentsComponent,
    AppointmentChangesComponent,
  ],
  exports: [
    OrderPanelContainer,
    OrderContainer,
    OrderComponent,
    OrderLinesComponent,
    AppointmentStatusLabelComponent,
    ResolveAppointmentsContainer,
    AppointmentStatusLabelComponent,
  ],
  providers: [],
})
export class FrontDeskSharedModule {}
