<div *ngIf="form | isDefined">
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value, productCategory)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name" />
      <mat-error *ngIf="form.get('name').hasError('required')">Required</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Category</mat-label>
      <mat-select formControlName="parentCategoryId">
        <mat-option>-- None --</mat-option>
        <mat-option
          *ngFor="let category of _categories"
          [ngClass]="'level-' + category.level"
          [value]="category._id">
          {{ category.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('parentCategoryId').hasError('invalidParentCategoryId')">
        Parent category can't be set to itself
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Order</mat-label>
      <input
        matInput
        formControlName="order"
        type="number" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Flags</mat-label>
      <mat-select
        formControlName="flags"
        multiple>
        <mat-option value="DEVELOPER">Developers</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveForm(form.value, productCategory)"
      type="button"
      [disabled]="form.invalid"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Save
    </button>
  </div>
</div>
