import { AbstractControl } from "@angular/forms";
import { isNil, includes, not } from "ramda";

export const MatchesOneOfValidator = (items: string[]) => (control: AbstractControl) => {
  const value = control.value;

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined
  } else if (not(includes(value, items))) {
    return { matchesOneOf: true };
  }

  return null;
};
