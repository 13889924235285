import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

import { MixingContainerVM } from "app/+components";
import { ProductAllowanceBlueprintVM } from "app/kernel/models/product-allowance";

import {
  calculateBlueprintBowlProductsTotal,
  calculateBlueprintBowlDeveloperTotal,
  calculateBlueprintBowlTotal,
  getMixingContainerVM,
} from "app/+service-menu/utils";

@Component({
  selector: "pac-blueprint-summary",
  templateUrl: "blueprint-summary.component.html",
  styleUrls: ["blueprint-summary.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PACBlueprintSummaryComponent {
  @Input() public blueprintBowl: ProductAllowanceBlueprintVM;
  @Input() public currency: string;

  public getMixingContainerVM(): MixingContainerVM {
    return getMixingContainerVM(this.blueprintBowl);
  }

  public getProductSubtotal(): number {
    return calculateBlueprintBowlProductsTotal(this.blueprintBowl);
  }

  public getDeveloperSubtotal(): number {
    return calculateBlueprintBowlDeveloperTotal(this.blueprintBowl);
  }

  public getTotal(): number {
    return calculateBlueprintBowlTotal(this.blueprintBowl);
  }
}
