import { Action } from "@ngrx/store";

export enum Types {
  TOGGLE_MENU = "[Ui] Toggle Menu",
  OPEN_PANEL = "[Ui] Open Panel",
  CLOSE_PANEL = "[Ui] Close Panel",
}

export class ToggleMenu implements Action {
  public readonly type = Types.TOGGLE_MENU;
}

export class OpenPanel implements Action {
  public readonly type = Types.OPEN_PANEL;
}

export class ClosePanel implements Action {
  public readonly type = Types.CLOSE_PANEL;
}

export type Actions = ToggleMenu | OpenPanel | ClosePanel;
