import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
  faCalculator,
  faEllipsisV,
  faEmptySet,
  faSort,
  faSortUp,
  faSortDown,
  faArrowUp,
  faArrowDown,
  faFilter,
  faEyeSlash,
  faTableColumns,
} from "@fortawesome/pro-solid-svg-icons";
import { DataTableComponent } from "./components/data-table.component";
import { SharedModule } from "app/kernel/shared";
import { MixingContainerModule } from "../+mixing-container";

import {
  ColoredIconCellComponent,
  MenuCellComponent,
  MenuItemDirective,
  MixingContainerCellComponent,
  RollupCellComponent,
  SelectionCellComponent,
  TextCellComponent,
  TextDirective,
} from "./components/cell-renderers";
import {
  NumericFilterComponent,
  SelectFilterComponent,
  SelectFilterOptionDirective,
  TextFilterComponent,
} from "./components/filter-renderers";
import {
  ColumnsDirective,
  ColumnDirective,
  ColumnIconsDirective,
  ColumnIconDirective,
  ColumnFilterDirective,
  ColumnSortDirective,
  BodyDirective,
  RowDirective,
  CategoryDirective,
  CellDirective,
  FooterDirective,
} from "./directives";
import { ColoredDotCellComponent } from "./components/cell-renderers/colored-dot/colored-dot-cell.component";
import { PagingComponent } from "./components/paging";

@NgModule({
  declarations: [
    DataTableComponent,
    ColoredDotCellComponent,
    ColoredIconCellComponent,
    MenuCellComponent,
    MenuItemDirective,
    MixingContainerCellComponent,
    SelectionCellComponent,
    TextCellComponent,
    RollupCellComponent,
    TextDirective,
    NumericFilterComponent,
    SelectFilterComponent,
    SelectFilterOptionDirective,
    TextFilterComponent,
    ColumnDirective,
    ColumnIconsDirective,
    ColumnIconDirective,
    ColumnFilterDirective,
    ColumnSortDirective,
    ColumnsDirective,
    BodyDirective,
    RowDirective,
    FooterDirective,
    CategoryDirective,
    CellDirective,
    PagingComponent,
  ],
  imports: [SharedModule, CommonModule, MixingContainerModule],
  exports: [
    DataTableComponent,
    ColoredDotCellComponent,
    ColoredIconCellComponent,
    MenuCellComponent,
    MenuItemDirective,
    MixingContainerCellComponent,
    SelectionCellComponent,
    TextCellComponent,
    RollupCellComponent,
    TextDirective,
    NumericFilterComponent,
    SelectFilterComponent,
    SelectFilterOptionDirective,
    TextFilterComponent,
    ColumnDirective,
    ColumnIconsDirective,
    ColumnIconDirective,
    ColumnFilterDirective,
    ColumnSortDirective,
    ColumnsDirective,
    BodyDirective,
    RowDirective,
    FooterDirective,
    CategoryDirective,
    CellDirective,
  ],
})
export class DataTableModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faArrowUp,
      faArrowDown,
      faCalculator,
      faEllipsisV,
      faEmptySet,
      faFilter,
      faSort,
      faSortUp,
      faSortDown,
      faEyeSlash,
      faTableColumns
    );
  }
}
