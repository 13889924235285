import { Salon } from "@getvish/model";
import * as salon from "./salon.actions";
import { PagingMetadata, JsonObject } from "@getvish/stockpile";
import { append } from "ramda";

export interface SalonState {
  loading: boolean;
  saving: boolean;
  records: Salon[];
  paging: PagingMetadata;
  selected: string;
  sort: JsonObject;
  filter: string;
}

const initialState: SalonState = {
  loading: false,
  saving: false,
  records: [],
  paging: undefined,
  selected: undefined,
  sort: undefined,
  filter: undefined,
};

export function salonReducer(state: SalonState = initialState, action: salon.Actions): SalonState {
  switch (action.type) {
    case salon.Types.SEARCH: {
      const filter = action.payload.filter;

      return {
        ...state,
        filter,
      };
    }

    case salon.Types.UPDATE_SORT: {
      const sort = action.payload;

      return {
        ...state,
        sort,
      };
    }

    case salon.Types.LOAD_ALL: {
      const loading = true;
      const { sort, filter } = action.payload;

      return {
        ...state,
        loading,
        sort,
        filter,
      };
    }

    case salon.Types.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload,
        paging: action.paging,
      };
    }

    case salon.Types.UPDATE:
    case salon.Types.ADD: {
      const saving = true;

      return {
        ...state,
        saving,
      };
    }

    case salon.Types.ADD_SUCCESS: {
      return {
        ...state,
        saving: false,
        records: append(action.payload, state.records),
      };
    }

    case salon.Types.UPDATE_SUCCESS: {
      const saving = false;

      return {
        ...state,
        saving,
        records: state.records.map((p) => (p._id === action.payload._id ? action.payload : p)),
      };
    }

    default: {
      return state;
    }
  }
}
