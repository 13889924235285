import { Manufacturer, Product, ProductCategory, SalonProduct } from "@getvish/model";
import { createAction, props } from "@ngrx/store";
import { FilterKey } from "../models/product-selection-vm";

export const show = createAction("[Import Salon Products] Show");

export const load = createAction("[Import Salon Products] Load");

export const loadSuccess = createAction(
  "[Import Salon Products] Load Success",
  props<{ salonProducts: SalonProduct[]; manufacturers: Manufacturer[] }>()
);

export const loadFail = createAction("[Import Salon Products] Load Fail", props<{ error: Error }>());

export const selectManufacturer = createAction("[Import Salon Products] Select Manufacturer", props<{ manufacturer: Manufacturer }>());

export const loadProductsForManufacturerSuccess = createAction(
  "[Import Salon Products] Load Products For Manufacturer Success",
  props<{ products: Product[]; categories: ProductCategory[] }>()
);

export const loadProductsForManufacturerFail = createAction(
  "[Import Salon Products] Load Products For Manufacturer Fail",
  props<{ error: any }>()
);

export const toggleProduct = createAction("[Import Salon Products] Toggle Product", props<{ product: Product }>());

export const selectProducts = createAction("[Import Salon Products] Select Products", props<{ products: Product[] }>());

export const deselectProducts = createAction("[Import Salon Products] Deselect Products", props<{ products: Product[] }>());

export const search = createAction("[Import Salon Products] Search", props<{ filter: string }>());

export const setFilters = createAction("[Import Salon Products] Set Filters", props<{ filters: FilterKey[] }>());

export const confirmImport = createAction("[Import Salon Products] Confirm Import");

export const previousStep = createAction("[Import Salon Products] Previous Step");

export const importAdditional = createAction("[Import Salon Products] Import Additional Products");

export const removeAllProductsForManufacturer = createAction(
  "[Import Salon Products] Remove All Products For Manufacturer",
  props<{ manufacturer: Manufacturer }>()
);

export const importProducts = createAction(
  "[Import Salon Products] Import Products",
  props<{ productGroups: { manufacturer: Manufacturer; products: Product[] }[] }>()
);

export const importProductsSuccess = createAction(
  "[Import Salon Products] Import Products Success",
  props<{ salonProducts: SalonProduct[]; manufacturers: Manufacturer[] }>()
);

export const importProductsFail = createAction("[Import Salon Products] Import Products Fail", props<{ error: Error }>());

export const pushCategory = createAction("[Import Salon Products] Push Catgory", props<{ category: ProductCategory }>());

export const popToCategory = createAction("[Import Salon Products] Pop To Category", props<{ categoryId: string }>());

export const clearCategories = createAction("[Import Salon Products] Clear Categories");
