import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DiscontinuedProduct, Product, ProductCategory, ProductFlag } from "@getvish/model";
import { pipe } from "fp-ts/function";
import { includes, map, sort } from "ramda";

import { option } from "fp-ts";
import { Selections } from "app/+components";

type ProductFlagVM = ProductFlag | "DISCONTINUED";
interface ProductVm extends Omit<Product, "flags"> {
  inactive?: boolean;
  selected?: boolean;
  flags: ProductFlagVM[];
}

@Component({
  selector: "products-list",
  templateUrl: "products-list.component.html",
  styleUrls: ["products-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsListComponent implements OnChanges {
  @Input() public products: Product[];
  @Input() public loading: boolean;
  @Input() public discontinuedProducts: { [key: string]: DiscontinuedProduct };
  @Input() public selectedProducts: Product[];
  @Input() public rootCategoryMap: Map<string, ProductCategory>;
  @Output() public edit: EventEmitter<Product>;
  @Output() public select: EventEmitter<Product>;
  @Output() public clearSelections: EventEmitter<void>;
  @Output() public discontinue: EventEmitter<Product>;

  public selections: Selections;
  public productVms: ProductVm[];
  public sortDirection: 1 | -1 = 1;

  constructor(private cd: ChangeDetectorRef) {
    this.edit = new EventEmitter(true);
    this.select = new EventEmitter(true);
    this.clearSelections = new EventEmitter(true);
    this.discontinue = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    this._buildProductVms();
  }

  private _buildProductVms(): void {
    const products = this.products;

    this.productVms = pipe(
      products,
      map((product) => {
        const inactive = includes(
          "INACTIVE",
          pipe(
            option.fromNullable(product.flags),
            option.getOrElse(() => [])
          )
        );

        return {
          ...product,
          flags:
            this.discontinuedProducts[product._id] != null
              ? ([...(product.flags || []), "DISCONTINUED"] as ProductFlagVM[])
              : product.flags,
          inactive,
          selected: this.selectedProducts.some((p) => p._id === product._id),
        };
      }),
      sort((p1, p2) => {
        const s = p1.name.localeCompare(p2.name);

        if (this.sortDirection === 1) {
          return s;
        }

        return s * -1;
      })
    );

    this.selections = {
      selection: {
        count: this.selectedProducts.length,
        clearSelections: this.clearSelected.bind(this),
      },
    };
  }

  public updateSort(direction: 1 | -1) {
    this.sortDirection = direction;
    this._buildProductVms();
    this.cd.markForCheck();
  }

  public editProduct(product: ProductVm): void {
    this.edit.emit(product as Product);
  }

  public discontinueProduct(product: ProductVm): void {
    this.discontinue.emit(product as Product);
  }

  public selectProduct(product: ProductVm): void {
    this.select.emit(product as Product);
  }

  public clearSelected(): void {
    this.clearSelections.emit();
  }

  public productRowTrackBy(idx: number, product: ProductVm) {
    return product._id;
  }
}
