import { Salon } from "@getvish/model";
import { createAction, props, union } from "@ngrx/store";
import { SalonApiVersion } from "app/kernel/models/salon-api-version";

export const loadForSalons = createAction("[Salon API Version] Load For Salons", props<{ salons: Salon[] }>());

export const loadForSalonsSuccess = createAction(
  "[Salon API Version] Load For Salons Success",
  props<{ salonApiVersions: SalonApiVersion[] }>()
);

export const loadForSalonsFail = createAction("[Salon API Version] Load For Salons Fail", props<{ error: string }>());

const actions = union({
  loadForSalons,
  loadForSalonsSuccess,
  loadForSalonsFail,
});

export type Actions = typeof actions;
