import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProductReport } from "../../kernel/models";
import { pipe } from "fp-ts/function";
import { getOrElse, some, none } from "fp-ts/lib/Option";
import { option } from "fp-ts";
import { isNil } from "ramda";
import { getUtcDateRangeDaysFromZonedTime } from "app/kernel/util/zoned-time-utils";

@Injectable()
export class ProductReportService extends EntityService<ProductReport> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "productReports" });
  }

  public fetchForDateRange(
    manufacturerId: string,
    maybeStartDate: number | undefined,
    maybeEndDate: number | undefined,
    timeZone: string
  ): Observable<ProductReport> {
    const maybeDateRange = isNil(maybeStartDate) || isNil(maybeEndDate) ? none : some({ startDate: maybeStartDate, endDate: maybeEndDate });

    const { startDate, endDate } = getOrElse(() => getUtcDateRangeDaysFromZonedTime(timeZone, 30))(maybeDateRange);

    const criteria = { manufacturerId, startDate, endDate };

    return this.findOne(criteria).pipe(map((result) => pipe(option.toUndefined(result))));
  }

  public fetchForEmployeeInDateRange(employeeId: string, startDate: number, endDate: number): Observable<option.Option<ProductReport>> {
    const employeeIds = [employeeId];
    const criteria = { startDate, endDate, employeeIds };

    return this.findOne(criteria);
  }
}
