import {
  AfterContentInit,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  TemplateRef,
} from "@angular/core";

@Directive({
  selector: "cell",
})
export class CellDirective {
  @ContentChild(TemplateRef)
  public renderer: TemplateRef<any>;

  @Input()
  public columnId: string;

  @Input()
  public className?: string;
}

@Directive({
  selector: "category",
})
export class CategoryDirective {
  @Input()
  public id: string;

  @Input()
  public title?: string;

  @Input()
  public selected?: boolean;

  @Output()
  public change: EventEmitter<void>;

  constructor() {
    this.change = new EventEmitter();
  }

  public _onChange(): void {
    this.change.emit();
  }
}

@Directive({
  selector: "row",
})
export class RowDirective implements AfterContentInit, OnChanges {
  @ContentChild(CategoryDirective)
  public category: CategoryDirective;

  @ContentChildren(CellDirective)
  public cells: QueryList<CellDirective>;

  @Input()
  public id: string;

  @Input()
  public className?: string;

  @Output()
  public action: EventEmitter<any>;

  public cellsByColumnId: { [columnId: string]: CellDirective };

  public classNames;

  constructor() {
    this.action = new EventEmitter();
  }

  public ngAfterContentInit(): void {
    this.cellsByColumnId = this.cells.reduce((acc, cur) => {
      acc[cur.columnId] = cur;
      return acc;
    }, {});
  }

  public ngOnChanges(): void {
    const classes = [];
    if (this.className != null) {
      classes.push(this.className);
    }

    if (this.action.observers.length) {
      classes.push("clickable");
    }

    this.classNames = classes.join(" ");
  }

  public onClick(): void {
    this.action.emit();
  }
}

@Directive({
  selector: "body",
})
export class BodyDirective {
  @ContentChildren(RowDirective)
  public values: QueryList<RowDirective>;
}
