<div class="page-content">
  <div class="page-header">
    <h1>
      <span class="content">{{ manufacturer?.name }} Product Usage Report</span>
    </h1>
    <div class="header-controls">
      <!--
        so, this is all _one_ component, right?
        basically the <date-range> _needs_ the <date-picker> component or else it can't really do its job?
        if that's the case we should just wrap them both up into one component
        so you don't have to remember to always render a <date-picker> via [matTooltip] ...
        and we could do things like automatically close the overlay when the user chooses a date
        right now we'd have to manually do the work from the calling component, which is awkward
      -->
      <mat-menu #datepickerMenu="matMenu">
        <date-picker
          [startDate]="startDate"
          [endDate]="endDate"
          [timeZone]="timeZone"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (update)="updateDateRange($event)"></date-picker>
      </mat-menu>
      <date-range
        [startDate]="startDate"
        [endDate]="endDate"
        [timeZone]="timeZone"
        [matMenuTriggerFor]="datepickerMenu"
        matTooltip="Change date range"></date-range>
      <!--  -->
    </div>
  </div>
  <div id="main-content">
    <div
      *ngIf="!loading; else loadingTemplate"
      class="row gutters">
      <section class="col col-12">
        <div class="card">
          <header>
            <h3>Products</h3>
          </header>
          <div class="products-list-container">
            <product-report-table
              [statistics]="productReport.productStatistics"
              [salonProducts]="products"
              [currency]="currency"
              [measurementUnit]="measurementUnit"
              [rootCategoryMap]="rootCategoryMap">
            </product-report-table>
          </div>
          <ng-template #emptyState>
            <empty-state-message
              title="No data here"
              icon="calendar-day">
              We couldn't find any data for the selected range, adjust the dates to view data
            </empty-state-message>
          </ng-template>
        </div>
      </section>
    </div>
    <ng-template #loadingTemplate>
      <large-loading-indicator></large-loading-indicator>
    </ng-template>
  </div>
</div>
