import { NgModule } from "@angular/core";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faArrowLeft, faCaretDown, faCaretRight, faCloudUploadAlt, faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ProductsSharedModule } from "../+products/products-shared.module";
import { SharedModule } from "../../kernel/shared";

import { KernelServicesModule } from "../../kernel";
import { SlideoutPanelModule } from "../../kernel/shared/rocket-ui/slideout-panel";
import { EditProductCategoryComponent } from "./components";
import { EditProductCategoryContainer, ManufacturerListContainer, ProductManagementContainer } from "./containers";

import { CardModule, PageModule } from "app/+components";
import { ManufacturersSharedModule } from "../+manufacturers";
import { ProductCategoriesRoutingModule } from "./product-categories-routing.module";
import { ProductCategoriesSharedModule } from "./product-categories-shared.module";
import { ProductCategoryEffects } from "./store/product-category.effects";
import { productCategoryReducer } from "./store/product-category.reducer";

@NgModule({
  imports: [
    SharedModule,
    KernelServicesModule,
    ProductCategoriesRoutingModule,
    ProductCategoriesSharedModule,
    ManufacturersSharedModule,
    SlideoutPanelModule,
    ProductsSharedModule,
    PageModule,
    CardModule,
    StoreModule.forFeature("productCategories", productCategoryReducer),
    EffectsModule.forFeature([ProductCategoryEffects]),
  ],
  declarations: [EditProductCategoryComponent, EditProductCategoryContainer, ManufacturerListContainer, ProductManagementContainer],
})
export class ProductCategoriesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faArrowLeft, faEllipsisV, faCloudUploadAlt, faCaretDown, faCaretRight);
  }
}
