import { ServiceCategory, ServiceCategoryModule } from "@getvish/model";
import { toUndefined } from "fp-ts/lib/Option";
import { compose, path, sortBy, toLower } from "ramda";
import { Observable, map } from "rxjs";

export type ServiceCategoryGroup = {
  rootCategory: ServiceCategory;
  children: ServiceCategory[];
};

export const getServiceCategoryGroups = (
  filteredCategories$: Observable<ServiceCategory[]>,
  allCategories: ServiceCategory[]
): Observable<ServiceCategoryGroup[]> => {
  return filteredCategories$.pipe(
    map((filteredCategories) => {
      return filteredCategories.reduce((acc: ServiceCategoryGroup[], category) => {
        if (ServiceCategoryModule.isRootCategory(category)) {
          if (!acc.some((c) => c.rootCategory._id === category._id)) {
            acc.push({ rootCategory: category, children: [] });
          }
        } else {
          const rootCategory = toUndefined(ServiceCategoryModule.getRootCategory(category, allCategories));

          if (!acc.some((c) => c.rootCategory._id === rootCategory._id)) {
            acc.push({ rootCategory: rootCategory, children: [] });
          }

          acc.find((c) => c.rootCategory._id === rootCategory._id).children.push(category);
        }

        return acc;
      }, [] as ServiceCategoryGroup[]);
    }),
    map((categoryGroups) =>
      categoryGroups.map((g) => ({
        ...g,
        children: sortBy(compose(toLower, path(["name"])))(g.children),
      }))
    ),
    map(sortBy(compose(toLower, path(["rootCategory", "name"]))))
  );
};
