import { createSelector, createFeatureSelector } from "@ngrx/store";
import { OrderState } from "./order.reducer";
import { isNil } from "ramda";
import { getUrl } from "app/kernel/store/selectors/router.selectors";

/*** SELECTORS ***/
export const getState = createFeatureSelector<OrderState>("orders");
export const getSelectedAppointment = createSelector(getState, (state) => state.selectedAppointment);
export const getSelectedOrderId = createSelector(getState, (state) => state.selectedOrderId);
export const getSelectedOrderLine = createSelector(getState, (state) => state.selectedOrderLine);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getIsCheckingOut = createSelector(getState, (state) => state.checkingOut);
export const getIsReactivating = createSelector(getState, (state) => state.reactivating);
export const getIsResolving = createSelector(getState, (state) => state.resolving);
export const getError = createSelector(getState, (state) => state.error);

export const getModule = createSelector(getUrl, (url) => {
  if (isNil(url)) {
    return undefined;
  } else if (url.includes("front-desk")) {
    return "front-desk";
  } else if (url.includes("sales")) {
    return "sales";
  } else if (url.includes("performed-services")) {
    return "performed-services";
  }
});

export const getSelectedOrder = createSelector(getState, (state) => state.selectedOrder);
