<slideout-panel (close)="close()">
  <div panel-header>Set Category Pricing</div>

  <set-category-pricing-component
    [categoryId]="categoryId$ | async"
    [measurementUnit]="measurementUnit$ | async"
    [currency]="currency$ | async"
    (save)="save($event)"
    (close)="close()"
    panel-body>
  </set-category-pricing-component>
</slideout-panel>
