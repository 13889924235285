<div class="search-wrapper">
  <input
    class="search-input"
    [placeholder]="placeholder"
    [value]="value"
    (input)="setFilter($event)"
    (keyup.esc)="clearSearch(search)"
    matInput
    [type]="type"
    #search />
  <div class="icon-container">
    <button
      *ngIf="value | notEmpty"
      (click)="clearSearch(search)"
      class="button transparent">
      <fa-icon icon="times-circle"></fa-icon>
    </button>
    <fa-icon
      *ngIf="value | isEmpty"
      icon="search"></fa-icon>
  </div>
</div>
