import { Injectable } from "@angular/core";
import { HttpError, HttpRequestHandler } from "@getvish/stockpile";
import { fold } from "fp-ts/lib/Either";
import { Observable, of, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";

export interface BillingPortalSession {
  id: string;
  object: string;
  configuration: string;
  created: number;
  customer: string;
  livemode: boolean;
  locale?: string;
  return_url: string;
  url: string;
}

@Injectable()
export class BillingPortalSessionService {
  constructor(private _httpRequestHandler: HttpRequestHandler) {}

  public create(): Observable<BillingPortalSession> {
    return this._httpRequestHandler.post<BillingPortalSession>("billing_portal/session", {}).pipe(
      mergeMap(
        fold<HttpError, BillingPortalSession, Observable<BillingPortalSession>>(
          (reason) => throwError(reason.payload),
          (payload) => of(payload)
        )
      )
    );
  }
}
