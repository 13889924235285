import { ContentChild, Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  selector: "list-orderer-item-prefix",
})
export class ListOrdererItemPrefixDirective {
  @ContentChild(TemplateRef)
  public prefix: TemplateRef<any>;
}

@Directive({
  selector: "list-orderer-item",
})
export class ListOrdererItemDirective {
  @Input() public id: string;
  @Input() public text: string;
  @Input() public classes: string[];

  @ContentChild(ListOrdererItemPrefixDirective)
  public prefixDirective: ListOrdererItemPrefixDirective;
}
