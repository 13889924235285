<dashboard
  [salonReport]="salonReport$ | async"
  [employeeReports]="employeeReports$ | async"
  [serviceReports]="serviceReports$ | async"
  [longitudinalReport]="longitudinalReport$ | async"
  [aggregatedBy]="aggregatedBy$ | async"
  [measurementUnit]="measurementUnit$ | async"
  [loading]="loading$ | async"
  [error]="error$ | async"
  [startDate]="startDate$ | async"
  [endDate]="endDate$ | async"
  [currency]="currency$ | async"
  [locale]="locale$ | async"
  [timeZone]="timeZone$ | async"
  [enableComplianceTracking]="enableComplianceTracking$ | async"
  (aggregation)="updateAggregation($event)"
  (downloadEmployeePerformanceReport)="downloadEmployeePerformanceReport()"
  (downloadServiceReport)="downloadServiceReport($event)"
  (updateDateRange)="updateDateRange($event)"
  (navigateToUnmixed)="navigateToUnmixed($event)"
  (navigateToMixed)="navigateToMixed($event)">
</dashboard>
