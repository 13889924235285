import { Injectable } from "@angular/core";
import { map, mergeMap, Observable, withLatestFrom } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { getSalonConfig } from "app/+salon-config/store/salon-config.selectors";
import { pipe } from "fp-ts/lib/function";
import { filter, map as ramdaMap, sortWith } from "ramda";
import { ServiceReport } from "app/kernel/models";
import { generateMetricsXlsxReport } from "./xlsx";
import { ServiceReportDownloaderService } from "./service-report-downloader.service";
import { ServiceReportService } from "./service-report.service";

@Injectable()
export class ServiceReportDownloaderLocalService extends ServiceReportDownloaderService {
  constructor(
    private _serviceReportService: ServiceReportService,
    private _store: Store<AppState>
  ) {
    super();
  }

  public downloadServiceReport(startDate: number, endDate: number, showExcludedServices?: boolean): Observable<Blob> {
    return this._serviceReportService.fetchForDateRange(startDate, endDate).pipe(
      withLatestFrom(this._store.select(getSalonConfig)),
      map(([serviceReports, salonConfig]) => {
        const enableComplianceTracking = salonConfig.enableComplianceTracking;

        const sortKeys = enableComplianceTracking ? ["numMixableServices", "numMixedServices"] : ["numServicesPerformed"];

        return {
          reports: pipe(
            serviceReports,
            filter<ServiceReport>((report) => {
              if (enableComplianceTracking) {
                if (showExcludedServices) {
                  return report.summary.numMixableServices > 0 || report.flags?.includes("EXCLUDE_FROM_ANALYTICS");
                } else {
                  return report.summary.numMixableServices > 0;
                }
              } else {
                return report.summary.numServicesPerformed > 0;
              }
            }),
            ramdaMap((report) => {
              if (enableComplianceTracking && showExcludedServices) {
                return {
                  ...report,
                  summary: {
                    ...report.summary,
                    numMixableServices: report.summary.numServices,
                    numMixedServices: report.summary.numServicesPerformed,
                  },
                };
              }

              return report;
            }),
            sortWith(sortKeys.map((key) => (a, b) => b.summary[key] - a.summary[key]))
          ),
          salonConfig,
        };
      }),
      mergeMap(({ reports, salonConfig }) =>
        generateMetricsXlsxReport(
          "Service Report",
          reports.map((row) => ({ name: row.serviceName, metrics: row.summary })),
          salonConfig
        )
      )
    );
  }
}
