<div class="blueprint-summary">
  <h4 class="title">Product Allowance</h4>
  <div class="bowl">
    <bowl [mixingContainerVM]="getMixingContainerVM()"></bowl>
  </div>
  <div class="subtotal">
    <span class="subtotal-title">Product</span>
    <span class="subtotal-amount">{{ getProductSubtotal() | currency: currency : "symbol-narrow" }}</span>
  </div>
  <div class="subtotal">
    <span class="subtotal-title">Developer</span>
    <span class="subtotal-amount">{{ getDeveloperSubtotal() | currency: currency : "symbol-narrow" }}</span>
  </div>
  <div class="total">
    <fieldset class="total-fs">
      <legend>Bowl Total</legend>
      <span class="total-amount">{{ getTotal() | currency: currency : "symbol-narrow" }}</span>
    </fieldset>
  </div>
</div>
