import { Pipe, PipeTransform } from "@angular/core";
import { not } from "ramda";
import { IsEmptyPipe } from "./is-empty.pipe";

@Pipe({ name: "notEmpty", pure: true })
export class NotEmptyPipe implements PipeTransform {
  constructor(private _isEmptyPipe: IsEmptyPipe) {}

  public transform(value: any): boolean {
    return not(this._isEmptyPipe.transform(value));
  }
}
