import { NgModule } from "@angular/core";
import { SharedModule } from "app/kernel/shared";
import { LocationAutocompleteComponent } from "./components/location-autocomplete.component";
import { LocationAutocompleteService } from "./services/location-autocomplete.service";

@NgModule({
  declarations: [LocationAutocompleteComponent],
  imports: [SharedModule],
  exports: [LocationAutocompleteComponent],
  providers: [LocationAutocompleteService],
})
export class LocationAutocompleteModule {}
