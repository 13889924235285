import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpRequestHandler } from "@getvish/stockpile";
import { LocalStorage } from "../../kernel/services/local-storage.service";
import { LoginResponse, AuthToken } from "../../kernel/models";
import { map } from "rxjs/operators";
import { User } from "@getvish/model";
import { not, isNil } from "ramda";
import { Either, fold, left, right } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { fromNullable, Option } from "fp-ts/Option";

import * as authMessages from "../store/auth-messages";

// @TODO wow this is heinous; move this info vish-model
export const isAdmin = (user: User): boolean => {
  const result =
    not(isNil(user)) &&
    not(isNil(user["_roles"])) &&
    not(
      isNil(
        user["_roles"].find((role) => {
          return role === "ADMIN" || role === "ROOT";
        })
      )
    );

  return result;
};

export const isRoot = (user: User): boolean =>
  pipe(
    user,
    option.fromNullable,
    option.map((user) => user._roles),
    option.fold(
      () => false,
      (roles) => roles.some((role) => role === "ROOT")
    )
  );

export const isManager = (user: User): boolean =>
  pipe(
    user,
    option.fromNullable,
    option.map((user) => user._roles),
    option.fold(
      () => false,
      (roles) => roles.some((role) => role === "MANAGER")
    )
  );

@Injectable({
  providedIn: "root",
})
export class AuthStorageService {
  constructor(private _localStorage: LocalStorage) {}

  public setLoginData(user: User, authToken: AuthToken): void {
    this.setUser(user);
    this.setAuthToken(authToken);
  }

  public setAuthToken(authToken: AuthToken): void {
    this._localStorage.setItem("AUTH_TOKEN", authToken._id);
  }

  public getAuthToken(): string {
    return this._localStorage.getItem("AUTH_TOKEN");
  }

  public logout(): void {
    this.removeAuthToken();
    this.removeUser();
  }

  public removeAuthToken(): void {
    this._localStorage.removeItem("AUTH_TOKEN");
  }

  public removeUser(): void {
    this._localStorage.removeItem("USER");
  }

  public setUser(user: User): void {
    this._localStorage.setItem("USER", JSON.stringify(user));
  }

  public getUser(): User {
    return JSON.parse(this._localStorage.getItem("USER"));
  }
}

@Injectable()
export class AuthService {
  constructor(
    private _httpRequestHandler: HttpRequestHandler,
    private _storage: AuthStorageService
  ) {}

  public login(username, password, salonSlug): Observable<Either<string, LoginResponse>> {
    const request: authMessages.Login = {
      username,
      password,
      salonSlug,
    };

    return this._httpRequestHandler.post<LoginResponse>("login", request).pipe(
      map(
        fold(
          (fail) => (fail.code === 401 ? left("Invalid username/password") : left("Something went wrong with the request")),
          (success) => right(success)
        )
      )
    );
  }

  public handleLoginSuccess(user: User, authToken: AuthToken): Observable<void> {
    return of(this._storage.setLoginData(user, authToken));
  }

  public loginFromAuthToken(authToken: string, salonSlug: string): Observable<Either<string, LoginResponse>> {
    const request: authMessages.LoginFromAuthToken = {
      authToken,
      salonSlug,
    };

    return this._httpRequestHandler.post<LoginResponse>("login/authToken", request).pipe(
      map(
        fold(
          (fail) => (fail.code === 401 ? left("Auth token was invalid") : left("Something went wrong with the request")),
          (success) => {
            return right(success);
          }
        )
      )
    );
  }

  public setAuthToken(authToken: AuthToken): void {
    this._storage.setAuthToken(authToken);
  }

  public getAuthToken(): Observable<Option<string>> {
    return of(fromNullable(this._storage.getAuthToken()));
  }

  public clearLoginData(): Observable<void> {
    this._storage.removeAuthToken();
    this._storage.removeUser();

    return of(undefined);
  }

  public logout(): void {
    this._storage.logout();
  }

  public removeAuthToken(): void {
    this._storage.removeAuthToken();
  }

  public removeUser(): void {
    this._storage.removeUser();
  }

  public setUser(user: User): void {
    this._storage.setUser(user);
  }

  public getUser(): User {
    return this._storage.getUser();
  }
}
