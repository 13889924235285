import { Manufacturer, ProductCategory, SalonManufacturer, SalonProduct, SalonProductCategory } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { Action } from "@ngrx/store";
import { isNil } from "ramda";
import { CategoryPricing, SalonProductFilterKey } from "./../common/index";

export enum Types {
  LOAD_ALL = "[SalonProduct] Load All",
  LOAD_ALL_SUCCESS = "[SalonProduct] Load All Success",
  LOAD_ALL_FAIL = "[SalonProduct] Load All Fail",
  LOAD_ALL_MANUFACTURER = "[SalonProduct] Load All Manufacturer",
  LOAD_ALL_MANUFACTURER_SUCCESS = "[SalonProduct] Load All Manufacturer Success",
  LOAD_ALL_MANUFACTURER_FAIL = "[SalonProduct] Load All Manufacturer Fail",
  NEW = "[SalonProduct] New",
  ADD = "[SalonProduct] Add",
  ADD_SUCCESS = "[SalonProduct] Add Success",
  ADD_FAIL = "[SalonProduct] Add Fail",
  EDIT = "[SalonProduct] Edit",
  UPDATE = "[SalonProduct] Update",
  UPDATE_SUCCESS = "[SalonProduct] Update Success",
  UPDATE_FAIL = "[SalonProduct] Update Fail",
  REMOVE = "[SalonProduct] Remove",
  REMOVE_SUCCESS = "[SalonProduct] Remove Success",
  REMOVE_FAIL = "[SalonProduct] Remove Fail",
  NAVIGATE_CATEGORY_PRICING = "[SalonProduct] Navigate Category Pricing",
  PLACE_SALON_INTERACTIVE_ORDER = "[SalonProduct] Place SalonInteractive Order",
  PLACE_SALON_INTERACTIVE_ORDER_SUCCESS = "[SalonProduct] Place SalonInteractive Order Success",
  SET_CATEGORY_INACTIVE = "[SalonProduct] Set Category Inactive",
  SET_CATEGORY_INACTIVE_FAIL = "[SalonProduct] Set Category Inactive Fail",
  SET_CATEGORY_INACTIVE_SUCCESS = "[SalonProduct] Set Category Inactive Success",
  SET_CATEGORY_ACTIVE = "[SalonProduct] Set Category Active",
  SET_CATEGORY_ACTIVE_FAIL = "[SalonProduct] Set Category Active Fail",
  SET_CATEGORY_ACTIVE_SUCCESS = "[SalonProduct] Set Category Active Success",
  SET_CATEGORY_PRICING = "[SalonProduct] Set Category Pricing",
  SET_CATEGORY_PRICING_FAIL = "[SalonProduct] Set Category Pricing Fail",
  SET_CATEGORY_PRICING_SUCCESS = "[SalonProduct] Set Category Pricing Success",
  SET_INCOMPLETE_PRICING_FILTER = "[SalonProduct] Set incomplete pricing filter",
  SET_PRODUCT_FILTERS = "[Prodcut] Set Product Filters",
  SET_SEARCH_FILTER = "[SalonProduct] Set Search Filter",
  SET_FLAG_FILTERS = "[SalonProduct] Set Flag Filters",
  SELECT_MANUFACTURER = "[SalonProduct] Select Manufacturer",
  IMPORT_MANUFACTURER = "[SalonProduct] Import Manufacturer",
  IMPORT_MANFACTURER_SUCCESS = "[SalonProduct] Import Manufacturer Success",
  IMPORT_MANFACTURER_FAIL = "[SalonProduct] Import Manufacturer Fail",
  IMPORT_PRODUCT_CATEGORY = "[SalonProduct] Import Product Category",
  IMPORT_PRODUCT_CATEGORY_SUCCESS = "[SalonProduct] Import Product Category Success",
  IMPORT_PRODUCT_CATEGORY_FAIL = "[SalonProduct] Import Product Category Fail",
  NAVIGATE_EDIT_PRODUCT = "[SalonProduct] Navigate Edit Product",
  LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER = "[SalonProduct] Load Products and Categories For Manufacturer",
  DOWNLOAD_SPREADSHEET = "[SalonProduct] Download Spreadsheet",
  UPLOAD_SPREADSHEET = "[SalonProduct] Upload Spreadsheet",
  UPLOAD_SPREADSHEET_COMPLETE = "[SalonProduct] Upload Spreadsheet Complete",
  UPLOAD_SPREADSHEET_FAIL = "[SalonProduct] Upload Spreadsheet Fail",
  OPEN_UPLOAD_COMPONENT = "[SalonProduct] Open Upload Component",
  CLOSE_UPLOAD_COMPONENT = "[SalonProduct] Close Upload Component",
  TOGGLE_PRODUCT = "[SalonProduct] Select Product",
  PUSH_PRODUCT_CATEGORY = "[SalonProduct] Push Product Category",
  POP_PRODUCT_CATEGORY = "[SalonProduct] Pop Product Category",
  POP_TO_PRODUCT_CATEGORY = "[SalonProduct] Pop To Product Category",
  CLEAR_PRODUCT_CATEGORIES = "[SalonProduct] Clear Product Categories",
  SHOW_MANUFACTURER_ORDER_DIALOG = "[SalonProduct] Show Manufacturer Order Dialog",
  UPDATE_SALON_MANUFACTURERS_ORDER = "[SalonProduct] Update Salon Manufacturers Order",
  UPDATE_SALON_MANUFACTURERS_ORDER_SUCCESS = "[SalonProduct] Update Salon Manufacturers Order Success",
  UPDATE_SALON_MANUFACTURERS_ORDER_FAIL = "[SalonProduct] Update Salon Manufacturers Order Fail",
  SHOW_PRODUCT_CATEGORY_ORDER_DIALOG = "[SalonProduct] Show Product Category Order Dialog",
  UPDATE_SALON_PRODUCT_CATEGORIES_ORDER = "[SalonProduct] Update Salon Product Categories Order",
  UPDATE_SALON_PRODUCT_CATEGORIES_ORDER_SUCCESS = "[SalonProduct] Update Salon Product Categories Order Success",
  UPDATE_SALON_PRODUCT_CATEGORIES_ORDER_FAIL = "[SalonProduct] Update Salon Product Categories Order Fail",
  SHOW_PRODUCT_ORDER_DIALOG = "[SalonProduct] Show Product Order Dialog",
  UPDATE_SALON_PRODUCTS = "[SalonProduct] Update Salon Products",
  UPDATE_SALON_PRODUCTS_SUCCESS = "[SalonProduct] Update Salon Products Success",
  UPDATE_SALON_PRODUCTS_FAIL = "[SalonProduct] Update Salon Products Fail",
  NAVIGATE_MARKUP = "[SalonProduct] Navigate Markup",
  SET_MARKUP = "[SalonProduct] Set Markup",
  SET_MARKUP_FAIL = "[SalonProduct] Set Markup Fail",
  SET_MARKUP_SUCCESS = "[SalonProduct] Set Markup Success",
}

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;

  constructor(
    public payload: {
      criteria?: JsonObject;
      sort?: JsonObject;
      page?: number;
      limit?: number;
    }
  ) {}
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public salonProducts: SalonProduct[],
    public salonManufacturers?: SalonManufacturer[],
    public salonProductCategories?: SalonProductCategory[],
    public manufacturers?: Manufacturer[],
    public paging?: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class LoadAllManufacturer implements Action {
  public readonly type = Types.LOAD_ALL_MANUFACTURER;

  constructor(public payload: string) {}
}

export class LoadAllManufacturerSuccess implements Action {
  public readonly type = Types.LOAD_ALL_MANUFACTURER_SUCCESS;

  constructor(
    public payload: SalonProduct[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllManufacturerFail implements Action {
  public readonly type = Types.LOAD_ALL_MANUFACTURER_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class New implements Action {
  public readonly type = Types.NEW;

  constructor(public payload?: { category: ProductCategory }) {}
}

export class Add implements Action {
  public readonly type = Types.ADD;

  constructor(public payload: { product: SalonProduct }) {}
}

export class AddSuccess implements Action {
  public readonly type = Types.ADD_SUCCESS;

  constructor(public payload: SalonProduct) {}
}

export class AddFail implements Action {
  public readonly type = Types.ADD_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class Update implements Action {
  public readonly type = Types.UPDATE;

  constructor(public payload: { product: SalonProduct }) {}
}

export class UpdateSuccess implements Action {
  public readonly type = Types.UPDATE_SUCCESS;

  constructor(public payload: SalonProduct) {}
}

export class UpdateFail implements Action {
  public readonly type = Types.UPDATE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class NavigateEditProduct implements Action {
  public readonly type = Types.NAVIGATE_EDIT_PRODUCT;

  constructor(public payload?: { productId: string }) {}
}

export const addOrUpdate = (payload: { product: SalonProduct }) => (isNil(payload.product._id) ? new Add(payload) : new Update(payload));

export class Remove implements Action {
  public readonly type = Types.REMOVE;

  constructor(public payload: { id: string }) {}
}

export class RemoveSuccess implements Action {
  public readonly type = Types.REMOVE_SUCCESS;

  constructor(public payload: string) {}
}

export class RemoveFail implements Action {
  public readonly type = Types.REMOVE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class SelectManufacturer implements Action {
  public readonly type = Types.SELECT_MANUFACTURER;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class ImportProductCategory implements Action {
  public readonly type = Types.IMPORT_PRODUCT_CATEGORY;

  constructor(public payload: { category: ProductCategory }) {}
}

export class ImportProductCategorySuccess implements Action {
  public readonly type = Types.IMPORT_PRODUCT_CATEGORY_SUCCESS;

  constructor(public payload: { numImported: number; productCategory: ProductCategory }) {}
}

export class ImportProductCategoryFail implements Action {
  public readonly type = Types.IMPORT_PRODUCT_CATEGORY_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class ImportManufacturer implements Action {
  public readonly type = Types.IMPORT_MANUFACTURER;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class ImportManufacturerSuccess implements Action {
  public readonly type = Types.IMPORT_MANFACTURER_SUCCESS;

  constructor(public payload: { numImported: number; manufacturer: Manufacturer }) {}
}

export class ImportManufacturerFail implements Action {
  public readonly type = Types.IMPORT_MANFACTURER_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class Edit implements Action {
  public readonly type = Types.EDIT;

  constructor(public payload: { product: SalonProduct }) {}
}

export class LoadProductsAndCategoriesForManufacturer implements Action {
  public readonly type = Types.LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER;

  constructor(public payload: { manufacturerId }) {}
}

export class SetSearchFilter implements Action {
  public readonly type = Types.SET_SEARCH_FILTER;

  constructor(public payload: { searchFilter: string }) {}
}

export class SetFlagFilters implements Action {
  public readonly type = Types.SET_FLAG_FILTERS;

  constructor(public payload: { filters: SalonProductFilterKey[] }) {}
}

export class NavigateCategoryPricing implements Action {
  public readonly type = Types.NAVIGATE_CATEGORY_PRICING;

  constructor(public payload: { category: ProductCategory }) {}
}

export class PlaceSalonInteractiveOrder implements Action {
  public readonly type = Types.PLACE_SALON_INTERACTIVE_ORDER;

  constructor(public payload: { category: ProductCategory }) {}
}

export class PlaceSalonInteractiveOrderSuccess implements Action {
  public readonly type = Types.PLACE_SALON_INTERACTIVE_ORDER_SUCCESS;

  constructor(public payload: JsonObject) {}
}

export class SetCategoryPricing implements Action {
  public readonly type = Types.SET_CATEGORY_PRICING;

  constructor(public payload: { pricing: CategoryPricing }) {}
}

export class SetCategoryPricingFail implements Action {
  public readonly type = Types.SET_CATEGORY_PRICING_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class SetCategoryPricingSuccess implements Action {
  public readonly type = Types.SET_CATEGORY_PRICING_SUCCESS;

  constructor(public payload: { numUpdated: number }) {}
}

export class SetIncompletePricingFilter implements Action {
  public readonly type = Types.SET_INCOMPLETE_PRICING_FILTER;

  constructor(public payload: { filter: boolean }) {}
}

export class SetCategoryInactive implements Action {
  public readonly type = Types.SET_CATEGORY_INACTIVE;

  constructor(public payload: { category: ProductCategory }) {}
}

export class SetCategoryInactiveFail implements Action {
  public readonly type = Types.SET_CATEGORY_INACTIVE_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class SetCategoryInactiveSuccess implements Action {
  public readonly type = Types.SET_CATEGORY_INACTIVE_SUCCESS;

  constructor(public payload: { numUpdated: number }) {}
}

export class SetCategoryActive implements Action {
  public readonly type = Types.SET_CATEGORY_ACTIVE;

  constructor(public payload: { category: ProductCategory }) {}
}

export class SetCategoryActiveFail implements Action {
  public readonly type = Types.SET_CATEGORY_ACTIVE_FAIL;

  constructor(public payload: { errors: Error[] }) {}
}

export class SetCategoryActiveSuccess implements Action {
  public readonly type = Types.SET_CATEGORY_ACTIVE_SUCCESS;

  constructor(public payload: { numUpdated: number }) {}
}

export class DownloadSpreadsheet implements Action {
  public readonly type = Types.DOWNLOAD_SPREADSHEET;

  constructor(public payload: { manufacturerId: string }) {}
}

export class UploadSpreadsheet implements Action {
  public readonly type = Types.UPLOAD_SPREADSHEET;

  constructor(public payload: { blobUrl: string; type: string }) {}
}

export class UploadSpreadsheetComplete implements Action {
  public readonly type = Types.UPLOAD_SPREADSHEET_COMPLETE;
}

export class UploadSpreadsheetFail implements Action {
  public readonly type = Types.UPLOAD_SPREADSHEET_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class OpenUploadComponent implements Action {
  public readonly type = Types.OPEN_UPLOAD_COMPONENT;
}
export class CloseUploadComponent implements Action {
  public readonly type = Types.CLOSE_UPLOAD_COMPONENT;
}

export class ToggleProduct implements Action {
  public readonly type = Types.TOGGLE_PRODUCT;

  constructor(public payload: { product: SalonProduct }) {}
}

export class PushProductCategory implements Action {
  public readonly type = Types.PUSH_PRODUCT_CATEGORY;

  constructor(public payload: { category: ProductCategory }) {}
}

export class PopProductCategory implements Action {
  public readonly type = Types.POP_PRODUCT_CATEGORY;
}

export class PopToProductCategory implements Action {
  public readonly type = Types.POP_TO_PRODUCT_CATEGORY;

  constructor(public payload: { categoryId: string }) {}
}

export class ClearProductCategories implements Action {
  public readonly type = Types.CLEAR_PRODUCT_CATEGORIES;
}

export class ShowManufacturerOrderDialog implements Action {
  public readonly type = Types.SHOW_MANUFACTURER_ORDER_DIALOG;
}

export class UpdateSalonManufacturersOrder implements Action {
  public readonly type = Types.UPDATE_SALON_MANUFACTURERS_ORDER;

  constructor(public payload: { salonManufacturers: SalonManufacturer[] }) {}
}

export class UpdateSalonManufacturersOrderSuccess implements Action {
  public readonly type = Types.UPDATE_SALON_MANUFACTURERS_ORDER_SUCCESS;

  constructor(public payload: { salonManufacturers: SalonManufacturer[] }) {}
}

export class UpdateSalonManufacturersOrderFail implements Action {
  public readonly type = Types.UPDATE_SALON_MANUFACTURERS_ORDER_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class ShowProductCategoryOrderDialog implements Action {
  public readonly type = Types.SHOW_PRODUCT_CATEGORY_ORDER_DIALOG;

  constructor(public payload: { productCategories: ProductCategory[] }) {}
}

export class UpdateSalonProductCategoriesOrder implements Action {
  public readonly type = Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER;

  constructor(public payload: { salonProductCategories: SalonProductCategory[] }) {}
}

export class UpdateSalonProductCategoriesOrderSuccess implements Action {
  public readonly type = Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER_SUCCESS;

  constructor(public payload: { salonProductCategories: SalonProductCategory[] }) {}
}

export class UpdateSalonProductCategoriesOrderFail implements Action {
  public readonly type = Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class ShowProductOrderDialog implements Action {
  public readonly type = Types.SHOW_PRODUCT_ORDER_DIALOG;

  constructor(public payload: { salonProducts: SalonProduct[] }) {}
}

export class UpdateSalonProducts implements Action {
  public readonly type = Types.UPDATE_SALON_PRODUCTS;

  constructor(public payload: { salonProducts: SalonProduct[] }) {}
}

export class UpdateSalonProductsSuccess implements Action {
  public readonly type = Types.UPDATE_SALON_PRODUCTS_SUCCESS;

  constructor(public payload: { salonProducts: SalonProduct[] }) {}
}
export class UpdateSalonProductsFail implements Action {
  public readonly type = Types.UPDATE_SALON_PRODUCTS_FAIL;

  constructor(public payload: { error: Error }) {}
}
export class NavigateMarkup implements Action {
  public readonly type = Types.NAVIGATE_MARKUP;

  constructor(public payload: { category: ProductCategory }) {}
}

export class SetMarkup implements Action {
  public readonly type = Types.SET_MARKUP;

  constructor(public payload: { markup: number; categoryId: string }) {}
}

export class SetMarkupFail implements Action {
  public readonly type = Types.SET_MARKUP_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class SetMarkupSuccess implements Action {
  public readonly type = Types.SET_MARKUP_SUCCESS;

  constructor(public payload: { numUpdated: number }) {}
}

export type Actions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAllManufacturer
  | LoadAllManufacturerSuccess
  | LoadAllManufacturerFail
  | New
  | Add
  | AddSuccess
  | Update
  | UpdateSuccess
  | Remove
  | RemoveSuccess
  | RemoveFail
  | NavigateEditProduct
  | SelectManufacturer
  | SetCategoryPricing
  | SetCategoryPricingSuccess
  | SetCategoryPricingFail
  | SetCategoryInactive
  | SetCategoryInactiveSuccess
  | SetCategoryInactiveFail
  | SetCategoryActive
  | SetCategoryActiveSuccess
  | SetCategoryActiveFail
  | SetSearchFilter
  | NavigateCategoryPricing
  | SetFlagFilters
  | ImportManufacturer
  | ImportManufacturerSuccess
  | ImportManufacturerFail
  | ImportProductCategory
  | ImportProductCategorySuccess
  | ImportProductCategoryFail
  | Edit
  | LoadProductsAndCategoriesForManufacturer
  | DownloadSpreadsheet
  | UploadSpreadsheet
  | UploadSpreadsheetComplete
  | UploadSpreadsheetFail
  | OpenUploadComponent
  | CloseUploadComponent
  | ToggleProduct
  | PushProductCategory
  | PopProductCategory
  | PopToProductCategory
  | ClearProductCategories
  | SetIncompletePricingFilter
  | ShowManufacturerOrderDialog
  | UpdateSalonManufacturersOrder
  | UpdateSalonManufacturersOrderSuccess
  | UpdateSalonManufacturersOrderFail
  | ShowProductCategoryOrderDialog
  | UpdateSalonProductCategoriesOrder
  | UpdateSalonProductCategoriesOrderSuccess
  | UpdateSalonProductCategoriesOrderFail
  | ShowProductOrderDialog
  | UpdateSalonProducts
  | UpdateSalonProductsSuccess
  | UpdateSalonProductsFail
  | NavigateMarkup
  | SetMarkup
  | SetMarkupSuccess
  | SetMarkupFail;
