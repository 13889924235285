import { ManufacturerReport, ProductCategoryReport, ProductReport } from "app/kernel/models";
import * as productReport from "./product-report.actions";

export interface ProductReportState {
  loading: boolean;
  startDate: number;
  endDate: number;
  error: string;
  manufacturerId: string;
  report: ProductReport;
  categoryReport: ProductCategoryReport;
  manufacturerReport: ManufacturerReport;
}

const initialState: ProductReportState = {
  loading: false,
  startDate: undefined,
  endDate: undefined,
  error: undefined,
  manufacturerId: undefined,
  report: undefined,
  categoryReport: undefined,
  manufacturerReport: undefined,
};

export function productReportReducer(state: ProductReportState = initialState, action: productReport.Actions): ProductReportState {
  switch (action.type) {
    case productReport.Types.INITIALIZE_AND_LOAD_MANUFACTURER_REPORT: {
      return {
        ...state,
        loading: true,
      };
    }

    case productReport.Types.LOAD_MANUFACTURER_REPORT: {
      const { startDate, endDate } = action.payload;

      return {
        ...state,
        startDate,
        endDate,
      };
    }

    case productReport.Types.LOAD_MANUFACTURER_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        manufacturerReport: action.payload,
      };
    }

    case productReport.Types.LOAD_PRODUCT_REPORT_GRAPH: {
      const loading = true;
      const { startDate, endDate, manufacturerId } = action.payload;

      return {
        ...state,
        loading,
        startDate,
        endDate,
        manufacturerId,
      };
    }

    case productReport.Types.LOAD_PRODUCT_REPORT_GRAPH_SUCCESS: {
      const loading = false;

      return {
        ...state,
        loading,
      };
    }

    case productReport.Types.UPDATE_PRODUCT_REPORT_FILTERS: {
      const loading = true;
      const { startDate, endDate } = action.payload;

      return {
        ...state,
        loading,
        startDate,
        endDate,
      };
    }

    case productReport.Types.UPDATE_MANUFACTURER_REPORT_FILTERS: {
      const loading = true;
      const { startDate, endDate } = action.payload;

      return {
        ...state,
        loading,
        startDate,
        endDate,
      };
    }

    case productReport.Types.LOAD_PRODUCT_REPORTS_SUCCESS: {
      return {
        ...state,
        report: action.payload,
        loading: false,
      };
    }

    case productReport.Types.LOAD_PRODUCT_CATEGORY_REPORTS_SUCCESS: {
      return {
        ...state,
        categoryReport: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
