import { SalonProduct, ProductCategory, Manufacturer, Product } from "@getvish/model";
import { createAction, props } from "@ngrx/store";

export type ProductSelectionMode = "DEVELOPER" | "NONDEVELOPER" | "ALL";
export type ProductSelectionType = "PRODUCTS" | "SALON_PRODUCTS";

export const load = createAction("[Product Selection] Load", props<{ psMode: ProductSelectionMode; psType: ProductSelectionType }>());

export const loadSuccess = createAction(
  "[Product Selection] Load Success",
  props<{ salonProducts?: SalonProduct[]; manufacturers: Manufacturer[] }>()
);

export const loadFail = createAction("[Product Selection] Load Fail", props<{ error: Error }>());

export const selectManufacturer = createAction("[Product Selection] Select Manufacturer", props<{ manufacturer: Manufacturer }>());

export const loadProductsForManufacturerSuccess = createAction(
  "[Import Salon Products] Load Products For Manufacturer Success",
  props<{ products: Product[]; categories: ProductCategory[] }>()
);

export const loadProductsForManufacturerFail = createAction(
  "[Import Salon Products] Load Products For Manufacturer Fail",
  props<{ error: any }>()
);

export const toggleProduct = createAction("[Product Selection] Toggle Product", props<{ product: SalonProduct }>());

export const selectProducts = createAction("[Product Selection] Select Products", props<{ products: SalonProduct[] }>());

export const deselectProducts = createAction("[Product Selection] Deselect Products", props<{ products: SalonProduct[] }>());

export const search = createAction("[Product Selection] Search", props<{ filter: string }>());

export const previousStep = createAction("[Product Selection] Previous Step");

export const removeAllProducts = createAction("[Product Selection] Remove All");

export const pushCategory = createAction("[Product Selection] Push Catgory", props<{ category: ProductCategory }>());

export const popToCategory = createAction("[Product Selection] Pop To Category", props<{ categoryId: string }>());

export const clearCategories = createAction("[Product Selection] Clear Categories");
