import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { back } from "../../../kernel/store/actions/router.actions";
import { SetMarkup } from "../store/salon-product.actions";
import * as fromSalonProduct from "../store/salon-product.selectors";

@Component({
  selector: "set-markup-container",
  templateUrl: "set-markup.container.html",
  styleUrls: ["set-markup.container.less"],
})
export class SetMarkupContainer implements OnInit {
  public categoryId$: Observable<string>;
  public saving$: Observable<boolean>;
  public form: UntypedFormGroup;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute,
    private _fb: UntypedFormBuilder
  ) {
    this.categoryId$ = _route.paramMap.pipe(map((params) => params.get("id")));
    this.saving$ = this._store.pipe(select(fromSalonProduct.getSavingMarkup));
  }

  public ngOnInit(): void {
    this._createForm();
  }

  private _createForm(): void {
    this.form = this._fb.group({
      markupPercent: [null, Validators.required],
    });
  }

  public close(): void {
    this._store.dispatch(back());
  }

  public saveForm(data: { markupPercent: number }, categoryId: string): void {
    if (this.form.valid) {
      this._store.dispatch(new SetMarkup({ markup: data.markupPercent / 100, categoryId }));
    }
  }
}
