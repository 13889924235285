import { Injectable } from "@angular/core";
import { SalonManufacturer } from "@getvish/model";
import { HttpRepositoryFactory, EntityService, HttpRequestHandler } from "@getvish/stockpile";
import { map, mergeMap } from "rxjs/operators";
import { Observable, forkJoin, of, throwError } from "rxjs";
import { fold } from "fp-ts/lib/Either";

interface UpdateSalonManufacturerOrderPayload {
  value: number;
}

@Injectable()
export class SalonManufacturerService extends EntityService<SalonManufacturer> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler
  ) {
    super(repositoryFactory, { entityKey: "salonManufacturers" });
  }

  public findAll(): Observable<SalonManufacturer[]> {
    return this.find().pipe(map((result) => result.records));
  }

  public updateOrderOrDie(salonManufacturer: SalonManufacturer, order: number): Observable<SalonManufacturer> {
    const payload: UpdateSalonManufacturerOrderPayload = { value: order };

    return this._requestHandler.post<SalonManufacturer>(`salonManufacturers/${salonManufacturer._id}/order`, payload).pipe(
      mergeMap(
        fold(
          (error) => throwError(() => new Error(error.toString())),
          (result) => of(result)
        )
      )
    );
  }

  public updateOrderMany(salonManufacturers: SalonManufacturer[]): Observable<SalonManufacturer[]> {
    return forkJoin(salonManufacturers.map((salonManufacturer) => this.updateOrderOrDie(salonManufacturer, salonManufacturer.order)));
  }
}
