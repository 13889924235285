import { ModuleWithProviders, NgModule } from "@angular/core";
import { EmployeeService, GlobalEmployeeService } from "./services";
import { KernelServicesModule } from "../kernel/services/kernel-services.module";

@NgModule({
  imports: [KernelServicesModule.forRoot()],
})
export class EmployeeServicesModule {
  public static forRoot(): ModuleWithProviders<EmployeeServicesModule> {
    return {
      ngModule: EmployeeServicesModule,
      providers: [EmployeeService, GlobalEmployeeService],
    };
  }
}
