import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { getSelectedRecords } from "./service-menu.selectors";
import { ServiceDescriptionService } from "../services";

import * as editServicesActions from "./edit-services.actions";
import * as routerActions from "../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../kernel/store/actions/snackbar.actions";
import * as serviceMenuActions from "./service-menu.actions";

@Injectable()
export class EditServicesEffects {
  public navigateEditServicesPanel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editServicesActions.navigateEditServicesPanel),
      map((_) =>
        routerActions.go({
          path: ["/service-menu", { outlets: { panel: "edit-services" } }],
          extras: { skipLocationChange: true, queryParamsHandling: "merge" },
        })
      )
    )
  );

  public editServices$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editServicesActions.editServices),
      map((action) => action.partial),
      withLatestFrom(this._store.pipe(select(getSelectedRecords))),
      switchMap(([partial, records]) =>
        this._serviceDescriptionService
          .updateMany(records, partial)
          .pipe(
            mergeMap((results) => [
              editServicesActions.editServicesSuccess(),
              new serviceMenuActions.UpdateServiceDescriptionsSuccess(results),
            ])
          )
      )
    )
  );

  public editServicesSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editServicesActions.editServicesSuccess),
      mergeMap(() => [editServicesActions.close(), new SnackbarActions.Info({ message: "Services modified successfully" })])
    )
  );

  public close$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editServicesActions.close),
      mergeMap(() => [
        new serviceMenuActions.DeselectAll(),
        routerActions.go({ path: ["/service-menu"], extras: { queryParamsHandling: "merge" } }),
      ])
    )
  );

  constructor(
    private _serviceDescriptionService: ServiceDescriptionService,
    private _store: Store<AppState>,
    private _actions$: Actions
  ) {}
}
