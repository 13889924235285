import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AuthStorageService } from "app/+auth/services";
import { WindowService } from "app/kernel";
import { Observable } from "rxjs";
import { HTTP_URL } from "../../kernel/services/common";

@Injectable()
export class ServiceMenuDownloaderService {
  constructor(
    private _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string,
    private _windowService: WindowService,
    private _authStorage: AuthStorageService
  ) {}

  public downloadServiceMenuReport(): Observable<Blob> {
    const headers = new HttpHeaders({
      "X-Salon-Slug": this._windowService.tenantPathName,
      "X-Auth-Token": this._authStorage.getAuthToken(),
    });

    return this._http.get(`${this._httpUrl}/serviceMenu/excel`, { responseType: "blob", headers });
  }
}
