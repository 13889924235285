import { AbstractControl } from "@angular/forms";
import { isNil, not, equals } from "ramda";

export const Equals = (test: string) => (control: AbstractControl) => {
  const value = control.value;

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined
  } else if (not(equals(value, test))) {
    return { equals: true };
  }

  return null;
};
