import { SalonConfig } from "@getvish/model";
import { createAction, props, union } from "@ngrx/store";
import { SalonApiVersion } from "app/kernel/models/salon-api-version";

export const checkTenantApiVersion = createAction("[Salon API Version] Check Tenant API Version", props<{ salonConfig: SalonConfig }>());

export const checkTenantApiVersionPass = createAction(
  "[Salon API Version] Check Tenant API Version Pass",
  props<{ apiVersion: SalonApiVersion }>()
);

export const checkTenantApiVersionFail = createAction(
  "[Salon API Version] Check Tenant API Version Fail",
  props<{ apiVersion: SalonApiVersion }>()
);

const actions = union({
  checkTenantApiVersion,
  checkTenantApiVersionPass,
  checkTenantApiVersionFail,
});

export type Actions = typeof actions;
