import { Customer } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import * as customer from "./customer.actions";

export interface CustomerState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  records: Customer[];
  paging: PagingMetadata;
  selected: string;
  criteria: JsonObject;
  sort: JsonObject;
  importing: boolean;
  numRecordsImported: number;
  selectedRecords: Customer[];
  filter: string;
  tableFilter: JsonObject;
}

const initialState: CustomerState = {
  loading: false,
  saving: false,
  deleting: false,
  records: [],
  paging: undefined,
  selected: undefined,
  criteria: undefined,
  sort: undefined,
  importing: undefined,
  numRecordsImported: undefined,
  selectedRecords: [],
  filter: undefined,
  tableFilter: undefined,
};

export function customerReducer(state: CustomerState = initialState, action: customer.Actions): CustomerState {
  switch (action.type) {
    case customer.Types.SEARCH: {
      const filter = action.payload.filter;

      return {
        ...state,
        filter,
      };
    }

    case customer.Types.UPDATE_TABLE_FILTER: {
      const tableFilter = action.payload;

      return {
        ...state,
        tableFilter,
      };
    }

    case customer.Types.UPDATE_SORT: {
      const sort = action.payload;

      return {
        ...state,
        sort,
      };
    }

    case customer.Types.LOAD_ALL: {
      const loading = true;
      const { criteria, sort, filter, tableFilter } = action.payload;

      return {
        ...state,
        loading,
        criteria,
        sort,
        filter,
        tableFilter,
      };
    }

    case customer.Types.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload,
        paging: action.paging,
      };
    }

    case customer.Types.REMOVE: {
      return {
        ...state,
        deleting: true,
      };
    }

    case customer.Types.REMOVE_FAIL: {
      return {
        ...state,
        deleting: false,
      };
    }

    case customer.Types.REMOVE_SUCCESS: {
      const records = state.records;
      const updatedRecords = records.filter((record) => record._id !== action.payload);

      return {
        ...state,
        records: updatedRecords,
        deleting: false,
      };
    }

    case customer.Types.TOGGLE_SELECTED: {
      const customer = action.payload;
      const selectedRecords = state.selectedRecords;

      const updatedSelectedRecords = selectedRecords.some((record) => record._id === customer._id)
        ? selectedRecords.filter((record) => record._id !== customer._id)
        : [...selectedRecords, customer];

      return {
        ...state,
        selectedRecords: updatedSelectedRecords,
      };
    }

    case customer.Types.CLEAR_SELECTED: {
      const selectedRecords = [];

      return {
        ...state,
        selectedRecords,
      };
    }

    default: {
      return state;
    }
  }
}
