import { Appointment, AppointmentStatus, ServiceDescription } from "@getvish/model";
import { PagingMetadata } from "@getvish/stockpile";

import * as appointment from "./appointment.actions";
import { reject } from "ramda";
import { AppointmentVM } from "../models/appointment";

export interface AppointmentState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  resolving: boolean;
  resolveLoading: boolean;
  resolveServiceDescriptions: ServiceDescription[];
  resolveAppointments: AppointmentVM[];
  records: AppointmentVM[];
  paging: PagingMetadata;
  selected: Appointment;
  searchFilter: string;
  statusFilter: AppointmentStatus;
  alertsOnly: boolean;
}

const initialState: AppointmentState = {
  loading: false,
  saving: false,
  deleting: false,
  resolving: false,
  resolveLoading: false,
  resolveServiceDescriptions: undefined,
  resolveAppointments: [],
  records: [],
  paging: undefined,
  selected: undefined,
  searchFilter: undefined,
  statusFilter: AppointmentStatus.ACTIVE,
  alertsOnly: false,
};

export function appointmentReducer(state: AppointmentState = initialState, action: appointment.Actions): AppointmentState {
  switch (action.type) {
    case appointment.loadCurrentAppointments.type: {
      return {
        ...state,
        loading: true,
      };
    }

    case appointment.Types.LOAD_CURRENT_APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload,
        paging: action.paging,
      };
    }

    case appointment.Types.SET_SEARCH_FILTER: {
      const searchFilter = action.payload.filter;

      return {
        ...state,
        searchFilter,
      };
    }

    case appointment.Types.SET_STATUS_FILTER: {
      const statusFilter = action.payload.status;

      return {
        ...state,
        statusFilter,
      };
    }

    case appointment.Types.TOGGLE_ALERTS_ONLY: {
      return {
        ...state,
        alertsOnly: !state.alertsOnly,
      };
    }

    case appointment.Types.DELETE: {
      return {
        ...state,
        deleting: true,
      };
    }

    case appointment.Types.DELETE_SUCCESS: {
      return {
        ...state,
        records: reject<Appointment>((appt) => appt._id === action.id)(state.records),
        deleting: false,
      };
    }

    case appointment.Types.DELETE_FAIL: {
      return {
        ...state,
        deleting: false,
      };
    }

    case appointment.Types.SHOW_RESOLVE: {
      return {
        ...state,
        resolveAppointments: action.appointments,
      };
    }

    case appointment.Types.LOAD_RESOLVE: {
      return {
        ...state,
        resolveLoading: true,
        resolveAppointments: action.appointments ?? state.resolveAppointments,
      };
    }

    case appointment.Types.LOAD_RESOLVE_SUCCESS: {
      return {
        ...state,
        resolveServiceDescriptions: action.serviceDescriptions,
        resolveLoading: false,
      };
    }

    case appointment.Types.LOAD_RESOLVE_FAIL: {
      return {
        ...state,
        resolveLoading: false,
      };
    }

    case appointment.Types.RESOLVE: {
      return {
        ...state,
        resolving: true,
      };
    }

    case appointment.Types.RESOLVE_SUCCESS: {
      return {
        ...state,
        records: state.records.map((appt) => (action.ids.includes(appt._id) ? { ...appt, changes: [] } : appt)),
        resolving: false,
      };
    }

    case appointment.Types.RESOLVE_FAIL: {
      return {
        ...state,
        resolving: false,
      };
    }

    default: {
      return state;
    }
  }
}
