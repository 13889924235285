import { Params, RouterStateSnapshot, ParamMap } from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  paramMap: ParamMap;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const queryParams = routerState.root.queryParams;
    const paramMap = routerState.root.queryParamMap;

    // Only return an object including the URL and query params
    // instead of the entire snapshot
    return { url, queryParams, paramMap };
  }
}
