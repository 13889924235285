import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "forgot-password-form",
  templateUrl: "forgot-password-form.component.html",
  styleUrls: ["common.less", "forgot-password-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent implements OnInit {
  @Input() public error: string;
  @Output() public save: EventEmitter<{ email: string }>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      email: [undefined, [Validators.required, Validators.email]],
    });
  }

  public submit(formValue: { email: string }) {
    this.save.emit(formValue);
  }
}
