import * as editSalon from "./edit-salon.actions";
import { Salon } from "@getvish/model";

export interface EditSalonState {
  loading: boolean;
  record: Salon;
  error: string;
}

const initialState: EditSalonState = {
  loading: false,
  record: undefined,
  error: undefined,
};

export function editSalonReducer(state: EditSalonState = initialState, action: editSalon.Actions): EditSalonState {
  switch (action.type) {
    case editSalon.Types.LOAD: {
      const loading = true;

      return {
        ...state,
        loading,
      };
    }

    case editSalon.Types.LOAD_SUCCESS: {
      const loading = false;
      const record = action.payload.salon;

      return {
        ...state,
        loading,
        record,
      };
    }

    case editSalon.Types.LOAD_FAIL: {
      const loading = false;
      const error = action.payload.error.message;

      return {
        ...state,
        loading,
        error,
      };
    }

    default: {
      return state;
    }
  }
}
