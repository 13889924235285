<slideout-panel (close)="close()">
  <div panel-header>{{ header$ | async }}</div>

  <div panel-body>
    <order-container
      *ngIf="showResolve === false; else resolveTemplate"
      (resolve)="setShowResolve(true)"></order-container>
    <ng-template #resolveTemplate>
      <resolve-appointments
        [loading]="loading$ | async"
        [appointments]="resolveAppointments$ | async"
        [serviceDescriptions]="resolveServiceDescriptions$ | async"
        [timeZone]="timeZone$ | async"
        (resolve)="resolve($event)"></resolve-appointments>
    </ng-template>
  </div>
</slideout-panel>
