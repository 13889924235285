import { toUndefined, isSome } from "fp-ts/Option";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SalonService } from "app/+salons/services";
import { Salon, User } from "@getvish/model";
import { Injectable } from "@angular/core";
import { isAdmin } from "app/+auth/services";
import { EmployeeService } from "app/+employees/services";

@Injectable()
export class CurrentSalonService {
  public currentSalonId: string;
  public currentSalonSlug: string;

  public getCurrentSalonId() {
    return this.currentSalonId;
  }

  public getCurrentSalonSlug() {
    return this.currentSalonSlug;
  }

  public setCurrentSalonId(salonId: string) {
    this.currentSalonId = salonId;
  }

  public setCurrentSalonSlug(slug: string) {
    this.currentSalonSlug = slug;
  }
}

export class CurrentTenantService {
  constructor(
    private _salonService: SalonService,
    private _currentSalonService: CurrentSalonService,
    private _employeeService: EmployeeService
  ) {}

  public tenantExists(slug: string): Observable<boolean> {
    return this._salonService.findById(slug).pipe(map(isSome));
  }

  public getAvailableTenantsForUser(user: User): Observable<Salon[]> {
    if (isAdmin(user)) {
      return this._salonService.findById(this._currentSalonService.getCurrentSalonSlug()).pipe(
        map(toUndefined),
        map((salon) => [salon])
      );
    } else {
      return this._employeeService.listTenants(user._id);
    }
  }
}
