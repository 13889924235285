<slideout-panel
  (close)="close()"
  [progressIndicator]="(saving$ | async) ? 'Saving...' : null">
  <div panel-header>{{ (categoryId$ | async) ? "Set Category Markup" : "Set Markup" }}</div>
  <div
    *ngLet="categoryId$ | async as categoryId"
    panel-body>
    <form
      [formGroup]="form"
      (submit)="saveForm(form.value, categoryId)"
      novalidate>
      <mat-form-field class="full-width">
        <mat-label>Markup</mat-label>
        <span class="input-item">
          <input
            matInput
            formControlName="markupPercent"
            type="number"
            min="0"
            step="25" />
          <span matSuffix>%</span>
        </span>
      </mat-form-field>
    </form>

    <div
      class="controls"
      style="position: relative; margin: 0 10%">
      <button
        (click)="saveForm(form.value, categoryId)"
        type="button"
        [disabled]="form.invalid"
        class="button primary"
        style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
        Save
      </button>
    </div>
  </div>
</slideout-panel>
