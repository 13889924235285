import { Injectable } from "@angular/core";
import { Manufacturer, ProductCategory, SalonManufacturer, SalonProduct, SalonProductCategory } from "@getvish/model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { saveAs } from "file-saver";
import { either } from "fp-ts";
import { isNil } from "ramda";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map, mapTo, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { getAuthToken } from "../../../+auth/store/auth.selectors";
import { getSalonConfig } from "../../../+salon-config/store/salon-config.selectors";
import { back, go } from "../../../kernel/store/actions/router.actions";
import { SalonManufacturerService, SalonProductService } from "../services";
import { SalonProductSpreadsheetService } from "../services/salon-product-spreadsheet.service";
import {
  getFilteredProducts,
  getManufacturers,
  getSalonManufacturers,
  getSalonProductCategories,
  getSearchFilter,
  getSelectedManufacturerId,
  selectProductIdsByCategoryId,
} from "./salon-product.selectors";

import * as ProductCategoryActions from "../../+product-categories/store/product-category.actions";
import * as fromProductCategory from "../../+product-categories/store/product-category.selectors";
import * as routerActions from "../../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../../kernel/store/actions/snackbar.actions";
import * as salonProductActions from "./salon-product.actions";
import { ManufacturerService } from "app/+product/+manufacturers/services";
import { left, right } from "fp-ts/lib/Either";
import { MatDialog } from "@angular/material/dialog";
import { ManufacturerOrderDialogComponent } from "../components/manufacturer-order-dialog/manufacturer-order-dialog.component";
import { fold, fromNullable } from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import { SalonProductCategoryService } from "../services/salon-product-category.service";
import { ProductCategoryOrderDialogComponent } from "../components/product-category-order-dialog/product-category-order-dialog.component";
import { ProductOrderDialogComponent } from "../components/product-order-dialog/product-order-dialog.component";

@Injectable()
export class SalonProductEffects {
  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.LoadAll>(salonProductActions.Types.LOAD_ALL),
      map((action) => action.payload.criteria),
      switchMap((_criteria) => {
        return forkJoin({
          salonProducts: this._salonProductService.findAll(),
          salonManufacturers: this._salonManufacturerService.findAll(),
        }).pipe(
          mergeMap(({ salonProducts, salonManufacturers }) => {
            const manufacturerIds = salonManufacturers.map((manufacturer) => manufacturer.manufacturerId);
            return this._manufacturerService.findByIds(manufacturerIds).pipe(
              mergeMap((manufacturers) => {
                return [new salonProductActions.LoadAllSuccess(salonProducts, salonManufacturers, undefined, manufacturers)];
              })
            );
          }),
          catchError((reason) => {
            console.error(reason);
            return of(new salonProductActions.LoadAllFail({ errors: reason }));
          })
        );
      })
    )
  );

  public selectManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.SelectManufacturer>(salonProductActions.Types.SELECT_MANUFACTURER),
      map((action) => action.payload.manufacturer),
      map((manufacturer) => manufacturer._id),
      map((manufacturerId) =>
        routerActions.go({
          path: [`/product/salon-products/manufacturer/${manufacturerId}`],
        })
      )
    )
  );

  public loadProductsAndCategoriesForManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.LoadProductsAndCategoriesForManufacturer>(
        salonProductActions.Types.LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER
      ),
      map((action) => action.payload.manufacturerId),
      mergeMap((manufacturerId) => [
        new ProductCategoryActions.LoadAll({ criteria: { manufacturerId } }),
        new salonProductActions.LoadAllManufacturer(manufacturerId),
      ])
    )
  );

  public loadAllManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.LoadAllManufacturer>(salonProductActions.Types.LOAD_ALL_MANUFACTURER),
      map((action) => action.payload),
      switchMap((manufacturerId) =>
        this._store.select(fromProductCategory.getAll).pipe(
          map((productCategories) => productCategories.filter((c) => c.manufacturerId === manufacturerId)),
          switchMap((productCategories) =>
            forkJoin({
              salonProducts: this._salonProductService.findForManufacturer(manufacturerId),
              salonProductCategories: this._salonProductCategoryService.findForProductCategories(productCategories),
            })
          )
        )
      ),
      mergeMap(({ salonProducts, salonProductCategories }) => [
        new salonProductActions.LoadAllSuccess(salonProducts, undefined, salonProductCategories),
      ]),
      catchError((reason) => {
        console.error(reason);
        return of(new salonProductActions.LoadAllFail({ errors: reason }));
      })
    )
  );

  public newProduct$ = createEffect(() =>
    this._actions$.pipe(ofType(salonProductActions.Types.NEW), mapTo(new salonProductActions.NavigateEditProduct()))
  );

  public editProduct$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.Edit>(salonProductActions.Types.EDIT),
      map((action) => action.payload.product._id),
      map((productId) => new salonProductActions.NavigateEditProduct({ productId }))
    )
  );

  public navigateEditProduct$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.NavigateEditProduct>(salonProductActions.Types.NAVIGATE_EDIT_PRODUCT),
      map((action) => (!isNil(action.payload) ? `edit/${action.payload.productId}` : "new")),
      map((fragment) =>
        routerActions.go({
          path: ["/product/salon-products", { outlets: { panel: fragment } }],
        })
      )
    )
  );

  public showCategoryPricing$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.NavigateCategoryPricing>(salonProductActions.Types.NAVIGATE_CATEGORY_PRICING),
      map((action) => action.payload.category),
      map((category) => category._id),
      map((categoryId) => `category/${categoryId}/pricing`),
      map((fragment) =>
        routerActions.go({
          path: ["/product/salon-products", { outlets: { panel: fragment } }],
        })
      )
    )
  );

  public showMarkup$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.NavigateMarkup>(salonProductActions.Types.NAVIGATE_MARKUP),
      map((action) => action.payload.category),
      map((category) => (category?._id ? `category/${category._id}/markup` : "manufacturer/markup")),
      map((fragment) =>
        routerActions.go({
          path: ["/product/salon-products", { outlets: { panel: fragment } }],
        })
      )
    )
  );

  public placeSalonInteractiveOrder$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.PlaceSalonInteractiveOrder>(salonProductActions.Types.PLACE_SALON_INTERACTIVE_ORDER),
      map((action) => action.payload.category),
      switchMap((category) => this._store.select(selectProductIdsByCategoryId(category._id))),
      switchMap((productIds) =>
        this._salonProductService.placeSalonInteractiveOrder(productIds).pipe(
          map(
            either.fold(
              (_) => new salonProductActions.PlaceSalonInteractiveOrderSuccess({}),
              (url) => {
                window.open(url, "_blank"); // Open the URL in a new tab/window
                return new salonProductActions.PlaceSalonInteractiveOrderSuccess({});
              }
            )
          )
        )
      )
    )
  );

  public add$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonProductActions.Types.ADD),
      map((action: salonProductActions.Add) => action.payload.product),
      switchMap((payload) =>
        this._salonProductService.insert(payload).pipe(
          mergeMap(
            either.fold<Error, SalonProduct, Action[]>(
              (error) => [new salonProductActions.AddFail({ errors: error })],
              (salonProduct) => [new salonProductActions.AddSuccess(salonProduct)]
            )
          )
        )
      )
    )
  );

  public update$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.Update>(salonProductActions.Types.UPDATE),
      map((action) => action.payload.product),
      withLatestFrom(this._store.select(getSelectedManufacturerId)),
      switchMap(([payload, manufacturerId]) =>
        this._salonProductService.update(payload).pipe(
          mergeMap(
            either.fold<Error, SalonProduct, Action[]>(
              (error) => [new salonProductActions.UpdateFail({ errors: error })],
              (salonProduct) => [
                new salonProductActions.UpdateSuccess(salonProduct),
                new salonProductActions.LoadAllManufacturer(manufacturerId),
              ]
            )
          )
        )
      )
    )
  );

  public setCategoryPricing$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.SetCategoryPricing>(salonProductActions.Types.SET_CATEGORY_PRICING),
      map((action) => action.payload.pricing),
      withLatestFrom(
        this._store.select(getSelectedManufacturerId),
        this._store.select(getSearchFilter),
        this._store.select(getFilteredProducts)
      ),
      switchMap(([pricing, manufacturerId, searchFilter, salonProducts]) => {
        let obs: Observable<either.Either<Error, number>>;

        if (searchFilter != null && searchFilter !== "") {
          obs = this._salonProductService
            .setSalonProductPricing(
              salonProducts.filter((sp) => sp.categoryId === pricing.categoryId),
              pricing
            )
            .pipe(
              mergeMap(
                either.fold<Error[], number, Observable<either.Either<Error, number>>>(
                  (errors) =>
                    of(left(new Error(`Setting product pricing failed with ${errors.length} error${errors.length > 1 ? "s" : ""}.`))),
                  (numUpdated) => of(right(numUpdated))
                )
              )
            );
        } else {
          obs = this._salonProductService.setCategoryPricing(pricing);
        }

        return obs.pipe(
          mergeMap(
            either.fold<Error, number, Action[]>(
              (error) => [new salonProductActions.SetCategoryPricingFail({ error })],
              (numUpdated) => [
                new salonProductActions.SetCategoryPricingSuccess({
                  numUpdated,
                }),
                new salonProductActions.LoadAllManufacturer(manufacturerId),
              ]
            )
          )
        );
      })
    )
  );

  public setMarkup$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.SetMarkup>(salonProductActions.Types.SET_MARKUP),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getSelectedManufacturerId)),
      switchMap(([{ markup, categoryId }, manufacturerId]) => {
        let obs: Observable<either.Either<Error, number>>;
        if (categoryId) {
          obs = this._salonProductService.setCategoryMarkup(categoryId, markup);
        } else {
          obs = this._salonProductService.setManufacturerMarkup(manufacturerId, markup);
        }

        return obs.pipe(
          mergeMap(
            either.fold<Error, number, Action[]>(
              (error) => [new salonProductActions.SetMarkupFail({ error })],
              (numUpdated) => [
                new salonProductActions.SetMarkupSuccess({
                  numUpdated,
                }),
                new salonProductActions.LoadAllManufacturer(manufacturerId),
              ]
            )
          )
        );
      })
    )
  );

  public setMarkupSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonProductActions.Types.SET_MARKUP_SUCCESS),
      mergeMap(() => {
        return [new SnackbarActions.Info({ message: "Markup saved successfully" }), routerActions.back()];
      })
    )
  );

  public setCategoryInactive$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.SetCategoryInactive>(salonProductActions.Types.SET_CATEGORY_INACTIVE),
      map((action) => action.payload.category),
      withLatestFrom(
        this._store.select(getSelectedManufacturerId),
        this._store.select(getSearchFilter),
        this._store.select(getFilteredProducts)
      ),
      switchMap(([category, manufacturerId, searchFilter, salonProducts]) => {
        let obs: Observable<either.Either<Error, number>>;

        if (searchFilter != null && searchFilter !== "") {
          obs = this._salonProductService.setSalonProductsInactive(salonProducts.filter((sp) => sp.categoryId === category._id)).pipe(
            mergeMap(
              either.fold<Error[], number, Observable<either.Either<Error, number>>>(
                (errors) => of(left(new Error(`Deactivating products failed with ${errors.length} error${errors.length > 1 ? "s" : ""}.`))),
                (numUpdated) => of(right(numUpdated))
              )
            )
          );
        } else {
          obs = this._salonProductService.setCategoryInactive(category._id);
        }

        return obs.pipe(
          mergeMap(
            either.fold<Error, number, Action[]>(
              (error) => [new salonProductActions.SetCategoryInactiveFail({ error })],
              (numUpdated) => [
                new salonProductActions.SetCategoryInactiveSuccess({
                  numUpdated,
                }),
                new salonProductActions.LoadAllManufacturer(manufacturerId),
              ]
            )
          )
        );
      })
    )
  );

  public updateCategorySuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonProductActions.Types.SET_CATEGORY_INACTIVE_SUCCESS),
      map(() => new SnackbarActions.Info({ message: "Products saved successfully" }))
    )
  );

  public setCategoryActive$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.SetCategoryInactive>(salonProductActions.Types.SET_CATEGORY_ACTIVE),
      map((action) => action.payload.category),
      withLatestFrom(this._store.select(getSelectedManufacturerId), this._store.select(getFilteredProducts)),
      switchMap(([category, manufacturerId, salonProducts]) =>
        this._salonProductService.setSalonProductsActive(salonProducts.filter((sp) => sp.categoryId === category._id)).pipe(
          mergeMap(
            either.fold<Error[], number, Action[]>(
              (errors) => [
                new salonProductActions.SetCategoryActiveFail({
                  errors,
                }),
              ],
              (numUpdated) => [
                new salonProductActions.SetCategoryActiveSuccess({
                  numUpdated,
                }),
                new salonProductActions.LoadAllManufacturer(manufacturerId),
              ]
            )
          )
        )
      )
    )
  );

  public setCategoryActiveSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonProductActions.Types.SET_CATEGORY_ACTIVE_SUCCESS),
      map(() => new SnackbarActions.Info({ message: "Products saved successfully" }))
    )
  );

  public addOrUpdateSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        salonProductActions.Types.ADD_SUCCESS,
        salonProductActions.Types.UPDATE_SUCCESS,
        salonProductActions.Types.SET_CATEGORY_PRICING_SUCCESS
      ),
      mergeMap(() => {
        return [new SnackbarActions.Info({ message: "Products saved successfully" }), routerActions.back()];
      })
    )
  );

  public importManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ImportManufacturer>(salonProductActions.Types.IMPORT_MANUFACTURER),
      map((action) => action.payload.manufacturer),
      switchMap((manufacturer) =>
        this._salonProductService.importManufacturer(manufacturer).pipe(
          map(
            either.fold<Error, number, Action>(
              (error) => new salonProductActions.ImportManufacturerFail({ error }),
              (numImported) =>
                new salonProductActions.ImportManufacturerSuccess({
                  numImported,
                  manufacturer,
                })
            )
          )
        )
      )
    )
  );

  public importProductCategory$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ImportProductCategory>(salonProductActions.Types.IMPORT_PRODUCT_CATEGORY),
      map((action) => action.payload.category),
      switchMap((productCategory) =>
        this._salonProductService.importProductCategory(productCategory).pipe(
          map(
            either.fold<Error, number, Action>(
              (error) => new salonProductActions.ImportProductCategoryFail({ error }),
              (numImported) =>
                new salonProductActions.ImportProductCategorySuccess({
                  numImported,
                  productCategory,
                })
            )
          )
        )
      )
    )
  );

  // TODO: revisit this. we longer use this due to the multi-select import (always importing a batch of individual products)
  public importManufacturerSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ImportManufacturerSuccess>(salonProductActions.Types.IMPORT_MANFACTURER_SUCCESS),
      map((action) => action.payload.manufacturer),
      map((manufacturer) => manufacturer._id),
      switchMap((manufacturerId) =>
        this._salonProductService
          .findForManufacturer(manufacturerId)
          .pipe(mergeMap((result) => [new salonProductActions.LoadAllSuccess(result)]))
      )
    )
  );

  public importManufacturererSuccessNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ImportManufacturerSuccess>(salonProductActions.Types.IMPORT_MANFACTURER_SUCCESS),
      map(
        () =>
          new SnackbarActions.Info({
            message: "Manufacturer successfully imported",
          })
      )
    )
  );

  // TODO: revisit this. we longer use this due to the multi-select import (always importing a batch of individual products)
  public importProductCategorySuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ImportProductCategorySuccess>(salonProductActions.Types.IMPORT_PRODUCT_CATEGORY_SUCCESS),
      map((action) => action.payload.productCategory.manufacturerId),
      switchMap((manufacturerId) =>
        this._salonProductService
          .findForManufacturer(manufacturerId)
          .pipe(mergeMap((result) => [new salonProductActions.LoadAllSuccess(result)]))
      )
    )
  );

  public importProductCategorySuccessNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ImportProductCategorySuccess>(salonProductActions.Types.IMPORT_PRODUCT_CATEGORY_SUCCESS),
      map(
        () =>
          new SnackbarActions.Info({
            message: "Category successfully imported",
          })
      )
    )
  );

  public downloadSpreadsheet$ = createEffect(
    () =>
      this._actions$
        .pipe(
          ofType<salonProductActions.DownloadSpreadsheet>(salonProductActions.Types.DOWNLOAD_SPREADSHEET),
          map((action) => action.payload),
          withLatestFrom(this._store.pipe(select(getSalonConfig)), this._store.pipe(select(getAuthToken))),
          switchMap(([payload, salonConfig, authToken]) =>
            this._salonProductSpreadsheetService.downloadSpreadsheet(salonConfig.salonId, authToken, payload.manufacturerId)
          )
        )
        .pipe(map((response) => saveAs(response, "salon-products.xlsx"))),
    { dispatch: false }
  );

  public openUploadSpreadsheetComponent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonProductActions.Types.OPEN_UPLOAD_COMPONENT),
      mapTo(
        go({
          path: ["/product/salon-products", { outlets: { panel: "upload" } }],
        })
      )
    )
  );

  public closeUploadSpreadsheetComponent$ = createEffect(() =>
    this._actions$.pipe(ofType(salonProductActions.Types.CLOSE_UPLOAD_COMPONENT), mapTo(back()))
  );

  public uploadSpreadsheet$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.UploadSpreadsheet>(salonProductActions.Types.UPLOAD_SPREADSHEET),
      withLatestFrom(this._store.pipe(select(getSalonConfig)), this._store.pipe(select(getAuthToken))),
      switchMap(([action, salonConfig, authToken]) =>
        this._salonProductSpreadsheetService
          .uploadSpreadsheet(salonConfig.salonId, authToken, action.payload.blobUrl, action.payload.type)
          .pipe(
            map(() => new salonProductActions.UploadSpreadsheetComplete()),
            catchError((error) => of(new salonProductActions.UploadSpreadsheetFail({ error })))
          )
      )
    )
  );

  public uploadSpreadsheetComplete$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonProductActions.Types.UPLOAD_SPREADSHEET_COMPLETE),
      withLatestFrom(this._store.pipe(select(getSelectedManufacturerId))),
      map(([_, manufacturerId]) => new salonProductActions.LoadAllManufacturer(manufacturerId))
    )
  );

  public showManufacturerOrderDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonProductActions.Types.SHOW_MANUFACTURER_ORDER_DIALOG),
      withLatestFrom(this._store.select(getManufacturers), this._store.select(getSalonManufacturers)),
      map(([_, manufacturers, salonManufacturers]) =>
        this._matDialog.open<
          ManufacturerOrderDialogComponent,
          { manufacturers: Manufacturer[]; salonManufacturers: SalonManufacturer[] },
          SalonManufacturer[]
        >(ManufacturerOrderDialogComponent, {
          disableClose: true,
          maxHeight: "95vh",
          panelClass: "dlg-no-padding-pane",
          data: {
            manufacturers,
            salonManufacturers,
          },
        })
      ),
      switchMap((dialogRef) => dialogRef.afterClosed()),
      map(fromNullable),
      map(
        fold(
          () => ({ type: "EMPTY_ACTION" }),
          (salonManufacturers) => new salonProductActions.UpdateSalonManufacturersOrder({ salonManufacturers })
        )
      )
    )
  );

  public updateSalonManufacturers$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.UpdateSalonManufacturersOrder>(salonProductActions.Types.UPDATE_SALON_MANUFACTURERS_ORDER),
      map((action) => action.payload),
      switchMap(({ salonManufacturers }) => this._salonManufacturerService.updateOrderMany(salonManufacturers)),
      map((salonManufacturers) => new salonProductActions.UpdateSalonManufacturersOrderSuccess({ salonManufacturers })),
      catchError((error) => of(new salonProductActions.UpdateSalonManufacturersOrderFail({ error })))
    )
  );

  public updateSalonManufacturersFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.UpdateSalonManufacturersOrderFail>(salonProductActions.Types.UPDATE_SALON_MANUFACTURERS_ORDER_FAIL),
      map(() => new SnackbarActions.Info({ message: "Updating manufacturers failed." }))
    )
  );

  public showCategoryOrderDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ShowProductCategoryOrderDialog>(salonProductActions.Types.SHOW_PRODUCT_CATEGORY_ORDER_DIALOG),
      withLatestFrom(this._store.select(getSalonProductCategories)),
      map(([action, salonProductCategories]) =>
        this._matDialog.open<
          ProductCategoryOrderDialogComponent,
          { productCategories: ProductCategory[]; salonProductCategories: SalonProductCategory[] },
          SalonProductCategory[]
        >(ProductCategoryOrderDialogComponent, {
          disableClose: true,
          maxHeight: "95vh",
          panelClass: "dlg-no-padding-pane",
          data: {
            productCategories: action.payload.productCategories,
            salonProductCategories: salonProductCategories.filter((spc) =>
              action.payload.productCategories.some((pc) => pc._id === spc.productCategoryId)
            ),
          },
        })
      ),
      switchMap((dialogRef) => dialogRef.afterClosed()),
      map(fromNullable),
      map(
        fold(
          () => ({ type: "EMPTY_ACTION" }),
          (salonProductCategories) => new salonProductActions.UpdateSalonProductCategoriesOrder({ salonProductCategories })
        )
      )
    )
  );

  public updateSalonProductCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.UpdateSalonProductCategoriesOrder>(salonProductActions.Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER),
      map((action) => action.payload),
      switchMap(({ salonProductCategories }) => this._salonProductCategoryService.updateOrderMany(salonProductCategories)),
      map((salonProductCategories) => new salonProductActions.UpdateSalonProductCategoriesOrderSuccess({ salonProductCategories })),
      catchError((error) => of(new salonProductActions.UpdateSalonProductCategoriesOrderFail({ error })))
    )
  );

  public updateSalonProductCategoriesFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.UpdateSalonProductCategoriesOrderFail>(
        salonProductActions.Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER_FAIL
      ),
      map(() => new SnackbarActions.Info({ message: "Updating product categories failed." }))
    )
  );

  public showProductsOrderDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.ShowProductOrderDialog>(salonProductActions.Types.SHOW_PRODUCT_ORDER_DIALOG),
      map((action) =>
        this._matDialog.open<ProductOrderDialogComponent, { salonProducts: SalonProduct[] }, SalonProduct[]>(ProductOrderDialogComponent, {
          disableClose: true,
          maxHeight: "95vh",
          panelClass: "dlg-no-padding-pane",
          data: {
            salonProducts: action.payload.salonProducts,
          },
        })
      ),
      switchMap((dialogRef) => dialogRef.afterClosed()),
      map(fromNullable),
      map(
        fold(
          () => ({ type: "EMPTY_ACTION" }),
          (salonProducts) => new salonProductActions.UpdateSalonProducts({ salonProducts })
        )
      )
    )
  );

  public updateSalonProductss$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.UpdateSalonProducts>(salonProductActions.Types.UPDATE_SALON_PRODUCTS),
      map((action) => action.payload),
      switchMap(({ salonProducts }) => this._salonProductService.updateMany(salonProducts)),
      map(
        E.fold<Error, SalonProduct[], Action>(
          (e) => new salonProductActions.UpdateSalonProductsFail({ error: e }),
          (salonProducts) => new salonProductActions.UpdateSalonProductsSuccess({ salonProducts })
        )
      )
    )
  );

  public updateSalonProductsFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonProductActions.UpdateSalonProductsFail>(salonProductActions.Types.UPDATE_SALON_PRODUCTS_FAIL),
      map(() => new SnackbarActions.Info({ message: "Updating products failed." }))
    )
  );

  constructor(
    private _salonProductService: SalonProductService,
    private _salonProductSpreadsheetService: SalonProductSpreadsheetService,
    private _salonManufacturerService: SalonManufacturerService,
    private _manufacturerService: ManufacturerService,
    private _salonProductCategoryService: SalonProductCategoryService,
    private _matDialog: MatDialog,
    private _actions$: Actions,
    private _store: Store<AppState>
  ) {}
}
