import { Action } from "@ngrx/store";
import { ServiceDescription } from "@getvish/model";
import { isNil, not } from "ramda";

export enum Types {
  NAVIGATE = "[Edit Service] Navigate",
  LOAD_DATA = "[Edit Service] Load Data",
  LOAD_DATA_SUCCESS = "[Edit Service] Load Data Success",
  LOAD_DATA_FAIL = "[Edit Service] Load Data Fail",
  ADD = "[Edit Service] Add",
  EDIT = "[Edit Service] Edit",
  ADD_SUCCESS = "[Edit Service] Add Success",
  EDIT_SUCCESS = "[Edit Service] Edit Success",
  FAIL = "[Edit Service] Fail",
  CLOSE = "[Edit Service] Close",
}

export class Navigate implements Action {
  public readonly type = Types.NAVIGATE;

  constructor(public payload?: { serviceDescriptionId: string }) {}
}

export class LoadData implements Action {
  public readonly type = Types.LOAD_DATA;

  constructor(public payload: { id: string }) {}
}

export class LoadDataSuccess implements Action {
  public readonly type = Types.LOAD_DATA_SUCCESS;

  constructor(public payload: ServiceDescription) {}
}

export class LoadDataFail implements Action {
  public readonly type = Types.LOAD_DATA_FAIL;

  constructor(public payload: { error: any }) {}
}

export class Add implements Action {
  public readonly type = Types.ADD;

  constructor(public payload: { serviceDescription: ServiceDescription }) {}
}

export class Edit implements Action {
  public readonly type = Types.EDIT;

  public constructor(public payload: { serviceDescription: ServiceDescription }) {}
}

export const addOrUpdate = (payload: { serviceDescription: ServiceDescription }) =>
  not(isNil(payload.serviceDescription._id)) ? new Edit(payload) : new Add(payload);

export class AddSuccess implements Action {
  public readonly type = Types.ADD_SUCCESS;

  public constructor(public payload: ServiceDescription) {}
}

export class EditSuccess implements Action {
  public readonly type = Types.EDIT_SUCCESS;

  public constructor(public payload: ServiceDescription) {}
}

export class Fail implements Action {
  public readonly type = Types.FAIL;

  public constructor(public payload: any) {}
}

export class Close implements Action {
  public readonly type = Types.CLOSE;
}

export type Actions = Navigate | LoadData | LoadDataSuccess | LoadDataFail | Add | Edit | AddSuccess | EditSuccess | Fail | Close;
