import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { map } from "rxjs/operators";
import { AppState, Equals } from "../../kernel";
import * as RevokeRestoreAccessActions from "../store/revoke-restore-access.actions";
import * as FromRevokeRestoreAccess from "../store/revoke-restore-access.selectors";
import { combineLatest, Observable, Subscription } from "rxjs";
import { Salon } from "@getvish/model";

@Component({
  selector: "revoke-access-container",
  templateUrl: "revoke-restore-access.container.html",
  styleUrls: ["./revoke-restore-access.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevokeRestoreAccessContainer implements OnDestroy {
  public mode$: Observable<"revoke" | "restore">;

  public salon$: Observable<Salon>;
  public loading$: Observable<boolean>;
  public error$: Observable<Error>;

  public form: UntypedFormGroup;

  public salonSubscription: Subscription;
  public salonIdSubscription: Subscription;

  public salon: Salon;

  public progressMessage$: Observable<string>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute,
    private _fb: UntypedFormBuilder
  ) {
    const salonId$ = _route.paramMap.pipe(map((params) => params.get("id")));

    this.mode$ = _route.data.pipe(map((data) => data.mode));

    this.loading$ = this._store.pipe(select(FromRevokeRestoreAccess.getLoading));
    this.salon$ = this._store.pipe(select(FromRevokeRestoreAccess.getSalon));
    this.error$ = this._store.pipe(select(FromRevokeRestoreAccess.getError));

    this.salonSubscription = this.salon$.subscribe((salon) => {
      this.salon = salon;

      if (salon != null) {
        this.form = this._fb.group({
          slug: [undefined, [Validators.required, Equals(this.salon.slug)]],
        });
      }
    });

    this.salonIdSubscription = salonId$.pipe(map((salonId) => RevokeRestoreAccessActions.load({ salonId }))).subscribe((action) => {
      this._store.dispatch(action);
    });

    this.progressMessage$ = combineLatest([
      this._store.pipe(select(FromRevokeRestoreAccess.getLoading)),
      this._store.pipe(select(FromRevokeRestoreAccess.getRevoking)),
      this._store.pipe(select(FromRevokeRestoreAccess.getRestoring)),
    ]).pipe(
      map(([loading, revoking, restoring]) => {
        if (loading) {
          return "Loading...";
        } else if (revoking) {
          return "Revoking Access...";
        } else if (restoring) {
          return "Restoring Access...";
        }

        return undefined;
      })
    );
  }

  public ngOnDestroy(): void {
    this.salonSubscription.unsubscribe();
    this.salonIdSubscription.unsubscribe();
  }

  public close(): void {
    this._store.dispatch(RevokeRestoreAccessActions.close());
  }

  public revokeAccess(salon: Salon): void {
    this._store.dispatch(RevokeRestoreAccessActions.revokeAccess({ salon }));
  }

  public restoreAccess(salon: Salon): void {
    this._store.dispatch(RevokeRestoreAccessActions.restoreAccess({ salon }));
  }
}
