import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ServiceDescription, ServiceCategory, ServiceDescriptionFlag } from "@getvish/model";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ServiceCategoryGroup, getServiceCategoryGroups } from "../utils/service-categories";

export const isValidCategory =
  (categories: ServiceCategory[] = []) =>
  (control: AbstractControl): ValidationErrors | null => {
    if (control.value != null && !categories.some((category) => category._id === control.value)) {
      return { selectCategory: true };
    }

    return null;
  };

export const displayCategory =
  (categories: ServiceCategory[]) =>
  (categoryId?: string): string | null => {
    const maybeCategory = categories.find((category) => category._id === categoryId);

    return pipe(
      maybeCategory,
      option.fromNullable,
      option.map((category) => category.name),
      option.toNullable
    );
  };

export const filterCategories =
  (categories: ServiceCategory[]) =>
  (filter: string): ServiceCategory[] => {
    const filterValue = filter.toLowerCase();
    return categories.filter((option) => option.name.toLowerCase().includes(filterValue));
  };
interface ServiceForm {
  name: string;
  productAllowance: number;
  categoryId: string;
  flags: ServiceDescriptionFlag[];
}

@Component({
  selector: "service-description-form",
  templateUrl: "service-description-form.component.html",
  styleUrls: ["service-description-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDescriptionFormComponent implements OnInit {
  @Input()
  public service: ServiceDescription;
  @Input()
  public categories: ServiceCategory[];
  @Input()
  public saving: boolean;
  @Input()
  public currency: string;
  @Output()
  public save: EventEmitter<ServiceDescription>;

  public form: UntypedFormGroup;

  public filteredCategories$: Observable<ServiceCategory[]>;
  public filteredCategoryGroups$: Observable<ServiceCategoryGroup[]>;
  public displayCategory = displayCategory;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this._buildForm(this.service);

    this.filteredCategories$ = this.form.controls["categoryId"].valueChanges.pipe(startWith(""), map(filterCategories(this.categories)));
    this.filteredCategoryGroups$ = getServiceCategoryGroups(this.filteredCategories$, this.categories);
  }

  public saveForm(service: ServiceDescription, data: ServiceForm): void {
    if (this.form.valid) {
      this.save.emit({
        ...service,
        ...data,
      });
    }
  }

  private _buildForm(service: ServiceDescription): void {
    this.form = this._fb.group({
      name: [service.name, Validators.required],
      categoryId: [service.categoryId, isValidCategory(this.categories)],
      flags: [service.flags],
    });
  }
}
