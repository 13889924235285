import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { MeasurementUnit, OrderLine } from "@getvish/model";

import { detailExpandAnimation } from "app/kernel/shared/rocket-ui/animations";
import { OrderLineProductDetailsVM } from "../models";
import { uniq } from "ramda";

interface OrderLineVM extends OrderLine {
  totalProductCost: number;
  groupedUsageDetails: {
    name: string;
    usageDetails: OrderLineProductDetailsVM[];
  }[];
}

const calculateTotalProductCostFromOrderLine = (orderLine: OrderLine): number | undefined =>
  orderLine.productUsageDetails.map((item) => item.retailCost).reduce((acc, curr) => acc + curr, 0);

@Component({
  selector: "[orderLines]",
  templateUrl: "order-lines.component.html",
  styleUrls: ["order-lines.component.less"],
  animations: [detailExpandAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLinesComponent implements OnChanges {
  @Input() public orderLines: OrderLine[];
  @Input() public currency: string;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public loading: boolean;
  @Input() public module: string;

  public orderLineVMs: OrderLineVM[];
  public expandedLine: OrderLine | null;
  public columnsToDisplay = ["Service", "Amount", "Extra", "Total"];
  public totalProductCost: number;

  public ngOnChanges(): void {
    this.orderLineVMs = this.orderLines.map((orderLine) => {
      const formulaIds = uniq(orderLine.productUsageDetails.map((usageDetails: OrderLineProductDetailsVM) => usageDetails.formulaId));

      return {
        ...orderLine,
        totalProductCost: calculateTotalProductCostFromOrderLine(orderLine),
        groupedUsageDetails: formulaIds.map((formulaId, idx) => ({
          name: `Bowl ${idx + 1}`,
          usageDetails: orderLine.productUsageDetails.filter(
            (usageDetails: OrderLineProductDetailsVM) => usageDetails.formulaId === formulaId
          ),
        })),
      };
    });
  }

  public lineClicked(orderLine: OrderLine) {
    this.expandedLine = this.expandedLine === orderLine ? undefined : orderLine;
  }
}
