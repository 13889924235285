import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { KernelServicesModule } from "app/kernel";
import { SlideoutPanelModule } from "app/kernel/shared/rocket-ui/slideout-panel";
import { SharedModule } from "../../kernel/shared";
import { EditManufacturerComponent } from "./components";
import { EditManufacturerContainer, ManufacturersContainer } from "./containers";
import { ManufacturersRoutingModule } from "./manufacturers-routing.module";
import { ManufacturersSharedModule } from "./manufacturers-shared.module";

import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faArrowLeft, faCloudUploadAlt, faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { CardModule, PageModule } from "app/+components";
import { ManufacturerEffects } from "./store/manufacturer.effects";
import { manufacturerReducer } from "./store/manufacturer.reducer";

@NgModule({
  imports: [
    SharedModule,
    KernelServicesModule,
    ManufacturersRoutingModule,
    ManufacturersSharedModule,
    SlideoutPanelModule,
    PageModule,
    CardModule,
    StoreModule.forFeature("manufacturers", manufacturerReducer),
    EffectsModule.forFeature([ManufacturerEffects]),
  ],
  declarations: [ManufacturersContainer, EditManufacturerContainer, EditManufacturerComponent],
})
export class ManufacturersModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faArrowLeft, faEllipsisV, faCloudUploadAlt);
  }
}
