import { PasswordResetToken } from "../services";
import * as actions from "./reset-password.actions";

export interface ResetPasswordState {
  token: PasswordResetToken;
  error: string;
}

const initialState: ResetPasswordState = {
  token: undefined,
  error: undefined,
};

export function reducer(state: ResetPasswordState = initialState, action: actions.Actions): ResetPasswordState {
  switch (action.type) {
    case actions.Types.SEND_RESET_PASSWORD_EMAIL_SUCCESS: {
      const token = action.payload.token;

      return {
        ...state,
        token,
        error: undefined,
      };
    }

    case actions.Types.SEND_RESET_PASSWORD_EMAIL_FAIL: {
      const error = action.payload.error;

      return {
        ...state,
        error,
      };
    }

    case actions.Types.CONFIRM_CODE_FAIL: {
      const error = action.payload.error;

      return {
        ...state,
        error,
      };
    }

    case actions.Types.CONFIRM_CODE_SUCCESS: {
      return {
        ...state,
        error: undefined,
      };
    }

    case actions.Types.SET_PASSWORD_FAIL: {
      const error = action.payload.error;

      return {
        ...state,
        error,
      };
    }

    case actions.Types.SET_PASSWORD_SUCCESS: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}
