import { UntypedFormGroup, AbstractControl } from "@angular/forms";
import { isEmpty, includes, isNil, all, or, complement } from "ramda";

/*
 * This validator checks that either ALL of the fields have an input value that is considered 'not empty'
 * OR ALL of the fields have an input value that is considered 'empty'
 * if one input has a 'not empty' value but the others are 'empty', this validator will fail
 * (empty === either value is null/undefined or value is empty as in empty string)
 */
export class AllOrNoneRequiredValidator {
  public static validate(keys: string[]) {
    return (group: UntypedFormGroup): { [key: string]: boolean } => {
      const controls = AllOrNoneRequiredValidator.createControlsList(group, keys);

      return AllOrNoneRequiredValidator._allOrNoneValid(controls) ? null : { allOrNoneRequired: true };
    };
  }

  public static validateArray(controls: AbstractControl[]) {
    return (): { [key: string]: boolean } => {
      return AllOrNoneRequiredValidator._allOrNoneValid(controls) ? null : { allOrNoneRequired: true };
    };
  }

  /**
   * since we are provided with a FormGroup and a list of keys (representing the elements that need to to be validated)
   * we only care about validating the elements given in @keys
   * so filter only the form elements we care about and return them here
   */
  public static createControlsList(group: UntypedFormGroup, keys: string[]): AbstractControl[] {
    return Object.keys(group.controls)
      .map((key) => ({ key, value: group.controls[key] }))
      .filter((entry) => includes(entry.key, keys))
      .map((entry) => entry.value);
  }

  /*
   * Returns true if either all of the controls have an 'empty' value (i.e. their values are either null/undefined or empty)
   * OR if ALL of the controls contains a value which is not 'empty'
   */
  private static _allOrNoneValid(controls: AbstractControl[]): boolean {
    const controlIsEmpty = (control: AbstractControl) => or(isNil(control.value), isEmpty(control.value));

    const controlIsNotEmpty = complement(controlIsEmpty);

    return or(all(controlIsEmpty)(controls), all(controlIsNotEmpty)(controls));
  }
}
