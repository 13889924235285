import { Injectable } from "@angular/core";
import { forkJoin, map, Observable, of, switchMap } from "rxjs";

import { Appointment, FormulaModule, ServiceDescription } from "@getvish/model";
import { AppointmentService, PerformedServicesService } from "app/+front-desk/services";
import { ServiceDescriptionService } from "app/+service-menu/services";
import { FormulaService } from "app/+front-desk/services/formula.service";
import { PerformedServiceVM } from "../model/performed-service";
import { CustomerService } from "app/+customers/services";
import { EmployeeService } from "app/+employees/services";

@Injectable()
export class PerformedServiceListService {
  constructor(
    private _performedServicesService: PerformedServicesService,
    private _appointmentService: AppointmentService,
    private _serviceDescriptionService: ServiceDescriptionService,
    private _formulaService: FormulaService,
    private _customerService: CustomerService,
    private _employeeService: EmployeeService
  ) {}

  public findPerformedServicesForDateRange(
    startDate: number,
    endDate: number,
    employeeId?: string,
    serviceId?: string,
    subset?: "mixable" | "mixed" | "unmixed"
  ): Observable<{ performedServices: PerformedServiceVM[]; appointments: Appointment[] }> {
    const criteria = {
      createdAt: { $gte: startDate, $lte: endDate },
    };

    if (employeeId != null) {
      criteria["employeeId"] = employeeId;
    }

    if (serviceId != null) {
      criteria["serviceDescriptionId"] = serviceId;
    }

    return this._performedServicesService.find(criteria).pipe(
      map((response) => response.records),
      switchMap((performedServices) => {
        if (performedServices.length === 0) {
          return of({ performedServices: [], appointments: [] });
        }
        const serviceDescriptionIds = performedServices.map((performedService) => performedService.serviceDescriptionId);

        return this._serviceDescriptionService.findByIds(serviceDescriptionIds).pipe(
          map((serviceDescriptions) =>
            performedServices.map((performedService) => ({
              ...performedService,
              service: serviceDescriptions.find((serviceDescription) => serviceDescription._id === performedService.serviceDescriptionId),
            }))
          ),
          switchMap((performedServices) => {
            let filteredPerformedServices = performedServices;

            if (subset != null) {
              filteredPerformedServices = performedServices.filter((performedService) => this.isMixable(performedService.service));
            }

            const appointmentIds = filteredPerformedServices.map((performedService) => performedService.appointmentId);

            return this._appointmentService.findNotCancelledByIds(appointmentIds).pipe(
              map((appointments) => {
                const servicesWithNotCancelledAppointments = filteredPerformedServices.filter((performedService) =>
                  appointments.some((appointment) => appointment._id === performedService.appointmentId)
                );

                return { performedServices: servicesWithNotCancelledAppointments, appointments };
              }),
              switchMap(({ performedServices, appointments }) => {
                if (subset === "mixed" || subset === "unmixed") {
                  return this._formulaService
                    .findForPerformedServiceIds(performedServices.map((performedService) => performedService._id))
                    .pipe(
                      map((formulas) => {
                        const { mixed, unmixed } = performedServices.reduce(
                          (acc, performedService) => {
                            const serviceFormulas = formulas.filter((formula) => formula.performedServiceId === performedService._id);

                            if (serviceFormulas.some((formula) => FormulaModule.isMixed(formula))) {
                              acc.mixed.push(performedService);
                            } else {
                              acc.unmixed.push(performedService);
                            }

                            return acc;
                          },
                          { mixed: [], unmixed: [] }
                        );

                        if (subset === "mixed") {
                          return { performedServices: mixed, appointments };
                        } else {
                          return { performedServices: unmixed, appointments };
                        }
                      })
                    );
                } else {
                  return of({ performedServices, appointments });
                }
              }),
              switchMap(({ performedServices, appointments }) => {
                if (performedServices.length === 0) {
                  return of({ performedServices, appointments });
                }

                const customerIds = performedServices.map((appointment) => appointment.customerId);
                const employeeIds = performedServices.map((appointment) => appointment.employeeId);

                const getCustomers$ = this._customerService.findByIds(customerIds);
                const getEmployees$ = this._employeeService.findByIds(employeeIds);

                return forkJoin([getCustomers$, getEmployees$]).pipe(
                  map(([customers, employees]) => {
                    const _customers = customers.reduce((acc, c) => {
                      acc[c._id] = c;
                      return acc;
                    }, {});
                    const _employees = employees.reduce((acc, e) => {
                      acc[e._id] = e;
                      return acc;
                    }, {});

                    return {
                      appointments,
                      performedServices: performedServices.map((performedService) => ({
                        ...performedService,
                        customer: _customers[performedService.customerId],
                        employee: _employees[performedService.employeeId],
                      })),
                    };
                  })
                );
              })
            );
          })
        );
      })
    );

    return of({ performedServices: [], appointments: [] });
  }

  private isMixable(serviceDescription: ServiceDescription): boolean {
    if (serviceDescription == null) {
      return false;
    }

    if (serviceDescription.flags?.includes("EXCLUDE_FROM_ANALYTICS")) {
      return false;
    }

    return serviceDescription.categoryId != null;
  }
}
