import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "page",
  templateUrl: "page.component.html",
  styleUrls: ["page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {
  @Input()
  public pageTitle?: string;

  @Input()
  public showBreadcrumbs: boolean = false;

  @Input()
  public progressText?: string;

  @Input()
  public error?: string;
}
