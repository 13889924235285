<manufacturer-report
  [manufacturerReport]="manufacturerReport$ | async"
  [measurementUnit]="measurementUnit$ | async"
  [loading]="loading$ | async"
  [startDate]="startDate$ | async"
  [endDate]="endDate$ | async"
  [currency]="currency$ | async"
  [timeZone]="timeZone$ | async"
  (updateFilters)="updateFilters($event)"
  (selectManufacturer)="selectManufacturer($event)">
</manufacturer-report>
