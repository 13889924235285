import { Component, ChangeDetectionStrategy, OnChanges, Input } from "@angular/core";
import { pipe } from "fp-ts/function";
import { getOrElse } from "fp-ts/Option";
import { adjust, isEmpty } from "ramda";

import { MixingContainerConfig, MixingContainerVM } from "../../models";

type ColorEntry = {
  _id: string;
  color: string;
  percentage: number;
};

@Component({
  selector: "generic-mixing-container",
  templateUrl: "./generic-mixing-container.component.html",
  styleUrls: ["./generic-mixing-container.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericMixingContainerComponent implements OnChanges {
  @Input() public mixingContainerConfig: MixingContainerConfig;
  @Input() public mixingContainerVM: MixingContainerVM;
  @Input() public clipPathId: string;

  public colorEntries: ColorEntry[];

  public ngOnChanges(): void {
    this.colorEntries = this._calculateColorEntries();
  }

  private _calculateColorEntries(): ColorEntry[] {
    return isEmpty(this.mixingContainerVM.entries) ? this._generateEmptyEntries() : this._generateColorEntries();
  }

  private _generateEmptyEntries() {
    return [
      {
        _id: "topLimit",
        percentage: 1 - this.mixingContainerConfig.topLimit,
        color: this.mixingContainerConfig.topBottomColorHex,
      },
      {
        _id: "empty",
        percentage: 1 - (this.mixingContainerConfig.bottomLimit ?? 0) - (1 - this.mixingContainerConfig.topLimit),
        color: this.mixingContainerConfig.emptyColorHex,
      },
      {
        _id: "bottomLimit",
        percentage: this.mixingContainerConfig.bottomLimit,
        color: this.mixingContainerConfig.topBottomColorHex,
      },
    ].filter((e) => e.percentage > 0);
  }

  private _generateColorEntries() {
    const totalTargetValue = pipe(
      this.mixingContainerVM.containerVolume,
      getOrElse(() => this.mixingContainerConfig.defaultVolume)
    );

    const totalValue = isEmpty(this.mixingContainerVM.entries)
      ? totalTargetValue
      : this.mixingContainerVM.entries.reduce((acc, e) => acc + e.value, 0);

    const weightToTargetRatio = Math.min(totalValue / totalTargetValue, 1);

    const empty = 1 - totalValue / totalTargetValue;

    const genId = (id) => {
      return `${id}_${this.mixingContainerVM.entries.length}`;
    };

    const entries = [
      {
        _id: genId("bottomLimit"),
        percentage: this.mixingContainerConfig.bottomLimit,
        color: this.mixingContainerConfig.topBottomColorHex,
      },
      ...this.mixingContainerVM.entries
        .filter((entry) => entry.value > 0)
        .map((entry) => ({
          _id: genId(entry._id),
          color: entry.color,
          percentage:
            (entry.value / totalValue) *
            weightToTargetRatio *
            (this.mixingContainerConfig.topLimit - this.mixingContainerConfig.bottomLimit),
        })),
      {
        _id: genId("empty"),
        percentage: empty > 0 ? empty * (this.mixingContainerConfig.topLimit - this.mixingContainerConfig.bottomLimit) : 0,
        color: this.mixingContainerConfig.emptyColorHex,
      },
      {
        _id: genId("topLimit"),
        percentage: 1 - this.mixingContainerConfig.topLimit,
        color: this.mixingContainerConfig.topBottomColorHex,
      },
    ]
      .filter((e) => e.percentage > 0 || (!e._id.startsWith("topLimit") && !e._id.startsWith("bottomLimit")))
      .reverse();

    const remaining = 1 - entries.reduce((acc, e) => acc + e.percentage, 0);

    return adjust(entries.length - 1, (e) => ({ ...e, percentage: e.percentage + remaining }), entries);
  }

  public trackById(index: number, item: ColorEntry) {
    return item._id;
  }
}
