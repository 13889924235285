<page [pageTitle]="'Employee Report' + (employeeReport?.employeeName ? ' - ' + employeeReport?.employeeName : '')">
  <div
    class="header-controls"
    card-header>
    <!--
      so, this is all _one_ component, right?
      basically the <date-range> _needs_ the <date-picker> component or else it can't really do its job?
      if that's the case we should just wrap them both up into one component
      so you don't have to remember to always render a <date-picker> via [matTooltip] ...
      and we could do things like automatically close the overlay when the user chooses a date
      right now we'd have to manually do the work from the calling component, which is awkward
    -->
    <mat-menu #datepickerMenu="matMenu">
      <date-picker
        [startDate]="startDate"
        [endDate]="endDate"
        [timeZone]="timeZone"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (update)="updateDateRange.emit($event)"></date-picker>
    </mat-menu>
    <date-range
      [startDate]="startDate"
      [endDate]="endDate"
      [timeZone]="timeZone"
      [matMenuTriggerFor]="datepickerMenu"
      matTooltip="Change date range"></date-range>
    <!--  -->
  </div>
  <ng-container page-body>
    <div *ngIf="reportHasData; else emptyState">
      <summary-report
        *ngIf="summary"
        [currency]="currency"
        [metrics]="summary.summary"
        [enableComplianceTracking]="enableComplianceTracking"></summary-report>
      <div
        class="row gutters"
        style="margin-top: 50px">
        <section class="col col-12">
          <div class="card">
            <header>
              <h3>Services Summary</h3>
              <div class="card-header-controls">
                <a
                  (click)="downloadServicesSummaryReport.emit()"
                  matTooltip="Download report as spreadsheet"
                  class="button secondary transparent">
                  <fa-icon icon="cloud-download-alt"></fa-icon>
                </a>
              </div>
            </header>
            <data-table localStorageId="employee-services-summary">
              <columns>
                <column id="icon"></column>
                <column
                  id="name"
                  title="Name"></column>
                <column
                  id="services"
                  title="Services"
                  [showInColumnManager]="true"></column>
                <column
                  *ngIf="enableComplianceTracking"
                  id="mixed"
                  title="Mixed"
                  [showByDefault]="false"
                  [showInColumnManager]="true"></column>
                <column
                  *ngIf="enableComplianceTracking"
                  id="unmixed"
                  title="Unmixed"
                  [showInColumnManager]="true"></column>
                <column
                  id="productDispensed"
                  title="Product Dispensed"
                  [showInColumnManager]="true"></column>
                <column
                  id="pdperService"
                  title="Per Service"
                  [showInColumnManager]="true"></column>
                <column
                  id="%Reweighed"
                  title="% Reweighed"
                  [showInColumnManager]="true"></column>
                <column
                  id="productWaste"
                  title="Product Waste"
                  [showInColumnManager]="true"></column>
                <column
                  id="estimatedWaste"
                  title="Estimated Waste"
                  [showInColumnManager]="true"></column>
                <column
                  id="ewperService"
                  title="Per Service"
                  [showInColumnManager]="true"></column>
                <column
                  id="productCharges"
                  title="Product Charges"
                  [showInColumnManager]="true"></column>
                <column
                  id="%Waste"
                  title="% Waste"
                  [showInColumnManager]="true"></column>
                <column
                  *ngIf="enableComplianceTracking"
                  id="%Compliance"
                  title="% Compliance"
                  [showInColumnManager]="true"></column>
              </columns>
              <body>
                <row
                  *ngFor="let serviceStatistic of _serviceStatistics"
                  [id]="serviceStatistic.entityId">
                  <cell columnId="icon">
                    <ng-template>
                      <colored-icon-cell
                        [title]="serviceStatistic.entityName"
                        [numChars]="1"></colored-icon-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="name">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          [value]="serviceStatistic.entityName"
                          className="primary">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="services">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          *ngIf="!enableComplianceTracking"
                          [value]="serviceStatistic.metrics.numServicesPerformed">
                        </text-cell-text>
                        <text-cell-text
                          *ngIf="enableComplianceTracking"
                          [value]="serviceStatistic.metrics.numMixableServices">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell
                    *ngIf="enableComplianceTracking"
                    columnId="mixed">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          [value]="serviceStatistic.metrics.numMixedServices"
                          (action)="
                            serviceStatistic.metrics.numMixedServices === 0 ? undefined : navigateToMixed.emit(serviceStatistic.entityId)
                          ">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell
                    *ngIf="enableComplianceTracking"
                    columnId="unmixed">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          [value]="(serviceStatistic.metrics.numMixableServices ?? 0) - (serviceStatistic.metrics.numMixedServices ?? 0)"
                          (action)="
                            (serviceStatistic.metrics.numMixableServices ?? 0) - (serviceStatistic.metrics.numMixedServices ?? 0) === 0
                              ? undefined
                              : navigateToUnmixed.emit(serviceStatistic.entityId)
                          ">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="productDispensed">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          className="primary"
                          [value]="serviceStatistic.metrics.wholesaleCostDollars | currency: currency">
                        </text-cell-text>
                        <text-cell-text
                          className="secondary"
                          [value]="serviceStatistic.metrics.totalAmountGrams | localMeasurementUnit: measurementUnit">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="pdperService">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          className="primary"
                          [value]="serviceStatistic.metrics.productDispensedPerServiceWholesaleCost | currency: currency">
                        </text-cell-text>
                        <text-cell-text
                          className="secondary"
                          [value]="serviceStatistic.metrics.productDispensedPerServiceGrams | localMeasurementUnit: measurementUnit">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="%Reweighed">
                    <ng-template>
                      <text-cell>
                        <text-cell-text [value]="serviceStatistic.metrics.completedFormulaRatio | percent"> </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="productWaste">
                    <ng-template>
                      <text-cell>
                        <text-cell-text [value]="serviceStatistic.metrics.wasteAmountGrams | localMeasurementUnit: measurementUnit">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="estimatedWaste">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          className="primary"
                          [value]="serviceStatistic.metrics.estimatedWasteDollars | currency: currency | default: '-'">
                        </text-cell-text>
                        <text-cell-text
                          className="secondary"
                          [value]="serviceStatistic.metrics.estimatedWaste | localMeasurementUnit: measurementUnit">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="ewperService">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          [value]="serviceStatistic.metrics.estimatedWastePerServiceDollars | currency: currency | default: '-'">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="productCharges">
                    <ng-template>
                      <text-cell>
                        <text-cell-text [value]="serviceStatistic.metrics.productOveruseDollars | currency: currency | default: '-'">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell columnId="%Waste">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          className="primary highlighted"
                          [value]="serviceStatistic.metrics.wasteRatio | percent">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                  <cell
                    *ngIf="enableComplianceTracking"
                    columnId="%Compliance">
                    <ng-template>
                      <text-cell>
                        <text-cell-text
                          className="primary highlighted"
                          [value]="getComplianceRatio(serviceStatistic.metrics) | percent">
                        </text-cell-text>
                      </text-cell>
                    </ng-template>
                  </cell>
                </row>
              </body>
              <empty-state-message
                title="No services here"
                icon="user-circle"
                data-table-empty-state>
                We couldn't find any data for the selected date range, adjust the dates to view data
              </empty-state-message>
            </data-table>
          </div>
        </section>
      </div>

      <div class="row gutters auto">
        <section class="col col-12">
          <div class="card">
            <header style="display: inline-block">
              <h3>Vish Waste History</h3>
            </header>
            <longitudinal-report
              *ngIf="longitudinalReport"
              [report]="longitudinalReport"
              [aggregatedBy]="aggregatedBy"
              [measurementUnit]="measurementUnit"
              [locale]="locale"
              [enableComplianceTracking]="enableComplianceTracking"
              (aggregation)="aggregation.emit($event)">
            </longitudinal-report>
          </div>
        </section>
      </div>
    </div>

    <ng-template #emptyState>
      <empty-state-message
        title="No data here"
        icon="calendar-day">
        We couldn't find any data for the selected range, adjust the dates to view data
      </empty-state-message>
    </ng-template>
  </ng-container>
</page>
