import { Injectable } from "@angular/core";
import { Observable, fromEvent, interval, of } from "rxjs";
import { map, filter, take, mergeMap } from "rxjs/operators";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

interface WindowOptions {
  width?: number;
  height?: number;
  status?: number;
  toolbar?: number;
}
@Injectable()
export class WindowService {
  public online$: Observable<unknown>;

  private _window = window;
  private _location = location;

  constructor() {
    this.online$ = fromEvent(this._window, "online");
  }

  public get isOnline(): boolean {
    return navigator.onLine;
  }

  public scrollTo(x: number, y: number): void {
    this._window.scrollTo(x, y);
  }

  public get(key: string): unknown {
    return this._window[key];
  }

  public get tenantPathName(): string {
    const tenantPath: string = this.pathname.split("/").splice(1, 1).join();
    return tenantPath;
  }

  public get tenantSlug(): string {
    return this.tenantPathName;
  }

  public get tenantPath(): string {
    const tenantPathName = this.tenantPathName;
    return `/${tenantPathName}`;
  }

  public get pathname(): string {
    return this._location.pathname;
  }

  public changeLocation(path: string): void {
    this._location.assign(path);
  }

  public changeTenant(slug: string): void {
    const path = `/${slug}`;

    this.changeLocation(path);
  }

  /**
   * Opens a new browser window but wraps it in an observable which polls the new window
   * to query if it has been closed. Will emit once when the window has been closed by the user
   * @param path
   * @param options
   */
  public openWindowObserved(
    path: string,
    options: { width?: number; height?: number; status?: number; toolbar?: number }
  ): Observable<void> {
    return of(this.openWindow(path, options)).pipe(
      mergeMap((window) => interval(200).pipe(map(() => window.closed))),
      filter((value) => value === true),
      take(1),
      map(() => undefined)
    );
  }

  public openWindow(path: string, options: WindowOptions): Window {
    const _options = pipe(
      option.fromNullable(options),
      option.map((o: WindowOptions) => {
        const heightFragment = pipe(
          option.fromNullable(o.height),
          option.map((h) => `height=${h}`),
          option.getOrElse(() => "")
        );
        const widthFragment = pipe(
          option.fromNullable(o.width),
          option.map((w) => `width=${w}`),
          option.getOrElse(() => "")
        );
        const statusFragment = pipe(
          option.fromNullable(o.status),
          option.map((s) => `status=${s}`),
          option.getOrElse(() => "")
        );
        const toolbarFragment = pipe(
          option.fromNullable(o.toolbar),
          option.map((t) => `toolbar=${t}`),
          option.getOrElse(() => "")
        );

        return [heightFragment, widthFragment, statusFragment, toolbarFragment].join(",");
      }),
      option.toUndefined
    );
    const window = this._window.open(path, "_blank", _options);

    return window;
  }

  public close(): void {
    this._window.close();
  }
}
