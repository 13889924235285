import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { SnackbarService } from "../../services/snackbar.service";
import * as SnackbarActions from "../actions/snackbar.actions";

@Injectable()
export class SnackbarEffects {
  public info$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SnackbarActions.Info>(SnackbarActions.Types.INFO),
        map((action) => action.payload),
        switchMap((payload) => this._snackbarService.info(payload.message, payload.config))
      ),
    { dispatch: false }
  );

  public prompt$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SnackbarActions.Prompt>(SnackbarActions.Types.PROMPT),
        map((action) => action.payload),
        switchMap((payload) => this._snackbarService.prompt(payload.message, payload.action, payload.config))
      ),
    { dispatch: false }
  );

  public dismiss$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SnackbarActions.Dismiss>(SnackbarActions.Types.DISMISS),
        switchMap(() => this._snackbarService.dismiss())
      ),
    { dispatch: false }
  );

  constructor(
    private _actions$: Actions,
    private _snackbarService: SnackbarService
  ) {}
}
