import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { SERVER_CONFIG } from "app/kernel/services/common";
import { ServerConfig } from "app/kernel/models/server-config";

@Injectable()
export class ExportDataSpreadsheetService {
  private _endpointUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(SERVER_CONFIG) serverConfig: ServerConfig
  ) {
    this._endpointUrl = `${serverConfig.httpProtocol}://${serverConfig.hostname}:${serverConfig.port}/api/export/excel`;
  }

  public export(slug: string, authToken: string): Observable<Blob> {
    const headers = { "X-Salon-Slug": slug, "X-Auth-Token": authToken };

    return this._http.get(this._endpointUrl, { headers, responseType: "blob" });
  }
}
