import { ErrorHandler, ModuleWithProviders, NgModule } from "@angular/core";
import { LogRocketManagerService, LogRocketModule } from "./logrocket";
import { CustomErrorHandler } from "./utils/custom-error-handler";

@NgModule({
  imports: [LogRocketModule.forRoot()],
})
export class ReportingModule {
  public static forRoot(): ModuleWithProviders<ReportingModule> {
    return {
      ngModule: ReportingModule,
      providers: [{ provide: ErrorHandler, useClass: CustomErrorHandler, deps: [LogRocketManagerService] }],
    };
  }
}
