import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";

import * as R from "ramda";
import { map } from "rxjs/operators";

import {
  MeasurementUnit,
  MeasurementUnitType,
  ProductType,
  SalonProductModule,
  TargetWeightMetadata,
  TargetWeightType,
} from "@getvish/model";

import { ProductSelectionDialogService } from "app/+product/+product-selection/services/product-selection.service";
import { ProductAllowanceService } from "app/+service-menu/services";
import {
  ProductAllowanceBlueprintIngredientVM,
  ProductAllowanceBlueprintVM,
  ProductAllowanceProduct,
} from "app/kernel/models/product-allowance";
import { toUndefined } from "fp-ts/lib/Option";

@Component({
  selector: "pac-blueprint",
  templateUrl: "blueprint.component.html",
  styleUrls: ["blueprint.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PACBlueprintComponent implements AfterViewInit {
  @Input() public title: string;
  @Input() public blueprintBowl: ProductAllowanceBlueprintVM;
  @Input() public currency: string;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public allowDelete: boolean;
  @Output() public blueprintChange: EventEmitter<ProductAllowanceBlueprintVM>;
  @Output() public delete: EventEmitter<void>;
  @Output() public isAddingProducts: EventEmitter<boolean>;

  public TargetWeightType = TargetWeightType;

  public constructor(
    private _productSelectionDlgSvc: ProductSelectionDialogService,
    private _productAllowanceSvc: ProductAllowanceService,
    private _eleRef: ElementRef
  ) {
    this.blueprintChange = new EventEmitter<ProductAllowanceBlueprintVM>();
    this.delete = new EventEmitter<void>();
    this.isAddingProducts = new EventEmitter<boolean>();
  }

  ngAfterViewInit() {
    if (this.blueprintBowl.scrollIntoView) {
      this._eleRef.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  public getIngredientTypeText(ingredient: ProductAllowanceBlueprintIngredientVM) {
    const type = ingredient.type?.toString();
    return type == null ? "" : `${type.charAt(0).toUpperCase()}${type.substring(1).toLowerCase()}`;
  }

  public getCostPerMeasure(ingredient: ProductAllowanceBlueprintIngredientVM) {
    const retailPrice = SalonProductModule.getRetailPrice(ingredient.product);
    const containerSize = ingredient.product.containerSize;

    if (!retailPrice || !containerSize) {
      return "";
    }

    return retailPrice / containerSize;
  }

  public getTotalProductWeight() {
    return this.blueprintBowl.pigments?.filter((i) => i.weight?.value).reduce((acc, i) => acc + i.weight.value, 0);
  }

  public pigmentChanged(productId: string, ingredient: ProductAllowanceBlueprintIngredientVM) {
    this.blueprintChange.emit({
      ...this.blueprintBowl,
      pigments: this.blueprintBowl.pigments.map((i) => {
        if (i.product._id === productId) {
          return ingredient;
        }

        return i;
      }),
    });
  }

  public developerChanged(productId: string, ingredient: ProductAllowanceBlueprintIngredientVM) {
    this.blueprintChange.emit({
      ...this.blueprintBowl,
      developers: this.blueprintBowl.developers.map((i) => {
        if (i.product._id === productId) {
          return ingredient;
        }

        return i;
      }),
    });
  }

  public deletePigment(productId: string) {
    this.blueprintChange.emit({
      ...this.blueprintBowl,
      pigments: this.blueprintBowl.pigments.filter((i) => i.product._id !== productId),
    });
  }

  public deleteDeveloper(productId: string) {
    this.blueprintChange.emit({
      ...this.blueprintBowl,
      developers: this.blueprintBowl.developers.filter((i) => i.product._id !== productId),
    });
  }

  public addProducts() {
    this.measurementUnit;
    this._addSalonProduct(ProductType.PIGMENT, () => ({
      value: this.measurementUnit.type === MeasurementUnitType.GRAMS ? 30 : this.measurementUnit.convertToBase(1),
      _type: TargetWeightType.ABSOLUTE,
    }));
  }

  public addDeveloper() {
    this._addSalonProduct(ProductType.DEVELOPER, () => ({ value: 1, _type: TargetWeightType.RATIO }));
  }

  private _addSalonProduct(productType: ProductType, weightFunc: (p: ProductAllowanceProduct) => TargetWeightMetadata) {
    const salonProducts$ = this._productSelectionDlgSvc.show({
      mode: productType === ProductType.DEVELOPER ? "DEVELOPER" : "NONDEVELOPER",
      saveButtonText: `Add ${productType === ProductType.DEVELOPER ? "Developer" : "Products"}`,
      disableSelectAll: true,
    });

    salonProducts$.subscribe(({ salonProducts }) => {
      this.isAddingProducts.emit(true);

      this._productAllowanceSvc
        .salonProductsToPAProduct(salonProducts)
        .pipe(map((products) => toUndefined(products)))
        .subscribe((products) => {
          if (products?.length) {
            const key = productType === ProductType.DEVELOPER ? "developers" : "pigments";

            this.blueprintChange.emit({
              ...this.blueprintBowl,
              [key]: R.uniqWith(
                (i1, i2) => i1.product._id === i2.product._id,
                [
                  ...(this.blueprintBowl[key] ?? []),
                  ...products.map((product) => ({
                    product,
                    weight: weightFunc(product),
                    type: productType,
                  })),
                ]
              ),
            });
          }

          this.isAddingProducts.emit(false);
        });
    });
  }
}
