<slideout-panel (close)="close()">
  <div panel-header>Upload Salon Products</div>

  <upload-salon-product-spreadsheet-form
    [loading]="uploading$ | async"
    [errors]="uploadingErrors$ | async"
    [success]="uploadingSuccess$ | async"
    (fileChange)="fileChange($event)"
    (close)="close()"
    panel-body>
  </upload-salon-product-spreadsheet-form>
</slideout-panel>
