<product-report
  [productReport]="productReport$ | async"
  [manufacturer]="manufacturer$ | async"
  [rootCategoryMap]="rootCategoryMap$ | async"
  [products]="salonProducts$ | async"
  [measurementUnit]="measurementUnit$ | async"
  [manufacturers]="manufacturers$ | async"
  [employees]="employees$ | async"
  [loading]="loading$ | async"
  [startDate]="startDate$ | async"
  [endDate]="endDate$ | async"
  [currency]="currency$ | async"
  [timeZone]="timeZone$ | async"
  (updateFilters)="updateFilters($event)">
</product-report>
