import { Manufacturer, Product, ProductCategory, SalonProduct } from "@getvish/model";
import { append, dropLastWhile, not, pipe, takeWhile, uniqBy } from "ramda";

import { createReducer, on } from "@ngrx/store";
import { FilterKey } from "../models/product-selection-vm";
import {
  clearCategories,
  confirmImport,
  deselectProducts,
  importAdditional,
  importProducts,
  importProductsFail,
  importProductsSuccess,
  load,
  loadFail,
  loadProductsForManufacturerFail,
  loadProductsForManufacturerSuccess,
  loadSuccess,
  popToCategory,
  previousStep,
  pushCategory,
  removeAllProductsForManufacturer,
  search,
  selectManufacturer,
  selectProducts,
  setFilters,
  show,
  toggleProduct,
} from "./import-salon-products.actions";

export interface ImportSalonProductsState {
  loading: boolean;
  saving: boolean;
  searchFilter: string;
  flagFilters: FilterKey[];
  manufacturers: Manufacturer[];
  salonProducts: SalonProduct[];
  selectedManufacturer: Manufacturer;
  availableProducts: Product[];
  availableCategories: ProductCategory[];
  selectedProducts: { [manufacturerId: string]: Product[] };
  selectedProductCategories: ProductCategory[];
  importStep: number;
  previousStep?: number;
  hasSeenConfirm: boolean;
}

const initialState: ImportSalonProductsState = {
  loading: false,
  saving: false,
  searchFilter: undefined,
  flagFilters: [],
  manufacturers: [],
  salonProducts: [],
  availableProducts: [],
  availableCategories: [],
  selectedManufacturer: undefined,
  selectedProducts: {},
  selectedProductCategories: [],
  importStep: 0,
  previousStep: undefined,
  hasSeenConfirm: false,
};

export const reducer = createReducer(
  initialState,
  on(show, () => ({
    ...initialState,
  })),
  on(load, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadSuccess, (state, { manufacturers, salonProducts }) => ({
    ...state,
    loading: false,
    manufacturers,
    salonProducts,
  })),
  on(loadFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(loadProductsForManufacturerSuccess, (state, { products, categories }) => ({
    ...state,
    availableProducts: products,
    availableCategories: categories,
    loading: false,
  })),
  on(loadProductsForManufacturerFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(selectManufacturer, (state, { manufacturer }) => ({
    ...state,
    importStep: 1,
    previousStep: state.importStep,
    loading: true,
    selectedManufacturer: manufacturer,
    availableProducts: [],
    availableCategories: [],
    selectedProductCategories: [],
    searchFilter: undefined,
  })),
  on(confirmImport, (state) => ({
    ...state,
    hasSelectedProducts: true,
    importStep: 2,
    previousStep: state.importStep,
    hasSeenConfirm: true,
  })),
  on(previousStep, (state) => ({
    ...state,
    importStep: state.previousStep ?? 0,
    previousStep: state.importStep === 0 ? state.previousStep : state.importStep,
    searchFilter: undefined,
  })),
  on(importProducts, (state) => ({
    ...state,
    saving: true,
  })),
  on(importProductsSuccess, (state) => ({
    ...state,
    saving: false,
  })),
  on(importProductsFail, (state) => ({
    ...state,
    saving: false,
  })),
  on(importAdditional, (state) => ({
    ...state,
    importStep: 0,
    previousStep: state.importStep,
    searchFilter: undefined,
  })),
  on(setFilters, (state, { filters }) => ({
    ...state,
    flagFilters: filters,
  })),
  on(search, (state, { filter }) => ({
    ...state,
    searchFilter: filter,
  })),
  on(selectProducts, (state, { products }) => {
    const selectedProductsForManufacturer = state.selectedProducts[state.selectedManufacturer._id] || [];
    const selectedProducts = uniqBy((product) => product._id, [...selectedProductsForManufacturer, ...products]);

    return {
      ...state,
      selectedProducts: {
        ...state.selectedProducts,
        [state.selectedManufacturer._id]: selectedProducts,
      },
    };
  }),
  on(deselectProducts, (state, { products }) => {
    const selectedProductsForManufacturer = state.selectedProducts[state.selectedManufacturer._id] || [];
    const productIds = products.map((product) => product._id);

    return {
      ...state,
      selectedProducts: {
        ...state.selectedProducts,
        [state.selectedManufacturer._id]: selectedProductsForManufacturer.filter((product) => not(productIds.includes(product._id))),
      },
    };
  }),
  on(toggleProduct, (state, { product }) => {
    const selectedProducts = state.selectedProducts[state.selectedManufacturer._id] || [];

    const updatedProducts = selectedProducts.some((selectedProduct) => product._id === selectedProduct._id)
      ? selectedProducts.filter((selectedProduct) => selectedProduct._id !== product._id)
      : [...selectedProducts, product];

    const updatedSelectedProducts = {
      ...state.selectedProducts,
      [state.selectedManufacturer._id]: updatedProducts,
    };

    return {
      ...state,
      saving: false,
      selectedProducts: updatedSelectedProducts,
    };
  }),
  on(pushCategory, (state, { category }) => {
    const selectedProductCategories = pipe(
      // if a category is being pushed, we'll want to make sure that we're not appending that category twice
      // and, at the same time, because a category could potentially be pushed to any point in the stack
      // we'll want to remove any categories _up to and including_ the category that's being pushed
      // example:
      // Currently-selected categories: [ A, B, C, D ]
      // Category B is selected/pushed (i.e. the user wants to "jump to" category B)
      // we'll want to remove all categories "up to and including" B, so we're left with [ A ]
      // then we'll append category B, so we end up with [ A, B ]
      // this allows us to both cover the case where the user wants to "jump back up to" category B
      // and also the case where the user wants to push a new category onto the stack (let's say E)
      takeWhile<ProductCategory>((selectedCategory) => {
        return selectedCategory._id !== category._id;
      }),
      dropLastWhile<ProductCategory>((selectedCategory) => {
        return selectedCategory.parentCategoryId === category.parentCategoryId;
      }),
      append(category)
    )(state.selectedProductCategories);

    return { ...state, selectedProductCategories };
  }),
  on(popToCategory, (state, { categoryId }) => ({
    ...state,
    selectedProductCategories: dropLastWhile<ProductCategory>((productCategory) => productCategory._id !== categoryId)(
      state.selectedProductCategories
    ),
  })),
  on(removeAllProductsForManufacturer, (state, { manufacturer }) => ({
    ...state,
    selectedProducts: {
      ...state.selectedProducts,
      [manufacturer._id]: [],
    },
  })),
  on(clearCategories, (state) => ({
    ...state,
    selectedProductCategories: [],
  }))
);

export const featureKey = "importSalonProducts";
