import { createSelector, createFeatureSelector } from "@ngrx/store";

import { ProductReportState } from "./product-reports.reducer";

/*** SELECTORS ***/
export const getState = createFeatureSelector<ProductReportState>("productReport");

export const getProductReport = createSelector(getState, (state) => state.report);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
export const getStartDate = createSelector(getState, (state) => state.startDate);
export const getEndDate = createSelector(getState, (state) => state.endDate);
export const getManufacturerId = createSelector(getState, (state) => state.manufacturerId);
