import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppointmentsContainer, OrderPanelContainer, ScheduleAppointmentContainer } from "./containers";
import { ResolveAppointmentsContainer } from "./containers/resolve-appointments.container";

export const routes = [
  { path: "", component: AppointmentsContainer },
  { path: "new", component: ScheduleAppointmentContainer, outlet: "panel" },
  { path: "order/:id", component: OrderPanelContainer, outlet: "panel" },
  { path: "resolve", component: ResolveAppointmentsContainer, outlet: "panel" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class AppointmentsRoutingModule {}
