import { NgModule } from "@angular/core";
import { PageComponent } from "./components/page.component";
import { SharedModule } from "app/kernel/shared";
import { ProgressIndicatorModule } from "../+progress-indicator";
import { BreadcrumbComponent } from "xng-breadcrumb";

@NgModule({
  declarations: [PageComponent],
  imports: [ProgressIndicatorModule, SharedModule, BreadcrumbComponent],
  exports: [PageComponent],
})
export class PageModule {}
