import { Component, Input, ChangeDetectionStrategy, OnChanges } from "@angular/core";
import { ReportStatistic } from "../../kernel/models/report-statistic";
import { map, take, isNil, sortBy, reverse, pipe as ramdaPipe } from "ramda";
import { MeasurementUnit, ProductCategory, SalonProduct } from "@getvish/model";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

interface ReportStatisticWithProduct extends ReportStatistic {
  product?: SalonProduct;
}

@Component({
  selector: "product-report-table",
  templateUrl: "product-report-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductReportTableComponent implements OnChanges {
  @Input() public take?: number;
  @Input() public statistics: ReportStatistic[];
  @Input() public salonProducts: SalonProduct[];
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public rootCategoryMap: Map<string, ProductCategory>;
  @Input() public currency: string;

  public _statistics: ReportStatisticWithProduct[];

  public ngOnChanges(): void {
    if (isNil(this.statistics)) {
      return;
    }

    const takeNum: number = pipe(
      option.fromNullable(this.take),
      option.getOrElse(() => Number.MAX_SAFE_INTEGER)
    );

    const sortItems = sortBy((item: ReportStatistic) => item.metrics.wholesaleCostDollars);
    const reverseItems = (items: ReportStatistic[]) => reverse(items);
    const mappingFn = (_statistics: ReportStatistic[]) => map((item: ReportStatistic) => item)(_statistics);

    const mapWithProduct = (products: SalonProduct[]) => (_statistics: ReportStatistic[]) =>
      map((item: ReportStatistic) => {
        const product = products.find((_product) => _product._id === item.entityId);

        return {
          ...item,
          product,
        };
      })(_statistics);

    this._statistics = ramdaPipe(sortItems, reverseItems, take(takeNum), mappingFn, mapWithProduct(this.salonProducts))(this.statistics);
  }
}
