<data-table
  [selections]="selections"
  [loading]="loading">
  <columns>
    <column id="selection"></column>
    <column id="icon"></column>
    <column
      id="name"
      title="Name">
      <column-sort
        [direction]="sortDirection"
        (change)="updateSort($event.direction)">
      </column-sort>
    </column>
    <column id="flags"></column>
    <column id="menu"></column>
  </columns>
  <body>
    <row
      *ngFor="let product of productVms; trackBy: productRowTrackBy"
      [id]="product._id">
      <cell columnId="selection">
        <ng-template>
          <selection-cell
            [checked]="product.selected"
            (change)="selectProduct(product)"></selection-cell>
        </ng-template>
      </cell>
      <cell columnId="icon">
        <ng-template>
          <colored-dot
            class="cell-minimal-width"
            [color]="product.hexColorCode"
            size="large"></colored-dot>
        </ng-template>
      </cell>
      <cell columnId="name">
        <ng-template>
          <text-cell>
            <text-cell-text
              [value]="product.name"
              [title]="product.name"
              className="primary"
              (action)="editProduct(product)">
            </text-cell-text>
            <text-cell-text
              [value]="product.category?.name"
              className="secondary">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell
        columnId="flags"
        className="flags">
        <ng-template>
          <text-cell class="cell-minimal-width">
            <text-cell-text
              *ngFor="let flag of product.flags"
              [value]="flag"
              [className]="'label upper flag ' + (flag | lowercase)">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="menu">
        <ng-template>
          <menu-cell>
            <menu-cell-item
              title="Edit"
              (action)="editProduct(product)">
            </menu-cell-item>
            <menu-cell-item
              title="Discontinue"
              (action)="discontinueProduct(product)">
            </menu-cell-item>
          </menu-cell>
        </ng-template>
      </cell>
    </row>
  </body>
</data-table>
