import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MeasurementUnit, Order } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { and, isNil, not } from "ramda";
import { Observable, Subscription, combineLatest, zip } from "rxjs";
import { map } from "rxjs/operators";
import { AppState } from "../../kernel/store";

import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";
import * as actions from "../store/order.actions";
import * as fromOrder from "../store/order.selectors";
import { AppointmentVM } from "../models/appointment";

@Component({
  selector: "order-container",
  templateUrl: "./order.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderContainer implements OnDestroy {
  @Output() public resolve: EventEmitter<AppointmentVM> = new EventEmitter<AppointmentVM>();

  public order$: Observable<Order>;
  public appointment$: Observable<AppointmentVM>;
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public currency$: Observable<string>;
  public timeZone$: Observable<string>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public checkingOut$: Observable<boolean>;
  public reactivating$: Observable<boolean>;
  public resolving$: Observable<boolean>;
  public module$: Observable<string>;
  public _shouldRenderForm$: Observable<boolean>;
  private idParamSubscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    private _route: ActivatedRoute
  ) {
    this.loading$ = _store.pipe(select(fromOrder.getLoading));
    this.error$ = _store.pipe(select(fromOrder.getError));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);
    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));
    this.checkingOut$ = _store.pipe(select(fromOrder.getIsCheckingOut));
    this.reactivating$ = _store.pipe(select(fromOrder.getIsReactivating));
    this.resolving$ = _store.pipe(select(fromOrder.getIsResolving));
    this._shouldRenderForm$ = combineLatest([this.loading$, this.error$]).pipe(map(([loading, error]) => and(not(loading), isNil(error))));

    const id$: Observable<string> = this._route.paramMap.pipe(map((params) => params.get("id")));

    this.module$ = this._store.pipe(select(fromOrder.getModule));

    this.order$ = this._store.pipe(select(fromOrder.getSelectedOrder));
    this.appointment$ = this._store.pipe(select(fromOrder.getSelectedAppointment));

    this.idParamSubscription = zip(id$, this.module$)
      .pipe(
        map(([id, module]) => (module === "sales" ? actions.load({ id }) : actions.generateOrLoadForAppointment({ appointmentId: id })))
      )
      .subscribe((action) => {
        this._store.dispatch(action);
      });
  }

  public checkout(order: Order): void {
    this._store.dispatch(actions.checkout({ order }));
  }

  public reactivate(order: Order): void {
    this._store.dispatch(actions.reactivate({ order }));
  }

  public ngOnDestroy(): void {
    this.idParamSubscription.unsubscribe();
  }
}
