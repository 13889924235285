import { Metrics } from "./metrics";
import { fromNullable, fold } from "fp-ts/Option";
import { pipe } from "fp-ts/function";

export class SalonReport {
  public _id: string;
  public interval: string;
  public startDate: number;
  public endDate: number;
  public summary: Metrics;
  public employeeIds: string[];
  public _type: string;
}

export const salonReportIsEmpty = (value: SalonReport): boolean =>
  pipe(
    value,
    fromNullable,
    fold(
      () => true, // if undefined we'll consider the report empty
      (report) => report.summary.numServicesPerformed === 0 // otherwise test if there are any performed services counted in this report
    )
  );
