import { Salon } from "@getvish/model";

import * as actions from "./import-salon-data.actions";

export interface State {
  salon: Salon;
  exporting: boolean;
  step: number;
  uploading: boolean;
  reports: { [key: string]: { success: boolean } };
  loading: boolean;
  error: string;
}

const initialState: State = {
  salon: undefined,
  exporting: false,
  step: 1,
  uploading: false,
  reports: undefined,
  loading: undefined,
  error: undefined,
};

export function reducer(state: State = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.Types.NAVIGATE: {
      return {
        ...initialState,
      };
    }

    case actions.Types.LOAD_SALON: {
      const salon = null;
      const step = 1;
      const loading = true;

      return {
        ...state,
        salon,
        step,
        loading,
      };
    }

    case actions.Types.LOAD_SALON_SUCCESS: {
      const salon = action.payload.salon;
      const loading = false;

      return {
        ...state,
        salon,
        loading,
      };
    }

    case actions.Types.LOAD_SALON_FAIL: {
      const loading = false;
      const salon = null;
      const error = action.payload.error;

      return {
        ...state,
        loading,
        error,
        salon,
      };
    }

    case actions.Types.EXPORT_DATA: {
      const exporting = true;

      return {
        ...state,
        exporting,
      };
    }

    case actions.Types.EXPORT_DATA_SUCCESS: {
      const exporting = false;

      return {
        ...state,
        exporting,
      };
    }

    case actions.Types.UPLOAD_CSV: {
      const uploading = true;

      return {
        ...state,
        uploading,
      };
    }

    case actions.Types.UPLOAD_CSV_SUCCESS: {
      const uploading = false;
      const key = action.payload.type;
      const report = { [key]: { success: true } };

      const reports = {
        ...state.reports,
        ...report,
      };

      return {
        ...state,
        uploading,
        reports,
      };
    }

    case actions.Types.UPLOAD_CSV_FAIL: {
      const uploading = false;
      const error = action.payload.error;

      return {
        ...state,
        uploading,
        error,
      };
    }

    case actions.Types.INITIALIZE_SESSION:
    case actions.Types.RUN_SESSION: {
      const loading = true;

      return {
        ...state,
        loading,
      };
    }

    case actions.Types.INITIALIZE_SESSION_SUCCESS:
    case actions.Types.RUN_SESSION_SUCCESS: {
      const loading = false;

      return {
        ...state,
        loading,
      };
    }

    case actions.Types.NEXT: {
      const step = state.step + 1;

      return {
        ...state,
        step,
      };
    }

    case actions.Types.DONE: {
      const step = 1;

      return {
        ...state,
        step,
      };
    }

    default: {
      return state;
    }
  }
}
