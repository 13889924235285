import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";

import { MeasurementUnit, ServiceDescription } from "@getvish/model";
import { AppState } from "app/kernel";

import * as fromSalonConfig from "app/+salon-config/store/salon-config.selectors";

import { ProductAllowanceBlueprintVM, ProductAllowanceVM } from "app/kernel/models/product-allowance";
import * as PACalculatorActions from "../store/product-allowance-calculator.actions";
import * as fromPACalculator from "../store/product-allowance-calculator.selectors";
import { calculateProductAllowanceTotal } from "../utils";

@Component({
  selector: "product-allowance-calculator-container",
  templateUrl: "product-allowance-calculator.container.html",
  styleUrls: ["product-allowance-calculator.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAllowanceCalculatorContainer implements OnInit {
  public serviceDescriptions$: Observable<ServiceDescription[]>;
  private productAllowance$: Observable<ProductAllowanceVM>;

  public measurementUnit$: Observable<MeasurementUnit>;
  public currency$: Observable<string>;

  public loading$: Observable<boolean>;
  public saving$: Observable<boolean>;
  public error$: Observable<string>;

  private isLoading: boolean;
  public isSaving: boolean;
  public isAddingProducts: boolean;

  public productAllowance: ProductAllowanceVM;
  public title: string = "Product Allowance";

  constructor(
    private ref: ChangeDetectorRef,
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));

    this.loading$ = _store.pipe(select(fromPACalculator.getLoading));
    this.saving$ = _store.pipe(select(fromPACalculator.getSaving));
    this.error$ = _store.pipe(select(fromPACalculator.getError));

    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.serviceDescriptions$ = _store.pipe(select(fromPACalculator.getServices));
    this.productAllowance$ = _store.pipe(select(fromPACalculator.getProductAllowance));

    _route.queryParams
      .pipe(
        map((params) => params.ids),
        take(1),
        map((ids) => new PACalculatorActions.LoadData({ ids: ids.split(",").map((id) => id.trim()) }))
      )
      .subscribe((action) => _store.dispatch(action));
  }

  ngOnInit(): void {
    this.serviceDescriptions$.subscribe((serviceDescriptions) => {
      if (serviceDescriptions?.length > 1) {
        this.title = `Product Allowance: ${serviceDescriptions.length} Services`;
      } else if (serviceDescriptions?.length === 1 && serviceDescriptions[0]?.name) {
        this.title = `Product Allowance: ${serviceDescriptions[0].name}`;
      }
    });

    this.productAllowance$.subscribe((productAllowance) => (this.productAllowance = productAllowance));
    this.loading$.subscribe((_loading) => {
      this.isLoading = _loading;
      this.ref.markForCheck();
    });
    this.saving$.subscribe((_saving) => {
      this.isSaving = _saving;
      this.ref.markForCheck();
    });
  }

  public getProgressText() {
    if (this.isLoading) {
      return "Loading...";
    }

    if (this.isSaving) {
      return "Saving...";
    }

    if (this.isAddingProducts) {
      return "Adding Product(s)...";
    }
  }

  public setIsAddingProducts(isAddingProducts) {
    this.isAddingProducts = isAddingProducts;
    this.ref.markForCheck();
  }

  public addBowl(): void {
    this._store.dispatch(new PACalculatorActions.AddBowl());
  }

  public blueprintChanged(idx: number, bowl: ProductAllowanceBlueprintVM) {
    this._store.dispatch(new PACalculatorActions.UpdateBlueprint({ idx, bowl }));
  }

  public deleteBlueprint(idx: number) {
    this._store.dispatch(new PACalculatorActions.DeleteBlueprint(idx));
  }

  public blueprintTrackFn(idx: number, bowl: ProductAllowanceBlueprintVM) {
    return bowl._id;
  }

  public save(): void {
    this._store.dispatch(new PACalculatorActions.SaveService());
  }

  public calculateProductAllowanceTotal(productAllowance: ProductAllowanceVM) {
    if (!productAllowance?.blueprints?.length) {
      return this.serviceDescriptions$.pipe(map((sd) => sd?.[0]?.productAllowance ?? 0));
    }

    return of(calculateProductAllowanceTotal(productAllowance));
  }
}
