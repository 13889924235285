import * as productCategory from "./product-category.actions";

import { PagingMetadata, JsonObject } from "@getvish/stockpile";
import { ProductCategory } from "@getvish/model";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { includes, reject, not, isNil, append } from "ramda";

export interface ProductCategoryState {
  loading: boolean;
  saving: boolean;
  records: ProductCategory[];
  paging: PagingMetadata;
  selected: string;
  criteria: JsonObject;
  sort: JsonObject;
  selectedManufacturerId: string;
  selectedCategoryIds: string[];
  newMetadata: {
    parentCategoryId?: string;
    manufacturerId?: string;
  };
}

const initialState: ProductCategoryState = {
  loading: false,
  saving: false,
  records: [],
  paging: undefined,
  selected: undefined,
  criteria: undefined,
  sort: undefined,
  selectedCategoryIds: undefined,
  selectedManufacturerId: undefined,
  newMetadata: undefined,
};

export function productCategoryReducer(state: ProductCategoryState = initialState, action: productCategory.Actions): ProductCategoryState {
  switch (action.type) {
    case productCategory.Types.LOAD_ALL: {
      const loading = true;
      const { criteria, sort } = action.payload;

      return {
        ...state,
        loading,
        criteria,
        sort,
      };
    }

    case productCategory.Types.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload,
        paging: action.paging,
      };
    }

    case productCategory.Types.NEW: {
      const parentCategoryId = pipe(
        option.fromNullable(action.payload),
        option.chain((payload) => option.fromNullable(payload.parentCategory)),
        option.map((category) => category._id),
        option.toUndefined
      );

      const manufacturerId = pipe(
        option.fromNullable(action.payload),
        option.chain((payload) => {
          return not(isNil(payload.parentCategory))
            ? pipe(option.some(payload.parentCategory.manufacturerId))
            : pipe(option.fromNullable(payload.manufacturerId));
        }),
        option.toUndefined
      );

      const newMetadata = { manufacturerId, parentCategoryId };

      return {
        ...state,
        newMetadata,
      };
    }

    case productCategory.Types.EDIT: {
      // explicitly set @manufacturerId and @parentCategoryId to undefined
      // just to make sure, since they should be taken from the ProductCategory being edited, not the store
      // see https://professor-frink.getvish.com/vish/vish-webapp/issues/41

      const manufacturerId = undefined;
      const parentCategoryId = undefined;

      const newMetadata = { manufacturerId, parentCategoryId };

      return {
        ...state,
        newMetadata,
      };
    }

    case productCategory.Types.EXPAND_ALL_CATEGORIES: {
      const selectedCategoryIds = state.records.map((record) => record._id);

      return {
        ...state,
        selectedCategoryIds,
      };
    }

    case productCategory.Types.COLLAPSE_ALL_CATEGORIES: {
      const selectedCategoryIds = [];

      return {
        ...state,
        selectedCategoryIds,
      };
    }

    case productCategory.Types.TOGGLE_CATEGORY: {
      const { categoryId } = action.payload;
      const currentSelectedCategoryIds = pipe(
        option.fromNullable(state.selectedCategoryIds),
        option.getOrElse(() => [])
      );
      const isSelected = includes(categoryId, currentSelectedCategoryIds);

      const selectedCategoryIds = isSelected
        ? reject((_id) => _id === categoryId, currentSelectedCategoryIds)
        : [...currentSelectedCategoryIds, categoryId];

      return {
        ...state,
        selectedCategoryIds,
      };
    }

    case productCategory.Types.ADD_SUCCESS: {
      return {
        ...state,
        records: append(action.payload, state.records),
      };
    }

    case productCategory.Types.UPDATE_SUCCESS: {
      return {
        ...state,
        records: state.records.map((r) => (r._id === action.payload._id ? action.payload : r)),
      };
    }

    case productCategory.Types.SELECT_MANUFACTURER: {
      const selectedManufacturerId = action.payload.manufacturer._id;
      const newMetadata = {
        ...state.newMetadata,
        manufacturerId: selectedManufacturerId,
      };

      return {
        ...state,
        selectedManufacturerId,
        newMetadata,
      };
    }

    case productCategory.Types.CLEAR_MANUFACTURER: {
      const selectedManufacturerId = undefined;
      const newMetadata = {
        ...state.newMetadata,
        manufacturerId: selectedManufacturerId,
      };
      const records = [];

      return {
        ...state,
        selectedManufacturerId,
        newMetadata,
        records,
      };
    }

    case productCategory.Types.UPDATE_PRODUCT_CATEGORIES: {
      return {
        ...state,
        saving: true,
      };
    }

    case productCategory.Types.UPDATE_PRODUCT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        saving: false,
        records: state.records.map((p) => ({
          ...p,
          order: action.payload.categories.find((_p) => _p._id === p._id)?.order ?? p.order,
        })),
      };
    }

    case productCategory.Types.UPDATE_PRODUCT_CATEGORIES_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    default: {
      return state;
    }
  }
}
