<div>
  <form
    [formGroup]="form"
    (submit)="saveSalon(salon, form.value)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Slug</mat-label>
      <input
        matInput
        formControlName="slug" />
      <mat-error *ngIf="form.get('slug').hasError('isValidSlug')">Invalid slug, only alphanumerics and -_ allowed</mat-error>
    </mat-form-field>
    <div formGroupName="billing">
      <mat-label>Stripe Customer ID</mat-label>
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="customerId" />
      </mat-form-field>
    </div>
  </form>

  <div
    class="controls"
    style="position: relative; margin: 0 10%">
    <button
      (click)="saveSalon(salon, form.value)"
      [disabled]="saving || !form.valid"
      type="button"
      class="button primary"
      style="float: right; width: 100%; padding-top: 12px; padding-bottom: 12px">
      Save
    </button>
  </div>
</div>
