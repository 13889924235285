<div class="content">
  <p>Please enter your email address below and a verification code will be sent to you</p>

  <alert-component
    *ngIf="error"
    type="danger">
    {{ error }}
  </alert-component>

  <form
    [formGroup]="form"
    (submit)="submit(form.value)">
    <mat-form-field class="full-width">
      <mat-label>Email Address</mat-label>
      <input
        matInput
        formControlName="email" />
      <mat-error *ngIf="form.get('email').hasError('required')">Required</mat-error>
      <mat-error *ngIf="form.get('email').hasError('email')">Please enter a valid email address</mat-error>
    </mat-form-field>

    <div class="controls">
      <button
        type="submit"
        [disabled]="form.invalid"
        class="button primary">
        Next
      </button>
    </div>
  </form>
  <span class="highlighted"
    >If you can't remember your login/email address, or are otherwise having trouble resetting your password, please
    <a
      href="https://docs.getvish.com/docs/2.0/contact-us"
      class="highlighted"
      external
      >contact support.</a
    ></span
  >
</div>
