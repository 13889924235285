import { Manufacturer, SalonProduct } from "@getvish/model";
import { pricingIncomplete } from "../../common/utils";

export interface ManufacturerVm extends Manufacturer {
  isPricingIncomplete: boolean;
  numProductsImported: number;
}

export const fromManufacturer = (salonProducts?: SalonProduct[]) => {
  const manufacturerDetails = salonProducts?.reduce(
    (acc, sp) => {
      if (acc[sp.manufacturerId] == null) {
        acc[sp.manufacturerId] = { count: 0, incompletePricing: false };
      }

      ++acc[sp.manufacturerId].count;

      if (pricingIncomplete(sp)) {
        acc[sp.manufacturerId].incompletePricing = true;
      }

      return acc;
    },
    {} as { [manufacturerId: string]: { count: number; incompletePricing: boolean } }
  );

  return (manufacturer: Manufacturer): ManufacturerVm => {
    return {
      ...manufacturer,
      isPricingIncomplete: manufacturerDetails?.[manufacturer._id]?.incompletePricing ?? false,
      numProductsImported: manufacturerDetails?.[manufacturer._id]?.count ?? 0,
    };
  };
};

export const getFilterByIncompletePricing =
  (hasIncompletePricingFilter: boolean) =>
  (manufacturer: ManufacturerVm): boolean =>
    hasIncompletePricingFilter === true ? manufacturer.isPricingIncomplete : true;
