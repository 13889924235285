import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { fromNullable, getOrElse } from "fp-ts/Option";
import { pipe } from "fp-ts/function";

@Component({
  selector: "search-input",
  templateUrl: "search-input.component.html",
  styleUrls: ["search-input.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements AfterViewInit, OnChanges {
  @Input() public placeholder: string;
  @Input() public searchFilter: string;
  @Input() public minLength: number = 0;
  @Input() public type: string = "text";
  @Input() public autoFocus: boolean = false;
  @Output() public filter: EventEmitter<string>;

  @ViewChild("search") inputSearch: ElementRef;

  public value: string | null;

  constructor() {
    this.filter = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    this.value = pipe(
      fromNullable(this.searchFilter),
      getOrElse(() => null)
    );
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputSearch.nativeElement.focus();
      }, 0);
    }
  }

  public setFilter(event: Event): void {
    const filter = (event.target as HTMLInputElement).value;

    if (filter.length >= this.minLength) {
      this.filter.emit(filter);
    }
    if (filter.length === 0) {
      this.filter.emit(null);
    }
  }

  public clearSearch(element: HTMLInputElement): void {
    element.focus();
    this.filter.emit(null);
  }
}
