import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import * as actions from "../store/appointment.actions";
import * as fromAppointment from "../store/appointment.selectors";
import { Observable } from "rxjs";
import { AppointmentVM } from "../models/appointment";
import * as fromSalonConfig from "app/+salon-config/store/salon-config.selectors";
import { ServiceDescription } from "@getvish/model";

@Component({
  selector: "resolve-appointments-container",
  templateUrl: "resolve-appointments.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResolveAppointmentsContainer {
  public loading$: Observable<boolean>;
  public appointments$: Observable<AppointmentVM[]>;
  public serviceDescriptions$: Observable<ServiceDescription[]>;
  public timeZone$: Observable<string>;

  constructor(private _store: Store<AppState>) {
    _store.dispatch(new actions.LoadResolve());

    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);

    this.loading$ = _store.select(fromAppointment.getResolveLoading);
    this.appointments$ = _store.select(fromAppointment.getResolveAppointments);
    this.serviceDescriptions$ = _store.select(fromAppointment.getResolveServiceDescriptions);
  }

  public resolve(appointments: AppointmentVM[]) {
    this._store.dispatch(actions.Resolve({ appointments }));
    this._store.dispatch(actions.CloseResolve());
  }

  public close() {
    this._store.dispatch(actions.CloseResolve());
  }
}
