<div class="search-wrapper">
  <ng-content></ng-content>
  <div class="icon-container">
    <mat-spinner
      *ngIf="isSearching"
      diameter="20"></mat-spinner>
    <button
      *ngIf="showClearElement$ | async"
      (click)="clearSearch()"
      class="button transparent">
      <fa-icon icon="times-circle"></fa-icon>
    </button>
    <fa-icon
      *ngIf="(showClearElement$ | async) !== true"
      icon="search"></fa-icon>
  </div>
  <div></div>
</div>
