import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ServiceCategory, ServiceDescription } from "@getvish/model";
import { uniqBy } from "ramda";
import { displayCategory, filterCategories, isValidCategory } from "./service-description-form.component";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ServiceCategoryGroup, getServiceCategoryGroups } from "../utils/service-categories";

@Component({
  selector: "edit-services",
  templateUrl: "edit-services.component.html",
  styleUrls: ["edit-services.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditServicesComponent implements OnInit {
  @Input() public saving: boolean;
  @Input() public services: ServiceDescription[];
  @Input() public categories: ServiceCategory[];
  @Output() public save: EventEmitter<Partial<ServiceDescription>>;

  public form: UntypedFormGroup;
  public filteredCategories$: Observable<ServiceCategory[]>;
  public filteredCategoryGroups$: Observable<ServiceCategoryGroup[]>;
  public displayCategory = displayCategory;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    const serviceCategories = uniqBy((s) => s.categoryId, this.services);

    this.form = this._fb.group({
      categoryId: [serviceCategories.length === 1 ? serviceCategories[0].categoryId : undefined, isValidCategory(this.categories)],
    });

    this.filteredCategories$ = this.form.controls["categoryId"].valueChanges.pipe(startWith(""), map(filterCategories(this.categories)));

    this.filteredCategoryGroups$ = getServiceCategoryGroups(this.filteredCategories$, this.categories);
  }

  public saveForm(value: Partial<ServiceDescription>): void {
    this.save.emit(value);
  }
}
