<page pageTitle="Salon Configuration">
  <card
    class="tabs-container"
    page-body>
    <ng-container card-body>
      <nav
        mat-tab-nav-bar
        [attr.mat-align-tabs]="'left'"
        [tabPanel]="tabPanel">
        <a
          mat-tab-link
          *ngFor="let link of navLinks"
          [routerLink]="link.path"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive">
          {{ link.label }}
        </a>
      </nav>
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </ng-container>
  </card>
</page>
