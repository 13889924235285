export class Metrics {
  public totalAmountGrams: number;
  public usedAmountGrams: number;
  public wasteAmountGrams: number;
  public wholesaleCostDollars: number;
  public retailCostDollars: number;
  public wasteCostDollars?: number;
  public usedCostDollars: number;
  public revenueDollars?: number;
  public numServicesPerformed: number;
  public numFormulas: number;
  public wasteRatio?: number;
  public usageRatio?: number;
  public completedFormulaRatio?: number;
  public estimatedWaste?: number;
  public estimatedWasteDollars?: number;
  public productDispensedPerServiceGrams: number;
  public productDispensedPerServiceWholesaleCost: number;
  public productOveruseDollars?: number;
  public wastePerServiceDollars?: number;
  public estimatedWastePerServiceDollars?: number;
  public numServices: number;
  public numMixableServices?: number;
  public numMixedServices?: number;
}

export const getComplianceRatio = (metrics: Metrics): number | undefined => {
  if ((metrics.numMixableServices ?? 0) === 0) {
    return undefined;
  }

  return metrics.numMixedServices / metrics.numMixableServices;
};
