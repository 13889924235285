import { ErrorHandler } from "@angular/core";
import { LogRocketManagerService } from "../logrocket";

export class CustomErrorHandler implements ErrorHandler {
  constructor(private _logrocketManager: LogRocketManagerService) {}

  public handleError(error: Error) {
    this._logrocketManager.captureException(error);

    throw error;
  }
}
