import { Component, OnDestroy } from "@angular/core";
import { AppointmentStatus } from "@getvish/model";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { combineLatest, distinctUntilChanged, map, Observable, Subscription } from "rxjs";
import {
  getEmployeeId,
  getEndDate,
  getLoading,
  getPerformedServices,
  getServiceId,
  getSort,
  getStartDate,
  getSubset,
  getTableFilter,
} from "../store/performed-service-list.selectors";
import { JsonObject } from "@getvish/stockpile";
import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";
import { getParamMap } from "app/kernel/store/selectors/router.selectors";
import { parseNumberOptional } from "app/kernel/util";
import { toUndefined } from "fp-ts/lib/Option";
import { initialize, updateSort, updateTableFilter } from "../store/performed-service-list.actions";
import { PerformedServiceVM } from "../model/performed-service";
import { go } from "app/kernel/store/actions/router.actions";

@Component({
  selector: "performed-service-list",
  templateUrl: "./performed-service-list.container.html",
  styleUrls: ["./performed-service-list.container.less"],
})
export class PerformedServiceListContainer implements OnDestroy {
  public performedServices$: Observable<PerformedServiceVM[]>;
  public loading$: Observable<boolean>;
  public sort$: Observable<{ [key: string]: 1 | -1 }>;
  public tableFilter$: Observable<JsonObject>;
  public startDate$: Observable<number>;
  public endDate$: Observable<number>;
  public timeZone$: Observable<string>;
  public pageTitle$: Observable<string>;

  public appointmentStatuses = Object.keys(AppointmentStatus);

  private urlParamsSubscription: Subscription;

  constructor(private _store: Store<AppState>) {
    this.loading$ = _store.select(getLoading);
    this.sort$ = _store.select(getSort) as Observable<{ [key: string]: 1 | -1 }>;
    this.tableFilter$ = _store.select(getTableFilter);
    this.startDate$ = _store.select(getStartDate);
    this.endDate$ = _store.select(getEndDate);

    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);

    this.performedServices$ = combineLatest([_store.select(getPerformedServices), this.sort$, this.tableFilter$]).pipe(
      map(([performedServices, sort, tableFilter]) => this.applyCriteria(performedServices, sort, tableFilter))
    );

    this.pageTitle$ = combineLatest([
      this.performedServices$,
      _store.select(getEmployeeId),
      _store.select(getServiceId),
      _store.select(getSubset),
    ]).pipe(
      map(([performedServices, employeeId, serviceId, subset]) => {
        let title;

        if (subset === "mixable") {
          title = "Mixable Services";
        } else if (subset === "mixed") {
          title = "Mixed Services";
        } else if (subset === "unmixed") {
          title = "Unmixed Services";
        }

        if (employeeId != null) {
          const service = performedServices.find((service) => service.employee?._id === employeeId);

          if (service?.employee != null) {
            title += " - " + service.employee?.firstName + " " + service.employee?.lastName;
          }
        }

        if (serviceId != null) {
          const service = performedServices.find((service) => service.service?._id === serviceId);

          if (service?.service != null) {
            title += " - " + service.service.name;
          }
        }

        return title;
      })
    );

    this.urlParamsSubscription = this._store
      .select(getParamMap)
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((params) => {
        const payload = {
          startDate: toUndefined(parseNumberOptional(params.get("startDate"))),
          endDate: toUndefined(parseNumberOptional(params.get("endDate"))),
          employeeId: params.get("employeeId"),
          serviceId: params.get("serviceId"),
          subset: params.get("subset") as "mixable" | "mixed" | "unmixed",
          filter: params.get("filter"),
          tableFilter: params.get("tableFilter") != null ? JSON.parse(params.get("tableFilter")) : undefined,
          sort: params.get("sort") != null ? JSON.parse(params.get("sort")) : { date: -1 },
        };

        this._store.dispatch(initialize({ payload }));
      });
  }

  public updateSort(sort: JsonObject): void {
    this._store.dispatch(updateSort({ payload: sort }));
  }

  public updateTableFilter(existingTableFilter: JsonObject, _filter: JsonObject): void {
    const newFilter = { ...(existingTableFilter ?? {}) };
    Object.keys(_filter).forEach((k) => {
      newFilter[k] = _filter[k] == null ? undefined : _filter[k];
    });

    this._store.dispatch(updateTableFilter({ payload: newFilter }));
  }

  public navigateToAppointment(appointmentId: string): void {
    this._store.dispatch(
      go({
        path: ["/insights", { outlets: { panel: `order/${appointmentId}` } }],
        extras: { queryParamsHandling: "merge" },
      })
    );
  }

  private applyCriteria(performedServices: PerformedServiceVM[], sort: JsonObject, tableFilter: JsonObject): PerformedServiceVM[] {
    if (performedServices == null || performedServices.length === 0) {
      return [];
    }

    const servicesById = performedServices.reduce((acc, service) => {
      acc[service._id] = service;
      return acc;
    }, {});

    const simplifiedServices = performedServices.map((performedService) => {
      return {
        _id: performedService._id,
        service: performedService.service?.name,
        customerName: performedService.customer?.firstName + " " + performedService.customer?.lastName,
        employeeName: performedService.employee?.firstName + " " + performedService.employee?.lastName,
        date: performedService.createdAt,
      };
    });

    let processedServices = simplifiedServices;

    if (tableFilter != null) {
      processedServices = processedServices.filter((service) => {
        return Object.keys(tableFilter).every((key) => {
          if (tableFilter[key] == null) {
            return true;
          }

          return service[key]?.toLowerCase().includes((tableFilter[key] as string).toLowerCase());
        });
      });
    }

    if (sort != null) {
      const sortKeys = Object.keys(sort);

      processedServices = processedServices.sort((a, b) => {
        return sortKeys.reduce((acc, key) => {
          if (acc !== 0) {
            return acc;
          }

          if (a[key] < b[key]) {
            return -1 * (sort[key] as number);
          } else if (a[key] > b[key]) {
            return 1 * (sort[key] as number);
          } else {
            return 0;
          }
        }, 0);
      });
    }

    return processedServices.map((service) => servicesById[service._id]);
  }

  ngOnDestroy(): void {
    if (this.urlParamsSubscription != null) {
      this.urlParamsSubscription.unsubscribe();
    }
  }
}
