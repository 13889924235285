<mat-card
  class="card"
  (click)="clicked.emit()">
  <div class="icons-container">
    <fa-icon
      class="plus-icon"
      icon="droplet"
      size="3x"></fa-icon>
    <div id="card-text">
      <fa-icon
        class="plus-icon"
        icon="plus"
        size="1x"></fa-icon>
      <span id="add-manufacturer">Add Manufacturer</span>
    </div>
  </div>
</mat-card>
