import { Metrics } from "./metrics";
import { ReportStatistic } from "./report-statistic";
import { fromNullable, fold } from "fp-ts/Option";
import { pipe } from "fp-ts/function";

export class EmployeeReport {
  public _id: string;
  public employeeId: string;
  public employeeName: string;
  public interval: string;
  public startDate: number;
  public endDate: number;
  public summary: Metrics;
  public serviceStatistics: ReportStatistic[];
  public _type: string;
}

export const employeeReportIsEmpty = (value: EmployeeReport): boolean =>
  pipe(
    value,
    fromNullable,
    fold(
      () => false,
      (report) => report.summary.numServicesPerformed === 0
    )
  );
