<slideout-panel (close)="close()">
  <div panel-header>{{ title$ | async }}</div>

  <div panel-body>
    <edit-salon-component
      *ngIf="shouldRenderForm$ | async"
      [salon]="salon$ | async"
      [saving]="saving$ | async"
      (save)="save($event)"
      (close)="close()">
    </edit-salon-component>

    <div *ngIf="loading$ | async">
      <large-loading-indicator></large-loading-indicator>
    </div>

    <div *ngIf="error$ | async">
      <alert-component type="danger">
        <h5>Something went wrong</h5>

        <p>{{ error$ | async }}</p>
      </alert-component>
    </div>
  </div>
</slideout-panel>
