import { Injectable } from "@angular/core";
import { PerformedService } from "@getvish/model";
import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";

@Injectable()
export class PerformedServicesService extends EntityService<PerformedService> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "performedServices" });
  }
}
