import { NavLinkSection } from "./nav-link-section";
import { InjectionToken } from "@angular/core";
import { User } from "@getvish/model";
import { isAdmin, isManager, isRoot } from "../../+auth/services";
import { SalonSoftwareIntegration } from "../models/salon-software-provider";

export const NAV_LINKS = new InjectionToken<NavLinkSection>("NAV_LINKS");

export const NavLinks: NavLinkSection[] = [
  {
    label: "Main",
    links: [
      {
        label: "Insights",
        path: "/insights",
        icon: "chart-area",
        roles: [],
      },
      {
        label: "Product Report",
        path: "/insights/products",
        icon: "chart-bar",
        roles: [],
      },
      {
        label: "Clients",
        path: "/clients",
        icon: "address-book",
        roles: [],
      },
      { label: "Employees", path: "/employees", icon: "users", roles: [] },
      {
        label: "Front Desk",
        path: "/front-desk",
        icon: "cash-register",
        roles: [],
      },
      {
        label: "Sales",
        path: "/sales",
        icon: "bags-shopping",
        roles: [],
      },
    ],
    canActivate: (slug: string) => slug && slug !== "admin",
  },
  {
    label: "Config",
    links: [
      {
        label: "Configuration",
        path: "/salon-config/details",
        icon: "cogs",
        roles: [],
      },
      {
        label: "Service Menu",
        path: "/service-menu",
        icon: "shopping-basket",
        roles: [],
      },
      { label: "Devices", path: "/devices", icon: "tablet", roles: [] },
    ],
    canActivate: (slug: string) => slug && slug !== "admin",
  },
  {
    label: "Integrations",
    links: [
      {
        label: "Integrations",
        path: "/integrations",
        icon: "plug",
        roles: [],
      },
    ],
    canActivate: (slug: string, user: User) => user && slug && slug !== "admin" && (isRoot(user) || isManager(user)),
  },
  {
    label: "Admin",
    links: [
      {
        label: "Salons",
        path: "/salons",
        icon: "building",
        roles: [],
      },
    ],
    canActivate: (slug: string, user: User) => slug && slug === "admin" && user && isAdmin(user),
  },
  {
    label: "Products",
    links: [
      {
        label: "Products",
        path: "/product/salon-products",
        icon: "tags",
        roles: [],
      },
      {
        label: "Inventory",
        path: "/inventory/products",
        icon: "shelves",
        roles: [],
      },
    ],
    canActivate: (slug: string, user: User, integrations: SalonSoftwareIntegration[]) =>
      slug && slug !== "admin" && integrations?.some((i) => i.type === "products" && i.active === true),
  },
  {
    label: "Products",
    links: [
      {
        label: "Products",
        path: "/product/salon-products",
        icon: "tags",
        roles: [],
      },
    ],
    canActivate: (slug: string, user: User, integrations: SalonSoftwareIntegration[]) =>
      slug && slug !== "admin" && !integrations?.some((i) => i.type === "products" && i.active === true),
  },
  {
    label: "Products",
    links: [
      {
        label: "Manufacturers",
        path: "/product/manufacturers",
        icon: "industry",
        roles: [],
      },
      {
        label: "Products",
        path: "/product/products",
        icon: "tags",
        roles: [],
      },
    ],
    canActivate: (slug: string, user: User) => slug && slug === "admin" && user && isAdmin(user),
  },
];
