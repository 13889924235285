import { Injectable } from "@angular/core";
import { ProductCategory, SalonProductCategory } from "@getvish/model";
import { HttpRepositoryFactory, EntityService, HttpRequestHandler } from "@getvish/stockpile";
import { map, mergeMap } from "rxjs/operators";
import { Observable, forkJoin, of, throwError } from "rxjs";
import * as R from "ramda";
import { pipe } from "fp-ts/lib/function";
import { fold } from "fp-ts/lib/Either";

interface UpdateSalonProductCategoryOrderPayload {
  value: number;
}

@Injectable()
export class SalonProductCategoryService extends EntityService<SalonProductCategory> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler
  ) {
    super(repositoryFactory, { entityKey: "salonProductCategories" });
  }

  public findAll(): Observable<SalonProductCategory[]> {
    return this.find().pipe(map((result) => result.records));
  }

  public findForProductCategories(productCategories: ProductCategory[]): Observable<SalonProductCategory[]> {
    const requests = pipe(
      productCategories,
      R.map((c) => c._id),
      R.uniq,
      R.splitEvery(40),
      R.map((ids) => this.find({ productCategoryId: { $in: ids as string[] } }).pipe(map((result) => result.records)))
    );

    return R.isEmpty(requests) ? of([]) : forkJoin(requests).pipe(map(R.flatten));
  }

  public updateOrderOrDie(salonProductCategory: SalonProductCategory, order: number): Observable<SalonProductCategory> {
    const payload: UpdateSalonProductCategoryOrderPayload = { value: order };

    return this._requestHandler.post<SalonProductCategory>(`salonProductCategories/${salonProductCategory._id}/order`, payload).pipe(
      mergeMap(
        fold(
          (error) => throwError(() => new Error(error.toString())),
          (result) => of(result)
        )
      )
    );
  }

  public updateOrderMany(salonProductCategories: SalonProductCategory[]): Observable<SalonProductCategory[]> {
    return forkJoin(
      salonProductCategories.map((salonProductCategory) => this.updateOrderOrDie(salonProductCategory, salonProductCategory.order))
    );
  }
}
