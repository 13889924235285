import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { switchMap, map, filter, withLatestFrom } from "rxjs/operators";
import { checkTenantApiVersion, checkTenantApiVersionPass, checkTenantApiVersionFail } from "./tenant-api-version-check.actions";
import { isSome, toUndefined } from "fp-ts/lib/Option";
import { AppState, SalonApiVersionService } from "app/kernel";
import { Store } from "@ngrx/store";

import * as fromCurrentTenant from "app/kernel/store/reducers/current-tenant.reducer";
import { TenantApiVersionCheckService } from "../services/tenant-api-version-check.service";

@Injectable()
export class TenantApiVersionCheckEffects {
  public checkTenantApiVersion$ = createEffect(() =>
    this._actions$.pipe(
      ofType(checkTenantApiVersion),
      switchMap(({ salonConfig }) =>
        this._salonApiVersionService.findForSalonId(salonConfig.salonId).pipe(
          filter(isSome), // if salon API version is not found for this salon, ignore check
          map(toUndefined),
          map((apiVersion) =>
            this._tenantApiVersionCheckService.isCorrectApiVersion(apiVersion)
              ? checkTenantApiVersionPass({ apiVersion })
              : checkTenantApiVersionFail({ apiVersion })
          )
        )
      )
    )
  );

  public navigateToCorrectAppVersion = createEffect(
    () =>
      this._actions$.pipe(
        ofType(checkTenantApiVersionFail),
        withLatestFrom(this._store.select(fromCurrentTenant.getCurrentTenant)),
        map(([{ apiVersion }, currentTenant]) =>
          this._tenantApiVersionCheckService.navigateToTenantForApiVersion(apiVersion, currentTenant.slug)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private _salonApiVersionService: SalonApiVersionService,
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _tenantApiVersionCheckService: TenantApiVersionCheckService
  ) {}
}
