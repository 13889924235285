import { Injectable } from "@angular/core";
import { Customer } from "@getvish/model";
import { Papa } from "ngx-papaparse";
import { filter, forEach, isEmpty, isNil, not, pipe } from "ramda";
import { Observable, Observer } from "rxjs";

@Injectable()
export class CustomerParserService {
  constructor(private _papa: Papa) {}

  public parseCsvFile(url: string): Observable<Customer> {
    return new Observable((observer: Observer<Customer>) => {
      const options = {
        download: true,
        header: true,
        skipEmptyLines: true,
        chunk: (results) => {
          pipe(
            () => results.data as Customer[],
            filter(
              (customer: Customer) =>
                not(isNil(customer.firstName)) &&
                not(isEmpty(customer.firstName)) &&
                not(isNil(customer.lastName)) &&
                not(isEmpty(customer.lastName))
            ) as () => Customer[],
            forEach((customer: Customer) => observer.next(customer))
          )();
        },
        complete: () => {
          observer.complete();
        },
      };

      this._papa.parse(url, options);
    });
  }
}
