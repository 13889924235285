import * as manufacturer from "./manufacturer.actions";

import { Manufacturer } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { append } from "ramda";

export interface ManufacturerState {
  searchFilter: string;
  loading: boolean;
  saving: boolean;
  records: Manufacturer[];
  paging: PagingMetadata;
  selected: string;
  criteria: JsonObject;
  sort: JsonObject;
}

const initialState: ManufacturerState = {
  searchFilter: undefined,
  loading: false,
  saving: false,
  records: [],
  paging: undefined,
  selected: undefined,
  criteria: undefined,
  sort: undefined,
};

export function manufacturerReducer(state: ManufacturerState = initialState, action: manufacturer.Actions): ManufacturerState {
  switch (action.type) {
    case manufacturer.Types.LOAD_ALL: {
      const loading = true;
      const { criteria, sort } = action.payload;

      return {
        ...state,
        loading,
        criteria,
        sort,
      };
    }

    case manufacturer.Types.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload,
        paging: action.paging,
      };
    }

    case manufacturer.Types.LOAD_ALL_FAIL: {
      return {
        ...state,
        loading: false,
        records: [],
        paging: undefined,
      };
    }

    case manufacturer.Types.ADD_SUCCESS: {
      return {
        ...state,
        records: append(action.payload, state.records),
      };
    }

    case manufacturer.Types.LOAD_BY_ID: {
      const loading = true;

      return {
        ...initialState,
        loading,
      };
    }

    case manufacturer.Types.LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: [action.payload.manufacturer],
      };
    }

    case manufacturer.Types.SET_SEARCH_FILTER: {
      return {
        ...state,
        searchFilter: action.payload.searchFilter,
      };
    }

    case manufacturer.Types.UPDATE_SORT: {
      const sort = action.payload;

      return {
        ...state,
        sort,
      };
    }

    default: {
      return state;
    }
  }
}
