import { Injectable } from "@angular/core";
import { SalonConfig, ServiceDescription } from "@getvish/model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { saveAs } from "file-saver";
import { option } from "fp-ts";
import { omit } from "ramda";
import { forkJoin, of } from "rxjs";
import { catchError, debounceTime, filter, map, mapTo, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { SalonConfigService } from "../../+salon-config/services";
import { AppState, SnackbarService } from "../../kernel";
import { ProductAllowanceService, ServiceCategoryService, ServiceDescriptionService, ServiceMenuDownloaderService } from "../services";

import { HttpError } from "@getvish/stockpile";
import { fold } from "fp-ts/lib/Either";
import * as routerActions from "../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../kernel/store/actions/snackbar.actions";
import * as serviceMenuActions from "./service-menu.actions";
import * as fromServiceMenu from "./service-menu.selectors";
import { getEnableComplianceTracking, getSalonTimeZone } from "app/+salon-config/store/salon-config.selectors";
import { ServiceComplianceAnomaliesService } from "../services/service-compliance-anomalies.service";
import { getUtcDateRangeDaysFromZonedTime } from "app/kernel/util/zoned-time-utils";

@Injectable()
export class ServiceMenuEffects {
  public handleFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.Search>(serviceMenuActions.Types.SEARCH),
      debounceTime(500),
      map((action) => action.payload),
      withLatestFrom(
        this._store.pipe(select(fromServiceMenu.getTableFilter)),
        this._store.pipe(select(fromServiceMenu.getShowInactive)),
        this._store.pipe(select(fromServiceMenu.getUncategorizedOnly))
      ),
      map(([payload, tableFilter, showInactive, uncategorizedOnly]) =>
        routerActions.go({
          path: ["/service-menu"],
          query: {
            filter: payload.filter,
            tableFilter: tableFilter == null ? undefined : JSON.stringify(tableFilter),
            showInactive,
            uncategorizedOnly,
          },
        })
      )
    )
  );

  public handleTableFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.UpdateTableFilter>(serviceMenuActions.Types.UPDATE_TABLE_FILTER),
      debounceTime(500),
      map((action) => action.payload),
      map((payload) =>
        routerActions.go({
          path: ["/service-menu"],
          query: { tableFilter: JSON.stringify(payload), page: undefined },
          extras: { queryParamsHandling: "merge" },
        })
      )
    )
  );

  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.LoadAll>(serviceMenuActions.Types.LOAD_ALL),
      withLatestFrom(this._store.select(fromServiceMenu.getComplianceAnomalies)),
      switchMap(([action, complianceAnomalies]) => {
        const criteria = serviceMenuActions.makeServiceMenuCriteria(action.payload);

        if (criteria?.["anomaly"] === true && complianceAnomalies == null) {
          return this._store.select(fromServiceMenu.getComplianceAnomalies).pipe(
            filter((anomalies) => anomalies != null),
            take(1),
            map((anomalies) => ({ action, complianceAnomalies: anomalies }))
          );
        } else {
          return of({ action, complianceAnomalies });
        }
      }),
      switchMap(({ action, complianceAnomalies }) => {
        let criteria = serviceMenuActions.makeServiceMenuCriteria(action.payload);

        if (criteria["anomaly"] === true) {
          criteria = omit(["anomaly"], criteria as any);

          criteria["_id"] = { $in: complianceAnomalies?.map((anomaly) => anomaly.serviceDescription._id) ?? [] };
        }

        const serviceDescriptions$ = this._serviceDescriptionService.find(
          criteria,
          action.payload.sort,
          action.payload.page,
          action.payload.limit
        );

        const productAllowances$ = serviceDescriptions$.pipe(
          map((serviceDescriptionsResult) => serviceDescriptionsResult.records),
          switchMap((serviceDescriptions) => this._productAllowanceService.getProductAllowances(serviceDescriptions))
        );

        return forkJoin([
          serviceDescriptions$,
          this._serviceCategoryService.find({}, { name: 1 }),
          this._salonConfigService.findOne({}),
          productAllowances$,
        ]).pipe(
          mergeMap(([serviceDescriptionsResult, serviceCategoriesResult, salonConfigResult, productAllowances]) => [
            new serviceMenuActions.LoadServiceCategoriesSuccess(serviceCategoriesResult.records),
            new serviceMenuActions.LoadServiceDescriptionsSuccess(serviceDescriptionsResult.records, serviceDescriptionsResult.paging),
            ...option.fold<SalonConfig, Action[]>(
              () => [new serviceMenuActions.LoadSalonConfigFail({ error: new Error("Not Found") })],
              (record) => [new serviceMenuActions.LoadSalonConfigSuccess(record)]
            )(salonConfigResult),
            new serviceMenuActions.LoadProductAllowancesSuccess(productAllowances),
          ]),
          catchError((e) => {
            console.error(e);
            return of(new serviceMenuActions.LoadServiceDescriptionsFail({ error: e }));
          })
        );
      })
    )
  );

  public loadAllTriggerAnomalyLoad$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.LoadAll>(serviceMenuActions.Types.LOAD_ALL),
      switchMap(() => this._store.select(getEnableComplianceTracking)),
      filter((enableComplianceTracking) => enableComplianceTracking != null),
      withLatestFrom(this._store.select(getSalonTimeZone)),
      map(([enableComplianceTracking, timeZone]) =>
        enableComplianceTracking
          ? new serviceMenuActions.LoadServiceComplianceAnomalies(getUtcDateRangeDaysFromZonedTime(timeZone, 30))
          : new serviceMenuActions.LoadServiceComplianceAnomaliesSuccess([])
      )
    )
  );

  public loadAnomalies$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.LoadServiceComplianceAnomalies>(serviceMenuActions.Types.LOAD_SERVICE_COMPLIANCE_ANOMALIES),
      switchMap((action) =>
        this._serviceComplianceAnomaliesService
          .getServiceComplianceAnomalies(action.payload.startDate, action.payload.endDate)
          .pipe(map((anomalies) => new serviceMenuActions.LoadServiceComplianceAnomaliesSuccess(anomalies)))
      )
    )
  );

  public updateManyToggleIsActive$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.UpdateManyToggleIsActive>(serviceMenuActions.Types.UPDATE_MANY_TOGGLE_IS_ACTIVE),
      withLatestFrom(this._store.pipe(select(fromServiceMenu.getSelectedRecords))),
      switchMap(([_, records]) =>
        this._serviceDescriptionService
          .updateManyToggleIsActive(records)
          .pipe(
            mergeMap((result) => [
              new serviceMenuActions.ClearSelectedServices(),
              new serviceMenuActions.UpdateServiceDescriptionsSuccess(result),
              new serviceMenuActions.UpdateManyToggleIsActiveSuccess(),
            ])
          )
      ),
      catchError((e) => {
        console.error(e);
        return of(new serviceMenuActions.UpdateManyToggleIsActiveFail());
      })
    )
  );

  public togglePartsAndLabor$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.TogglePartsAndLabor>(serviceMenuActions.Types.TOGGLE_PARTS_AND_LABOR),
      withLatestFrom(this._store.pipe(select(fromServiceMenu.getSelectedRecords))),
      mergeMap(([_, records]) => {
        let serviceDescriptions = records;
        if (_.payload?.serviceDescription) {
          serviceDescriptions = [_.payload?.serviceDescription];
        }

        return this._serviceDescriptionService.updateManyToggleMode(serviceDescriptions).pipe(
          mergeMap(
            fold<HttpError, ServiceDescription[], Action[]>(
              (error) => [new serviceMenuActions.TogglePartsAndLaborFail({ errors: [error] })],
              (serviceDescriptions) => [
                new serviceMenuActions.ClearSelectedServices(),
                new serviceMenuActions.UpdateServiceDescriptionsSuccess(serviceDescriptions),
                new serviceMenuActions.TogglePartsAndLaborSuccess(),
              ]
            )
          )
        );
      })
    )
  );

  public togglePartsAndLaborError$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.TogglePartsAndLaborFail>(serviceMenuActions.Types.TOGGLE_PARTS_AND_LABOR_FAIL),
      mapTo(new SnackbarActions.Info({ message: "There was an error while toggling Parts and Labor." }))
    )
  );

  public calculateProductAllowance$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.CalculateProductAllowance>(serviceMenuActions.Types.CALCULATE_PRODUCT_ALLOWANCE),
      withLatestFrom(this._store.pipe(select(fromServiceMenu.getSelectedRecords))),
      map(([_, records]) =>
        routerActions.go({ path: ["/service-menu/pa-calculator"], query: { ids: records.map((r) => r._id).join(",") } })
      )
    )
  );

  public navigateToPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.NavigateToPage>(serviceMenuActions.Types.NAVIGATE_TO_PAGE),
      map((action) => action.payload.page),
      withLatestFrom(
        this._store.pipe(select(fromServiceMenu.getFilter)),
        this._store.pipe(select(fromServiceMenu.getTableFilter)),
        this._store.pipe(select(fromServiceMenu.getShowInactive)),
        this._store.pipe(select(fromServiceMenu.getUncategorizedOnly))
      ),
      map(([page, filter, tableFilter, showInactive, uncategorizedOnly]) =>
        routerActions.go({
          path: ["/service-menu"],
          query: {
            filter,
            tableFilter: tableFilter == null ? undefined : JSON.stringify(tableFilter),
            page,
            showInactive,
            uncategorizedOnly,
          },
        })
      )
    )
  );

  public toggleOption$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.ToggleUncategorizedOnly>(
        serviceMenuActions.Types.TOGGLE_SHOW_INACTIVE,
        serviceMenuActions.Types.TOGGLE_UNCATEGORIZED_ONLY
      ),
      withLatestFrom(
        this._store.pipe(select(fromServiceMenu.getFilter)),
        this._store.pipe(select(fromServiceMenu.getTableFilter)),
        this._store.pipe(select(fromServiceMenu.getShowInactive)),
        this._store.pipe(select(fromServiceMenu.getUncategorizedOnly))
      ),
      map(([_, filter, tableFilter, showInactive, uncategorizedOnly]) =>
        routerActions.go({
          path: ["/service-menu"],
          query: { filter, tableFilter: tableFilter == null ? undefined : JSON.stringify(tableFilter), showInactive, uncategorizedOnly },
        })
      )
    )
  );

  public editServiceDone$ = createEffect(() =>
    this._actions$.pipe(
      ofType(serviceMenuActions.Types.EDIT_SERVICE_DONE),
      map(() => routerActions.go({ path: ["/service-menu"], extras: { queryParamsHandling: "merge" } }))
    )
  );

  public downloadServiceMenuReport$ = createEffect(() =>
    this._actions$.pipe(
      ofType<serviceMenuActions.DownloadServiceMenuReport>(serviceMenuActions.Types.DOWNLOAD_SERVICE_MENU_REPORT),
      switchMap(() =>
        this._serviceMenuDownloaderService.downloadServiceMenuReport().pipe(
          tap((response) => saveAs(response, "vish-service-menu-report.xlsx")),
          map(() => new serviceMenuActions.DownloadServiceMenuReportSuccess()),
          catchError((e) => {
            console.error(e);
            this._snackbarService.info(`Error downloading service menu report.`);
            return of(new serviceMenuActions.DownloadServiceMenuReportFail());
          })
        )
      )
    )
  );

  public updateSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(serviceMenuActions.Types.UPDATE_SERVICE_DESCRIPTIONS_SUCCESS),
      map(() => new serviceMenuActions.Reload())
    )
  );

  public reload$ = createEffect(() =>
    this._actions$.pipe(
      ofType(serviceMenuActions.Types.RELOAD),
      withLatestFrom(
        this._store.select(fromServiceMenu.getFilter),
        this._store.select(fromServiceMenu.getTableFilter),
        this._store.select(fromServiceMenu.getCurrentPage),
        this._store.select(fromServiceMenu.getShowInactive),
        this._store.select(fromServiceMenu.getUncategorizedOnly)
      ),
      mergeMap(([_, filter, tableFilter, page, showInactive, uncategorizedOnly]) => [
        new serviceMenuActions.ClearServiceComplianceAnomalies(),
        new serviceMenuActions.LoadAll({ filter, tableFilter, page, limit: 50, showInactive, uncategorizedOnly }),
      ])
    )
  );

  constructor(
    private _serviceDescriptionService: ServiceDescriptionService,
    private _serviceCategoryService: ServiceCategoryService,
    private _salonConfigService: SalonConfigService,
    private _serviceMenuDownloaderService: ServiceMenuDownloaderService,
    private _productAllowanceService: ProductAllowanceService,
    private _serviceComplianceAnomaliesService: ServiceComplianceAnomaliesService,
    private _snackbarService: SnackbarService,
    private _actions$: Actions,
    private _store: Store<AppState>
  ) {}
}
