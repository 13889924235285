import { ModuleWithProviders, NgModule } from "@angular/core";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faBell, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { NotificationBellContainer } from "./containers";
import { SharedModule } from "app/kernel/shared";
import { IdbNotificationService, NotificationService } from "./services";
import { OverlayModule } from "@angular/cdk/overlay";
import { NotificationListComponent } from "./components/notification-list";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import { featureKey, reducer } from "./store/notifications.reducer";
import { NotificationsEffects } from "./store/notifications.effects";
import { NotificationListItemComponent } from "./components/notification-list-item/notification-list-item.component";

@NgModule({
  imports: [OverlayModule, SharedModule, StoreModule.forFeature(featureKey, reducer), EffectsModule.forFeature([NotificationsEffects])],
  declarations: [NotificationBellContainer, NotificationListComponent, NotificationListItemComponent],
  exports: [NotificationBellContainer],
})
export class NotificationsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faBell, faEnvelope);
  }

  public static forRoot(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [{ provide: NotificationService, useClass: IdbNotificationService }],
    };
  }
}
