import { fromMeasurementType } from "@getvish/model";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { isEmpty, not } from "ramda";
import { DEFAULT_MEASUREMENT_UNIT_TYPE } from "../../kernel/defaults";
import { SalonConfigState } from "./salon-config.reducer";

export const getSalonConfigState = createFeatureSelector<SalonConfigState>("salonConfig");
export const getCurrentSalon = createSelector(getSalonConfigState, (state) => state.currentSalon);
export const getLoading = createSelector(getSalonConfigState, (state) => state.loading);

export const getSaving = createSelector(getSalonConfigState, (state) => state.saving);

export const getSalonConfig = createSelector(getSalonConfigState, (state) => state.currentSalonConfig);
export const getSalonTimeZone = createSelector(getSalonConfig, (salonConfig) => salonConfig?.timeZone);

export const getMeasurementUnit = createSelector(getSalonConfig, (maybeSalonConfig) =>
  pipe(
    option.fromNullable(maybeSalonConfig),
    option.chain((salonConfig) =>
      pipe(
        option.fromNullable(salonConfig.measurementUnit),
        option.map((measurementUnit) => fromMeasurementType(measurementUnit))
      )
    )
  )
);

export const getMeasurementUnitOrDefault = createSelector(getMeasurementUnit, (maybeMeasurementUnit) =>
  pipe(
    maybeMeasurementUnit,
    option.getOrElse(() => fromMeasurementType(DEFAULT_MEASUREMENT_UNIT_TYPE))
  )
);

export const getTimeZones = createSelector(getSalonConfigState, (state) => state.timeZones);
export const getCurrency = createSelector(getSalonConfig, (config) => (config ? config.currency : undefined));
export const getCurrencies = createSelector(getSalonConfigState, (config) => config.currencies);
export const getLocale = createSelector(getSalonConfig, (config) => (config ? config.locale : undefined));
export const getIsBillingConfigured = createSelector(getCurrentSalon, (salon) =>
  pipe(
    option.fromNullable(salon),
    option.chain((salon) => option.fromNullable(salon.billing)),
    option.map((billing) => not(isEmpty(billing.customerId))),
    option.getOrElse(() => false)
  )
);

export const getEnableComplianceTracking = createSelector(getSalonConfig, (config) => config?.enableComplianceTracking);
