<div class="content">
  <h2>Confirm Verification Code</h2>

  <p>We've sent a 4-digit verification code to your email. Enter the code below to continue:</p>

  <alert-component
    *ngIf="error"
    type="danger">
    {{ error }}
  </alert-component>

  <form
    [formGroup]="form"
    (submit)="save.emit(form.value)">
    <mat-form-field class="full-width">
      <mat-label>Verification Code</mat-label>
      <input
        matInput
        formControlName="code"
        type="number" />
      <mat-error *ngIf="form.get('code').hasError('required')">Required</mat-error>
    </mat-form-field>

    <div class="controls">
      <button
        [disabled]="form.invalid"
        type="submit"
        class="button primary">
        Next
      </button>
    </div>
  </form>
</div>
