import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../../kernel/store";
import { Observable } from "rxjs";

import * as SalonConfigActions from "../../store/salon-config.actions";
import * as fromSalonConfig from "../../store/salon-config.selectors";

@Component({
  selector: "salon-payment-config-container",
  templateUrl: "salon-payment-config.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalonPaymentConfigContainer {
  public isBillingConfigured$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.isBillingConfigured$ = this._store.select(fromSalonConfig.getIsBillingConfigured);
  }

  public managePayments(): void {
    this._store.dispatch(new SalonConfigActions.ManagePaymentMethods());
  }
}
