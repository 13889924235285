import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { Salon } from "@getvish/model";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Equals } from "../../kernel/validators";

@Component({
  selector: "migrate-salon-component",
  templateUrl: "./migrate-salon.component.html",
  styleUrls: ["./migrate-salon.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MigrateSalonComponent implements OnInit {
  @Input() public salon: Salon;
  @Input() public migrating: boolean;
  @Input() public success: boolean;
  @Input() public error: string;

  @Output() public close: EventEmitter<void>;
  @Output() public cancel: EventEmitter<void>;
  @Output() public migrate: EventEmitter<Salon>;
  @Output() public done: EventEmitter<void>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.migrate = new EventEmitter(true);
    this.close = new EventEmitter(true);
    this.cancel = new EventEmitter(true);
    this.done = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      slug: [undefined, [Validators.required, Equals(this.salon.slug)]],
    });
  }

  public migrateSalon(salon: Salon): void {
    if (!this.migrating) {
      this.migrate.emit(salon);
    }
  }
}
