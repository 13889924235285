import { Injectable } from "@angular/core";
import { Employee, SalonConfig } from "@getvish/model";
import { EmployeeService } from "app/+employees/services";
import { SalonConfigService } from "app/+salon-config/services";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ScheduleAppointmentService {
  constructor(
    private _employeeService: EmployeeService,
    private _salonConfigService: SalonConfigService
  ) {}

  public loadData(): Observable<{ salonConfig: SalonConfig; employees: Employee[] }> {
    const loadEmployees$ = this._employeeService.find().pipe(map((result) => result.records));
    const loadSalonConfig$ = this._salonConfigService.findOrDie();

    return forkJoin({
      employees: loadEmployees$,
      salonConfig: loadSalonConfig$,
    });
  }
}
