import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { SalonConfig, SalonConfigModule, MeasurementUnitType } from "@getvish/model";
import { range } from "ramda";
import { Observable } from "rxjs";
import { milliseconds } from "date-fns";
import { Currency } from "app/+salon-config/services";
import { Timezone } from "../../../kernel/models/timezone";
import { startWith, map } from "rxjs/operators";
import { MatchesOneOfValidator } from "../../../kernel/validators";

@Component({
  selector: "salon-settings",
  templateUrl: "salon-settings.component.html",
  styleUrls: ["salon-settings.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalonSettingsComponent implements OnInit {
  @Input() public config: SalonConfig;
  @Input() public timeZones: Timezone[];
  @Input() public currencies: Currency[];
  @Input() public isRoot: boolean;

  @Output() public save: EventEmitter<SalonConfig>;

  public form: UntypedFormGroup;
  public measurementUnitOptions: Array<{
    value: MeasurementUnitType;
    label: string;
  }>;

  public autoLogoutIntervals: number[];

  public localeOptions: Array<{
    value: string;
    label: string;
  }>;

  public numEmployeeExperienceLevelOptions: number[];
  public filteredTimeZones$: Observable<Timezone[]>;
  public autoLogoutEnabled$: Observable<boolean>;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);

    this.measurementUnitOptions = [
      { value: MeasurementUnitType.GRAMS, label: "Grams" },
      { value: MeasurementUnitType.OUNCES, label: "Ounces" },
    ];

    this.autoLogoutIntervals = [0.5, ...range(1, 21)].map((minutes) => milliseconds({ minutes }));
    this.numEmployeeExperienceLevelOptions = range(1, 51);

    this.localeOptions = [
      { value: "en", label: "English (US)" },
      { value: "en-GB", label: "English (Great Britian)" },
      { value: "en-AU", label: "English (Australian)" },
      { value: "en-NZ", label: "English (New Zealand)" },
      { value: "en-ZA", label: "English (South Africa)" },
      { value: "en-150", label: "English (Europe)" },
      { value: "ar-QA", label: "Arabic (Qatar)" },
      { value: "ar-AE", label: "Arabic (United Arab Emirates)" },
      { value: "fr", label: "French" },
      { value: "de", label: "German (Germany)" },
      { value: "es-MX", label: "Spanish (Mexico)" },
      { value: "es", label: "Spanish (Spain)" },
    ];
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      minimumContainerWeight: [this.config.minimumContainerWeight],
      measurementUnit: [this.config.measurementUnit],
      autoLogoutEnabled: [this.config.autoLogoutEnabled],
      autoLogoutInterval: [this.config.autoLogoutInterval, Validators.required],
      currency: [this.config.currency, Validators.required],
      restrictedScheduling: [this.config.restrictedScheduling],
      containerWeightTolerance: [this.config.containerWeightTolerance, Validators.compose([Validators.min(0.2), Validators.max(1)])],
      timeZone: [this.config.timeZone, [Validators.required, MatchesOneOfValidator(this.timeZones.map((timeZone) => timeZone.value))]],
      syncingMode: [this.config.syncingMode],
      locale: [this.config.locale, Validators.required],
      defaultProductMarkupPercent: [
        this.config.defaultProductMarkup != null ? this.config.defaultProductMarkup * 100 : 100,
        Validators.required,
      ],
      enableComplianceTracking: [this.config.enableComplianceTracking ?? false],
    });

    this.filteredTimeZones$ = this.form.controls["timeZone"].valueChanges.pipe(
      startWith(""),
      map((value) => this._filterTimeZones(value))
    );

    this.autoLogoutEnabled$ = this.form.controls["autoLogoutEnabled"].valueChanges.pipe(
      startWith(this.config.autoLogoutEnabled),
      map((value) => value as boolean)
    );
  }

  public saveForm(original: SalonConfig, data): void {
    const updatedConfig = SalonConfigModule.merge(original, data);

    updatedConfig.defaultProductMarkup =
      data.defaultProductMarkupPercent != null ? data.defaultProductMarkupPercent / 100 : updatedConfig.defaultProductMarkup;
    delete (updatedConfig as any).defaultProductMarkupPercent;

    this.save.emit(updatedConfig);
  }

  private _filterTimeZones(filter: string): Timezone[] {
    const timeZones = this.timeZones;
    const filterValue = filter.toLowerCase();
    return timeZones.filter((option) => option.value.toLowerCase().includes(filterValue));
  }
}
