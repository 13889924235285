import { Component } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Product } from "@getvish/model";
import { Observable } from "rxjs";
import { AppState } from "app/kernel";

import * as fromProduct from "../store/product.selectors";
import * as ProductActions from "../store/product.actions";

@Component({
  selector: "products-container",
  templateUrl: "products.container.html",
  styleUrls: ["products.container.less"],
})
export class ProductsContainer {
  public products$: Observable<Product[]>;
  public loading$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.products$ = _store.pipe(select(fromProduct.getFilteredProducts));
    this.loading$ = this._store.pipe(select(fromProduct.getLoading));
  }

  public new(): void {
    this._store.dispatch(new ProductActions.New());
  }

  public edit(product: Product): void {
    this._store.dispatch(new ProductActions.Edit({ product }));
  }
}
