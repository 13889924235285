import { Action } from "@ngrx/store";
import { PagingMetadata, JsonObject } from "@getvish/stockpile";
import { ProductCategory, Manufacturer } from "@getvish/model";
import { isNil } from "ramda";

export enum Types {
  LOAD_ALL = "[ProductCategory] Load All",
  LOAD_ALL_SUCCESS = "[ProductCategory] Load All Success",
  LOAD_ALL_FAIL = "[ProductCategory] Load All Fail",
  NEW = "[ProductCategory] New",
  ADD = "[ProductCategory] Add",
  ADD_SUCCESS = "[ProductCategory] Add Success",
  ADD_FAIL = "[ProductCategory] Add Fail",
  EDIT = "[ProductCategory] Edit",
  EDIT_SUCCESS = "[ProductCategory] Edit Success",
  EDIT_FAIL = "[ProductCategory] Edit Fail",
  SAVE = "[ProductCategory] Save",
  UPDATE = "[ProductCategory] Update",
  UPDATE_SUCCESS = "[ProductCategory] Update Success",
  UPDATE_FAIL = "[ProductCategory] Update Fail",
  LOAD_MANUFACTURERS = "[ProductCategory] Load Manufacturers",
  SELECT_MANUFACTURER = "[ProductCategory] Select Manufacturer",
  CLEAR_MANUFACTURER = "[ProductCategory] Clear Selected Manufacturer",
  EXPAND_ALL_CATEGORIES = "[ProductCategory] Expand All Categories",
  COLLAPSE_ALL_CATEGORIES = "[ProductCategory] Collapse All Categories",
  TOGGLE_CATEGORY = "[ProductCategory] Toggle Category",
  LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER = "[ProductCategory] Load Products and Categories For Manufacturer",
  LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER_SUCCESS = "[ProductCategory] Load Products and Categories For Manufacturer Success",
  CHANGE_CATEGORY_ORDER = "[ProductCategory] Change Category Order",
  UPDATE_PRODUCT_CATEGORIES = "[ProductCategory] Update Product Categories",
  UPDATE_PRODUCT_CATEGORIES_SUCCESS = "[ProductCategory] Update Product Categories Success",
  UPDATE_PRODUCT_CATEGORIES_FAIL = "[ProductCategory] Update Product Categories Fail",
}

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;

  constructor(
    public payload: {
      criteria?: JsonObject;
      sort?: JsonObject;
      page?: number;
      limit?: number;
    }
  ) {}
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public payload: ProductCategory[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class New implements Action {
  public readonly type = Types.NEW;

  constructor(public payload?: { parentCategory?: ProductCategory; manufacturerId?: string }) {}
}

export class Add implements Action {
  public readonly type = Types.ADD;

  constructor(public payload: { productCategory: ProductCategory }) {}
}

export class AddSuccess implements Action {
  public readonly type = Types.ADD_SUCCESS;

  constructor(public payload: ProductCategory) {}
}

export class AddFail implements Action {
  public readonly type = Types.ADD_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class Edit implements Action {
  public readonly type = Types.EDIT;

  constructor(public payload: { productCategory: ProductCategory }) {}
}

export class EditFail implements Action {
  public readonly type = Types.EDIT_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class Update implements Action {
  public readonly type = Types.UPDATE;

  constructor(public payload: { productCategory: ProductCategory }) {}
}

export class UpdateSuccess implements Action {
  public readonly type = Types.UPDATE_SUCCESS;

  constructor(public payload: ProductCategory) {}
}

export class UpdateFail implements Action {
  public readonly type = Types.UPDATE_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class LoadManufacturers implements Action {
  public readonly type = Types.LOAD_MANUFACTURERS;
}

export class SelectManufacturer implements Action {
  public readonly type = Types.SELECT_MANUFACTURER;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class ClearManufacturer implements Action {
  public readonly type = Types.CLEAR_MANUFACTURER;
}

export class ExpandAllCategories implements Action {
  public readonly type = Types.EXPAND_ALL_CATEGORIES;
}

export class CollapseAllCategories implements Action {
  public readonly type = Types.COLLAPSE_ALL_CATEGORIES;
}

export class ToggleCategory implements Action {
  public readonly type = Types.TOGGLE_CATEGORY;

  constructor(public payload: { categoryId: string }) {}
}

export class LoadProductsAndCategoriesForManufacturer implements Action {
  public readonly type = Types.LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER;

  constructor(public payload: { manufacturerId: string }) {}
}

export class LoadProductsAndCategoriesForManufacturerSuccess implements Action {
  public readonly type = Types.LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER_SUCCESS;
}

export class ChangeCategoryOrder implements Action {
  public readonly type = Types.CHANGE_CATEGORY_ORDER;

  constructor(public payload: { categories: ProductCategory[] }) {}
}

export class UpdateProductCategories implements Action {
  public readonly type = Types.UPDATE_PRODUCT_CATEGORIES;

  constructor(public payload: { categories: ProductCategory[] }) {}
}

export class UpdateProductCategoriesSuccess implements Action {
  public readonly type = Types.UPDATE_PRODUCT_CATEGORIES_SUCCESS;

  constructor(public payload: { categories: ProductCategory[] }) {}
}

export class UpdateProductCategoriesFail implements Action {
  public readonly type = Types.UPDATE_PRODUCT_CATEGORIES_FAIL;

  constructor(public payload: { error: Error }) {}
}

export const addOrUpdate = (productCategory: ProductCategory) =>
  isNil(productCategory._id) ? new Add({ productCategory }) : new Update({ productCategory });

export type Actions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | New
  | Add
  | AddSuccess
  | AddFail
  | Edit
  | EditFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | LoadManufacturers
  | SelectManufacturer
  | ClearManufacturer
  | ExpandAllCategories
  | CollapseAllCategories
  | ToggleCategory
  | LoadProductsAndCategoriesForManufacturer
  | LoadProductsAndCategoriesForManufacturerSuccess
  | ChangeCategoryOrder
  | UpdateProductCategories
  | UpdateProductCategoriesSuccess
  | UpdateProductCategoriesFail;
