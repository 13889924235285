<page
  [progressText]="getProgressText() | async"
  pageTitle="Service Menu"
  class="full-height-table">
  <header-controls page-header>
    <div class="search-input-control">
      <search-input
        [searchFilter]="filter$ | async"
        (filter)="updateFilter($event)"
        placeholder="Search Services..."></search-input>
    </div>
    <button
      class="button outline"
      (click)="addService()">
      Add Service
    </button>
    <ng-container>
      <mat-menu #controlsMenu="matMenu">
        <button
          mat-menu-item
          title="Edit selected services"
          (click)="editServices()"
          [disabled]="selectedRecords$ | async | isEmpty"
          class="menu-item-btn">
          <div class="menu-item">
            <fa-icon icon="pen-to-square"></fa-icon>
            <span>Edit Selected Services</span>
          </div>
        </button>
        <button
          mat-menu-item
          title="Toggle Active/Inactive status for selected services"
          (click)="updateManyToggleActive()"
          [disabled]="selectedRecords$ | async | isEmpty"
          class="menu-item-btn">
          <div class="menu-item">
            <fa-icon icon="eye-slash"></fa-icon>
            <span>Toggle Active / Inactive</span>
          </div>
        </button>
        <button
          mat-menu-item
          title='Toggle "Parts and Labor" / "Product Allowance" for selected services'
          (click)="togglePartsAndLabor()"
          [disabled]="selectedRecords$ | async | isEmpty"
          class="menu-item-btn">
          <div class="menu-item">
            <fa-icon icon="clock"></fa-icon>
            <span>Toggle Parts and Labor / Product Allowance</span>
          </div>
        </button>
        <button
          mat-menu-item
          (click)="calculateProductAllowance()"
          [disabled]="(selectedPaRecords$ | async)?.length !== (selectedRecords$ | async)?.length"
          [title]="
            (selectedPaRecords$ | async)?.length !== (selectedRecords$ | async)?.length
              ? 'Cannot calculate product allowance for Parts and Labor services'
              : 'Calculate product allowance for selected services'
          "
          class="menu-item-btn">
          <div class="menu-item">
            <fa-icon icon="calculator"></fa-icon>
            <span>Calculate Allowance</span>
          </div>
        </button>
      </mat-menu>
      <button
        [matMenuTriggerFor]="controlsMenu"
        class="button transparent"
        [disabled]="selectedRecords$ | async | isEmpty"
        [title]="(selectedRecords$ | async | isEmpty) ? 'No services selected' : ''">
        <fa-icon icon="ellipsis-v"></fa-icon>
      </button>
    </ng-container>
  </header-controls>
  <ng-container page-body>
    <card>
      <div
        class="card-header-controls"
        card-header>
        <mat-slide-toggle
          [checked]="uncategorizedOnly$ | async"
          (change)="toggleUncategorizedOnly()">
          Uncategorized Only
        </mat-slide-toggle>
        <mat-slide-toggle
          [checked]="showInactive$ | async"
          (change)="toggleShowInactive()">
          Show Inactive Services
        </mat-slide-toggle>
        <a
          (click)="downloadServiceMenuReport()"
          matTooltip="Download report as spreadsheet"
          class="button secondary transparent">
          <fa-icon icon="cloud-download-alt"></fa-icon>
        </a>
      </div>
      <data-table
        [paging]="paging$ | async"
        [selections]="selections$ | async"
        card-body>
        <columns>
          <column id="selection"></column>
          <column
            id="serviceName"
            title="Service Name / Category"></column>
          <column
            id="productAllowance"
            title="Product Allowance">
            <column-icons>
              <column-icon
                icon="circle-question"
                url="https://docs.getvish.com/docs/2.0/dashboard-service-menu#product-allowances"></column-icon>
            </column-icons>
          </column>
          <column
            id="paBlueprintsDescription"
            title="Allowance Summary"></column>
          <column
            id="flags"
            title="Flags">
            <column-filter [value]="(tableFilter$ | async)?.flags">
              <ng-template let-column="column">
                <select-filter
                  [value]="(tableFilter$ | async)?.flags"
                  [column]="column"
                  (filter)="
                    updateTableFilter({
                      flags: $event
                    })
                  ">
                  <select-filter-option
                    value="DEFAULT"
                    title="Default"></select-filter-option>
                  <select-filter-option
                    value="EXCLUDE_FROM_ANALYTICS"
                    title="Exclude From Analytics"></select-filter-option>
                  <select-filter-option
                    value="INACTIVE"
                    title="Inactive"></select-filter-option>
                  <select-filter-option
                    value="ANOMALY"
                    title="Service Compliance Anomaly"></select-filter-option>
                </select-filter>
              </ng-template>
            </column-filter>
          </column>
          <column id="menu"></column>
        </columns>
        <body *ngLet="complianceAnomalies$ | async as complianceAnomalies">
          <row
            *ngFor="let serviceDescription of serviceDescriptions$ | async as serviceDescriptions; trackBy: serviceDescriptionTrackBy"
            [className]="'service-description-row' + (serviceDescription.flags?.includes('INACTIVE') ? ' inactive' : '')"
            [id]="serviceDescription._id">
            <ng-container *ngIf="serviceCategories$ | async as serviceCategories">
              <ng-container
                *ngIf="getRootCategoryIfServiceHasCategory(serviceDescription, serviceCategories$ | async) as sc; else uncategorized">
                <category
                  [id]="sc._id"
                  [title]="sc.name"
                  [selected]="(selectedCategories$ | async).includes(sc._id)"
                  (change)="toggleSelectedCategory(sc._id, getGroupedServices(serviceDescriptions, serviceCategories)[sc._id])">
                </category>
              </ng-container>
              <ng-template #uncategorized>
                <category
                  id="Uncategorized"
                  title="Uncategorized"
                  [selected]="(selectedCategories$ | async).includes('Uncategorized')"
                  (change)="
                    toggleSelectedCategory('Uncategorized', getGroupedServices(serviceDescriptions, serviceCategories)['undefined'])
                  ">
                </category>
              </ng-template>
            </ng-container>
            <cell columnId="selection">
              <ng-template>
                <selection-cell
                  [checked]="serviceDescription.selected"
                  (change)="toggleSelected(serviceDescription)"></selection-cell>
              </ng-template>
            </cell>
            <cell columnId="serviceName">
              <ng-template>
                <text-cell>
                  <text-cell-text
                    [value]="serviceDescription.name"
                    title="Edit"
                    className="primary"
                    (action)="edit(serviceDescription)">
                  </text-cell-text>
                  <text-cell-text
                    [value]="serviceDescription.category?.name"
                    className="secondary">
                  </text-cell-text>
                </text-cell>
              </ng-template>
            </cell>
            <cell columnId="productAllowance">
              <ng-template>
                <product-allowance-cell
                  *ngIf="!isPartsAndLabor((productAllowances$ | async)?.[serviceDescription._id], serviceDescription)"
                  [serviceId]="serviceDescription._id"
                  [productAllowance]="getAllowance(serviceDescription) | async"
                  [currency]="serviceDescription.currency"
                  [disabled]="
                    isPartsAndLabor((productAllowances$ | async)?.[serviceDescription._id], serviceDescription)
                  "></product-allowance-cell>
                <text-cell *ngIf="isPartsAndLabor((productAllowances$ | async)?.[serviceDescription._id], serviceDescription)">
                  <text-cell-text
                    value="Parts and Labor"
                    className="label parts-and-labor">
                  </text-cell-text>
                </text-cell>
              </ng-template>
            </cell>
            <cell
              columnId="paBlueprintsDescription"
              className="fill">
              <ng-template>
                <product-allowance-summary-cell
                  *ngIf="!isPartsAndLabor((productAllowances$ | async)?.[serviceDescription._id], serviceDescription)"
                  [productAllowance]="(productAllowances$ | async)?.[serviceDescription._id]"
                  [measurementUnit]="measurementUnit$ | async">
                </product-allowance-summary-cell>
              </ng-template>
            </cell>
            <cell
              columnId="flags"
              className="flags"
              *ngLet="getNumberOfMixAnomalies(serviceDescription._id, complianceAnomalies) as mixAnomalies">
              <ng-template>
                <text-cell>
                  <text-cell-text
                    *ngFor="let flag of serviceDescription.flags"
                    [value]="formatFlag(flag)"
                    [className]="getClassNamesForFlag(flag)">
                  </text-cell-text>
                  <text-cell-text
                    *ngIf="mixAnomalies > 0"
                    value="Service Compliance Anomalies"
                    className="flag anomaly"
                    [tooltip]="getAnomalyTooltip(serviceDescription._id, complianceAnomalies)">
                  </text-cell-text>
                </text-cell>
              </ng-template>
            </cell>
            <cell columnId="menu">
              <ng-template>
                <menu-cell>
                  <menu-cell-item
                    title="Edit"
                    (action)="edit(serviceDescription)">
                  </menu-cell-item>
                  <menu-cell-item
                    [title]="
                      isPartsAndLabor((productAllowances$ | async)?.[serviceDescription._id], serviceDescription)
                        ? 'Switch To Product Allowance'
                        : 'Switch To Parts and Labor'
                    "
                    (action)="togglePartsAndLabor(serviceDescription)">
                  </menu-cell-item>
                  <menu-cell-item
                    *ngIf="!isPartsAndLabor((productAllowances$ | async)?.[serviceDescription._id], serviceDescription)"
                    title="Calculate Product Allowance"
                    (action)="openCalculator(serviceDescription)">
                  </menu-cell-item>
                </menu-cell>
              </ng-template>
            </cell>
          </row>
        </body>
        <empty-state-message
          title="No services here"
          icon="shopping-basket"
          data-table-empty-state>
          No services found for the current search criteria, or no services have been added, yet
        </empty-state-message>
      </data-table>
    </card>
  </ng-container>
</page>
