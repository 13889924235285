<div
  class="order-lines"
  *ngIf="orderLineVMs.length >= 1">
  <span></span>
  <span class="order-line-header-cell">Service</span>
  <span class="order-line-header-cell quantity-cell">Amount</span>
  <span class="order-line-header-cell quantity-cell">Extra</span>
  <span class="order-line-header-cell quantity-cell">Total</span>

  <ng-container *ngFor="let line of orderLineVMs">
    <div
      class="arrow-container"
      [ngClass]="line === expandedLine ? 'expanded' : ''"
      (click)="lineClicked(line)">
      <fa-icon
        [ngClass]="line === expandedLine ? '' : 'rotated'"
        class="side-arrow"
        icon="sort-down">
      </fa-icon>
    </div>
    <div
      class="order-line-cell"
      (click)="lineClicked(line)">
      <div class="service-name">
        {{ line.serviceDescriptionName }}
      </div>
    </div>
    <span
      class="order-line-cell quantity-cell"
      (click)="lineClicked(line)">
      {{ line.totalWeight | localMeasurementUnit: measurementUnit }}
    </span>
    <span
      class="order-line-cell quantity-cell"
      (click)="lineClicked(line)">
      {{ line.productOveruseTotal | currency: currency | default: "-" }}
    </span>
    <span
      class="order-line-cell quantity-cell"
      (click)="lineClicked(line)">
      {{ line.total | currency: currency }}
    </span>

    <div
      class="order-line-detail"
      [@detailExpand]="line === expandedLine ? 'expanded' : 'collapsed'">
      <div class="expanded-detail-content">
        <div class="bowls">
          <ng-container *ngFor="let group of line.groupedUsageDetails">
            <span
              class="bowl-header"
              *ngIf="line.groupedUsageDetails.length > 1"
              >{{ group.name }}</span
            >
            <span
              class="bowl-header"
              *ngIf="line.groupedUsageDetails.length === 1"></span>
            <span class="bowl-column-header">Product</span>
            <span class="bowl-column-header quantity-cell">Weight</span>
            <span class="bowl-column-header quantity-cell">Cost</span>

            <ng-container *ngFor="let productUsageDetails of group.usageDetails">
              <div class="bowl-cell">
                <span class="colored-dot">
                  <colored-dot
                    [color]="productUsageDetails.hexColorCode"
                    size="medium"></colored-dot> </span
                >{{ productUsageDetails.productName }}
              </div>
              <span class="bowl-cell quantity-cell">{{ productUsageDetails.weight | localMeasurementUnit: measurementUnit }}</span>
              <span class="bowl-cell quantity-cell">
                {{ productUsageDetails.retailCost | currency: currency }}
              </span>
            </ng-container>
          </ng-container>
        </div>
        <hr />
        <div class="order-summary">
          <div class="order-summary-item">
            <span class="order-summary-label">Total Product Cost</span>
            <span class="order-summary-value">{{ line.totalProductCost | currency: currency | default: "-" }}</span>
          </div>
          <div class="order-summary-item">
            <span class="order-summary-label">Product Allowance</span>
            <span class="order-summary-value">{{ line.productAllowance | currency: currency | default: "-" }}</span>
          </div>
          <div class="order-summary-item">
            <span class="order-summary-label">Extra Product Charge</span>
            <span class="order-summary-value">{{ line.productOveruseTotal | currency: currency | default: "-" }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="orderLineVMs.length === 0">
  <alert-component type="info"> This appointment has no services </alert-component>
</div>

<div
  class="loading-container"
  *ngIf="loading">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
