import { Component, ChangeDetectionStrategy } from "@angular/core";
import { AppState } from "../../kernel/store";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { ProductReport, ProductCategoryReport } from "../../kernel/models";
import { Manufacturer, MeasurementUnit, ProductCategory, SalonProduct } from "@getvish/model";
import { switchMap, map, take } from "rxjs/operators";
import { getProductReport, getLoading, getStartDate, getEndDate } from "../store/product-report.selectors";
import { getProductCategoryReport } from "../store/product-category-report.selectors";
import { go } from "../../kernel/store/actions/router.actions";
import { getRootCategoryMap } from "../../+product/+product-categories/store/product-category.selectors";
import { getMeasurementUnitOrDefault } from "../../+salon-config/store/salon-config.selectors";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { DownloadManufacturerReport, UpdateDateRange, InitializeAndLoadProductReportGraph } from "../store/product-report.actions";

import * as fromManufacturer from "../../+product/+manufacturers/store/manufacturer.selectors";
import * as fromSalonProduct from "../../+product/+salon-products/store/salon-product.selectors";
import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";

@Component({
  selector: "product-report-dashboard-container",
  templateUrl: "product-report-dashboard.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductReportDashboardContainer {
  public productReport$: Observable<ProductReport>;
  public productCategoryReport$: Observable<ProductCategoryReport>;
  public manufacturers$: Observable<Manufacturer[]>;
  public salonProducts$: Observable<SalonProduct[]>;
  public manufacturer$: Observable<Manufacturer>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public loading$: Observable<boolean>;
  public manufacturerId$: Observable<string>;
  public rootCategoryMap$: Observable<Map<string, ProductCategory>>;
  public startDate$: Observable<number>;
  public endDate$: Observable<number>;
  public currency$: Observable<string>;
  public timeZone$: Observable<string>;

  constructor(
    private _store: Store<AppState>,
    route: ActivatedRoute
  ) {
    this.manufacturerId$ = route.paramMap.pipe(map((params: ParamMap) => params.get("manufacturerId")));

    this.productReport$ = _store.pipe(select(getProductReport));
    this.productCategoryReport$ = _store.pipe(select(getProductCategoryReport));
    this.manufacturers$ = _store.pipe(select(fromManufacturer.getManufacturers));
    this.rootCategoryMap$ = _store.pipe(select(getRootCategoryMap));
    this.salonProducts$ = _store.pipe(select(fromSalonProduct.getAll));
    this.startDate$ = _store.pipe(select(getStartDate));
    this.endDate$ = _store.pipe(select(getEndDate));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);

    this.manufacturer$ = this.manufacturerId$.pipe(
      switchMap((manufacturerId) => _store.pipe(select(fromManufacturer.getManufacturer(manufacturerId))))
    );

    this.measurementUnit$ = _store.pipe(select(getMeasurementUnitOrDefault));
    this.loading$ = _store.pipe(select(getLoading));

    this.manufacturerId$
      .pipe(
        map((manufacturerId) => new InitializeAndLoadProductReportGraph({ manufacturerId })),
        take(1)
      )
      .subscribe((action) => this._store.dispatch(action));
  }

  public updateDateRange(event: { startDate: number; endDate: number }): void {
    const { startDate, endDate } = event;

    this._store.dispatch(new UpdateDateRange({ startDate, endDate }));
  }

  public moreProducts(manufacturerId: string): void {
    this._store.dispatch(go({ path: [`/insights/products/${manufacturerId}`] }));
  }

  public downloadReport(): void {
    this._store.dispatch(new DownloadManufacturerReport());
  }
}
