import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { LongitudinalReport } from "../../kernel/models";
import { AnalyticsUtils, AVAILABLE_KEY_MAPPERS, ReportKeyMapper } from "../utils";
import { isNil } from "ramda";
import { MeasurementUnit } from "@getvish/model";
import { colorScheme } from "../common";

@Component({
  selector: "longitudinal-report",
  templateUrl: "./longitudinal-report.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LongitudinalReportComponent implements OnChanges {
  @Input() public report: LongitudinalReport;
  @Input() public aggregatedBy: string;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public locale: string;
  @Input() public currency: string;
  @Input() public enableComplianceTracking: boolean;
  @Output() public aggregation: EventEmitter<string>;

  public data: object[];

  public colorScheme = colorScheme;

  public activeEntries: any[] = [];

  private _enabledKeyMappers: string[] = [
    // 'Waste',
    // 'Services Performed',
    // 'Percent Waste',
    // 'Estimated Waste',
    // 'Percent Reweighed',
    // 'Estimated Product Wasted ($)',
    "Product Dispensed Per Service",
    "Product Waste Per Service",
  ];

  constructor() {
    this.aggregation = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    const report = this.report;
    this._prepareChart(report, AVAILABLE_KEY_MAPPERS, this._enabledKeyMappers, this.measurementUnit, this.locale);
  }

  public changeAggregation(aggregatedBy: string): void {
    this.aggregation.emit(aggregatedBy);
  }

  public toggleLine(lineName: string) {
    const enabledKeyMappers = this._enabledKeyMappers;

    this._enabledKeyMappers = enabledKeyMappers.find((name) => name === lineName)
      ? enabledKeyMappers.filter((name) => name !== lineName)
      : [...enabledKeyMappers, lineName];

    this._prepareChart(this.report, AVAILABLE_KEY_MAPPERS, this._enabledKeyMappers, this.measurementUnit, this.locale);
  }

  private _prepareChart(
    report: LongitudinalReport,
    keyMappers: ReportKeyMapper[],
    enabledKeyMappers: string[],
    measurementUnit: MeasurementUnit,
    locale: string
  ): void {
    const availableKeyMappers = this.enableComplianceTracking
      ? keyMappers
      : keyMappers.filter((mapper) => mapper.key !== "complianceRatio");

    this.computeActiveEntries();

    this.data = isNil(report)
      ? []
      : AnalyticsUtils.mapLongitudinalReportToNgxChartsDataSeries(report, availableKeyMappers, enabledKeyMappers, measurementUnit, locale);
  }

  public computeActiveEntries(): void {
    this.activeEntries = this._enabledKeyMappers.map((name) => ({ name, value: name }));
  }
}
