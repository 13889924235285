<div class="breadcrumbs-container">
  <ng-content></ng-content>
  <span
    class="breadcrumb-item"
    *ngFor="let item of items; trackBy: trackByFn"
    (click)="itemSelected.emit(item)">
    <fa-icon
      icon="chevron-right"
      size="xs"
      class="separator"></fa-icon>
    {{ item.name }}
  </span>
</div>
