import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Product, ProductCategory } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { isNil } from "ramda";
import { Observable, of } from "rxjs";
import { map, mergeMap, switchMap } from "rxjs/operators";
import { back } from "../../../kernel/store/actions/router.actions";
import { addOrUpdate } from "../store/product.actions";

import * as fromProductCategory from "../../../+product/+product-categories/store/product-category.selectors";
import * as fromProduct from "../store/product.selectors";

@Component({
  selector: "edit-product-container",
  templateUrl: "edit-product.container.html",
})
export class EditProductContainer {
  public product$: Observable<Product>;
  public manufacturerId$: Observable<string>;
  public categories$: Observable<ProductCategory[]>;
  public newMetadata$: Observable<{ manufacturerId?: string; parentCategoryId?: string }>;
  public saving$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.product$ = _route.params.pipe(
      map((params) => params["id"] as string),
      mergeMap((id) => (isNil(id) ? of(new Product()) : _store.pipe(select(fromProduct.getOne(id)))))
    );

    this.saving$ = _store.pipe(select(fromProduct.getSaving));

    this.categories$ = _store.pipe(select(fromProductCategory.getAll));

    this.manufacturerId$ = _route.queryParamMap.pipe(
      switchMap((params) => {
        if (params.has("manufacturerId")) {
          return of(params.get("manufacturerId"));
        }

        return this.product$.pipe(map((p) => p.manufacturerId));
      })
    );

    this.newMetadata$ = _store.pipe(select(fromProduct.getNewMetadata));
  }

  public save(product: Product): void {
    this._store.dispatch(addOrUpdate({ product }));
  }

  public close(): void {
    this._store.dispatch(back());
  }
}
