import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from "@angular/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { ManufacturerVm, fromManufacturer } from "../../../+manufacturers/models/manufacturer-view.model";
import { AppState } from "../../../../kernel";
import { select, Store } from "@ngrx/store";

import { map } from "rxjs/operators";
import { not } from "ramda";
import { Manufacturer, ProductCategory, SalonProduct } from "@getvish/model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fromProductCategories, ProductCategoryVM } from "../../../+salon-products/common";

import { ProductSelectionVM, fromSalonProducts } from "../../models/product-selection-vm";

import {
  load,
  search,
  pushCategory,
  popToCategory,
  clearCategories,
  previousStep,
  removeAllProducts,
  toggleProduct,
  selectManufacturer,
  selectProducts,
  deselectProducts,
  ProductSelectionMode,
  ProductSelectionType,
} from "../../store/product-selection.actions";

import * as fromProductSelection from "../../store/product-selection.selectors";

@Component({
  templateUrl: "./product-selection-dialog.component.html",
  styleUrls: ["../common/product-selection-dialog.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectionDialogComponent implements OnDestroy {
  public loading$: Observable<boolean>;

  public manufacturers$: Observable<ManufacturerVm[]>;
  public selectedManufacturer$: Observable<Manufacturer>;
  public salonProducts$: Observable<ProductSelectionVM[]>;
  public productCategories$: Observable<ProductCategoryVM[]>;

  public selectedCategories$: Observable<ProductCategory[]>;
  public selectedProducts$: Observable<SalonProduct[]>;

  public searchFilter$: Observable<string>;
  public breadcrumbsItems$: Observable<Array<{ _id: string; name: string }>>;
  public importStep$: Observable<number>;
  public allProductsSelected$: Observable<boolean>;

  public saveButtonText = "Save";
  public disableSelectAll = false;

  public trackByGroup = (_: number, value: { manufacturer: Manufacturer; products: SalonProduct[] }): string => value.manufacturer._id;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<ProductSelectionDialogComponent, { salonProducts: SalonProduct[]; manufacturer: Manufacturer }>,
    @Inject(MAT_DIALOG_DATA)
    _data: {
      mode?: ProductSelectionMode;
      type?: ProductSelectionType;
      saveButtonText?: string;
      disableSelectAll?: boolean;
    }
  ) {
    this.loading$ = this._store.pipe(select(fromProductSelection.getLoading));
    this.searchFilter$ = this._store.pipe(select(fromProductSelection.getSearchFilter));

    this.selectedManufacturer$ = this._store.pipe(select(fromProductSelection.getSelectedManufacturer));

    this.selectedProducts$ = this._store.pipe(select(fromProductSelection.getSelectedProducts));

    this.salonProducts$ = combineLatest([
      this._store.pipe(select(fromProductSelection.getProductsForSelectedManufacturer)),
      this._store.pipe(select(fromProductSelection.getSelectedProducts)),
      this._store.pipe(select(fromProductSelection.getAvailableCategories)),
      this._store.pipe(select(fromProductSelection.getSelectedCategories)),
    ]).pipe(
      map(([salonProducts, selectedSalonProducts, productCategories, selectedCategories]) =>
        fromSalonProducts(salonProducts, selectedSalonProducts, productCategories, selectedCategories)
      )
    );

    this.allProductsSelected$ = this.salonProducts$.pipe(
      map((products) => products.every((product) => product.selected || product.inactive))
    );

    this.productCategories$ = combineLatest([
      this._store.pipe(select(fromProductSelection.getAvailableCategories)),
      this._store.pipe(select(fromProductSelection.getSelectedCategories)),
      this._store.pipe(select(fromProductSelection.getProductsForSelectedManufacturer)),
    ]).pipe(
      map(([productCategories, selectedCategories, products]) =>
        fromProductCategories(
          productCategories,
          selectedCategories,
          products.filter((p) => !p.flags?.includes("INACTIVE"))
        )
      )
    );

    this.manufacturers$ = combineLatest([
      this._store.pipe(select(fromProductSelection.getFilteredManufacturers)),
      this._store.pipe(select(fromProductSelection.getSalonProducts)),
    ]).pipe(map(([manufacturers, salonProducts]) => manufacturers.map(fromManufacturer(salonProducts))));

    this.importStep$ = this._store.pipe(select(fromProductSelection.getImportStep));

    this.breadcrumbsItems$ = this._store.pipe(select(fromProductSelection.getSelectedCategories));

    this._store.dispatch(load({ psMode: _data.mode, psType: _data.type }));

    if (_data.saveButtonText) {
      this.saveButtonText = _data.saveButtonText;
    }

    if (_data.disableSelectAll != null) {
      this.disableSelectAll = _data.disableSelectAll;
    }
  }

  public selectManufacturer(manufacturer: Manufacturer): void {
    this._store.dispatch(selectManufacturer({ manufacturer }));
  }

  public toggleProduct(productVM: ProductSelectionVM): void {
    this._store.dispatch(toggleProduct({ product: productVM.product as SalonProduct }));
  }

  public selectProducts(productVMs: ProductSelectionVM[]): void {
    const products = productVMs.filter((productVM) => not(productVM.inactive)).map((productVM) => productVM.product as SalonProduct);

    this._store.dispatch(selectProducts({ products }));
  }

  public deselectProducts(productVMs: ProductSelectionVM[]): void {
    const products = productVMs.map((productVM) => productVM.product as SalonProduct);

    this._store.dispatch(deselectProducts({ products }));
  }

  public setSearchFilter(filter: string): void {
    this._store.dispatch(search({ filter }));
  }

  public pushCategory(category: ProductCategory): void {
    this._store.dispatch(pushCategory({ category }));
  }

  public popToCategory(categoryId: string): void {
    this._store.dispatch(popToCategory({ categoryId }));
  }

  public clearSelectedCategories(): void {
    this._store.dispatch(clearCategories());
  }

  public previousStep(): void {
    this._store.dispatch(previousStep());
  }

  public save(salonProducts: SalonProduct[], manufacturer: Manufacturer): void {
    this._matDialogRef.close({ salonProducts, manufacturer });
  }

  public dismiss(): void {
    this._matDialogRef.close();
  }

  public removeAllProducts(): void {
    this._store.dispatch(removeAllProducts());
  }

  public ngOnDestroy(): void {
    this._onDestroy$.next();
  }
}
