import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { tenantEntitlementsReducer, tenantEntitlementsFeatureKey } from "./store/tenant-entitlements.reducer";
import { TenantEntitlementsEffects } from "./store/tenant-entitlements.effects";
import { SharedModule } from "../shared";
import { TenantEntitlementsService } from "./services/tenant-entitlements.service";

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature(tenantEntitlementsFeatureKey, tenantEntitlementsReducer),
    EffectsModule.forFeature([TenantEntitlementsEffects]),
  ],
  providers: [TenantEntitlementsService],
})
export class TenantEntitlementsModule {}
