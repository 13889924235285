<generic-mixing-container
  [mixingContainerVM]="mixingContainerVM"
  [mixingContainerConfig]="mixingContainerConfig"
  [clipPathId]="clipPathId">
  <svg
    width="0"
    height="0">
    <defs>
      <clipPath
        [id]="clipPathId"
        clipPathUnits="objectBoundingBox">
        <path
          d="M0,0.033 C0,0.015,0.007,0,0.015,0 L0.985,0 C0.993,0,1,0.015,1,0.033 C1,0.051,0.993,0.066,0.985,0.066 L0.974,0.066 C0.974,0.129,0.969,0.33,0.918,0.529 C0.858,0.762,0.737,0.894,0.734,0.898 L0.734,0.967 C0.734,0.985,0.727,1,0.718,1 L0.28,1 C0.271,1,0.265,0.985,0.265,0.967 L0.265,0.898 L0.264,0.898 C0.264,0.898,0.141,0.765,0.08,0.529 C0.029,0.33,0.024,0.129,0.023,0.066 L0.015,0.066 C0.007,0.066,0,0.051,0,0.033 M0.499,0.898 L0.499,0.066 L0.499,0.066 L0.499,0.898 M0.499,0.898"></path>
      </clipPath>
    </defs>
  </svg>
</generic-mixing-container>
