<slideout-panel (close)="close()">
  <div panel-header>Import Data</div>

  <div panel-body>
    <div *ngIf="error$ | async as error">
      <alert-component type="danger">
        <h5>Something went wrong</h5>

        <p>{{ error }}</p>
      </alert-component>
    </div>
    <div *ngIf="salon$ | async as salon">
      <div [ngSwitch]="step$ | async">
        <div *ngSwitchDefault>
          <div class="page-content">
            <h4>
              Export/Import Data for
              <span class="highlight">{{ salon.name }}</span>
            </h4>
            <p>Exporting data will fetch an Excel spreadsheet with separate Customer, Employee, and Service Menu sheets.</p>
          </div>

          <div class="controls">
            <button
              (click)="exportData(salon)"
              [disabled]="exporting$ | async"
              class="button outline">
              <span *ngIf="(exporting$ | async) === false; else loadingIndicator">Export Data</span>
              <ng-template #loadingIndicator>
                <fa-icon
                  *ngIf="exporting$ | async"
                  [icon]="['fas', 'circle-notch']"
                  [spin]="true"></fa-icon>
              </ng-template>
            </button>
            <button
              (click)="initializeSession(salon)"
              [disabled]="exporting$ | async"
              class="button primary">
              <span>Next</span>
            </button>
          </div>
        </div>

        <div *ngSwitchCase="2">
          <div class="page-content">
            <upload-csv
              title="Customer"
              [loading]="uploading$ | async"
              [report]="report$('customer') | async"
              type="customer"
              (selectFile)="uploadFile($event, salon)"
              (next)="next()">
            </upload-csv>
          </div>
        </div>

        <div *ngSwitchCase="3">
          <div class="page-content">
            <upload-csv
              title="Employee"
              [loading]="uploading$ | async"
              [report]="report$('employee') | async"
              type="employee"
              (selectFile)="uploadFile($event, salon)"
              (next)="next()">
            </upload-csv>
          </div>
        </div>

        <div *ngSwitchCase="4">
          <div class="page-content">
            <upload-csv
              title="Service Menu"
              [loading]="uploading$ | async"
              [report]="report$('serviceDescription') | async"
              type="serviceDescription"
              (selectFile)="uploadFile($event, salon)"
              (next)="runSession(salon)">
            </upload-csv>
          </div>
        </div>

        <div *ngSwitchCase="5">
          <div class="page-content">
            <h4>Data export/import complete</h4>

            <div
              *ngIf="reports$ | async as reports; else emptyElement"
              class="page-content">
              <alert-component type="success">
                <p>Data uploaded successfully. A confirmation email will be sent to you once processing completes</p>
              </alert-component>
            </div>
            <ng-template #emptyElement>
              <alert-component type="info"> Seems like nothing happened. </alert-component>
            </ng-template>
            <div class="controls">
              <button
                (click)="done()"
                class="button primary">
                <span>Done</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading$ | async">
      <large-loading-indicator></large-loading-indicator>
    </div>
  </div>
</slideout-panel>
