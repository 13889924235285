import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Directive, ElementRef, Input, OnChanges, OnDestroy } from "@angular/core";
import { ProgressIndicatorComponent } from "../components/progress-indicator.component";
import { BackdropComponent } from "../components/backdrop.component";
import { ResizeService } from "app/kernel";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";

@Directive({
  selector: "[progressIndicator]",
})
export class ProgressIndicatorDirective implements OnChanges, OnDestroy {
  @Input() progressIndicator: string;
  @Input() progressIndicatorMode: ProgressSpinnerMode;
  @Input() progressIndicatorValue: number;

  private backdropRef: OverlayRef;
  private overlayRef: OverlayRef;
  private overlayComponentRef: ComponentRef<ProgressIndicatorComponent>;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private resizeService: ResizeService
  ) {}

  private _createBackdropPositionStrategy() {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: "start",
          originY: "top",
          overlayX: "start",
          overlayY: "top",
          panelClass: "full-width-height",
        },
      ]);
  }

  private _createOverlayPositionStrategy() {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: "center",
          originY: "center",
          overlayX: "center",
          overlayY: "center",
        },
      ]);
  }

  ngOnChanges(): void {
    this._updateOverlay();
  }

  private _updateOverlay() {
    if (this.progressIndicator != null) {
      if (!this.overlayRef) {
        const isDialog = this.resizeService.getClosestDialog(this.elementRef.nativeElement) != null;
        const element = this.elementRef.nativeElement as HTMLElement;

        this.backdropRef = this.overlay.create({
          positionStrategy: this._createBackdropPositionStrategy(),
        });

        if (isDialog) {
          this.backdropRef.updateSize({ width: element.clientWidth, height: element.clientHeight });
        }

        this.overlayRef = this.overlay.create({
          positionStrategy: this._createOverlayPositionStrategy(),
        });

        this.backdropRef.attach(new ComponentPortal(BackdropComponent));

        this.overlayComponentRef = this.overlayRef.attach(new ComponentPortal(ProgressIndicatorComponent));

        this.resizeService.addResizeEventListener(
          this.elementRef.nativeElement,
          () => {
            if (this.overlayRef) {
              this.backdropRef.updatePositionStrategy(this._createBackdropPositionStrategy());

              if (isDialog) {
                this.backdropRef.updateSize({ width: element.clientWidth, height: element.clientHeight });
              }

              this.overlayRef.updatePositionStrategy(this._createOverlayPositionStrategy());
            }
          },
          true
        );
      }

      this.overlayComponentRef.instance.text = this.progressIndicator;
      this.overlayComponentRef.instance.mode = this.progressIndicatorMode ?? "indeterminate";
      this.overlayComponentRef.instance.value = this.progressIndicatorValue;
    } else if (this.overlayRef) {
      this._destroyOverlay();
    }
  }

  private _destroyOverlay() {
    this.resizeService.removeResizeEventListener(this.elementRef.nativeElement);

    this.backdropRef.detach();
    this.overlayRef.detach();

    this.backdropRef = null;
    this.overlayRef = null;
    this.overlayComponentRef = null;
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this._destroyOverlay();
    }
  }
}
