import { NgModule } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { DataTableModule } from "app/+components";
import { KernelServicesModule } from "app/kernel";
import { SharedModule } from "../../kernel/shared";
import { ManufacturerCardComponent } from "./components/manufacturer-card/manufacturer-card.component";
import { ManufacturersListComponent } from "./components/manufacturers-list/manufacturers-list.component";
import { AdminManufacturersTableContainer } from "./containers/admin-manufacturers-table.container";
import { ManufacturerService } from "./services";

@NgModule({
  imports: [DataTableModule, KernelServicesModule, SharedModule, MatChipsModule],
  declarations: [AdminManufacturersTableContainer, ManufacturerCardComponent, ManufacturersListComponent],
  exports: [AdminManufacturersTableContainer, ManufacturerCardComponent, ManufacturersListComponent],
  providers: [ManufacturerService],
})
export class ManufacturersSharedModule {}
