import { Component, ChangeDetectionStrategy } from "@angular/core";
import { AppState } from "../../kernel/store";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { Manufacturer, MeasurementUnit } from "@getvish/model";
import { getLoading, getStartDate, getEndDate } from "../store/product-report.selectors";
import { getManufacturerReport } from "../store/manufacturer-report.selectors";
import { getMeasurementUnitOrDefault } from "../../+salon-config/store/salon-config.selectors";
import { go } from "../../kernel/store/actions/router.actions";
import { InitializeAndLoadManufacturerReport, UpdateManufacturerReportFilters } from "../store/product-report.actions";
import { ManufacturerReport } from "../../kernel/models/manufacturer-report";

import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";

@Component({
  selector: "manufacturer-report-container",
  templateUrl: "manufacturer-report.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerReportContainer {
  public manufacturerReport$: Observable<ManufacturerReport>;
  public manufacturers$: Observable<Manufacturer[]>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public loading$: Observable<boolean>;
  public startDate$: Observable<number>;
  public endDate$: Observable<number>;
  public currency$: Observable<string>;
  public timeZone$: Observable<string>;

  constructor(private _store: Store<AppState>) {
    this.manufacturerReport$ = _store.pipe(select(getManufacturerReport));
    this.measurementUnit$ = _store.pipe(select(getMeasurementUnitOrDefault));
    this.loading$ = _store.pipe(select(getLoading));
    this.startDate$ = _store.pipe(select(getStartDate));
    this.endDate$ = _store.pipe(select(getEndDate));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);

    this._store.dispatch(new InitializeAndLoadManufacturerReport());
  }

  public updateFilters(event: { startDate: number; endDate: number }): void {
    const { startDate, endDate } = event;

    this._store.dispatch(new UpdateManufacturerReportFilters({ startDate, endDate }));
  }

  public selectManufacturer(id: string): void {
    this._store.dispatch(go({ path: [`/insights/dashboard/${id}`] }));
  }
}
