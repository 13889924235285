import { Action } from "@ngrx/store";
import { NavigationExtras } from "@angular/router";

export enum RouterActionTypes {
  GO = "[Router] Go",
  BACK = "[Router] Back",
  FORWARD = "[Router] Forward",
}

export class Go implements Action {
  public readonly type = RouterActionTypes.GO;

  constructor(public payload: { path: any[]; query?: object; extras?: NavigationExtras }) {}
}

export function go(payload: { path: any[]; query?: object; extras?: NavigationExtras }) {
  const { path, query, extras } = payload;
  return new Go({ path, query, extras });
}

export class Back implements Action {
  public readonly type = RouterActionTypes.BACK;
}

export function back() {
  return new Back();
}

export class Forward implements Action {
  public readonly type = RouterActionTypes.FORWARD;
}

export function forward() {
  return new Forward();
}

export type Actions = Go | Back | Forward;
