import { Manufacturer, SalonProduct } from "@getvish/model";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { hasSearchTerm } from "app/+product/common/utils";
import { allPass, includes, equals, isNil, isEmpty, uniqBy, sortWith } from "ramda";
import { salonProductFilters } from "../common";
import { SalonProductState } from "./salon-product.reducer";
import { pipe } from "fp-ts/lib/function";

/*** SELECTORS ***/
export const getState = createFeatureSelector<SalonProductState>("salonProducts");

export const getLoading = createSelector(getState, (state) => state.loading);
export const getSaving = createSelector(getState, (state) => state.saving);
export const getSavingMarkup = createSelector(getState, (state) => state.savingMarkup);
export const getError = createSelector(getState, (state) => state.error);

export const getUploading = createSelector(getState, (state) => state.uploading);

export const getUploadingErrors = createSelector(getState, (state) => state.uploadingErrors);

export const getUploadingSuccess = createSelector(getState, (state) => state.uploadingSuccess);

export const getSearchFilter = createSelector(getState, (state) => state.searchFilter);

export const getSelectedCategories = createSelector(getState, (state) => state.selectedProductCategories);

export const getSelectedProducts = createSelector(getState, (state) => state.selectedProducts);

export const getSelectedManufacturerId = createSelector(getState, (state) => state.selectedManufacturerId);

export const selectProductIdsByCategoryId = (categoryId: string) =>
  createSelector(getAll, (records) => records.filter((record) => record.categoryId === categoryId).map((record) => record.productId));

export const getFlagFilters = createSelector(getState, (state) => state.flagFilters);

export const getPaging = createSelector(getState, (state) => state.paging);

export const getAll = createSelector(getState, (state) => state.records);

export const getOne = (id: string) => createSelector(getAll, (records) => records.find((_record) => equals(id, _record._id)));

export const getFilteredProducts = createSelector(getAll, getSearchFilter, getFlagFilters, (products, searchFilter, flagFilters) => {
  const activeFilters = salonProductFilters.filter((item) => includes(item.key, flagFilters)).map((filter) => filter.fn);

  const bySearchFilter = (product: SalonProduct) => hasSearchTerm(searchFilter)(product) || hasSearchTerm(searchFilter)(product.category);

  return products.filter(bySearchFilter).filter(allPass(activeFilters));
});

export const getSalonManufacturers = createSelector(getState, (state) => state.salonManufacturers);
export const getSalonProductCategories = createSelector(getState, (state) => state.salonProductCategories);

export const getManufacturers = createSelector(getState, (state) => state.manufacturers);

export const getActiveManufacturers = createSelector(getSalonManufacturers, getManufacturers, (salonManufacturers, manufacturers) => {
  const manufacturersById = manufacturers.reduce((acc, m) => {
    acc[m._id] = m;
    return acc;
  }, {});

  const sortedSalonManufacturers = pipe(
    salonManufacturers,
    uniqBy((sm) => sm.manufacturerId),
    sortWith([
      (sm1, sm2) => (sm1.order ?? Number.MAX_SAFE_INTEGER) - (sm2.order ?? Number.MAX_SAFE_INTEGER),
      (sm1, sm2) => manufacturersById[sm1.manufacturerId].name.localeCompare(manufacturersById[sm2.manufacturerId].name),
    ])
  );

  return sortedSalonManufacturers.map((sm) => manufacturersById[sm.manufacturerId]);
});

export const getHasActiveManufacturers = createSelector(
  getActiveManufacturers,
  (manufactures: Manufacturer[]) => !isNil(manufactures) && !isEmpty(manufactures)
);

export const getManufacturerByNameFilter = createSelector(getActiveManufacturers, getSearchFilter, (manufacturers, searchFilter) =>
  manufacturers.filter(hasSearchTerm(searchFilter))
);

export const getIncompletePricingFilter = createSelector(getState, (state: SalonProductState): boolean => state.pricingIncompleteFilter);
