<div class="page-content">
  <div class="page-header">
    <h1>
      <span class="content">{{ manufacturer?.name }} Product Usage Report</span>
    </h1>
    <div class="header-controls">
      <!--
        so, this is all _one_ component, right?
        basically the <date-range> _needs_ the <date-picker> component or else it can't really do its job?
        if that's the case we should just wrap them both up into one component
        so you don't have to remember to always render a <date-picker> via [matTooltip] ...
        and we could do things like automatically close the overlay when the user chooses a date
        right now we'd have to manually do the work from the calling component, which is awkward
      -->
      <mat-menu #datepickerMenu="matMenu">
        <date-picker
          [startDate]="startDate"
          [endDate]="endDate"
          [timeZone]="timeZone"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (update)="updateDateRange.emit($event)"></date-picker>
      </mat-menu>
      <date-range
        [startDate]="startDate"
        [endDate]="endDate"
        [timeZone]="timeZone"
        [matMenuTriggerFor]="datepickerMenu"
        matTooltip="Change date range"></date-range>
      <!--  -->
    </div>
  </div>
  <div id="main-content">
    <ng-template #loadingTemplate>
      <div class="info-container">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </ng-template>
    <div *ngIf="!loading; else loadingTemplate">
      <div
        *ngIf="reportHasData; else emptyState"
        class="row gutters">
        <section class="col col-6">
          <div class="card">
            <header>
              <h3>Top Categories</h3>
            </header>
            <div class="chart-container">
              <ngx-charts-bar-horizontal
                [scheme]="colorScheme"
                [results]="data"
                xAxis="true"
                yAxis="true"
                [legend]="false"
                [showXAxisLabel]="true"
                [showYAxisLabel]="true"
                [xAxisLabel]="'Cost' | appendCurrencySymbol: currency"
                yAxisLabel="Category"></ngx-charts-bar-horizontal>
            </div>
          </div>
        </section>
        <section class="col col-6">
          <div class="card">
            <header>
              <h3>Top Products</h3>
            </header>
            <div class="chart-container">
              <ngx-charts-bar-horizontal
                [scheme]="colorScheme"
                [results]="productData"
                xAxis="true"
                yAxis="true"
                [legend]="false"
                [showXAxisLabel]="true"
                [showYAxisLabel]="true"
                [xAxisLabel]="'Cost' | appendCurrencySymbol: currency"
                yAxisLabel="Product"></ngx-charts-bar-horizontal>
            </div>
          </div>
        </section>
        <section class="col col-12">
          <div class="card">
            <header>
              <h3>Product Categories</h3>
            </header>
            <div class="products-list-container">
              <table *ngIf="categoryReport | notEmpty; else emptyCategoryReport">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Dispensed</th>
                    <th>Waste</th>
                    <th>Wholesale Cost</th>
                    <th>Retail Price</th>
                    <th># Services</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let statistic of topCategoryStatistics"
                    class="product-row">
                    <td class="icon-container">
                      <colored-icon [title]="statistic.entityName"></colored-icon>
                    </td>
                    <td>
                      <span class="row-title">{{ statistic.entityName }}</span>
                    </td>
                    <td>{{ statistic.metrics.totalAmountGrams | localMeasurementUnit: measurementUnit }}</td>
                    <td>{{ statistic.metrics.wasteAmountGrams | localMeasurementUnit: measurementUnit }}</td>
                    <td>{{ statistic.metrics.wholesaleCostDollars | currency: currency }}</td>
                    <td>{{ statistic.metrics.retailCostDollars | currency: currency }}</td>
                    <td>{{ statistic.metrics.numServicesPerformed }}</td>
                  </tr>
                </tbody>
              </table>
              <ng-template #emptyCategoryReport>
                <empty-state-message
                  title="No data here"
                  icon="calendar-day">
                  <p>We couldn't find any data for the selected range, adjust the dates to view data</p>
                </empty-state-message>
              </ng-template>
            </div>
          </div>
        </section>
        <section class="col col-12">
          <div class="card">
            <header>
              <h3>Top Products</h3>
              <div class="top-controls">
                <a
                  (click)="downloadReport.emit()"
                  matTooltip="Download report as spreadsheet"
                  class="button secondary transparent">
                  <fa-icon icon="cloud-download-alt"></fa-icon>
                </a>
                <mat-menu #moreOptionsMenu="matMenu">
                  <button
                    mat-menu-item
                    (click)="moreProducts.emit(manufacturerId)">
                    View detailed report
                  </button>
                </mat-menu>
                <button
                  [matMenuTriggerFor]="moreOptionsMenu"
                  class="button secondary transparent">
                  <fa-icon icon="ellipsis-v"></fa-icon>
                </button>
              </div>
            </header>
            <div class="products-list-container">
              <product-report-table
                [take]="20"
                [statistics]="productReport.productStatistics"
                [salonProducts]="salonProducts"
                [currency]="currency"
                [measurementUnit]="measurementUnit"
                [rootCategoryMap]="rootCategoryMap">
              </product-report-table>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ng-template #emptyState>
      <empty-state-message
        title="No data here"
        icon="calendar-day">
        We couldn't find any data for the selected range, adjust the dates to view data
      </empty-state-message>
    </ng-template>
  </div>
</div>
