<div class="content">
  <button
    id="brand-logo"
    (click)="navigateHome.emit()"
    [disabled]="!currentSalon"
    [class.next]="appConfig.apiVersion === 'V2'">
    <span
      class="api-version-badge"
      *ngIf="appConfig.apiVersion === 'V2'"
      >Next</span
    >
  </button>
  <div class="main-content">
    <div id="menu-button-container">
      <div id="menu-button">
        <button
          (click)="toggleMenu()"
          *ngIf="currentSalon">
          <fa-icon icon="bars"></fa-icon>
        </button>
      </div>
    </div>
    <nav id="menu-items">
      <ul id="app-menu-items-list">
        <li class="help-docs-link">
          <a href="https://docs.getvish.com/docs/2.0/dashboard-getting-started">
            <fa-icon icon="question-circle"></fa-icon>
            <span>Help</span>
          </a>
        </li>
        <li class="salon-item">
          <ng-container *ngIf="isRoot">
            <mat-menu
              #salonPickerMenu="matMenu"
              class="salon-picker">
              <div
                mat-menu-item
                (click)="$event.stopPropagation()">
                <autocomplete-search-input>
                  <input
                    type="text"
                    placeholder="Search for salons..."
                    matInput
                    [formControl]="formControl"
                    [value]="salonFilter"
                    [matAutocomplete]="salonAutocomplete" />
                </autocomplete-search-input>
                <mat-autocomplete
                  #salonAutocomplete="matAutocomplete"
                  [displayWith]="displaySalons(salons)"
                  (optionSelected)="changeSalonOnSelection($event)">
                  <mat-option
                    *ngFor="let salon of salons"
                    [value]="salon">
                    <span>{{ salon.name }}</span>
                  </mat-option>
                </mat-autocomplete>
              </div>
            </mat-menu>
            <button
              class="button transparent"
              [matMenuTriggerFor]="salonPickerMenu">
              <fa-icon icon="list"></fa-icon>
              <span style="margin-right: 10px">{{ currentSalon?.name }}</span>
            </button>
          </ng-container>
          <ng-container *ngIf="!isRoot">
            <mat-menu
              #salonPickerMenu="matMenu"
              class="salon-picker">
              <button
                *ngFor="let salon of salons"
                mat-menu-item
                (click)="changeSalon.emit(salon)">
                {{ salon.name }}
              </button>
            </mat-menu>

            <button
              class="button transparent"
              [matMenuTriggerFor]="salonPickerMenu"
              [disabled]="salons.length < 2">
              <fa-icon icon="list"></fa-icon>
              <span style="margin-right: 10px">{{ currentSalon?.name }}</span>
            </button>
          </ng-container>
        </li>
        <li class="notification-item">
          <notification-bell></notification-bell>
        </li>
        <li
          *ngIf="user"
          class="user-avatar">
          <i class="fa fa-user-o"></i>
          {{ user?.firstName }} {{ user?.lastName }}
          <mat-menu
            #userLogoutMenu="matMenu"
            class="logout">
            <button
              (click)="logout.emit()"
              class="button transparent">
              Log Out<i class="fa fa-sign-out"></i>
            </button>
          </mat-menu>
          <fa-icon
            icon="user-circle"
            [matMenuTriggerFor]="userLogoutMenu"></fa-icon>
        </li>
      </ul>
    </nav>
  </div>
</div>
