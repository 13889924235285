import { Injectable } from "@angular/core";
import { Action } from "@ngrx/store";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, map, mergeMap, debounceTime } from "rxjs/operators";
import { either } from "fp-ts";
import { Appointment } from "@getvish/model";
import { AppointmentService, ScheduleAppointmentService } from "../services";

import * as actions from "./schedule-appointment.actions";
import * as appointmentActions from "./appointment.actions";
import * as customerActions from "../../+customers/store/customer.actions";
import * as RouterActions from "../../kernel/store/actions/router.actions";

@Injectable()
export class ScheduleAppointmentEffects {
  public navigate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.navigate),
      map(() => RouterActions.go({ path: ["/front-desk", { outlets: { panel: "new" } }] }))
    )
  );

  public load$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.load),
      switchMap(() => this._scheduleAppointmentService.loadData().pipe(mergeMap(({ employees }) => [actions.loadSuccess({ employees })])))
    )
  );

  public schedule$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.schedule),
      map((action) => action.appointment),
      switchMap((appointment) =>
        this._appointmentService.insert(appointment).pipe(
          mergeMap(
            either.fold<Error, Appointment, Action[]>(
              (error) => [actions.fail({ error })],
              () => [actions.success({ appointment })]
            )
          )
        )
      )
    )
  );

  public navigateToAppointmentsListOnSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.SUCCESS, actions.close, actions.cancel),
      map(() => appointmentActions.navigateToAppointmentsList())
    )
  );

  public searchCustomer = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.searchCustomers),
      debounceTime(500),
      map((action) => action.filter),
      map((filter) => {
        return new customerActions.LoadAll({ filter });
      })
    )
  );

  constructor(
    private _scheduleAppointmentService: ScheduleAppointmentService,
    private _appointmentService: AppointmentService,
    private _actions$: Actions
  ) {}
}
