<header-controls>
  <button
    class="button outline"
    [disabled]="form.invalid"
    (click)="saveForm(config, form.value)">
    Save
  </button>
</header-controls>
<form
  [formGroup]="form"
  (submit)="saveForm(config, form.value)"
  novalidate>
  <div class="full-width">
    <mat-form-field class="half-width">
      <mat-label>Minimum Container Weight</mat-label>
      <input
        matInput
        formControlName="minimumContainerWeight"
        type="number" />
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field class="half-width">
      <mat-label>Measurement Unit</mat-label>
      <mat-select formControlName="measurementUnit">
        <mat-option
          *ngFor="let option of measurementUnitOptions"
          [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="half-width">
    <mat-label>Automatic Logout</mat-label>
    <div
      class="auto-logout-container"
      [ngClass]="{ active: autoLogoutEnabled$ | async }">
      <mat-slide-toggle formControlName="autoLogoutEnabled"></mat-slide-toggle>
      <mat-form-field class="full-width">
        <mat-label>Timeout</mat-label>
        <mat-select
          [disabled]="(autoLogoutEnabled$ | async) === false"
          formControlName="autoLogoutInterval">
          <mat-option
            *ngFor="let option of autoLogoutIntervals"
            [value]="option">
            {{ option | formatDuration: config.locale }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('autoLogoutInterval').hasError">This field is required</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="full-width">
    <mat-form-field class="half-width">
      <mat-label>Container Weight Tolerance</mat-label>
      <input
        matInput
        formControlName="containerWeightTolerance"
        step="0.1"
        type="number" />
      <mat-error *ngIf="form.get('containerWeightTolerance').hasError">Tolerance must be between 0.25 and 1.0</mat-error>
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field
      class="half-width"
      [ngClass]="{ 'field-pulse': form.value.currency | isNil }">
      <mat-label>Currency</mat-label>
      <mat-select formControlName="currency">
        <mat-option
          *ngFor="let option of currencies"
          [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field class="half-width">
      <mat-label>Restricted Scheduling</mat-label>
      <mat-select formControlName="restrictedScheduling">
        <mat-option [value]="true">On</mat-option>
        <mat-option [value]="false">Off</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field
      class="half-width"
      [ngClass]="{ 'field-pulse': form.value.timeZone | isNil }">
      <mat-label>Time Zone</mat-label>
      <input
        type="text"
        aria-label="Time Zone"
        matInput
        formControlName="timeZone"
        [matAutocomplete]="timeZoneAuto" />
      <mat-error *ngIf="form.get('timeZone').hasError('matchesOneOf')">Not a valid Time Zone</mat-error>
    </mat-form-field>
    <mat-autocomplete
      autoActiveFirstOption
      #timeZoneAuto="matAutocomplete">
      <mat-option
        *ngFor="let timeZone of filteredTimeZones$ | async"
        [value]="timeZone.value">
        {{ timeZone.value }}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div class="full-width">
    <mat-form-field
      class="half-width"
      [ngClass]="{ 'field-pulse': form.value.locale | isNil }">
      <mat-label>Locale</mat-label>
      <mat-select formControlName="locale">
        <mat-option
          *ngFor="let option of localeOptions"
          [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field class="half-width">
      <mat-label>Syncing Mode</mat-label>
      <mat-select formControlName="syncingMode">
        <mat-option value="real-time">Real-time</mat-option>
        <mat-option value="long-polling">Long-polling</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="full-width">
    <mat-form-field class="half-width">
      <mat-label>Default Product Markup</mat-label>
      <input
        matInput
        formControlName="defaultProductMarkupPercent"
        type="number"
        min="0"
        step="25" />
      <span matSuffix>%</span>
    </mat-form-field>
  </div>

  <div
    *ngIf="isRoot === true"
    class="full-width">
    <mat-form-field class="half-width">
      <mat-label>Compliance Module</mat-label>
      <mat-select formControlName="enableComplianceTracking">
        <mat-option [value]="true">On</mat-option>
        <mat-option [value]="false">Off</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
