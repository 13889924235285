import { SalonProductModule } from "@getvish/model";
import { MixingContainerVM } from "app/+components";
import { ProductAllowanceBlueprintVM, ProductAllowanceVM } from "app/kernel/models/product-allowance";
import { none, some } from "fp-ts/lib/Option";

export const calculateProductAllowanceTotal = (productAllowance: ProductAllowanceVM): number => {
  const productsTotal = _calculateProductAllowanceProductsTotal(productAllowance);

  return productsTotal + _calculateProductAllowanceDeveloperTotal(productAllowance);
};

const _calculateProductAllowanceProductsTotal = (productAllowance: ProductAllowanceVM): number => {
  return (
    productAllowance?.blueprints?.reduce((acc, bowl) => {
      return (
        bowl.pigments?.reduce((acc2, ingredient) => {
          acc2 += (SalonProductModule.getRetailPrice(ingredient.product) / ingredient.product.containerSize) * ingredient.weight.value;
          return acc2;
        }, acc) || acc
      );
    }, 0) || 0
  );
};

const _calculateProductAllowanceDeveloperTotal = (productAllowance: ProductAllowanceVM): number => {
  return (
    productAllowance?.blueprints?.reduce((acc, bowl) => {
      const productsTotalWeight =
        bowl.pigments?.reduce((acc2, ingredient) => {
          acc2 += ingredient.weight.value;
          return acc2;
        }, 0) || 0;

      return (
        bowl.developers?.reduce((acc2, ingredient) => {
          return (
            acc2 +
            (SalonProductModule.getRetailPrice(ingredient.product) / ingredient.product.containerSize) *
              (ingredient.weight.value * productsTotalWeight)
          );
        }, acc) || acc
      );
    }, 0) || 0
  );
};

export const calculateBlueprintBowlProductsTotal = (bowl: ProductAllowanceBlueprintVM): number => {
  return (
    bowl.pigments?.reduce(
      (acc, i) => (acc += (SalonProductModule.getRetailPrice(i.product) / i.product.containerSize) * i.weight.value),
      0
    ) ?? 0
  );
};

export const calculateBlueprintBowlDeveloperTotal = (bowl: ProductAllowanceBlueprintVM): number => {
  const productWeight = bowl.pigments?.reduce((acc, i) => acc + i.weight.value, 0) ?? 0;

  return (
    bowl.developers?.reduce(
      (acc, i) => (acc += (SalonProductModule.getRetailPrice(i.product) / i.product.containerSize) * (i.weight.value * productWeight)),
      0
    ) ?? 0
  );
};

export const calculateBlueprintBowlTotal = (bowl: ProductAllowanceBlueprintVM): number => {
  return calculateBlueprintBowlProductsTotal(bowl) + calculateBlueprintBowlDeveloperTotal(bowl);
};

export const getMixingContainerVM = (bowl: ProductAllowanceBlueprintVM): MixingContainerVM => {
  const products = bowl.pigments ?? [];
  const developers = bowl.developers ?? [];

  const productWeight = products.reduce((acc, p) => acc + p.weight.value, 0);
  const developerWeight = developers.reduce((acc, d) => acc + d.weight?.value * productWeight, 0);

  return {
    entries: [
      ...developers.map((d) => ({
        _id: d.product._id,
        value: d.weight.value * productWeight,
        color: d.product.hexColorCode,
      })),
      ...products.map((p) => ({
        _id: p.product._id,
        value: p.weight.value,
        color: p.product.hexColorCode,
      })),
    ],
    containerVolume: some(Math.max(productWeight + developerWeight, 150)),
    overlayText: none,
  };
};
