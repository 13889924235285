import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { mergeMap, switchMap, catchError, map } from "rxjs/operators";
import { loadForSalons, loadForSalonsSuccess, loadForSalonsFail } from "./tenant-entitlements.actions";
import { of } from "rxjs";
import { TenantEntitlementsService } from "../services/tenant-entitlements.service";

@Injectable()
export class TenantEntitlementsEffects {
  public init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadForSalons),
      map(({ salons }) => salons.map((salon) => salon._id)),
      switchMap((salonIds) => this._tenantEntitlementsService.findForSalonIds(salonIds)),
      mergeMap((entitlements) => [loadForSalonsSuccess({ entitlements })]),
      catchError((error) => of(loadForSalonsFail({ error })))
    )
  );

  constructor(
    private _tenantEntitlementsService: TenantEntitlementsService,
    private _actions$: Actions
  ) {}
}
