import { Product, ProductCategory, SalonProduct } from "@getvish/model";
import * as option from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { allPass, includes } from "ramda";
import { ProductCategoryVM, fromProductCategory, getDescendentProductsForSelectedCategory } from "../../+salon-products/common";

export interface ProductSelectionVM {
  selected: boolean;
  inactive: boolean;
  availableCategories: ProductCategoryVM[];
  product: SalonProduct | Product;
}

export const fromSalonProduct =
  (selectedProducts: SalonProduct[], availableCategories: ProductCategoryVM[]) =>
  (salonProduct: SalonProduct): ProductSelectionVM => ({
    product: salonProduct,
    selected: selectedProducts.some((selectedProduct) => selectedProduct._id === salonProduct._id),
    inactive: includes(
      "INACTIVE",
      pipe(
        option.fromNullable(salonProduct.flags),
        option.getOrElse(() => [])
      )
    ),
    availableCategories: availableCategories.filter((category) => salonProduct.categoryId === category._id),
  });

export const fromSalonProducts = (
  salonProducts: SalonProduct[],
  selectedProducts: SalonProduct[],
  productCategories: ProductCategory[],
  selectedCategories: ProductCategory[]
) => {
  const { products, categories } = getDescendentProductsForSelectedCategory(salonProducts, productCategories, selectedCategories);
  const categoryVMs = categories.map(fromProductCategory(selectedCategories, productCategories));

  return products.map(fromSalonProduct(selectedProducts, categoryVMs));
};

export const fromProduct = (selectedProducts: Product[], importedProducts: SalonProduct[], availableCategories: ProductCategoryVM[]) => {
  const selectedProductIds: Set<string> = selectedProducts.reduce((acc, p) => {
    acc.add(p._id);
    return acc;
  }, new Set<string>());

  const importedProductIds: Set<string> = importedProducts.reduce((acc, sp) => {
    acc.add(sp.productId);
    return acc;
  }, new Set<string>());

  return (product: Product): ProductSelectionVM => ({
    product,
    selected: selectedProductIds.has(product._id) || importedProductIds.has(product._id),
    inactive: importedProductIds.has(product._id),
    availableCategories: availableCategories.filter((category) => product.categoryId === category._id),
  });
};

export const fromProducts = (
  allProducts: Product[],
  selectedProducts: Product[],
  importedProducts: SalonProduct[],
  productCategories: ProductCategory[],
  selectedCategories: ProductCategory[],
  flagFilters: FilterKey[]
) => {
  const { products, categories } = getDescendentProductsForSelectedCategory(allProducts, productCategories, selectedCategories);
  const categoryVMs = categories.map(fromProductCategory(selectedCategories, productCategories));

  const activeFilters = importProductsFilters.filter((item) => includes(item.key, flagFilters)).map((filter) => filter.fn);

  return products.map(fromProduct(selectedProducts, importedProducts, categoryVMs)).filter(allPass(activeFilters));
};

export type Filter = { key: FilterKey; val: string; fn: (item: ProductSelectionVM) => boolean };

export type FilterKey = "NON_IMPORTED";

export const importProductsFilters: Filter[] = [
  { key: "NON_IMPORTED", val: "Non-imported only", fn: (item: ProductSelectionVM) => !item.inactive },
];
