<slideout-panel (close)="close()">
  <div
    panel-header
    *ngIf="(manufacturer$ | async)._id | isDefined">
    Edit Manufacturer
  </div>
  <div
    panel-header
    *ngIf="(manufacturer$ | async)._id | isNil">
    Add Manufacturer
  </div>

  <edit-manufacturer-component
    [manufacturer]="manufacturer$ | async"
    (save)="save($event)"
    (close)="close()"
    panel-body>
  </edit-manufacturer-component>
</slideout-panel>
