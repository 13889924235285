<div class="blueprint-container">
  <div class="blueprint-header">
    <div class="blueprint-title">{{ title }}</div>
    <div class="blueprint-controls">
      <span
        class="delete"
        *ngIf="allowDelete"
        (click)="delete.emit()">
        <fa-icon
          title="Delete Bowl"
          icon="xmark"></fa-icon>
      </span>
    </div>
  </div>
  <div class="blueprint">
    <div class="ingredients">
      <span
        *ngIf="blueprintBowl.pigments?.length || blueprintBowl.developers?.length"
        class="ingredients-header ih-productName"
        >Product</span
      >
      <span
        *ngIf="blueprintBowl.pigments?.length || blueprintBowl.developers?.length"
        class="ingredients-header ih-amount"
        >Amount</span
      >
      <span
        *ngIf="blueprintBowl.pigments?.length || blueprintBowl.developers?.length"
        class="ingredients-header ih-cost"
        >Cost</span
      >
      <span
        *ngIf="blueprintBowl.pigments?.length || blueprintBowl.developers?.length"
        class="ingredients-header ih-controls"></span>
      <pac-blueprint-ingredient
        *ngFor="let ingredient of blueprintBowl.pigments"
        [ingredient]="ingredient"
        [weightType]="TargetWeightType.ABSOLUTE"
        [includeSeparator]="true"
        [currency]="currency"
        [measurementUnit]="measurementUnit"
        (ingredientChange)="pigmentChanged(ingredient.product._id, $event)"
        (delete)="deletePigment(ingredient.product._id)"></pac-blueprint-ingredient>
      <div class="add-ingredient">
        <a
          title="Add Products"
          (click)="addProducts()"
          >+ Add Products</a
        >
      </div>
      <pac-blueprint-ingredient
        *ngFor="let ingredient of blueprintBowl.developers; index as idx"
        [ingredient]="ingredient"
        [weightType]="TargetWeightType.RATIO"
        [includeSeparator]="idx > 0"
        [totalProductWeight]="getTotalProductWeight()"
        [currency]="currency"
        [measurementUnit]="measurementUnit"
        (ingredientChange)="developerChanged(ingredient.product._id, $event)"
        (delete)="deleteDeveloper(ingredient.product._id)"></pac-blueprint-ingredient>
      <div class="add-ingredient">
        <a
          title="Add Developer"
          (click)="addDeveloper()"
          >+ Add Developer</a
        >
      </div>
    </div>
    <div class="summary">
      <pac-blueprint-summary
        [blueprintBowl]="blueprintBowl"
        [currency]="currency"></pac-blueprint-summary>
    </div>
  </div>
</div>
