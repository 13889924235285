import { Injectable } from "@angular/core";
import { Location } from "../models/location";
import { LocationSuggestion } from "../models/location-suggestion";
import { Observable, map } from "rxjs";
import { HttpRequestHandler } from "@getvish/stockpile";
import { fold, toUndefined } from "fp-ts/lib/Option";

@Injectable()
export class LocationAutocompleteService {
  constructor(private _httpRequestHandler: HttpRequestHandler) {}

  public getSuggestions(text: string): Observable<Array<LocationSuggestion>> {
    const query = encodeURIComponent(text);
    return this._httpRequestHandler.get<LocationSuggestion[]>(`address/location/suggestions?query=${query}&limit=5`).pipe(
      map(
        fold(
          () => [],
          (results) => results
        )
      )
    );
  }

  public getLocation(locationId: string): Observable<Location> {
    return this._httpRequestHandler.get<Location>(`address/location/${locationId}`).pipe(map(toUndefined));
  }
}
