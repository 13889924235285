import { ProductCategory, Product } from "@getvish/model";
import { isNil, includes, isEmpty } from "ramda";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

export interface ProductCategoryNode extends ProductCategory {
  children?: ProductCategory[];
  products?: Product[];
  level?: number;
  isSelected?: boolean;
  isRoot?: boolean;
  isTerminal?: boolean;
}

export const getChildCategories =
  (categories: ProductCategoryNode[]) =>
  (category: ProductCategoryNode): ProductCategoryNode[] => {
    return categories.filter((_category) => _category.parentCategoryId === category._id);
  };

export const assignChildCategories =
  (categories: ProductCategoryNode[]) =>
  (category: ProductCategoryNode): ProductCategoryNode => {
    const children = categories
      .filter((_category) => _category.parentCategoryId === category._id)
      .map((_category) => assignChildCategories(categories)(_category));

    const isTerminal = isEmpty(children);

    return {
      ...category,
      children,
      isTerminal,
    };
  };

export const assignProductsToCategory = (products: Product[]) => (category: ProductCategoryNode) => {
  const productsInCategory = products.filter((product) => product.categoryId === category._id);

  return { ...category, products: productsInCategory };
};

export const assignIsActiveCategory =
  (activeCategoryIds: string[]) =>
  (category: ProductCategoryNode): ProductCategoryNode =>
    includes(
      category._id,
      pipe(
        option.fromNullable(activeCategoryIds),
        option.getOrElse(() => [])
      )
    )
      ? { ...category, isSelected: true }
      : category;

export const flattenProductCategoryNode =
  (categories: ProductCategoryNode[]) =>
  (category: ProductCategoryNode, level = 0): ProductCategoryNode[] => {
    const getChildCategoriesPartial = getChildCategories(categories);
    const currentNode: ProductCategoryNode = { ...category, level };

    const tail = getChildCategoriesPartial(currentNode)
      .map((child) => flattenProductCategoryNode(categories)(child, level + 1))
      .reduce((acc, curr) => acc.concat(curr), []);

    return [currentNode, ...tail];
  };

export const mapCategoriesToNodes = (categories: ProductCategory[], selectedIds: string[]) =>
  categories.map((category) => {
    const isSelected = isNil(selectedIds) ? false : selectedIds.some((id) => id === category._id);

    const isRoot = isNil(category.parentCategoryId);

    const node = category as ProductCategoryNode;
    return { ...node, isSelected, isRoot };
  });

export const sortCategoryNodesByOrderFn = (a: ProductCategoryNode, b: ProductCategoryNode) => {
  // explicitly use POSITIVE_INFINITY here so that any items which don't have an @order (i.e. undefined/null)
  // will always sort to the end of the list

  const aOrInfinity = pipe(
    option.fromNullable(a.order),
    option.getOrElse(() => Number.POSITIVE_INFINITY)
  );

  const bOrInfinity = pipe(
    option.fromNullable(b.order),
    option.getOrElse(() => Number.POSITIVE_INFINITY)
  );

  const ret = aOrInfinity - bOrInfinity;

  if (ret === 0) {
    return a.name.localeCompare(b.name, undefined, { sensitivity: "base" });
  }

  return ret;
};
