import { createAction, props } from "@ngrx/store";
import { ProductSelection } from "./product.reducer";
import { MergeProductsSuccess } from "../services";

export const init = createAction("[Merge Products] Init");
export const updatePrimary = createAction("[Merge Products] Update Primary", props<{ product: ProductSelection }>());
export const merge = createAction("[Merge Products] Merge");
export const mergeProductsFail = createAction("[Merge Products] Merge Fail", props<{ error: Error }>());
export const mergeProductsSuccess = createAction("[Merge Products] Merge Success", props<{ result: MergeProductsSuccess }>());
export const done = createAction("[Merge Products] done");
