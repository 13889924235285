import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType, OnInitEffects } from "@ngrx/effects";
import { tap, map, delay, mergeMap, switchMap, withLatestFrom, catchError } from "rxjs/operators";
import { WindowService } from "../../services/window.service";
import { go } from "../actions/router.actions";
import { Action, Store } from "@ngrx/store";
import { AppMode } from "app/kernel/models/app-mode";
import { SalonConfigService } from "app/+salon-config/services";
import { SalonConfig } from "@getvish/model";

import * as AppActions from "../actions/app.actions";
import * as AuthActions from "../../../+auth/store/auth.actions";
import * as MultiTenantActions from "../../../+multi-tenant-login/store/multi-tenant-login.actions";
import * as CurrentTenantActions from "../actions/current-tenant.actions";
import * as O from "fp-ts/Option";
import * as R from "ramda";
import * as snackbarActions from "app/kernel/store/actions/snackbar.actions";
import * as tenantApiVersionCheckActions from "app/kernel/+salon-api-version/store/tenant-api-version-check.actions";
import * as SalonConfigActions from "app/+salon-config/store/salon-config.actions";
import * as fromCurrentTenant from "app/kernel/store/reducers/current-tenant.reducer";
import { AppState } from "../reducers";

export const appInitGetNextActions = (appMode: AppMode, slug: string): Action[] => {
  switch (appMode.value) {
    case "SINGLE_TENANT": {
      return [AppActions.loadingStarted(), CurrentTenantActions.init({ slug })];
    }
    case "ADMIN": {
      return [AppActions.loadingStarted(), CurrentTenantActions.init({ slug })];
    }
    default: {
      return [
        {
          type: "EMPTY_ACTION",
        },
      ];
    }
  }
};

@Injectable()
export class AppEffects implements OnInitEffects {
  public ngrxOnInitEffects(): Action {
    return AppActions.init();
  }

  public init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.init),
      map(() => ({
        slug: this._windowService.tenantSlug,
        pathname: this._windowService.pathname,
      })),
      map(({ slug, pathname }) => AppActions.initializeTenantUrlConfig({ slug, pathname }))
    )
  );

  public initializeTenantUrlConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.initializeTenantUrlConfig),
      map(({ slug }) => slug),
      delay(0),
      switchMap((slug) => appInitGetNextActions(this._appMode, slug))
    )
  );

  public changeSalon$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AppActions.changeSalon, AppActions.changeSalonBySlug),
        tap(({ slug }) => this._windowService.changeTenant(slug))
      ),
    { dispatch: false }
  );

  public currentTenantNotFound$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CurrentTenantActions.tenantNotFound),
      map(() => go({ path: ["/not-found", { outlets: { panel: null } }] }))
    )
  );

  public currentTenantExists$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CurrentTenantActions.currentTenantExists),
      map(() => new AuthActions.Init())
    )
  );

  public loadAvailableSalons$ = createEffect(() =>
    this._actions$.pipe(
      ofType<AuthActions.LoginSuccess | AuthActions.LoginFromAuthTokenSuccess>(
        AuthActions.Types.LOGIN_SUCCESS,
        AuthActions.Types.LOGIN_FROM_AUTH_TOKEN_SUCCESS
      ),
      mergeMap((response) => [
        CurrentTenantActions.loadAvailableSalons({ user: response.payload.user }),
        CurrentTenantActions.setCurrentTenant({ tenant: response.payload.salon }),
        AppActions.loadSalonConfig(),
      ])
    )
  );

  public loadSalonConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.loadSalonConfig),
      switchMap(() => {
        // yeah this is a bit of a hack so that we don't try to load
        // the SalonConfig (...which will result in an error)
        // if we're not in "SINGLE_TENANT" mode
        // this could definitely be better, but I'm tired.
        if (this._appMode.value === "SINGLE_TENANT") {
          return this._salonConfigService.findOne({}).pipe(
            mergeMap(
              O.fold<SalonConfig, Action[]>(
                () => [AppActions.loadSalonConfigFail()],
                (salonConfig) => [
                  AppActions.loadSalonConfigSuccess({ salonConfig }),
                  tenantApiVersionCheckActions.checkTenantApiVersion({ salonConfig }),
                  new SalonConfigActions.LoadCurrentSalon(salonConfig),
                ]
              )
            ),
            catchError((e) => {
              if (e.status === 403) {
                return [AppActions.loadSalonConfigForbidden()];
              }

              console.error(e);
              return [AppActions.loadSalonConfigFail()];
            })
          );
        }

        return [AppActions.loadingComplete()];
      })
    )
  );

  public loadSalonConfigCheckTimezone$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.loadSalonConfigSuccess),
      withLatestFrom(this._store.select(fromCurrentTenant.getCurrentTenant)),

      mergeMap(([{ salonConfig }, currentTenant]) => {
        if (R.isNil(currentTenant.address)) {
          return [
            go({ path: ["/salon-config/details", { outlets: { panel: null } }] }),
            new snackbarActions.Info({ message: "Please configure an address for this salon." }),
          ];
        } else if (R.isNil(salonConfig.timeZone) || R.isNil(salonConfig.currency) || R.isNil(salonConfig.locale)) {
          return [
            go({ path: ["/salon-config/settings", { outlets: { panel: null } }] }),
            new snackbarActions.Info({ message: "Please configure a currency, time zone and locale for this salon." }),
          ];
        }

        return [];
      })
    )
  );

  public loadSalonConfigFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.loadSalonConfigFail),
      map(() => new snackbarActions.Info({ message: "Something went wrong while loading your configuration. Please try again." }))
    )
  );

  public navigateHome$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AppActions.navigateHome),
      map(() => go({ path: ["/home", { outlets: { panel: null } }] }))
    )
  );

  public loadingComplete$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        CurrentTenantActions.tenantNotFound,
        SalonConfigActions.Types.LOAD_SALON_CONFIG_SUCCESS,
        AuthActions.Types.NAVIGATE_TO_LOGIN,
        MultiTenantActions.navigate
      ),
      map(() => AppActions.loadingComplete())
    )
  );

  constructor(
    private _appMode: AppMode,
    private _actions$: Actions,
    private _windowService: WindowService,
    private _salonConfigService: SalonConfigService,
    private _store: Store<AppState>
  ) {}
}
