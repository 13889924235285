import { SalonApiVersion } from "app/kernel/models/salon-api-version";
import { loadForSalons, loadForSalonsFail, loadForSalonsSuccess } from "./salon-api-version.actions";
import { Action, createReducer, on } from "@ngrx/store";

export interface SalonApiVersionState {
  loading: boolean;
  records: SalonApiVersion[];
  error: string;
}

const initialState: SalonApiVersionState = {
  loading: false,
  records: [],
  error: undefined,
};

const salonApiVersionReducer = createReducer(
  initialState,
  on(loadForSalons, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadForSalonsSuccess, (state, { salonApiVersions }) => ({
    ...state,
    loading: false,
    records: salonApiVersions,
  })),
  on(loadForSalonsFail, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  }))
);

export function reducer(state: SalonApiVersionState | undefined, action: Action) {
  return salonApiVersionReducer(state, action);
}

export const salonApiVersionFeatureKey = "salonApiVersions";
