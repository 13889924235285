import { Injectable } from "@angular/core";
import { MeasurementUnit } from "@getvish/model";
import { DecimalPipe } from "@angular/common";

@Injectable()
export class MeasurementUnitConversionService {
  private _decimalPipe: DecimalPipe;
  constructor() {
    // NOTE: this will only happen once so if the measurement unit is updated while the app is running it won't be reflected here
    // but really, how often is the measurement unit going to be updated? probably reasonable to expect a restart after that
    // const measurementUnitType = _globalSettings.measurementUnit || _defaultMeasurementUnit;

    this._decimalPipe = new DecimalPipe("en");
  }

  public convertFromBase(value: number, measurementUnit: MeasurementUnit): number {
    return measurementUnit.convertFromBase(value);
  }

  public convertToBase(value: number, measurementUnit: MeasurementUnit): number {
    return measurementUnit.convertToBase(value);
  }

  public convertFromBaseWithAbbreviation(value: number, measurementUnit: MeasurementUnit): string {
    const abbreviation = measurementUnit.abbreviation;

    const result = this.convertFromBase(value, measurementUnit);
    const precision = measurementUnit.precision;
    const formattedResult = this._decimalPipe.transform(result, `0.0-${precision}`);

    return `${formattedResult}${abbreviation}`;
  }
}
