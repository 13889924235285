<data-table [paging]="paging$ | async">
  <columns>
    <column id="icon"></column>
    <column
      id="name"
      title="Manufacturer">
      <column-sort
        [direction]="(sort$ | async)?.name"
        (change)="updateSort({ name: $event.direction })">
      </column-sort>
    </column>
  </columns>
  <body>
    <row
      *ngFor="let manufacturer of manufacturers$ | async"
      [id]="manufacturer._id">
      <cell columnId="icon">
        <ng-template>
          <colored-icon-cell
            [title]="manufacturer.name"
            [numChars]="1"></colored-icon-cell>
        </ng-template>
      </cell>
      <cell columnId="name">
        <ng-template>
          <text-cell>
            <text-cell-text
              [value]="manufacturer.name"
              [title]="manufacturer.name"
              className="primary"
              (action)="select.emit(manufacturer)">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
    </row>
  </body>
</data-table>
