import { Customer } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { Action } from "@ngrx/store";
import { createUserFilterCriteria } from "app/kernel/util";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";

export enum Types {
  SEARCH = "[Customer] Search",
  UPDATE_TABLE_FILTER = "[Customer] Update Table Filter",
  UPDATE_SORT = "[Customer] Update Sort",
  LOAD_ALL = "[Customer] Load All",
  LOAD_ALL_SUCCESS = "[Customer] Load All Success",
  LOAD_ALL_FAIL = "[Customer] Load All Fail",
  LOAD_MORE = "[Customer] Load More",
  LOAD_MORE_SUCCESS = "[Customer] Load More Success",
  LOAD_MORE_FAIL = "[Customer] Load More Failed",
  RELOAD = "[Customer] Reload",
  NAVIGATE_TO_PAGE = "[Customer] Navigate to Page",
  CLEAR = "[Customer] Clear",
  REMOVE = "[Customer] Remove",
  REMOVE_SUCCESS = "[Customer] Remove Success",
  REMOVE_FAIL = "[Customer] Remove Fail",
  NAVIGATE_EDIT_CUSTOMER = "[Customer] Navigate to Edit Customer",
  NAVIGATE_IMPORT_CUSTOMERS = "[Customer] Navigate Import Customers",
  IMPORT_CUSTOMERS_FROM_FILE = "[Customer] Import Customers From File",
  IMPORT_CUSTOMERS_FROM_FILE_SUCCESS = "[Customer] Import Customers From File Success",
  UPLOAD_CUSTOMERS = "[Customer] Upload Customers",
  UPLOAD_CUSTOMERS_SUCCESS = "[Customer] Upload Customers Success",
  INSERT_MANY = "[Customer] Insert Many",
  CLOSE_CUSTOMER_IMPORT = "[Customer] Close Customer Import",
  TOGGLE_SELECTED = "[Customer] Toggle Selected",
  CLEAR_SELECTED = "[Customer] Clear Selected",
}

export class Search implements Action {
  public readonly type = Types.SEARCH;

  constructor(public payload: { filter: string }) {}
}

export class UpdateSort implements Action {
  public readonly type = Types.UPDATE_SORT;

  constructor(public payload: JsonObject) {}
}

export class UpdateTableFilter implements Action {
  public readonly type = Types.UPDATE_TABLE_FILTER;

  constructor(public payload: JsonObject) {}
}

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;
  public payload: { filter: string; tableFilter?: JsonObject; criteria?: JsonObject; sort?: JsonObject; page?: number; limit?: number };

  constructor(payload: { filter?: string; tableFilter?: JsonObject; sort?: JsonObject; page?: number; limit?: number }) {
    const criteria = createUserFilterCriteria(payload.filter, payload.tableFilter);

    const sort = pipe(
      option.fromNullable(payload.sort),
      option.getOrElse(() => ({ firstName: 1, lastName: 1 }))
    );

    this.payload = {
      filter: payload.filter,
      tableFilter: payload.tableFilter,
      criteria,
      sort,
      limit: payload.limit,
      page: payload.page,
    };
  }
}

export class NavigateToPage implements Action {
  public readonly type = Types.NAVIGATE_TO_PAGE;

  constructor(public payload: { page: number }) {}
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public payload: Customer[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class LoadMore implements Action {
  public readonly type = Types.LOAD_MORE;
}

export class Reload implements Action {
  public readonly type = Types.RELOAD;
}

export class Remove implements Action {
  public readonly type = Types.REMOVE;

  constructor(public payload: { id: string }) {}
}

export class RemoveSuccess implements Action {
  public readonly type = Types.REMOVE_SUCCESS;

  constructor(public payload: string) {}
}

export class RemoveFail implements Action {
  public readonly type = Types.REMOVE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class NavigateImportCustomers implements Action {
  public readonly type = Types.NAVIGATE_IMPORT_CUSTOMERS;
}

export class ImportCustomersFromFile implements Action {
  public readonly type = Types.IMPORT_CUSTOMERS_FROM_FILE;

  constructor(public payload: { url: string }) {}
}

export class ImportCustomersFromFileSuccess implements Action {
  public readonly type = Types.IMPORT_CUSTOMERS_FROM_FILE_SUCCESS;

  constructor(public payload: { records: Customer[] }) {}
}

export class UploadCustomers implements Action {
  public readonly type = Types.UPLOAD_CUSTOMERS;
}

export class UploadCustomersSuccess implements Action {
  public readonly type = Types.UPLOAD_CUSTOMERS_SUCCESS;

  constructor(public payload: { numInserted: number }) {}
}

export class InsertMany implements Action {
  public readonly type = Types.INSERT_MANY;

  constructor(public payload: { customers: Customer[] }) {}
}

export class CloseCustomerImport implements Action {
  public readonly type = Types.CLOSE_CUSTOMER_IMPORT;
}

export class ToggleSelected implements Action {
  public readonly type = Types.TOGGLE_SELECTED;

  constructor(public payload: Customer) {}
}

export class ClearSelected implements Action {
  public readonly type = Types.CLEAR_SELECTED;
}

export type Actions =
  | Search
  | UpdateTableFilter
  | UpdateSort
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadMore
  | Reload
  | NavigateToPage
  | Remove
  | RemoveSuccess
  | RemoveFail
  | NavigateImportCustomers
  | ImportCustomersFromFile
  | ImportCustomersFromFileSuccess
  | UploadCustomers
  | UploadCustomersSuccess
  | InsertMany
  | CloseCustomerImport
  | ToggleSelected
  | ClearSelected;
