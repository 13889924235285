import { Device } from "@getvish/model";
import * as device from "./device.actions";

import { not } from "ramda";

export interface DeviceState {
  loading: boolean;
  saving: boolean;
  showDisabled: boolean;
  devices: Device[];
}

const initialState: DeviceState = {
  loading: false,
  saving: false,
  showDisabled: false,
  devices: [],
};

export function deviceReducer(state: DeviceState = initialState, action: device.Actions): DeviceState {
  switch (action.type) {
    case device.Types.LOAD_ALL: {
      const loading = true;

      return {
        ...state,
        loading,
      };
    }

    case device.Types.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        devices: action.payload,
      };
    }

    case device.Types.REMOVE: {
      const saving = true;

      return {
        ...state,
        saving,
      };
    }

    case device.Types.REMOVE_SUCCESS: {
      const saving = false;

      return {
        ...state,
        saving,
      };
    }

    case device.Types.TOGGLE_SHOW_DISABLED: {
      const showDisabled = not(state.showDisabled);

      return {
        ...state,
        showDisabled,
      };
    }

    default: {
      return state;
    }
  }
}
