import { Injectable } from "@angular/core";
import { map, mergeMap, Observable, of, throwError, withLatestFrom } from "rxjs";
import { EmployeeReportDownloaderService } from "./employee-report-downloader.service";
import { EmployeeReportService } from "./employee-report.service";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { getSalonConfig } from "app/+salon-config/store/salon-config.selectors";
import { pipe } from "fp-ts/lib/function";
import { filter, sortWith } from "ramda";
import { EmployeeReport } from "app/kernel/models";
import { generateMetricsXlsxReport } from "./xlsx";
import { fold } from "fp-ts/lib/Option";
import { ReportStatistic } from "app/kernel/models/report-statistic";
import { EmployeeService } from "app/+employees/services";

@Injectable()
export class EmployeeReportDownloaderLocalService extends EmployeeReportDownloaderService {
  constructor(
    private _employeeReportService: EmployeeReportService,
    private _employeeService: EmployeeService,
    private _store: Store<AppState>
  ) {
    super();
  }

  public downloadEmployeesReport(startDate: number, endDate: number): Observable<Blob> {
    return this._employeeReportService.fetchForDateRange(startDate, endDate).pipe(
      withLatestFrom(this._store.select(getSalonConfig)),
      map(([employeeReports, salonConfig]) => {
        const enableComplianceTracking = salonConfig.enableComplianceTracking;

        const drivingKey = enableComplianceTracking ? "numMixableServices" : "numServicesPerformed";
        const sortKeys = enableComplianceTracking ? ["numMixableServices", "numMixedServices"] : ["numServicesPerformed"];

        return {
          reports: pipe(
            employeeReports,
            filter<EmployeeReport>((report) => report.summary[drivingKey] > 0),
            sortWith(sortKeys.map((key) => (a, b) => b.summary[key] - a.summary[key]))
          ),
          salonConfig,
        };
      }),
      mergeMap(({ reports, salonConfig }) =>
        generateMetricsXlsxReport(
          "Employee Performance",
          reports.map((row) => ({ name: row.employeeName, metrics: row.summary })),
          salonConfig
        )
      )
    );
  }

  public downloadSingleEmployeeReport(employeeId: string, startDate: number, endDate: number): Observable<Blob> {
    return this._employeeReportService.fetchForEmployeeInDateRange(employeeId, startDate, endDate).pipe(
      mergeMap(
        fold(
          () => throwError(() => "Unable to retrieve employee report"),
          (report) => of(report)
        )
      ),
      withLatestFrom(this._store.select(getSalonConfig), this._employeeService.findByIdOrDie(employeeId)),
      map(([employeeReport, salonConfig, employee]) => {
        const enableComplianceTracking = salonConfig.enableComplianceTracking;

        const drivingKey = enableComplianceTracking ? "numMixableServices" : "numServicesPerformed";
        const sortKeys = enableComplianceTracking ? ["numMixableServices", "numMixedServices"] : ["numServicesPerformed"];

        return {
          stats: pipe(
            employeeReport.serviceStatistics,
            filter<ReportStatistic>((report) => report.metrics[drivingKey] > 0),
            sortWith(sortKeys.map((key) => (a, b) => b.metrics[key] - a.metrics[key]))
          ),
          salonConfig,
          employee,
        };
      }),
      mergeMap(({ stats, salonConfig, employee }) =>
        generateMetricsXlsxReport(
          `Employee Performance - ${employee.firstName} ${employee.lastName}`,
          stats.map((row) => ({ name: row.entityName, metrics: row.metrics })),
          salonConfig
        )
      )
    );
  }
}
