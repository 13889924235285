import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from "@angular/core";
import { AppointmentChange, AppointmentServiceChange, AppointmentVM } from "../models/appointment";
import { detailExpandAnimation } from "app/kernel/shared/rocket-ui";
import { ServiceDescription } from "@getvish/model";

@Component({
  selector: "appointment-changes",
  templateUrl: "./appointment-changes.component.html",
  styleUrls: ["./appointment-changes.component.less"],
  animations: [detailExpandAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentChangesComponent {
  @Input() public appointment: AppointmentVM;
  @Input() public serviceDescriptions: ServiceDescription[];
  @Input() public expanded: boolean;
  @Input() public timeZone: string;
  @Output() public expand: EventEmitter<boolean> = new EventEmitter<boolean>();

  public getChangeType(change: AppointmentChange): string {
    if (change.type === "service") {
      const serviceChange = change as AppointmentServiceChange;

      if (serviceChange.fromServiceId != null) {
        return "Service Changed";
      } else {
        return "Service Added";
      }
    }

    return change.type.charAt(0).toUpperCase() + change.type.slice(1);
  }

  public hasFromService(change: AppointmentChange): boolean {
    const serviceChange = change as AppointmentServiceChange;
    return serviceChange.fromServiceId != null;
  }

  public getFromServiceName(change: AppointmentChange): string {
    const serviceChange = change as AppointmentServiceChange;
    return this.serviceDescriptions.find((sd) => sd._id === serviceChange.fromServiceId)?.name;
  }

  public getToServiceName(change: AppointmentChange): string {
    const serviceChange = change as AppointmentServiceChange;
    return this.serviceDescriptions.find((sd) => sd._id === serviceChange.toServiceId)?.name;
  }
}
