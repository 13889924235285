import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { SalonService } from "../../../+salons/services";
import { debounceTime, map, switchMap } from "rxjs/operators";

import * as actions from "../actions/admin.actions";

@Injectable()
export class AdminEffects {
  public searchSalons$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.searchSalons),
      debounceTime(500),
      switchMap(({ filter, sort }) =>
        this._salonService.search(filter, sort).pipe(map((result) => actions.searchSalonsSuccess({ salons: result.records })))
      )
    )
  );

  constructor(
    private _actions$: Actions,
    private _salonService: SalonService
  ) {}
}
