import { Component } from "@angular/core";

@Component({
  selector: "salon-config-container",
  templateUrl: "salon-config.container.html",
})
export class SalonConfigContainer {
  public navLinks: Array<{ label: string; path: string }>;

  constructor() {
    this.navLinks = [
      { label: "Details", path: "details" },
      { label: "Settings", path: "settings" },
      { label: "Payment", path: "payment" },
    ];
  }
}
