<page
  pageTitle="Products"
  [progressText]="progressText$ | async">
  <header-controls page-header>
    <button
      class="button outline small"
      (click)="importProducts()">
      Import Products
    </button>
    <ng-container>
      <mat-menu #controlsMenu="matMenu">
        <button
          mat-menu-item
          title="Change Manufacturer Order"
          (click)="changeManufacturerOrder()"
          class="menu-item-btn">
          <div class="menu-item">
            <span>Change Manufacturer Order</span>
          </div>
        </button>
      </mat-menu>
      <button
        [matMenuTriggerFor]="controlsMenu"
        class="button transparent">
        <fa-icon icon="ellipsis-v"></fa-icon>
      </button>
    </ng-container>
  </header-controls>
  <div
    *ngIf="hasActiveManufactures$ | async; else noAddedManufactures"
    class="manufacturer-view"
    page-body>
    <card>
      <manufacturers-list
        [filteredManufacturers]="filteredActiveManufacturers$ | async"
        (searchedByName)="searchManufacturerByName($event)"
        (manufacturerSelected)="selectManufacturer($event)"
        card-body>
        <mat-checkbox
          filters
          class="manufacturer-filter"
          (change)="setIncompletePricingFilter($event?.checked)"
          >Incomplete pricing</mat-checkbox
        >
        <p noFoundManufacturersHint>Please, adjust the filters or add a manufacturer.</p>
      </manufacturers-list>
    </card>
  </div>
  <ng-template #noAddedManufactures>
    <ng-container *ngIf="(error$ | async) == null; else errorElement">
      <empty-state-message
        title="No manufacturers found"
        icon="magnifying-glass">
        It seems like no manufacturers have been added.
      </empty-state-message>
    </ng-container>
  </ng-template>
  <ng-template #errorElement>
    <alert-component type="danger">
      <h4>An Error Has Occured</h4>
      <p>{{ error$ | async }}</p>
    </alert-component>
  </ng-template>
</page>
