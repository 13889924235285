import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { ServiceReport } from "../../kernel/models";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ServiceReportService extends EntityService<ServiceReport> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "serviceReports" });
  }

  public fetchForDateRange(startDate: number, endDate: number): Observable<ServiceReport[]> {
    const criteria = { startDate, endDate };

    return this.find(criteria).pipe(map((result) => result.records));
  }
}
