import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MeasurementUnit, SalonProduct, SalonProductModule } from "@getvish/model";
import { GreaterThanNumberValidator } from "../validators/greater-than-number.validator";
import { Subscription } from "rxjs";
import { roundToTwoDecimals } from "app/kernel/util";

@Component({
  selector: "edit-salon-product-component",
  templateUrl: "edit-salon-product.component.html",
  styleUrls: ["edit-salon-product.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSalonProductComponent implements OnInit, OnDestroy {
  @Input() public product: SalonProduct;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public currency: string;
  @Output() public close: EventEmitter<void>;
  @Output() public save: EventEmitter<SalonProduct>;

  public form: UntypedFormGroup;

  private _wholesaleSubscription: Subscription;
  private _markupSubscription: Subscription;
  private _retailSubscription: Subscription;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
    this.close = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this._createForm();
  }

  public saveForm(data, original: SalonProduct): void {
    if (this.form.valid) {
      const updated = { ...original, ...data };
      updated.markup = data.markupPercent != null ? data.markupPercent / 100 : updated.markup;
      delete updated.markupPercent;

      // because the container size will be in the local unit of measurement for this user
      // we need to convert it back to the base unit that the server will understand before saving it
      // this probably isn't my favorite place to do this but it's the best I've got right now?
      const containerSizeConvertedToBase = this.measurementUnit.convertToBase(updated.containerSize);

      const updatedWithBaseMeasurementUnit = {
        ...updated,
        containerSize: containerSizeConvertedToBase,
      };

      this.save.emit(updatedWithBaseMeasurementUnit);
    }
  }

  private _createForm(): void {
    const product = this.product;

    // because the container size will come from server in the base unit
    // we need to convert it to the user's local unit of measurement before rendering it
    const containerSizeConvertedToLocal = this.measurementUnit.convertFromBase(product.containerSize);

    this.form = this._fb.group({
      name: [product.name, Validators.required],
      wholesalePrice: [product.wholesalePrice, Validators.required],
      markupPercent: [product.markup * 100, Validators.required],
      retailPrice: [roundToTwoDecimals(SalonProductModule.getRetailPrice(product)), Validators.required],
      containerSize: [containerSizeConvertedToLocal, [Validators.required, GreaterThanNumberValidator(0)]],
      flags: [product.flags],
    });

    this._wholesaleSubscription = this.form.controls["wholesalePrice"].valueChanges.subscribe((wholesalePrice) => {
      this.form.patchValue(
        {
          retailPrice: roundToTwoDecimals(
            SalonProductModule.getRetailPrice({
              ...this.form.value,
              markup: this.form.value.markupPercent != null ? this.form.value.markupPercent / 100 : undefined,
              wholesalePrice,
            })
          ),
        },
        { emitEvent: false }
      );
    });

    this._markupSubscription = this.form.controls["markupPercent"].valueChanges.subscribe((markupPercent) => {
      this.form.patchValue(
        {
          retailPrice: roundToTwoDecimals(
            SalonProductModule.getRetailPrice({ ...this.form.value, markup: markupPercent != null ? markupPercent / 100 : undefined })
          ),
        },
        { emitEvent: false }
      );
    });

    this._retailSubscription = this.form.controls["retailPrice"].valueChanges.subscribe((retailPrice) => {
      const markupPercent =
        retailPrice > 0 && this.form.value.wholesalePrice > 0
          ? ((retailPrice - this.form.value.wholesalePrice) / this.form.value.wholesalePrice) * 100
          : 0;

      this.form.patchValue({ markupPercent: roundToTwoDecimals(markupPercent) }, { emitEvent: false });
    });
  }

  public ngOnDestroy(): void {
    if (this._wholesaleSubscription != null) {
      this._wholesaleSubscription.unsubscribe();
    }

    if (this._markupSubscription != null) {
      this._markupSubscription.unsubscribe();
    }

    if (this._retailSubscription != null) {
      this._retailSubscription.unsubscribe();
    }
  }
}
