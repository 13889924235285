import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppState } from "../../../kernel";

import * as SalonProductActions from "../store/salon-product.actions";
import * as fromSalonProduct from "../store/salon-product.selectors";

@Component({
  selector: "upload-salon-product-spreadsheet-container",
  templateUrl: "upload-salon-product-spreadsheet.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadSalonProductSpreadsheetContainer {
  public uploading$: Observable<boolean>;
  public uploadingErrors$: Observable<string>;
  public uploadingSuccess$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.uploading$ = this._store.pipe(select(fromSalonProduct.getUploading));
    this.uploadingErrors$ = this._store.pipe(select(fromSalonProduct.getUploadingErrors));
    this.uploadingSuccess$ = this._store.pipe(select(fromSalonProduct.getUploadingSuccess));
  }

  public close(): void {
    this._store.dispatch(new SalonProductActions.CloseUploadComponent());
  }

  public fileChange({ url, type }): void {
    this._store.dispatch(new SalonProductActions.UploadSpreadsheet({ blobUrl: url, type }));
  }
}
