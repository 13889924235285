import { AbstractControl } from "@angular/forms";
import { isNil, not } from "ramda";

export const longerThan = (length: number) => (control: AbstractControl) => {
  const value = control.value;

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined
  } else if (not(value.length > length)) {
    return { longerThan: true };
  }

  return null;
};
