<employee-report
  [employeeReport]="employeeReport$ | async"
  [longitudinalReport]="longitudinalReport$ | async"
  [summary]="summary$ | async"
  [aggregatedBy]="aggregatedBy$ | async"
  [measurementUnit]="measurementUnit$ | async"
  [startDate]="startDate$ | async"
  [endDate]="endDate$ | async"
  [currency]="currency$ | async"
  [locale]="locale$ | async"
  [enableComplianceTracking]="enableComplianceTracking$ | async"
  (aggregation)="changeAggregation($event)"
  (downloadServicesSummaryReport)="downloadServicesSummaryReport()"
  (updateDateRange)="updateDateRange($event)"
  (navigateToMixed)="navigateToMixed($event)"
  (navigateToUnmixed)="navigateToUnmixed($event)">
</employee-report>
