<div class="changes">
  <div
    class="header"
    (click)="expand.emit()">
    <div
      class="arrow-container"
      [ngClass]="expanded ? 'expanded' : ''">
      <fa-icon
        [ngClass]="expanded ? '' : 'rotated'"
        class="side-arrow"
        icon="sort-down">
      </fa-icon>
    </div>
    <div class="customer">
      {{ appointment.customer | fullName | default: "-" }}
    </div>
  </div>
  <div
    class="content"
    [@detailExpand]="expanded ? 'expanded' : 'collapsed'">
    <div class="flex-row">
      <div class="change-label">Employee:</div>
      <span>{{ appointment.employee | fullName | default: "-" }}</span>
    </div>
    <div class="flex-row">
      <div class="change-label">Date:</div>
      <span>{{ appointment.date | zonedDate: timeZone : "MMM d, y, h:mm a" }}</span>
    </div>
    <div class="change-label">Changes:</div>
    <div class="changes-box">
      <ng-container *ngFor="let change of appointment.changes">
        <div class="change-label">Type:</div>
        <div class="change-value">{{ getChangeType(change) }}</div>

        <ng-container *ngIf="change.type === 'service'">
          <ng-container *ngIf="hasFromService(change)">
            <div class="change-label">From:</div>
            <div class="change-value">{{ getFromServiceName(change) }}</div>
          </ng-container>
          <div class="change-label">{{ hasFromService(change) ? "To:" : "Service:" }}</div>
          <div class="change-value">{{ getToServiceName(change) }}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
