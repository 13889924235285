import { Action } from "@ngrx/store";
import { PasswordResetToken } from "../services";

export enum Types {
  SEND_RESET_PASSWORD_EMAIL = "[Auth] Send Reset Password Email",
  SEND_RESET_PASSWORD_EMAIL_SUCCESS = "[Auth] Send Reset Password Email Success",
  SEND_RESET_PASSWORD_EMAIL_FAIL = "[Auth] Send Reset Password Email Fail",
  CONFIRM_CODE = "[Reset Password] Confirm Code",
  CONFIRM_CODE_SUCCESS = "[Reset Password] Confirm Code Success",
  CONFIRM_CODE_FAIL = "[Reset Password] Confirm Code Fail",
  SET_PASSWORD = "[Reset Password] Set Password",
  SET_PASSWORD_SUCCESS = "[Reset Password] Set Password Success",
  SET_PASSWORD_FAIL = "[Reset Password] Set Password Fail",
  DONE = "[Reset Password] Done",
  NAVIGATE_TO_LOGIN_NEW_WINDOW = "[Reset Password] Navigate To Login New Window",
  NAVIGATE = "[Reset Password] Reset",
}

export class SendResetPasswordEmail implements Action {
  public readonly type = Types.SEND_RESET_PASSWORD_EMAIL;

  constructor(public payload: { email: string }) {}
}

export class SendResetPasswordEmailSuccess implements Action {
  public readonly type = Types.SEND_RESET_PASSWORD_EMAIL_SUCCESS;

  constructor(public payload: { token: PasswordResetToken }) {}
}

export class SendResetPasswordEmailFail implements Action {
  public readonly type = Types.SEND_RESET_PASSWORD_EMAIL_FAIL;

  constructor(public payload: { error: string }) {}
}

export class SetPassword implements Action {
  public readonly type = Types.SET_PASSWORD;

  constructor(public payload: { password: string }) {}
}

export class SetPasswordSuccess implements Action {
  public readonly type = Types.SET_PASSWORD_SUCCESS;
}

export class SetPasswordFail implements Action {
  public readonly type = Types.SET_PASSWORD_FAIL;

  constructor(public payload: { error: string }) {}
}

export class ConfirmCode implements Action {
  public readonly type = Types.CONFIRM_CODE;

  constructor(public payload: { code: number }) {}
}

export class ConfirmCodeSuccess implements Action {
  public readonly type = Types.CONFIRM_CODE_SUCCESS;
}

export class ConfirmCodeFail implements Action {
  public readonly type = Types.CONFIRM_CODE_FAIL;

  constructor(public payload: { error: string }) {}
}

export class Done implements Action {
  public readonly type = Types.DONE;
}

export class NavigateToLoginNewWindow implements Action {
  public readonly type = Types.NAVIGATE_TO_LOGIN_NEW_WINDOW;
}

export class Navigate implements Action {
  public readonly type = Types.NAVIGATE;
}

export type Actions =
  | SendResetPasswordEmail
  | SendResetPasswordEmailSuccess
  | SendResetPasswordEmailFail
  | SetPassword
  | SetPasswordSuccess
  | SetPasswordFail
  | ConfirmCode
  | ConfirmCodeSuccess
  | ConfirmCodeFail
  | Done
  | NavigateToLoginNewWindow
  | Navigate;
