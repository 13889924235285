import { Salon, User } from "@getvish/model";
import { union, createAction, props } from "@ngrx/store";

export const init = createAction("[Current Tenant] Init", props<{ slug: string }>());

export const loadAvailableSalons = createAction("[Current Tenant] Load Available Salons", props<{ user: User }>());

export const loadAvailableTenantsSuccess = createAction("[Current Tenant] Load Available Tenants Success", props<{ tenants: Salon[] }>());

export const setCurrentTenant = createAction("[Current Tenant] Set Current Tenant", props<{ tenant: Salon }>());

export const currentTenantExists = createAction("[Current Tenant] Current Tenant Exists", props<{ slug: string }>());

export const tenantNotFound = createAction("[Current Tenant] Tenant Not Found");

export const ensureCurrentTenantExists = createAction("[Current Tenant] Ensure Current Tenant Exists", props<{ slug: string }>());

const actions = union({
  init,
  loadAvailableSalons,
  loadAvailableTenantsSuccess,
  setCurrentTenant,
  ensureCurrentTenantExists,
  currentTenantExists,
  tenantNotFound,
});

export type Actions = typeof actions;
