import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Employee, Appointment, Customer } from "@getvish/model";
import { Observable } from "rxjs";
import { AppState } from "../../kernel";
import { getSalonTimeZone } from "app/+salon-config/store/salon-config.selectors";

import * as fromCustomer from "../../+customers/store/customer.selectors";
import * as fromScheduleAppointment from "../store/schedule-appointment.selectors";
import * as actions from "../store/schedule-appointment.actions";

@Component({
  selector: "schedule-appointment-container",
  templateUrl: "schedule-appointment.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleAppointmentContainer {
  public employees$: Observable<Employee[]>;
  public customers$: Observable<Customer[]>;
  public error$: Observable<string>;
  public loading$: Observable<boolean>;
  public saving$: Observable<boolean>;
  public filter$: Observable<string>;
  public timeZone$: Observable<string>;

  constructor(private _store: Store<AppState>) {
    this.employees$ = this._store.pipe(select(fromScheduleAppointment.getEmployees));
    this.customers$ = this._store.pipe(select(fromCustomer.getCustomers));
    this.loading$ = this._store.pipe(select(fromScheduleAppointment.getLoading));
    this.saving$ = this._store.pipe(select(fromScheduleAppointment.getSaving));
    this.filter$ = this._store.pipe(select(fromScheduleAppointment.getFilter));
    this.error$ = this._store.pipe(select(fromScheduleAppointment.getError));
    this.timeZone$ = this._store.select(getSalonTimeZone);

    this._store.dispatch(actions.load());
  }

  public close(): void {
    this._store.dispatch(actions.close());
  }

  public cancel(): void {
    this._store.dispatch(actions.cancel());
  }

  public save(appointment: Appointment): void {
    this._store.dispatch(actions.schedule({ appointment }));
  }

  public searchCustomer(filter: string): void {
    this._store.dispatch(actions.searchCustomers({ filter }));
  }
}
