import { ValidationErrors, UntypedFormGroup } from "@angular/forms";

export const equalsValidator =
  (controlNameA: string, controlNameB: string) =>
  (control: UntypedFormGroup): ValidationErrors | null => {
    const controlA = control.get(controlNameA);
    const controlB = control.get(controlNameB);

    return controlA && controlB && (controlA.value === controlB.value ? null : { equals: true });
  };
