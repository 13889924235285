/**
 * Created by ryne on 01/09/16.
 */
import { Component, HostBinding, Input, ChangeDetectionStrategy } from "@angular/core";
/*
 * Represents the panel backdrop.
 * Backdrop will be shown whenever a panel is open, focusing attention on the current panel
 */
@Component({
  selector: "backdrop-component",
  template: "",
  styleUrls: ["./backdrop.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackdropComponent {
  @Input()
  @HostBinding("class.in")
  public visible = false;
}
