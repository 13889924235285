import { EmployeeReport, LongitudinalReport, SalonReport, ServiceReport } from "app/kernel/models";
import * as analytics from "./analytics.actions";

export interface AnalyticsState {
  loading: boolean;
  startDate: number;
  endDate: number;
  error: string;
  aggregatedBy: string;
  employeeId: string;
  currentSalonReport: SalonReport;
  longitudinalReport: LongitudinalReport;
  employeeReports: EmployeeReport[];
  serviceReports: ServiceReport[];
}

const initialState: AnalyticsState = {
  loading: false,
  startDate: undefined,
  endDate: undefined,
  error: undefined,
  aggregatedBy: "MONTHLY",
  employeeId: undefined,
  currentSalonReport: undefined,
  longitudinalReport: undefined,
  employeeReports: undefined,
  serviceReports: undefined,
};

export function analyticsReducer(state: AnalyticsState = initialState, action: analytics.Actions): AnalyticsState {
  switch (action.type) {
    case analytics.Types.INITIALIZE_AND_LOAD_DASHBOARD_REPORTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case analytics.Types.LOAD_DASHBOARD_REPORTS: {
      const loading = true;
      const error = undefined;
      const employeeId = undefined;
      const { startDate, endDate, aggregatedBy } = action.payload;

      return {
        ...state,
        loading,
        error,
        startDate,
        endDate,
        aggregatedBy,
        employeeId,
      };
    }

    case analytics.Types.LOAD_CURRENT_SUCCESS: {
      return {
        ...state,
        currentSalonReport: action.payload,
      };
    }

    case analytics.Types.LOAD_LONGITUDINAL_REPORT_SUCCESS: {
      return {
        ...state,
        longitudinalReport: action.entity,
      };
    }

    case analytics.Types.LOAD_EMPLOYEE_REPORTS_SUCCESS: {
      return {
        ...state,
        employeeReports: action.payload,
      };
    }

    case analytics.Types.LOAD_SERVICE_REPORTS_SUCCESS: {
      return {
        ...state,
        serviceReports: action.payload,
      };
    }

    case analytics.Types.LOAD_DASHBOARD_REPORTS_SUCCESS: {
      const loading = false;

      return {
        ...state,
        loading,
      };
    }

    case analytics.Types.LOAD_DASHBOARD_REPORTS_FAIL: {
      const loading = false;
      const error = action.payload.error;

      return {
        ...state,
        loading,
        error,
      };
    }

    case analytics.Types.LOAD_EMPLOYEE_REPORTS: {
      const loading = true;
      const error = undefined;
      const { startDate, endDate, employeeId } = action.payload;

      return {
        ...state,
        loading,
        error,
        employeeId,
        startDate,
        endDate,
      };
    }

    case analytics.Types.UPDATE_DATE_RANGE: {
      const loading = true;
      const startDate = action.payload.startDate;
      const endDate = action.payload.endDate;

      return {
        ...state,
        loading,
        startDate,
        endDate,
      };
    }

    case analytics.Types.CHANGE_AGGREGATION: {
      const aggregatedBy = action.payload.aggregatedBy;

      return {
        ...state,
        aggregatedBy,
      };
    }

    default: {
      return state;
    }
  }
}
