<div *ngIf="error">
  <div class="page-content">
    <alert-component type="danger">
      <h4>An Error Has Occured</h4>

      <p>{{ error }}</p>
    </alert-component>
  </div>
</div>

<div *ngIf="!error">
  <div *ngIf="!success">
    <div class="page-content">
      <h4>
        Delete salon <span class="highlight">{{ salon.name }}</span
        >?
      </h4>
      <p>This will pemenantly remove this salon from the database. This <b>cannot be undone.</b></p>
    </div>
    <form
      [formGroup]="form"
      (submit)="deleteSalon(salon)"
      novalidate>
      <h5>
        Type <b>'{{ salon.slug }}'</b> to confirm
      </h5>
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="slug" />
        <mat-error *ngIf="form.get('slug').hasError('required')">Required</mat-error>
        <mat-error *ngIf="form.get('slug').hasError('equals')">Please enter the <i>exact</i> slug of the salon</mat-error>
      </mat-form-field>
    </form>

    <div class="controls">
      <button
        (click)="cancel.emit()"
        [disabled]="deleting"
        class="button outline">
        <span>Cancel</span>
      </button>
      <button
        (click)="deleteSalon(salon)"
        [disabled]="form.invalid"
        class="button primary">
        <span *ngIf="!deleting; else loadingIndicator">Delete</span>
        <ng-template #loadingIndicator>
          <fa-icon
            *ngIf="deleting"
            [icon]="['fas', 'circle-notch']"
            [spin]="true"></fa-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <div
    *ngIf="success"
    class="page-content">
    <div class="page-content">
      <alert-component type="success">
        <h4>Salon Deleted Successfully</h4>

        <p>{{ salon.name }} was successfully deleted from the database. You can close this panel now.</p>
      </alert-component>
    </div>

    <div class="controls">
      <button
        (click)="done.emit()"
        class="button primary">
        <span>Done</span>
      </button>
    </div>
  </div>
</div>
