import { ChangeDetectionStrategy, Component } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../kernel";
import { Manufacturer } from "@getvish/model";
import { combineLatest, Observable } from "rxjs";
import { ManufacturerVm, fromManufacturer, getFilterByIncompletePricing } from "../../+manufacturers/models/manufacturer-view.model";
import { map } from "rxjs/operators";

import * as SalonProductActions from "../store/salon-product.actions";
import * as fromSalonProduct from "../store/salon-product.selectors";
import * as ImportProductsDialogActions from "../../+product-selection/store/import-salon-products.actions";

@Component({
  selector: "salon-products-container",
  templateUrl: "salon-products.container.html",
  styleUrls: ["salon-product.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalonProductsContainer {
  public loading$: Observable<boolean>;
  public saving$: Observable<boolean>;
  public error$: Observable<string>;
  public filteredActiveManufacturers$: Observable<ManufacturerVm[]>;
  public hasActiveManufactures$: Observable<boolean>;

  public progressText$: Observable<string>;

  constructor(private _store: Store<AppState>) {
    this.hasActiveManufactures$ = _store.pipe(select(fromSalonProduct.getHasActiveManufacturers));

    this.loading$ = this._store.pipe(select(fromSalonProduct.getLoading));
    this.saving$ = this._store.pipe(select(fromSalonProduct.getSaving));
    this.error$ = this._store.pipe(select(fromSalonProduct.getError));

    this.progressText$ = combineLatest([this.loading$, this.saving$]).pipe(
      map(([loading, saving]) => {
        if (loading) {
          return "Loading...";
        } else if (saving) {
          return "Saving...";
        }

        return undefined;
      })
    );

    this.filteredActiveManufacturers$ = combineLatest([
      this._store.pipe(select(fromSalonProduct.getManufacturerByNameFilter)),
      this._store.pipe(select(fromSalonProduct.getAll)),
      this._store.pipe(select(fromSalonProduct.getIncompletePricingFilter)),
    ]).pipe(
      map(([manufacturers, salonProducts, pricingIncompleteFilter]) =>
        manufacturers.map(fromManufacturer(salonProducts)).filter(getFilterByIncompletePricing(pricingIncompleteFilter))
      )
    );

    this._store.dispatch(new SalonProductActions.LoadAll({}));
    this._store.dispatch(new SalonProductActions.SetSearchFilter({ searchFilter: null }));
    this._store.dispatch(new SalonProductActions.SetIncompletePricingFilter({ filter: false }));
  }

  public selectManufacturer(manufacturer: Manufacturer): void {
    this._store.dispatch(new SalonProductActions.SelectManufacturer({ manufacturer }));
  }

  public searchManufacturerByName(name: string): void {
    this._store.dispatch(new SalonProductActions.SetSearchFilter({ searchFilter: name }));
  }

  public setIncompletePricingFilter(incompletePricingOnly: boolean): void {
    this._store.dispatch(
      new SalonProductActions.SetIncompletePricingFilter({
        filter: incompletePricingOnly,
      })
    );
  }

  public importProducts(): void {
    this._store.dispatch(ImportProductsDialogActions.show());
  }

  public changeManufacturerOrder(): void {
    this._store.dispatch(new SalonProductActions.ShowManufacturerOrderDialog());
  }
}
