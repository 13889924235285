import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ProductCategoryModule } from "@getvish/model";
import { ProductCategoryState } from "./product-category.reducer";
import { equals } from "ramda";
import { getManufacturer } from "../../+manufacturers/store/manufacturer.selectors";
import { ProductState } from "../../../+product/+products/store/product.reducer";

/*** HELPERS ***/

/*** SELECTORS ***/
export const getState = createFeatureSelector<ProductCategoryState>("productCategories");
export const getProductState = createFeatureSelector<ProductState>("products");
export const getRecords = createSelector(getState, (state) => state.records);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getSaving = createSelector(getState, (state) => state.saving);
export const getPaging = createSelector(getState, (state) => state.paging);

export const getAll = createSelector(getState, (state) => state.records);
export const getAllProducts = createSelector(getProductState, (state) => state.records);

export const get = (id: string) => createSelector(getAll, (entities) => entities.find((entity) => equals(entity._id, id)));

export const getNewMetadata = createSelector(getState, (state) => state.newMetadata);

export const getSelectedManufacturerId = createSelector(getState, (state) => state.selectedManufacturerId);

export const getSelectedManufacturer = createSelector(getSelectedManufacturerId, (selectedManufacturerId) =>
  getManufacturer(selectedManufacturerId)
);

export const getSelectedCategoryIds = createSelector(getState, (state) => state.selectedCategoryIds);

export const getRootCategoryMap = createSelector(getAll, (entities) => ProductCategoryModule.generateRootCategoryIdMap(entities));
