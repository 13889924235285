import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Product, SalonProduct } from "@getvish/model";
import { ListOrdererItem } from "app/+components/+list-orderer/components/list-orderer.component";
import { pipe } from "fp-ts/lib/function";
import * as R from "ramda";
import { v4 as uuid } from "uuid";

type Item = {
  id: string;
  salonProduct: SalonProduct;
  product: Product;
  text: string;
};

@Component({
  selector: "product-order-dialog",
  templateUrl: "./product-order-dialog.component.html",
  styleUrls: ["./product-order-dialog.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductOrderDialogComponent {
  public items: Item[];

  private products: Product[];
  private salonProducts: SalonProduct[];

  constructor(
    private _matDialogRef: MatDialogRef<ProductOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      products?: Product[];
      salonProducts?: SalonProduct[];
    }
  ) {
    this.products = data.products;
    this.salonProducts = data.salonProducts;

    this.items = pipe(
      data.products ?? data.salonProducts,
      R.sort((p1, p2) => {
        const order1 = p1.order ?? Number.MAX_SAFE_INTEGER;
        const order2 = p2.order ?? Number.MAX_SAFE_INTEGER;

        const ret = order1 - order2;

        if (ret === 0) {
          return p1.name.localeCompare(p2.name, undefined, { sensitivity: "base" });
        }

        return ret;
      }),
      R.map((product) => {
        return {
          id: uuid(),
          salonProduct: this.salonProducts ? (product as SalonProduct) : undefined,
          product,
          text: product.name,
        };
      })
    );
  }

  public orderChanged(items: ListOrdererItem[]) {
    this.items = items.map((item) => ({
      ...(this.items.find((i) => i.id === item.id) ?? []),
      ...item,
    })) as Item[];
  }

  public save(): void {
    const changes = (this.products ?? this.salonProducts).reduce((acc, pc) => {
      const idx = this.items.findIndex((i) => i["product"]._id === pc._id);

      if (this.salonProducts) {
        const products = this.salonProducts.filter((spc) => spc.productId === pc["productId"]);

        if (!R.isEmpty(products)) {
          acc.push(
            ...products
              .filter((p) => p.order !== idx)
              .map((p) => ({
                ...p,
                order: idx,
              }))
          );
        }
      } else if (pc.order !== idx) {
        acc.push({
          ...pc,
          order: idx,
        });
      }

      return acc;
    }, []);

    this._matDialogRef.close(changes.length === 0 ? undefined : changes);
  }

  public close(): void {
    this._matDialogRef.close();
  }

  public sortAlphabetical() {
    this.items = R.sortBy((i) => i.text, this.items);
  }

  public sortReverseAlphabetical() {
    this.items = R.reverse(R.sortBy((i) => i.text, this.items));
  }

  public sortAlphanumerical() {
    this.items = R.sort((i1, i2) => this._compareNamesAlphanumerically(i1.text, i2.text, false), this.items);
  }

  public sortReverseAlphanumerical() {
    this.items = R.sort((i1, i2) => this._compareNamesAlphanumerically(i1.text, i2.text, true), this.items);
  }

  private _compareNamesAlphanumerically = (name1: string, name2: string, reverseNumeric: boolean): number => {
    const tokenize = (str: string) =>
      str.split("").reduce(
        (acc, ch) => {
          const numeric = ch >= "0" && ch <= "9";
          const value = numeric ? parseInt(ch, 10) : ch;

          if (R.isEmpty(acc)) {
            return [...acc, value];
          } else {
            const lastValueNumeric = typeof R.last(acc) === "number";

            if (numeric && !lastValueNumeric && ch === "0") {
              return R.adjust(acc.length - 1, (lastValue) => `${lastValue}${value}`, acc);
            } else if (numeric !== lastValueNumeric) {
              return [...acc, value];
            } else if (numeric) {
              return R.adjust(acc.length - 1, (lastValue) => parseInt(`${lastValue}${value}`, 10), acc);
            } else {
              return R.adjust(acc.length - 1, (lastValue) => `${lastValue}${value}`, acc);
            }
          }
        },
        [] as (string | number)[]
      );

    const tokens1 = tokenize(name1);
    const tokens2 = tokenize(name2);

    return tokens1.reduce((acc: number, token1, i) => {
      if (acc !== 0) {
        return acc;
      }

      const token2 = tokens2.length > i ? tokens2[i] : undefined;

      if (R.isNil(token2)) {
        return 1;
      } else if (token1 === token2) {
        return 0;
      }

      const numeric1 = typeof token1 === "number";
      const numeric2 = typeof token2 === "number";

      if (numeric1) {
        if (numeric2) {
          if (reverseNumeric) {
            return (token2 - token1) as number;
          } else {
            return (token1 - token2) as number;
          }
        } else {
          return -1;
        }
      } else if (numeric2) {
        return 1;
      } else {
        return token1.toLocaleLowerCase().localeCompare(token2.toLocaleLowerCase(), undefined, { sensitivity: "base" });
      }
    }, 0) as number;
  };
}
