import { Component, ViewEncapsulation, Renderer2, HostBinding } from "@angular/core";
import { Observable } from "rxjs";
import { getPanelOpen } from "../../../store/reducers/ui.reducer";
import { AppState } from "../../../store";

import { Store } from "@ngrx/store";

@Component({
  selector: "slideout-panel-container",
  template: `
    <backdrop-component *ngIf="isOpen$ | async"></backdrop-component>
    <div class="slideout-panel-container">
      <div class="slideout-panel">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ["./slideout-panel.less"],
  encapsulation: ViewEncapsulation.None,
})
export class SlideoutPanelContainerComponent {
  public isOpen$: Observable<boolean>;

  @HostBinding("class.isOpen")
  public _isOpen: boolean;

  constructor(
    private _renderer: Renderer2,
    private _store: Store<AppState>
  ) {
    this.isOpen$ = _store.select(getPanelOpen);
  }
}
