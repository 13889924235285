import { Component, Input } from "@angular/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";

@Component({
  selector: "progress-indicator",
  templateUrl: "progress-indicator.component.html",
  styleUrls: ["progress-indicator.component.less"],
})
export class ProgressIndicatorComponent {
  @Input()
  public text?: string;

  @Input()
  public mode: ProgressSpinnerMode = "indeterminate";

  @Input()
  public value?: number;
}
