import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ManufacturerReport } from "../../kernel/models";

import { option } from "fp-ts";

@Injectable()
export class ManufacturerReportService extends EntityService<ManufacturerReport> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "manufacturerReports" });
  }

  public fetchForDateRange(startDate: number, endDate: number): Observable<ManufacturerReport> {
    const criteria = { startDate, endDate };

    return this.findOne(criteria).pipe(map(option.toUndefined));
  }

  public fetchForEmployeeInDateRange(
    employeeId: string,
    startDate: number,
    endDate: number
  ): Observable<option.Option<ManufacturerReport>> {
    const employeeIds = [employeeId];
    const criteria = { startDate, endDate, employeeIds };

    return this.findOne(criteria);
  }
}
