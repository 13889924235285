import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil, tap } from "rxjs/operators";

import { NotificationService } from "../services";
import { loadNotifications, loadNotificationsFail, loadNotificationsSuccess, markAsRead, markAsReadSuccess } from "./notifications.actions";
import { AppState } from "app/kernel";
import { Store } from "@ngrx/store";

@Injectable()
export class NotificationsEffects {
  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _notificationService: NotificationService
  ) {}

  public loadNotifications$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(loadNotifications),
        switchMap(() =>
          this._notificationService.getAllNotifications().pipe(
            takeUntil(this._actions$.pipe(ofType(loadNotifications))),
            tap((notifications) => this._store.dispatch(loadNotificationsSuccess({ notifications })))
          )
        ),
        catchError((error) => of(loadNotificationsFail({ error })))
      ),
    { dispatch: false }
  );

  public markAsRead$ = createEffect(() =>
    this._actions$.pipe(
      ofType(markAsRead),
      switchMap(({ notifications }) =>
        this._notificationService.markAsRead(notifications).pipe(map(() => markAsReadSuccess({ notifications })))
      )
    )
  );
}
