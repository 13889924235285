import { Injectable } from "@angular/core";
import { AuthService, isAdmin } from "app/+auth/services";
import { Observable, of } from "rxjs";

@Injectable()
export class CanActivateAdminUser {
  constructor(private _authService: AuthService) {}

  public canActivate(): Observable<boolean> {
    return of(isAdmin(this._authService.getUser()));
  }
}
