<div>
  <form
    [formGroup]="form"
    (submit)="saveForm(service, form.value)"
    novalidate>
    <div>
      <mat-form-field class="full-width">
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name" />
        <mat-error *ngIf="form.get('name').hasError('required')">Required</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Category</mat-label>
        <input
          type="text"
          matInput
          formControlName="categoryId"
          [matAutocomplete]="categoryAuto" />
        <mat-error *ngIf="form.get('categoryId').hasError('selectCategory')"> Please select a category from the list </mat-error>
      </mat-form-field>
      <mat-autocomplete
        #categoryAuto="matAutocomplete"
        [displayWith]="displayCategory(categories)">
        <ng-container *ngFor="let group of filteredCategoryGroups$ | async">
          <mat-option [value]="group.rootCategory._id">
            {{ group.rootCategory.name }}
          </mat-option>
          <mat-option
            *ngFor="let category of group.children"
            class="child-category"
            [value]="category._id">
            {{ category.name }}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <mat-form-field class="full-width">
        <mat-label>Flags</mat-label>
        <mat-select
          formControlName="flags"
          multiple>
          <mat-option value="DEFAULT">Default</mat-option>
          <mat-option value="EXCLUDE_FROM_ANALYTICS">Exclude From Analytics</mat-option>
          <mat-option value="INACTIVE">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="controls">
    <button
      (click)="saveForm(service, form.value)"
      class="button primary"
      [disabled]="!form.valid">
      <span *ngIf="!saving; else loadingIndicator">Save</span>
      <ng-template #loadingIndicator>
        <fa-icon
          *ngIf="saving"
          [icon]="['fas', 'circle-notch']"
          [spin]="true"></fa-icon>
      </ng-template>
    </button>
  </div>
</div>
