import { Component, ChangeDetectionStrategy, Input, HostBinding } from "@angular/core";

@Component({
  selector: "card",
  templateUrl: "card.component.html",
  styleUrls: ["card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @HostBinding("class") class = "card";

  @Input()
  public cardTitle?: string;
}
