import { Component } from "@angular/core";
import { AppState } from "../../kernel";
import { Store } from "@ngrx/store";

import { Done } from "../store/reset-password.actions";

@Component({
  selector: "set-password-success",
  templateUrl: "set-password-success.container.html",
  styleUrls: ["set-password-success.container.less"],
})
export class SetPasswordSuccessContainer {
  constructor(private _store: Store<AppState>) {}

  public navigateToLogin(): void {
    this._store.dispatch(new Done());
  }
}
