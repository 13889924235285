import { Action } from "@ngrx/store";
import { MatSnackBarConfig } from "@angular/material/snack-bar";

export enum Types {
  INFO = "[Snackbar] Information Snackbar",
  PROMPT = "[Snackbar] Prompt Snackbar",
  DISMISS = "[Snackbar] Dismiss Snackbar",
}

export class Info implements Action {
  public readonly type = Types.INFO;

  constructor(public payload: { message: string; config?: MatSnackBarConfig }) {}
}

export class Prompt implements Action {
  public readonly type = Types.PROMPT;

  constructor(public payload: { message: string; action: string; config?: MatSnackBarConfig }) {}
}

export class Dismiss implements Action {
  public readonly type = Types.DISMISS;

  constructor() {}
}

export type Actions = Info | Prompt | Dismiss;
