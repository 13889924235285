import { createSelector, createFeatureSelector } from "@ngrx/store";
import { isNil, not } from "ramda";
import { AnalyticsState } from "./analytics.reducer";

/*** SELECTORS ***/
export const getAnalayticsState = createFeatureSelector<AnalyticsState>("analytics");

export const getLongitudinalReport = createSelector(getAnalayticsState, (state) => state.longitudinalReport);

export const getEmployeeReports = createSelector(getAnalayticsState, (state) =>
  not(isNil(state.employeeReports)) ? state.employeeReports : []
);

export const getServiceReports = createSelector(getAnalayticsState, (state) =>
  not(isNil(state.serviceReports)) ? state.serviceReports : []
);

export const getSalonReport = createSelector(getAnalayticsState, (state) => state.currentSalonReport);

export const getEmployeeReport = (employeeId: string) =>
  createSelector(getEmployeeReports, (reports) => reports.find((report) => report.employeeId === employeeId));

export const getLoading = createSelector(getAnalayticsState, (state) => state.loading);
export const getError = createSelector(getAnalayticsState, (state) => state.error);
export const getStartDate = createSelector(getAnalayticsState, (state) => state.startDate);
export const getEndDate = createSelector(getAnalayticsState, (state) => state.endDate);
export const getAggregatedBy = createSelector(getAnalayticsState, (state) => state.aggregatedBy);
export const getEmployeeId = createSelector(getAnalayticsState, (state) => state.employeeId);
