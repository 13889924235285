import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { DiscontinuedProduct, Product, ProductFlag } from "@getvish/model";
import { pipe } from "fp-ts/lib/function";
import { map, sort } from "ramda";

type ProductFlagVM = ProductFlag | "DISCONTINUED";
interface ProductVm extends Omit<Product, "flags"> {
  notImported?: boolean;
  flags?: ProductFlagVM[];
}

@Component({
  selector: "product-categories-product-list",
  templateUrl: "product-categories-products-list.component.html",
  styleUrls: ["product-categories-products-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCategoriesProductListComponent implements OnChanges {
  @Input() public products: Product[];
  @Input() public discontinuedProducts: { [key: string]: DiscontinuedProduct };
  @Output() public select: EventEmitter<Product>;
  @Output() public discontinue: EventEmitter<Product>;

  public productVms: ProductVm[];

  constructor() {
    this.select = new EventEmitter(true);
    this.discontinue = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    this.productVms = pipe(
      this.products,
      map((product) => {
        return {
          ...product,
          flags:
            this.discontinuedProducts[product._id] != null
              ? ([...(product.flags || []), "DISCONTINUED"] as ProductFlagVM[])
              : product.flags,
        };
      }),
      sort((p1, p2) => {
        const order1 = p1.order ?? Number.MAX_SAFE_INTEGER;
        const order2 = p2.order ?? Number.MAX_SAFE_INTEGER;

        const ret = order1 - order2;

        if (ret === 0) {
          return p1.name.localeCompare(p2.name, undefined, { sensitivity: "base" });
        }

        return ret;
      })
    );
  }

  selectProduct(product: ProductVm): void {
    this.select.emit(product as Product);
  }

  discontinueProduct(product: ProductVm): void {
    this.discontinue.emit(product as Product);
  }
}
