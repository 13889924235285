import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { equalsValidator } from "../validators";

export interface FormData {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: "reset-password-form",
  templateUrl: "reset-password-form.component.html",
  styleUrls: ["./common.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() public error: string;
  @Output() public save: EventEmitter<FormData>;

  public form: UntypedFormGroup;

  constructor() {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup(
      {
        password: new UntypedFormControl(undefined, [Validators.required]),
        confirmPassword: new UntypedFormControl(undefined, [Validators.required]),
      },
      equalsValidator("password", "confirmPassword")
    );
  }

  public submit(value: FormData) {
    this.save.emit(value);
  }
}
