<div class="notification-list">
  <ng-container *ngIf="(notifications?.length ?? 0) > 0; else noNotifications">
    <div
      class="item"
      *ngFor="let notification of notifications; let idx = index">
      <notification-list-item
        [notification]="notification"
        (action)="action.emit(notification)"></notification-list-item>
    </div>
  </ng-container>
  <ng-template #noNotifications>
    <div class="no-notifications">
      <empty-state-message
        icon="envelope"
        title="No notifications">
        <p>You have no notifications at this time.</p>
      </empty-state-message>
    </div>
  </ng-template>
</div>
