import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { AppState } from "../../kernel";
import { map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { ManufacturerReportService, ProductCategoryReportService } from "../services";
import { ProductReportService } from "../services/product-report.service";
import { ManufacturerService } from "../../+product/+manufacturers/services";
import { ProductCategoryService } from "../../+product/+product-categories/services";
import { SalonProductService } from "../../+product/+salon-products";
import { EmployeeService } from "../../+employees/services";
import { getManufacturerId } from "./product-report.selectors";
import { saveAs } from "file-saver";
import { ProductReportDownloaderService } from "../services/product-report-downloader.service";
import { getSalonConfig, getSalonTimeZone } from "../../+salon-config/store/salon-config.selectors";
import { AnalyticsService } from "../services/analytics.service";

import * as actions from "./product-report.actions";
import * as manufacturerActions from "../../+product/+manufacturers/store/manufacturer.actions";
import * as productCategoryActions from "../..//+product/+product-categories/store/product-category.actions";
import * as salonProductActions from "../..//+product/+salon-products/store/salon-product.actions";
import * as employeeActions from "../..//+employees/store/employee.actions";
import * as fromProductReport from "./product-report.selectors";

@Injectable()
export class ProductReportsEffects {
  public initializeAndLoadProductReportGraph$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.InitializeAndLoadProductReportGraph>(actions.Types.INITIALIZE_AND_LOAD_PRODUCT_REPORT_GRAPH),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(fromProductReport.getStartDate)), this._store.pipe(select(fromProductReport.getEndDate))),
      switchMap(([{ manufacturerId }, startDate, endDate]) =>
        this._analyticsService.loadSalonConfigAndDateRange(startDate, endDate).pipe(
          mergeMap(({ startDate, endDate }) => [
            new actions.LoadProductReportGraph({
              manufacturerId,
              startDate,
              endDate,
            }),
          ])
        )
      )
    )
  );

  public loadProductReportGraph$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.LoadProductReportGraph>(actions.Types.LOAD_PRODUCT_REPORT_GRAPH),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getSalonTimeZone)),
      switchMap(([{ startDate, endDate, manufacturerId }, timeZone]) => {
        const fetchProductCategoryReport$ = this._productCategoryReportService.fetchForDateRange(
          manufacturerId,
          startDate,
          endDate,
          timeZone
        );
        const fetchManufacturers$ = this._manufacturerService.find();
        const fetchProductReport$ = this._productReportService.fetchForDateRange(manufacturerId, startDate, endDate, timeZone);
        const fetchProductCategoriesForManufacturer$ = this._productCategoryService.findForManufacturer(manufacturerId);
        const fetchSalonProductsForManufacturer$ = this._salonProductService.findForManufacturer(manufacturerId);
        const fetchEmployees$ = this._employeeService.find();

        return forkJoin({
          productCategoryReport: fetchProductCategoryReport$,
          productReport: fetchProductReport$,
          manufacturers: fetchManufacturers$,
          productCategories: fetchProductCategoriesForManufacturer$,
          salonProducts: fetchSalonProductsForManufacturer$,
          employees: fetchEmployees$,
        }).pipe(
          map(
            ({ productCategoryReport, manufacturers, productReport, productCategories, salonProducts, employees }) =>
              new actions.LoadProductReportGraphSuccess({
                productCategoryReport,
                manufacturers,
                productReport,
                productCategories,
                salonProducts,
                employees,
              })
          )
        );
      })
    )
  );

  public loadProductReportGraphSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.LoadProductReportGraphSuccess>(actions.Types.LOAD_PRODUCT_REPORT_GRAPH_SUCCESS),
      map((action) => action.payload),
      mergeMap(({ productCategoryReport, manufacturers, productReport, productCategories, salonProducts, employees }) => {
        return [
          new actions.LoadProductReportsSuccess(productReport),
          new manufacturerActions.LoadAllSuccess(manufacturers.records, manufacturers.paging),
          new actions.LoadProductCategoryReportSuccess(productCategoryReport),
          new productCategoryActions.LoadAllSuccess(productCategories.records, productCategories.paging),
          new salonProductActions.LoadAllSuccess(salonProducts),
          new employeeActions.LoadAllSuccess(employees.records, employees.paging),
        ];
      })
    )
  );

  public updateProductReportFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.UpdateProductReportFilters>(actions.Types.UPDATE_PRODUCT_REPORT_FILTERS),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getManufacturerId)), this._store.select(getSalonTimeZone)),
      switchMap(([{ startDate, endDate }, manufacturerId, timeZone]) =>
        this._productReportService
          .fetchForDateRange(manufacturerId, startDate, endDate, timeZone)
          .pipe(mergeMap((result) => [new actions.LoadProductReportsSuccess(result)]))
      )
    )
  );

  public initializeAndLoadManufacturerReport$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.InitializeAndLoadManufacturerReport>(actions.Types.INITIALIZE_AND_LOAD_MANUFACTURER_REPORT),
      withLatestFrom(this._store.pipe(select(fromProductReport.getStartDate)), this._store.pipe(select(fromProductReport.getEndDate))),
      switchMap(([_, startDate, endDate]) =>
        this._analyticsService
          .loadSalonConfigAndDateRange(startDate, endDate)
          .pipe(mergeMap(({ startDate, endDate }) => [new actions.LoadManufacturerReport({ startDate, endDate })]))
      )
    )
  );

  public loadManufacturerReport$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.LoadManufacturerReport>(actions.Types.LOAD_MANUFACTURER_REPORT),
      map((action) => action.payload),
      switchMap(({ startDate, endDate }) =>
        this._manufacturerReportService
          .fetchForDateRange(startDate, endDate)
          .pipe(mergeMap((result) => [new actions.LoadManufacturerReportSuccess(result)]))
      )
    )
  );

  public updateManufacturerReportFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.UpdateManufacturerReportFilters>(actions.Types.UPDATE_MANUFACTURER_REPORT_FILTERS),
      map((action) => action.payload),
      switchMap(({ startDate, endDate }) =>
        this._manufacturerReportService
          .fetchForDateRange(startDate, endDate)
          .pipe(mergeMap((result) => [new actions.LoadManufacturerReportSuccess(result)]))
      )
    )
  );

  public updateDateRange$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.UpdateDateRange>(actions.Types.UPDATE_DATE_RANGE),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getManufacturerId))),
      map(
        ([{ startDate, endDate }, manufacturerId]) =>
          new actions.LoadProductReportGraph({
            manufacturerId,
            startDate,
            endDate,
          })
      )
    )
  );

  public downloadReport$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<actions.DownloadManufacturerReport>(actions.Types.DOWNLOAD_MANUFACTURER_REPORT),
        withLatestFrom(
          this._store.pipe(select(getSalonConfig)),
          this._store.pipe(select(fromProductReport.getStartDate)),
          this._store.pipe(select(fromProductReport.getEndDate)),
          this._store.pipe(select(fromProductReport.getManufacturerId))
        ),
        switchMap(([_, salonConfig, startDate, endDate, manufacturerId]) =>
          this._productReportDownloaderService
            .downloadReport(salonConfig.salonId, manufacturerId, startDate, endDate)
            .pipe(map((response) => saveAs(response, "vish-product-report.xlsx")))
        )
      ),
    { dispatch: false }
  );

  constructor(
    private _productReportService: ProductReportService,
    private _manufacturerReportService: ManufacturerReportService,
    private _manufacturerService: ManufacturerService,
    private _productCategoryReportService: ProductCategoryReportService,
    private _salonProductService: SalonProductService,
    private _productCategoryService: ProductCategoryService,
    private _employeeService: EmployeeService,
    private _analyticsService: AnalyticsService,
    private _store: Store<AppState>,
    private _productReportDownloaderService: ProductReportDownloaderService,
    private _actions$: Actions
  ) {}
}
