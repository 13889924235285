<div
  class="progress-indicator"
  [ngClass]="{ 'with-text': text | isDefined }">
  <mat-progress-spinner
    diameter="75"
    [mode]="mode"
    [value]="value"></mat-progress-spinner>
  <div
    class="text"
    *ngIf="text">
    {{ text }}
  </div>
</div>
