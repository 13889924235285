import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter, OnChanges, TemplateRef } from "@angular/core";
import { ProductCategory, Product, SalonProduct } from "@getvish/model";
import { ProductCategoryNode, assignChildCategories, assignProductsToCategory } from "../common";
import { isNil } from "ramda";

@Component({
  selector: "product-categories",
  templateUrl: "product-categories.component.html",
  styleUrls: ["product-categories.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCategoriesComponent implements OnChanges {
  @Input() public categories: ProductCategory[];
  @Input() public activeCategoryIds: string[];
  @Input() public products: Product[];
  @Input() public loading: boolean;
  @Input() public categoryMenuTemplate: TemplateRef<any>;
  @Input() public productListTemplate: TemplateRef<any>;
  @Output() public select: EventEmitter<ProductCategory>;
  @Output() public addCategory: EventEmitter<ProductCategory>;
  @Output() public addProduct: EventEmitter<ProductCategory>;
  @Output() public editProduct: EventEmitter<SalonProduct>;
  @Output() public edit: EventEmitter<ProductCategory>;
  @Output() public toggleCategory: EventEmitter<ProductCategory>;

  public categoryNodes: ProductCategoryNode[];

  constructor() {
    this.select = new EventEmitter(true);
    this.addCategory = new EventEmitter(true);
    this.addProduct = new EventEmitter(true);
    this.edit = new EventEmitter(true);
    this.editProduct = new EventEmitter(true);
    this.toggleCategory = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    const categories = this.categories;
    const products = this.products;

    const categoriesWithProducts = categories.map(assignProductsToCategory(products));

    const isRootCategory = (category: ProductCategoryNode) => isNil(category.parentCategoryId);

    const assignChildCategoriesPartial = assignChildCategories(categoriesWithProducts);

    this.categoryNodes = categoriesWithProducts.filter(isRootCategory).map(assignChildCategoriesPartial);
  }
}
