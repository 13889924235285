import { Metrics } from "./metrics";
import { ReportStatistic } from "./report-statistic";
import { fromNullable, fold } from "fp-ts/Option";
import { pipe } from "fp-ts/function";

export class ProductReport {
  public _id: string;
  public summary: Metrics;
  public productStatistics: ReportStatistic[];
  public _type: string;
}

export const productReportIsEmpty = (value: ProductReport): boolean =>
  pipe(
    value,
    fromNullable,
    fold(
      () => false,
      (report) => report.summary.numServicesPerformed === 0
    )
  );
