<div class="component-container">
  <div class="content-wrapper">
    <header>
      <h1>Reset Password</h1>
    </header>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
