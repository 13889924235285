import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { v4 as uuid } from "uuid";
import { MixingContainerConfig, MixingContainerVM } from "../../models";

@Component({
  selector: "bowl",
  templateUrl: "./bowl.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BowlComponent {
  @Input() public mixingContainerVM: MixingContainerVM;
  public clipPathId = uuid();

  public mixingContainerConfig: MixingContainerConfig = {
    bottomLimit: 0,
    topLimit: 0.935,
    defaultVolume: 150,
    emptyColorHex: "#dfdfdf99",
    topBottomColorHex: "#313131",
    svgType: "bowl",
  };
}
