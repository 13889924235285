import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Either } from "fp-ts/lib/Either";
import { HttpError, HttpRequestHandler } from "@getvish/stockpile";

export interface PasswordResetTokenRequest {
  email: string;
}
export interface PasswordResetToken {
  id: string;
}
export interface SetPasswordRequest {
  tokenId: string;
  password: string;
}

@Injectable()
export class ResetPasswordService {
  constructor(private _requestHandler: HttpRequestHandler) {}

  public sendResetPasswordEmail(request: PasswordResetTokenRequest): Observable<Either<HttpError, PasswordResetToken>> {
    return this._requestHandler.post("user/password/reset", request);
  }

  public resetPassword(request: SetPasswordRequest): Observable<Either<HttpError, void>> {
    return this._requestHandler.post("user/password/reset/set", request);
  }

  public confirmCode(token: PasswordResetToken, code: { code: number }): Observable<Either<HttpError, void>> {
    return this._requestHandler.post("user/password/reset/code", { tokenId: token.id, code: code.code });
  }
}
