import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SalonsContainer, EditSalonContainer, DeleteSalonContainer, ImportSalonDataContainer, MigrateSalonContainer } from "./containers";
import { RevokeRestoreAccessContainer } from "./containers/revoke-restore-access.container";

export const routes = [
  { path: "", component: SalonsContainer },
  { path: "new", component: EditSalonContainer, outlet: "panel" },
  { path: "edit/:id", component: EditSalonContainer, outlet: "panel" },
  { path: "delete/:id", component: DeleteSalonContainer, outlet: "panel" },
  { path: "import/:id", component: ImportSalonDataContainer, outlet: "panel" },
  { path: "migrate/:id", component: MigrateSalonContainer, outlet: "panel" },
  { path: "revoke/:id", component: RevokeRestoreAccessContainer, outlet: "panel", data: { mode: "revoke" } },
  { path: "restore/:id", component: RevokeRestoreAccessContainer, outlet: "panel", data: { mode: "restore" } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalonsRoutingModule {}
