import { Pipe, PipeTransform } from "@angular/core";
import { isTodayInTimeZoneFromUTC } from "app/kernel/util/zoned-time-utils";
import { isToday } from "date-fns";
import { isNil } from "ramda";

@Pipe({ name: "isToday", pure: true })
export class IsTodayPipe implements PipeTransform {
  transform(value: Date | number, timeZone: string | undefined): boolean {
    return isNil(timeZone) ? isToday(value) : isTodayInTimeZoneFromUTC(value, timeZone);
  }
}
