import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { tap } from "ramda";
import { LogRocketManagerService } from "../services";
import { init } from "./logrocket.actions";

@Injectable()
export class LogRocketEffects {
  public ngrxOnInitEffects(): Action {
    return init();
  }

  public initLogrocket$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(init),
        tap(() => this._logRocketManagerService.init())
      ),
    { dispatch: false }
  );

  constructor(
    private _actions: Actions,
    private _logRocketManagerService: LogRocketManagerService
  ) {}
}
