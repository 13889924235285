import { getComplianceRatio, LongitudinalReport, Metrics } from "../../kernel/models";
import { isNil } from "ramda";
import { MeasurementUnit } from "@getvish/model";
import { formatWithOptions } from "date-fns/fp";
import { formattedLocale } from "../../kernel/util";

export interface ReportKeyMapper {
  key: string;
  name: string;
  mapFn?: (value: number, measurementUnit: MeasurementUnit) => number;
  valueFn?: (metrics: Metrics) => number;
}

export const AVAILABLE_KEY_MAPPERS: Array<ReportKeyMapper> = [
  {
    key: "wasteAmountGrams",
    name: "Waste",
    mapFn: (value: number, measurementUnit) => measurementUnit.convertFromBase(value),
  },
  { key: "numServicesPerformed", name: "Services Performed" },
  {
    key: "wasteRatio",
    name: "Percent Waste",
    mapFn: (value: number) => value * 100,
  },
  {
    key: "estimatedWaste",
    name: "Estimated Waste",
    mapFn: (value: number, measurementUnit) => measurementUnit.convertFromBase(value),
  },
  {
    key: "completedFormulaRatio",
    name: "Percent Reweighed",
    mapFn: (value: number) => value * 100,
  },
  {
    key: "complianceRatio",
    name: "Percent Compliance",
    valueFn: (metrics: Metrics) => getComplianceRatio(metrics),
    mapFn: (value: number) => value * 100,
  },
  { key: "wholesaleCostDollars", name: "Product Dispensed" },
  { key: "estimatedWasteDollars", name: "Estimated Product Wasted" },
  { key: "productDispensedPerServiceWholesaleCost", name: "Product Dispensed Per Service" },
  { key: "estimatedWastePerServiceDollars", name: "Product Waste Per Service" },
];
export class AnalyticsUtils {
  public static mapLongitudinalReportToNgxChartsDataSeries(
    report: LongitudinalReport,
    keyMaps: ReportKeyMapper[],
    enabledKeyMaps: string[],
    measurementUnit: MeasurementUnit,
    locale: string
  ): object[] {
    const data = report.dataSeries;
    return keyMaps.map((tuple) => {
      const isEnabled = enabledKeyMaps.find((enabledKeyMap) => enabledKeyMap === tuple.name);

      const series = data.map((curr) => {
        const date = new Date(curr.date);
        const seriesName = formatWithOptions({ locale: formattedLocale.get(locale) })("dd MMMM, yyyy")(date);
        const rawValue = tuple.valueFn ? tuple.valueFn(curr.summary) : curr.summary[tuple.key];
        const valueForKey = isNil(rawValue) ? 0 : rawValue;
        const mappedValue = isNil(tuple.mapFn) ? valueForKey : tuple.mapFn(valueForKey, measurementUnit);

        return {
          name: seriesName,
          value: mappedValue,
        };
      });

      return {
        name: tuple.name,
        series: isEnabled ? series : [],
      };
    });
  }
}
