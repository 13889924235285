import { Injectable } from "@angular/core";
import { SalonConfig } from "@getvish/model";
import { PagedResult } from "@getvish/stockpile";
import { fold, fromNullable } from "fp-ts/lib/Option";
import { Observable, of, throwError } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { SalonConfigService } from "app/+salon-config/services";
import { AppointmentService } from "./appointment.service";
import { getTime } from "date-fns";
import { getEndOfDayInTimeZone, getStartOfDayInTimeZone } from "app/kernel/util/zoned-time-utils";
import { AppointmentVM } from "../models/appointment";

@Injectable()
export class FrontDeskService {
  constructor(
    private _appointmentsService: AppointmentService,
    private _salonConfigService: SalonConfigService
  ) {}

  public loadAppointmentsForToday(): Observable<{
    appointments: PagedResult<AppointmentVM>;
  }> {
    return this._fetchSalonTimeZone().pipe(
      mergeMap(({ timeZone }) => {
        const todayStartMillis = getTime(getStartOfDayInTimeZone(timeZone));
        const todayEndMillis = getTime(getEndOfDayInTimeZone(timeZone));

        return this._appointmentsService.findForDateRange(todayStartMillis, todayEndMillis);
      })
    );
  }

  private _fetchSalonTimeZone(): Observable<{ timeZone: string; salonConfig: SalonConfig }> {
    return this._salonConfigService.findOne({}).pipe(
      mergeMap(
        fold<SalonConfig, Observable<{ timeZone: string; salonConfig: SalonConfig }>>(
          () => throwError(() => new Error("No SalonConfig found")),
          (salonConfig) => {
            return fold<string, Observable<{ timeZone: string; salonConfig: SalonConfig }>>(
              () => throwError(() => new Error("No Time Zone configured")),
              (timeZone) => of({ timeZone, salonConfig })
            )(fromNullable(salonConfig.timeZone));
          }
        )
      )
    );
  }
}
