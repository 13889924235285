<div
  class="product-allowance-summary"
  *ngIf="blueprints?.length">
  <div
    class="blueprint-container"
    *ngFor="let blueprint of blueprints">
    <div class="bowl">
      <bowl [mixingContainerVM]="blueprint.mixingContainerVM"></bowl>
    </div>
    <div class="blueprint-details">
      <ng-container *ngIf="blueprint.blueprintBowlVM.pigments?.length && blueprint.blueprintBowlVM.pigments; let ingredients">
        <span class="title">Products:</span>
        <div class="weights">
          <span
            class="weight"
            *ngFor="let ingredient of ingredients"
            [title]="ingredient.product.rootCategory.name + ' - ' + ingredient.product.name">
            <ng-container *ngIf="ingredient.weight._type === TargetWeightType.ABSOLUTE">{{
              ingredient.weight.value | localMeasurementUnit: measurementUnit
            }}</ng-container>
            <ng-container *ngIf="ingredient.weight._type === TargetWeightType.RATIO">{{ ingredient.weight.value }}x</ng-container>
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="blueprint.blueprintBowlVM.developers?.length && blueprint.blueprintBowlVM.developers; let ingredients">
        <span class="title">Developer:</span>
        <div class="weights">
          <span
            class="weight"
            *ngFor="let ingredient of ingredients"
            [title]="ingredient.product.rootCategory.name + ' - ' + ingredient.product.name">
            <ng-container *ngIf="ingredient.weight._type === TargetWeightType.ABSOLUTE">{{
              ingredient.weight.value | localMeasurementUnit: measurementUnit
            }}</ng-container>
            <ng-container *ngIf="ingredient.weight._type === TargetWeightType.RATIO">{{ ingredient.weight.value }}x</ng-container>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
