<product-report-dashboard
  [categoryReport]="productCategoryReport$ | async"
  [salonProducts]="salonProducts$ | async"
  [productReport]="productReport$ | async"
  [measurementUnit]="measurementUnit$ | async"
  [loading]="loading$ | async"
  [manufacturerId]="manufacturerId$ | async"
  [manufacturer]="manufacturer$ | async"
  [rootCategoryMap]="rootCategoryMap$ | async"
  [startDate]="startDate$ | async"
  [endDate]="endDate$ | async"
  [currency]="currency$ | async"
  [timeZone]="timeZone$ | async"
  (moreProducts)="moreProducts($event)"
  (downloadReport)="downloadReport()"
  (updateDateRange)="updateDateRange($event)">
</product-report-dashboard>
