import { Component, Input, Output, ChangeDetectionStrategy, OnInit, EventEmitter } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { LoginRequest } from "../../kernel/models";

@Component({
  selector: "login-form-component",
  templateUrl: "login-form.component.html",
  styleUrls: ["login-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  @Input() public error: string;
  @Input() public loggingIn: boolean;
  @Output() public login: EventEmitter<LoginRequest>;
  @Output() public forgotPassword: EventEmitter<void>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.login = new EventEmitter(true);
    this.forgotPassword = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this._prepareForm();
  }

  public submit(data: LoginRequest): void {
    this.login.emit(data);
  }

  private _prepareForm(): void {
    this.form = this._fb.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }
}
