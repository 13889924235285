import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from "@angular/core";
import { ProductReport } from "../../kernel/models";
import { Manufacturer, MeasurementUnit, ProductCategory, SalonProduct, Employee } from "@getvish/model";
import { addDays } from "date-fns/esm";
import { getDateInTimeZone, getTodayInTimeZone } from "app/kernel/util/zoned-time-utils";

@Component({
  selector: "product-report",
  templateUrl: "product-report.component.html",
  styleUrls: ["product-report.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductReportComponent implements OnInit {
  @Input() public loading: boolean;
  @Input() public productReport: ProductReport;
  @Input() public manufacturer: Manufacturer;
  @Input() public employees: Employee[];
  @Input() public manufacturers: Manufacturer[];
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public products: SalonProduct[];
  @Input() public rootCategoryMap: Map<string, ProductCategory>;
  @Input() public startDate: number;
  @Input() public endDate: number;
  @Input() public currency: string;
  @Input() public timeZone: string;

  @Output() public updateFilters: EventEmitter<{ startDate?: number; endDate?: number; employeeId?: string; manufacturerId?: string }>;

  public minDate = new Date(2000, 0, 1);
  public maxDate = addDays(new Date(), 1);

  constructor() {
    this.updateFilters = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.minDate = getDateInTimeZone(this.timeZone, new Date(2000, 0, 1));
    this.maxDate = getTodayInTimeZone(this.timeZone);
  }

  public updateDateRange(event: { startDate: number; endDate: number }): void {
    const manufacturerId = this.manufacturer._id;
    const { startDate, endDate } = event;

    this.updateFilters.emit({ startDate, endDate, manufacturerId });
  }
}
