<slideout-panel (close)="close()">
  <div panel-header>Edit Service</div>
  <div panel-body>
    <div *ngIf="(loading$ | async) === false; else loadingElement">
      <ng-container *ngIf="(error$ | async) == null; else errorElement">
        <service-description-form
          [service]="serviceDescription$ | async"
          [categories]="serviceCategories$ | async"
          [saving]="saving$ | async"
          [currency]="currency$ | async"
          (save)="save($event)">
        </service-description-form>
      </ng-container>

      <ng-template #errorElement>
        <alert-component type="danger">
          <h4>An Error Has Occured</h4>

          <p>{{ error$ | async }}</p>
        </alert-component>
      </ng-template>
    </div>
    <ng-template #loadingElement>
      <large-loading-indicator></large-loading-indicator>
    </ng-template>
  </div>
</slideout-panel>
