import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProductCategoryReport } from "../../kernel/models";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";

@Injectable()
export class ProductCategoryReportService extends EntityService<ProductCategoryReport> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "productCategoryReports" });
  }

  public fetchForDateRange(
    manufacturerId: string,
    startDate: number,
    endDate: number,
    _timeZone: string
  ): Observable<ProductCategoryReport> {
    const criteria = { manufacturerId, startDate, endDate };

    return this.findOne(criteria).pipe(map((result) => pipe(option.toUndefined(result))));
  }

  public fetchForEmployeeInDateRange(
    employeeId: string,
    startDate: number,
    endDate: number
  ): Observable<option.Option<ProductCategoryReport>> {
    const employeeIds = [employeeId];
    const criteria = { startDate, endDate, employeeIds };

    return this.findOne(criteria);
  }
}
