import { Component } from "@angular/core";
import { Salon, SalonConfig } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppState } from "../../../kernel/store";

import * as SalonConfigActions from "../../store/salon-config.actions";
import * as fromSalonConfig from "../../store/salon-config.selectors";

@Component({
  selector: "salon-details-container",
  templateUrl: "salon-details.container.html",
})
export class SalonDetailsContainer {
  public salon$: Observable<Salon>;
  public salonConfig$: Observable<SalonConfig>;
  public loading$: Observable<boolean>;
  public saving$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.salon$ = _store.pipe(select(fromSalonConfig.getCurrentSalon));
    this.salonConfig$ = _store.pipe(select(fromSalonConfig.getSalonConfig));
    this.loading$ = _store.pipe(select(fromSalonConfig.getLoading));
    this.saving$ = _store.pipe(select(fromSalonConfig.getSaving));
  }

  public save({ salon, salonConfig }): void {
    this._store.dispatch(new SalonConfigActions.SaveSalon({ salon }));
    this._store.dispatch(new SalonConfigActions.SaveSalonConfig({ config: salonConfig }));
  }
}
