import { Action } from "@ngrx/store";
import { Salon } from "@getvish/model";

export enum Types {
  NAVIGATE_EDIT_SALON = "[Edit Salon] Navigate to Edit Salon",
  LOAD = "[Edit Salon] Load",
  LOAD_SUCCESS = "[Edit Salon] Load Success",
  LOAD_FAIL = "[Edit Salon] Load Fail",
}

export class NavigateEditSalon implements Action {
  public readonly type = Types.NAVIGATE_EDIT_SALON;

  constructor(public payload?: { salon: Salon }) {}
}

export class Load implements Action {
  public readonly type = Types.LOAD;

  constructor(public payload: { salonId: string }) {}
}

export class LoadSuccess implements Action {
  public readonly type = Types.LOAD_SUCCESS;

  constructor(public payload: { salon: Salon }) {}
}

export class LoadFail implements Action {
  public readonly type = Types.LOAD_FAIL;

  constructor(public payload: { error: Error }) {}
}

export type Actions = NavigateEditSalon | Load | LoadSuccess | LoadFail;
