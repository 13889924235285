<page
  [progressText]="getProgressText()"
  [showBreadcrumbs]="true"
  [pageTitle]="title">
  <header-controls page-header>
    <button
      class="button outline"
      (click)="addBowl()">
      Add Bowl
    </button>
  </header-controls>
  <div
    page-body
    class="main-body"
    *ngIf="(loading$ | async) === false">
    <card>
      <ng-container card-body>
        <div class="cardText">
          <span
            *ngIf="productAllowance?.blueprints?.length"
            class="description">
            Configure one or more sample bowls below to set the Product Allowance for this service.
          </span>
          <span class="learnMore">
            What is Product Allowance?
            <a
              href="https://docs.getvish.com/docs/2.0/dashboard-service-menu#product-allowances"
              rel="noopener noreferrer"
              target="_blank"
              >Learn More</a
            >
          </span>
        </div>

        <div class="blueprints">
          <pac-blueprint
            *ngFor="let blueprintBowl of productAllowance?.blueprints; index as idx; trackBy: blueprintTrackFn"
            [title]="'Bowl ' + (idx + 1) + (productAllowance.blueprints.length > 1 ? ' of ' + productAllowance.blueprints.length : '')"
            [blueprintBowl]="blueprintBowl"
            [currency]="currency$ | async"
            [measurementUnit]="measurementUnit$ | async"
            [allowDelete]="productAllowance.blueprints.length > 1"
            (blueprintChange)="blueprintChanged(idx, $event)"
            (isAddingProducts)="setIsAddingProducts($event)"
            (delete)="deleteBlueprint(idx)"></pac-blueprint>
          <empty-state-message
            *ngIf="!productAllowance?.blueprints?.length"
            title="Sample Bowls Not Configured"
            icon="calculator">
            To calculate a new product allowance, start by clicking the "Add Bowl" button above.
          </empty-state-message>
        </div>
      </ng-container>
    </card>
  </div>
  <div
    page-footer
    *ngIf="(loading$ | async) === false">
    <div class="footer">
      <fieldset class="pa-total">
        <legend>Product Allowance</legend>
        <span class="pa-total-value">
          {{ calculateProductAllowanceTotal(productAllowance) | async | currency: (currency$ | async) : "symbol-narrow" | default: "-" }}
        </span>
        <button
          *ngIf="productAllowance?.blueprints?.length"
          class="save-btn button primary"
          (click)="save()">
          Save
        </button>
      </fieldset>
    </div>
  </div>
</page>
