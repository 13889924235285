import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import { CardModule, LocationAutocompleteModule, PageModule, ProgressIndicatorModule } from "app/+components";
import { MapModule } from "app/+components/+map";
import { SalonsSharedModule } from "../+salons";
import { KernelServicesModule } from "../kernel/services";
import { SharedModule } from "../kernel/shared";
import { SalonConfigContainer } from "./containers";
import { SalonConfigRoutingModule } from "./salon-config-routing.module";
import { SalonDetailsContainer, SalonFormComponent } from "./salon-details";
import { SalonPaymentConfigContainer, SalonSettingsComponent, SalonSettingsContainer } from "./salon-settings";

@NgModule({
  imports: [
    CardModule,
    LocationAutocompleteModule,
    MapModule,
    PageModule,
    KernelServicesModule,
    SharedModule,
    ProgressIndicatorModule,
    SalonConfigRoutingModule,
    SalonsSharedModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SalonConfigContainer,
    SalonDetailsContainer,
    SalonFormComponent,
    SalonSettingsContainer,
    SalonSettingsComponent,
    SalonPaymentConfigContainer,
  ],
})
export class SalonConfigModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCreditCard);
  }
}
