import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ProductCategory } from "@getvish/model";
import { LeadingTrailingWhitespaceValidator } from "app/kernel";
import { isNil } from "ramda";
import { ProductCategoryNode, flattenProductCategoryNode } from "../common";

// keeping this here because it's so specific we're probaly not going to use it anywhere else
// since it only applies to ProductCategory and making it invalid to set its own @_id === @parentCategoryId
export const parentCategoryValidator = (categoryId: string) => (control: AbstractControl) => {
  const value = control.value;

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined
  } else if (categoryId === value) {
    // make sure that the user can't set the field to the same value as its @_id (provided as @categoryId)
    // see https://professor-frink.getvish.com/vish/vish-webapp/issues/36
    return { invalidParentCategoryId: true };
  }

  return null;
};

@Component({
  selector: "edit-product-category-component",
  templateUrl: "edit-product-category.component.html",
  styleUrls: ["edit-product-category.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProductCategoryComponent implements OnInit {
  @Input() public productCategory: ProductCategory;
  @Input() public productCategories: ProductCategory[];
  @Input() public parentCategoryId?: string;
  @Input() public manufacturerId?: string;

  @Output() public close: EventEmitter<void>;
  @Output() public save: EventEmitter<ProductCategory>;

  public _categories: ProductCategoryNode[];

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
    this.close = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this._createForm();
    this._categories = this._flattenProductCategoryNodes(this.productCategories);
  }

  private _flattenProductCategoryNodes(categories: ProductCategoryNode[]): ProductCategoryNode[] {
    const rootCategoriesOnly = (category: ProductCategoryNode) => isNil(category.parentCategoryId);
    const flattenProductCategoryNodePartial = flattenProductCategoryNode(categories);

    return categories
      .filter(rootCategoriesOnly)
      .map((category) => flattenProductCategoryNodePartial(category))
      .reduce((acc, category) => acc.concat(category), []);
  }

  public saveForm(data: Partial<ProductCategory>, original: ProductCategory): void {
    if (this.form.valid) {
      const updated = { ...original, ...data };

      this.save.emit(updated);
    }
  }

  private _createForm(): void {
    const productCategory = this.productCategory;

    // check if the ProductCategory is new (i.e. has no @_id)
    // if it is then we'll grab the @parentCategoryId and @manufacturerId from input
    // otherwise we'll use the data from the ProductCategory itself
    const categoryId = productCategory._id;

    const { manufacturerId, parentCategoryId } = isNil(categoryId)
      ? { manufacturerId: this.manufacturerId, parentCategoryId: this.parentCategoryId }
      : { manufacturerId: productCategory.manufacturerId, parentCategoryId: productCategory.parentCategoryId };

    this.form = this._fb.group({
      name: [productCategory.name?.trim(), [Validators.required, LeadingTrailingWhitespaceValidator]],
      parentCategoryId: [parentCategoryId, parentCategoryValidator(categoryId)],
      manufacturerId: [manufacturerId],
      order: [productCategory.order],
      flags: [productCategory.flags],
    });
  }
}
