<div style="float: right">
  <button
    class="button"
    (click)="changeAggregation('YEARLY')"
    [ngClass]="aggregatedBy === 'YEARLY' ? 'active' : 'inactive'">
    Yearly
  </button>
  <button
    class="button"
    (click)="changeAggregation('MONTHLY')"
    [ngClass]="aggregatedBy === 'MONTHLY' ? 'active' : 'inactive'">
    Monthly
  </button>
  <button
    class="button"
    (click)="changeAggregation('DAILY')"
    [ngClass]="aggregatedBy === 'DAILY' ? 'active' : 'inactive'">
    Daily
  </button>
</div>
<div style="min-height: 500px">
  <ngx-charts-line-chart
    [scheme]="colorScheme"
    [results]="data"
    [gradient]="false"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    xAxisLabel="Date"
    [yAxisLabel]="'Amount' | appendCurrencySymbol: currency"
    [autoScale]="true"
    [activeEntries]="activeEntries"
    (deactivate)="computeActiveEntries()"
    (select)="toggleLine($event)">
  </ngx-charts-line-chart>
</div>
