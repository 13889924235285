import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SalonConfigContainer } from "./containers";
import { SalonDetailsContainer } from "./salon-details";
import { SalonPaymentConfigContainer, SalonSettingsContainer } from "./salon-settings";

export const routes = [
  {
    path: "",
    component: SalonConfigContainer,
    children: [
      { path: "", component: SalonDetailsContainer },
      { path: "details", component: SalonDetailsContainer },
      { path: "settings", component: SalonSettingsContainer },
      { path: "payment", component: SalonPaymentConfigContainer },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalonConfigRoutingModule {}
