import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Manufacturer } from "@getvish/model";
import { LeadingTrailingWhitespaceValidator } from "app/kernel";

@Component({
  selector: "edit-manufacturer-component",
  templateUrl: "./edit-manufacturer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditManufacturerComponent implements OnInit {
  @Input() public manufacturer: Manufacturer;

  @Output() public close: EventEmitter<void>;
  @Output() public save: EventEmitter<Manufacturer>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
    this.close = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this._createForm();
  }

  public saveForm(data: Partial<Manufacturer>, original: Manufacturer): void {
    if (this.form.valid) {
      const updated = { ...original, ...data };

      this.save.emit(updated);
    }
  }

  private _createForm(): void {
    const manufacturer = this.manufacturer;

    this.form = this._fb.group({
      name: [manufacturer.name?.trim(), [Validators.required, LeadingTrailingWhitespaceValidator]],
    });
  }
}
