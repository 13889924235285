import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmDialogComponent } from "./components/confirm-dialog.component";
import { ConfirmDialogService } from "./services/confirm-dialog.service";
import { DialogModule } from "app/+components/+dialog";

@NgModule({
  imports: [CommonModule, DialogModule],
  declarations: [ConfirmDialogComponent],
  exports: [ConfirmDialogComponent],
  providers: [ConfirmDialogService],
})
export class ConfirmDialogModule {}
