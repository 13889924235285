import { createSelector, createFeatureSelector, createReducer, on } from "@ngrx/store";

import * as app from "../actions/app.actions";

export interface State {
  slug: string;
  pathname: string;
}

const initialState: State = {
  slug: undefined,
  pathname: undefined,
};

export const reducer = createReducer<State, app.Actions>(
  initialState,
  on(app.initializeTenantUrlConfig, (state, { slug, pathname }) => ({
    ...state,
    slug,
    pathname,
  }))
);

export const getCommonState = createFeatureSelector<State>("common");
export const getSlug = createSelector(getCommonState, (state) => state.slug);
export const getPathname = createSelector(getCommonState, (state) => state.pathname);
