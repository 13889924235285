import { Pipe, PipeTransform } from "@angular/core";
import { formattedLocale } from "app/kernel/util/utils";
import { formatDuration, intervalToDuration } from "date-fns";
import { isNil } from "ramda";

@Pipe({ name: "formatDuration", pure: true })
export class FormatDurationPipe implements PipeTransform {
  public transform(value: number, locale: string): string {
    return isNil(value)
      ? value
      : // Here we are creating a duration from the input value, and then passing that along with the locale to format the time
        formatDuration(intervalToDuration({ start: 0, end: value }), { locale: formattedLocale.get(locale) });
  }
}
