import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../kernel";
import { Observable, combineLatest, Subscription } from "rxjs";
import { Salon } from "@getvish/model";
import * as DeleteSalonActions from "../store/delete-salon.actions";
import * as fromDeleteSalon from "../store/delete-salon.selectors";
import { map } from "rxjs/operators";
import { and, isNil, not } from "ramda";

@Component({
  selector: "delete-salon-container",
  templateUrl: "delete-salon.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteSalonContainer implements OnDestroy {
  public salon$: Observable<Salon>;
  public loading$: Observable<boolean>;
  public deleting$: Observable<boolean>;
  public success$: Observable<boolean>;
  public error$: Observable<string>;
  public shouldRenderForm$: Observable<boolean>;

  public salonIdSubscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.loading$ = this._store.pipe(select(fromDeleteSalon.getLoading));
    this.deleting$ = this._store.pipe(select(fromDeleteSalon.getDeleting));
    this.success$ = this._store.pipe(select(fromDeleteSalon.getSuccess));
    this.salon$ = this._store.pipe(select(fromDeleteSalon.getSalon));
    this.error$ = this._store.pipe(select(fromDeleteSalon.getError));
    this.shouldRenderForm$ = combineLatest(this.loading$, this.error$).pipe(map(([loading, error]) => and(not(loading), isNil(error))));

    const salonId$ = _route.paramMap.pipe(map((params) => params.get("id")));

    this.salonIdSubscription = salonId$.pipe(map((salonId) => new DeleteSalonActions.Load({ salonId }))).subscribe((action) => {
      this._store.dispatch(action);
    });
  }

  public close(): void {
    this._store.dispatch(new DeleteSalonActions.Done());
  }

  public cancel(): void {
    this._store.dispatch(new DeleteSalonActions.Cancel());
  }

  public done(): void {
    this._store.dispatch(new DeleteSalonActions.Done());
  }

  public delete(salon: Salon): void {
    this._store.dispatch(new DeleteSalonActions.Delete({ salon }));
  }

  public ngOnDestroy(): void {
    this.salonIdSubscription.unsubscribe();
  }
}
