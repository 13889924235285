<page
  [pageTitle]="pageTitle$ | async"
  [progressText]="(loading$ | async) ? 'Loading...' : undefined"
  [showBreadcrumbs]="true"
  class="full-height-table">
  <card page-body>
    <data-table card-body>
      <columns *ngLet="tableFilter$ | async as tableFilter">
        <column
          id="service"
          title="Service">
          <column-sort
            [direction]="(sort$ | async)?.service"
            (change)="updateSort({ service: $event.direction })">
          </column-sort>
          <column-filter [value]="tableFilter?.service">
            <ng-template let-column="column">
              <text-filter
                [column]="column"
                (filter)="
                  updateTableFilter(tableFilter, {
                    service: $event
                  })
                ">
              </text-filter>
            </ng-template>
          </column-filter>
        </column>
        <column
          id="client"
          title="Client">
          <column-sort
            [direction]="(sort$ | async)?.customerName"
            (change)="updateSort({ customerName: $event.direction })">
          </column-sort>
          <column-filter [value]="tableFilter?.customerName">
            <ng-template let-column="column">
              <text-filter
                [column]="column"
                (filter)="
                  updateTableFilter(tableFilter, {
                    customerName: $event
                  })
                ">
              </text-filter>
            </ng-template>
          </column-filter>
        </column>
        <column
          id="employee"
          title="Employee">
          <column-sort
            [direction]="(sort$ | async)?.employeeName"
            (change)="updateSort({ employeeName: $event.direction })">
          </column-sort>
          <column-filter [value]="tableFilter?.employeeName">
            <ng-template let-column="column">
              <text-filter
                [column]="column"
                (filter)="
                  updateTableFilter(tableFilter, {
                    employeeName: $event
                  })
                ">
              </text-filter>
            </ng-template>
          </column-filter>
        </column>
        <column
          id="date"
          title="Date">
          <column-sort
            [direction]="(sort$ | async)?.date"
            (change)="updateSort({ date: $event.direction })">
          </column-sort>
        </column>
      </columns>
      <body>
        <row
          *ngFor="let performedService of performedServices$ | async"
          [id]="performedService._id"
          (action)="navigateToAppointment(performedService.appointmentId)">
          <cell columnId="service">
            <ng-template>
              <text-cell>
                <text-cell-text
                  [value]="performedService.service?.name"
                  className="primary">
                </text-cell-text>
              </text-cell>
            </ng-template>
          </cell>
          <cell columnId="client">
            <ng-template>
              <text-cell>
                <text-cell-text
                  [value]="performedService.customer?.firstName + ' ' + performedService.customer?.lastName"
                  className="primary">
                </text-cell-text>
              </text-cell>
            </ng-template>
          </cell>
          <cell columnId="employee">
            <ng-template>
              <text-cell>
                <text-cell-text
                  [value]="performedService.employee?.firstName + ' ' + performedService.employee?.lastName"
                  className="primary">
                </text-cell-text>
              </text-cell>
            </ng-template>
          </cell>
          <cell columnId="date">
            <ng-template>
              <text-cell>
                <text-cell-text
                  *ngIf="timeZone$ | async as timeZone"
                  [value]="performedService.createdAt | zonedDate: timeZone : 'MMM d, y, h:mm a'">
                </text-cell-text>
              </text-cell>
            </ng-template>
          </cell>
        </row>
      </body>
      <empty-state-message
        title="No Orders found"
        icon="bags-shopping">
        We could not find any Appointments for the current criteria. Adjust your search to find what you're looking for.
      </empty-state-message>
    </data-table>
  </card>
</page>
