import { NgModule } from "@angular/core";
import { SharedModule } from "../../kernel/shared";
import { KernelServicesModule } from "../../kernel";
import { ProductCategoryService } from "./services";
import { ProductCategoriesComponent, RecursiveCategoriesListComponent, ProductCategoriesProductListComponent } from "./components";

@NgModule({
  imports: [KernelServicesModule, SharedModule],
  declarations: [ProductCategoriesComponent, RecursiveCategoriesListComponent, ProductCategoriesProductListComponent],
  exports: [ProductCategoriesComponent, RecursiveCategoriesListComponent, ProductCategoriesProductListComponent],
  providers: [ProductCategoryService],
})
export class ProductCategoriesSharedModule {}
