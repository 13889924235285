import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "confirm-code-form",
  templateUrl: "./confirm-code-form.component.html",
  styleUrls: ["./common.less"],
})
export class ConfirmCodeFormComponent implements OnInit {
  @Input() public error: string;
  @Output() public save: EventEmitter<{ code: number }>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      code: [undefined, [Validators.required]],
    });
  }
}
