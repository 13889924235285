<mat-card
  class="card"
  [ngClass]="disabled ? 'disabled' : 'enabled'"
  (click)="onCardClick()">
  <div class="card-title">
    <span class="card-title-text">{{ manufacturer.name }}</span>
    <ng-content select="[header-actions]"></ng-content>
  </div>
  <div class="card-logo">
    <img
      class="card-logo-image"
      *ngIf="logoFound; else noImageFound"
      src="{{ logoPath }}"
      loading="lazy"
      (error)="onImageError()"
      [alt]="manufacturer.name" />
  </div>
  <div class="card-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</mat-card>

<ng-template #noImageFound>
  <div class="no-logo-container">
    <div class="card-title-text">
      {{ manufacturer.name }}
    </div>
  </div>
</ng-template>
