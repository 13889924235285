import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { map, catchError, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { Observable, forkJoin, of } from "rxjs";
import { AppState } from "app/kernel";
import { SalonProductService } from "../../+salon-products/services";

import {
  load,
  selectManufacturer,
  loadSuccess,
  loadProductsForManufacturerSuccess,
  loadProductsForManufacturerFail,
} from "./product-selection.actions";

import { getProductSelectionMode, getProductSelectionType } from "./product-selection.selectors";

import { uniq } from "ramda";
import { ManufacturerService } from "app/+product/+manufacturers/services";
import { SalonManufacturerService } from "app/+product/+salon-products/services";
import { ProductCategoryService } from "app/+product/+product-categories/services";
import { Product, ProductCategory, ProductCategoryModule } from "@getvish/model";
import { ProductService } from "app/+product/+products/services";

@Injectable()
export class ProductSelectionEffects {
  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType(load),
      switchMap(({ psMode, psType }) => {
        if (psType === "PRODUCTS") {
          return this._manufacturerService.find(undefined, { name: 1 }).pipe(
            map((manufacturersResult) =>
              loadSuccess({
                manufacturers: manufacturersResult.records,
              })
            )
          );
        }

        return forkJoin({
          salonProducts: this._salonProductService.findAll(),
          salonManufacturers: this._salonManufacturerService.findAll(),
        }).pipe(
          mergeMap(({ salonProducts, salonManufacturers }) => {
            let manufacturerIds$: Observable<string[]>;

            if (psMode === "ALL") {
              manufacturerIds$ = of(uniq(salonManufacturers.map((sm) => sm.manufacturerId)));
            } else {
              const criteria = {
                flags: psMode === "DEVELOPER" ? "DEVELOPER" : { $ne: "DEVELOPER" },
              };

              manufacturerIds$ = this._productCategoryService
                .findForManufacturers(
                  salonManufacturers.map((sm) => sm.manufacturerId),
                  criteria
                )
                .pipe(
                  map((results) => results.map((r) => r._id)),
                  map((categoryIds) =>
                    uniq(
                      salonProducts
                        .filter((p) => !p.flags?.includes("INACTIVE") && categoryIds?.includes(p.categoryId))
                        .map((p) => p.manufacturerId)
                    )
                  )
                );
            }

            return manufacturerIds$.pipe(
              switchMap((manufacturerIds) => this._manufacturerService.find({ _id: { $in: manufacturerIds } }, { name: 1 })),
              map((manufacturersResult) =>
                loadSuccess({
                  manufacturers: manufacturersResult.records,
                  salonProducts,
                })
              )
            );
          })
        );
      })
    )
  );

  public loadProductsForManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(selectManufacturer),
      withLatestFrom(this._store.pipe(select(getProductSelectionMode)), this._store.pipe(select(getProductSelectionType))),
      switchMap(([{ manufacturer }, mode, type]) => {
        const actions = [
          this._productCategoryService.findForManufacturer(manufacturer._id).pipe(
            map((categoriesResult) => {
              if (mode === "DEVELOPER") {
                return categoriesResult.records.filter(ProductCategoryModule.hasDevelopers);
              } else if (mode === "NONDEVELOPER") {
                return categoriesResult.records.filter((c) => !ProductCategoryModule.hasDevelopers(c));
              }

              return categoriesResult.records;
            })
          ),
        ] as Array<Observable<ProductCategory[] | Product[]>>;

        if (type === "PRODUCTS") {
          actions.push(this._productService.findByManufacturerIds([manufacturer._id]));
        }

        return forkJoin(actions);
      }),
      map((results) =>
        loadProductsForManufacturerSuccess({
          categories: results[0] as ProductCategory[],
          products: results?.[1] as Product[],
        })
      ),
      catchError((error) => of(loadProductsForManufacturerFail({ error })))
    )
  );

  constructor(
    private _productService: ProductService,
    private _salonProductService: SalonProductService,
    private _productCategoryService: ProductCategoryService,
    private _manufacturerService: ManufacturerService,
    private _salonManufacturerService: SalonManufacturerService,
    private _actions$: Actions,
    private _store: Store<AppState>
  ) {}
}
