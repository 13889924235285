import { HttpRepositoryFactory, EntityService } from "@getvish/stockpile";
import { Timezone } from "../../kernel/models/timezone";
import { Injectable } from "@angular/core";

@Injectable()
export class TimezoneService extends EntityService<Timezone> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "timezones" });
  }
}
