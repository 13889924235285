import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { getLogoPathForManufacturer } from "../../models/manufacturer-logos";
import { Manufacturer } from "@getvish/model";

@Component({
  selector: "manufacturer-card",
  templateUrl: "./manufacturer-card.component.html",
  styleUrls: ["./manufacturer-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerCardComponent implements OnChanges {
  @Input()
  public manufacturer: Manufacturer;

  @Input()
  disabled = false;

  @Output()
  public clicked: EventEmitter<void> = new EventEmitter<void>(true);

  public logoFound = true;
  public logoPath: string;

  public ngOnChanges(): void {
    this.logoPath = getLogoPathForManufacturer(this.manufacturer._id);
  }

  public onCardClick(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }

  public onImageError(): void {
    this.logoFound = false;
  }
}
