import { NgModule } from "@angular/core";
import { SharedModule } from "../kernel/shared";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faAt, faLock } from "@fortawesome/pro-solid-svg-icons";
import { LoginFormComponent, LoginAuthTokenComponent } from "./components";

export const COMPONENTS = [LoginFormComponent, LoginAuthTokenComponent];

@NgModule({
  imports: [SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class AuthSharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faAt, faLock);
  }
}
