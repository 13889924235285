import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Address, SalonConfig } from "@getvish/model";
import { Location } from "app/+components/+location-autocomplete/models/location";
import { Observable, map, startWith } from "rxjs";
import * as countries from "i18n-iso-countries";
import * as localeDataEn from "i18n-iso-countries/langs/en.json";

import * as L from "leaflet";
import { Geometry, Point } from "geojson";
import { Salon } from "app/+salons/models";

countries.registerLocale(localeDataEn);

const VISH_ICON = L.icon({
  iconUrl: "assets/images/vish_pin.png",
  iconSize: [30, 41],
  iconAnchor: [15, 41],
  popupAnchor: [0, -38],
});
@Component({
  selector: "salon-form-component",
  templateUrl: "salon-form.component.html",
  styleUrls: ["salon-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalonFormComponent implements OnInit {
  @Input() public salon: Salon;
  @Input() public salonConfig: SalonConfig;
  @Output() public save: EventEmitter<{ salon: Salon; salonConfig: SalonConfig }>;
  public form: UntypedFormGroup;

  public filteredCountries$: Observable<{ value: string; label: string }[]>;
  private timeZone: string;
  private geometry: Geometry;
  public markers: Array<L.Marker>;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
  }

  public ngOnInit(): void {
    const salon = this.salon;

    const address: Address = { ...this.salon.address };

    this.form = this._fb.group({
      name: [salon.name, Validators.required],
      address: this._fb.group({
        addressLine1: [address.addressLine1, Validators.required],
        addressLine2: [address.addressLine2],
        city: [address.city, Validators.required],
        province: [address.province, Validators.required],
        country: [address.country, Validators.required],
        postalCode: [address.postalCode, Validators.required],
      }),
    });

    this.filteredCountries$ = this.getCountryControl().valueChanges.pipe(
      startWith(""),
      map((filterValue) => {
        return Object.keys(countries.getAlpha3Codes())
          .map((alpha3) => ({
            value: alpha3,
            label: countries.getName(alpha3, "en"),
          }))
          .filter(
            (c) => c.value.toLowerCase().includes(filterValue.toLowerCase()) || c.label.toLowerCase().includes(filterValue.toLowerCase())
          );
      })
    );

    this.timeZone = this.salonConfig.timeZone;
    this.geometry = this.salon.geometry;
    this._updateMarkers(this.salon.geometry as Point);
  }

  public getCountryControl() {
    return (this.form.controls["address"] as FormGroup).controls["country"];
  }

  public displayCountry(alpha3) {
    return countries.getName(alpha3, "en") ?? alpha3;
  }

  public saveForm(salon: Salon, data: Partial<Salon>): void {
    if (this.form.valid) {
      const updatedSalon: Salon = { ...salon, ...data, geometry: this.geometry };
      const updatedSalonConfig: SalonConfig = { ...this.salonConfig, timeZone: this.timeZone };

      this.save.emit({ salon: updatedSalon, salonConfig: updatedSalonConfig });
    }
  }

  public locationSelected(location: Location): void {
    this.form.patchValue({ address: location.address });
    this.timeZone = location.timeZone?.name;
    this.geometry = location.geometry;

    this._updateMarkers(location.geometry as Point);
  }

  private _updateMarkers(point: Point): void {
    if (point != null) {
      const marker = L.marker([point.coordinates[1], point.coordinates[0]], {
        draggable: true,
        autoPan: true,
        icon: VISH_ICON,
      });
      marker.on("dragend", (event) => {
        const marker = event.target;
        const position = marker.getLatLng();
        this.geometry = {
          type: "Point",
          coordinates: [position.lng, position.lat],
        };
      });
      this.markers = [marker];
    }
  }
}
