<mat-form-field>
  <mat-label>{{ "Search " + column.title + "..." }}</mat-label>
  <mat-select
    [value]="value"
    (selectionChange)="updateFilter($event.value)"
    multiple>
    <mat-option
      *ngFor="let option of options"
      [value]="option.value"
      >{{ option.title ?? option.value }}</mat-option
    >
  </mat-select>
</mat-form-field>
