import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { map, mapTo, catchError, switchMap, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { ProductCategoryService } from "../services";
import { either } from "fp-ts";
import { ProductCategory } from "@getvish/model";
import { Action } from "@ngrx/store";

import * as ProductCategoryActions from "./product-category.actions";
import * as ProductActions from "../../+products/store/product.actions";
import * as RouterActions from "../../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../../kernel/store/actions/snackbar.actions";
import { MatDialog } from "@angular/material/dialog";
import { ProductCategoryOrderDialogComponent } from "app/+product/+salon-products/components/product-category-order-dialog/product-category-order-dialog.component";
import { fold, fromNullable } from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";

@Injectable()
export class ProductCategoryEffects {
  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.LoadAll>(ProductCategoryActions.Types.LOAD_ALL),
      map((action) => action.payload),
      switchMap((payload) =>
        this._productCategoryService.find(payload.criteria, payload.sort, payload.page, payload.limit).pipe(
          mergeMap((result) => [new ProductCategoryActions.LoadAllSuccess(result.records, result.paging)]),
          catchError((reason) => {
            console.log("error is: ", reason);
            return of(new ProductCategoryActions.LoadAllFail({ errors: reason }));
          })
        )
      )
    )
  );

  public new$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductCategoryActions.Types.NEW),
      mapTo(
        RouterActions.go({
          path: ["/product/products", { outlets: { panel: "categories/new" } }],
        })
      )
    )
  );

  public add$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.Add>(ProductCategoryActions.Types.ADD),
      map((action) => action.payload.productCategory),
      switchMap((productCategory) =>
        this._productCategoryService.insert(productCategory).pipe(
          mergeMap(
            either.fold<Error, ProductCategory, Action[]>(
              (error) => [new ProductCategoryActions.AddFail({ error })],
              (createdProductCategory) => [new ProductCategoryActions.AddSuccess(createdProductCategory)]
            )
          )
        )
      )
    )
  );

  public addOrUpdateSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductCategoryActions.Types.ADD_SUCCESS, ProductCategoryActions.Types.UPDATE_SUCCESS),
      mapTo(RouterActions.back())
    )
  );

  public edit$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.Edit>(ProductCategoryActions.Types.EDIT),
      map((action) => action.payload.productCategory._id),
      map((id) =>
        RouterActions.go({
          path: ["/product/products", { outlets: { panel: `categories/edit/${id}` } }],
        })
      )
    )
  );

  public update$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.Update>(ProductCategoryActions.Types.UPDATE),
      map((action) => action.payload.productCategory),
      switchMap((productCategory) =>
        this._productCategoryService.update(productCategory).pipe(
          mergeMap(
            either.fold<Error, ProductCategory, Action[]>(
              (error) => [new ProductCategoryActions.UpdateFail({ error })],
              (_productCategory) => [new ProductCategoryActions.UpdateSuccess(_productCategory ?? productCategory)]
            )
          )
        )
      )
    )
  );

  public loadProductsAndCategoriesForManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.LoadProductsAndCategoriesForManufacturer>(
        ProductCategoryActions.Types.LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER
      ),
      map((action) => action.payload.manufacturerId),
      mergeMap((manufacturerId) => [
        new ProductCategoryActions.LoadAll({ criteria: { manufacturerId } }),
        new ProductActions.LoadAll({ criteria: { manufacturerId }, sort: { order: 1 } }),
      ])
    )
  );

  public selectManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.SelectManufacturer>(ProductCategoryActions.Types.SELECT_MANUFACTURER),
      map((action) => action.payload.manufacturer._id),
      map((manufacturerId) =>
        RouterActions.go({
          path: [`/product/products/manufacturer/${manufacturerId}`],
        })
      )
    )
  );

  public showCategoryOrderDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.ChangeCategoryOrder>(ProductCategoryActions.Types.CHANGE_CATEGORY_ORDER),
      map((action) => action.payload.categories),
      map((categories) =>
        this._matDialog.open<ProductCategoryOrderDialogComponent, { productCategories: ProductCategory[] }, ProductCategory[]>(
          ProductCategoryOrderDialogComponent,
          {
            disableClose: true,
            maxHeight: "95vh",
            panelClass: "dlg-no-padding-pane",
            data: {
              productCategories: categories,
            },
          }
        )
      ),
      switchMap((dialogRef) => dialogRef.afterClosed()),
      map(fromNullable),
      map(
        fold(
          () => ({ type: "EMPTY_ACTION" }),
          (categories) => new ProductCategoryActions.UpdateProductCategories({ categories })
        )
      )
    )
  );

  public updateProductCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.UpdateProductCategories>(ProductCategoryActions.Types.UPDATE_PRODUCT_CATEGORIES),
      map((action) => action.payload),
      switchMap(({ categories }) => this._productCategoryService.updateMany(categories)),
      map(
        E.fold<Error, ProductCategory[], Action>(
          (e) => new ProductCategoryActions.UpdateProductCategoriesFail({ error: e }),
          (categories) => new ProductCategoryActions.UpdateProductCategoriesSuccess({ categories })
        )
      )
    )
  );

  public updateProductCategoriesFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ProductCategoryActions.UpdateProductCategoriesFail>(ProductCategoryActions.Types.UPDATE_PRODUCT_CATEGORIES_FAIL),
      map(() => new SnackbarActions.Info({ message: "Updating product categories failed." }))
    )
  );

  constructor(
    private _productCategoryService: ProductCategoryService,
    private _matDialog: MatDialog,
    private _actions$: Actions
  ) {}
}
