import { Action } from "@ngrx/store";
import { AuthToken, LoginRequest } from "../../kernel/models";
import { isAdmin } from "../../+auth/services/auth.service";
import { Salon, User } from "@getvish/model";

export enum Types {
  INIT = "[Auth] Init",
  LOAD_SETTINGS = "[Auth] Load Settings",
  LOAD_SETTINGS_SUCCESS = "[Auth] Load Settings Success",
  CHECK_FOR_AUTH_TOKEN = "[Auth] Check For Auth Token",
  NAVIGATE_TO_LOGIN = "[Auth] Navigate to Login",
  NAVIGATE_TO_AUTH_TOKEN_COMPONENT = "[Auth] Navigate to Auth Token Component",
  LOGIN_FROM_AUTH_TOKEN = "[Auth] Login From Auth Token",
  LOGIN_FROM_AUTH_TOKEN_SUCCESS = "[Auth] Login From Auth Token Success",
  AUTH_TOKEN_NOT_FOUND = "[Auth] Auth Token Not Found",
  LOGIN_FROM_AUTH_TOKEN_FAIL = "[Auth] Login From Auth Token Fail",
  LOGIN = "Login",
  LOGIN_SUCCESS = "LoginSuccess",
  LOGIN_FAIL = "LoginFail",
  LOGOUT = "[Auth] Logout",
  SET_AUTH_TOKEN_SUCCESS = "[Auth] Set Auth Token Success",
  FORGOT_PASSWORD = "[Auth] Forgot Password",
}

export class Init implements Action {
  public readonly type = Types.INIT;
}

export class LoadSettings implements Action {
  public readonly type = Types.LOAD_SETTINGS;
}

export class LoadSettingsSuccess implements Action {
  public readonly type = Types.LOAD_SETTINGS_SUCCESS;

  constructor(public payload: { authToken: string }) {}
}

export class CheckForAuthToken implements Action {
  public readonly type = Types.CHECK_FOR_AUTH_TOKEN;
}

export class LoginFromAuthToken implements Action {
  public readonly type = Types.LOGIN_FROM_AUTH_TOKEN;

  constructor(public payload: { authToken: string }) {}
}

export class LoginFromAuthTokenSuccess implements Action {
  public readonly type = Types.LOGIN_FROM_AUTH_TOKEN_SUCCESS;

  constructor(public payload: { authToken: AuthToken; user: User; salon: Salon }) {}
}

export class AuthTokenNotFound implements Action {
  public readonly type = Types.AUTH_TOKEN_NOT_FOUND;
}

export class LoginFromAuthTokenFail implements Action {
  public readonly type = Types.LOGIN_FROM_AUTH_TOKEN_FAIL;

  constructor(public payload: { reason: string }) {}
}

export class Login implements Action {
  public readonly type = Types.LOGIN;

  constructor(public payload: LoginRequest) {}
}

export class LoginSuccess implements Action {
  public readonly type = Types.LOGIN_SUCCESS;

  constructor(public payload: { authToken: AuthToken; user: User; salon: Salon }) {}
}

export class LoginFail implements Action {
  public readonly type = Types.LOGIN_FAIL;

  constructor(public payload: { reason: string }) {}
}

export class Logout implements Action {
  public readonly type = Types.LOGOUT;
}

export class NavigateToLogin implements Action {
  public readonly type = Types.NAVIGATE_TO_LOGIN;
}

export class NavigateToAuthTokenComponent implements Action {
  public readonly type = Types.NAVIGATE_TO_AUTH_TOKEN_COMPONENT;
}

export class SetAuthTokenSuccess implements Action {
  public readonly type = Types.SET_AUTH_TOKEN_SUCCESS;
  public payload: { isAdmin: boolean; user: User };

  constructor(payload: { user: User }) {
    this.payload = { isAdmin: isAdmin(payload.user), user: payload.user };
  }
}

export class ForgotPassword implements Action {
  public readonly type = Types.FORGOT_PASSWORD;
}

export type Actions =
  | Init
  | LoadSettings
  | LoadSettingsSuccess
  | CheckForAuthToken
  | NavigateToLogin
  | NavigateToAuthTokenComponent
  | LoginFromAuthToken
  | LoginFromAuthTokenSuccess
  | AuthTokenNotFound
  | LoginFromAuthTokenFail
  | Login
  | LoginSuccess
  | LoginFail
  | Logout
  | SetAuthTokenSuccess
  | ForgotPassword;
