import { ModuleWithProviders, NgModule } from "@angular/core";
import { CustomerService, CustomerParserService } from "./services";
import { KernelServicesModule } from "../kernel/services/kernel-services.module";

@NgModule({
  imports: [KernelServicesModule.forRoot()],
})
export class CustomerServicesModule {
  public static forRoot(): ModuleWithProviders<CustomerServicesModule> {
    return {
      ngModule: CustomerServicesModule,
      providers: [CustomerService, CustomerParserService],
    };
  }
}
