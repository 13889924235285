import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnInit, OnDestroy } from "@angular/core";
import { Salon, User } from "@getvish/model";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { UntypedFormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { isNil } from "ramda";
import { AppConfig } from "app/kernel/models/app-config";

@Component({
  selector: "navbar-component",
  styleUrls: ["navbar.component.less"],
  templateUrl: "navbar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() public user: User;
  @Input() public connectionStatus: string;
  @Input() public salons: Salon[];
  @Input() public currentSalon: Salon;
  @Input() public slug: string;
  @Input() public salonFilter: string;
  @Input() public isRoot: boolean;
  @Output() public menuToggle: EventEmitter<void>;
  @Output() public changeSalon: EventEmitter<Salon>;
  @Output() public navigateHome: EventEmitter<void>;
  @Output() public searchSalon: EventEmitter<string>;
  @Output() public clearSearchSalon: EventEmitter<void>;
  @Output() public logout: EventEmitter<void>;

  public formControl: UntypedFormControl;

  private _formControlSubscription: Subscription;

  constructor(public appConfig: AppConfig) {
    this.menuToggle = new EventEmitter(true);
    this.changeSalon = new EventEmitter(true);
    this.navigateHome = new EventEmitter(true);
    this.searchSalon = new EventEmitter(true);
    this.clearSearchSalon = new EventEmitter(true);
    this.logout = new EventEmitter(true);
  }

  public ngOnInit(): void {
    this.formControl = new UntypedFormControl();

    this._formControlSubscription = this.formControl.valueChanges.subscribe((value) => {
      if (isNil(value)) {
        this.clearSearchSalon.emit();
      } else {
        this.searchSalon.emit(value);
      }
    });
  }

  public changeSalonOnSelection(event: MatAutocompleteSelectedEvent): void {
    const salon: Salon = event.option.value;

    this.changeSalon.emit(salon);
  }

  public displaySalons =
    (salons: Salon[]) =>
    (salonId?: string): string | null => {
      const salon = salons.find((salon) => salon._id === salonId);

      return pipe(
        option.fromNullable(salon),
        option.map((salon) => `${salon.name}`),
        option.toNullable
      );
    };

  public toggleMenu(): void {
    this.menuToggle.emit();
  }

  public ngOnDestroy(): void {
    this._formControlSubscription.unsubscribe();
  }
}
