import { createReducer, on } from "@ngrx/store";

import * as actions from "./discontinue-product.actions";
import { DiscontinuedProduct, Product } from "@getvish/model";
import { ProductReplacement } from "app/+product/+product-selection/components";

export interface DiscontinueProductState {
  loading: boolean;
  saving: boolean;
  error: Error;
  product: Product;
  discontinuedProduct: DiscontinuedProduct;
  replacements: ProductReplacement[];
}

export const initialState: DiscontinueProductState = {
  loading: false,
  saving: false,
  error: undefined,
  product: undefined,
  discontinuedProduct: undefined,
  replacements: [],
};

export const reducer = createReducer(
  initialState,
  on(actions.init, () => ({
    ...initialState,
    loading: true,
  })),
  on(actions.initSuccess, (state, { product, discontinuedProduct, replacements }) => ({
    ...state,
    product,
    discontinuedProduct,
    replacements,
    loading: false,
  })),
  on(actions.initFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(actions.discontinue, (state) => ({
    ...state,
    saving: true,
  })),
  on(actions.discontinueSuccess, (state) => ({
    ...state,
    saving: false,
  })),
  on(actions.discontinueFail, (state, { error }) => ({
    ...state,
    saving: false,
    error,
  }))
);

export const featureKey = "discountinueProduct";
