<div
  id="wrapper"
  [slideoutRevealPanel]="panelOpen$ | async"
  [progressIndicator]="(loading$ | async) ? 'Application Loading...' : undefined">
  <navbar-component
    *ngIf="(loading$ | async) === false && user$ | async | isDefined"
    (changeSalon)="changeSalon($event)"
    (menuToggle)="toggleMenu()"
    (navigateHome)="navigateHome()"
    (logout)="logout()"
    (searchSalon)="searchSalon($event)"
    (clearSearchSalon)="clearSearchSalon()"
    [slug]="slug$ | async"
    [user]="user$ | async"
    [isRoot]="isRoot$ | async"
    [salons]="salons$ | async"
    [currentSalon]="currentSalon$ | async"
    [salonFilter]="salonFilter$ | async">
  </navbar-component>
  <div
    id="content-wrapper"
    [ngClass]="{ 'with-navbar': user$ | async | isDefined }">
    <sidebar-component
      *ngIf="(loading$ | async) === false && user$ | async | isDefined"
      [isExpanded]="menuExpanded$ | async"
      [navLinks]="navLinks$ | async"
      [disabled]="(forbidden$ | async) === true">
    </sidebar-component>
    <ng-container *ngIf="(forbidden$ | async) === true; else content">
      <div class="forbidden">
        <div class="inner">
          <fa-icon icon="exclamation-circle"></fa-icon>
          <h1>Account Temporarily Suspended</h1>
          <p>You do not currently have access to this dashboard.</p>
          <p>
            If you believe this is an error please contact customer support at
            <a href="mailto: support@getvish.com">support&#64;getvish.com</a> or <a href="tel:1-888-419-6391">1-888-419-6391</a>.
          </p>
        </div>
      </div>
    </ng-container>
    <ng-template #content>
      <div
        *ngIf="(loading$ | async) === false"
        id="content">
        <router-outlet></router-outlet>
      </div>
    </ng-template>
  </div>
</div>
<slideout-panel-container>
  <router-outlet name="panel"></router-outlet>
</slideout-panel-container>
