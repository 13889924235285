<salon-form-component
  *ngIf="(salon$ | async | isDefined) && (salonConfig$ | async | isDefined) && (loading$ | async) === false; else loadingElement"
  [salon]="salon$ | async"
  [salonConfig]="salonConfig$ | async"
  (save)="save($event)">
</salon-form-component>

<ng-template #loadingElement>
  <large-loading-indicator></large-loading-indicator>
</ng-template>
