import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { KernelServicesModule } from "app/kernel";
import { AppointmentsContainer, ScheduleAppointmentContainer } from "./containers";
import { ScheduleAppointmentComponent } from "./components";
import { reducer as scheduleAppointmentReducer } from "./store/schedule-appointment.reducer";
import { ScheduleAppointmentEffects } from "./store/schedule-appointment.effects";
import { EmployeeServicesModule } from "app/+employees/employee-services.module";
import { SharedModule } from "../kernel/shared";
import { AppointmentsRoutingModule } from "./front-desk-routing.module";
import { AppointmentService, OrderService, PerformedServicesService } from "./services";
import { appointmentReducer } from "./store/appointment.reducer";
import { AppointmentEffects } from "./store/appointment.effects";
import { CustomerServicesModule } from "app/+customers/customer-services.module";
import { orderReducer } from "./store/order.reducer";
import { OrderEffects } from "./store/order.effects";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
  faCheckCircle,
  faEllipsisV,
  faCalendarDay,
  faMortarPestle,
  faSortDown,
  faCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { AppointmentRowComponent } from "./components";
import { IsTodayPipe } from "./pipes/is-today.pipe";
import { CurrencyService, SalonConfigService } from "app/+salon-config/services";
import { FrontDeskSharedModule } from "./front-desk-shared.module";
import { FrontDeskService, ScheduleAppointmentService } from "./services";
import { CardModule, PageModule } from "app/+components";
import { FormulaService } from "./services/formula.service";
import { FrontDeskNotificationsService } from "./services/front-desk-notifications.service";
import { NotificationsModule } from "app/+notifications/notifications.module";
import { ServiceMenuModule } from "app/+service-menu";

@NgModule({
  imports: [
    CardModule,
    PageModule,
    SharedModule,
    AppointmentsRoutingModule,
    FrontDeskSharedModule,
    CustomerServicesModule.forRoot(),
    EmployeeServicesModule.forRoot(),
    KernelServicesModule.forRoot(),
    ServiceMenuModule.forRoot(),
    StoreModule.forFeature("appointments", appointmentReducer),
    StoreModule.forFeature("scheduleAppointment", scheduleAppointmentReducer),
    StoreModule.forFeature("orders", orderReducer),
    EffectsModule.forFeature([AppointmentEffects, ScheduleAppointmentEffects, OrderEffects]),
  ],
  declarations: [AppointmentsContainer, ScheduleAppointmentContainer, ScheduleAppointmentComponent, AppointmentRowComponent, IsTodayPipe],
  providers: [
    AppointmentService,
    OrderService,
    CurrencyService,
    SalonConfigService,
    FrontDeskService,
    ScheduleAppointmentService,
    FormulaService,
    PerformedServicesService,
  ],
})
export class FrontDeskModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCheckCircle, faCircleExclamation, faEllipsisV, faCalendarDay, faSortDown, faMortarPestle);
  }

  public static forRoot() {
    return {
      ngModule: FrontDeskModule,
      imports: [NotificationsModule.forRoot()],
      providers: [FrontDeskNotificationsService],
      exports: [FrontDeskNotificationsService],
    };
  }
}
