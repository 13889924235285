import { Salon, SalonConfig } from "@getvish/model";
import { Timezone } from "../../kernel/models/timezone";
import * as salonConfig from "./salon-config.actions";

export interface SalonConfigState {
  loading: boolean;
  saving: boolean;
  timeZones: Timezone[];
  currencies: { value: string; label: string }[];
  currentSalon: Salon;
  currentSalonConfig: SalonConfig;
}

const initialState: SalonConfigState = {
  loading: false,
  saving: false,
  timeZones: undefined,
  currencies: [],
  currentSalon: undefined,
  currentSalonConfig: undefined,
};

export function salonConfigReducer(state: SalonConfigState = initialState, action: salonConfig.Actions): SalonConfigState {
  switch (action.type) {
    case salonConfig.Types.LOAD_CURRENT_SALON: {
      const loading = true;

      return {
        ...state,
        loading,
      };
    }

    case salonConfig.Types.LOAD_CURRENT_SALON_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentSalon: action.payload,
      };
    }

    case salonConfig.Types.LOAD_SALON_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentSalonConfig: action.payload,
      };
    }

    case salonConfig.Types.LOAD_TIMEZONES_SUCCESS: {
      const timeZones: Timezone[] = action.payload.records;

      return {
        ...state,
        timeZones,
      };
    }

    case salonConfig.Types.LOAD_CURRENCIES_SUCCESS: {
      const currencies = action.payload.currencies;

      return {
        ...state,
        currencies,
      };
    }

    case salonConfig.Types.SAVE_SALON: {
      return {
        ...state,
        saving: true,
      };
    }

    case salonConfig.Types.SAVE_SALON_SUCCESS: {
      return {
        ...state,
        saving: false,
        currentSalon: action.payload,
      };
    }

    case salonConfig.Types.SAVE_SALON_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonConfig.Types.SAVE_SALON_CONFIG_SUCCESS: {
      return {
        ...state,
        saving: false,
        currentSalonConfig: { ...action.payload, salonId: action.payload.salonId ?? state.currentSalonConfig.salonId },
      };
    }

    default: {
      return state;
    }
  }
}
