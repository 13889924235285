import { DiscontinuedProduct, Manufacturer, Product, ProductCategory } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { Action } from "@ngrx/store";
import { isNil } from "ramda";
import { ProductSelection } from "./product.reducer";

export enum Types {
  LOAD_ALL = "[Product] Load All",
  LOAD_ALL_SUCCESS = "[Product] Load All Success",
  LOAD_ALL_FAIL = "[Product] Load All Fail",
  NEW = "[Product] New",
  ADD = "[Product] Add",
  ADD_SUCCESS = "[Product] Add Success",
  ADD_FAIL = "[Product] Add Fail",
  EDIT = "[Product] Edit",
  SELECT = "[Product] Select",
  CLEAR_SELECTIONS = "[Product] Clear Selections",
  NAVIGATE_DISCONTINUE = "[Product] Navigate Discontinue",
  MERGE = "[Product] Merge",
  UPDATE = "[Product] Update",
  UPDATE_SUCCESS = "[Product] Update Success",
  UPDATE_FAIL = "[Product] Update Fail",
  DISCONTINUE = "[Product] Discontinue",
  DISCONTINUE_SUCCESS = "[Product] Discontinue Success",
  DISCONTINUE_FAIL = "[Product] Discontinue Fail",
  REMOVE = "[Product] Remove",
  REMOVE_SUCCESS = "[Product] Remove Success",
  REMOVE_FAIL = "[Product] Remove Fail",
  SET_FILTER = "[Prodcut] Set Search Filter",
  SELECT_MANUFACTURER = "[Product] Select Manufacturer",
  CLEAR_MANUFACTURER = "[Product] Clear Manufacturer",
  NAVIGATE_EDIT_PRODUCT = "[Product] Navigate Edit Product",
  CHANGE_PRODUCT_ORDER = "[Product] Change Product Order",
  UPDATE_PRODUCTS = "[Product] Update Products",
  UPDATE_PRODUCTS_SUCCESS = "[Product] Update Products Success",
  UPDATE_PRODUCTS_FAIL = "[Product] Update Products Fail",
}

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;

  constructor(
    public payload: {
      criteria?: JsonObject;
      sort?: JsonObject;
      page?: number;
      limit?: number;
    }
  ) {}
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public products: Product[],
    public discontinuedProducts: DiscontinuedProduct[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class New implements Action {
  public readonly type = Types.NEW;

  constructor(public payload?: { category: ProductCategory; manufacturerId: string }) {}
}

export class Add implements Action {
  public readonly type = Types.ADD;

  constructor(public payload: { product: Product }) {}
}

export class AddSuccess implements Action {
  public readonly type = Types.ADD_SUCCESS;

  constructor(public payload: Product) {}
}

export class AddFail implements Action {
  public readonly type = Types.ADD_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class Update implements Action {
  public readonly type = Types.UPDATE;

  constructor(public payload: { product: Product }) {}
}

export class UpdateSuccess implements Action {
  public readonly type = Types.UPDATE_SUCCESS;

  constructor(public payload: Product) {}
}

export class UpdateFail implements Action {
  public readonly type = Types.UPDATE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class NavigateEditProduct implements Action {
  public readonly type = Types.NAVIGATE_EDIT_PRODUCT;

  constructor(public payload?: { productId: string }) {}
}

export const addOrUpdate = (payload: { product: Product }) => (isNil(payload.product._id) ? new Add(payload) : new Update(payload));

export class Remove implements Action {
  public readonly type = Types.REMOVE;

  constructor(public payload: { id: string }) {}
}

export class RemoveSuccess implements Action {
  public readonly type = Types.REMOVE_SUCCESS;

  constructor(public payload: string) {}
}

export class RemoveFail implements Action {
  public readonly type = Types.REMOVE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class SetFilter implements Action {
  public readonly type = Types.SET_FILTER;

  constructor(public payload: { filter: string }) {}
}

export class SelectManufacturer implements Action {
  public readonly type = Types.SELECT_MANUFACTURER;

  constructor(public payload: { manufacturer: Manufacturer }) {}
}

export class ClearManufacturer implements Action {
  public readonly type = Types.CLEAR_MANUFACTURER;
}

export class Edit implements Action {
  public readonly type = Types.EDIT;

  constructor(public payload: { product: Product }) {}
}

export class Select implements Action {
  public readonly type = Types.SELECT;

  constructor(public payload: { product: ProductSelection }) {}
}

export class ClearSelections implements Action {
  public readonly type = Types.CLEAR_SELECTIONS;
}
export class Merge implements Action {
  public readonly type = Types.MERGE;
}
export class ChangeProductOrder implements Action {
  public readonly type = Types.CHANGE_PRODUCT_ORDER;

  constructor(public payload: { products: Product[] }) {}
}

export class UpdateProducts implements Action {
  public readonly type = Types.UPDATE_PRODUCTS;

  constructor(public payload: { products: Product[] }) {}
}

export class UpdateProductsSuccess implements Action {
  public readonly type = Types.UPDATE_PRODUCTS_SUCCESS;

  constructor(public payload: { products: Product[] }) {}
}

export class UpdateProductsFail implements Action {
  public readonly type = Types.UPDATE_PRODUCTS_FAIL;

  constructor(public payload: { error: Error }) {}
}

export type Actions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | New
  | Add
  | AddSuccess
  | Update
  | UpdateSuccess
  | UpdateFail
  | Remove
  | RemoveSuccess
  | RemoveFail
  | NavigateEditProduct
  | SetFilter
  | SelectManufacturer
  | ClearManufacturer
  | Edit
  | Select
  | ClearSelections
  | ChangeProductOrder
  | UpdateProducts
  | UpdateProductsSuccess
  | UpdateProductsFail;
