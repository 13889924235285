<slideout-panel (close)="close()">
  <div panel-header>Migrate Salon to v2</div>

  <div panel-body>
    <migrate-salon-component
      *ngIf="shouldRenderForm$ | async"
      [salon]="salon$ | async"
      [migrating]="migrating$ | async"
      [success]="success$ | async"
      [error]="error$ | async"
      (migrate)="migrate($event)"
      (close)="close()"
      (cancel)="cancel()"
      (done)="done()"
      panel-body>
    </migrate-salon-component>

    <div *ngIf="loading$ | async">
      <large-loading-indicator></large-loading-indicator>
    </div>

    <div *ngIf="error$ | async">
      <alert-component type="danger">
        <h5>Something went wrong</h5>

        <p>{{ error$ | async }}</p>
      </alert-component>
    </div>
  </div>
</slideout-panel>
