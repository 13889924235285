<data-table localStorageId="insights-employee-performance">
  <columns>
    <column id="icon"></column>
    <column
      id="name"
      title="Name"></column>
    <column
      id="services"
      title="Services"
      [showInColumnManager]="true"></column>
    <column
      *ngIf="enableComplianceTracking"
      id="mixed"
      title="Mixed"
      [showInColumnManager]="true"
      [showByDefault]="false"></column>
    <column
      *ngIf="enableComplianceTracking"
      id="unmixed"
      title="Unmixed"
      [showInColumnManager]="true"></column>
    <column
      id="productDispensed"
      title="Product Dispensed"
      [showInColumnManager]="true"></column>
    <column
      id="pdperService"
      title="Per Service"
      [showInColumnManager]="true"></column>
    <column
      id="%Reweighed"
      title="% Reweighed"
      [showInColumnManager]="true"></column>
    <column
      id="productWaste"
      title="Product Waste"
      [showInColumnManager]="true"></column>
    <column
      id="estimatedWaste"
      title="Estimated Waste"
      [showInColumnManager]="true"></column>
    <column
      id="ewperService"
      title="Per Service"
      [showInColumnManager]="true"></column>
    <column
      id="productCharges"
      title="Product Charges"
      [showInColumnManager]="true"></column>
    <column
      id="%Waste"
      title="% Waste"
      [showInColumnManager]="true"></column>
    <column
      *ngIf="enableComplianceTracking"
      id="%Compliance"
      title="% Compliance"
      [showInColumnManager]="true"></column>
  </columns>
  <body>
    <row
      *ngFor="let report of _reports | take: take"
      [id]="report._id">
      <cell columnId="icon">
        <ng-template>
          <colored-icon-cell
            [title]="report.employeeName"
            [numChars]="1"></colored-icon-cell>
        </ng-template>
      </cell>
      <cell columnId="name">
        <ng-template>
          <text-cell>
            <text-cell-text
              [value]="report.employeeName"
              title="View Employee Report"
              className="primary"
              (action)="viewEmployeeReport(report.employeeId)">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="services">
        <ng-template>
          <text-cell>
            <text-cell-text
              *ngIf="!enableComplianceTracking"
              [value]="report.summary.numServicesPerformed">
            </text-cell-text>
            <text-cell-text
              *ngIf="enableComplianceTracking"
              [value]="report.summary.numMixableServices">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell
        *ngIf="enableComplianceTracking"
        columnId="mixed">
        <ng-template>
          <text-cell>
            <text-cell-text
              [value]="report.summary.numMixedServices"
              (action)="report.summary.numMixedServices === 0 ? undefined : navigateToMixed.emit(report.employeeId)">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell
        *ngIf="enableComplianceTracking"
        columnId="unmixed">
        <ng-template>
          <text-cell>
            <text-cell-text
              [value]="(report.summary.numMixableServices ?? 0) - (report.summary.numMixedServices ?? 0)"
              (action)="
                (report.summary.numMixableServices ?? 0) - (report.summary.numMixedServices ?? 0) === 0
                  ? undefined
                  : navigateToUnmixed.emit(report.employeeId)
              ">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="productDispensed">
        <ng-template>
          <text-cell>
            <text-cell-text
              className="primary"
              [value]="report.summary.wholesaleCostDollars | currency: currency">
            </text-cell-text>
            <text-cell-text
              className="secondary"
              [value]="report.summary.totalAmountGrams | localMeasurementUnit: measurementUnit">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="pdperService">
        <ng-template>
          <text-cell>
            <text-cell-text
              className="primary"
              [value]="report.summary.productDispensedPerServiceWholesaleCost | currency: currency">
            </text-cell-text>
            <text-cell-text
              className="secondary"
              [value]="report.summary.productDispensedPerServiceGrams | localMeasurementUnit: measurementUnit">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="%Reweighed">
        <ng-template>
          <text-cell>
            <text-cell-text [value]="report.summary.completedFormulaRatio | percent"> </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="productWaste">
        <ng-template>
          <text-cell>
            <text-cell-text [value]="report.summary.wasteAmountGrams | localMeasurementUnit: measurementUnit"> </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="estimatedWaste">
        <ng-template>
          <text-cell>
            <text-cell-text
              className="primary"
              [value]="report.summary.estimatedWasteDollars | currency: currency | default: '-'">
            </text-cell-text>
            <text-cell-text
              className="secondary"
              [value]="report.summary.estimatedWaste | localMeasurementUnit: measurementUnit">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="ewperService">
        <ng-template>
          <text-cell>
            <text-cell-text [value]="report.summary.estimatedWastePerServiceDollars | currency: currency | default: '-'"> </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="productCharges">
        <ng-template>
          <text-cell>
            <text-cell-text [value]="report.summary.productOveruseDollars | currency: currency | default: '-'"> </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell columnId="%Waste">
        <ng-template>
          <text-cell>
            <text-cell-text
              className="primary highlighted"
              [value]="report.summary.wasteRatio | percent">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
      <cell
        *ngIf="enableComplianceTracking"
        columnId="%Compliance">
        <ng-template>
          <text-cell>
            <text-cell-text
              className="primary highlighted"
              [value]="getComplianceRatio(report.summary) | percent">
            </text-cell-text>
          </text-cell>
        </ng-template>
      </cell>
    </row>
  </body>
  <empty-state-message
    title="No employees here"
    icon="user-circle"
    data-table-empty-state>
    We couldn't find any data for the selected date range, adjust the dates to view data
  </empty-state-message>
</data-table>
