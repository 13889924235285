import { Salon } from "@getvish/model";
import { createAction, props } from "@ngrx/store";

export const navigateToRevoke = createAction("[Revoke Restore Access] Navigate To Revoke", props<{ salonId: string }>());
export const navigateToRestore = createAction("[Revoke Restore Access] Navigate To Restore", props<{ salonId: string }>());
export const load = createAction("[Revoke Restore Access] Load", props<{ salonId: string }>());
export const loadSuccess = createAction("[Revoke Restore Access] Load Success", props<{ salon: Salon }>());
export const loadFail = createAction("[Revoke Restore Access] Load Fail", props<{ error: Error }>());
export const revokeAccess = createAction("[Revoke Restore Access] Revoke Access", props<{ salon: Salon }>());
export const revokeAccessSuccess = createAction("[Revoke Restore Access] Revoke Access Success");
export const revokeAccessFail = createAction("[Revoke Restore Access] Revoke Access Fail", props<{ error: Error }>());
export const restoreAccess = createAction("[Revoke Restore Access] Restore Access", props<{ salon: Salon }>());
export const restoreAccessSuccess = createAction("[Revoke Restore Access] Restore Access Success");
export const restoreAccessFail = createAction("[Revoke Restore Access] Restore Access Fail", props<{ error: Error }>());
export const close = createAction("[Revoke Restore Access] Close");
