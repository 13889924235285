import { NgModule, ModuleWithProviders } from "@angular/core";
import { SharedModule } from "../kernel/shared";
import { AuthRoutingModule } from "./auth-routing.module";
import { StoreModule } from "@ngrx/store";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faAt, faLock } from "@fortawesome/pro-solid-svg-icons";
import { KernelServicesModule } from "../kernel/services";
import { AuthService, AuthStorageService } from "./services";
import { LoginContainer } from "./containers";
import { AuthEffects, reducer, featureKey } from "./store";
import { AuthSharedModule } from "./auth-shared.module";
import { EffectsModule } from "@ngrx/effects";

@NgModule({
  imports: [StoreModule.forFeature(featureKey, reducer), EffectsModule.forFeature([AuthEffects])],
})
export class RootAuthModule {
  public static forRoot(): ModuleWithProviders<RootAuthModule> {
    return {
      ngModule: RootAuthModule,
      providers: [AuthStorageService, AuthService],
    };
  }
}

@NgModule({
  imports: [RootAuthModule, SharedModule, KernelServicesModule, AuthRoutingModule, AuthSharedModule],
  declarations: [LoginContainer],
})
export class AuthModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faAt, faLock);
  }
}
