import { Component, Input, HostBinding, ChangeDetectionStrategy } from "@angular/core";
import { NavLinkSection } from "../../../util";
import { AppState } from "app/kernel/store";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "sidebar-component",
  styleUrls: ["sidebar.component.less"],
  templateUrl: "sidebar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  @HostBinding("class")
  public cssClass = "expanded";
  @Input()
  public navLinks: NavLinkSection[];
  @Input()
  public disabled: boolean;

  private _isExpanded: boolean;

  constructor(private _store: Store<AppState>) {}

  @Input()
  public set isExpanded(value: boolean) {
    this._isExpanded = value;

    this._updateCssClass(value);
  }

  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  private _updateCssClass(isExpanded: boolean): void {
    this.cssClass = isExpanded ? "expanded" : "collapsed";
  }

  public evaluateBadge(badge?: (store: Store<AppState>) => Observable<number>): Observable<number> {
    if (badge == null) {
      return undefined;
    }

    return badge(this._store);
  }
}
