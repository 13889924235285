import { Injectable } from "@angular/core";
import { ServiceReportService } from "app/+analytics/services";
import { map, Observable, switchMap } from "rxjs";
import { ServiceComplianceAnomaly } from "../model";
import { ServiceDescriptionService } from "./service-description.service";

@Injectable()
export class ServiceComplianceAnomaliesService {
  constructor(
    private _serviceReportService: ServiceReportService,
    private _serviceDescriptionService: ServiceDescriptionService
  ) {}

  public getServiceComplianceAnomalies(startDate: number, endDate: number): Observable<ServiceComplianceAnomaly[]> {
    return this._serviceReportService.fetchForDateRange(startDate, endDate).pipe(
      map((serviceReports) =>
        (serviceReports ?? []).filter((report) => report.summary.numMixableServices === 0 && report.summary.numServicesPerformed !== 0)
      ),
      switchMap((serviceReports) => {
        if (serviceReports.length === 0) {
          return [[]];
        }

        return this._serviceDescriptionService.findByIds(serviceReports.map((report) => report.serviceId)).pipe(
          map((serviceDescriptions) => {
            return serviceReports
              .map((report) => {
                const serviceDescription = serviceDescriptions.find((description) => description._id === report.serviceId);

                return {
                  serviceDescription: serviceDescription,
                  numMixedServices: report.summary.numServicesPerformed,
                };
              })
              .filter(
                (serviceReport) =>
                  serviceReport.serviceDescription != null &&
                  (serviceReport.serviceDescription.flags == null ||
                    !serviceReport.serviceDescription.flags.includes("EXCLUDE_FROM_ANALYTICS"))
              );
          })
        );
      })
    );
  }
}
