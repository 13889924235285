import { createSelector, createFeatureSelector } from "@ngrx/store";
import { SalonState } from "./salon.reducer";
import { fromNullable } from "fp-ts/Option";

/*** SELECTORS ***/
export const getSalonState = createFeatureSelector<SalonState>("salons");
export const getLoading = createSelector(getSalonState, (state) => state.loading);
export const getFilter = createSelector(getSalonState, (state) => state.filter);
export const getSort = createSelector(getSalonState, (state) => state.sort);
export const getPaging = createSelector(getSalonState, (state) => fromNullable(state.paging));
export const getSaving = createSelector(getSalonState, (state) => state.saving);
export const getSalons = createSelector(getSalonState, (state) => state.records);
export const getSalon = (id: string) => createSelector(getSalons, (salons) => salons.find((salon) => salon._id === id));
