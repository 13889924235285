import { Product, ProductCategory, ProductCategoryModule, SalonProduct } from "@getvish/model";
import { includes } from "ramda";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { pricingIncomplete } from "../../common/utils";
import { last } from "fp-ts/lib/Array";
import { getOrElse, map, some, toUndefined } from "fp-ts/lib/Option";

export interface CategoryPricing {
  categoryId: string;
  wholesalePrice: number;
  markup: number;
  containerSize: number;
}

export type Filter = { key: SalonProductFilterKey; val: string; fn: (item: SalonProduct) => boolean };

export type SalonProductFilterKey = "ACTIVE" | "INACTIVE" | "PRICING_INCOMPLETE" | "NON_IMPORTED";

const commonProductFilters: Filter[] = [
  { key: "PRICING_INCOMPLETE", val: "Pricing Incomplete", fn: (item: SalonProduct) => pricingIncomplete(item) },
];

export const salonProductFilters: Filter[] = [
  ...commonProductFilters,
  {
    key: "ACTIVE",
    val: "Active",
    fn: (item: SalonProduct) =>
      !includes(
        "INACTIVE",
        pipe(
          option.fromNullable(item.flags),
          option.getOrElse(() => [])
        )
      ),
  },
  {
    key: "INACTIVE",
    val: "Inactive",
    fn: (item: SalonProduct) =>
      includes(
        "INACTIVE",
        pipe(
          option.fromNullable(item.flags),
          option.getOrElse(() => [])
        )
      ),
  },
];

export interface SalonProductVM extends SalonProduct {
  selected: boolean;
  pricingIncomplete: boolean;
  availableCategories: ProductCategoryVM[];
  inactive: boolean;
}

export const getDescendentProductsForSelectedCategory = (
  products: SalonProduct[] | Product[],
  productCategories: ProductCategory[],
  selectedCategories: ProductCategory[]
) =>
  pipe(
    last(selectedCategories),
    map((selectedCategory) => [selectedCategory, ...ProductCategoryModule.getDescendantCategories(productCategories)(selectedCategory)]),
    map((categories) => ({
      categories,
      products: products.filter((product) => categories.some((category) => product.categoryId === category._id)),
    })),
    getOrElse(() => ({ products, categories: productCategories }))
  );

const fromSalonProduct =
  (selectedProducts: SalonProduct[], availableCategories: ProductCategoryVM[]) =>
  (salonProduct: SalonProduct): SalonProductVM => ({
    ...salonProduct,
    selected: selectedProducts.some((selectedProduct) => selectedProduct._id === salonProduct._id),
    pricingIncomplete: pricingIncomplete(salonProduct),
    availableCategories: availableCategories.filter((category) => salonProduct.categoryId === category._id),
    inactive: includes(
      "INACTIVE",
      pipe(
        option.fromNullable(salonProduct.flags),
        option.getOrElse(() => [])
      )
    ),
  });

export const fromSalonProducts = (
  salonProducts: SalonProduct[],
  productCategories: ProductCategory[],
  selectedProducts: SalonProduct[],
  selectedCategories: ProductCategory[]
) => {
  const { products, categories } = getDescendentProductsForSelectedCategory(salonProducts, productCategories, selectedCategories);
  const categoryVMs = categories.map(fromProductCategory(selectedCategories, productCategories));

  return products.map(fromSalonProduct(selectedProducts, categoryVMs));
};

export class ProductCategoryVM extends ProductCategory {
  selected: boolean;
  terminal: boolean;
  isRoot: boolean;
  rootCategory: ProductCategory;
}

const getSibilings = (productCategories: ProductCategory[]) => (selectedCategory: ProductCategory) =>
  productCategories.filter((category) => category.parentCategoryId === selectedCategory.parentCategoryId);

const getAvailableCategoriesForSelectedCategory = (productCategories: ProductCategory[], selectedCategories: ProductCategory[]) =>
  pipe(
    last(selectedCategories),
    map((selectedCategory) =>
      ProductCategoryModule.isTerminal(productCategories)(selectedCategory)
        ? getSibilings(productCategories)(selectedCategory)
        : ProductCategoryModule.getChildCategories(productCategories)(selectedCategory)
    ),
    getOrElse(() => productCategories.filter((category) => category.parentCategoryId === undefined))
  );

export const fromProductCategory =
  (selectedCategories: ProductCategory[], productCategories: ProductCategory[]) =>
  (productCategory: ProductCategory): ProductCategoryVM => ({
    ...productCategory,
    selected: selectedCategories.some((selectedCategory) => selectedCategory._id === productCategory._id),
    terminal: ProductCategoryModule.isTerminal(productCategories)(productCategory),
    isRoot: ProductCategoryModule.isRootCategory(productCategory),
    rootCategory: toUndefined(ProductCategoryModule.getRootCategory(some(productCategory), productCategories)),
  });

export const hasAtLeastOneDescendentProduct = (categories: ProductCategory[], products: Product[]) => (category: ProductCategory) => {
  const availableCategories = [category, ...ProductCategoryModule.getDescendantCategories(categories)(category)];

  return availableCategories.some((category) => products.some((product) => product.categoryId === category._id));
};

export const fromProductCategories = (productCategories: ProductCategory[], selectedCategories: ProductCategory[], products: Product[]) =>
  getAvailableCategoriesForSelectedCategory(productCategories, selectedCategories)
    .filter(hasAtLeastOneDescendentProduct(productCategories, products))
    .map(fromProductCategory(selectedCategories, productCategories));
