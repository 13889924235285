import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnChanges, OnInit } from "@angular/core";
import { EmployeeReport, LongitudinalReport, SalonReport, employeeReportIsEmpty, getComplianceRatio } from "../../kernel/models";
import { MeasurementUnit } from "@getvish/model";
import { ReportStatistic } from "../../kernel/models/report-statistic";
import { filter, isNil, not, sortWith } from "ramda";
import { getDateInTimeZone, getTodayInTimeZone } from "app/kernel/util/zoned-time-utils";
import { pipe } from "fp-ts/lib/function";

@Component({
  selector: "employee-report",
  templateUrl: "employee-report.component.html",
  styleUrls: ["employee-report.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeReportComponent implements OnChanges, OnInit {
  @Input() public employeeReport: EmployeeReport;
  @Input() public longitudinalReport: LongitudinalReport;
  @Input() public summary: SalonReport;
  @Input() public aggregatedBy: string;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public startDate: number;
  @Input() public endDate: number;
  @Input() public currency: string;
  @Input() public locale: string;
  @Input() public timeZone: string;
  @Input() public enableComplianceTracking: boolean;

  @Output() public aggregation: EventEmitter<string>;
  @Output() public downloadServicesSummaryReport: EventEmitter<void>;
  @Output() public updateDateRange: EventEmitter<{ startDate: number; endDate: number }>;
  @Output() public navigateToUnmixed: EventEmitter<string>;
  @Output() public navigateToMixed: EventEmitter<string>;

  public minDate: Date;
  public maxDate: Date;
  public _serviceStatistics: ReportStatistic[];
  public reportHasData: boolean;

  public getComplianceRatio = getComplianceRatio;

  constructor() {
    this.aggregation = new EventEmitter();
    this.downloadServicesSummaryReport = new EventEmitter(true);
    this.updateDateRange = new EventEmitter();
    this.navigateToUnmixed = new EventEmitter();
    this.navigateToMixed = new EventEmitter();
  }

  public ngOnInit(): void {
    this.minDate = getDateInTimeZone(this.timeZone, new Date(2000, 0, 1));
    this.maxDate = getTodayInTimeZone(this.timeZone);
  }

  public ngOnChanges(): void {
    if (isNil(this.employeeReport)) {
      return;
    }

    const drivingKey = this.enableComplianceTracking ? "numMixableServices" : "numServicesPerformed";
    const sortKeys = this.enableComplianceTracking ? ["numMixableServices", "numMixedServices"] : ["numServicesPerformed"];

    this._serviceStatistics = pipe(
      this.employeeReport.serviceStatistics,
      filter<ReportStatistic>((report) => report.metrics[drivingKey] > 0),
      sortWith(sortKeys.map((key) => (a, b) => b.metrics[key] - a.metrics[key]))
    );

    this.reportHasData = not(employeeReportIsEmpty(this.employeeReport));
  }
}
