<slideout-panel
  (close)="close()"
  [progressIndicator]="progressMessage$ | async"
  *ngLet="mode$ | async as mode">
  <div panel-header>{{ mode === "revoke" ? "Revoke" : "Restore" }} Salon Access</div>

  <div panel-body>
    <div *ngIf="error$ | async as error">
      <div class="page-content">
        <alert-component type="danger">
          <h4>An Error Has Occured</h4>

          <p>{{ error.message }}</p>
        </alert-component>
      </div>
    </div>

    <div *ngIf="(error$ | async) == null && salon != null">
      <div>
        <div class="page-content">
          <h4>
            {{ mode === "revoke" ? "Revoke" : "Restore" }} access for <span class="highlight">{{ salon.name }}</span> ?
          </h4>
        </div>
        <form
          class="revoke-form"
          [formGroup]="form"
          novalidate>
          <h5>
            Type <b>'{{ salon.slug }}'</b> to confirm
          </h5>
          <mat-form-field class="full-width">
            <input
              matInput
              formControlName="slug" />
            <mat-error *ngIf="form.get('slug').hasError('required')">Required</mat-error>
            <mat-error *ngIf="form.get('slug').hasError('equals')">Please enter the <i>exact</i> slug of the salon</mat-error>
          </mat-form-field>
        </form>

        <div class="controls">
          <button
            (click)="close()"
            class="button outline">
            <span>Cancel</span>
          </button>
          <button
            (click)="mode === 'revoke' ? revokeAccess(salon) : restoreAccess(salon)"
            [disabled]="form.invalid"
            class="button primary">
            <span>{{ mode === "revoke" ? "Revoke" : "Restore" }} Access</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</slideout-panel>
