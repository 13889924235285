import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges } from "@angular/core";
import { MeasurementUnit, TargetWeightMetadata, TargetWeightType } from "@getvish/model";

@Component({
  selector: "weight-selector",
  templateUrl: "weight-selector.component.html",
  styleUrls: ["weight-selector.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeightSelectorComponent implements OnChanges {
  @Input() weight: TargetWeightMetadata;
  @Input() options: TargetWeightMetadata[];
  @Input() type: TargetWeightType;
  @Input() measurementUnit: MeasurementUnit;
  @Input() allowCustom: boolean;
  @Input() step: number;
  @Output() weightChange: EventEmitter<TargetWeightMetadata>;

  public customValue?: number;
  public customActive?: boolean;

  public constructor() {
    this.weightChange = new EventEmitter<TargetWeightMetadata>();
  }

  public ngOnChanges(): void {
    this.customValue =
      !this.customActive && this.options.some((option) => this.weight?.value === option.value) ? undefined : this.weight?.value;
  }

  public getOptionText(option: TargetWeightMetadata) {
    if (option._type === TargetWeightType.ABSOLUTE) {
      return `${option.value}${this.measurementUnit.abbreviation}`;
    }

    return `${option.value}x`;
  }

  public customChanged(event) {
    this.weightChange.emit({
      value: Number(event.target.value),
      _type: this.type,
    });
  }

  public getCustomText() {
    return this.getOptionText({
      value: this.customValue,
      _type: this.type,
    });
  }

  public customFocused() {
    this.customActive = true;
  }

  public customBlurred() {
    this.customActive = false;
  }
}
