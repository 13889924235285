import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Salon, SalonModule } from "@getvish/model";
import { LeadingTrailingWhitespaceValidator } from "app/kernel";
import { isValidSlug } from "app/kernel/validators/slug.validator";
import { isEmpty, isNil } from "ramda";

@Component({
  selector: "edit-salon-component",
  templateUrl: "./edit-salon.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSalonComponent implements OnChanges {
  @Input() public salon: Salon;
  @Input() public saving: boolean;

  @Output() public close: EventEmitter<void>;
  @Output() public save: EventEmitter<Salon>;

  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
    this.close = new EventEmitter(true);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.salon) {
      this._createForm();
    }
  }

  public saveSalon(original: Salon, data: Partial<Salon>): void {
    // TODO
    // so this probably isn't the most elegant way to handle this, but,
    // if the user doesn't complete the `billing.customerId` field, it will be null
    // and when a patch is generated and sent to the server later it'll be calculated
    // as an empty object, which then is illegal (because @customerId is required if there's a Billing object)
    // so, in the case that @customerId is null/undefined, set `@billing: undefined` so it'll be
    // completely stripped out before it's sent over the wire and we don't have to deal with this problem
    const incoming = isNil(data.billing.customerId) || isEmpty(data.billing.customerId) ? { ...data, billing: undefined } : data;

    const updated = SalonModule.merge(original, incoming);

    this.save.emit(updated);
  }

  private _createForm(): void {
    const salon = this.salon;

    this.form = this._fb.group({
      name: [salon.name?.trim(), [Validators.required, LeadingTrailingWhitespaceValidator]],
      slug: [salon.slug, isValidSlug],
      billing: this._fb.group({
        customerId: [salon.billing?.customerId],
      }),
    });
  }
}
