import { AbstractControl } from "@angular/forms";
import { isNil, not } from "ramda";

export function ValidateHexColorCode(control: AbstractControl) {
  const value = control.value as string;

  if (isNil(value)) {
    return null; // since we aren't validating that this field is required we don't care if it's null/undefined
  } else if (not(value.match(/^#([A-Fa-f0-9]{6})$/))) {
    return { validHexColorCode: true };
  }

  return null;
}
