<button
  class="button transparent bell"
  mat-icon-button
  [matBadge]="badgeText$ | async"
  matBadgeColor="warn"
  matBadgeSize="small"
  title="Notifications"
  (click)="toggleOpen()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">
  <fa-icon icon="bell"></fa-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="isOpen"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (backdropClick)="isOpen = false">
  <div class="notification-list-container">
    <notification-list
      [notifications]="notificationsForPopup$ | async"
      (action)="doNotificationAction($event)"></notification-list>
  </div>
</ng-template>
