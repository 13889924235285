import { LongitudinalReport } from "../../kernel/models";
import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Option } from "fp-ts/Option";
import { isNil } from "ramda";

@Injectable()
export class LongitudinalReportService extends EntityService<LongitudinalReport> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "longitudinalReports" });
  }

  public fetchForDateRange(startDate: number, endDate: number, aggregatedBy: string): Observable<Option<LongitudinalReport>> {
    const criteria = { startDate, endDate, aggregatedBy };

    return this.findOne(criteria);
  }

  public fetchForEmployeeInDateRange(
    employeeId: string,
    startDate: number,
    endDate: number,
    aggregatedBy: string
  ): Observable<Option<LongitudinalReport>> {
    const employeeIds = isNil(employeeId) ? undefined : [employeeId];
    const criteria = { startDate, endDate, employeeIds, aggregatedBy };

    return this.findOne(criteria);
  }
}
