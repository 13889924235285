import {
  Manufacturer,
  ProductAllowance,
  ProductCategory,
  ProductType,
  SalonProduct,
  ServiceDescription,
  TargetWeightMetadata,
} from "@getvish/model";
export interface ProductAllowanceProduct extends SalonProduct {
  manufacturer: Manufacturer;
  rootCategory: ProductCategory;
}

export interface ProductAllowanceBlueprintIngredientVM {
  product: ProductAllowanceProduct;
  weight: TargetWeightMetadata;
  type: ProductType;
}

export interface ProductAllowanceBlueprintVM {
  _id: string;
  pigments?: ProductAllowanceBlueprintIngredientVM[];
  developers?: ProductAllowanceBlueprintIngredientVM[];
  scrollIntoView?: boolean;
}

export interface ProductAllowanceVM {
  blueprints: ProductAllowanceBlueprintVM[];
  isActive: boolean;
}

export const isPartsAndLabor = (pa: ProductAllowanceVM | ProductAllowance, sd: ServiceDescription) => {
  return (pa == null && (sd.productAllowance == null || sd.productAllowance <= 0)) || pa?.isActive === false;
};
