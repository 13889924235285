<div>
  <form
    [formGroup]="form"
    (submit)="saveForm(form.value)"
    novalidate>
    <div class="service-list">
      <span>You are modifying the following services:</span>
      <div *ngFor="let service of services">
        <li>{{ service.name }}</li>
      </div>
    </div>

    <mat-form-field class="full-width">
      <mat-label>Category</mat-label>
      <input
        type="text"
        matInput
        formControlName="categoryId"
        [matAutocomplete]="categoryAuto" />
      <mat-error *ngIf="form.get('categoryId').hasError('selectCategory')"> Please select a category from the list </mat-error>
    </mat-form-field>
    <mat-autocomplete
      #categoryAuto="matAutocomplete"
      [displayWith]="displayCategory(categories)">
      <ng-container *ngFor="let group of filteredCategoryGroups$ | async">
        <mat-option [value]="group.rootCategory._id">
          {{ group.rootCategory.name }}
        </mat-option>
        <mat-option
          *ngFor="let category of group.children"
          class="child-category"
          [value]="category._id">
          {{ category.name }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </form>

  <div class="controls">
    <button
      (click)="saveForm(form.value)"
      class="button primary">
      <span *ngIf="!saving; else loadingIndicator">Save</span>
      <ng-template #loadingIndicator>
        <fa-icon
          *ngIf="saving"
          [icon]="['fas', 'circle-notch']"
          [spin]="true"></fa-icon>
      </ng-template>
    </button>
  </div>
</div>
