import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromTenantEntitlementsReducer from "./tenant-entitlements.reducer";

export const getState = createFeatureSelector<fromTenantEntitlementsReducer.TenantEntitlementsState>(
  fromTenantEntitlementsReducer.tenantEntitlementsFeatureKey
);

export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
export const getEntitlements = createSelector(getState, (state) => state.entitlements);
