import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./schedule-appointment.reducer";

export const getState = createFeatureSelector<State>("scheduleAppointment");
export const getLoading = createSelector(getState, (state) => state.loading);
export const getSelectedCustomerId = createSelector(getState, (state) => state.selectedCustomerId);
export const getSaving = createSelector(getState, (state) => state.saving);
export const getFilter = createSelector(getState, (state) => state.filter);
export const getError = createSelector(getState, (state) => state.error);
export const getEmployees = createSelector(getState, (state) => state.employees);
