import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Manufacturer } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { getParamMap } from "app/kernel/store/selectors/router.selectors";
import escapeStringRegexp from "escape-string-regexp";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { isNil, not } from "ramda";
import { Observable, Subscription, combineLatest, zip } from "rxjs";
import { map } from "rxjs/operators";
import { AppState } from "../../../kernel";

import { JsonObject } from "@getvish/stockpile";
import { Paging } from "app/+components";
import * as ManufacturerActions from "../store/manufacturer.actions";
import * as fromManufacturer from "../store/manufacturer.selectors";

@Component({
  selector: "admin-manufacturers-table",
  templateUrl: "admin-manufacturers-table.container.html",
  styleUrls: ["admin-manufacturers-table.container.less"],
})
export class AdminManufacturersTableContainer implements OnDestroy {
  @Output() select: EventEmitter<Manufacturer> = new EventEmitter<Manufacturer>();

  public manufacturers$: Observable<Manufacturer[]>;
  public filter$: Observable<string>;
  public paging$: Observable<Paging>;
  public sort$: Observable<{ [key: string]: 1 | -1 }>;
  public loading$: Observable<boolean>;

  private urlParamsSubscription: Subscription;

  public limit: number;

  constructor(private _store: Store<AppState>) {
    this.limit = 50;
    this.manufacturers$ = _store.select(fromManufacturer.getManufacturers);

    const count$ = _store.select(fromManufacturer.getPaging).pipe(
      map((paging) =>
        pipe(
          paging,
          option.map((paging) => paging.count),
          option.getOrElse(() => 0)
        )
      )
    );

    this.loading$ = _store.select(fromManufacturer.getLoading);

    const currPage$ = this._store.pipe(select(getParamMap)).pipe(
      map((params) => (not(isNil(params)) ? params.get("page") : undefined)),
      map((page) => (not(isNil(page)) ? Number(page) : 1))
    );

    this.paging$ = combineLatest([count$, currPage$]).pipe(
      map(([count, currentPage]) => {
        return { count, currentPage, limit: this.limit, changePage: this.navigateToPage.bind(this) };
      })
    );

    const filter$ = this._store.pipe(
      select(getParamMap),
      map((params) => (not(isNil(params)) ? params.get("filter") : undefined))
    );

    this.sort$ = this._store.pipe(
      select(getParamMap),
      map((params) => (params.get("sort") != null ? JSON.parse(params.get("sort")) : { name: 1 }))
    );

    this.urlParamsSubscription = zip(currPage$, filter$, this.sort$)
      .pipe(
        map(([page, filter, sort]) => {
          const criteria = pipe(
            filter,
            option.fromNullable,
            option.filter((filter) => filter.trim() !== ""),
            option.fold(
              () => undefined,
              (filter) => ({ name: { $regex: `^${escapeStringRegexp(filter)}`, $options: "i" } })
            )
          );

          return new ManufacturerActions.LoadAll({ page, limit: this.limit, criteria, sort });
        })
      )
      .subscribe((action) => this._store.dispatch(action));
  }

  public updateFilter(filter: string): void {
    this._store.dispatch(new ManufacturerActions.SetAdminFilter({ filter }));
  }

  public updateSort(sort: JsonObject): void {
    this._store.dispatch(new ManufacturerActions.SetAdminSort(sort));
  }

  public navigateToPage(page: number): void {
    this._store.dispatch(new ManufacturerActions.NavigateToPage({ page }));
  }

  public ngOnDestroy(): void {
    this.urlParamsSubscription.unsubscribe();
  }
}
