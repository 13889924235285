<div
  class="notification-list-item"
  [ngClass]="{ hasAction: notification.action | isDefined }"
  (click)="this.action.emit()">
  <div
    class="read-indicator"
    [ngClass]="{ unread: notification.isRead !== true }"></div>
  <div
    class="icon"
    [ngClass]="{ read: notification.isRead === true }">
    <fa-icon
      *ngIf="notification.icon | isDefined"
      [icon]="notification.icon"></fa-icon>
    <span
      *ngIf="notification.icon | isNil"
      class="dot"></span>
  </div>
  <div class="content">
    <div class="title">{{ notification.title }}</div>
    <div class="description">{{ notification.description }}</div>
    <div class="date">{{ notification.date | formatDistance }}</div>
  </div>
</div>
