import { ColoredDotComponent } from "./colored-dot";
import { SearchInputComponent, AutocompleteSearchInputComponent } from "./search-input";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ColoredIconComponent } from "./colored-icon";
import { SlideoutPanelModule } from "./slideout-panel";
import { HeaderControlsComponent } from "./header-controls";
import { AlertComponent } from "./alert";
import {
  TakePipe,
  DefaultIfEmptyPipe,
  JoinPipe,
  LowercasePipe,
  IsEmptyPipe,
  NotEmptyPipe,
  IncludesPipe,
  IsNilPipe,
  ReplacePipe,
  LocalMeasurementUnitPipe,
  AppendCurrencySymbolPipe,
  FullNamePipe,
  FormatDurationPipe,
  ZonedDatePipe,
  IsDefinedPipe,
  PluckPipe,
} from "./pipes";
import { StopClickPropagationDirective } from "./directives";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ReactiveFormsModule } from "@angular/forms";
import { LargeLoadingIndicatorComponent } from "./large-loading-indicator";
import { CurrencySuffixComponent } from "./currency-suffix";
import { EmptyStateMessageComponent } from "./empty-state-message";
import { DatePickerComponent } from "./date-picker";
import { DateRangeComponent } from "./date-range";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircle, faSearch, faTimes, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    SlideoutPanelModule.forRoot(),
    MatDatepickerModule,
    FontAwesomeModule,
  ],
  declarations: [
    ColoredIconComponent,
    HeaderControlsComponent,
    AlertComponent,
    TakePipe,
    DefaultIfEmptyPipe,
    JoinPipe,
    LowercasePipe,
    IsEmptyPipe,
    NotEmptyPipe,
    StopClickPropagationDirective,
    IncludesPipe,
    PluckPipe,
    ReplacePipe,
    LocalMeasurementUnitPipe,
    SearchInputComponent,
    AutocompleteSearchInputComponent,
    IsNilPipe,
    ColoredDotComponent,
    LargeLoadingIndicatorComponent,
    AppendCurrencySymbolPipe,
    CurrencySuffixComponent,
    FullNamePipe,
    FormatDurationPipe,
    EmptyStateMessageComponent,
    DatePickerComponent,
    DateRangeComponent,
    ZonedDatePipe,
    IsDefinedPipe,
  ],
  exports: [
    ColoredIconComponent,
    SlideoutPanelModule,
    HeaderControlsComponent,
    AlertComponent,
    TakePipe,
    DefaultIfEmptyPipe,
    JoinPipe,
    LowercasePipe,
    IsEmptyPipe,
    NotEmptyPipe,
    LocalMeasurementUnitPipe,
    StopClickPropagationDirective,
    IncludesPipe,
    PluckPipe,
    ReplacePipe,
    SearchInputComponent,
    AutocompleteSearchInputComponent,
    IsNilPipe,
    ColoredDotComponent,
    LargeLoadingIndicatorComponent,
    AppendCurrencySymbolPipe,
    CurrencySuffixComponent,
    FullNamePipe,
    FormatDurationPipe,
    EmptyStateMessageComponent,
    DatePickerComponent,
    DateRangeComponent,
    ZonedDatePipe,
    IsDefinedPipe,
  ],
  providers: [IsEmptyPipe, DatePipe],
})
export class RocketUiModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCircle, faTimesCircle, faSearch, faTimes);
  }
}
