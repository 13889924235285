import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";

import * as actions from "../store/reset-password.actions";

@Component({
  selector: "set-password-container",
  templateUrl: "set-password.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordContainer {
  constructor(private _store: Store<AppState>) {}

  public submit(value: { password: string }): void {
    this._store.dispatch(new actions.SetPassword(value));
  }
}
