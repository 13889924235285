import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { utcToZonedTime } from "date-fns-tz";
import { isNil } from "ramda";

@Pipe({ name: "zonedDate", pure: true })
export class ZonedDatePipe implements PipeTransform {
  constructor(private _datePipe: DatePipe) {}

  public transform(value: Date | number, timeZone: string, format?: string, locale?: string): string {
    if (isNil(value)) {
      return undefined;
    }
    const zonedDate = utcToZonedTime(value, timeZone);

    return this._datePipe.transform(zonedDate, format, undefined, locale);
  }
}
