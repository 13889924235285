import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Manufacturer, MeasurementUnit, ProductCategory, SalonProduct } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, combineLatest } from "rxjs";
import { map, mergeMap, take, takeUntil } from "rxjs/operators";
import { AppState } from "../../../kernel/store";
import { go } from "../../../kernel/store/actions/router.actions";
import {
  Filter,
  ProductCategoryVM,
  SalonProductFilterKey,
  SalonProductVM,
  fromProductCategories,
  fromSalonProducts,
  salonProductFilters,
} from "../common";

import * as fromAuth from "app/+auth/store/auth.selectors";
import * as fromManufacturer from "../../+manufacturers/store/manufacturer.selectors";
import * as fromProductCategory from "../../+product-categories/store/product-category.selectors";
import * as fromSalonConfig from "../../../+salon-config/store/salon-config.selectors";
import * as fromSalonProduct from "../store/salon-product.selectors";

import * as ManufacturerActions from "../../+manufacturers/store/manufacturer.actions";
import * as ProductCategoryActions from "../../+product-categories/store/product-category.actions";
import * as SalonProductActions from "../store/salon-product.actions";
import * as MasterPricingActions from "../../+master-pricing/store/master-pricing.actions";
import { isNil, sort } from "ramda";

@Component({
  selector: "salon-product-management-container",
  templateUrl: "salon-product-management.container.html",
  styleUrls: ["salon-product-management.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalonProductManagementContainer implements OnDestroy {
  public selectedManufacturer$: Observable<Manufacturer>;
  public loading$: Observable<boolean>;
  public isRootUser$: Observable<boolean>;

  public salonProducts$: Observable<SalonProductVM[]>;
  public productCategories$: Observable<ProductCategoryVM[]>;
  public allProductCategories$: Observable<ProductCategory[]>;

  public selectedCategories$: Observable<ProductCategory[]>;
  public selectedProducts$: Observable<SalonProduct[]>;

  public measurementUnit$: Observable<MeasurementUnit>;
  public currency$: Observable<string>;
  public searchFilter$: Observable<string>;
  public flagFilters$: Observable<string[]>;
  public breadcrumbsItems$: Observable<Array<{ _id: string; name: string }>>;

  public availableFilters: Filter[] = salonProductFilters;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.loading$ = this._store.pipe(select(fromSalonProduct.getLoading));
    this.searchFilter$ = this._store.pipe(select(fromSalonProduct.getSearchFilter));
    this.flagFilters$ = this._store.pipe(select(fromSalonProduct.getFlagFilters));

    const selectedManufacturerId$ = _route.paramMap.pipe(
      map((params) => params.get("manufacturerId")),
      takeUntil(this._onDestroy$)
    );

    this.selectedManufacturer$ = selectedManufacturerId$.pipe(
      mergeMap((manufacturerId) => _store.pipe(select(fromManufacturer.getManufacturer(manufacturerId))))
    );

    this.allProductCategories$ = combineLatest([
      this._store.select(fromProductCategory.getAll),
      this._store.select(fromSalonProduct.getSalonProductCategories),
    ]).pipe(
      map(([productCategories, salonProductCategories]) => {
        const spcById = salonProductCategories.reduce((acc, spc) => {
          acc[spc.productCategoryId] = spc;
          return acc;
        }, {});

        const adjustedCategories = productCategories.map((c) => ({
          ...c,
          order: spcById[c._id]?.order ?? c.order,
        }));

        const catById = adjustedCategories.reduce((acc, c) => {
          acc[c._id] = c;
          return acc;
        }, {});

        const createChain = (category: ProductCategory, acc: ProductCategory[] = []) => {
          const parent = catById[category.parentCategoryId];

          if (parent != null) {
            createChain(parent, acc);
          }

          acc.push(category);
          return acc;
        };

        const categoryChains = adjustedCategories.reduce((acc, category) => {
          acc[category._id] = createChain(category);
          return acc;
        }, {});

        const sorted = sort((c1, c2) => {
          const chain1 = categoryChains[c1._id];
          const chain2 = categoryChains[c2._id];

          const rootCategory1 = chain1[0];
          const rootCategory2 = chain2[0];

          if (rootCategory1._id === rootCategory2._id) {
            return chain1.reduce((c, category, i) => {
              if (c !== 0) {
                return c;
              }

              const category2 = chain2.length > i ? chain2[i] : undefined;

              if (isNil(category2)) {
                return 1;
              } else if (category._id === category2._id) {
                return 0;
              }

              const order1 = category.order ?? Number.MAX_SAFE_INTEGER;
              const order2 = category2.order ?? Number.MAX_SAFE_INTEGER;

              return order1 - order2;
            }, 0);
          } else {
            const order1 = rootCategory1.order ?? Number.MAX_SAFE_INTEGER;
            const order2 = rootCategory2.order ?? Number.MAX_SAFE_INTEGER;

            const ret = order1 - order2;
            return ret === 0 ? rootCategory1.name.localeCompare(rootCategory2.name) : ret;
          }
        }, adjustedCategories);

        return sorted;
      })
    );

    this.productCategories$ = combineLatest([
      this.allProductCategories$,
      this._store.pipe(select(fromSalonProduct.getSelectedCategories)),
      this._store.pipe(select(fromSalonProduct.getFilteredProducts)),
    ]).pipe(
      map(([productCategories, selectedCategories, products]) => fromProductCategories(productCategories, selectedCategories, products))
    );

    this.salonProducts$ = combineLatest([
      this._store.pipe(select(fromSalonProduct.getFilteredProducts)),
      this.allProductCategories$,
      this._store.pipe(select(fromSalonProduct.getSelectedCategories)),
      this._store.pipe(select(fromSalonProduct.getSelectedProducts)),
    ]).pipe(
      map(([salonProducts, productCategories, selectedCategories, selectedProducts]) =>
        fromSalonProducts(salonProducts, productCategories, selectedProducts, selectedCategories)
      )
    );

    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));

    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));

    this.breadcrumbsItems$ = this._store.pipe(select(fromSalonProduct.getSelectedCategories));

    selectedManufacturerId$.subscribe((manufacturerId) => {
      _store.dispatch(new ManufacturerActions.LoadManufacturerById({ manufacturerId }));
      _store.dispatch(
        new SalonProductActions.LoadProductsAndCategoriesForManufacturer({
          manufacturerId,
        })
      );
    });

    this.isRootUser$ = _store.select(fromAuth.isRoot);
  }

  public ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  public back(): void {
    this._store.dispatch(go({ path: ["/product/salon-products"] }));
  }

  public toggleCategory(category: ProductCategory): void {
    const categoryId = category._id;

    this._store.dispatch(new ProductCategoryActions.ToggleCategory({ categoryId }));
  }

  public importManufacturer(manufacturer: Manufacturer): void {
    this._store.dispatch(new SalonProductActions.ImportManufacturer({ manufacturer }));
  }

  public importCategory(category: ProductCategory): void {
    this._store.dispatch(new SalonProductActions.ImportProductCategory({ category }));
  }

  public editProduct(product: SalonProduct): void {
    this._store.dispatch(new SalonProductActions.Edit({ product }));
  }

  public setFilter(filter: string): void {
    this._store.dispatch(new SalonProductActions.SetSearchFilter({ searchFilter: filter }));
  }

  public setFlagFilters(filters: SalonProductFilterKey[]): void {
    this._store.dispatch(new SalonProductActions.SetFlagFilters({ filters }));
  }

  public setCategoryPricing(category: ProductCategory): void {
    this._store.dispatch(new SalonProductActions.NavigateCategoryPricing({ category }));
  }

  public placeSalonInteractiveOrder(category: ProductCategory): void {
    this._store.dispatch(new SalonProductActions.PlaceSalonInteractiveOrder({ category }));
  }

  public setCategoryInactive(category: ProductCategory): void {
    this._store.dispatch(new SalonProductActions.SetCategoryInactive({ category }));
  }

  public setCategoryActive(category: ProductCategory): void {
    this._store.dispatch(new SalonProductActions.SetCategoryActive({ category }));
  }

  public downloadSheet(manufacturer: Manufacturer): void {
    const manufacturerId = manufacturer._id;
    this._store.dispatch(new SalonProductActions.DownloadSpreadsheet({ manufacturerId }));
  }

  public uploadSheet(): void {
    this._store.dispatch(new SalonProductActions.OpenUploadComponent());
  }

  public pushCategory(category: ProductCategory): void {
    this._store.dispatch(new SalonProductActions.PushProductCategory({ category }));
  }

  public popCategory(): void {
    this._store.dispatch(new SalonProductActions.PopProductCategory());
  }

  public popToCategory(categoryId: string): void {
    this._store.dispatch(new SalonProductActions.PopToProductCategory({ categoryId }));
  }

  public clearSelectedCategories(): void {
    this._store.dispatch(new SalonProductActions.ClearProductCategories());
  }

  public showMasterPricing(manufacturer: Manufacturer): void {
    this._store.dispatch(MasterPricingActions.show(manufacturer));
  }

  public changeCategoryOrder(): void {
    this.productCategories$.pipe(take(1)).subscribe((productCategories) => {
      this._store.dispatch(new SalonProductActions.ShowProductCategoryOrderDialog({ productCategories }));
    });
  }

  public setMarkup(category?: ProductCategory): void {
    this._store.dispatch(new SalonProductActions.NavigateMarkup({ category }));
  }
}
