import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { NavigateToLogin } from "app/+auth/store";
import { AppState } from "app/kernel";

@Component({
  selector: "reset-password-root-container",
  templateUrl: "./reset-password-root.container.html",
  styleUrls: ["./reset-password-root.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordRootContainer {
  constructor(private _store: Store<AppState>) {}

  public close() {
    this._store.dispatch(new NavigateToLogin());
  }
}
