import { ProductCategory, SalonProduct } from "@getvish/model";
import { any, isNil } from "ramda";

export const pricingIncomplete = (product: SalonProduct): boolean =>
  any(isNil)([product.wholesalePrice, product.containerSize, product.markup]);

export type HasName = { name: string };
export const hasSearchTerm = (search: string) => (value: HasName) => {
  if (isNil(search)) {
    return true;
  }

  if (isNil(value?.name)) {
    return false;
  }

  const searchTerms = search.split(" ");
  return searchTerms.every((term) => value.name.toLowerCase().includes(term.toLowerCase()));
};

type HasCategoryId = { categoryId: string };
export const hasCategorySearchTerm = (search: string, categories: ProductCategory[]) => (value: HasCategoryId) => {
  if (isNil(search)) {
    return true;
  }

  if (isNil(value?.categoryId)) {
    return false;
  }

  const categoriesById = categories.reduce((acc, c) => {
    acc[c._id] = c;
    return acc;
  }, {});

  let category = categoriesById[value.categoryId];

  while (category != null) {
    if (hasSearchTerm(search)(category)) {
      return true;
    }

    category = categoriesById[category.parentCategoryId];
  }

  return false;
};
