import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "product-allowance-cell",
  templateUrl: "product-allowance-cell.component.html",
  styleUrls: ["product-allowance-cell.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAllowanceCellComponent {
  @Input()
  public serviceId: string;
  @Input()
  public productAllowance: number;
  @Input()
  public currency: string;
  @Input()
  public disabled = false;
}
