import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { ColumnDirective } from "app/+components/+data-table/directives";

@Component({
  selector: "text-filter",
  templateUrl: "text-filter.component.html",
  styleUrls: ["text-filter.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFilterComponent {
  @Input()
  public column: ColumnDirective;

  @Output()
  public filter: EventEmitter<string>;

  constructor() {
    this.filter = new EventEmitter();
  }

  public updateFilter(value: string): void {
    this.filter.emit(value);
  }
}
