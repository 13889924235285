import { Component, Inject } from "@angular/core";
import { Observable, combineLatest, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { Store, select } from "@ngrx/store";
import { AppState } from "./kernel/store";
import { Salon, User } from "@getvish/model";
import { getMenuExpanded, getPanelOpen } from "./kernel/store/reducers/ui.reducer";
import { changeSalon, navigateHome } from "./kernel/store/actions/app.actions";
import { NavLinkSection } from "./kernel/util/nav-link-section";
import { NAV_LINKS } from "./kernel/util/nav-links";

import * as ui from "./kernel/store/actions/ui.actions";
import * as fromApp from "./kernel/store/reducers/app.reducer";
import * as fromAuth from "./+auth/store/auth.selectors";
import * as fromIntegrations from "./+integrations/store/integrations.selectors";
import * as fromCommon from "./kernel/store/reducers/common.reducer";
import * as fromAdmin from "./kernel/store/reducers/admin.reducer";
import * as fromCurrentTenant from "./kernel/store/reducers/current-tenant.reducer";

import * as adminActions from "./kernel/store/actions/admin.actions";
import * as authActions from "./+auth/store/auth.actions";
import { FrontDeskNotificationsService } from "./+front-desk/services/front-desk-notifications.service";
import { ServiceMenuNotificationsService } from "./+service-menu/services/service-menu-notifications.service";

@Component({
  selector: "app",
  styleUrls: ["app.component.less"],
  templateUrl: "app.component.html",
})
export class AppComponent {
  public menuExpanded$: Observable<boolean>;
  public user$: Observable<User>;
  public isRoot$: Observable<boolean>;
  public panelOpen$: Observable<boolean>;
  public salons$: Observable<Salon[]>;
  public currentSalon$: Observable<Salon>;
  public navLinks$: Observable<NavLinkSection[]>;
  public slug$: Observable<string>;
  public salonFilter$: Observable<string>;
  public loading$: Observable<boolean>;
  public forbidden$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    _frontDeskNotificationsService: FrontDeskNotificationsService,
    _serviceMenuNotificationsService: ServiceMenuNotificationsService,
    @Inject(NAV_LINKS) navLinks: NavLinkSection[]
  ) {
    _frontDeskNotificationsService.enableNotifications();
    _serviceMenuNotificationsService.enableNotifications();

    this.menuExpanded$ = this._store.select(getMenuExpanded);
    this.panelOpen$ = this._store.select(getPanelOpen);
    this.user$ = this._store.select(fromAuth.getUser);
    this.isRoot$ = this._store.select(fromAuth.isRoot);
    this.slug$ = this._store.select(fromCommon.getSlug);
    this.salonFilter$ = this._store.select(fromAdmin.getSalonFilter);

    this.navLinks$ = combineLatest([this.user$, this.slug$]).pipe(
      switchMap(([user, slug]) => {
        const integrations$ = slug === "admin" ? of([]) : this._store.select(fromIntegrations.getIntegrations);
        return integrations$.pipe(map((integrations) => navLinks.filter((navLink) => navLink.canActivate(slug, user, integrations))));
      })
    );

    const userSalons$ = _store.select(fromCurrentTenant.getTenants);
    const adminSalons$ = _store.select(fromAdmin.getSalons);

    // if the current user is a ROOT user we'll want to pipe through the searchable salons from the admin state slice
    // otherwise we'll send through the regular user-specific salons (basically only the salons that that user has access to)
    this.salons$ = this.isRoot$.pipe(switchMap((isRoot) => (isRoot ? adminSalons$ : userSalons$)));

    this.currentSalon$ = _store.pipe(select(fromCurrentTenant.getCurrentTenant));

    this.loading$ = _store.pipe(select(fromApp.getLoading));
    this.forbidden$ = _store.pipe(select(fromApp.getForbidden));
  }

  public changeSalon(salon: Salon): void {
    this._store.dispatch(changeSalon({ salon }));
  }

  public toggleMenu(): void {
    this._store.dispatch(new ui.ToggleMenu());
  }

  public navigateHome(): void {
    this._store.dispatch(navigateHome());
  }

  public logout(): void {
    this._store.dispatch(new authActions.Logout());
  }

  public searchSalon(filter: string): void {
    this._store.dispatch(adminActions.searchSalons(filter));
  }

  public clearSearchSalon(): void {
    this._store.dispatch(adminActions.clearSalonFilter());
  }
}
