import { isNil } from "ramda";

interface ManufacturerLogo {
  manufacturerId: string;
  name: string;
  logoPath: string;
}

export const manufacturerLogos: ManufacturerLogo[] = [
  {
    name: "IN COMMON",
    manufacturerId: "2f1fcdeb-5834-4d11-8a50-a5b392514f37",
    logoPath: "assets/images/manufacturers/in-common-logo.png",
  },
  {
    name: "Neäl & Wølf",
    manufacturerId: "034dfee3-03b3-47f0-8f77-fe5988c4f124",
    logoPath: "assets/images/manufacturers/neäl-&-wølf-logo.png",
  },
  {
    name: "Brazilian Blowout",
    manufacturerId: "14fea536-6cd3-4774-b61c-970203b2eedf",
    logoPath: "assets/images/manufacturers/brazilian-blowout-logo.png",
  },
  {
    name: "Paul Mitchell",
    manufacturerId: "0bb9ba6d-87e5-49da-880e-4563dde8b944",
    logoPath: "assets/images/manufacturers/paul-mitchell-logo.png",
  },
  {
    name: "Pure Colour ",
    manufacturerId: "01b36dc3-3bf9-4fc3-a307-fc77c82917a0",
    logoPath: "assets/images/manufacturers/pure-colour-logo.png",
  },
  {
    name: "Lasio",
    manufacturerId: "09e87240-1099-4241-86cd-79565abddf49",
    logoPath: "assets/images/manufacturers/lasio-logo.png",
  },
  {
    name: "Pravana",
    manufacturerId: "50326f22-be99-4ecf-80b9-a2f426e3568e",
    logoPath: "assets/images/manufacturers/pravana-logo.png",
  },
  {
    name: "Robson Peluquero",
    manufacturerId: "a66e7bb2-62a3-4563-8352-51acbae942c4",
    logoPath: "assets/images/manufacturers/robson-peluquero-logo.png",
  },
  {
    name: "Balmain (Mexico)",
    manufacturerId: "b6b7a141-69f4-48ce-9bd1-cfc3d169e3b3",
    logoPath: "assets/images/manufacturers/balmain-(mexico)-logo.png",
  },
  {
    name: "REF",
    manufacturerId: "c225fb93-5e79-466f-b6b6-6561a2bcb3df",
    logoPath: "assets/images/manufacturers/ref-logo.png",
  },
  {
    name: "Goldwell",
    manufacturerId: "d110dc13-dd11-4320-bab7-5cc27f8ba4c6",
    logoPath: "assets/images/manufacturers/goldwell-logo.png",
  },
  {
    name: "Oway",
    manufacturerId: "f17fcc06-ec4b-4109-ace3-26399b81e100",
    logoPath: "assets/images/manufacturers/oway-logo.png",
  },
  {
    name: "Wolffbehr",
    manufacturerId: "8e194756-023a-4709-a7ca-306fb9c9adc4",
    logoPath: "assets/images/manufacturers/wolffbehr-logo.png",
  },
  {
    name: "Inoar Professional ",
    manufacturerId: "5c9ad8cf-0bde-4903-816a-e74962bf2b93",
    logoPath: "assets/images/manufacturers/inoar-professional-logo.png",
  },
  {
    name: "Sensus",
    manufacturerId: "a2a901f8-88a2-4033-a8d6-8779b9ee52db",
    logoPath: "assets/images/manufacturers/sensus-logo.png",
  },
  {
    name: "NXT",
    manufacturerId: "59faaf1e-333b-4d40-af01-d2efacba749d",
    logoPath: "assets/images/manufacturers/nxt-logo.png",
  },
  {
    name: "Moroccan Oil",
    manufacturerId: "4e0577e1-81c7-41e6-9f51-c454e3827b0d",
    logoPath: "assets/images/manufacturers/moroccan-oil-logo.png",
  },
  {
    name: "R + Co",
    manufacturerId: "8eaf9212-93a7-4f8c-ada9-dafb38adb10c",
    logoPath: "assets/images/manufacturers/r-+-co-logo.png",
  },
  {
    name: "Kalona",
    manufacturerId: "30231ce0-ea81-4754-b386-02831f901770",
    logoPath: "assets/images/manufacturers/kalona-logo.png",
  },
  {
    name: "Nioxin",
    manufacturerId: "66c2b228-8591-46e8-9e97-9bf28a157433",
    logoPath: "assets/images/manufacturers/nioxin-logo.png",
  },
  {
    name: "Ybera",
    manufacturerId: "eb5e881f-91d9-46ce-869f-4b814746a141",
    logoPath: "assets/images/manufacturers/ybera-logo.png",
  },
  {
    name: "Cali-Curl",
    manufacturerId: "61a8ce4a-51f6-4e03-8ba3-c2f8297db0ea",
    logoPath: "assets/images/manufacturers/cali-curl-logo.png",
  },
  {
    name: "Zuriel",
    manufacturerId: "2f571738-b1c6-4fa2-8e35-37c019e86e8f",
    logoPath: "assets/images/manufacturers/zuriel-logo.png",
  },
  {
    name: "Charcool",
    manufacturerId: "0907af4f-2fb7-4fd4-a513-acad563a0da9",
    logoPath: "assets/images/manufacturers/charcool-logo.png",
  },
  {
    name: "L'Oreal (Non-Professional)",
    manufacturerId: "9cade950-72fa-4bd5-a9d7-78ac328d552c",
    logoPath: "assets/images/manufacturers/l'oreal-(non-professional)-logo.png",
  },
  {
    name: "Guy Tang",
    manufacturerId: "1bbdc1d7-4bc0-4c10-ba13-05d8bbc687b5",
    logoPath: "assets/images/manufacturers/guy-tang-logo.png",
  },
  {
    name: "P Element",
    manufacturerId: "241a82b5-acb6-4faf-a199-9cb934d1e17a",
    logoPath: "assets/images/manufacturers/p-element-logo.png",
  },
  {
    name: "Naturia ",
    manufacturerId: "252077c7-daf9-4284-986d-65d78992e1b4",
    logoPath: "assets/images/manufacturers/naturia-logo.png",
  },
  {
    name: "Uans ",
    manufacturerId: "3b60981c-128d-4b57-9bbf-1e2fc6beb20c",
    logoPath: "assets/images/manufacturers/uans-logo.png",
  },
  {
    name: "American Crew",
    manufacturerId: "5ffaf9fb-7c0f-4685-b322-2db61ba981c7",
    logoPath: "assets/images/manufacturers/american-crew-logo.png",
  },
  {
    name: "Surface",
    manufacturerId: "636a30f1-1dfc-4eb3-82e2-749b24afef1c",
    logoPath: "assets/images/manufacturers/surface-logo.png",
  },
  {
    name: "Colo Labo",
    manufacturerId: "7e004636-d392-4707-8da7-bb77386303e6",
    logoPath: "assets/images/manufacturers/colo-labo-logo.png",
  },
  {
    name: "Kaaral",
    manufacturerId: "977ec9f9-1681-4be9-a039-f1f1864c5f3d",
    logoPath: "assets/images/manufacturers/kaaral-logo.png",
  },
  {
    name: "AntidotPro",
    manufacturerId: "b7f02714-3dbf-494d-b03b-bf05f2dc50f3",
    logoPath: "assets/images/manufacturers/antidotpro-logo.png",
  },
  {
    name: "Ardell",
    manufacturerId: "da21fbe6-bd12-4c80-ad89-aede2a5c6736",
    logoPath: "assets/images/manufacturers/ardell-logo.png",
  },
  {
    name: "Matrix",
    manufacturerId: "f7517977-7141-4ed6-a1c2-d66289d8052b",
    logoPath: "assets/images/manufacturers/matrix-logo.png",
  },
  {
    name: "Nouvelle",
    manufacturerId: "8b1e6dd3-1b95-42e0-b594-cb7ddfcbb334",
    logoPath: "assets/images/manufacturers/nouvelle-logo.png",
  },
  {
    name: "Giulietta",
    manufacturerId: "2420c0e1-f869-4443-88bd-da2c3b2bb52a",
    logoPath: "assets/images/manufacturers/giulietta-logo.png",
  },
  {
    name: "MiN New York",
    manufacturerId: "035d1093-9997-4dd7-9595-2c012fb9c37e",
    logoPath: "assets/images/manufacturers/min-new-york-logo.png",
  },
  {
    name: "Inline",
    manufacturerId: "664d9c1a-7655-43a1-b798-deccfb5d7e42",
    logoPath: "assets/images/manufacturers/inline-logo.png",
  },
  {
    name: "Stefan's Professional ",
    manufacturerId: "d70fcfe8-2de6-4749-ab08-cd8cf4bbce5e",
    logoPath: "assets/images/manufacturers/stefan's-professional-logo.png",
  },
  {
    name: "Big Professional",
    manufacturerId: "89bb8bf9-a20d-4b3c-8633-d544208060cd",
    logoPath: "assets/images/manufacturers/big-professional-logo.png",
  },
  {
    name: "JKS International",
    manufacturerId: "1eeb4450-422f-4423-9ada-68aaa64aff55",
    logoPath: "assets/images/manufacturers/jks-international-logo.png",
  },
  {
    name: "Regal USA",
    manufacturerId: "2b4bcc52-efd2-46d7-9616-4a008f41a35d",
    logoPath: "assets/images/manufacturers/regal-usa-logo.png",
  },
  {
    name: "Aston & Fincher",
    manufacturerId: "b2b94acb-055b-4049-8210-108683b1e05a",
    logoPath: "assets/images/manufacturers/aston-&-fincher-logo.png",
  },
  {
    name: "Keune",
    manufacturerId: "186a2c5b-db85-4f5d-88c3-ebbb678acced",
    logoPath: "assets/images/manufacturers/keune-logo.png",
  },
  {
    name: "SHATUSH",
    manufacturerId: "27564977-eec6-4bcb-b4d6-81889da71d3b",
    logoPath: "assets/images/manufacturers/shatush-logo.png",
  },
  {
    name: "Sunlight Balayage",
    manufacturerId: "483b753d-6319-44bf-a59d-7f84fb951614",
    logoPath: "assets/images/manufacturers/sunlight-balayage-logo.png",
  },
  {
    name: "SHATUSH OLD",
    manufacturerId: "98b5031e-9899-4b81-8fb8-0849414a8e2a",
    logoPath: "assets/images/manufacturers/shatush-old-logo.png",
  },
  {
    name: "Oligo Professional",
    manufacturerId: "9c75a804-b74e-432a-86cc-8cf5d6ae0e9d",
    logoPath: "assets/images/manufacturers/oligo-professional-logo.png",
  },
  {
    name: "MK Professional ",
    manufacturerId: "c5354ad1-0aed-4357-8867-de9e75c90c7c",
    logoPath: "assets/images/manufacturers/mk-professional-logo.png",
  },
  {
    name: "Tressa",
    manufacturerId: "dfef80d4-6924-4730-92e0-08d82e08b86e",
    logoPath: "assets/images/manufacturers/tressa-logo.png",
  },
  {
    name: "7 2 Hair",
    manufacturerId: "33ba8f79-dfcc-44f0-9f3c-554aacaded53",
    logoPath: "assets/images/manufacturers/7-2-hair-logo.png",
  },
  {
    name: "QOD Professional",
    manufacturerId: "a8a50269-c206-4a27-9128-f3c605250093",
    logoPath: "assets/images/manufacturers/qod-professional-logo.png",
  },
  {
    name: "Basic Hair Care",
    manufacturerId: "6c51c0ad-fdb7-4d1e-b395-7d3685aac427",
    logoPath: "assets/images/manufacturers/basic-hair-care-logo.png",
  },
  {
    name: "RG Cosmetics",
    manufacturerId: "330b6fb6-cda7-4603-9671-544681dbea6a",
    logoPath: "assets/images/manufacturers/rg-cosmetics-logo.png",
  },
  {
    name: "Phillip Martin's",
    manufacturerId: "9275bf32-5ac1-4c2d-a6bc-dfa156664275",
    logoPath: "assets/images/manufacturers/phillip-martin's-logo.png",
  },
  {
    name: "Color Capture",
    manufacturerId: "2fedea49-2ade-493c-a583-7380bf71ccf6",
    logoPath: "assets/images/manufacturers/color-capture-logo.png",
  },
  {
    name: "INSIGHT",
    manufacturerId: "ede8b036-1f73-4483-994a-d5fdef3ba8f9",
    logoPath: "assets/images/manufacturers/insight-logo.png",
  },
  {
    name: "Intensive",
    manufacturerId: "2c2290c3-42b7-4693-a13d-945dbf47a0be",
    logoPath: "assets/images/manufacturers/intensive-logo.png",
  },
  {
    name: "Asti",
    manufacturerId: "cef0a02d-83fd-4645-ad72-0e04a8a3e2d5",
    logoPath: "assets/images/manufacturers/asti-logo.png",
  },
  {
    name: "Fabriq",
    manufacturerId: "b5cebcf0-69ea-427e-9bdf-cd6937638842",
    logoPath: "assets/images/manufacturers/fabriq-logo.png",
  },
  {
    name: "Malibu C",
    manufacturerId: "1b2d9854-6449-44a8-b8cf-39ccc90051e1",
    logoPath: "assets/images/manufacturers/malibu-c-logo.png",
  },
  {
    name: "Powertools",
    manufacturerId: "54695227-222a-4f14-9b1b-7b9187a7bcc9",
    logoPath: "assets/images/manufacturers/powertools-logo.png",
  },
  {
    name: "Test Manufacturer",
    manufacturerId: "630f3082-b3e2-46bd-a22d-dcb732278795",
    logoPath: "assets/images/manufacturers/test-manufacturer-logo.png",
  },
  {
    name: "L'anza",
    manufacturerId: "9f72520e-ae5b-4e1a-94fc-ea0386ed2cf2",
    logoPath: "assets/images/manufacturers/l'anza-logo.png",
  },
  {
    name: "Phyto",
    manufacturerId: "bed63ff3-35ba-4342-83c7-9adf4a2f6de9",
    logoPath: "assets/images/manufacturers/phyto-logo.png",
  },
  {
    name: "Evo",
    manufacturerId: "ef774e92-3e4a-4665-ab13-8b7800f74f31",
    logoPath: "assets/images/manufacturers/evo-logo.png",
  },
  {
    name: "Blonde Solutions ",
    manufacturerId: "85ec2814-0ca1-48dc-bd8d-a2a6b275e33a",
    logoPath: "assets/images/manufacturers/blonde-solutions-logo.png",
  },
  {
    name: "Ultimate",
    manufacturerId: "c72d1313-fbf2-4269-85d9-d22f102fe587",
    logoPath: "assets/images/manufacturers/ultimate-logo.png",
  },
  {
    name: "Delete",
    manufacturerId: "75062548-122c-4cd1-873b-1da1c99193c0",
    logoPath: "assets/images/manufacturers/delete-logo.png",
  },
  {
    name: "Kode Professional",
    manufacturerId: "00336ad6-aaf1-4c1c-84b5-3f6dc88f9f71",
    logoPath: "assets/images/manufacturers/kode-professional-logo.png",
  },
  {
    name: "Design Essentials",
    manufacturerId: "ea2aa074-fbc7-4859-9db3-0861126ff49c",
    logoPath: "assets/images/manufacturers/design-essentials-logo.png",
  },
  {
    name: "Zero",
    manufacturerId: "26dd5768-bca2-40cd-891d-cc06af4b3567",
    logoPath: "assets/images/manufacturers/zero-logo.png",
  },
  {
    name: "Kemon",
    manufacturerId: "6008aed2-c9af-487c-ba57-89fc5c960d80",
    logoPath: "assets/images/manufacturers/kemon-logo.png",
  },
  {
    name: "Fanola",
    manufacturerId: "c28a554e-179f-4eb6-b4ef-5ddb6446a491",
    logoPath: "assets/images/manufacturers/fanola-logo.png",
  },
  {
    name: "Organethic",
    manufacturerId: "15b78d46-d6ac-4642-845a-642e2c53b0e6",
    logoPath: "assets/images/manufacturers/organethic-logo.png",
  },
  {
    name: "Mondo Verde",
    manufacturerId: "e26613a7-1235-4eb1-9f24-d122633b0347",
    logoPath: "assets/images/manufacturers/mondo-verde-logo.png",
  },
  {
    name: "ProCare",
    manufacturerId: "28de5753-1776-4c4b-b9c0-23d08e39c134",
    logoPath: "assets/images/manufacturers/procare-logo.png",
  },
  {
    name: "Kitoko ",
    manufacturerId: "fc1c00eb-95c9-42e1-a888-877b74ca44fb",
    logoPath: "assets/images/manufacturers/kitoko-logo.png",
  },
  {
    name: "poly 8",
    manufacturerId: "6d6babb7-8b18-47d6-adb8-db23797bcce5",
    logoPath: "assets/images/manufacturers/poly-8-logo.png",
  },
  {
    name: "ProLific",
    manufacturerId: "0ca1d26a-8ca0-4caa-8042-5dfe1113c8a9",
    logoPath: "assets/images/manufacturers/prolific-logo.png",
  },
  {
    name: "Innoluxe",
    manufacturerId: "107a242f-935c-4750-91e6-ba200dca0093",
    logoPath: "assets/images/manufacturers/innoluxe-logo.png",
  },
  {
    name: "Lakme",
    manufacturerId: "23910adb-7ecc-4b38-afc9-83418f1025ee",
    logoPath: "assets/images/manufacturers/lakme-logo.png",
  },
  {
    name: "Misc/Extras",
    manufacturerId: "47102d46-403c-47fa-8dd9-dc1a80d7c81f",
    logoPath: "assets/images/manufacturers/misc/extras-logo.png",
  },
  {
    name: "Olaplex",
    manufacturerId: "8d12edc0-688d-4bb2-ad97-8f7a54a7933d",
    logoPath: "assets/images/manufacturers/olaplex-logo.png",
  },
  {
    name: "Milbon – Sophistone",
    manufacturerId: "d6e4c52c-a54b-4ee3-9893-ce5b6471706b",
    logoPath: "assets/images/manufacturers/milbon-–-sophistone-logo.png",
  },
  {
    name: "Liquid Keratin",
    manufacturerId: "01974348-ce20-4ff6-ab1f-aeca548b998a",
    logoPath: "assets/images/manufacturers/liquid-keratin-logo.png",
  },
  {
    name: "A.S.P Luxury Haircare",
    manufacturerId: "e26aaeb8-0985-420d-8e79-7b90eb9af889",
    logoPath: "assets/images/manufacturers/a.s.p-luxury-haircare-logo.png",
  },
  {
    name: "QiQi",
    manufacturerId: "5469c599-c056-43b0-891b-8647fe1d0e78",
    logoPath: "assets/images/manufacturers/qiqi-logo.png",
  },
  {
    name: "No Inhibition",
    manufacturerId: "f1b630f7-b39b-48c8-84c6-fac970f56a27",
    logoPath: "assets/images/manufacturers/no-inhibition-logo.png",
  },
  {
    name: "Duomo",
    manufacturerId: "b0a4fca7-ef71-4963-af87-3ec86b8dda6e",
    logoPath: "assets/images/manufacturers/duomo-logo.png",
  },
  {
    name: "Masters of Balayage",
    manufacturerId: "6a637398-eff4-4c58-9d54-d2f2d49ed279",
    logoPath: "assets/images/manufacturers/masters-of-balayage-logo.png",
  },
  {
    name: "Simply Smooth",
    manufacturerId: "e65a7fdf-840a-4165-b013-9e5a9b1da700",
    logoPath: "assets/images/manufacturers/simply-smooth-logo.png",
  },
  {
    name: "Chromastics",
    manufacturerId: "75e3afc8-db57-4e88-a2f2-dd7dc2cea8ed",
    logoPath: "assets/images/manufacturers/chromastics-logo.png",
  },
  {
    name: "Redken",
    manufacturerId: "8b08cb7a-31af-4fde-b05c-27b7e756d684",
    logoPath: "assets/images/manufacturers/redken-logo.png",
  },
  {
    name: "Subtil",
    manufacturerId: "b2106f4a-8c86-494a-a612-6ae4189ce4bd",
    logoPath: "assets/images/manufacturers/subtil-logo.png",
  },
  {
    name: "Affinage",
    manufacturerId: "c125e157-1d76-4eb4-851f-51ed4f8dd245",
    logoPath: "assets/images/manufacturers/affinage-logo.png",
  },
  {
    name: "Osmo Ikon",
    manufacturerId: "d911793a-5774-4f1c-98c5-68fb737deb8d",
    logoPath: "assets/images/manufacturers/osmo-ikon-logo.png",
  },
  {
    name: "Leaf and Flower",
    manufacturerId: "69d26750-32cf-404f-962a-1b743a3f1d03",
    logoPath: "assets/images/manufacturers/leaf-and-flower-logo.png",
  },
  {
    name: "Color Space",
    manufacturerId: "e3ee986e-e171-4623-9041-361c7a376723",
    logoPath: "assets/images/manufacturers/color-space-logo.png",
  },
  {
    name: "Get Pure",
    manufacturerId: "e4e58e35-3abf-431d-af43-846c9a078057",
    logoPath: "assets/images/manufacturers/get-pure-logo.png",
  },
  {
    name: "IStraight",
    manufacturerId: "5dd2bd5a-e2b8-440d-aa65-4fd3ae8dc18f",
    logoPath: "assets/images/manufacturers/istraight-logo.png",
  },
  {
    name: "Sim",
    manufacturerId: "7be800a3-957a-4282-9d58-f4ea1d48c851",
    logoPath: "assets/images/manufacturers/sim-logo.png",
  },
  {
    name: "Biotop",
    manufacturerId: "117fd5dd-0ed5-46b7-b684-9c6022d29c07",
    logoPath: "assets/images/manufacturers/biotop-logo.png",
  },
  {
    name: "Discov Hair",
    manufacturerId: "ac088ea2-47c2-4607-bde5-8a241a8e8f3c",
    logoPath: "assets/images/manufacturers/discov-hair-logo.png",
  },
  {
    name: "Base",
    manufacturerId: "c320d4ef-45bd-4417-b8e9-8850be9dc69f",
    logoPath: "assets/images/manufacturers/base-logo.png",
  },
  {
    name: "Generic Color Line ",
    manufacturerId: "0f5a5775-fa7d-4051-baa6-483772a339dd",
    logoPath: "assets/images/manufacturers/generic-color-line-logo.png",
  },
  {
    name: "Iroiro",
    manufacturerId: "39bf035d-901a-4e5b-ad41-b5ea23a98d9d",
    logoPath: "assets/images/manufacturers/iroiro-logo.png",
  },
  {
    name: "milk_shake",
    manufacturerId: "18e3c36e-3890-4f3b-90b2-45396ba64fdb",
    logoPath: "assets/images/manufacturers/milk_shake-logo.png",
  },
  {
    name: "Dikson",
    manufacturerId: "287cf1ff-ccf6-4348-8918-67fb44164b01",
    logoPath: "assets/images/manufacturers/dikson-logo.png",
  },
  {
    name: "Wella UK",
    manufacturerId: "14bdbf43-9e9d-4a07-8047-1fda10ebb708",
    logoPath: "assets/images/manufacturers/wella-uk-logo.png",
  },
  {
    name: "Leyton House Professional ",
    manufacturerId: "443b9c4c-7ccf-4798-ba50-fb844f99495a",
    logoPath: "assets/images/manufacturers/leyton-house-professional-logo.png",
  },
  {
    name: "Mizani",
    manufacturerId: "5aa1dd1d-d546-425b-91a8-92b7f7fbd0ef",
    logoPath: "assets/images/manufacturers/mizani-logo.png",
  },
  {
    name: "O & M",
    manufacturerId: "6df305b9-4b54-4c69-870b-d1252cb80684",
    logoPath: "assets/images/manufacturers/o-&-m-logo.png",
  },
  {
    name: "Aloxxi",
    manufacturerId: "96e58c85-9421-40a3-80f7-ccc066db4d34",
    logoPath: "assets/images/manufacturers/aloxxi-logo.png",
  },
  {
    name: "Kenra Color",
    manufacturerId: "e19c9004-94fd-4ee0-81ac-0de1587cffe0",
    logoPath: "assets/images/manufacturers/kenra-color-logo.png",
  },
  {
    name: "Kadus Professional",
    manufacturerId: "f623aede-2ced-4cf7-9a4f-8e2651950d0e",
    logoPath: "assets/images/manufacturers/kadus-professional-logo.png",
  },
  {
    name: "MUK",
    manufacturerId: "df5dac1d-7e86-4615-84de-b071fde5f83b",
    logoPath: "assets/images/manufacturers/muk-logo.png",
  },
  {
    name: "Kérastase",
    manufacturerId: "0e8c1d23-0a85-4cc1-816d-8de078e3d838",
    logoPath: "assets/images/manufacturers/kérastase-logo.png",
  },
  {
    name: "Serie Expert",
    manufacturerId: "06449c4e-036f-45f3-8ee8-4ce432db5eec",
    logoPath: "assets/images/manufacturers/serie-expert-logo.png",
  },
  {
    name: "AGI One",
    manufacturerId: "9cc119d4-a2df-4639-8f24-1f70b48dc1e7",
    logoPath: "assets/images/manufacturers/agi-one-logo.png",
  },
  {
    name: "InColor",
    manufacturerId: "a719d69b-756c-4e77-a416-2b37211f803d",
    logoPath: "assets/images/manufacturers/incolor-logo.png",
  },
  {
    name: "Nak",
    manufacturerId: "936f95ee-31e3-4f3e-87f9-fe46a0c304d7",
    logoPath: "assets/images/manufacturers/nak-logo.png",
  },
  {
    name: "UnDo",
    manufacturerId: "3b38a0ba-9c6c-4241-81d3-f91e386255ac",
    logoPath: "assets/images/manufacturers/undo-logo.png",
  },
  {
    name: "Kente",
    manufacturerId: "048c8eff-0604-40bb-8bd0-3e883a6e1e56",
    logoPath: "assets/images/manufacturers/kente-logo.png",
  },
  {
    name: "Quif",
    manufacturerId: "6205afce-d032-4413-be8a-29de5d6200b3",
    logoPath: "assets/images/manufacturers/quif-logo.png",
  },
  {
    name: "Capital Hair & Beauty",
    manufacturerId: "f3b9a532-2e33-4b24-94aa-fefd3cae6469",
    logoPath: "assets/images/manufacturers/capital-hair-&-beauty-logo.png",
  },
  {
    name: "Maxima",
    manufacturerId: "582ba5e0-8ba6-4dfd-9926-cea8c73c1a7c",
    logoPath: "assets/images/manufacturers/maxima-logo.png",
  },
  {
    name: "Screen ",
    manufacturerId: "3bc5eccb-29a0-4bb9-9358-0435c0798f18",
    logoPath: "assets/images/manufacturers/screen-logo.png",
  },
  {
    name: "Davines",
    manufacturerId: "524ca2c1-d626-4c52-84c8-a27c01599271",
    logoPath: "assets/images/manufacturers/davines-logo.png",
  },
  {
    name: "Alter Ego ",
    manufacturerId: "6d8656e7-6d77-4461-93ca-b90cc2234427",
    logoPath: "assets/images/manufacturers/alter-ego-logo.png",
  },
  {
    name: "Joico",
    manufacturerId: "7b431624-165f-4061-a63b-5f5371e458e4",
    logoPath: "assets/images/manufacturers/joico-logo.png",
  },
  {
    name: "Kevin Murphy",
    manufacturerId: "b6c359b9-e0f0-4bd2-be14-93b032a5b24e",
    logoPath: "assets/images/manufacturers/kevin-murphy-logo.png",
  },
  {
    name: "Framesi ",
    manufacturerId: "c9e90753-7b52-4bc8-ab51-ce87389dde31",
    logoPath: "assets/images/manufacturers/framesi-logo.png",
  },
  {
    name: "TIGI",
    manufacturerId: "d21a95c8-b945-4a17-8dc1-0b64262f05c7",
    logoPath: "assets/images/manufacturers/tigi-logo.png",
  },
  {
    name: "Mowan",
    manufacturerId: "ef04cf74-c6d8-4e5e-b8cb-cdcfbfb3de21",
    logoPath: "assets/images/manufacturers/mowan-logo.png",
  },
  {
    name: "Luxury",
    manufacturerId: "f62f087c-e286-48e3-a659-dcc02bbff316",
    logoPath: "assets/images/manufacturers/luxury-logo.png",
  },
  {
    name: "Color Design",
    manufacturerId: "f8b73d5b-d050-4e9e-bec4-0a9adf090f8a",
    logoPath: "assets/images/manufacturers/color-design-logo.png",
  },
  {
    name: "Eleven Colour ",
    manufacturerId: "0091389a-da22-4bbb-ba63-e0ac7fcfe978",
    logoPath: "assets/images/manufacturers/eleven-colour-logo.png",
  },
  {
    name: "Oribe ",
    manufacturerId: "e5b3eb00-2984-4adb-a2ac-1dc828066c69",
    logoPath: "assets/images/manufacturers/oribe-logo.png",
  },
  {
    name: "Silky",
    manufacturerId: "23ed0a14-6570-4590-b0bf-8d29d64a680e",
    logoPath: "assets/images/manufacturers/silky-logo.png",
  },
  {
    name: "Truzone",
    manufacturerId: "700fe64f-9fe1-428d-8764-6c688f0eb30b",
    logoPath: "assets/images/manufacturers/truzone-logo.png",
  },
  {
    name: "Pure Brazillian",
    manufacturerId: "b1dbc7a2-9d7f-4440-9021-67694ae7eb79",
    logoPath: "assets/images/manufacturers/pure-brazillian-logo.png",
  },
  {
    name: "Adore",
    manufacturerId: "fd87e3ed-cf5c-401a-bc08-ae1d9b6df597",
    logoPath: "assets/images/manufacturers/adore-logo.png",
  },
  {
    name: "FFØR",
    manufacturerId: "ca5448a8-f636-4c25-b684-59e47933e510",
    logoPath: "assets/images/manufacturers/ffør-logo.png",
  },
  {
    name: "Divina",
    manufacturerId: "02a5ca06-4387-47e3-87c2-fe29a464a43f",
    logoPath: "assets/images/manufacturers/divina-logo.png",
  },
  {
    name: "Hair Forensic Laboratories",
    manufacturerId: "42477cc7-4a60-4a09-9cee-2afa2ce062de",
    logoPath: "assets/images/manufacturers/hair-forensic-laboratories-logo.png",
  },
  {
    name: "Satin",
    manufacturerId: "62999348-1a64-4ff4-8abd-ae0dd324f831",
    logoPath: "assets/images/manufacturers/satin-logo.png",
  },
  {
    name: "Carlo Oliveri",
    manufacturerId: "80c9cdeb-577d-4eb7-9a3e-1c5a07d7d53b",
    logoPath: "assets/images/manufacturers/carlo-oliveri-logo.png",
  },
  {
    name: "Wella",
    manufacturerId: "1da4d6bb-a7e7-4a85-a821-66ce443772a5",
    logoPath: "assets/images/manufacturers/wella-logo.png",
  },
  {
    name: "REVLON Professional ",
    manufacturerId: "2b9800ca-4d4c-4644-94bb-aac2ee85278b",
    logoPath: "assets/images/manufacturers/revlon-professional-logo.png",
  },
  {
    name: "Agave Oil ",
    manufacturerId: "3301eb4b-4a70-4829-8662-30dc2fdf4f8e",
    logoPath: "assets/images/manufacturers/agave-oil-logo.png",
  },
  {
    name: "Trionics",
    manufacturerId: "75972a4f-4b2a-44c0-b750-90a9adbb21d8",
    logoPath: "assets/images/manufacturers/trionics-logo.png",
  },
  {
    name: "Peter Coppola",
    manufacturerId: "a125cee9-1905-448c-987a-6d5f77215652",
    logoPath: "assets/images/manufacturers/peter-coppola-logo.png",
  },
  {
    name: "L'Oreal UK",
    manufacturerId: "a7d56205-0f4b-4d16-8c00-dc9e39f32930",
    logoPath: "assets/images/manufacturers/l'oreal-uk-logo.png",
  },
  {
    name: "Revlon Professional (DELETE)",
    manufacturerId: "b11a151e-7812-4fe7-9336-da4dcfad651b",
    logoPath: "assets/images/manufacturers/revlon-professional-(delete)-logo.png",
  },
  {
    name: "Eufora",
    manufacturerId: "d34e88e1-8e94-4b1b-866e-aa78143e2473",
    logoPath: "assets/images/manufacturers/eufora-logo.png",
  },
  {
    name: "Pureology",
    manufacturerId: "b2f77cef-9842-4a92-90a3-6ac15c809484",
    logoPath: "assets/images/manufacturers/pureology-logo.png",
  },
  {
    name: "Refectocil",
    manufacturerId: "8c0e9e61-ac80-45d5-8b5c-74e6bc7c11e6",
    logoPath: "assets/images/manufacturers/refectocil-logo.png",
  },
  {
    name: "S4",
    manufacturerId: "59d15517-b54d-44ed-8acc-640548d1c664",
    logoPath: "assets/images/manufacturers/s4-logo.png",
  },
  {
    name: "K18",
    manufacturerId: "94cbc4f2-663d-4969-9676-58029dce276e",
    logoPath: "assets/images/manufacturers/k18-logo.png",
  },
  {
    name: "Lasaunt",
    manufacturerId: "51d3b23b-9cc0-4c37-b9fe-a571e429cebc",
    logoPath: "assets/images/manufacturers/lasaunt-logo.png",
  },
  {
    name: "Scruples ",
    manufacturerId: "46cbfb61-bcf0-435c-b3ad-5f5474dd5d9a",
    logoPath: "assets/images/manufacturers/scruples-logo.png",
  },
  {
    name: "Truss Professional",
    manufacturerId: "88c3ccda-4f47-4bc0-aa5d-d82afec0b459",
    logoPath: "assets/images/manufacturers/truss-professional-logo.png",
  },
  {
    name: "Artego Color",
    manufacturerId: "aaecc7c2-c33b-4570-a765-28bf5bc21b61",
    logoPath: "assets/images/manufacturers/artego-color-logo.png",
  },
  {
    name: "Tutto Colors",
    manufacturerId: "c1ffe1c5-02d4-4756-8616-637012f75b33",
    logoPath: "assets/images/manufacturers/tutto-colors-logo.png",
  },
  {
    name: "Trissola",
    manufacturerId: "dd1bf58f-ad8c-4cb0-a113-410f9c158fe7",
    logoPath: "assets/images/manufacturers/trissola-logo.png",
  },
  {
    name: "BIOLAGE haircolor",
    manufacturerId: "f841d782-21ae-4b31-aba0-0ff992408b30",
    logoPath: "assets/images/manufacturers/biolage-haircolor-logo.png",
  },
  {
    name: "Sebastian Professional",
    manufacturerId: "b4a82045-b291-4d65-86d8-9859bfcc471a",
    logoPath: "assets/images/manufacturers/sebastian-professional-logo.png",
  },
  {
    name: "Marcia Teixeira",
    manufacturerId: "5bb313e3-527b-4f5d-bcb8-bfae8521048a",
    logoPath: "assets/images/manufacturers/marcia-teixeira-logo.png",
  },
  {
    name: "id Hair",
    manufacturerId: "6fdb43a7-4e60-42ae-94b2-1ecc4830439c",
    logoPath: "assets/images/manufacturers/id-hair-logo.png",
  },
  {
    name: "Glynt",
    manufacturerId: "334438a5-a249-46fc-8e50-50edf49ef2a0",
    logoPath: "assets/images/manufacturers/glynt-logo.png",
  },
  {
    name: "Plastica Dos Fios",
    manufacturerId: "96c35386-a1b3-468e-9ec1-e0d290ca9400",
    logoPath: "assets/images/manufacturers/plastica-dos-fios-logo.png",
  },
  {
    name: "Inca Glow",
    manufacturerId: "797acae2-7657-4f25-98c0-ed5f190fd0d0",
    logoPath: "assets/images/manufacturers/inca-glow-logo.png",
  },
  {
    name: "Virtue",
    manufacturerId: "2261a607-a771-4010-bea2-3881aec86231",
    logoPath: "assets/images/manufacturers/virtue-logo.png",
  },
  {
    name: "Inova",
    manufacturerId: "71dc005c-0ad9-40c2-8c72-197906274f4a",
    logoPath: "assets/images/manufacturers/inova-logo.png",
  },
  {
    name: "Sparks",
    manufacturerId: "77c9df89-4fa7-4708-8b6d-3ab7394b2ca0",
    logoPath: "assets/images/manufacturers/sparks-logo.png",
  },
  {
    name: "Advantage",
    manufacturerId: "b4f8e068-5cf2-4736-9514-5f3fc9f97eec",
    logoPath: "assets/images/manufacturers/advantage-logo.png",
  },
  {
    name: "Manic Panic",
    manufacturerId: "cac6d82d-aaa1-4f1b-b831-70a5e5892733",
    logoPath: "assets/images/manufacturers/manic-panic-logo.png",
  },
  {
    name: "Lumar ",
    manufacturerId: "e6be5614-65d3-4066-b46f-a62a2323751c",
    logoPath: "assets/images/manufacturers/lumar-logo.png",
  },
  {
    name: "Crazy Color",
    manufacturerId: "c85dd0c4-b1d4-4c6d-97ad-617095763c72",
    logoPath: "assets/images/manufacturers/crazy-color-logo.png",
  },
  {
    name: "Advance Pro",
    manufacturerId: "235b8d1c-9b12-421f-aa93-659efae66cc9",
    logoPath: "assets/images/manufacturers/advance-pro-logo.png",
  },
  {
    name: "California Glam",
    manufacturerId: "1d16fe92-91ec-43a7-a1cd-dbbabbcec3c7",
    logoPath: "assets/images/manufacturers/california-glam-logo.png",
  },
  {
    name: "B24",
    manufacturerId: "85065405-8401-420c-9f25-2e745974df2e",
    logoPath: "assets/images/manufacturers/b24-logo.png",
  },
  {
    name: "Mounir",
    manufacturerId: "665e39d9-9114-490a-a7c5-98c496886829",
    logoPath: "assets/images/manufacturers/mounir-logo.png",
  },
  {
    name: "TeaTree",
    manufacturerId: "5954d7c7-3077-497e-b2e2-f2a5d06c660e",
    logoPath: "assets/images/manufacturers/teatree-logo.png",
  },
  {
    name: "Eva Professional",
    manufacturerId: "772ab87f-5e2a-4121-b5aa-2bb2f2629206",
    logoPath: "assets/images/manufacturers/eva-professional-logo.png",
  },
  {
    name: "Hi Lift",
    manufacturerId: "69c9e934-7d92-4059-bdd3-b4e4f586f4f3",
    logoPath: "assets/images/manufacturers/hi-lift-logo.png",
  },
  {
    name: "Saach Organics",
    manufacturerId: "0837570b-e94c-4b95-b383-8da75de2a2b4",
    logoPath: "assets/images/manufacturers/saach-organics-logo.png",
  },
  {
    name: "LA BIOSTHETIQUE",
    manufacturerId: "1b7230e1-1035-496a-bca0-352b20e91843",
    logoPath: "assets/images/manufacturers/la-biosthetique-logo.png",
  },
  {
    name: "Cezanne",
    manufacturerId: "2f3afd8b-6c73-442e-ab70-49979ae9247a",
    logoPath: "assets/images/manufacturers/cezanne-logo.png",
  },
  {
    name: "Whittemore House Hair Paint",
    manufacturerId: "499f726f-db33-48b3-bc3d-a2a5b46cc698",
    logoPath: "assets/images/manufacturers/whittemore-house-hair-paint-logo.png",
  },
  {
    name: "Clairol",
    manufacturerId: "78494e23-2178-4779-aa34-9e3504060f0d",
    logoPath: "assets/images/manufacturers/clairol-logo.png",
  },
  {
    name: "Keratherapy ",
    manufacturerId: "c315c272-43ba-40f7-938a-49129fe91791",
    logoPath: "assets/images/manufacturers/keratherapy-logo.png",
  },
  {
    name: "evo fabuloso",
    manufacturerId: "c500ae4b-42c1-4c45-ad2d-2930d0c2022f",
    logoPath: "assets/images/manufacturers/evo-fabuloso-logo.png",
  },
  {
    name: "KeraStraight",
    manufacturerId: "cfc4e508-9958-4d9f-9ee6-14a602fe5030",
    logoPath: "assets/images/manufacturers/kerastraight-logo.png",
  },
  {
    name: "Magic Lightener",
    manufacturerId: "d7560128-e844-4d9e-b86c-7caf5e46e178",
    logoPath: "assets/images/manufacturers/magic-lightener-logo.png",
  },
  {
    name: "BBcos Hair Pro",
    manufacturerId: "e2a56aa4-0e88-40c5-9d18-1d8201da868e",
    logoPath: "assets/images/manufacturers/bbcos-hair-pro-logo.png",
  },
  {
    name: "Joico Lightener",
    manufacturerId: "fefa53e3-8740-4d5d-83b7-1c20bb844186",
    logoPath: "assets/images/manufacturers/joico-lightener-logo.png",
  },
  {
    name: "Jack Winn",
    manufacturerId: "512760f0-a5bb-4b3a-847d-e904d65aae82",
    logoPath: "assets/images/manufacturers/jack-winn-logo.png",
  },
  {
    name: "Uberliss",
    manufacturerId: "2981c934-b372-4cf7-b878-9174cd812b8a",
    logoPath: "assets/images/manufacturers/uberliss-logo.png",
  },
  {
    name: "Chi",
    manufacturerId: "dd2b5f1b-1cf4-40d2-94e5-62a8d590a7ed",
    logoPath: "assets/images/manufacturers/chi-logo.png",
  },
  {
    name: "Curl Cult",
    manufacturerId: "e3bc53c5-85ce-4d48-89aa-b49e7f19bba8",
    logoPath: "assets/images/manufacturers/curl-cult-logo.png",
  },
  {
    name: "My Vitamins",
    manufacturerId: "9358d02d-026a-4234-8885-8289e6963fa5",
    logoPath: "assets/images/manufacturers/my-vitamins-logo.png",
  },
  {
    name: "Velvapro",
    manufacturerId: "0607d336-47df-43a2-bda8-c9eab535eccc",
    logoPath: "assets/images/manufacturers/velvapro-logo.png",
  },
  {
    name: "Kashmir Keratin",
    manufacturerId: "3a33173c-9039-49e0-8bde-03e0a96f57ac",
    logoPath: "assets/images/manufacturers/kashmir-keratin-logo.png",
  },
  {
    name: "Logona",
    manufacturerId: "232b114a-9449-4325-a25b-9ce879ab90f9",
    logoPath: "assets/images/manufacturers/logona-logo.png",
  },
  {
    name: "All-Nutrient",
    manufacturerId: "17183f45-fb96-4f91-af10-dd40ec855812",
    logoPath: "assets/images/manufacturers/all-nutrient-logo.png",
  },
  {
    name: "J Beverly Hills",
    manufacturerId: "2c05effb-d195-4384-9dce-a037ba32fffe",
    logoPath: "assets/images/manufacturers/j-beverly-hills-logo.png",
  },
  {
    name: "GK Hair",
    manufacturerId: "361153d5-f0fd-4b50-bb93-645195ae2204",
    logoPath: "assets/images/manufacturers/gk-hair-logo.png",
  },
  {
    name: "Simply Organic",
    manufacturerId: "369649b6-8853-42fd-b787-aefb4ff9f861",
    logoPath: "assets/images/manufacturers/simply-organic-logo.png",
  },
  {
    name: "ORGANIC COLOUR SYSTEMS",
    manufacturerId: "8422c5d1-dcd9-4af7-b708-14c0dc8b95c6",
    logoPath: "assets/images/manufacturers/organic-colour-systems-logo.png",
  },
  {
    name: "Royal Botox",
    manufacturerId: "c460723f-9a55-446b-b98a-cd4beddb3f53",
    logoPath: "assets/images/manufacturers/royal-botox-logo.png",
  },
  {
    name: "Tints of Nature",
    manufacturerId: "c613564d-3b87-44ba-a22e-f0f97621f892",
    logoPath: "assets/images/manufacturers/tints-of-nature-logo.png",
  },
  {
    name: "Pure NV",
    manufacturerId: "7cc36981-3a3a-4f5b-9b93-e1084bbfdbfa",
    logoPath: "assets/images/manufacturers/pure-nv-logo.png",
  },
  {
    name: "Felps",
    manufacturerId: "d5cf953d-1b8c-4c90-8a4a-8994665f14bc",
    logoPath: "assets/images/manufacturers/felps-logo.png",
  },
  {
    name: "Oya",
    manufacturerId: "b0ab3425-00b1-422e-be43-bad42ebeae67",
    logoPath: "assets/images/manufacturers/oya-logo.png",
  },
  {
    name: "Keratin Revolution",
    manufacturerId: "7817c771-c276-4a62-b834-d067579a0b0f",
    logoPath: "assets/images/manufacturers/keratin-revolution-logo.png",
  },
  {
    name: "Keratin Express",
    manufacturerId: "83722899-4204-43eb-95c2-9d8b845f6d33",
    logoPath: "assets/images/manufacturers/keratin-express-logo.png",
  },
  {
    name: "Lisap",
    manufacturerId: "855f6987-2c2e-4547-9c6f-886362e7411f",
    logoPath: "assets/images/manufacturers/lisap-logo.png",
  },
  {
    name: "Eugene Perma",
    manufacturerId: "77a60494-1236-4874-968d-b6826018b2bc",
    logoPath: "assets/images/manufacturers/eugene-perma-logo.png",
  },
  {
    name: "Balmain UK",
    manufacturerId: "7d9cd075-7243-43f5-8784-1631224a1a85",
    logoPath: "assets/images/manufacturers/balmain-uk-logo.png",
  },
  {
    name: "ISO",
    manufacturerId: "8b7b083f-7623-41f0-b6cf-3de43f48e286",
    logoPath: "assets/images/manufacturers/iso-logo.png",
  },
  {
    name: "Schwarzkopf",
    manufacturerId: "a905aa6b-0632-46da-b4d9-fc74b55e3fbe",
    logoPath: "assets/images/manufacturers/schwarzkopf-logo.png",
  },
  {
    name: "Alfaparf Milano",
    manufacturerId: "ace5ae16-710a-4065-b4e2-9d531bae8693",
    logoPath: "assets/images/manufacturers/alfaparf-milano-logo.png",
  },
  {
    name: "Aveda",
    manufacturerId: "d6d93a32-c1c6-4d7a-9c02-195bcbc75deb",
    logoPath: "assets/images/manufacturers/aveda-logo.png",
  },
  {
    name: "Difiaba Color",
    manufacturerId: "d98128d1-11c9-4e85-8612-cce7657328b3",
    logoPath: "assets/images/manufacturers/difiaba-color-logo.png",
  },
  {
    name: "Magic Sleek",
    manufacturerId: "da031d23-cdce-4cc4-a9e2-1da5476d9b62",
    logoPath: "assets/images/manufacturers/magic-sleek-logo.png",
  },
  {
    name: "MUK (remove)",
    manufacturerId: "63f118d7-6136-4650-be1f-e52c8ab69c84",
    logoPath: "assets/images/manufacturers/muk-(remove)-logo.png",
  },
  {
    name: "Arctic Fox",
    manufacturerId: "fed0a121-76dd-448f-bce6-416edb408732",
    logoPath: "assets/images/manufacturers/arctic-fox-logo.png",
  },
  {
    name: "Yellow",
    manufacturerId: "a06de799-6e19-419f-85c1-6db088c6a62f",
    logoPath: "assets/images/manufacturers/yellow-logo.png",
  },
  {
    name: "CocoChoco",
    manufacturerId: "73c64503-d16f-4e30-91a9-d3b604a20aa3",
    logoPath: "assets/images/manufacturers/cocochoco-logo.png",
  },
  {
    name: "Affirm",
    manufacturerId: "6401ee5b-52d5-4a83-a65e-daba5ea2cf5f",
    logoPath: "assets/images/manufacturers/affirm-logo.png",
  },
  {
    name: "Kincreme",
    manufacturerId: "9623a127-e678-4f7d-81cb-15d631379ad3",
    logoPath: "assets/images/manufacturers/kincreme-logo.png",
  },
  {
    name: "Sassoon",
    manufacturerId: "04427f3a-d387-4580-9a47-f81c6d908427",
    logoPath: "assets/images/manufacturers/sassoon-logo.png",
  },
  {
    name: "Bellami Professional",
    manufacturerId: "759386c6-f8ce-444d-8b20-b66cd632b8be",
    logoPath: "assets/images/manufacturers/bellami-professional-logo.png",
  },
  {
    name: "Elleebana",
    manufacturerId: "911ee2c1-6466-4a0b-bb0a-161fb448ee30",
    logoPath: "assets/images/manufacturers/elleebana-logo.png",
  },
  {
    name: "Depot",
    manufacturerId: "c05461a4-7ac6-43aa-b991-dc73895af083",
    logoPath: "assets/images/manufacturers/depot-logo.png",
  },
  {
    name: "Hair Bar NYC",
    manufacturerId: "ac13ff0d-80c3-48da-bb98-e6a49915d10c",
    logoPath: "assets/images/manufacturers/hair-bar-nyc-logo.png",
  },
  {
    name: "Bigen",
    manufacturerId: "da201e50-936d-4a86-ac2f-19a1396727a1",
    logoPath: "assets/images/manufacturers/bigen-logo.png",
  },
  {
    name: "Pulp Riot",
    manufacturerId: "2462bfe6-f874-4f94-a896-9169691ccc8a",
    logoPath: "assets/images/manufacturers/pulp-riot-logo.png",
  },
  {
    name: "Natulique",
    manufacturerId: "72c318ba-cae4-41b3-85e6-0f8d261e8579",
    logoPath: "assets/images/manufacturers/natulique-logo.png",
  },
  {
    name: "BES",
    manufacturerId: "745d06c6-3bac-41a8-acf2-b106a832b2b3",
    logoPath: "assets/images/manufacturers/bes-logo.png",
  },
  {
    name: "Fanola ",
    manufacturerId: "8b30c52b-1377-471a-8ed1-4aaac057d1e8",
    logoPath: "assets/images/manufacturers/fanola-logo.png",
  },
  {
    name: "Options NFH",
    manufacturerId: "b891a3d2-51e9-4659-a5d4-510e5aa8e4e8",
    logoPath: "assets/images/manufacturers/options-nfh-logo.png",
  },
  {
    name: "Trillion Tones",
    manufacturerId: "bbafb4b9-aff7-4b8d-80a2-e17f645f6915",
    logoPath: "assets/images/manufacturers/trillion-tones-logo.png",
  },
  {
    name: "Eco Style",
    manufacturerId: "a6f28857-0780-4e19-b71b-f05237bf0f73",
    logoPath: "assets/images/manufacturers/eco-style-logo.png",
  },
  {
    name: "La Brasiliana",
    manufacturerId: "d9389752-eb57-4461-8bb3-ccc7d825be92",
    logoPath: "assets/images/manufacturers/la-brasiliana-logo.png",
  },
  {
    name: "Eternity Liss",
    manufacturerId: "6ec32223-200f-48e6-a96c-d39dcae9f98b",
    logoPath: "assets/images/manufacturers/eternity-liss-logo.png",
  },
  {
    name: "Keratinworks",
    manufacturerId: "288e7fb5-ae79-4b7d-87cf-73e331f70918",
    logoPath: "assets/images/manufacturers/keratinworks-logo.png",
  },
  {
    name: "Yuko",
    manufacturerId: "a5fb5c45-9036-40ff-9ddf-b23dbb7aa0b4",
    logoPath: "assets/images/manufacturers/yuko-logo.png",
  },
  {
    name: "Bhave",
    manufacturerId: "5f58fe7a-1857-44aa-891a-59e6bfbfca5a",
    logoPath: "assets/images/manufacturers/bhave-logo.png",
  },
  {
    name: "Malina",
    manufacturerId: "c70086ec-fc74-4991-a731-897b4a9d5438",
    logoPath: "assets/images/manufacturers/malina-logo.png",
  },
  {
    name: "Lomé",
    manufacturerId: "0299192f-b783-42c3-aebb-2b2a1811cc9e",
    logoPath: "assets/images/manufacturers/lomé-logo.png",
  },
  {
    name: "Organethic (OPC)",
    manufacturerId: "6311381d-624d-4896-b056-4acb0d396f44",
    logoPath: "assets/images/manufacturers/organethic-(opc)-logo.png",
  },
  {
    name: "Pura Luxe",
    manufacturerId: "fb4eae88-a53b-4ffe-bb4c-29be4d915ed9",
    logoPath: "assets/images/manufacturers/pura-luxe-logo.png",
  },
  {
    name: "Pro Oxy",
    manufacturerId: "5b099982-72df-4698-8e3b-ee3fcbd9fed7",
    logoPath: "assets/images/manufacturers/pro-oxy-logo.png",
  },
  {
    name: "Color Wow",
    manufacturerId: "8650be5d-fd47-492e-86a4-246c05796b9b",
    logoPath: "assets/images/manufacturers/color-wow-logo.png",
  },
  {
    name: "Vish After Dark",
    manufacturerId: "0ae1422c-9abf-410d-a60e-3ede3898f2f4",
    logoPath: "assets/images/manufacturers/vish-after-dark-logo.png",
  },
  {
    name: "Salon Care",
    manufacturerId: "3bb37add-b547-470a-aeec-becb65aa696a",
    logoPath: "assets/images/manufacturers/salon-care-logo.png",
  },
  {
    name: "Naturigin",
    manufacturerId: "3f95d06d-b3a1-4be2-b4c7-1bb30e839102",
    logoPath: "assets/images/manufacturers/naturigin-logo.png",
  },
  {
    name: "Formula 18",
    manufacturerId: "41c47cbe-db64-4eb9-a5a2-f935d449cb4f",
    logoPath: "assets/images/manufacturers/formula-18-logo.png",
  },
  {
    name: "Keratin Complex",
    manufacturerId: "88013c57-d967-4fc0-9260-daffa6555994",
    logoPath: "assets/images/manufacturers/keratin-complex-logo.png",
  },
  {
    name: "Brazilian Bond Build3r",
    manufacturerId: "acb70ff4-6da1-477a-9dec-3b39ea1db164",
    logoPath: "assets/images/manufacturers/brazilian-bond-build3r-logo.png",
  },
  {
    name: "NanoKeratin",
    manufacturerId: "af3e3b13-6b2c-47ab-976a-db0eabcdaa56",
    logoPath: "assets/images/manufacturers/nanokeratin-logo.png",
  },
  {
    name: "ProAddiction",
    manufacturerId: "b2fe9a16-f0ac-44cb-8ef5-78e41df6f600",
    logoPath: "assets/images/manufacturers/proaddiction-logo.png",
  },
  {
    name: "Rusk",
    manufacturerId: "c5530072-0738-470b-97a8-af9b99c57497",
    logoPath: "assets/images/manufacturers/rusk-logo.png",
  },
  {
    name: "Pure",
    manufacturerId: "cf878f3d-b466-451b-b743-457088754ac5",
    logoPath: "assets/images/manufacturers/pure-logo.png",
  },
  {
    name: "L'Oreal",
    manufacturerId: "d5d516d0-3f30-4667-9e0e-de5eb322690e",
    logoPath: "assets/images/manufacturers/l'oreal-logo.png",
  },
  {
    name: "Amika",
    manufacturerId: "a85daa85-c7bd-4558-a523-6c14f9cba8a3",
    logoPath: "assets/images/manufacturers/amika-logo.png",
  },
  {
    name: "Super Star",
    manufacturerId: "fb64a46e-2b00-46bf-9dd6-bd7d314cd39f",
    logoPath: "assets/images/manufacturers/super-star-logo.png",
  },
  {
    name: "Amore",
    manufacturerId: "f93fa4b6-ecb9-41bc-9a21-2410bb038377",
    logoPath: "assets/images/manufacturers/amore-logo.png",
  },
  {
    name: "Everygreen",
    manufacturerId: "abd1a3b3-4cd4-4b5f-815c-2b7f4956f334",
    logoPath: "assets/images/manufacturers/everygreen-logo.png",
  },
  {
    name: "Kera Color",
    manufacturerId: "abc8b576-6621-4f04-b762-3c6d5e8a7129",
    logoPath: "assets/images/manufacturers/kera-color-logo.png",
  },
  {
    name: "Kincrem",
    manufacturerId: "33060535-3395-440f-85e5-b846665266e5",
    logoPath: "assets/images/manufacturers/kincrem-logo.png",
  },
  {
    name: "Salerm",
    manufacturerId: "fc66482a-31fc-4ebb-89ff-ee0c4d90babe",
    logoPath: "assets/images/manufacturers/salerm-logo.png",
  },
  {
    name: "Color Latino Milano",
    manufacturerId: "9fed797c-039e-4b1d-ad03-a476908e68c6",
    logoPath: "assets/images/manufacturers/color-latino-milano-logo.png",
  },
  {
    name: "Saphira",
    manufacturerId: "d78e15f6-69fb-464f-9bd4-6f5dfaf11c26",
    logoPath: "assets/images/manufacturers/saphira-logo.png",
  },
  {
    name: "Itely Hair Fashion",
    manufacturerId: "4af83597-4422-4e36-913b-d79e96da9477",
    logoPath: "assets/images/manufacturers/itely-hair-fashion-logo.png",
  },
  {
    name: "Mon Platin Professional",
    manufacturerId: "4cf90c99-ab1b-4547-9ad0-796020a37061",
    logoPath: "assets/images/manufacturers/mon-platin-professional-logo.png",
  },
  {
    name: "4Ever Smooth",
    manufacturerId: "1d7b8ffe-1b9c-4a88-bd7b-d208b90df180",
    logoPath: "assets/images/manufacturers/4ever-smooth-logo.png",
  },
  {
    name: "Nov'Max Keratin",
    manufacturerId: "4ae003d2-2152-4996-b1a2-c3a8459945dc",
    logoPath: "assets/images/manufacturers/nov'max-keratin-logo.png",
  },
  {
    name: "Emiliv",
    manufacturerId: "b1e0cb3f-2fa3-4335-87f4-874196e8a6bf",
    logoPath: "assets/images/manufacturers/emiliv-logo.png",
  },
  {
    name: "Eclipse",
    manufacturerId: "5d2515c4-bc95-41ea-b2a3-5d3640241d5b",
    logoPath: "assets/images/manufacturers/eclipse-logo.png",
  },
  {
    name: "Bronsun",
    manufacturerId: "5f5ad578-499c-4f3e-92e2-678c9633ecf5",
    logoPath: "assets/images/manufacturers/bronsun-logo.png",
  },
  {
    name: "NASHI",
    manufacturerId: "992f028a-868c-4a2d-a371-0084cba42bf7",
    logoPath: "assets/images/manufacturers/nashi-logo.png",
  },
  {
    name: "Skinceuticals",
    manufacturerId: "a0c45d6f-b6bd-4de2-8e4f-9e29cba22ad5",
    logoPath: "assets/images/manufacturers/skinceuticals-logo.png",
  },
  {
    name: "Dermalogica",
    manufacturerId: "667364c4-4899-4445-86e6-cfc47e32ec31",
    logoPath: "assets/images/manufacturers/dermalogica-logo.png",
  },
  {
    name: "Tocco Magico",
    manufacturerId: "6bee8e47-b5f8-4e81-b5a7-407f0e954d0b",
    logoPath: "assets/images/manufacturers/tocco-magico-logo.png",
  },
  {
    name: "Maria Nila",
    manufacturerId: "6ee5e7d7-8a7c-45c2-b159-80c72fd856b4",
    logoPath: "assets/images/manufacturers/maria-nila-logo.png",
  },
  {
    name: "Danger Jones",
    manufacturerId: "d646e08d-70d6-46e6-940e-da7fbb319f6c",
    logoPath: "assets/images/manufacturers/danger-jones-logo.png",
  },
  {
    name: "Epres",
    manufacturerId: "3b8f11a1-e53e-4319-b1fb-c3b3d5d8be6a",
    logoPath: "assets/images/manufacturers/epres-logo.png",
  },
  {
    name: "Rejuvenol",
    manufacturerId: "15572fc1-9353-46ee-8d32-567a0c6f3699",
    logoPath: "assets/images/manufacturers/rejuvenol-logo.png",
  },
  {
    name: "The Hair Movement",
    manufacturerId: "3a280368-4efc-49ea-92d1-79c881085d8f",
    logoPath: "assets/images/manufacturers/the-hair-movement-logo.png",
  },
];

export const getLogoPathForManufacturer = (manufacturerId: string): string => {
  const logo = manufacturerLogos.find((logo) => logo.manufacturerId === manufacturerId);

  return isNil(logo) ? "" : logo.logoPath;
};
