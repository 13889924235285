import { Action } from "@ngrx/store";
import { ServiceDescription } from "@getvish/model";
import { ProductAllowanceBlueprintVM, ProductAllowanceVM } from "app/kernel/models/product-allowance";

export enum Types {
  LOAD_DATA = "[Product Allowance Calculator] Load Data",
  LOAD_DATA_SUCCESS = "[Product Allowance Calculator] Load Data Success",
  LOAD_DATA_FAIL = "[Product Allowance Calculator] Load Data Fail",
  ADD_BOWL = "[Product Allowance Calculator] Add Bowl",
  UPDATE_BLUEPRINT = "[Product Allowance Calculator] Update Blueprint",
  DELETE_BLUEPRINT = "[Product Allowance Calculator] Delete Blueprint",
  SAVE_SERVICE = "[Product Allowance Calculator] Save Service",
  SAVE_SERVICE_SUCCESS = "[Product Allowance Calculator] Save Service Success",
  SAVE_SERVICE_FAIL = "[Product Allowance Calculator] Save Service Fail",
}

export class LoadData implements Action {
  public readonly type = Types.LOAD_DATA;

  constructor(public payload: { ids: string[] }) {}
}

export class LoadDataSuccess implements Action {
  public readonly type = Types.LOAD_DATA_SUCCESS;

  constructor(public payload: { services: ServiceDescription[]; productAllowance: ProductAllowanceVM }) {}
}

export class LoadDataFail implements Action {
  public readonly type = Types.LOAD_DATA_FAIL;

  constructor(public payload: { error: any }) {}
}

export class AddBowl implements Action {
  public readonly type = Types.ADD_BOWL;
}

export class UpdateBlueprint implements Action {
  public readonly type = Types.UPDATE_BLUEPRINT;

  constructor(public payload: { idx: number; bowl: ProductAllowanceBlueprintVM }) {}
}

export class DeleteBlueprint implements Action {
  public readonly type = Types.DELETE_BLUEPRINT;

  constructor(public payload: number) {}
}

export class SaveService implements Action {
  public readonly type = Types.SAVE_SERVICE;

  constructor() {}
}

export class SaveServiceSuccess implements Action {
  public readonly type = Types.SAVE_SERVICE_SUCCESS;

  constructor() {}
}

export class SaveServiceFail implements Action {
  public readonly type = Types.SAVE_SERVICE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export type Actions =
  | LoadData
  | LoadDataSuccess
  | LoadDataFail
  | AddBowl
  | UpdateBlueprint
  | DeleteBlueprint
  | SaveService
  | SaveServiceSuccess
  | SaveServiceFail;
