import { Injectable } from "@angular/core";
import { Customer } from "@getvish/model";
import { HttpRepositoryFactory, EntityService, HttpRequestHandler } from "@getvish/stockpile";
import { either } from "fp-ts";
import { Either } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { splitEvery } from "ramda";
import { Observable, concat, of } from "rxjs";
import { catchError, map, reduce } from "rxjs/operators";

export interface MergePayload {
  primaryCustomerId: string;
  dependentCustomerIds: string[];
}
export interface MergeCustomerSuccess {
  customer: Customer;
  numUpdatedAppointments: number;
  numUpdatedOrders: number;
  numDeletedCustomers: number;
}

@Injectable()
export class CustomerService extends EntityService<Customer> {
  public controllerKey: string;

  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler
  ) {
    super(repositoryFactory, { entityKey: "customers" });
    this.controllerKey = "customers";
  }

  public insertMany(records: Customer[]): Observable<number> {
    const chunks = splitEvery(250, records);
    const requests$ = chunks.map((chunk) => this._requestHandler.post(`${this.controllerKey}/many`, chunk));

    return concat(...requests$).pipe(
      map((response) => {
        const responsePayload: { numInserted: number } = pipe(
          response,
          either.getOrElse(() => null)
        );

        return responsePayload.numInserted;
      }),
      reduce((acc: number, curr) => acc + curr, 0)
    );
  }

  public mergeCustomers(primary: Customer, records: Customer[]): Observable<Either<Error, MergeCustomerSuccess>> {
    const customerIds = records.map((record) => record._id).filter((id) => id !== primary._id);

    const payload: MergePayload = {
      primaryCustomerId: primary._id,
      dependentCustomerIds: customerIds,
    };

    return this._requestHandler.post<MergeCustomerSuccess>(`${this.controllerKey}/merge`, payload).pipe(
      map((response) =>
        pipe(
          response,
          either.mapLeft((fail) => new Error(fail.payload["reason"]))
        )
      ),
      catchError((error) => {
        console.log("error was: ", error);
        return of(either.left(new Error(error.toString())));
      })
    );
  }
}
