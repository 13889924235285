import { Component } from "@angular/core";
import { Manufacturer } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { getParamMap } from "app/kernel/store/selectors/router.selectors";
import { isNil, not } from "ramda";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppState } from "../../../kernel";

import { JsonObject } from "@getvish/stockpile";
import * as ManufacturerActions from "../store/manufacturer.actions";
import * as fromManufacturer from "../store/manufacturer.selectors";

@Component({
  selector: "manufacturers-container",
  templateUrl: "manufacturers.container.html",
  styleUrls: ["manufacturers.container.less"],
})
export class ManufacturersContainer {
  public filter$: Observable<string>;
  public loading$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.loading$ = _store.select(fromManufacturer.getLoading);

    this.filter$ = this._store.pipe(
      select(getParamMap),
      map((params) => (not(isNil(params)) ? params.get("filter") : undefined))
    );
  }

  public new(): void {
    this._store.dispatch(new ManufacturerActions.New());
  }

  public edit(manufacturer: Manufacturer): void {
    this._store.dispatch(new ManufacturerActions.Edit({ manufacturer }));
  }

  public updateFilter(filter: string): void {
    this._store.dispatch(new ManufacturerActions.SetAdminFilter({ filter }));
  }

  public updateSort(sort: JsonObject): void {
    this._store.dispatch(new ManufacturerActions.SetAdminSort(sort));
  }
}
