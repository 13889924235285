import { Appointment, AppointmentStatus, ServiceDescription } from "@getvish/model";
import { PagingMetadata } from "@getvish/stockpile";
import { Action, createAction, props } from "@ngrx/store";
import { AppointmentVM } from "../models/appointment";

export enum Types {
  LOAD_CURRENT_APPOINTMENTS_SUCCESS = "[Appointment] Load Current Appointments Success",
  SET_SEARCH_FILTER = "[Appointment] Set Search Filter",
  DELETE = "[Appointment] Delete",
  DELETE_SUCCESS = "[Appointment] Delete Success",
  DELETE_FAIL = "[Appointment] Delete Fail",
  SHOW_RESOLVE = "[Appointment] Show Resolve",
  LOAD_RESOLVE = "[Appointment] Load Resolve",
  LOAD_RESOLVE_SUCCESS = "[Appointment] Load Resolve Success",
  LOAD_RESOLVE_FAIL = "[Appointment] Load Resolve Fail",
  CLOSE_RESOLVE = "[Appointment] Close Resolve",
  RESOLVE = "[Appointment] Resolve",
  RESOLVE_SUCCESS = "[Appointment] Resolve Success",
  RESOLVE_FAIL = "[Appointment] Resolve Fail",
  SET_STATUS_FILTER = "[Appointment] Set Status Filter",
  TOGGLE_ALERTS_ONLY = "[Appointment] Toggle Alerts Only",
}

export const navigateToAppointmentsList = createAction("[Appointment] Navigate to Appointments List");

export const loadCurrentAppointments = createAction("[Appointment] Load Current Appointments");

export const loadCurrentAppointmentsFail = createAction("[Appointment] Load Current Appointments Fail", props<{ error: any }>());

export const select = createAction("[Appointment] Select", props<{ appointment: Appointment }>());

export class LoadCurrentAppointmentsSuccess implements Action {
  public readonly type = Types.LOAD_CURRENT_APPOINTMENTS_SUCCESS;

  constructor(
    public payload: AppointmentVM[],
    public paging: PagingMetadata
  ) {}
}

export class SetSearchFilter implements Action {
  public readonly type = Types.SET_SEARCH_FILTER;

  constructor(public payload: { filter: string }) {}
}

export const Delete = createAction(Types.DELETE, props<{ appointment: Appointment }>());

export const DeleteFail = createAction(Types.DELETE_FAIL, props<{ error: Error }>());

export class DeleteSuccess implements Action {
  public readonly type = Types.DELETE_SUCCESS;

  constructor(public id: string) {}
}

export class ShowResolve implements Action {
  public readonly type = Types.SHOW_RESOLVE;

  constructor(public appointments: AppointmentVM[]) {}
}

export class LoadResolve implements Action {
  public readonly type = Types.LOAD_RESOLVE;

  constructor(public appointments?: Appointment[]) {}
}

export class LoadResolveSuccess implements Action {
  public readonly type = Types.LOAD_RESOLVE_SUCCESS;

  constructor(public serviceDescriptions: ServiceDescription[]) {}
}

export const LoadResolveFail = createAction(Types.LOAD_RESOLVE_FAIL, props<{ error: Error }>());

export const CloseResolve = createAction(Types.CLOSE_RESOLVE);

export const Resolve = createAction(Types.RESOLVE, props<{ appointments: Appointment[] }>());

export const ResolveFail = createAction(Types.RESOLVE_FAIL, props<{ error: Error }>());

export class ResolveSuccess implements Action {
  public readonly type = Types.RESOLVE_SUCCESS;

  constructor(public ids: string[]) {}
}

export class SetStatusFilter implements Action {
  public readonly type = Types.SET_STATUS_FILTER;

  constructor(public payload: { status: AppointmentStatus }) {}
}

export class ToggleAlertsOnly implements Action {
  public readonly type = Types.TOGGLE_ALERTS_ONLY;
}

export type Actions =
  | typeof loadCurrentAppointments
  | typeof Delete
  | typeof DeleteFail
  | typeof LoadResolveFail
  | typeof CloseResolve
  | typeof Resolve
  | typeof ResolveFail
  | LoadCurrentAppointmentsSuccess
  | SetSearchFilter
  | ShowResolve
  | LoadResolve
  | LoadResolveSuccess
  | DeleteSuccess
  | ResolveSuccess
  | SetStatusFilter
  | ToggleAlertsOnly;
