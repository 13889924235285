import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ListOrdererComponent } from "./components/list-orderer.component";
import { SharedModule } from "app/kernel/shared";
import { ListOrdererItemDirective, ListOrdererItemPrefixDirective } from "./directives/list-orderer-item.directive";

@NgModule({
  declarations: [ListOrdererComponent, ListOrdererItemDirective, ListOrdererItemPrefixDirective],
  imports: [CommonModule, DragDropModule, SharedModule],
  exports: [ListOrdererComponent, ListOrdererItemDirective, ListOrdererItemPrefixDirective],
})
export class ListOrdererModule {}
