import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromSalonApiVersion from "./salon-api-version.reducer";

/*** SELECTORS ***/
export const getState = createFeatureSelector<fromSalonApiVersion.SalonApiVersionState>(fromSalonApiVersion.salonApiVersionFeatureKey);

export const getRecords = createSelector(getState, (state) => state.records);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
export const getSalonApiVersions = createSelector(getState, (state) => state.records);
