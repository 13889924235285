import { NgModule } from "@angular/core";
import { SharedModule } from "app/kernel/shared";
import { ProgressIndicatorComponent } from "./components/progress-indicator.component";
import { ProgressIndicatorDirective } from "./directives";
import { BackdropComponent } from "./components/backdrop.component";
import { ResizeService } from "app/kernel/services";

@NgModule({
  declarations: [BackdropComponent, ProgressIndicatorComponent, ProgressIndicatorDirective],
  imports: [SharedModule],
  exports: [ProgressIndicatorDirective],
  providers: [ResizeService],
})
export class ProgressIndicatorModule {}
