import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { MeasurementUnit, ProductCategory, SalonProduct, SalonProductModule } from "@getvish/model";
import { trackByIdFn } from "../../../../kernel/util";
import { ProductCategoryVM, SalonProductVM } from "../../common";
import { getSaving } from "../../store/salon-product.selectors";

import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import * as R from "ramda";
import { ShowProductOrderDialog } from "../../store/salon-product.actions";

export interface RetailSalonProductVM extends SalonProductVM {
  retailPrice: number;
}
@Component({
  selector: "salon-products-grouped-list",
  templateUrl: "./salon-products-grouped-list.component.html",
  styleUrls: ["./salon-products-grouped-list.component.less"],
})
export class SalonProductsGroupedListComponent implements OnChanges {
  @Input() public products: SalonProductVM[];
  @Input() public productCategories: ProductCategory[];
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public currency: string;

  @Output()
  public editProductClicked: EventEmitter<SalonProduct> = new EventEmitter<SalonProduct>(true);
  @Output()
  public deactivateCategoryClicked: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>(true);
  @Output()
  public reactivateCategoryClicked: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>(true);
  @Output()
  public setCategoryPricingClicked: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>(true);
  @Output()
  public setCategoryMarkupClicked: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>(true);
  @Output()
  public placeSalonInteractiveOrderClicked: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>(true);

  public isSaving = false;

  public productGroupings: Array<{
    category: ProductCategoryVM;
    products: RetailSalonProductVM[];
    allInactive: boolean;
    hasActive: boolean;
  }>;

  constructor(private _store: Store<AppState>) {
    _store.select(getSaving).subscribe((saving) => {
      this.isSaving = saving;
    });
  }

  public trackByGroup = (_: number, value: { category: ProductCategory; products: SalonProduct[] }): string => value.category._id;
  public trackById = trackByIdFn;

  public ngOnChanges(): void {
    const uniqueCategories = R.uniq(R.flatten(this.products.map((product) => product.availableCategories)));

    const sortFunc = (c1?: { order?: number; name: string }, c2?: { order?: number; name: string }) => {
      const s = (c1?.order ?? Number.MAX_SAFE_INTEGER) - (c2?.order ?? Number.MAX_SAFE_INTEGER);

      if (s === 0) {
        return (c1?.name ?? "").localeCompare(c2?.name ?? "", undefined, { sensitivity: "base" });
      }

      return s;
    };

    const categories = this.productCategories.map((c) => uniqueCategories.find((_c) => c._id === _c._id)).filter(R.complement(R.isNil));

    this.productGroupings = categories.map((category) => {
      const products = R.sort(
        sortFunc,
        this.products
          .filter((product) => product.categoryId === category._id)
          .map((p) => ({ ...p, retailPrice: SalonProductModule.getRetailPrice(p) }))
      ) as RetailSalonProductVM[];

      return {
        category,
        products,
        allInactive: products.every((product) => product.inactive),
        hasActive: products.some((product) => R.not(product.inactive)),
      };
    });
  }

  public changeProductOrder(salonProducts: SalonProduct[]): void {
    this._store.dispatch(new ShowProductOrderDialog({ salonProducts }));
  }
}
