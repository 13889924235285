import { Component, ChangeDetectionStrategy } from "@angular/core";
import { AppState } from "../../kernel/store";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { LoginRequest } from "../../kernel/models";
import { ForgotPassword, Login } from "../store";
import { map } from "rxjs/operators";
import { and, not } from "ramda";

import * as fromAuth from "../store/auth.selectors";

@Component({
  selector: "login-container",
  templateUrl: "./login.container.html",
  styleUrls: ["./login.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContainer {
  public error$: Observable<string>;
  public loggingInFromAuthToken$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public loggingIn$: Observable<boolean>;
  public loggedIn$: Observable<boolean>;
  public renderLoginForm$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.error$ = this._store.select(fromAuth.getError);
    this.loggingInFromAuthToken$ = this._store.select(fromAuth.getLoggingInFromAuthToken);
    this.loggingIn$ = this._store.select(fromAuth.getLoggingIn);
    this.loggedIn$ = this._store.select(fromAuth.getLoggedIn);

    this.renderLoginForm$ = combineLatest([this.loggingInFromAuthToken$, this.loggedIn$]).pipe(
      map(([loggingInFromAuthToken, loggedIn]) => and(not(loggingInFromAuthToken), not(loggedIn)))
    );
  }

  public login(payload: LoginRequest): void {
    this._store.dispatch(new Login(payload));
  }

  public forgotPassword(): void {
    this._store.dispatch(new ForgotPassword());
  }
}
