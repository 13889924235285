import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MeasurementUnit, Order } from "@getvish/model";
import { spinAnimation } from "../../kernel/shared/rocket-ui/animations";
import { AppointmentVM } from "../models/appointment";

@Component({
  selector: "order-component",
  templateUrl: "order.component.html",
  styleUrls: ["order.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [spinAnimation],
})
export class OrderComponent {
  @Input() public order: Order;
  @Input() public appointment?: AppointmentVM;
  @Input() public loading: boolean;
  @Input() public error: string;
  @Input() public currency: string;
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public checkingOut: boolean;
  @Input() public reactivating: boolean;
  @Input() public resolving: boolean;
  @Input() public module: string;
  @Input() public timeZone: string;
  @Output() public back: EventEmitter<void>;
  @Output() public checkout: EventEmitter<Order>;
  @Output() public reactivate: EventEmitter<Order>;
  @Output() public resolve: EventEmitter<AppointmentVM>;

  constructor() {
    this.back = new EventEmitter(true);
    this.checkout = new EventEmitter(true);
    this.reactivate = new EventEmitter(true);
    this.resolve = new EventEmitter(true);
  }

  public getProgressText() {
    if (this.checkingOut) {
      return "Checking out...";
    } else if (this.reactivating) {
      return "Reactivating...";
    } else if (this.resolving) {
      return "Resolving...";
    } else {
      return undefined;
    }
  }
}
