import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Notification } from "app/+notifications/models";

@Component({
  selector: "notification-list",
  templateUrl: "notification-list.component.html",
  styleUrls: ["./notification-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent {
  @Input() public notifications: Notification[];
  @Output() public action: EventEmitter<Notification> = new EventEmitter<Notification>();
}
