import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Salon } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { option } from "fp-ts";
import { pipe } from "fp-ts/pipeable";
import { map } from "rxjs/operators";
import { AppState } from "../../kernel";
import { go } from "../../kernel/store/actions/router.actions";

import { and, isNil, not } from "ramda";
import { Observable, Subscription, combineLatest } from "rxjs";
import * as EditSalonActions from "../store/edit-salon.actions";
import * as fromEditSalon from "../store/edit-salon.selectors";
import * as SalonActions from "../store/salon.actions";
import * as fromSalon from "../store/salon.selectors";

@Component({
  selector: "edit-salon-container",
  templateUrl: "edit-salon.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSalonContainer implements OnDestroy {
  public salon$: Observable<Salon>;
  public salonId$: Observable<string>;
  public title$: Observable<string>;
  public loading$: Observable<boolean>;
  public error$: Observable<string>;
  public saving$: Observable<boolean>;
  public shouldRenderForm$: Observable<boolean>;

  public salonIdSubscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.salon$ = this._store.pipe(select(fromEditSalon.getRecord));
    this.title$ = this.salon$.pipe(
      map((salon) =>
        pipe(
          option.fromNullable(salon),
          option.map((value) =>
            pipe(
              option.fromNullable(value._id),
              option.fold(
                () => "New Salon",
                (_) => "Edit Salon"
              )
            )
          ),
          option.getOrElse(() => "Edit Salon")
        )
      )
    );

    this.loading$ = this._store.pipe(select(fromEditSalon.getLoading));
    this.saving$ = this._store.pipe(select(fromSalon.getSaving));
    this.error$ = this._store.pipe(select(fromEditSalon.getError));
    this.salon$ = _store.pipe(select(fromEditSalon.getRecord));

    this.shouldRenderForm$ = combineLatest(this.loading$, this.error$).pipe(map(([loading, error]) => and(not(loading), isNil(error))));

    this.salonId$ = _route.paramMap.pipe(map((params) => params.get("id")));

    this.salonIdSubscription = this.salonId$
      .pipe(map((salonId) => new EditSalonActions.Load({ salonId })))
      .subscribe((action) => this._store.dispatch(action));
  }

  public save(salon: Salon): void {
    this._store.dispatch(SalonActions.addOrUpdate({ salon }));
  }

  public close(): void {
    this._store.dispatch(go({ path: ["salons"], extras: { queryParamsHandling: "merge" } }));
  }

  public ngOnDestroy(): void {
    this.salonIdSubscription.unsubscribe();
  }
}
