import { createAction, props } from "@ngrx/store";
import { MasterList } from "../models/master-pricing.model";
import { Manufacturer } from "@getvish/model";
import { JsonObject } from "@getvish/stockpile";
import { MasterListVM } from "../components/master-pricing-dialog";

export const init = createAction("[Master Pricing] Initialize", props<{ manufacturerId: string }>());
export const initSuccess = createAction("[Master Pricing] Initialize Success", props<{ masterLists: MasterList[] }>());
export const initFail = createAction("[Master Pricing] Initialize Fail", props<{ error: Error }>());
export const show = createAction("[Master Pricing] Show", props<Manufacturer>());
export const toggleSelected = createAction("[Master Pricing] Toggle Selected", props<MasterListVM>());
export const clearSelected = createAction("[Master Pricing] Clear Selected");
export const updateTable = createAction("[Master Pricing] Update Table", props<{ sort?: JsonObject; filter?: JsonObject }>());
export const updateTableStarted = createAction("[Master Pricing] Update Table Started");
export const updateTableSuccess = createAction("[Master Pricing] Update Table Success", props<{ masterLists: MasterList[] }>());
export const updateTableFail = createAction("[Master Pricing] Update Table Fail", props<{ error: Error }>());
export const applyPricing = createAction("[Master Pricing] Apply Pricing");
export const applyPricingSuccess = createAction("[Master Pricing] Apply Pricing Success");
export const applyPricingFail = createAction("[Master Pricing] Apply Pricing Fail", props<{ error: Error }>());
