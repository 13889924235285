import { Pipe, PipeTransform } from "@angular/core";
import { MeasurementUnitConversionService } from "../../../../+salon-config/services";
import { is, isNil } from "ramda";
import { MeasurementUnit } from "@getvish/model";

@Pipe({ name: "localMeasurementUnit", pure: true })
export class LocalMeasurementUnitPipe implements PipeTransform {
  constructor(private _measurementUnitConversionService: MeasurementUnitConversionService) {}

  public transform(value: string | number, measurementUnit: MeasurementUnit): string {
    if (isNil(value)) {
      return undefined;
    }

    const valueNum: number = is(String, value) ? parseFloat(String(value)) : Number(value);

    return this._measurementUnitConversionService.convertFromBaseWithAbbreviation(valueNum, measurementUnit);
  }
}
