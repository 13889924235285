import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { RouterActionTypes } from "../actions/router.actions";
import { map, tap } from "rxjs/operators";

import * as RouterActions from "../actions/router.actions";

@Injectable()
export class RouterEffects {
  public navigate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<RouterActions.Go>(RouterActionTypes.GO),
        map((action: RouterActions.Go) => action.payload),
        tap(({ path, query: queryParams, extras }) => this._router.navigate(path, { queryParams, ...extras }))
      ),
    { dispatch: false }
  );

  public navigateBack$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<RouterActions.Back>(RouterActionTypes.BACK),
        tap(() => this._location.back())
      ),
    { dispatch: false }
  );

  public navigateForward$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(RouterActionTypes.FORWARD),
        tap(() => this._location.forward())
      ),
    { dispatch: false }
  );

  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _location: Location
  ) {}
}
