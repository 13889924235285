import { createSelector, createFeatureSelector } from "@ngrx/store";

import { AnalyticAppointmentListState, featureKey } from "./performed-service-list.reducer";

export const getState = createFeatureSelector<AnalyticAppointmentListState>(featureKey);

export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
export const getStartDate = createSelector(getState, (state) => state.startDate);
export const getEndDate = createSelector(getState, (state) => state.endDate);
export const getPerformedServices = createSelector(getState, (state) => state.performedServices);
export const getAppointments = createSelector(getState, (state) => state.appointments);
export const getSort = createSelector(getState, (state) => state.sort);
export const getTableFilter = createSelector(getState, (state) => state.tableFilter);
export const getEmployeeId = createSelector(getState, (state) => state.employeeId);
export const getServiceId = createSelector(getState, (state) => state.serviceId);
export const getSubset = createSelector(getState, (state) => state.subset);
