import { Appointment } from "@getvish/model";
import { JsonObject } from "@getvish/stockpile";
import { createAction, props, union } from "@ngrx/store";
import { PerformedServiceVM } from "../model/performed-service";

export const initialize = createAction(
  "[Performed Service List] Initialize",
  props<{
    payload: {
      startDate?: number;
      endDate?: number;
      employeeId?: string;
      serviceId?: string;
      subset?: "mixable" | "mixed" | "unmixed";
      sort?: JsonObject;
      tableFilter?: JsonObject;
    };
  }>()
);

export const initializeSuccess = createAction(
  "[Performed Service List] Initialize Success",
  props<{ performedServices: PerformedServiceVM[]; appointments: Appointment[] }>()
);

export const initializeFail = createAction("[Performed Service List] Initialize Fail", props<{ error: any }>());

export const updateSort = createAction("[Performed Service List] Update Sort", props<{ payload: JsonObject }>());

export const updateTableFilter = createAction("[Performed Service List] Update Table Filter", props<{ payload: JsonObject }>());

const actions = union({
  initialize,
  initializeSuccess,
  initializeFail,
  updateSort,
  updateTableFilter,
});

export type Actions = typeof actions;
