export const environment = {
    production: true,
    version: "",
    LOG_ROCKET: {
      AppId: "",
    },
    SERVER_CONFIG: {
      hostname: "api.disco-stu.getvish.io",
      port: "",
      protocol: "wss",
      httpProtocol: "https",
    },
    APP_CONFIG: {
      baseUrl: "app.disco-stu.getvish.io",
      apiVersion: "V2",
      v1Url: "https://app.disco-stu.getvish.io",
      v2Url: "",
    },
    booker: {
      authEntryPoint: "https://integrations.getvish.com",
      baseUrl: "https://identity.booker.com",
      clientId: "qG9ixMKJ6UFi",
      redirectUrl: "https://integrations.getvish.com/booker/auth/callback",
    },
  };