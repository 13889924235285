import { SalonReport } from "../../kernel/models";
import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Option } from "fp-ts/Option";
import { addDays } from "date-fns/esm/fp";

@Injectable()
export class SalonReportService extends EntityService<SalonReport> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "salonReports" });
  }

  public fetchForDateRange(startDate: number, endDate: number, employeeIds?: string[]): Observable<Option<SalonReport>> {
    const criteria = { startDate, endDate, employeeIds };

    return this.findOne(criteria);
  }

  public fetchCurrentSalonReport(employeeIds?: string[]): Observable<Option<SalonReport>> {
    const today = new Date();
    const yesterday = addDays(-1)(today);

    const criteria = { startDate: yesterday.getTime(), endDate: today.getTime(), employeeIds };

    return this.findOne(criteria);
  }
}
