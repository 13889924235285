import { Action } from "@ngrx/store";
import { ProductReport, ManufacturerReport, ProductCategoryReport } from "../../kernel/models";
import { Manufacturer, ProductCategory, SalonProduct, Employee } from "@getvish/model";
import { PagedResult } from "@getvish/stockpile";

export enum Types {
  INITIALIZE_AND_LOAD_PRODUCT_REPORT_GRAPH = "[Product Reports] Initialize and Load Product Report Graph",
  LOAD_PRODUCT_REPORT_GRAPH = "[Product Reports] Load Product Report Graph",
  LOAD_PRODUCT_REPORT_GRAPH_SUCCESS = "[Product Reports] Load Product Report Graph Success",
  LOAD_PRODUCT_REPORT_GRAPH_FAIL = "[Product Reports] Load Product Report Graph Fail",
  LOAD_PRODUCT_REPORTS_SUCCESS = "[Product Report] Load Product Reports Success",
  LOAD_PRODUCT_CATEGORY_REPORTS_SUCCESS = "[Product Report] Load Product Category Reports Success",
  INITIALIZE_AND_LOAD_MANUFACTURER_REPORT = "[Manufacturer Report] Initialize and Load Manufacturer Report",
  LOAD_MANUFACTURER_REPORT = "[Manufacturer Report] Load",
  LOAD_MANUFACTURER_REPORT_SUCCESS = "[Manufacturer Report] Load Success",
  LOAD_MANUFACTURER_REPORT_FAIL = "[Manufacturer Report] Load Fail",
  UPDATE_PRODUCT_REPORT_FILTERS = "[Product Reports] Update Filters",
  UPDATE_MANUFACTURER_REPORT_FILTERS = "[Product Reports] Update Manufacturer Report Filters",
  DOWNLOAD_MANUFACTURER_REPORT = "[Product Reports] Download Manufacturer Report",
  UPDATE_DATE_RANGE = "[Product Reports] Update Date Range",
}

export class InitializeAndLoadProductReportGraph implements Action {
  public readonly type = Types.INITIALIZE_AND_LOAD_PRODUCT_REPORT_GRAPH;

  constructor(public payload: { manufacturerId: string }) {}
}

export class LoadProductReportGraph implements Action {
  public readonly type = Types.LOAD_PRODUCT_REPORT_GRAPH;

  constructor(public payload: { manufacturerId: string; startDate: number; endDate: number }) {}
}

export class LoadProductReportGraphSuccess implements Action {
  public readonly type = Types.LOAD_PRODUCT_REPORT_GRAPH_SUCCESS;

  constructor(
    public payload: {
      productCategoryReport: ProductCategoryReport;
      manufacturers: PagedResult<Manufacturer>;
      productReport: ProductReport;
      productCategories: PagedResult<ProductCategory>;
      salonProducts: SalonProduct[];
      employees: PagedResult<Employee>;
    }
  ) {}
}

export class LoadProductReportGraphFail implements Action {
  public readonly type = Types.LOAD_PRODUCT_REPORT_GRAPH_FAIL;

  constructor(public payload: { error: string }) {}
}

export class LoadProductReportsSuccess implements Action {
  public readonly type = Types.LOAD_PRODUCT_REPORTS_SUCCESS;

  constructor(public payload: ProductReport) {}
}

export class InitializeAndLoadManufacturerReport implements Action {
  public readonly type = Types.INITIALIZE_AND_LOAD_MANUFACTURER_REPORT;
}

export class LoadManufacturerReport implements Action {
  public readonly type = Types.LOAD_MANUFACTURER_REPORT;

  constructor(public payload?: { startDate: number; endDate: number }) {}
}

export class LoadManufacturerReportSuccess implements Action {
  public readonly type = Types.LOAD_MANUFACTURER_REPORT_SUCCESS;

  constructor(public payload: ManufacturerReport) {}
}

export class LoadProductCategoryReportSuccess implements Action {
  public readonly type = Types.LOAD_PRODUCT_CATEGORY_REPORTS_SUCCESS;

  constructor(public payload: ProductCategoryReport) {}
}

export class UpdateProductReportFilters implements Action {
  public readonly type = Types.UPDATE_PRODUCT_REPORT_FILTERS;

  constructor(public payload: { startDate?: number; endDate?: number; manufacturerId?: string; employeeId?: string }) {}
}

export class UpdateManufacturerReportFilters implements Action {
  public readonly type = Types.UPDATE_MANUFACTURER_REPORT_FILTERS;

  constructor(public payload: { startDate: number; endDate: number }) {}
}

export class DownloadManufacturerReport implements Action {
  public readonly type = Types.DOWNLOAD_MANUFACTURER_REPORT;
}

export class UpdateDateRange implements Action {
  public readonly type = Types.UPDATE_DATE_RANGE;

  constructor(public payload: { startDate: number; endDate: number }) {}
}

export type Actions =
  | InitializeAndLoadProductReportGraph
  | LoadProductReportGraph
  | LoadProductReportGraphSuccess
  | LoadProductReportGraphFail
  | LoadProductReportsSuccess
  | InitializeAndLoadManufacturerReport
  | LoadManufacturerReport
  | LoadManufacturerReportSuccess
  | LoadProductCategoryReportSuccess
  | UpdateProductReportFilters
  | UpdateManufacturerReportFilters
  | UpdateDateRange;
