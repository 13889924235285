import { v4 as uuid } from "uuid";
import { ServiceDescription } from "@getvish/model";

import * as paCalculator from "./product-allowance-calculator.actions";
import { ProductAllowanceVM } from "app/kernel/models/product-allowance";

export interface PACalculatorState {
  services: ServiceDescription[];
  productAllowance: ProductAllowanceVM;
  saving: boolean;
  saveError: string;
  loading: boolean;
  error: string;
}

const initialState: PACalculatorState = {
  services: undefined,
  productAllowance: undefined,
  saving: false,
  saveError: undefined,
  loading: false,
  error: undefined,
};

export function paCalculatorReducer(state: PACalculatorState = initialState, action: paCalculator.Actions): PACalculatorState {
  switch (action.type) {
    case paCalculator.Types.ADD_BOWL: {
      return {
        ...state,
        productAllowance: {
          ...state.productAllowance,
          blueprints: [
            ...(state.productAllowance.blueprints ?? []),
            {
              _id: uuid(),
              scrollIntoView: true,
            },
          ],
        },
      };
    }

    case paCalculator.Types.UPDATE_BLUEPRINT: {
      return {
        ...state,
        productAllowance: {
          ...state.productAllowance,
          blueprints: state.productAllowance.blueprints.map((b, idx) => {
            if (idx === action.payload.idx) {
              return action.payload.bowl;
            }

            return b;
          }),
        },
      };
    }

    case paCalculator.Types.DELETE_BLUEPRINT: {
      // Don't allow deleting if there is only one blueprint
      if (state.productAllowance.blueprints.length === 1) {
        return state;
      }

      return {
        ...state,
        productAllowance: {
          ...state.productAllowance,
          blueprints: state.productAllowance.blueprints.filter((_, idx) => idx !== action.payload),
        },
      };
    }

    case paCalculator.Types.LOAD_DATA: {
      return {
        ...state,
        services: undefined,
        productAllowance: undefined,
        loading: true,
      };
    }

    case paCalculator.Types.LOAD_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        services: action.payload.services,
        productAllowance: action.payload.productAllowance,
      };
    }

    case paCalculator.Types.LOAD_DATA_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.error.message,
      };
    }

    case paCalculator.Types.SAVE_SERVICE: {
      return {
        ...state,
        saving: true,
        error: undefined,
      };
    }

    case paCalculator.Types.SAVE_SERVICE_SUCCESS: {
      return {
        ...state,
        saving: false,
        saveError: undefined,
      };
    }

    case paCalculator.Types.SAVE_SERVICE_FAIL: {
      return {
        ...state,
        saving: false,
        saveError: action.payload.errors.map((e) => e.message).join("\n"),
      };
    }

    default: {
      return state;
    }
  }
}
