import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Notification } from "app/+notifications/models";

@Component({
  selector: "notification-list-item",
  templateUrl: "notification-list-item.component.html",
  styleUrls: ["./notification-list-item.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListItemComponent {
  @Input() public notification: Notification;
  @Output() public action: EventEmitter<void> = new EventEmitter<void>();
}
