<slideout-panel (close)="close()">
  <div panel-header>Schedule Appointment</div>

  <schedule-appointment
    panel-body
    *ngIf="(loading$ | async) === false; else loadingElement"
    [employees]="employees$ | async"
    [customers]="customers$ | async"
    [saving]="saving$ | async"
    [searchFilter]="filter$ | async"
    [error]="error$ | async"
    [timeZone]="timeZone$ | async"
    (save)="save($event)"
    (close)="close()"
    (cancel)="cancel()"
    (searchCustomer)="searchCustomer($event)">
  </schedule-appointment>

  <ng-template #loadingElement>
    <large-loading-indicator></large-loading-indicator>
  </ng-template>
</slideout-panel>
