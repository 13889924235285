import { Locale } from "date-fns";
import { enUS, enGB, enAU, enNZ, enZA, arSA, ar, fr, de, es } from "date-fns/locale";
import { option } from "fp-ts";
import { isNil, toLower } from "ramda";
import { pipe } from "fp-ts/lib/function";
import { none, some } from "fp-ts/lib/Option";

export const isDefined = (value) => value !== undefined && value !== null;
export const isNotDefined = (value) => !isDefined(value);
export const isEmpty = (value: unknown[]) => isNotDefined(value) || value.length === 0;
export const isNotEmpty = (value: unknown[]) => !isEmpty(value);

export const getProperty = (object: unknown, property: string) => object[property]; // @TODO rename this to 'pluck'

export const getParamAsBoolean = (param: string): boolean =>
  pipe(
    param,
    option.fromNullable,
    option.map((param) => toLower(param)),
    option.map((param) => param === "true"),
    option.getOrElse(() => false)
  );

export const parseNumberOptional = (maybeNumber: string | undefined) => (isNil(maybeNumber) ? none : some(Number(maybeNumber)));

export type HasId = { _id: string };
export const trackByIdFn = (index: number, value: HasId): string => value._id;

export const formattedLocale = new Map<string, Locale>([
  ["en", enUS],
  ["en-GB", enGB],
  ["en-AU", enAU],
  ["en-NZ", enNZ],
  ["en-ZA", enZA],
  ["en-150", enUS],
  ["ar-QA", arSA],
  ["ar-AE", ar],
  ["fr", fr],
  ["de", de],
  ["es-MX", es],
  ["es", es],
]);

export const roundToTwoDecimals = (value: number): number => {
  return Math.round(value * 100) / 100;
};
