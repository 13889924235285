<salon-settings
  *ngIf="salonConfig$ | async as salonConfig; else loadingElement"
  [config]="salonConfig"
  [timeZones]="timeZones$ | async"
  [currencies]="currencies$ | async"
  [isRoot]="isRoot$ | async"
  (save)="save($event)">
</salon-settings>

<ng-template #loadingElement>
  <large-loading-indicator></large-loading-indicator>
</ng-template>
