import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DiscontinueProductContainer, EditProductContainer, MergeProductsContainer } from "../+products/containers";
import { CanActivateAdminUser } from "../services/can-activate-admin-user";
import { EditProductCategoryContainer, ManufacturerListContainer, ProductManagementContainer } from "./containers";

export const routes = [
  {
    path: "",
    component: ManufacturerListContainer,
    canActivate: [CanActivateAdminUser],
    data: { breadcrumb: { label: "Manage Products" } },
  },
  {
    path: "manufacturer/:manufacturerId",
    component: ProductManagementContainer,
    canActivate: [CanActivateAdminUser],
  },
  {
    path: "categories/new",
    component: EditProductCategoryContainer,
    canActivate: [CanActivateAdminUser],
    outlet: "panel",
  },
  {
    path: "categories/edit/:id",
    canActivate: [CanActivateAdminUser],
    component: EditProductCategoryContainer,
    outlet: "panel",
  },
  {
    path: "new",
    component: EditProductContainer,
    outlet: "panel",
    canActivate: [CanActivateAdminUser],
  },
  {
    path: "edit/:id",
    component: EditProductContainer,
    outlet: "panel",
    canActivate: [CanActivateAdminUser],
  },
  {
    path: "discontinue/:id",
    component: DiscontinueProductContainer,
    outlet: "panel",
    canActivate: [CanActivateAdminUser],
  },
  {
    path: "merge",
    component: MergeProductsContainer,
    outlet: "panel",
    canActivate: [CanActivateAdminUser],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [CanActivateAdminUser],
  exports: [RouterModule],
})
export class ProductCategoriesRoutingModule {}
