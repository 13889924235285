<page
  [progressText]="loading ? 'Loading...' : undefined"
  [error]="error"
  pageTitle="Vish Insights">
  <div
    class="header-controls"
    page-header>
    <!--
      so, this is all _one_ component, right?
      basically the <date-range> _needs_ the <date-picker> component or else it can't really do its job?
      if that's the case we should just wrap them both up into one component
      so you don't have to remember to always render a <date-picker> via [matTooltip] ...
      and we could do things like automatically close the overlay when the user chooses a date
      right now we'd have to manually do the work from the calling component, which is awkward
    -->
    <mat-menu #datepickerMenu="matMenu">
      <date-picker
        [startDate]="startDate"
        [endDate]="endDate"
        [timeZone]="timeZone"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (update)="updateDates($event)"></date-picker>
    </mat-menu>
    <date-range
      [startDate]="startDate"
      [endDate]="endDate"
      [timeZone]="timeZone"
      [matMenuTriggerFor]="datepickerMenu"
      matTooltip="Change date range"></date-range>
    <!--  -->
  </div>
  <ng-container
    page-body
    *ngIf="!loading">
    <div *ngIf="reportHasData; else emptyState">
      <summary-report
        [currency]="currency"
        [metrics]="salonReport.summary"
        [enableComplianceTracking]="enableComplianceTracking"></summary-report>
      <div
        class="row gutters"
        style="margin-top: 50px">
        <section class="col col-12">
          <card cardTitle="Employee Performance">
            <div
              class="card-header-controls"
              card-header>
              <a
                disabled
                (click)="downloadEmployeePerformanceReport.emit()"
                matTooltip="Download report as spreadsheet"
                class="button secondary transparent">
                <fa-icon icon="cloud-download-alt"></fa-icon>
              </a>
            </div>
            <employee-performance-table
              [currency]="currency"
              [reports]="employeeReports"
              [measurementUnit]="measurementUnit"
              [enableComplianceTracking]="enableComplianceTracking"
              (navigateToMixed)="navigateToMixed.emit({ employeeId: $event })"
              (navigateToUnmixed)="navigateToUnmixed.emit({ employeeId: $event })"
              card-body>
            </employee-performance-table>
          </card>
        </section>
        <section class="col col-12">
          <card cardTitle="Service Report">
            <div
              class="card-header-controls"
              card-header>
              <mat-slide-toggle
                [checked]="showExcludedServices"
                (change)="toggleShowExcludedServices()">
                Show Excluded Services
              </mat-slide-toggle>
              <a
                (click)="downloadServiceReport.emit({ showExcludedServices })"
                matTooltip="Download report as spreadsheet"
                class="button secondary transparent">
                <fa-icon icon="cloud-download-alt"></fa-icon>
              </a>
            </div>
            <service-report-table
              [currency]="currency"
              [reports]="serviceReports"
              [measurementUnit]="measurementUnit"
              [showExcludedServices]="showExcludedServices"
              [enableComplianceTracking]="enableComplianceTracking"
              (navigateToMixed)="navigateToMixed.emit({ serviceId: $event })"
              (navigateToUnmixed)="navigateToUnmixed.emit({ serviceId: $event })"
              card-body>
            </service-report-table>
          </card>
        </section>
      </div>
      <div class="row gutters auto">
        <section class="col col-12">
          <card cardTitle="Vish History">
            <longitudinal-report
              [currency]="currency"
              [report]="longitudinalReport"
              [aggregatedBy]="aggregatedBy"
              [measurementUnit]="measurementUnit"
              [locale]="locale"
              [enableComplianceTracking]="enableComplianceTracking"
              (aggregation)="aggregation.emit($event)"
              card-body>
            </longitudinal-report>
          </card>
        </section>
      </div>
    </div>
    <ng-template #emptyState>
      <empty-state-message
        title="No data here"
        icon="calendar-day">
        We couldn't find any data for the selected range, adjust the dates to view data
      </empty-state-message>
    </ng-template>
  </ng-container>
</page>
