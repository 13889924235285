import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { EntityService, HttpRepositoryFactory, NotFoundError, RktError } from "@getvish/stockpile";
import { Either, left, right } from "fp-ts/lib/Either";
import { Observable, catchError, map, mergeMap, of } from "rxjs";
import { Operation, compare } from "fast-json-patch";
import { fold } from "fp-ts/lib/Option";

export class PatchingEntityService<T> extends EntityService<T> {
  constructor(
    public controllerKey: string,
    protected repositoryFactory: HttpRepositoryFactory,
    protected _http: HttpClient,
    protected _requestTransformer,
    protected _httpUrl: string
  ) {
    super(repositoryFactory, { entityKey: controllerKey });
  }

  update(doc: T): Observable<Either<Error, T>> {
    const id = (doc as any)._id;

    return this.findById(id).pipe(
      mergeMap(
        fold(
          () => of(left<RktError, T>(new NotFoundError(`Document not found with id ${id}`))),
          (record) => {
            return this.patch(id, compare(record, doc));
          }
        )
      )
    );
  }

  patch(id: string, operations: Operation[]): Observable<Either<Error, T>> {
    const endpoint = `${this._httpUrl}/${this.controllerKey}/${id}`;

    const headers = this._requestTransformer(new HttpHeaders());

    return this._http.patch<T>(endpoint, operations, { headers }).pipe(
      map((response) => right<RktError, T>(response)),
      catchError((error: HttpErrorResponse) => of(left<RktError, T>(new RktError(error.message, error.message, error.status, error.error))))
    );
  }
}
