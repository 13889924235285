import { Injectable } from "@angular/core";
import { AppointmentStatus, Formula, FormulaModule, Order, OrderLineProductDetails, SalonConfig } from "@getvish/model";
import { HttpRepositoryFactory, EntityService, HttpError, HttpRequestHandler } from "@getvish/stockpile";
import { SalonConfigService } from "app/+salon-config/services";
import { either, option } from "fp-ts";
import { Either } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import * as R from "ramda";
import { OrderLineProductDetailsVM } from "../models";
import { FormulaService } from "./formula.service";
import { fromNullable, getOrElse } from "fp-ts/lib/Option";

@Injectable()
export class OrderService extends EntityService<Order> {
  public controllerKey: string;

  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler,
    private _salonConfigService: SalonConfigService,
    private _formulaService: FormulaService
  ) {
    super(repositoryFactory, { entityKey: "orders" });
    this.controllerKey = "orders";
  }

  public loadDataForOrder(id: string): Observable<{ order: Order; salonConfig: SalonConfig }> {
    const fetchOrder$ = this.findById(id).pipe(
      mergeMap(
        option.fold(
          () => throwError(() => new Error("Order not found")),
          (order) => this._enhanceOrderLineProductDetails(order)
        )
      )
    );
    const fetchSalonConfig$ = this._salonConfigService.findOrDie();

    return forkJoin({
      order: fetchOrder$,
      salonConfig: fetchSalonConfig$,
    });
  }

  public fetchOrGenerateOrderForAppointment(appointmentId: string): Observable<{ order: Order; salonConfig: SalonConfig }> {
    const payload = { appointmentId };
    const generateTicket$ = this._requestHandler.post<Order>(`${this.controllerKey}/generate`, payload).pipe(
      mergeMap(
        either.fold(
          (error) => throwError(() => error),
          (order) => this._enhanceOrderLineProductDetails(order)
        )
      )
    );

    const findOrGenerateTicket$ = this.findOne({ appointmentId }).pipe(
      mergeMap((maybeOrder) =>
        pipe(
          maybeOrder,
          option.fold<Order, Observable<Order>>(
            () => generateTicket$,
            (order) => (order.status === AppointmentStatus.CHECKED_OUT ? this._enhanceOrderLineProductDetails(order) : generateTicket$)
          )
        )
      )
    );

    const fetchSalonConfig$ = this._salonConfigService.findOrDie();

    return forkJoin({
      order: findOrGenerateTicket$,
      salonConfig: fetchSalonConfig$,
    });
  }

  public checkout(order: Order): Observable<Either<HttpError, Order>> {
    const orderId = order._id;
    const payload = { orderId };

    return this._requestHandler.post<Order>(`${this.controllerKey}/${orderId}/checkout`, payload);
  }

  public reactivate(order: Order): Observable<Either<HttpError, Order>> {
    const orderId = order._id;
    const payload = { orderId };

    return this._requestHandler.post<Order>(`${this.controllerKey}/${orderId}/reactivate`, payload);
  }

  private _enhanceOrderLineProductDetails(order: Order): Observable<Order> {
    const performedServiceIds = pipe(
      order.orderLines,
      R.map((orderLine) => orderLine.performedServiceId),
      R.flatten
    ) as string[];

    if (R.isEmpty(performedServiceIds)) {
      return of(order);
    }

    return this._formulaService.findForPerformedServiceIds(performedServiceIds).pipe(
      map((formulas) => ({
        ...order,
        orderLines: order.orderLines.map((orderLine) => ({
          ...orderLine,
          productUsageDetails: this._createOrderLineProductDetailsVMs(
            orderLine.performedServiceId,
            formulas.filter(R.complement(FormulaModule.isDiscarded)),
            orderLine.productUsageDetails
          ),
        })),
      }))
    );
  }

  private _createOrderLineProductDetailsVMs(
    performedServiceId: string,
    formulas: Formula[],
    productUsageDetails: OrderLineProductDetails[]
  ): OrderLineProductDetailsVM[] {
    const serviceFormulas = formulas.filter((formula) => formula.performedServiceId === performedServiceId);

    const allPortions = serviceFormulas.reduce((acc, formula) => {
      acc.push(
        ...formula.productPortions.map((productPortion) => ({
          productPortion,
          formulaId: formula._id,
        }))
      );

      return acc;
    }, []);

    const productDetails = allPortions
      .map((portion) => {
        const matchingDetails = productUsageDetails.find((details) => details.productId === portion.productPortion.productId);

        if (matchingDetails) {
          const weightRatio = matchingDetails.weight === 0 ? 0 : portion.productPortion.weight / matchingDetails.weight;

          return {
            ...matchingDetails,
            weight: portion.productPortion.weight,
            retailCost: matchingDetails.retailCost * weightRatio,
            wholesaleCost: matchingDetails.wholesaleCost * weightRatio,
            formulaId: portion.formulaId,
          };
        }
      })
      .filter((details) => R.not(R.isNil(details)));

    const formulaOrders = formulas.reduce((acc, formula) => ({ ...acc, [formula._id]: formula.order }), {});
    const getOrder = (formulaId) => getOrElse(() => Number.MAX_SAFE_INTEGER)(fromNullable(formulaOrders[formulaId]));

    return R.sort((productDetails1, productDetails2) => {
      const order1 = getOrder(productDetails1.formulaId);
      const order2 = getOrder(productDetails2.formulaId);

      return order1 - order2;
    }, productDetails);
  }
}
