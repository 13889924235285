import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Notification } from "app/+notifications/models";
import { loadNotifications, markAsRead } from "app/+notifications/store/notifications.actions";
import { getNotifications } from "app/+notifications/store/notifications.selectors";
import { AppState } from "app/kernel";
import { sort } from "ramda";
import { map, Observable, take } from "rxjs";

@Component({
  selector: "notification-bell",
  templateUrl: "notification-bell.container.html",
  styleUrls: ["./notification-bell.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBellContainer {
  public isOpen = false;
  public notifications$: Observable<Notification[]>;
  public badgeText$: Observable<string>;

  public notificationsForPopup$?: Observable<Notification[]>;

  constructor(private _store: Store<AppState>) {
    _store.dispatch(loadNotifications());

    this.notifications$ = _store.select(getNotifications).pipe(map(sort((a, b) => b.date.getTime() - a.date.getTime())));

    this.badgeText$ = this.notifications$.pipe(
      map((notifications) => notifications.filter((notification) => notification.isRead !== true)),
      map((notifications) => (notifications.length > 0 ? `${notifications.length}` : undefined))
    );
  }

  public doNotificationAction(notification: Notification): void {
    if (notification.action != null) {
      this.isOpen = false;
      notification.action();
    }
  }

  public toggleOpen(): void {
    if (this.isOpen) {
      this.isOpen = false;
    } else {
      this.notifications$.pipe(take(1)).subscribe((notifications) => {
        this.notificationsForPopup$ = this.notifications$.pipe(
          map((n) =>
            n.map((notification) => {
              const matchingNotification = notifications.find((_n) => _n.id === notification.id);

              return {
                ...notification,
                isRead: matchingNotification?.isRead ?? notification.isRead,
              };
            })
          )
        );

        this.isOpen = true;

        if (notifications.some((notification) => notification.isRead !== true)) {
          this._store.dispatch(
            markAsRead({
              notifications: notifications.filter((notification) => notification.isRead !== true),
            })
          );
        }
      });
    }
  }
}
