import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterContentInit,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { ListOrdererItemDirective } from "../directives/list-orderer-item.directive";
import { Subscription } from "rxjs";

export type ListOrdererItem = {
  id: string;
  text: string;
  classes?: string[];
  prefix?: TemplateRef<any>;
};

@Component({
  selector: "list-orderer",
  templateUrl: "list-orderer.component.html",
  styleUrls: ["list-orderer.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOrdererComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(ListOrdererItemDirective)
  public items: QueryList<ListOrdererItemDirective>;

  @Output() public orderChanged: EventEmitter<ListOrdererItem[]>;

  public _items: ListOrdererItem[];
  private itemSub?: Subscription;

  constructor(private _cd: ChangeDetectorRef) {
    this.orderChanged = new EventEmitter<ListOrdererItem[]>();
  }

  public ngAfterContentInit(): void {
    this._itemsChanged();
    this.itemSub = this.items.changes.subscribe(() => this._itemsChanged());
  }

  private _itemsChanged() {
    this._items = this.items.map((item) => ({
      id: item.id,
      text: item.text,
      classes: item.classes,
      prefix: item.prefixDirective?.prefix,
    }));

    this._cd.markForCheck();
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this._items, event.previousIndex, event.currentIndex);
    this.orderChanged.emit(this._items);
  }

  public ngOnDestroy(): void {
    if (this.itemSub) {
      this.itemSub.unsubscribe();
      this.itemSub = undefined;
    }
  }
}
