import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { SharedModule } from "app/kernel/shared";
import { KernelServicesModule } from "../../kernel";
import { AddManufacturerCardComponent } from "./components";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { ProductsCategoriesListComponent } from "./components/products-categories-list/products-categories-list.component";
import { SalonProductsListComponent } from "./components/salon-products-list.component";
import { SalonProductCategoryService } from "./services/salon-product-category.service";
import { SalonProductService } from "./services/salon-product.service";

@NgModule({
  imports: [SharedModule, KernelServicesModule, MatCardModule],
  declarations: [BreadcrumbsComponent, ProductsCategoriesListComponent, AddManufacturerCardComponent, SalonProductsListComponent],
  exports: [BreadcrumbsComponent, ProductsCategoriesListComponent, AddManufacturerCardComponent],
  providers: [SalonProductService, SalonProductCategoryService],
})
export class SalonProductsSharedModule {}
