import { NgModule } from "@angular/core";
import { DataTableModule } from "app/+components";
import { KernelServicesModule } from "../../kernel";
import { SharedModule } from "../../kernel/shared";
import { ProductsListComponent } from "./components";
import { ProductsContainer } from "./containers";
import { DiscontinuedProductService, ProductService } from "./services";

@NgModule({
  imports: [DataTableModule, KernelServicesModule, SharedModule],
  declarations: [ProductsContainer, ProductsListComponent],
  exports: [ProductsContainer, ProductsListComponent],
  providers: [DiscontinuedProductService, ProductService],
})
export class ProductsSharedModule {}
