<div
  *ngFor="let category of _categoryNodes; let idx = index; trackBy: trackByFn"
  class="recursive-container"
  [ngClass]="category.isRoot ? 'root-node' : 'child-node'">
  <ul
    [ngClass]="{ 'root-node': category.isRoot }"
    class="fa-ul">
    <li>
      <div
        (click)="toggle.emit(category)"
        class="actions-container"
        [ngClass]="{ 'root-node': category.isRoot }">
        <span class="fa-li fa-lg">
          <fa-icon
            *ngIf="!category.isRoot"
            [icon]="['fas', isActive(category, activeCategoryIds) ? 'caret-down' : 'caret-right']"></fa-icon>
        </span>
        <span
          [ngClass]="category.isRoot ? 'root-node' : 'child-node'"
          class="node-name"
          >{{ category.name }}</span
        >

        <div class="more-menu-container">
          <ng-container
            *ngIf="categoryMenuTemplate"
            [ngTemplateOutlet]="categoryMenuTemplate"
            [ngTemplateOutletContext]="{ category: category }">
          </ng-container>
        </div>
      </div>
      <ng-container
        *ngIf="isActive(category, activeCategoryIds) && category.isTerminal"
        [ngTemplateOutletContext]="{ products: category.products }"
        [ngTemplateOutlet]="productListTemplate">
      </ng-container>
      <recursive-categories-list
        *ngIf="isActive(category, activeCategoryIds)"
        [categoryNodes]="category.children"
        [productListTemplate]="productListTemplate"
        [categoryMenuTemplate]="categoryMenuTemplate"
        [activeCategoryIds]="activeCategoryIds"
        (select)="select.emit($event)"
        (addProduct)="addProduct.emit($event)"
        (editProduct)="editProduct.emit($event)"
        (addCategory)="addCategory.emit($event)"
        (toggle)="toggle.emit($event)">
      </recursive-categories-list>
    </li>
  </ul>
</div>
