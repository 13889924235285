import { ContentChildren, Directive, QueryList } from "@angular/core";
import { RowDirective } from "./rows.directive";

@Directive({
  selector: "footer",
})
export class FooterDirective {
  @ContentChildren(RowDirective)
  public values: QueryList<RowDirective>;
}
