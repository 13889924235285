import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent, ConfirmDialogData } from "../components/confirm-dialog.component";
import { Observable, Subject } from "rxjs";

interface ConfirmDialogOptions {
  title?: string;
  message?: string;
  actionText?: string;
  cancelText?: string;
}

@Injectable()
export class ConfirmDialogService {
  constructor(private _matDialog: MatDialog) {}

  public open(options: ConfirmDialogOptions): {
    closeFn: () => void;
    onAction$: Observable<void>;
    onCancel$: Observable<void>;
  } {
    const onCancel$ = new Subject<void>();
    const onAction$ = new Subject<void>();

    const cancelFn = () => {
      onCancel$.next();
      onCancel$.complete();
    };

    const actionFn = () => {
      onAction$.next();
      onAction$.complete();
    };

    const config: ConfirmDialogData = {
      ...options,
      onAction: actionFn,
      onCancel: cancelFn,
    };

    const dialogRef = this._matDialog.open(ConfirmDialogComponent, {
      data: config,
    });

    return {
      closeFn: dialogRef.close,
      onAction$: onAction$.asObservable(),
      onCancel$: onCancel$.asObservable(),
    };
  }
}
