import { NgModule } from "@angular/core";
import { DialogContentsComponent } from "./components/dialog-contents.component";
import { SharedModule } from "app/kernel/shared";
import { ProgressIndicatorModule } from "../+progress-indicator";

@NgModule({
  declarations: [DialogContentsComponent],
  imports: [ProgressIndicatorModule, SharedModule],
  exports: [DialogContentsComponent],
})
export class DialogModule {}
