import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ContentChildren, Directive, QueryList } from "@angular/core";
import { JsonValue } from "@getvish/stockpile";
import { ColumnDirective } from "app/+components/+data-table/directives";

@Directive({
  selector: "select-filter-option",
})
export class SelectFilterOptionDirective {
  @Input()
  value: string;
  @Input()
  title?: string;
}

@Component({
  selector: "select-filter",
  templateUrl: "select-filter.component.html",
  styleUrls: ["select-filter.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterComponent {
  @Input()
  public value: JsonValue;

  @Input()
  public column: ColumnDirective;

  @ContentChildren(SelectFilterOptionDirective)
  public options: QueryList<SelectFilterOptionDirective>;

  @Output()
  public filter: EventEmitter<Array<string>>;

  constructor() {
    this.filter = new EventEmitter();
  }

  public updateFilter(value: Array<string>): void {
    this.filter.emit(Array.isArray(value) && value.length === 0 ? undefined : value);
  }
}
