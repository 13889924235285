import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DiscontinuedProduct, Product } from "@getvish/model";
import { select, Store } from "@ngrx/store";
import { ProductReplacement } from "app/+product/+product-selection/components";
import { AppState } from "app/kernel";
import { combineLatest, Observable, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { back } from "../../../kernel/store/actions/router.actions";
import { deleteDiscontinue, discontinue, init } from "../store/discontinue-product.actions";
import * as fromDiscontinueProduct from "../store/discontinue-product.selector";

@Component({
  selector: "discontinue-product-container",
  templateUrl: "discontinue-product.container.html",
  styleUrls: ["discontinue-product.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscontinueProductContainer implements OnInit, OnDestroy {
  public product$: Observable<Product>;
  public saving$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public error$: Observable<Error>;
  public discontinuedProduct$: Observable<DiscontinuedProduct>;

  public form: UntypedFormGroup;

  public subscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    private _route: ActivatedRoute,
    private _fb: UntypedFormBuilder
  ) {
    this.product$ = _store.pipe(select(fromDiscontinueProduct.getProduct));

    _route.params
      .pipe(
        map((params) => params["id"] as string),
        take(1)
      )
      .subscribe((id) => {
        this._store.dispatch(init({ productId: id }));
      });

    this.saving$ = _store.pipe(select(fromDiscontinueProduct.getSaving));
    this.loading$ = _store.pipe(select(fromDiscontinueProduct.getLoading));
    this.error$ = _store.pipe(select(fromDiscontinueProduct.getError));
    this.discontinuedProduct$ = _store.pipe(select(fromDiscontinueProduct.getDiscontinuedProduct));
  }

  public ngOnInit(): void {
    this.form = this._fb.group({
      date: [new Date(), Validators.required],
      replacements: [],
    });

    this.subscription = combineLatest([
      this._store.pipe(select(fromDiscontinueProduct.getDiscontinuedProduct)),
      this._store.pipe(select(fromDiscontinueProduct.getReplacements)),
    ]).subscribe(([discontinuedProduct, replacements]) => {
      if (discontinuedProduct?.discontinuedDate != null) {
        this.form.patchValue({
          date: new Date(discontinuedProduct.discontinuedDate),
        });
      }

      if (replacements != null) {
        this.form.patchValue({ replacements });
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public close(): void {
    this._store.dispatch(back());
  }

  public saveForm(
    data: {
      date: Date;
      replacements: ProductReplacement[];
    },
    product: Product,
    discontinuedProduct: DiscontinuedProduct
  ): void {
    this._store.dispatch(discontinue({ product, date: data.date, replacements: data.replacements, discontinuedProduct }));
  }

  public deleteDiscontinue(discontinuedProduct: DiscontinuedProduct): void {
    this._store.dispatch(deleteDiscontinue({ discontinuedProduct }));
  }
}
