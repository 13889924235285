import { Component, ChangeDetectionStrategy } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { Salon } from "@getvish/model";
import { CsvUploadFileType } from "../store/import-salon-data.actions";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "app/kernel";

import * as fromImportSalonData from "../store/import-salon-data.selectors";
import * as Actions from "../store/import-salon-data.actions";

@Component({
  selector: "import-salon-data-container",
  templateUrl: "./import-salon-data.container.html",
  styleUrls: ["./import-salon-data.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportSalonDataContainer {
  public salon$: Observable<Salon>;
  public loading$: Observable<boolean>;
  public exporting$: Observable<boolean>;
  public step$: Observable<number>;
  public uploading$: Observable<boolean>;
  public report$: (CsvUploadFileType) => Observable<{ success: boolean }>;
  public reports$: Observable<{ [key: string]: { success: boolean } }>;
  public error$: Observable<string>;

  public salonIdSubscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.salon$ = _store.pipe(select(fromImportSalonData.getSalon));
    this.loading$ = _store.pipe(select(fromImportSalonData.getLoading));
    this.exporting$ = _store.pipe(select(fromImportSalonData.getExporting));
    this.step$ = _store.pipe(select(fromImportSalonData.getStep));
    this.uploading$ = this._store.pipe(select(fromImportSalonData.getUploading));
    this.report$ = (key: CsvUploadFileType) => this._store.pipe(select(fromImportSalonData.getReport(key)));
    this.reports$ = _store.pipe(select(fromImportSalonData.getReports));
    this.error$ = _store.pipe(select(fromImportSalonData.getError));

    this.salonIdSubscription = _route.paramMap
      .pipe(
        map((params) => params.get("id")),
        map((id) => new Actions.LoadSalon({ id }))
      )
      .subscribe((action) => this._store.dispatch(action));
  }

  public close(): void {
    this._store.dispatch(new Actions.Done());
  }

  public exportData(salon: Salon): void {
    this._store.dispatch(new Actions.ExportData({ salon }));
  }

  public uploadFile(payload: { file: File; type: CsvUploadFileType }, salon: Salon): void {
    this._store.dispatch(new Actions.UploadCsv({ type: payload.type, file: payload.file, salon }));
  }

  public initializeSession(salon: Salon): void {
    this._store.dispatch(new Actions.InitializeSession({ salon }));
  }

  public next(): void {
    this._store.dispatch(new Actions.Next());
  }

  public runSession(salon: Salon): void {
    this._store.dispatch(new Actions.RunSession({ salon }));
  }

  public done(): void {
    this._store.dispatch(new Actions.Done());
  }
}
