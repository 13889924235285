<slideout-panel (close)="close()">
  <div panel-header>Resolve Alerts</div>
  <div panel-body>
    <resolve-appointments
      [loading]="loading$ | async"
      [appointments]="appointments$ | async"
      [serviceDescriptions]="serviceDescriptions$ | async"
      [timeZone]="timeZone$ | async"
      (resolve)="resolve($event)"></resolve-appointments>
  </div>
</slideout-panel>
