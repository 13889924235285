import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { MeasurementUnit, ServiceCategory, ServiceCategoryModule, ServiceDescription } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { equals, groupBy, isNil, not } from "ramda";
import { Observable, Subject, Subscription, combineLatest, zip } from "rxjs";
import { distinctUntilChanged, map, takeUntil, withLatestFrom } from "rxjs/operators";
import { AppState } from "../../kernel/store";

import { Paging, Selections } from "app/+components/+data-table";
import { isPartsAndLabor, ProductAllowanceVM } from "app/kernel/models";
import { getParamMap } from "app/kernel/store/selectors/router.selectors";
import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";
import * as editServiceActions from "../store/edit-service.actions";
import * as editServicesActions from "../store/edit-services.actions";
import * as actions from "../store/service-menu.actions";
import * as fromServiceMenu from "../store/service-menu.selectors";
import { calculateProductAllowanceTotal } from "../utils";
import { ServiceComplianceAnomaly } from "../model";
import { JsonObject } from "@getvish/stockpile";

interface ServiceDescriptionVM extends ServiceDescription {
  selected: boolean;
  currency: string;
}

@Component({
  selector: "service-menu-list-container",
  templateUrl: "service-menu-list.container.html",
  styleUrls: ["service-menu-list.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceMenuListContainer implements OnDestroy {
  public showInactive$: Observable<boolean>;
  public uncategorizedOnly$: Observable<boolean>;
  public complianceAnomalies$: Observable<ServiceComplianceAnomaly[]>;
  public selectedRecords$: Observable<ServiceDescription[]>;
  public selectedPaRecords$: Observable<ServiceDescription[]>;
  public tableFilter$: Observable<JsonObject>;
  public loading$: Observable<boolean>;
  public saving$: Observable<boolean>;
  public filter$: Observable<string>;

  private destroy$ = new Subject<void>();

  public limit: number;

  private urlParamsSubscription: Subscription;

  public paging$: Observable<Paging>;
  public selections$: Observable<Selections>;
  public serviceDescriptions$: Observable<ServiceDescriptionVM[]>;
  public selectedCategories$: Observable<string[]>;
  public serviceCategories$: Observable<ServiceCategory[]>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public productAllowances$: Observable<{ [serviceId: string]: ProductAllowanceVM }>;
  public preparingExport$: Observable<boolean>;

  private tableFilter: JsonObject;

  constructor(
    private _store: Store<AppState>,
    public _router: Router
  ) {
    this.limit = 50;

    this.selectedRecords$ = _store.select(fromServiceMenu.getSelectedRecords);
    this.productAllowances$ = _store.pipe(select(fromServiceMenu.getProductAllowances));

    this.selectedPaRecords$ = combineLatest([this.selectedRecords$, this.productAllowances$]).pipe(
      map(([sds, pas]) => sds.filter((sd) => !isPartsAndLabor(pas?.[sd._id], sd)))
    );
    this.loading$ = _store.select(fromServiceMenu.getLoading);
    this.saving$ = _store.select(fromServiceMenu.getSaving);
    this.filter$ = _store.select(fromServiceMenu.getFilter);
    this.preparingExport$ = _store.select(fromServiceMenu.isPreparingExport);

    const count$ = _store.select(fromServiceMenu.getPaging).pipe(
      map((paging) =>
        pipe(
          paging,
          option.map((paging) => paging.count),
          option.getOrElse(() => 0)
        )
      )
    );

    const currency$ = _store.pipe(select(fromSalonConfig.getCurrency));

    this.uncategorizedOnly$ = this._store.pipe(select(getParamMap)).pipe(
      map((params) => params.get("uncategorizedOnly")),
      map(equals("true"))
    );

    this.complianceAnomalies$ = _store.pipe(select(fromServiceMenu.getComplianceAnomalies));

    this.showInactive$ = this._store.pipe(select(getParamMap)).pipe(
      map((params) => params.get("showInactive")),
      map(equals("true"))
    );

    this.serviceDescriptions$ = combineLatest([
      currency$,
      _store.pipe(select(fromServiceMenu.getServiceDescriptions)),
      this.selectedRecords$,
    ]).pipe(
      map(([currency, serviceDescriptions, selectedRecords]) => {
        return serviceDescriptions.map((serviceDescription) => {
          const selected = selectedRecords.some((record) => record._id === serviceDescription._id);

          return {
            ...serviceDescription,
            currency,
            selected,
          };
        });
      })
    );

    this.selectedCategories$ = _store.select(fromServiceMenu.getSelectedCategories);
    this.serviceCategories$ = _store.pipe(select(fromServiceMenu.getServiceCategories));
    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));

    this.tableFilter$ = this._store.pipe(select(fromServiceMenu.getTableFilter));

    const currPage$ = this._store.pipe(select(getParamMap)).pipe(
      map((params) => params.get("page")),
      map((page) => (not(isNil(page)) ? Number(page) : 1))
    );

    const queryParamFilter$ = this._store.pipe(
      select(getParamMap),
      map((params) => params.get("filter"))
    );

    const queryParamTableFilter$ = this._store.pipe(
      select(getParamMap),
      map((params) => (params.get("tableFilter") != null ? JSON.parse(params.get("tableFilter")) : undefined))
    );

    this.urlParamsSubscription = zip(currPage$, queryParamFilter$, queryParamTableFilter$, this.showInactive$, this.uncategorizedOnly$)
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        map(
          ([page, filter, tableFilter, showInactive, uncategorizedOnly]) =>
            new actions.LoadAll({ page, limit: this.limit, filter, tableFilter, showInactive, uncategorizedOnly })
        )
      )
      .subscribe((action) => this._store.dispatch(action));

    this.paging$ = combineLatest([count$, currPage$]).pipe(
      map(([count, currentPage]) => {
        return { count, currentPage, limit: this.limit, changePage: this.navigateToPage.bind(this) };
      })
    );

    this.selections$ = this.selectedRecords$.pipe(
      map((selectedRecords) => {
        return {
          selection: {
            count: selectedRecords.length,
            clearSelections: this.clearSelected.bind(this),
          },
        };
      })
    );

    this.tableFilter$.pipe(takeUntil(this.destroy$)).subscribe((tf) => {
      this.tableFilter = tf;
    });
  }

  public clearSelected(): void {
    this._store.dispatch(new actions.ClearSelectedServices());
  }

  public toggleSelected(serviceDescription: ServiceDescription): void {
    this._store.dispatch(new actions.ToggleSelected(serviceDescription));
  }

  public toggleSelectedCategory(categoryId: string, services: ServiceDescriptionVM[]): void {
    this._store.dispatch(new actions.ToggleSelectedCategory({ categoryId, services }));
  }

  public updateManyToggleActive(): void {
    this._store.dispatch(new actions.UpdateManyToggleIsActive());
  }

  public calculateProductAllowance(): void {
    this._store.dispatch(new actions.CalculateProductAllowance());
  }

  public editServices(): void {
    this._store.dispatch(editServicesActions.navigateEditServicesPanel());
  }

  public addService(): void {
    this._store.dispatch(new editServiceActions.Navigate());
  }

  public togglePartsAndLabor(serviceDescription?: ServiceDescription): void {
    this._store.dispatch(new actions.TogglePartsAndLabor({ serviceDescription }));
  }

  public edit(service: ServiceDescription): void {
    this._store.dispatch(new editServiceActions.Navigate({ serviceDescriptionId: service._id }));
  }

  public toggleUncategorizedOnly(): void {
    this._store.dispatch(new actions.ToggleUncategorizedOnly());
  }

  public toggleShowInactive(): void {
    this._store.dispatch(new actions.ToggleShowInactive());
  }

  public navigateToPage(page: number): void {
    this._store.dispatch(new actions.NavigateToPage({ page }));
  }

  public updateFilter(filter: string): void {
    this._store.dispatch(new actions.Search({ filter, limit: this.limit }));
  }

  public updateTableFilter(_filter: JsonObject): void {
    const newFilter = { ...(this.tableFilter ?? {}) };
    Object.keys(_filter).forEach((k) => {
      newFilter[k] = _filter[k] == null ? undefined : _filter[k];
    });

    this._store.dispatch(new actions.UpdateTableFilter(newFilter));
  }

  public downloadServiceMenuReport(): void {
    this._store.dispatch(new actions.DownloadServiceMenuReport());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.urlParamsSubscription.unsubscribe();
  }

  public getRootCategoryIfServiceHasCategory(
    service: ServiceDescription,
    serviceCategories: ServiceCategory[]
  ): ServiceCategory | undefined {
    const rootCat = isNil(service.category)
      ? undefined
      : pipe(
          ServiceCategoryModule.getRootCategory(service.category, serviceCategories),
          option.getOrElse(() => {
            return undefined;
          })
        );

    return rootCat;
  }

  public getGroupedServices(serviceDescriptions: ServiceDescriptionVM[], serviceCategories: ServiceCategory[]) {
    const groupedServices = groupBy((service: ServiceDescriptionVM) => {
      return this.getRootCategoryIfServiceHasCategory(service, serviceCategories)?._id;
    }, serviceDescriptions);

    return groupedServices;
  }

  public openCalculator(service: ServiceDescription): void {
    this._router.navigate(["/service-menu/pa-calculator"], { queryParams: { ids: service._id } });
  }

  public formatFlag(flag: string): string {
    return flag.replace(/_/g, " ");
  }

  public getClassNamesForFlag(flag: string): string {
    let className = "flag";
    if (flag === "DEFAULT") {
      className = `${className} default`;
    } else if (flag === "EXCLUDE_FROM_ANALYTICS") {
      className = `${className} exclude`;
    }

    return className;
  }

  public getAllowance(serviceDescription: ServiceDescription) {
    return this.productAllowances$.pipe(
      map((productAllowances) => {
        if (productAllowances?.[serviceDescription._id]?.blueprints.length) {
          return calculateProductAllowanceTotal(productAllowances[serviceDescription._id]);
        }

        return serviceDescription.productAllowance;
      })
    );
  }

  public getProgressText(): Observable<string | undefined> {
    return this.loading$.pipe(
      withLatestFrom(this.preparingExport$, this.saving$),
      map(([loading, preparingExport, saving]) => {
        if (loading) {
          return "Loading...";
        } else if (preparingExport) {
          return "Preparing Report...";
        } else if (saving) {
          return "Saving...";
        }

        return undefined;
      })
    );
  }

  public serviceDescriptionTrackBy(idx: number, serviceDescription: ServiceDescription) {
    return serviceDescription._id;
  }

  public isPartsAndLabor(pa: ProductAllowanceVM, sd: ServiceDescription): boolean {
    return isPartsAndLabor(pa, sd);
  }

  public getNumberOfMixAnomalies(serviceId: string, complianceAnomalies: ServiceComplianceAnomaly[]): number {
    return complianceAnomalies?.find((anomaly) => anomaly.serviceDescription._id === serviceId)?.numMixedServices ?? 0;
  }

  public getAnomalyTooltip(serviceId: string, complianceAnomalies: ServiceComplianceAnomaly[]): string {
    const numAnomalies = this.getNumberOfMixAnomalies(serviceId, complianceAnomalies);

    return numAnomalies === 1
      ? "There has been a mix against this service in the last 30 days."
      : `There have been ${numAnomalies} mixes against this service in the last 30 days.`;
  }
}
