import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DiscontinueProductState, featureKey } from "./discontinue-product.reducer";

export const getState = createFeatureSelector<DiscontinueProductState>(featureKey);

export const getSaving = createSelector(getState, (state) => state.saving);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getError = createSelector(getState, (state) => state.error);
export const getProduct = createSelector(getState, (state) => state.product);
export const getDiscontinuedProduct = createSelector(getState, (state) => state.discontinuedProduct);
export const getReplacements = createSelector(getState, (state) => state.replacements);
