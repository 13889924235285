import { NgModule, ModuleWithProviders } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule, RouterStateSerializer } from "@ngrx/router-store";
import { CustomRouterStateSerializer } from "./util";
import { reducers } from "./reducers";
import { effects } from "./effects";
import { SharedModule } from "../shared";
import { clearStateOnLogout } from "./reducers/logout.metareducer";

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers: [clearStateOnLogout] }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ maxAge: 250 }),
    StoreRouterConnectingModule.forRoot(),
  ],
})
export class CoreStoreModule {
  public static forRoot(): ModuleWithProviders<CoreStoreModule> {
    return {
      ngModule: CoreStoreModule,
      providers: [{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer }, StoreModule],
    };
  }
}
