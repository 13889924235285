<page
  [progressText]="getProgressText() | async"
  pageTitle="Today's Appointments">
  <header-controls page-header>
    <div class="search-input-control">
      <search-input
        [searchFilter]="filter$ | async"
        (filter)="updateFilter($event)"
        placeholder="Search by employee or customer..."></search-input>
    </div>
    <button
      class="button outline"
      (click)="new()">
      New Appointment
    </button>
    <mat-menu #moreOptionsMenu="matMenu">
      <button
        mat-menu-item
        *ngIf="hasChangedAppointments$ | async"
        (click)="resolveAll()">
        Resolve All Alerts
      </button>
    </mat-menu>
    <button
      [matMenuTriggerFor]="moreOptionsMenu"
      *ngIf="hasChangedAppointments$ | async"
      class="button secondary transparent">
      <fa-icon icon="ellipsis-v"></fa-icon>
    </button>
  </header-controls>
  <ng-container
    page-body
    *ngIf="(loading$ | async) === false && (appointments$ | async) as appointments">
    <card class="tabs-container">
      <ng-container card-body>
        <!-- <div class="table-header-controls">
          <mat-slide-toggle
            [checked]="alertsOnly$ | async"
            (change)="toggleAlertsOnly()"
            >Alerts Only</mat-slide-toggle
          >
        </div> -->
        <mat-tab-group
          [selectedIndex]="selectedTabIndex$ | async"
          (selectedIndexChange)="tabChanged($event)">
          <mat-tab
            *ngFor="let tab of tabs"
            [label]="tab.label"></mat-tab>
        </mat-tab-group>

        <appointment-row
          *ngFor="let appointment of appointments"
          [appointment]="appointment"
          [timeZone]="timeZone$ | async"
          (select)="select($event)"
          (delete)="delete($event)"
          (resolve)="resolve($event)">
        </appointment-row>

        <empty-state-message
          *ngIf="appointments | isEmpty"
          title="No appointments here"
          icon="calendar-day">
          Adjust your filter or search to view more appointments, or
          <button
            class="button link"
            (click)="new()">
            add a new appointment
          </button>
        </empty-state-message>
      </ng-container>
    </card>
  </ng-container>
</page>
