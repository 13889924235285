import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { isNil } from "ramda";

export interface ConfirmDialogData {
  title?: string;
  message?: string;

  actionText?: string;
  onAction?: () => void;

  cancelText?: string;
  onCancel?: () => void;
}

@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.less"],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  public actionClicked(): void {
    if (!isNil(this.data.onAction)) {
      this.data.onAction();
      this._close();
    }
  }

  public cancelClicked(): void {
    if (!isNil(this.data.onCancel)) {
      this.data.onCancel();
      this._close();
    }
  }

  private _close(): void {
    this.dialogRef.close();
  }
}
