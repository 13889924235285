import { Order, OrderLine } from "@getvish/model";

import * as order from "./order.actions";
import { AppointmentVM } from "../models/appointment";

export interface OrderState {
  selectedAppointment: AppointmentVM;
  selectedOrder: Order;
  selectedOrderId: string;
  selectedOrderLine: OrderLine;
  loading: boolean;
  checkingOut: boolean;
  reactivating: boolean;
  resolving: boolean;
  error: string;
}

const initialState: OrderState = {
  selectedAppointment: undefined,
  selectedOrder: undefined,
  selectedOrderId: undefined,
  selectedOrderLine: undefined,
  loading: false,
  checkingOut: false,
  reactivating: false,
  resolving: false,
  error: undefined,
};

export function orderReducer(state: OrderState = initialState, action: order.Actions): OrderState {
  switch (action.type) {
    case order.navigateToOrder.type:
    case order.generate.type:
    case order.generateOrLoadForAppointment.type:
    case order.load.type: {
      return {
        ...state,
        loading: true,
      };
    }

    case order.Types.GENERATE_SUCCESS: {
      return {
        ...state,
        selectedOrder: action.payload.order,
        selectedOrderId: action.payload.order._id,
        selectedAppointment: action.payload.appointment,
        loading: false,
      };
    }
    case order.loadSuccess.type: {
      const selectedOrderId = action.payload._id;
      const selectedOrder = action.payload;

      return {
        ...state,
        selectedOrder,
        selectedOrderId,
        loading: false,
      };
    }

    case order.Types.GENERATE_FAIL: {
      const error: string = action.payload.error["payload"];

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case order.checkout.type: {
      return {
        ...state,
        checkingOut: true,
      };
    }
    case order.reactivate.type: {
      return {
        ...state,
        reactivating: true,
      };
    }

    case order.Types.REACTIVATE_SUCCESS: {
      return {
        ...state,
        reactivating: false,
        selectedOrder: action.payload,
      };
    }

    case order.Types.CHECKOUT_SUCCESS: {
      return {
        ...state,
        checkingOut: false,
        selectedOrder: action.payload,
      };
    }

    case order.done.type: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}
