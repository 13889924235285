import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter, OnChanges, OnInit } from "@angular/core";
import { ProductReport, ProductCategoryReport, productReportIsEmpty } from "../../kernel/models";
import { ReportStatistic } from "app/kernel/models/report-statistic";
import { map, take, isNil, sortBy, reverse, pipe, not } from "ramda";
import { Manufacturer, MeasurementUnit, ProductCategory, SalonProduct } from "@getvish/model";
import { colorScheme } from "../common";
import { getDateInTimeZone, getTodayInTimeZone } from "app/kernel/util/zoned-time-utils";

@Component({
  selector: "product-report-dashboard",
  templateUrl: "product-report-dashboard.component.html",
  styleUrls: ["product-report-dashboard.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductReportDashboardComponent implements OnChanges, OnInit {
  @Input() public categoryReport: ProductCategoryReport;
  @Input() public productReport: ProductReport;
  @Input() public salonProducts: SalonProduct[];
  @Input() public measurementUnit: MeasurementUnit;
  @Input() public loading: boolean;
  @Input() public manufacturerId: string;
  @Input() public manufacturer: Manufacturer;
  @Input() public rootCategoryMap: Map<string, ProductCategory>;
  @Input() public startDate: number;
  @Input() public endDate: number;
  @Input() public currency: string;
  @Input() public timeZone: string;

  @Output() public moreProducts: EventEmitter<string>;
  @Output() public downloadReport: EventEmitter<void>;
  @Output() public updateDateRange: EventEmitter<{ startDate: number; endDate: number }>;

  public data: Array<{ name: string; value: number }> = [];
  public productData: Array<{ name: string; value: number }> = [];

  public topCategoryStatistics: ReportStatistic[];
  public colorScheme = colorScheme;

  public sortItems = sortBy((item: ReportStatistic) => item.metrics.wholesaleCostDollars);

  public minDate: Date;
  public maxDate: Date;
  public reportHasData: boolean;

  constructor() {
    this.moreProducts = new EventEmitter(true);
    this.downloadReport = new EventEmitter(true);
    this.updateDateRange = new EventEmitter(true);
  }

  public reverseItems = (items: ReportStatistic[]) => reverse(items);

  public ngOnInit(): void {
    this.minDate = getDateInTimeZone(this.timeZone, new Date(2000, 0, 1));
    this.maxDate = getTodayInTimeZone(this.timeZone);
  }

  public ngOnChanges(): void {
    if (isNil(this.categoryReport)) {
      return;
    }

    this.data = this._mapProductStatisticsToChartData(this.categoryReport.statistics);
    this.productData = this._mapProductStatisticsToChartData(this.productReport.productStatistics);

    const mappingFn = (_statistics: ReportStatistic[]) => map((item: ReportStatistic) => item)(_statistics);

    this.topCategoryStatistics = pipe(this.sortItems, this.reverseItems, mappingFn)(this.categoryReport.statistics);

    this.reportHasData = not(productReportIsEmpty(this.productReport));
  }

  private _mapProductStatisticsToChartData(statistics: ReportStatistic[]): Array<{ name: string; value: number }> {
    const sortItems = this.sortItems;
    const reverseItems = this.reverseItems;
    const mappingFn = (_statistics: ReportStatistic[]) =>
      map((item: ReportStatistic) => ({ name: item.entityName, value: item.metrics.wholesaleCostDollars }))(_statistics);

    return pipe(sortItems, reverseItems, take(20), mappingFn)(statistics);
  }

  public isProductReport(productReport: ProductReport): boolean {
    return productReport ? productReport.summary.numServicesPerformed > 0 : false;
  }
}
