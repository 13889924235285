import { NgModule } from "@angular/core";
import { SharedModule } from "app/kernel/shared";
import { HeroBoxComponent } from "./components/hero-box.component";
import { HeroBoxesComponent } from "./components/hero-boxes.component";

@NgModule({
  declarations: [HeroBoxesComponent, HeroBoxComponent],
  imports: [SharedModule],
  exports: [HeroBoxesComponent, HeroBoxComponent],
})
export class HeroBoxesModule {}
