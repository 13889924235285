import { Injectable } from "@angular/core";
import { SalonConfig } from "@getvish/model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { BillingPortalSessionService } from "app/+salons/services/billing-portal.service";
import { either, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { flatten } from "ramda";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { SalonService } from "../../+salons/services";
import { AppState, GlobalSettingsService, SnackbarService, WindowService } from "../../kernel";
import { CurrencyService, SalonConfigService, TimezoneService } from "../services";

import * as snackbarActions from "../../kernel/store/actions/snackbar.actions";
import * as fromAuth from "../../+auth/store/auth.selectors";
import * as fromCommon from "../../kernel/store/reducers/common.reducer";
import * as SalonConfigActions from "./salon-config.actions";
import { EmployeeService } from "app/+employees/services";
import { isAdmin } from "app/+auth/services";
import { Salon } from "app/+salons/models";

@Injectable()
export class SalonConfigEffects {
  public loadCurrentSalon$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.LOAD_CURRENT_SALON),
      map((action: SalonConfigActions.LoadCurrentSalon) => action.salonConfig),
      withLatestFrom(this._store.pipe(select(fromCommon.getSlug)), this._store.pipe(select(fromAuth.getUser))),
      switchMap(([salonConfig, slug, user]) => {
        const loadSalonConfig$ = salonConfig ? of(option.fromNullable(salonConfig)) : this._salonConfigService.findOne({});

        const loadSalon$ = isAdmin(user)
          ? this._salonService.findOne({ slug })
          : this._employeeService.listTenants(user._id).pipe(map((salons) => option.some(salons.find((salon) => salon.slug === slug))));

        const loadSalonWithGeometry$ = loadSalon$.pipe(
          switchMap((maybeSalon) => {
            return option.fold(
              () => of(maybeSalon),
              (salon: Salon) => {
                return this._salonService.getLocation(salon._id).pipe(
                  map((geometry) => option.some({ ...salon, geometry })),
                  catchError(() => of(option.some(salon)))
                );
              }
            )(maybeSalon);
          })
        );

        const loadTimezones$ = this._timeZoneService.find({});
        const loadCurrencies$ = this._currencyService.getCurrencies();

        return forkJoin([loadSalonWithGeometry$, loadSalonConfig$, loadTimezones$, loadCurrencies$]).pipe(
          mergeMap(([maybeSalon, maybeSalonConfig, timeZones, currencies]) => {
            return flatten([
              option.fold<Salon, Action[]>(
                () => [new SalonConfigActions.LoadCurrentSalonFail({ errors: "not found" })],
                (salon) => [new SalonConfigActions.LoadCurrentSalonSuccess(salon)]
              )(maybeSalon),
              option.fold<SalonConfig, Action[]>(
                () => [new SalonConfigActions.LoadSalonConfigFail({ errors: "not found" })],
                (salonConfig) => {
                  this._globalSettingsService.setSalonId(salonConfig._id);
                  return [new SalonConfigActions.LoadSalonConfigSuccess(salonConfig)];
                }
              )(maybeSalonConfig),
              [new SalonConfigActions.LoadTimezonesSuccess(timeZones)],
              [new SalonConfigActions.LoadCurrenciesSuccess({ currencies })],
            ]);
          }),
          catchError(() => of(new SalonConfigActions.LoadCurrentSalonFail({ errors: "error" })))
        );
      })
    )
  );

  public saveSalon$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.SAVE_SALON),
      map((action: SalonConfigActions.SaveSalon) => action.payload.salon),
      switchMap((salon) => {
        return forkJoin([
          this._salonService.updateOrDie(salon),
          salon.geometry != null ? this._salonService.createOrUpdateLocation(salon._id, salon.geometry) : of(undefined),
        ]).pipe(
          mergeMap(([salonConfig]) => [new SalonConfigActions.SaveSalonSuccess(salonConfig)]),
          catchError((error) => of(new SalonConfigActions.SaveSalonFail({ errors: error })))
        );
      })
    )
  );

  public saveSalonSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.SAVE_SALON_SUCCESS),
      map(() => new snackbarActions.Info({ message: "Salon Updated Succesfully" }))
    )
  );

  public saveSaveFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.SAVE_SALON_FAIL),
      map(() => new snackbarActions.Info({ message: "Something went wrong: Failed to update Salon Details" }))
    )
  );

  public saveSalonConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.SAVE_SALON_CONFIG),
      map((action: SalonConfigActions.SaveSalonConfig) => action.payload.config),
      switchMap((payload) =>
        this._salonConfigService.update(payload).pipe(
          mergeMap(
            either.fold<Error, SalonConfig, Action[]>(
              (error) => [new SalonConfigActions.SaveSalonConfigFail({ errors: error })],
              (salonConfig) => [new SalonConfigActions.SaveSalonConfigSuccess(salonConfig)]
            )
          )
        )
      )
    )
  );

  public saveSalonConfigSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.SAVE_SALON_CONFIG_SUCCESS),
      map(() => new snackbarActions.Info({ message: "Salon Config Saved Succesfully" }))
    )
  );

  public saveSalonConfigFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SalonConfigActions.Types.SAVE_SALON_CONFIG_FAIL),
      map(() => new snackbarActions.Info({ message: "Something went wrong: Failed to update Salon Config" }))
    )
  );

  public managePaymentMethods$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SalonConfigActions.Types.MANAGE_PAYMENT_METHODS),
        switchMap(() =>
          this._billingPortalSessionService.create().pipe(
            tap((billingPortalSession) => this._windowService.openWindow(billingPortalSession.url, undefined)),
            catchError((e) =>
              this._snackbarService.info(
                pipe(
                  e.error,
                  option.fromNullable,
                  option.fold(
                    () => JSON.stringify(e),
                    (error) => error.message
                  )
                )
              )
            )
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _employeeService: EmployeeService,
    private _salonConfigService: SalonConfigService,
    private _currencyService: CurrencyService,
    private _timeZoneService: TimezoneService,
    private _salonService: SalonService,
    private _billingPortalSessionService: BillingPortalSessionService,
    private _globalSettingsService: GlobalSettingsService,
    private _windowService: WindowService,
    private _snackbarService: SnackbarService
  ) {}
}
