<page
  pageTitle="Manage Products"
  [progressText]="(loading$ | async) ? 'Loading...' : undefined"
  class="full-height-table">
  <header-controls page-header>
    <div class="search-input-control">
      <search-input
        [searchFilter]="filter$ | async"
        (filter)="updateFilter($event)"
        placeholder="Search Manufacturers..."></search-input>
    </div>
  </header-controls>
  <card page-body>
    <admin-manufacturers-table
      card-body
      (select)="selectManufacturer($event)"></admin-manufacturers-table>
  </card>
</page>
