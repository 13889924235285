import { JsonObject } from "@getvish/stockpile";
import { Actions, initialize, initializeFail, initializeSuccess, updateSort, updateTableFilter } from "./performed-service-list.actions";
import { Appointment } from "@getvish/model";
import { createReducer, on } from "@ngrx/store";
import { PerformedServiceVM } from "../model/performed-service";

export const featureKey = "performedServiceList";

export interface AnalyticAppointmentListState {
  loading: boolean;
  startDate: number;
  endDate: number;
  error: string;
  performedServices: PerformedServiceVM[];
  appointments: Appointment[];
  employeeId: string;
  serviceId: string;
  subset: "mixable" | "mixed" | "unmixed";
  sort: JsonObject;
  tableFilter: JsonObject;
}

const initialState: AnalyticAppointmentListState = {
  loading: false,
  startDate: undefined,
  endDate: undefined,
  error: undefined,
  performedServices: undefined,
  appointments: undefined,
  employeeId: undefined,
  serviceId: undefined,
  subset: undefined,
  sort: undefined,
  tableFilter: undefined,
};

export const reducer = createReducer<AnalyticAppointmentListState, Actions>(
  initialState,
  on(initialize, (state, { payload }) => ({
    ...state,
    loading: true,
    startDate: payload.startDate,
    endDate: payload.endDate,
    sort: payload.sort,
    tableFilter: payload.tableFilter,
    employeeId: payload.employeeId,
    serviceId: payload.serviceId,
    subset: payload.subset,
  })),
  on(initializeSuccess, (state, { performedServices, appointments }) => ({
    ...state,
    loading: false,
    performedServices,
    appointments,
  })),
  on(initializeFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(updateSort, (state, { payload }) => ({
    ...state,
    sort: payload,
  })),
  on(updateTableFilter, (state, { payload }) => ({
    ...state,
    tableFilter: payload,
  }))
);
