import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "upload-salon-product-spreadsheet-form",
  templateUrl: "upload-salon-product-spreadsheet-form.component.html",
  styleUrls: ["upload-salon-product-spreadsheet-form.component.less"],
})
export class UploadSalonProductSpreadsheetFormComponent {
  @Input() public loading: boolean;
  @Input() public errors: string;
  @Input() public success: boolean;
  @Output() public fileChange: EventEmitter<{ url: string; type: string }>;
  @Output() public close: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    this.fileChange = new EventEmitter(true);
  }

  public onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      const url = window.URL.createObjectURL(file);

      this.fileChange.emit({ url, type: file.type });
    }
  }
}
