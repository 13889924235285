<div class="date-picker-content">
  <div class="date-pickers">
    <mat-form-field>
      <mat-label>Start date</mat-label>
      <input
        matInput
        [value]="startControlDate"
        [min]="_minDate"
        [max]="_maxDate"
        [matDatepicker]="startDatePicker"
        (dateChange)="updateStartDate($event.value)" />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>End date</mat-label>
      <input
        matInput
        [value]="endControlDate"
        [min]="_minDate"
        [max]="_maxDate"
        [matDatepicker]="endDatePicker"
        (dateChange)="updateEndDate($event.value)" />
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="aggregations">
    <button
      class="button secondary transparent"
      (click)="changeDateRange('TODAY')">
      Today
    </button>
    <button
      class="button secondary transparent"
      (click)="changeDateRange('THIS_WEEK')">
      Last 7 Days
    </button>
    <button
      class="button secondary transparent"
      (click)="changeDateRange('30_DAYS')">
      Last 30 days
    </button>
    <button
      class="button secondary transparent"
      (click)="changeDateRange('PREVIOUS_YEAR')">
      Last 365 Days
    </button>
  </div>
</div>
