import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { map, catchError, switchMap, withLatestFrom, tap, mapTo } from "rxjs/operators";
import { of } from "rxjs";
import { ExportDataSpreadsheetService, SalonService, UploadSalonDataService } from "../services";
import { Store, Action } from "@ngrx/store";
import { getAuthToken, getUser } from "../../+auth/store";
import { AppState } from "../../kernel";
import { saveAs } from "file-saver";
import { fold } from "fp-ts/lib/Option";
import { Salon } from "@getvish/model";

import * as actions from "./import-salon-data.actions";
import * as routerActions from "../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../kernel/store/actions/snackbar.actions";

@Injectable()
export class ImportSalonDataEffects {
  public done$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.DONE),
      map(() => routerActions.go({ path: ["/salons", { outlets: { panel: null } }] }))
    )
  );

  public loadSalon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.LoadSalon>(actions.Types.LOAD_SALON),
      map((action) => action.payload.id),
      switchMap((id) => this._salonService.findById(id)),
      map(
        fold<Salon, Action>(
          () => new actions.LoadSalonFail({ error: "Salon not found" }),
          (salon) => new actions.LoadSalonSuccess({ salon })
        )
      )
    )
  );

  public navigateToPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Navigate>(actions.Types.NAVIGATE),
      map((action) => action.payload.salon._id),
      map((salonId) => routerActions.go({ path: ["/salons", { outlets: { panel: `import/${salonId}` } }] }))
    )
  );

  public exportData$ = createEffect(() =>
    this._actions$
      .pipe(
        ofType<actions.ExportData>(actions.Types.EXPORT_DATA),
        map((action) => action.payload.salon.slug),
        withLatestFrom(this._store.select(getAuthToken)),
        switchMap(([slug, authToken]) => this._exportDataService.export(slug, authToken))
      )
      .pipe(
        tap((response) => saveAs(response, "vish-data-export.xlsx")),
        mapTo(new actions.ExportDataSuccess()),
        catchError(() => of(new SnackbarActions.Info({ message: "Something went wrong: Failed to export salon data" })))
      )
  );

  public exportDataSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.EXPORT_DATA_SUCCESS),
      map(() => new SnackbarActions.Info({ message: "Data exported successfully" }))
    )
  );

  public initializeSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.InitializeSession>(actions.Types.INITIALIZE_SESSION),
      map((action) => action.payload.salon),
      withLatestFrom(this._store.select(getUser), this._store.select(getAuthToken)),
      switchMap(([salon, user, authToken]) =>
        this._uploadSalonDataService.initializeSession(salon, user.email, authToken).pipe(
          mapTo(new actions.InitializeSessionSuccess()),
          catchError(() => of(new SnackbarActions.Info({ message: "Something went wrong: Failed to initialize import session" })))
        )
      )
    )
  );

  public initializeSessionSuccess$ = createEffect(() =>
    this._actions$.pipe(ofType<actions.InitializeSessionSuccess>(actions.Types.INITIALIZE_SESSION_SUCCESS), mapTo(new actions.Next()))
  );

  public uploadSalonData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.UploadCsv>(actions.Types.UPLOAD_CSV),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getAuthToken)),
      switchMap(([{ file, type, salon }, authToken]) =>
        this._uploadSalonDataService.uploadCsvFile(file, salon, authToken, type).pipe(
          map(() => new actions.UploadCsvSuccess({ type })),
          catchError((error) =>
            of(
              new actions.UploadCsvFail({ error: error.toString() }),
              new SnackbarActions.Info({ message: "Something went wrong while uploading CSV file" })
            )
          )
        )
      )
    )
  );

  public runSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.RunSession>(actions.Types.RUN_SESSION),
      map((action) => action.payload.salon),
      withLatestFrom(this._store.select(getAuthToken)),
      switchMap(([salon, authToken]) =>
        this._uploadSalonDataService.runSession(salon, authToken).pipe(
          mapTo(new actions.RunSessionSuccess()),
          catchError(() => of(new SnackbarActions.Info({ message: "Something went wrong: Failed to run import session" })))
        )
      )
    )
  );

  public runSessionSuccess = createEffect(() =>
    this._actions$.pipe(ofType<actions.RunSessionSuccess>(actions.Types.RUN_SESSION_SUCCESS), mapTo(new actions.Next()))
  );

  constructor(
    private _exportDataService: ExportDataSpreadsheetService,
    private _uploadSalonDataService: UploadSalonDataService,
    private _salonService: SalonService,
    private _store: Store<AppState>,
    private _actions$: Actions
  ) {}
}
