<page
  [progressText]="loading ? 'Loading...' : undefined"
  pageTitle="Product Usage Report">
  <div
    class="header-controls"
    page-header>
    <!--
      so, this is all _one_ component, right?
      basically the <date-range> _needs_ the <date-picker> component or else it can't really do its job?
      if that's the case we should just wrap them both up into one component
      so you don't have to remember to always render a <date-picker> via [matTooltip] ...
      and we could do things like automatically close the overlay when the user chooses a date
      right now we'd have to manually do the work from the calling component, which is awkward
    -->
    <mat-menu #datepickerMenu="matMenu">
      <date-picker
        [startDate]="startDate"
        [endDate]="endDate"
        [timeZone]="timeZone"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (update)="updateFilters.emit($event)"></date-picker>
    </mat-menu>
    <date-range
      [startDate]="startDate"
      [endDate]="endDate"
      [timeZone]="timeZone"
      [matMenuTriggerFor]="datepickerMenu"
      matTooltip="Change date range"></date-range>
    <!--  -->
  </div>
  <ng-container
    page-body
    *ngIf="!loading">
    <div *ngIf="reportHasData; else emptyState">
      <div class="row gutters metrics-container">
        <section class="col col-6">
          <div class="card">
            <header>
              <h3>{{ selected?.entityName }}</h3>
            </header>
            <div
              *ngIf="selected; else noSelectedManufacturerTemplate"
              id="manufacturer-details-container">
              <div class="row metrics">
                <div class="col col-6">
                  <colored-icon
                    [title]="selected.entityName"
                    size="6x"></colored-icon>
                </div>
                <div class="col col-6 metrics">
                  <p class="statistic-important">
                    <span class="statistic-important-highlight">{{ selected.metrics.usageRatio | percent }}</span>
                    <span class="statistic-important-title">of total usage</span>
                  </p>
                  <p>
                    <span class="statistic-title">Product Wholesale</span>
                    <span class="statistic-highlight">{{ selected.metrics.wholesaleCostDollars | currency: currency }}</span>
                  </p>
                  <p>
                    <span class="statistic-title">Product Retail</span>
                    <span class="statistic-highlight">{{ selected.metrics.retailCostDollars | currency: currency }}</span>
                  </p>
                  <p>
                    <span class="statistic-title">Product Waste</span>
                    <span class="statistic-highlight">
                      {{ selected.metrics.wasteCostDollars | currency: currency }}
                    </span>
                  </p>
                  <p>
                    <span class="statistic-title">Product Dispensed</span>
                    <span class="statistic-highlight">
                      {{ selected.metrics.totalAmountGrams | localMeasurementUnit: measurementUnit }}
                    </span>
                  </p>
                  <p>
                    <span class="statistic-title">Number of Services</span>
                    <span class="statistic-highlight">{{ selected.metrics.numServicesPerformed }}</span>
                  </p>
                </div>
              </div>
              <div class="bottom-controls">
                <a
                  (click)="visitManufacturer(selected.entityId)"
                  class="more-info">
                  <span>More Details</span>
                  <fa-icon icon="chevron-right"></fa-icon>
                </a>
              </div>
            </div>

            <ng-template #noSelectedManufacturerTemplate>
              <empty-state-message
                title="No Manufacturers here"
                icon="tags">
                Either no Manufacturers have been added or we couldn't find any data for your search/filter
              </empty-state-message>
            </ng-template>
          </div>
        </section>

        <section class="col col-6">
          <div class="card">
            <header>
              <h3>Top Manufacturers</h3>
            </header>
            <div id="pie-chart-container">
              <ngx-charts-pie-chart
                *ngIf="filteredStatistics | notEmpty; else emptyTopManufacturers"
                [view]="[500, 400]"
                [scheme]="colorScheme"
                [results]="data"
                [legend]="false"
                [labels]="true"
                [doughnut]="true"
                [arcWidth]="0.4"
                (select)="onSelect($event)">
              </ngx-charts-pie-chart>
              <ng-template #emptyTopManufacturers>
                <empty-state-message
                  title="No data here"
                  icon="calendar-day">
                  <p>We couldn't find any data for the selected range, adjust the dates to view data</p>
                </empty-state-message>
              </ng-template>
            </div>
          </div>
        </section>
      </div>
      <div
        class="row gutters"
        id="manufacturers-report-table">
        <section class="col col-12">
          <div class="card">
            <header>
              <h3>Manufacturers</h3>
            </header>
            <div class="products-list-container">
              <table *ngIf="filteredStatistics | notEmpty; else emptyProductsList">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Dispensed</th>
                    <th>Waste</th>
                    <th>Wholesale Cost</th>
                    <th>Retail Price</th>
                    <th>Num Services</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let statistic of filteredStatistics"
                    (click)="selectManufacturer.emit(statistic.entityId)"
                    class="product-row">
                    <td class="icon-container">
                      <colored-icon [title]="statistic.entityName"></colored-icon>
                    </td>
                    <td>
                      <span class="row-title">{{ statistic.entityName }}</span>
                    </td>
                    <td>{{ statistic.metrics.totalAmountGrams | localMeasurementUnit: measurementUnit }}</td>
                    <td>{{ statistic.metrics.wasteAmountGrams | localMeasurementUnit: measurementUnit }}</td>
                    <td>{{ statistic.metrics.wholesaleCostDollars | currency: currency }}</td>
                    <td>{{ statistic.metrics.retailCostDollars | currency: currency }}</td>
                    <td>{{ statistic.metrics.numServicesPerformed }}</td>
                  </tr>
                </tbody>
              </table>
              <ng-template #emptyProductsList>
                <empty-state-message
                  title="No data here"
                  icon="calendar-day">
                  <p>We couldn't find any data for the selected range, adjust the dates to view data</p>
                </empty-state-message>
              </ng-template>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ng-template #emptyState>
      <empty-state-message
        title="No data here"
        icon="calendar-day">
        We couldn't find any data for the selected date range, adjust the dates to view data
      </empty-state-message>
    </ng-template>
  </ng-container>
</page>
