import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { CsvUploadFileType } from "../store/import-salon-data.actions";

@Component({
  selector: "upload-csv",
  templateUrl: "./upload-csv.component.html",
  styleUrls: ["./upload-csv.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadCsvComponent {
  @Input() public title: string;
  @Input() public loading: boolean;
  @Input() public report: { success: boolean };
  @Input() public type: CsvUploadFileType;
  @Output() public selectFile: EventEmitter<{ file: File; type: CsvUploadFileType }>;
  @Output() public next: EventEmitter<void>;

  constructor() {
    this.selectFile = new EventEmitter(true);
    this.next = new EventEmitter(true);
  }

  public onFileChange(event: Event): void {
    const target = event.target as any;

    this.selectFile.emit({ file: target.files[0], type: this.type });
  }
}
