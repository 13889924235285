import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";
import { DiscontinuedProduct, Manufacturer, Product, ProductCategory, ProductCategoryModule } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { AppState } from "app/kernel";
import { isNil, prop } from "ramda";
import { Observable, Subscription, combineLatest } from "rxjs";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { go } from "../../../kernel/store/actions/router.actions";

import { BreadcrumbService } from "xng-breadcrumb";
import * as manufacturerActions from "../../+manufacturers/store/manufacturer.actions";
import * as fromManufacturer from "../../+manufacturers/store/manufacturer.selectors";
import * as DiscontinuedProductActions from "../../+products/store/discontinue-product.actions";
import * as ProductActions from "../../+products/store/product.actions";
import * as fromProduct from "../../+products/store/product.selectors";
import * as ProductCategoryActions from "../store/product-category.actions";
import * as fromProductCategory from "../store/product-category.selectors";
import { ProductCategoryNode } from "../common";
import { toUndefined } from "fp-ts/lib/Option";

@Component({
  selector: "product-mangagement-container",
  templateUrl: "product-management.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductManagementContainer implements OnDestroy {
  public selectedManufacturer$: Observable<Manufacturer>;
  public selectedManufacturerId$: Observable<string>;
  public categories$: Observable<ProductCategory[]>;
  public products$: Observable<Product[]>;
  public discontinuedProducts$: Observable<{ [key: string]: DiscontinuedProduct }>;
  public filteredProducts$: Observable<Product[]>;
  public selectedProducts$: Observable<Product[]>;
  public rootCategoryMap$: Observable<Map<string, ProductCategory>>;
  public activeCategoryIds$: Observable<string[]>;
  public filter$: Observable<string>;
  public loading$: Observable<boolean>;
  public productsLoading$: Observable<boolean>;
  public saving$: Observable<boolean>;

  public currentTabIndex: number = 0;

  private _subscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    private _route: ActivatedRoute,
    _breadcrumbService: BreadcrumbService
  ) {
    this.filter$ = this._store.pipe(select(fromProduct.getSearchFilter));
    this.categories$ = this._store.pipe(select(fromProductCategory.getAll));
    this.filteredProducts$ = this._store.pipe(select(fromProduct.getFilteredProducts));
    this.selectedProducts$ = this._store.pipe(select(fromProduct.getSelected));
    this.products$ = this._store.pipe(select(fromProduct.getAll));
    this.discontinuedProducts$ = this._store.pipe(select(fromProduct.getDiscontinuedProducts));
    this.rootCategoryMap$ = this._store.pipe(select(fromProductCategory.getRootCategoryMap));
    this.loading$ = this._store.pipe(select(fromProductCategory.getLoading));
    this.productsLoading$ = this._store.pipe(select(fromProduct.getLoading));

    this.saving$ = combineLatest([
      this._store.pipe(select(fromProductCategory.getSaving)),
      this._store.pipe(select(fromProduct.getSaving)),
    ]).pipe(map(([saving1, saving2]) => saving1 || saving2));

    this.activeCategoryIds$ = this._store.pipe(select(fromProductCategory.getSelectedCategoryIds));

    this.selectedManufacturerId$ = this._route.params.pipe(map((params) => prop("manufacturerId", params) as string));

    this.selectedManufacturer$ = this.selectedManufacturerId$.pipe(
      tap((manufacturerId) => this._store.dispatch(new manufacturerActions.LoadManufacturerById({ manufacturerId }))),
      mergeMap((manufacturerId) => this._store.pipe(select(fromManufacturer.getManufacturer(manufacturerId))))
    );

    this.selectedManufacturer$.subscribe((manufacturer) => {
      if (manufacturer != null) {
        _breadcrumbService.set(`/product/products/manufacturer/:manufacturerId`, manufacturer.name);
      }
    });

    this._subscription = this.selectedManufacturerId$.subscribe((manufacturerId) =>
      this._store.dispatch(
        new ProductCategoryActions.LoadProductsAndCategoriesForManufacturer({
          manufacturerId,
        })
      )
    );
  }

  public tabChange(event: MatTabChangeEvent): void {
    this.currentTabIndex = event.index;
  }

  public back(): void {
    this._store.dispatch(go({ path: ["/product/products"] }));
  }

  public newCategory(parentCategory?: ProductCategory, manufacturerId?: string): void {
    this._store.dispatch(new ProductCategoryActions.New({ parentCategory, manufacturerId }));
  }

  public editCategory(productCategory: ProductCategory): void {
    this._store.dispatch(new ProductCategoryActions.Edit({ productCategory }));
  }

  public newProduct(category: ProductCategory, manufacturerId: string): void {
    this._store.dispatch(new ProductActions.New({ category, manufacturerId }));
  }

  public editProduct(product: Product): void {
    this._store.dispatch(new ProductActions.Edit({ product }));
  }

  public selectProduct(product: Product, manufacturer: Manufacturer, categories: ProductCategory[]): void {
    const generateCategoriesString = (category: ProductCategory, str = ""): string => {
      if (category == null) {
        return str;
      }

      const categoryString = category.name;
      const newStr = str.length > 0 ? `${categoryString} > ${str}` : categoryString;

      return generateCategoriesString(toUndefined(ProductCategoryModule.getParentCategory(category, categories)), newStr);
    };

    this._store.dispatch(
      new ProductActions.Select({
        product: {
          ...product,
          manufacturer: manufacturer.name,
          categories: generateCategoriesString(categories.find((c) => c._id === product.categoryId)),
        },
      })
    );
  }

  public clearSelections(): void {
    this._store.dispatch(new ProductActions.ClearSelections());
  }

  public discontinueProduct(product: Product): void {
    this._store.dispatch(DiscontinuedProductActions.navigate({ product }));
  }

  public setFilter(filter: string): void {
    this._store.dispatch(new ProductActions.SetFilter({ filter }));
  }

  public expandAll(): void {
    this._store.dispatch(new ProductCategoryActions.ExpandAllCategories());
  }

  public collapseAll(): void {
    this._store.dispatch(new ProductCategoryActions.CollapseAllCategories());
  }

  public changeRootCategoryOrder(): void {
    this.categories$
      .pipe(
        map((categories) => categories.filter((c) => isNil(c.parentCategoryId))),
        take(1)
      )
      .subscribe((categories) => this._store.dispatch(new ProductCategoryActions.ChangeCategoryOrder({ categories })));
  }

  public changeCategoryOrder(categoryNode: ProductCategoryNode) {
    this._store.dispatch(new ProductCategoryActions.ChangeCategoryOrder({ categories: categoryNode.children }));
  }

  public changeProductOrder(categoryNode: ProductCategoryNode) {
    this._store.dispatch(new ProductActions.ChangeProductOrder({ products: categoryNode.products }));
  }

  public toggleCategory(category: ProductCategory): void {
    const categoryId = category._id;
    this._store.dispatch(new ProductCategoryActions.ToggleCategory({ categoryId }));
  }

  public mergeProducts(): void {
    this._store.dispatch(new ProductActions.Merge());
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
