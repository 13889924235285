<div class="container">
  <header>
    <h1>Please Log In</h1>
  </header>
  <div class="logo-container">
    <img
      id="brand-logo"
      src="/assets/images/logo.svg" />
  </div>
  <form
    [formGroup]="form"
    (submit)="submit(form.value)"
    autocomplete="off"
    novalidate>
    <div
      *ngIf="error"
      class="error-container">
      <alert-component type="danger">
        {{ error }}
      </alert-component>
    </div>

    <mat-form-field
      class="full-width"
      autocomplete="off">
      <mat-icon
        matPrefix
        aria-hidden="true">
        <fa-icon icon="at"></fa-icon>
      </mat-icon>
      <mat-label>Email Address</mat-label>
      <input
        matInput
        formControlName="username"
        type="email" />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="full-width"
      autocomplete="off">
      <mat-icon
        matPrefix
        aria-hidden="true">
        <fa-icon icon="lock"></fa-icon>
      </mat-icon>
      <mat-label>Password</mat-label>
      <input
        matInput
        formControlName="password"
        type="password" />
    </mat-form-field>

    <div id="login-button-container">
      <button
        type="submit"
        [disabled]="form.invalid"
        class="button primary"
        style="width: 100%; padding-top: 12px; padding-bottom: 12px">
        <span *ngIf="!loggingIn; else loadingIndicator">Log In</span>
        <ng-template #loadingIndicator>
          <fa-icon
            *ngIf="loggingIn"
            [icon]="['fas', 'circle-notch']"
            [spin]="true"></fa-icon>
        </ng-template>
      </button>
    </div>
    <div id="forgot-password-link">
      <button
        (click)="forgotPassword.emit()"
        class="transparent"
        type="button">
        Forgot Password?
      </button>
    </div>
  </form>
</div>
