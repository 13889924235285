import { ServiceDescription } from "@getvish/model";
import { createAction, props } from "@ngrx/store";

export const navigateEditServicesPanel = createAction("[Edit Services] Navigate Edit Services Panel");

export const editServices = createAction("[Edit Services] Edit Services", props<{ partial: Partial<ServiceDescription> }>());

export const editServicesSuccess = createAction("[Edit Services] Edit Services Success");

export const close = createAction("[Edit Services] Close");
