import * as salonProduct from "./salon-product.actions";

import { Manufacturer, ProductCategory, SalonManufacturer, SalonProduct, SalonProductCategory } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { append, dropLastWhile, pipe, takeWhile, uniqBy } from "ramda";

export interface SalonProductState {
  loading: boolean;
  saving: boolean;
  error: string;
  records: SalonProduct[];
  paging: PagingMetadata;
  selected: string;
  criteria: JsonObject;
  sort: JsonObject;
  productFilters: string[];
  searchFilter: string;
  flagFilters: string[];
  uploading: boolean;
  uploadingErrors: string;
  uploadingSuccess: boolean;
  selectedManufacturerId: string;
  selectedProducts: SalonProduct[];
  selectedProductCategories: ProductCategory[];
  salonManufacturers: SalonManufacturer[];
  salonProductCategories: SalonProductCategory[];
  manufacturers: Manufacturer[];
  pricingIncompleteFilter: boolean;
  savingMarkup: boolean;
}

const initialState: SalonProductState = {
  loading: false,
  saving: false,
  error: undefined,
  records: [],
  paging: undefined,
  selected: undefined,
  criteria: undefined,
  sort: undefined,
  productFilters: [],
  searchFilter: undefined,
  flagFilters: [],
  uploading: false,
  uploadingErrors: undefined,
  uploadingSuccess: undefined,
  selectedManufacturerId: undefined,
  selectedProducts: [],
  selectedProductCategories: [],
  salonManufacturers: [],
  salonProductCategories: [],
  manufacturers: [],
  pricingIncompleteFilter: false,
  savingMarkup: false,
};

export function salonProductReducer(state: SalonProductState = initialState, action: salonProduct.Actions): SalonProductState {
  switch (action.type) {
    case salonProduct.Types.LOAD_ALL: {
      const loading = true;
      const { criteria, sort } = action.payload;
      const records = [];
      const selectedProducts = [];
      const selectedProductCategories = [];
      const searchFilter = undefined;
      const flagFilters = [];

      return {
        ...state,
        loading,
        criteria,
        sort,
        records,
        selectedProducts,
        selectedProductCategories,
        searchFilter,
        flagFilters,
      };
    }

    case salonProduct.Types.LOAD_ALL_MANUFACTURER: {
      const clearSelections = state.selectedManufacturerId !== action.payload;

      return {
        ...state,
        loading: true,
        records: [],
        selectedProducts: [],
        selectedProductCategories: clearSelections ? [] : state.selectedProductCategories,
        searchFilter: clearSelections ? undefined : state.searchFilter,
      };
    }

    case salonProduct.Types.LOAD_ALL_SUCCESS: {
      const _state = {
        ...state,
        loading: false,
        records: action.salonProducts,
        paging: action.paging,
      };

      if (action.salonManufacturers) {
        _state.salonManufacturers = action.salonManufacturers;
      }

      if (action.manufacturers) {
        _state.manufacturers = action.manufacturers;
      }

      if (action.salonProductCategories) {
        _state.salonProductCategories = action.salonProductCategories;
      }

      return _state;
    }

    case salonProduct.Types.LOAD_ALL_FAIL: {
      return {
        ...state,
        error: action.payload.errors,
        loading: false,
      };
    }

    case salonProduct.Types.ADD: {
      const saving = true;

      return {
        ...state,
        saving,
      };
    }

    case salonProduct.Types.ADD_SUCCESS: {
      const records = state.records;
      const newRecord = action.payload;
      const saving = false;
      const updatedRecords = uniqBy((e) => e._id, [newRecord, ...records]);

      return {
        ...state,
        saving,
        records: updatedRecords,
      };
    }

    case salonProduct.Types.REMOVE_SUCCESS: {
      const records = state.records;
      const updatedRecords = records.filter((record) => record._id !== action.payload);

      return {
        ...state,
        records: updatedRecords,
      };
    }

    case salonProduct.Types.LOAD_PRODUCTS_AND_CATEGORIES_FOR_MANUFACTURER: {
      const selectedManufacturerId = action.payload.manufacturerId;

      return {
        ...state,
        selectedManufacturerId,
      };
    }

    case salonProduct.Types.SET_SEARCH_FILTER: {
      const searchFilter = action.payload.searchFilter;

      return {
        ...state,
        searchFilter,
      };
    }

    case salonProduct.Types.SET_FLAG_FILTERS: {
      const flagFilters = action.payload.filters;

      return {
        ...state,
        flagFilters,
      };
    }

    case salonProduct.Types.TOGGLE_PRODUCT: {
      const product = action.payload.product;

      const isSelected = state.selectedProducts.some((selectedProduct) => selectedProduct._id === product._id);

      const updatedSelectedProducts =
        isSelected === true
          ? state.selectedProducts.filter((selectedProduct) => selectedProduct._id !== product._id)
          : [...state.selectedProducts, product];

      return {
        ...state,
        selectedProducts: updatedSelectedProducts,
      };
    }

    case salonProduct.Types.PUSH_PRODUCT_CATEGORY: {
      const category = action.payload.category;

      const selectedProductCategories = pipe(
        // if a category is being pushed, we'll want to make sure that we're not appending that category twice
        // and, at the same time, because a category could potentially be pushed to any point in the stack
        // we'll want to remove any categories _up to and including_ the category that's being pushed
        // example:
        // Currently-selected categories: [ A, B, C, D ]
        // Category B is selected/pushed (i.e. the user wants to "jump to" category B)
        // we'll want to remove all categories "up to and including" B, so we're left with [ A ]
        // then we'll append category B, so we end up with [ A, B ]
        // this allows us to both cover the case where the user wants to "jump back up to" category B
        // and also the case where the user wants to push a new category onto the stack (let's say E)
        takeWhile<ProductCategory>((selectedCategory) => {
          return selectedCategory._id !== category._id;
        }),
        dropLastWhile<ProductCategory>((selectedCategory) => {
          return selectedCategory.parentCategoryId === category.parentCategoryId;
        }),
        append(category)
      )(state.selectedProductCategories);

      return {
        ...state,
        selectedProductCategories,
      };
    }

    case salonProduct.Types.POP_PRODUCT_CATEGORY: {
      return {
        ...state,
        selectedProductCategories: state.selectedProductCategories.slice(0, -1),
      };
    }

    case salonProduct.Types.POP_TO_PRODUCT_CATEGORY: {
      const categoryId = action.payload.categoryId;

      return {
        ...state,
        selectedProductCategories: dropLastWhile<ProductCategory>((productCategory) => productCategory._id !== categoryId)(
          state.selectedProductCategories
        ),
      };
    }

    case salonProduct.Types.CLEAR_PRODUCT_CATEGORIES: {
      return {
        ...state,
        selectedProductCategories: [],
      };
    }

    case salonProduct.Types.UPLOAD_SPREADSHEET: {
      const uploading = true;
      const uploadingErrors = undefined;
      const uploadingSuccess = undefined;

      return {
        ...state,
        uploading,
        uploadingErrors,
        uploadingSuccess,
      };
    }

    case salonProduct.Types.UPLOAD_SPREADSHEET_COMPLETE: {
      const uploading = false;
      const uploadingSuccess = true;

      return {
        ...state,
        uploading,
        uploadingSuccess,
      };
    }

    case salonProduct.Types.UPLOAD_SPREADSHEET_FAIL: {
      const uploading = false;
      const errors = action.payload.error.toString();

      return {
        ...state,
        uploading,
        uploadingErrors: errors,
      };
    }

    case salonProduct.Types.CLOSE_UPLOAD_COMPONENT: {
      const uploading = false;
      const uploadingErrors = undefined;
      const uploadingSuccess = undefined;

      return {
        ...state,
        uploading,
        uploadingSuccess,
        uploadingErrors,
      };
    }

    case salonProduct.Types.SET_CATEGORY_ACTIVE: {
      return {
        ...state,
        saving: true,
      };
    }

    case salonProduct.Types.SET_CATEGORY_INACTIVE: {
      return {
        ...state,
        saving: true,
      };
    }

    case salonProduct.Types.SET_CATEGORY_ACTIVE_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonProduct.Types.SET_CATEGORY_INACTIVE_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonProduct.Types.SET_CATEGORY_ACTIVE_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonProduct.Types.SET_CATEGORY_INACTIVE_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonProduct.Types.SET_INCOMPLETE_PRICING_FILTER: {
      return {
        ...state,
        pricingIncompleteFilter: action.payload.filter,
      };
    }

    case salonProduct.Types.UPDATE_SALON_MANUFACTURERS_ORDER: {
      return {
        ...state,
        saving: true,
      };
    }

    case salonProduct.Types.UPDATE_SALON_MANUFACTURERS_ORDER_SUCCESS: {
      return {
        ...state,
        saving: false,
        salonManufacturers: state.salonManufacturers.map((m) => action.payload.salonManufacturers.find((_m) => _m._id === m._id) ?? m),
      };
    }

    case salonProduct.Types.UPDATE_SALON_MANUFACTURERS_ORDER_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonProduct.Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER: {
      return {
        ...state,
        saving: true,
      };
    }

    case salonProduct.Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER_SUCCESS: {
      return {
        ...state,
        saving: false,
        salonProductCategories: [
          ...state.salonProductCategories.map((c) => action.payload.salonProductCategories.find((_c) => _c._id === c._id) ?? c),
          ...action.payload.salonProductCategories.filter((c) => !state.salonProductCategories.some((_c) => c._id === _c._id)),
        ],
      };
    }

    case salonProduct.Types.UPDATE_SALON_PRODUCT_CATEGORIES_ORDER_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonProduct.Types.UPDATE_SALON_PRODUCTS: {
      return {
        ...state,
        saving: true,
      };
    }

    case salonProduct.Types.UPDATE_SALON_PRODUCTS_SUCCESS: {
      return {
        ...state,
        saving: false,
        records: state.records.map((p) => ({
          ...p,
          order: action.payload.salonProducts.find((_p) => _p._id === p._id)?.order ?? p.order,
        })),
      };
    }

    case salonProduct.Types.UPDATE_SALON_PRODUCTS_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case salonProduct.Types.SET_MARKUP: {
      return {
        ...state,
        savingMarkup: true,
      };
    }

    case salonProduct.Types.SET_MARKUP_SUCCESS:
    case salonProduct.Types.SET_MARKUP_FAIL: {
      return {
        ...state,
        savingMarkup: false,
      };
    }

    default: {
      return state;
    }
  }
}
