import { createSelector, createFeatureSelector } from "@ngrx/store";
import { CsvUploadFileType } from "./import-salon-data.actions";
import { State } from "./import-salon-data.reducer";
import { fromNullable, map, toUndefined } from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";

/*** SELECTORS ***/
export const getState = createFeatureSelector<State>("importSalonData");
export const getSalon = createSelector(getState, (state) => state.salon);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getExporting = createSelector(getState, (state) => state.exporting);
export const getStep = createSelector(getState, (state) => state.step);
export const getError = createSelector(getState, (state) => state.error);
export const getUploading = createSelector(getState, (state) => state.uploading);
export const getReports = createSelector(getState, (state) => state.reports);
export const getReport = (key: CsvUploadFileType) =>
  createSelector(getState, (state) =>
    pipe(
      state.reports,
      fromNullable,
      map((reports) => reports[key]),
      toUndefined
    )
  );
