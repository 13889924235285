import { Component, ChangeDetectionStrategy, Input, OnChanges } from "@angular/core";
import { MeasurementUnit, TargetWeightType } from "@getvish/model";
import { MixingContainerVM } from "app/+components/+mixing-container";
import { getMixingContainerVM } from "app/+service-menu/utils";
import { ProductAllowanceBlueprintVM, ProductAllowanceVM } from "app/kernel/models/product-allowance";

@Component({
  selector: "product-allowance-summary-cell",
  templateUrl: "product-allowance-summary-cell.component.html",
  styleUrls: ["product-allowance-summary-cell.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAllowanceSummaryCellComponent implements OnChanges {
  @Input()
  public productAllowance: ProductAllowanceVM;
  @Input()
  public measurementUnit: MeasurementUnit;

  public blueprints: {
    blueprintBowlVM: ProductAllowanceBlueprintVM;
    mixingContainerVM: MixingContainerVM;
  }[] = [];

  public TargetWeightType = TargetWeightType;

  ngOnChanges(): void {
    this.blueprints = this.productAllowance?.blueprints?.map((b) => ({
      blueprintBowlVM: b,
      mixingContainerVM: getMixingContainerVM(b),
    }));
  }
}
