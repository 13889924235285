import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "dialog-contents",
  templateUrl: "dialog-contents.component.html",
  styleUrls: ["dialog-contents.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentsComponent {
  @Input()
  public dialogTitle?: string;

  @Input()
  public progressText?: string;

  @Input()
  public error?: string;

  @Output()
  public close: EventEmitter<void>;

  constructor() {
    this.close = new EventEmitter(true);
  }

  public dismiss() {
    this.close.emit();
  }
}
