<div>
  <div style="padding: 80px; text-align: center">
    <button
      *ngIf="(isBillingConfigured$ | async) === true"
      class="button outline"
      (click)="managePayments()">
      Manage Payment Methods
    </button>
  </div>
  <empty-state-message
    *ngIf="(isBillingConfigured$ | async) === false"
    title="Billing information has not been configured"
    icon="credit-card">
  </empty-state-message>
</div>
