import { JsonObject } from "@getvish/stockpile";
import escapeStringRegexp from "escape-string-regexp";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { isEmpty } from "ramda";

export const createUserFilterCriteria = (filter?: string, tableFilter?: JsonObject) => {
  const filterExpressions = pipe([
    ...pipe(
      option.fromNullable(filter),
      option.chainNullableK((s) => (s.trim() === "" ? undefined : s.trim())),
      option.fold(
        () => [],
        (value) => [
          {
            $or: value
              .split(" ")
              .map((s) => escapeStringRegexp(s.trim()))
              .filter((s) => s !== "")
              .map((s) => [
                { firstName: { $regex: `${s}`, $options: "i" } },
                { lastName: { $regex: `${s}`, $options: "i" } },
                { email: { $regex: `${s}`, $options: "i" } },
              ])
              .reduce((acc, v) => [...acc, ...v], []),
          },
        ]
      )
    ),
    ...pipe(
      option.fromNullable(tableFilter),
      option.chainNullableK((tf) => tf.firstName),
      option.fold(
        () => [],
        (value: string) => [
          {
            $or: value
              .split(" ")
              .map((s) => escapeStringRegexp(s.trim()))
              .filter((s) => s !== "")
              .map((s) => [{ firstName: { $regex: `${s}`, $options: "i" } }, { lastName: { $regex: `${s}`, $options: "i" } }])
              .reduce((acc, v) => [...acc, ...v], []),
          },
        ]
      )
    ),
    ...pipe(
      option.fromNullable(tableFilter),
      option.fold(
        () => [],
        (value) =>
          Object.entries(value)
            .reduce((acc, [field, value]) => {
              if (field === "firstName") {
                return acc;
              }

              if (typeof value === "string" && value.trim() === "") {
                return acc;
              }

              return [...acc, { field, value }];
            }, [])
            .map((f) =>
              typeof f.value === "string"
                ? {
                    [f.field]: { $regex: `${escapeStringRegexp(f.value.trim())}`, $options: "i" },
                  }
                : { [f.field]: f.value }
            )
      )
    ),
  ]);

  return isEmpty(filterExpressions) ? {} : { $and: filterExpressions };
};

export const createMappingFilterCriteria = (filter?: string, tableFilter?: JsonObject) => {
  const filterExpressions = pipe([
    ...pipe(
      option.fromNullable(filter),
      option.chainNullableK((s) => (s.trim() === "" ? undefined : s.trim())),
      option.fold(
        () => [],
        (value) => [
          {
            $or: value
              .split(" ")
              .map((s) => escapeStringRegexp(s.trim()))
              .filter((s) => s !== "")
              .map((s) => [
                { firstName: { $regex: `${s}`, $options: "i" } },
                { lastName: { $regex: `${s}`, $options: "i" } },
                { email: { $regex: `${s}`, $options: "i" } },
              ])
              .reduce((acc, v) => [...acc, ...v], []),
          },
        ]
      )
    ),
    ...pipe(
      option.fromNullable(tableFilter),
      option.fold(
        () => [],
        (value) =>
          Object.entries(value)
            .reduce((acc, [field, value]) => {
              if (typeof value === "string" && value.trim() === "") {
                return acc;
              }
              if (field === "mapped") {
                if (Array.isArray(value) && value.length === 1) {
                  return [...acc, { field: "externalId", value: { $exists: value[0] === "Yes" } }];
                } else {
                  return acc;
                }
              }
              return [...acc, { field, value }];
            }, [])
            .map((f) => {
              if (typeof f.value === "string") {
                return {
                  [f.field]: { $regex: `${escapeStringRegexp(f.value.trim())}`, $options: "i" },
                };
              } else if (Array.isArray(f.value)) {
                return {
                  [f.field]: { $in: f.value },
                };
              } else {
                return { [f.field]: f.value };
              }
            })
      )
    ),
  ]);

  return isEmpty(filterExpressions) ? {} : { $and: filterExpressions };
};
