import { Action } from "@ngrx/store";
import { Salon } from "@getvish/model";

export type CsvUploadFileType = "customer" | "employee" | "serviceDescription";

export enum Types {
  DONE = "[Import Salon Data] Done",
  LOAD_SALON = "[Import Salon Data] Load Salon",
  LOAD_SALON_SUCCESS = "[Import Salon Data] Load Salon Success",
  LOAD_SALON_FAIL = "[Import Salon Data] Load Salon Fail",
  NAVIGATE = "[Import Salon Data] Navigate",
  EXPORT_DATA = "[Import Salon Data] Export Data",
  EXPORT_DATA_SUCCESS = "[Import Salon Data] Export Data Success",
  EXPORT_DATA_FAIL = "[Import Salon Data] Export Data Fail",
  IMPORT_DATA = "[Import Salon Data] Import Data",
  INITIALIZE_SESSION = "[Import Salon Data] Initialize Session",
  INITIALIZE_SESSION_SUCCESS = "[Import Salon Data] Initialize Session Success",
  UPLOAD_CSV = "[Import Salon Data] Upload CSV",
  UPLOAD_CSV_SUCCESS = "[Import Salon Data] Upload CSV Success",
  UPLOAD_CSV_FAIL = "[Import Salon Data] Upload CSV Fail",
  RUN_SESSION = "[Import Salon Data] Run Session",
  RUN_SESSION_SUCCESS = "[Import Salon Data] Run Session Success",
  NEXT = "[Import Salon Data] Next",
}

export class Done implements Action {
  public readonly type = Types.DONE;
}

export class LoadSalon implements Action {
  public readonly type = Types.LOAD_SALON;

  constructor(public payload: { id: string }) {}
}

export class LoadSalonSuccess implements Action {
  public readonly type = Types.LOAD_SALON_SUCCESS;

  constructor(public payload: { salon: Salon }) {}
}

export class LoadSalonFail implements Action {
  public readonly type = Types.LOAD_SALON_FAIL;

  constructor(public payload: { error: string }) {}
}

export class Next implements Action {
  public readonly type = Types.NEXT;
}

export class Navigate implements Action {
  public readonly type = Types.NAVIGATE;

  constructor(public payload: { salon: Salon }) {}
}

export class ExportData implements Action {
  public readonly type = Types.EXPORT_DATA;

  constructor(public payload: { salon: Salon }) {}
}

export class ExportDataSuccess implements Action {
  public readonly type = Types.EXPORT_DATA_SUCCESS;
}

export class ImportData implements Action {
  public readonly type = Types.IMPORT_DATA;

  constructor(public payload: { salon: Salon }) {}
}

export class InitializeSession implements Action {
  public readonly type = Types.INITIALIZE_SESSION;

  constructor(public payload: { salon: Salon }) {}
}

export class InitializeSessionSuccess implements Action {
  public readonly type = Types.INITIALIZE_SESSION_SUCCESS;
}

export class UploadCsv implements Action {
  public readonly type = Types.UPLOAD_CSV;

  constructor(public payload: { type: CsvUploadFileType; file: File; salon: Salon }) {}
}

export class UploadCsvSuccess implements Action {
  public readonly type = Types.UPLOAD_CSV_SUCCESS;

  constructor(public payload: { type: CsvUploadFileType }) {}
}

export class UploadCsvFail implements Action {
  public readonly type = Types.UPLOAD_CSV_FAIL;

  constructor(public payload: { error: string }) {}
}

export class RunSession implements Action {
  public readonly type = Types.RUN_SESSION;

  constructor(public payload: { salon: Salon }) {}
}

export class RunSessionSuccess implements Action {
  public readonly type = Types.RUN_SESSION_SUCCESS;
}

export type Actions =
  | Done
  | LoadSalon
  | LoadSalonSuccess
  | LoadSalonFail
  | Next
  | Navigate
  | ExportData
  | ExportDataSuccess
  | ImportData
  | InitializeSession
  | InitializeSessionSuccess
  | UploadCsv
  | UploadCsvSuccess
  | UploadCsvFail
  | RunSession
  | RunSessionSuccess;
