import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SlideoutPanelService } from "./slideout-panel.service";
import { SlideoutPanelContainerComponent } from "./slideout-panel-container.component";
import { BackdropComponent } from "./backdrop.component";
import { SlideoutPanel } from "./slideout-panel";
import { SlideoutPanelComponent } from "./slideout-panel.component";
import { SlideoutRevealPanelDirective } from "./slideout-reveal-panel.directive";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [SlideoutPanelContainerComponent, BackdropComponent, SlideoutPanelComponent, SlideoutRevealPanelDirective],
  providers: [SlideoutPanel],
  exports: [SlideoutPanelContainerComponent, SlideoutPanelComponent, SlideoutRevealPanelDirective],
})
export class SlideoutPanelModule {
  public static forRoot(): ModuleWithProviders<SlideoutPanelModule> {
    return {
      ngModule: SlideoutPanelModule,
      providers: [SlideoutPanelService],
    };
  }

  constructor(library: FaIconLibrary) {
    library.addIcons(faTimes);
  }
}
