<order-component
  *ngIf="_shouldRenderForm$ | async"
  [order]="order$ | async"
  [appointment]="appointment$ | async"
  [error]="error$ | async"
  [currency]="currency$ | async"
  [measurementUnit]="measurementUnit$ | async"
  [checkingOut]="checkingOut$ | async"
  [reactivating]="reactivating$ | async"
  [resolving]="resolving$ | async"
  [module]="module$ | async"
  [timeZone]="timeZone$ | async"
  (reactivate)="reactivate($event)"
  (checkout)="checkout($event)"
  (resolve)="resolve.emit($event)">
</order-component>

<div *ngIf="loading$ | async">
  <large-loading-indicator></large-loading-indicator>
</div>

<div *ngIf="error$ | async">
  <alert-component type="danger">
    <h5>Something went wrong</h5>
    <span class="title"> Unable to generate ticket </span>

    <p>{{ error$ | async }}</p>
  </alert-component>
</div>
