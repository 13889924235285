import { Component } from "@angular/core";
import { Manufacturer } from "@getvish/model";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppState } from "../../../kernel";
import { SelectManufacturer } from "../store/product-category.actions";

import { getParamMap } from "app/kernel/store/selectors/router.selectors";
import { isNil, not } from "ramda";
import { map } from "rxjs/operators";
import * as ManufacturerActions from "../../+manufacturers/store/manufacturer.actions";
import * as fromManufacturer from "../../+manufacturers/store/manufacturer.selectors";

@Component({
  selector: "manufacturer-list-container",
  templateUrl: "manufacturer-list.container.html",
})
export class ManufacturerListContainer {
  public manufacturers$: Observable<Manufacturer[]>;
  public filter$: Observable<string>;
  public loading$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this._store.dispatch(new ManufacturerActions.LoadAll({ sort: { name: 1 } }));

    this.manufacturers$ = this._store.pipe(select(fromManufacturer.getManufacturers));

    this.loading$ = _store.select(fromManufacturer.getLoading);

    this.filter$ = this._store.pipe(
      select(getParamMap),
      map((params) => (not(isNil(params)) ? params.get("filter") : undefined))
    );
  }

  public selectManufacturer(manufacturer?: Manufacturer): void {
    this._store.dispatch(new SelectManufacturer({ manufacturer }));
  }

  public updateFilter(filter: string): void {
    this._store.dispatch(new ManufacturerActions.SetAdminFilter({ filter }));
  }
}
