import { Injectable } from "@angular/core";
import { Salon } from "@getvish/model";
import { HttpError, JsonObject } from "@getvish/stockpile";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { saveAs } from "file-saver";
import { fold } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { of } from "rxjs";
import { catchError, debounceTime, map, mapTo, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { getAuthToken } from "../../+auth/store";
import { AppState } from "../../kernel";
import { ExportFormulaSpreadsheetService, ExportUsageReportService, SalonService } from "../services";
import { getFilter } from "./salon.selectors";

import * as salonApiVersionActions from "../../kernel/+salon-api-version/store/salon-api-version.actions";
import * as tenantEntitlementActions from "../../kernel/+tenant-entitlements/store/tenant-entitlements.actions";
import * as routerActions from "../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../kernel/store/actions/snackbar.actions";
import * as salonActions from "./salon.actions";

@Injectable()
export class SalonEffects {
  public handleFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonActions.Search>(salonActions.Types.SEARCH),
      debounceTime(500),
      map((action) => action.payload),
      map((payload) =>
        routerActions.go({
          path: ["/salons"],
          query: { filter: payload.filter, page: undefined },
          extras: { queryParamsHandling: "merge" },
        })
      )
    )
  );

  public handleSort$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonActions.UpdateSort>(salonActions.Types.UPDATE_SORT),
      debounceTime(500),
      map((action) => action.payload),
      map((payload) =>
        routerActions.go({
          path: ["/salons"],
          query: { sort: JSON.stringify(payload), page: undefined },
          extras: { queryParamsHandling: "merge" },
        })
      )
    )
  );

  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonActions.Types.LOAD_ALL),
      map((action: salonActions.LoadAll) => action.payload),
      switchMap((payload) => {
        return this._salonService.search(payload.filter, payload.sort, payload.page, payload.limit).pipe(
          mergeMap(({ records, paging }) => [
            new salonActions.LoadAllSuccess(records, paging),
            salonApiVersionActions.loadForSalons({ salons: records }),
            tenantEntitlementActions.loadForSalons({ salons: records }),
          ]),
          catchError((reason) => of(new salonActions.LoadAllFail({ errors: reason })))
        );
      })
    )
  );

  public navigateToPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonActions.NavigateToPage>(salonActions.Types.NAVIGATE_TO_PAGE),
      map((action) => action.payload.page),
      withLatestFrom(this._store.pipe(select(getFilter))),
      map(([page, filter]) => routerActions.go({ path: ["/salons"], query: { filter, page } }))
    )
  );

  public add$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonActions.Types.ADD),
      map((action: salonActions.Add) => action.payload.salon),
      switchMap((payload) =>
        this._salonService.insert(payload).pipe(
          mergeMap(
            fold<Error, Salon, Action[]>(
              (error) => [new salonActions.AddFail({ errors: error })],
              (salon) => [new salonActions.AddSuccess(salon)]
            )
          )
        )
      )
    )
  );

  public addOrUpdateSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonActions.Types.ADD_SUCCESS, salonActions.Types.UPDATE_SUCCESS),
      mapTo(routerActions.go({ path: ["/salons"], extras: { queryParamsHandling: "merge" } }))
    )
  );

  public update$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonActions.Types.UPDATE),
      map((action: salonActions.Update) => action.payload.salon),
      switchMap((payload) =>
        this._salonService.update(payload).pipe(
          mergeMap(
            fold<Error, Salon, Action[]>(
              (fail) => [new salonActions.UpdateFail({ errors: fail })],
              (updatedSalon) => [new salonActions.UpdateSuccess(updatedSalon)]
            )
          ),
          catchError((error) => of(new salonActions.UpdateFail({ errors: error })))
        )
      )
    )
  );

  public retroactivelyGenerateOrders$ = createEffect(() =>
    this._actions$.pipe(
      ofType<salonActions.RetroactivelyGenerateOrders>(salonActions.Types.RETROACTIVELY_GENERATE_ORDERS),
      map((action) => action.payload.salon._id),
      switchMap((salonId) =>
        this._salonService.createOrders(salonId).pipe(
          map((result) =>
            pipe(
              result,
              fold<HttpError, JsonObject, Action>(
                () => new salonActions.RetroactivelyGenerateOrdersFail(),
                () => new salonActions.RetroactivelyGenerateOrdersSuccess()
              )
            )
          )
        )
      )
    )
  );

  public retroactivelyGenerateOrdersSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonActions.Types.RETROACTIVELY_GENERATE_ORDERS_SUCCESS),
      map(() => new SnackbarActions.Info({ message: "Orders generated successfully" }))
    )
  );

  public retroactivelyGenerateOrdersFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salonActions.Types.RETROACTIVELY_GENERATE_ORDERS_FAIL),
      map(() => new SnackbarActions.Info({ message: "Generating orders failed; something went wrong" }))
    )
  );

  public downloadSpreadsheet$ = createEffect(() =>
    this._actions$
      .pipe(
        ofType<salonActions.ExportFormulas>(salonActions.Types.EXPORT_FORMULAS),
        map((action) => action.payload.slug),
        withLatestFrom(this._store.pipe(select(getAuthToken))),
        switchMap(([slug, authToken]) => this._exportFormulaSpreadsheetService.downloadFormulaSpreadsheet(slug, authToken))
      )
      .pipe(
        tap((response) => saveAs(response, "vish-formula-export.xlsx")),
        map(() => new SnackbarActions.Info({ message: "Formulas exported successfully" })),
        catchError(() => of(new SnackbarActions.Info({ message: "Something went wrong: Failed to export Client Formulas" })))
      )
  );

  public exportSalonUsageReport$ = createEffect(() =>
    this._actions$
      .pipe(
        ofType<salonActions.ExportUsageReport>(salonActions.Types.EXPORT_USAGE_REPORT),
        withLatestFrom(this._store.select(getAuthToken)),
        switchMap(([_slug, authToken]) => this._exportUsageReportService.generateSalonUsageReport("admin", authToken))
      )
      .pipe(
        map(() => new SnackbarActions.Info({ message: "Export started. Check your email for download link." })),
        catchError(() => of(new SnackbarActions.Info({ message: "Something went wrong: Failed to export usage report." })))
      )
  );

  constructor(
    private _salonService: SalonService,
    private _exportFormulaSpreadsheetService: ExportFormulaSpreadsheetService,
    private _exportUsageReportService: ExportUsageReportService,
    private _store: Store<AppState>,
    private _actions$: Actions
  ) {}
}
