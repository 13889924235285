import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Employee, Salon } from "@getvish/model";
import { HttpError, HttpRepositoryFactory, HttpRequestHandler, JsonObject, PagedResult, PagingMetadata } from "@getvish/stockpile";
import { AuthStorageService } from "app/+auth/services";
import { WindowService } from "app/kernel/services";
import { HTTP_REQUEST_TRANSFORMER, HTTP_URL } from "app/kernel/services/common";
import { PatchingEntityService } from "app/kernel/services/patching-entity-service";
import { either, option } from "fp-ts";
import { Either, fold, left, right } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { separate } from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { isNil } from "ramda";
import { Observable, forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable()
export class EmployeeService extends PatchingEntityService<Employee> {
  constructor(
    repositoryFactory: HttpRepositoryFactory,
    private _requestHandler: HttpRequestHandler,
    _http: HttpClient,
    @Inject(HTTP_REQUEST_TRANSFORMER) _requestTransformer,
    @Inject(HTTP_URL) _httpUrl: string,
    private _windowService: WindowService,
    private _authStorage: AuthStorageService
  ) {
    super("employees", repositoryFactory, _http, _requestTransformer, _httpUrl);
  }

  public find(
    criteria?: JsonObject,
    sort?: JsonObject,
    page?: number,
    limit?: number,
    deletedOnly?: boolean
  ): Observable<PagedResult<Employee>> {
    return deletedOnly === true ? this.findDeleted(criteria, sort, page, limit) : super.find(criteria, sort, page, limit);
  }

  public findDeleted(
    criteria: JsonObject = {},
    sort = {},
    page = 1,
    limit: number = Number.MAX_SAFE_INTEGER
  ): Observable<PagedResult<Employee>> {
    const urlCriteria = JSON.stringify(criteria);
    const urlSort = JSON.stringify(sort);
    const endpoint = `${this._httpUrl}/${this.controllerKey}/deleted?criteria=${urlCriteria}&sort=${urlSort}&page=${page}&limit=${limit}`;

    const headers = new HttpHeaders({
      "X-Salon-Slug": this._windowService.tenantPathName,
      "X-Auth-Token": this._authStorage.getAuthToken(),
    });

    return this._http.get<Employee[]>(endpoint, { headers, observe: "response" }).pipe(
      map((response) => {
        const responseHeaders = response.headers;

        const paging: PagingMetadata = {
          count: this.parseHeaderToInt(responseHeaders.get("X-PAGING-COUNT")),
          page: this.parseHeaderToInt(responseHeaders.get("X-PAGING-PAGE")),
          limit: this.parseHeaderToInt(responseHeaders.get("X-PAGING-LIMIT")),
          offset: this.parseHeaderToInt(responseHeaders.get("X-PAGING-OFFSET")),
          pages: this.parseHeaderToInt(responseHeaders.get("X-PAGING-PAGES")),
        };

        return new PagedResult(response.body, paging);
      })
    );
  }

  update(employee: Employee): Observable<either.Either<Error, Employee>> {
    return super.update(
      Object.entries(employee).reduce((acc, [key, value]) => {
        if (key !== "password" || value != null) {
          acc[key] = value;
        }

        return acc;
      }, {} as Employee)
    );
  }

  public restoreEmployee(employee: Employee): Observable<Either<HttpError, void>> {
    return (
      employee.email == null || employee.email === ""
        ? of(right({}))
        : this._requestHandler.post(`${this.controllerKey}/uniqueEmail`, {
            email: employee.email,
            employeeId: employee._id,
          })
    ).pipe(
      switchMap(
        fold(
          (e) => of(left(e)),
          () => this._requestHandler.post<void>(`${this.controllerKey}/${employee._id}/restore`, {})
        )
      )
    );
  }

  public setRoles(employeeId: string, roles: string[]): Observable<either.Either<Error, Employee>> {
    const payload = { employeeId, roles };
    const endpoint = `${this.controllerKey}/${employeeId}/setRoles`;

    return this._requestHandler.post<Employee>(endpoint, payload).pipe(
      map((response) =>
        pipe(
          response,
          either.mapLeft((fail) => new Error(fail.payload["message"]))
        )
      )
    );
  }

  public checkUniquePinCode(employee: Employee): Observable<boolean> {
    const pinCode = employee.pinCode;
    const employeeId = employee._id;
    const payload = { pinCode, employeeId };

    if (isNil(pinCode)) {
      return of(true);
    }

    return this._requestHandler.post("employees/uniquePincode", payload).pipe(map(either.isRight));
  }

  public importEmployee(employeeId: string): Observable<Either<HttpError, void>> {
    const payload = { employeeId };
    const endpoint = `${this.controllerKey}/${employeeId}/tenant`;

    return this._requestHandler.post<void>(endpoint, payload);
  }

  public setManyRoles(employees: Employee[]): Observable<either.Either<Error[], Employee[]>> {
    const requests$ = employees.map((employee) => this.setRoles(employee._id, employee._roles));
    return forkJoin(requests$).pipe(
      map(separate),
      map(({ left: errors, right: employees }) => {
        if (errors.length > 0) {
          return either.left(errors);
        }

        return either.right(employees);
      })
    );
  }

  public listTenants(employeeId: string): Observable<Salon[]> {
    return this._requestHandler.get<Salon[]>(`employees/${employeeId}/tenant`).pipe(map(O.toUndefined));
  }

  private parseHeaderToInt(value: string): number | undefined {
    const parseToNumber = (value: string) => parseInt(value, 10);
    return pipe(value, option.fromNullable, option.map(parseToNumber), option.toUndefined);
  }
}
