import { Injectable } from "@angular/core";
import { Salon } from "@getvish/model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { either, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { of } from "rxjs";
import { catchError, map, mapTo, mergeMap, switchMap } from "rxjs/operators";
import { SalonService } from "../services";

import { HttpError } from "@getvish/stockpile";
import * as routerActions from "../../kernel/store/actions/router.actions";
import * as actions from "./migrate-salon.actions";

@Injectable()
export class MigrateSalonEffects {
  public navigate$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Navigate>(actions.Types.NAVIGATE),
      map((action) => action.payload.salonId),
      map((id) =>
        routerActions.go({ path: ["/salons", { outlets: { panel: `migrate/${id}` } }], extras: { queryParamsHandling: "merge" } })
      )
    )
  );

  public load$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Load>(actions.Types.LOAD),
      map((action) => action.payload.salonId),
      switchMap((salonId) =>
        this._salonService.findById(salonId).pipe(
          map(
            option.fold<Salon, Action>(
              () => new actions.LoadFail({ error: new Error("Salon not found") }),
              (salon) => new actions.LoadSuccess({ salon })
            )
          )
        )
      )
    )
  );

  public migrate$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Migrate>(actions.Types.MIGRATE),
      map((action) => action.payload.salonId),
      switchMap((id) =>
        this._salonService.migrateToV2(id).pipe(
          mergeMap(
            pipe(
              either.fold<HttpError, void, Action[]>(
                (error) => [new actions.Fail({ error: new Error(`Migrate request failed with code: ${error.code}`) })],
                () => [new actions.Success()]
              )
            )
          ),
          catchError((error) => of(new actions.Fail({ error })))
        )
      )
    )
  );

  public finished$ = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.Types.DONE, actions.Types.CANCEL),
      mapTo(routerActions.go({ path: ["/salons"], extras: { queryParamsHandling: "merge" } }))
    )
  );

  constructor(
    private _salonService: SalonService,
    private _actions$: Actions
  ) {}
}
