import { Salon } from "@getvish/model";

import * as actions from "./revoke-restore-access.actions";
import { createReducer, on } from "@ngrx/store";

export interface RevokeRestoreAccessState {
  salon: Salon;
  loading: boolean;
  revoking: boolean;
  restoring: boolean;
  error: Error;
}

const initialState: RevokeRestoreAccessState = {
  salon: undefined,
  loading: false,
  revoking: false,
  restoring: false,
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(actions.navigateToRevoke, () => ({
    ...initialState,
  })),
  on(actions.load, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.loadSuccess, (state, { salon }) => ({
    ...state,
    loading: false,
    salon,
  })),
  on(actions.loadFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(actions.revokeAccess, (state) => ({
    ...state,
    revoking: true,
  })),
  on(actions.revokeAccessSuccess, (state) => ({
    ...state,
    revoking: false,
  })),
  on(actions.revokeAccessFail, (state, { error }) => ({
    ...state,
    revoking: false,
    error,
  })),
  on(actions.restoreAccess, (state) => ({
    ...state,
    restoring: true,
  })),
  on(actions.restoreAccessSuccess, (state) => ({
    ...state,
    restoring: false,
  })),
  on(actions.restoreAccessFail, (state, { error }) => ({
    ...state,
    restoring: false,
    error,
  }))
);

export const featureKey = "revokeAccess";
