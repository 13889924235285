import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as L from "leaflet";
import { equals } from "ramda";

const MAP_LAYER = {
  urlTemplate: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  options: {
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
};

@Component({
  selector: "map",
  templateUrl: "map.component.html",
  styleUrls: ["map.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  public markers: Array<L.Marker>;

  @ViewChild("map")
  private mapRef: ElementRef;

  private map!: L.Map;
  private resizeObserver: ResizeObserver;

  public ngAfterViewInit(): void {
    this._init();
    this._handleMarkers(null, this.markers);
  }

  public ngOnDestroy(): void {
    if (this.resizeObserver != null) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.map != null) {
      if (!equals(changes.markers.previousValue, changes.markers.currentValue)) {
        this._handleMarkers(changes.markers.previousValue, changes.markers.currentValue);
      }
    }
  }

  private _init() {
    this.map = L.map(this.mapRef.nativeElement);
    L.tileLayer(MAP_LAYER.urlTemplate, MAP_LAYER.options).addTo(this.map);

    this.resizeObserver = new ResizeObserver(() => {
      this.map.invalidateSize();
    });

    this.resizeObserver.observe(this.mapRef.nativeElement);
  }

  private _handleMarkers(previousMarkers: Array<L.Marker>, currentMarkers: Array<L.Marker>) {
    if (previousMarkers != null) {
      for (const previousMarker of previousMarkers) {
        previousMarker.remove();
      }
    }

    if (currentMarkers != null) {
      for (const currentMarker of currentMarkers) {
        currentMarker.addTo(this.map);
      }

      this.map.fitBounds(L.featureGroup(currentMarkers).getBounds());
    }
  }
}
