<div
  *ngIf="order | isDefined"
  class="page-content"
  [progressIndicator]="getProgressText()">
  <header>
    <div class="left">
      <button
        *ngIf="appointment?.changes?.length > 0"
        matTooltip="One or more services have been updated for this appointment. Please update the appointment in your POS system accordingly."
        class="button transparent important">
        <fa-icon icon="circle-exclamation"></fa-icon>
      </button>
      <h4>{{ order.customerName }}</h4>
    </div>
    <div
      class="right"
      *ngIf="module === 'front-desk'">
      <button
        id="checkout-button"
        *ngIf="order.status !== 'CHECKED_OUT'"
        (click)="checkout.emit(order)"
        class="button outline">
        Check Out
      </button>

      <div
        *ngIf="order.status === 'CHECKED_OUT' || appointment?.changes?.length > 0"
        id="checked-out-container">
        <div
          *ngIf="order.status === 'CHECKED_OUT'"
          id="checked-out-indicator"
          @spin>
          <fa-icon icon="check-circle"></fa-icon>
        </div>
        <mat-menu #moreOptionsMenu="matMenu">
          <button
            *ngIf="appointment?.changes?.length > 0"
            (click)="resolve.emit(appointment)"
            mat-menu-item>
            Resolve Alert
          </button>
          <button
            *ngIf="order.status === 'CHECKED_OUT'"
            (click)="reactivate.emit(order)"
            mat-menu-item>
            Reactivate
          </button>
        </mat-menu>
        <button
          [matMenuTriggerFor]="moreOptionsMenu"
          class="button secondary transparent">
          <fa-icon icon="ellipsis-v"></fa-icon>
        </button>
      </div>
    </div>
    <span
      id="customer-subtitle"
      *ngIf="order.status === 'COMPLETED'">
      Order is completed and ready to be checked out
    </span>
  </header>

  <div>
    <table class="order-details">
      <tbody>
        <tr>
          <th>Date</th>
          <td>{{ order.date | zonedDate: timeZone : "MMM d, y, h:mm a" }}</td>
        </tr>
        <tr>
          <th>Employee</th>
          <td>{{ order.employeeName }}</td>
        </tr>
        <tr>
          <th>Services</th>
          <td>{{ order.orderLines.length }}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <order-lines
      [orderLines]="order.orderLines"
      [currency]="currency"
      [measurementUnit]="measurementUnit"
      [loading]="loading">
    </order-lines>
    <ng-template #emptyAppointment>
      <alert-component type="info"> This appointment has no services </alert-component>
    </ng-template>
    <hr />
    <table
      id="order-summary"
      class="order-details">
      <tbody>
        <tr>
          <th>Extra Product</th>
          <td>
            {{ order.productOveruseTotal | currency: currency | default: "-" }}
          </td>
        </tr>
        <tr class="total-line">
          <th>Total</th>
          <td>
            <span>{{ order.total | currency: currency }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
