import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SalonProduct, MeasurementUnit } from "@getvish/model";
import { isNil } from "ramda";
import { back } from "../../../kernel/store/actions/router.actions";
import { addOrUpdate } from "../store/salon-product.actions";
import { AppState } from "app/kernel";

import * as fromSalonProduct from "../store/salon-product.selectors";
import * as fromSalonConfig from "../../../+salon-config/store/salon-config.selectors";

@Component({
  selector: "edit-salon-product-container",
  templateUrl: "edit-salon-product.container.html",
})
export class EditSalonProductContainer {
  public product$: Observable<SalonProduct>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public currency$: Observable<string>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.product$ = _route.paramMap.pipe(
      map((params) => params.get("id")),
      mergeMap((id) => (isNil(id) ? of(new SalonProduct()) : _store.pipe(select(fromSalonProduct.getOne(id)))))
    );

    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
  }

  public save(product: SalonProduct): void {
    this._store.dispatch(addOrUpdate({ product }));
  }

  public close(): void {
    this._store.dispatch(back());
  }
}
