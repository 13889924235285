import { SalonConfig, ServiceCategory, ServiceDescription } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { Action } from "@ngrx/store";
import { ProductAllowanceVM } from "app/kernel/models";
import escapeStringRegexp from "escape-string-regexp";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { isEmpty, isNil, or } from "ramda";
import { ServiceComplianceAnomaly } from "../model";

export enum Types {
  LOAD_SALON_CONFIG = "[Service Menu] Load Salon Config",
  LOAD_SALON_CONFIG_SUCCESS = "[Service Menu] Load Salon Config Success",
  LOAD_SALON_CONFIG_FAIL = "[Service Menu] Load Salon Config Fail",
  LOAD_ALL = "[Service Menu] Load All",
  LOAD_SERVICE_DESCRIPTIONS_SUCCESS = "[Service Menu] Load Service Descriptions Success",
  LOAD_SERVICE_DESCRIPTIONS_FAIL = "[Service Menu] Load Service Descriptions Fail",
  LOAD_SERVICE_CATEGORIES_SUCCESS = "[Service Menu] Load Service Categories Success",
  LOAD_PRODUCT_ALLOWANCES_SUCCESS = "[Service Menu] Load Product Allowances Success",
  CLEAR_SERVICE_COMPLIANCE_ANOMALIES = "[Service Menu] Clear Service Compliance Anomalies",
  LOAD_SERVICE_COMPLIANCE_ANOMALIES = "[Service Menu] Load Service Compliance Anomalies",
  LOAD_SERVICE_COMPLIANCE_ANOMALIES_SUCCESS = "[Service Menu] Load Service Compliance Anomalies Success",
  TOGGLE_SELECTED = "[Service Menu] Toggle Selected Service",
  TOGGLE_SELECTED_CATEGORY = "[Service Menu] Toggle Selected Category",
  DESELECT_ALL = "[Service Menu] Deselect All",
  UPDATE_MANY_TOGGLE_IS_ACTIVE = "[Service Menu] Update Many Toggle IsActive",
  UPDATE_MANY_TOGGLE_IS_ACTIVE_SUCCESS = "[Service Menu] Update Many Toggle IsActive Success",
  UPDATE_MANY_TOGGLE_IS_ACTIVE_FAIL = "[Service Menu] Update Many Toggle IsActive Fail",
  TOGGLE_PARTS_AND_LABOR = "[Service Menu] Toggle Parts And Labor",
  TOGGLE_PARTS_AND_LABOR_SUCCESS = "[Service Menu] Toggle Parts And Labor Success",
  TOGGLE_PARTS_AND_LABOR_FAIL = "[Service Menu] Toggle Parts And Labor Fail",
  CLEAR_SELECTED_SERVICES = "[Service Menu] Clear Selected Services",
  TOGGLE_UNCATEGORIZED_ONLY = "[Service Menu] Toggle Uncategorized Only",
  TOGGLE_SHOW_INACTIVE = "[Service Menu] Toggle Show Inactive",
  CALCULATE_PRODUCT_ALLOWANCE = "[Service Menu] Calculate Product Allowance",
  NAVIGATE_TO_PAGE = "[Service Menu] Navigate To Page",
  RELOAD = "[Service Menu] Reload",
  SEARCH = "[Service Menu] Search",
  EDIT_SERVICE_DONE = "[Service Menu] Edit Service Done",
  DOWNLOAD_SERVICE_MENU_REPORT = "[Service Menu] Download Service Menu Report",
  DOWNLOAD_SERVICE_MENU_REPORT_SUCCESS = "[Service Menu] Download Service Menu Report Success",
  DOWNLOAD_SERVICE_MENU_REPORT_FAIL = "[Service Menu] Download Service Menu Report Fail",
  UPDATE_SERVICE_DESCRIPTIONS_SUCCESS = "[Service Menu] Update Service Descriptions Success",
  UPDATE_SERVICE_CATEGORIES_SUCCESS = "[Service Menu] Update Service Categories Success",
  UPDATE_TABLE_FILTER = "[Service Menu] Update Table Filter",
}

export type ServiceMenuLoadPayload = {
  filter: string;
  tableFilter?: JsonObject;
  sort?: JsonObject;
  page?: number;
  limit?: number;
  uncategorizedOnly?: boolean;
  showInactive?: boolean;
};

export const makeServiceMenuCriteria = (payload: ServiceMenuLoadPayload) => {
  const emptyCriteria = {};

  const makeCriteria = (filter: string) => {
    return {
      name: { $regex: escapeStringRegexp(filter), $options: "i" },
    };
  };

  const criteria = pipe(
    option.fromNullable(payload.filter),
    option.fold(
      () => emptyCriteria,
      (value) => makeCriteria(value)
    )
  );

  const anomalyCriteria = {};

  const flagsCriteriaArray = [];

  if (payload.showInactive !== true) {
    flagsCriteriaArray.push({ $ne: "INACTIVE" });
  }

  if (payload.tableFilter?.flags != null) {
    const flags = (payload.tableFilter.flags as string[]).filter((flag) => flag !== "ANOMALY");

    if (flags.length) {
      flagsCriteriaArray.push({ $in: flags });
    }

    if ((payload.tableFilter.flags as string[]).includes("ANOMALY")) {
      anomalyCriteria["anomaly"] = true;
    }
  }

  const flagsCriteria =
    flagsCriteriaArray.length === 0 ? {} : { flags: flagsCriteriaArray.reduce((acc, criteria) => ({ ...acc, ...criteria }), {}) };

  const uncategorizedCriteria = payload.uncategorizedOnly === true ? { categoryId: null } : {};

  return {
    ...criteria,
    ...anomalyCriteria,
    ...flagsCriteria,
    ...uncategorizedCriteria,
  };
};

export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;
  public payload: ServiceMenuLoadPayload;

  constructor(payload: ServiceMenuLoadPayload) {
    // if we weren't explicitly given a sort then we'll provide a default
    const sort = or(isEmpty(payload.sort), isNil(payload.sort)) ? { categoryId: 1, name: 1 } : payload.sort;

    this.payload = {
      filter: payload.filter,
      tableFilter: payload.tableFilter,
      showInactive: payload.showInactive,
      uncategorizedOnly: payload.uncategorizedOnly,
      sort,
      limit: payload.limit,
      page: payload.page,
    };
  }
}

export class LoadServiceDescriptionsSuccess implements Action {
  public readonly type = Types.LOAD_SERVICE_DESCRIPTIONS_SUCCESS;

  constructor(
    public payload: ServiceDescription[],
    public paging: PagingMetadata
  ) {}
}

export class LoadServiceDescriptionsFail implements Action {
  public readonly type = Types.LOAD_SERVICE_DESCRIPTIONS_FAIL;

  constructor(public payload: { error: any }) {}
}

export class LoadServiceCategoriesSuccess implements Action {
  public readonly type = Types.LOAD_SERVICE_CATEGORIES_SUCCESS;

  constructor(public payload: ServiceCategory[]) {}
}

export class LoadProductAllowancesSuccess implements Action {
  public readonly type = Types.LOAD_PRODUCT_ALLOWANCES_SUCCESS;

  constructor(public payload: { [serviceId: string]: ProductAllowanceVM }) {}
}

export class ClearServiceComplianceAnomalies implements Action {
  public readonly type = Types.CLEAR_SERVICE_COMPLIANCE_ANOMALIES;
}

export class LoadServiceComplianceAnomalies implements Action {
  public readonly type = Types.LOAD_SERVICE_COMPLIANCE_ANOMALIES;

  constructor(public payload: { startDate: number; endDate: number }) {}
}

export class LoadServiceComplianceAnomaliesSuccess implements Action {
  public readonly type = Types.LOAD_SERVICE_COMPLIANCE_ANOMALIES_SUCCESS;

  constructor(public payload: ServiceComplianceAnomaly[]) {}
}

export class LoadSalonConfig implements Action {
  public readonly type = Types.LOAD_SALON_CONFIG;
}

export class LoadSalonConfigSuccess implements Action {
  public readonly type = Types.LOAD_SALON_CONFIG_SUCCESS;

  constructor(public payload: SalonConfig) {}
}

export class LoadSalonConfigFail implements Action {
  public readonly type = Types.LOAD_SALON_CONFIG_FAIL;

  constructor(public payload: { error: Error }) {}
}

export class ToggleSelected implements Action {
  public readonly type = Types.TOGGLE_SELECTED;

  constructor(public payload: ServiceDescription) {}
}

export class ToggleSelectedCategory implements Action {
  public readonly type = Types.TOGGLE_SELECTED_CATEGORY;

  constructor(public payload: { categoryId: string; services: ServiceDescription[] }) {}
}

export class DeselectAll implements Action {
  public readonly type = Types.DESELECT_ALL;
}

export class UpdateManyToggleIsActive implements Action {
  public readonly type = Types.UPDATE_MANY_TOGGLE_IS_ACTIVE;
}

export class UpdateManyToggleIsActiveSuccess implements Action {
  public readonly type = Types.UPDATE_MANY_TOGGLE_IS_ACTIVE_SUCCESS;
}

export class UpdateManyToggleIsActiveFail implements Action {
  public readonly type = Types.UPDATE_MANY_TOGGLE_IS_ACTIVE_FAIL;
}

export class TogglePartsAndLabor implements Action {
  public readonly type = Types.TOGGLE_PARTS_AND_LABOR;

  constructor(public payload: { serviceDescription?: ServiceDescription }) {}
}

export class TogglePartsAndLaborSuccess implements Action {
  public readonly type = Types.TOGGLE_PARTS_AND_LABOR_SUCCESS;
}
export class TogglePartsAndLaborFail implements Action {
  public readonly type = Types.TOGGLE_PARTS_AND_LABOR_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class ClearSelectedServices implements Action {
  public readonly type = Types.CLEAR_SELECTED_SERVICES;
}

export class ToggleShowInactive implements Action {
  public readonly type = Types.TOGGLE_SHOW_INACTIVE;
}

export class ToggleUncategorizedOnly implements Action {
  public readonly type = Types.TOGGLE_UNCATEGORIZED_ONLY;
}

export class CalculateProductAllowance implements Action {
  public readonly type = Types.CALCULATE_PRODUCT_ALLOWANCE;
}

export class NavigateToPage implements Action {
  public readonly type = Types.NAVIGATE_TO_PAGE;

  constructor(public payload: { page: number }) {}
}

export class Reload implements Action {
  public readonly type = Types.RELOAD;
}

export class Search implements Action {
  public readonly type = Types.SEARCH;

  constructor(public payload: { filter: string; sort?: JsonObject; limit?: number }) {}
}

export class UpdateTableFilter implements Action {
  public readonly type = Types.UPDATE_TABLE_FILTER;

  constructor(public payload: JsonObject) {}
}

export class EditServiceDone implements Action {
  public readonly type = Types.EDIT_SERVICE_DONE;
}

export class DownloadServiceMenuReport implements Action {
  public readonly type = Types.DOWNLOAD_SERVICE_MENU_REPORT;
}

export class DownloadServiceMenuReportSuccess implements Action {
  public readonly type = Types.DOWNLOAD_SERVICE_MENU_REPORT_SUCCESS;
}

export class DownloadServiceMenuReportFail implements Action {
  public readonly type = Types.DOWNLOAD_SERVICE_MENU_REPORT_FAIL;
}

export class UpdateServiceDescriptionsSuccess implements Action {
  public readonly type = Types.UPDATE_SERVICE_DESCRIPTIONS_SUCCESS;

  constructor(public payload: ServiceDescription[]) {}
}

export class UpdateServiceCategoriesSuccess implements Action {
  public readonly type = Types.UPDATE_SERVICE_CATEGORIES_SUCCESS;

  constructor(public payload: ServiceCategory[]) {}
}

export type Actions =
  | LoadAll
  | LoadServiceDescriptionsSuccess
  | LoadServiceDescriptionsFail
  | LoadServiceCategoriesSuccess
  | LoadProductAllowancesSuccess
  | ClearServiceComplianceAnomalies
  | LoadServiceComplianceAnomalies
  | LoadServiceComplianceAnomaliesSuccess
  | LoadSalonConfig
  | LoadSalonConfigSuccess
  | LoadSalonConfigFail
  | ToggleSelected
  | ToggleSelectedCategory
  | DeselectAll
  | UpdateManyToggleIsActive
  | UpdateManyToggleIsActiveSuccess
  | UpdateManyToggleIsActiveFail
  | ClearSelectedServices
  | ToggleUncategorizedOnly
  | ToggleShowInactive
  | CalculateProductAllowance
  | NavigateToPage
  | Reload
  | Search
  | UpdateTableFilter
  | EditServiceDone
  | DownloadServiceMenuReport
  | DownloadServiceMenuReportSuccess
  | DownloadServiceMenuReportFail
  | TogglePartsAndLabor
  | TogglePartsAndLaborSuccess
  | TogglePartsAndLaborFail
  | UpdateServiceDescriptionsSuccess
  | UpdateServiceCategoriesSuccess;
