import { Injectable } from "@angular/core";
import { WindowService } from "app/kernel/services";
import { AppConfig } from "app/kernel/models/app-config";
import { SalonApiVersion } from "app/kernel/models/salon-api-version";

@Injectable()
export class TenantApiVersionCheckService {
  constructor(
    private _appConfig: AppConfig,
    private _windowService: WindowService
  ) {}

  public isCorrectApiVersion(salonApiVersion: SalonApiVersion): boolean {
    if (
      salonApiVersion.apiVersion !== this._appConfig.apiVersion &&
      new RegExp(`^\\/${this._windowService.tenantSlug}\\/integrations`).test(this._windowService.pathname)
    ) {
      console.log("Intentionally not failing API version check for /integrations");
      return true;
    }
    return salonApiVersion.apiVersion === this._appConfig.apiVersion;
  }

  public navigateToTenantForApiVersion(salonApiVersion: SalonApiVersion, salonSlug: string): void {
    const url = salonApiVersion.apiVersion === "V1" ? this._appConfig.v1Url : this._appConfig.v2Url;

    this._windowService.changeLocation(`${url}/${salonSlug}`);
  }
}
