import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, combineLatest, merge } from "rxjs";
import { ManufacturerVm, fromManufacturer } from "../../../+manufacturers/models/manufacturer-view.model";
import { AppState } from "../../../../kernel";

import { MatDialogRef } from "@angular/material/dialog";
import { Manufacturer, MeasurementUnit, Product, ProductCategory } from "@getvish/model";
import { ConfirmDialogService } from "app/kernel/shared/rocket-ui/+confirm-dialog/services/confirm-dialog.service";
import { isEmpty, not } from "ramda";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { ProductCategoryVM, fromProductCategories } from "../../../+salon-products/common";

import { Filter, FilterKey, ProductSelectionVM, fromProducts, importProductsFilters } from "../../models/product-selection-vm";

import {
  clearCategories,
  confirmImport,
  deselectProducts,
  importAdditional,
  importProducts,
  load,
  popToCategory,
  previousStep,
  pushCategory,
  removeAllProductsForManufacturer,
  search,
  selectManufacturer,
  selectProducts,
  setFilters,
  toggleProduct,
} from "../../store/import-salon-products.actions";

import * as fromSalonConfig from "../../../../+salon-config/store/salon-config.selectors";
import * as fromImportSalonProducts from "../../store/import-salon-products.selectors";

@Component({
  templateUrl: "./import-products-dialog.component.html",
  styleUrls: ["../common/product-selection-dialog.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportProductsDialogComponent implements OnDestroy {
  public loading$: Observable<boolean>;

  public manufacturers$: Observable<ManufacturerVm[]>;
  public selectedManufacturer$: Observable<Manufacturer>;
  public salonProducts$: Observable<ProductSelectionVM[]>;
  public productCategories$: Observable<ProductCategoryVM[]>;

  public selectedCategories$: Observable<ProductCategory[]>;
  public previousStep$: Observable<number>;
  public hasSeenConfirm$: Observable<boolean>;
  public selectedProductsGroups$: Observable<{ manufacturer: Manufacturer; products: Product[] }[]>;
  public saving$: Observable<boolean>;
  private isSaving: boolean = false;

  public measurementUnit$: Observable<MeasurementUnit>;
  public currency$: Observable<string>;
  public searchFilter$: Observable<string>;
  public flagFilters$: Observable<string[]>;
  public breadcrumbsItems$: Observable<Array<{ _id: string; name: string }>>;
  public importStep$: Observable<number>;
  public allProductsSelected$: Observable<boolean>;

  public availableFilters: Filter[] = importProductsFilters;

  public trackByGroup = (_: number, value: { manufacturer: Manufacturer; products: Product[] }): string => value.manufacturer._id;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private _store: Store<AppState>,
    private _matDialogRef: MatDialogRef<ImportProductsDialogComponent, { manufacturer: Manufacturer; products: Product[] }[]>,
    private _confirmDialogService: ConfirmDialogService
  ) {
    this.loading$ = this._store.pipe(select(fromImportSalonProducts.getLoading));
    this.searchFilter$ = this._store.pipe(select(fromImportSalonProducts.getSearchFilter));
    this.flagFilters$ = this._store.pipe(select(fromImportSalonProducts.getFlagFilters));

    this.selectedManufacturer$ = this._store.pipe(select(fromImportSalonProducts.getSelectedManufacturer));

    this.saving$ = this._store.pipe(select(fromImportSalonProducts.getSaving));

    this.saving$.subscribe((saving) => {
      this.isSaving = saving;
    });

    this.salonProducts$ = combineLatest([
      this._store.pipe(select(fromImportSalonProducts.getFilteredProducts)),
      this._store.pipe(select(fromImportSalonProducts.getSalonProducts)),
      this._store.pipe(select(fromImportSalonProducts.getProductsForSelectedManufacturer)),
      this._store.pipe(select(fromImportSalonProducts.getAvailableCategories)),
      this._store.pipe(select(fromImportSalonProducts.getSelectedCategories)),
      this._store.pipe(select(fromImportSalonProducts.getFlagFilters)),
    ]).pipe(
      map(([products, salonProducts, selectedProducts, productCategories, selectedCategories, flagFilters]) =>
        fromProducts(products, selectedProducts, salonProducts, productCategories, selectedCategories, flagFilters)
      )
    );

    this.previousStep$ = this._store.pipe(select(fromImportSalonProducts.getPreviousStep));
    this.hasSeenConfirm$ = this._store.pipe(select(fromImportSalonProducts.hasSeenConfirm));

    this.allProductsSelected$ = this.salonProducts$.pipe(
      map((products) => products.every((product) => product.selected || product.inactive))
    );

    this.productCategories$ = combineLatest([
      this._store.pipe(select(fromImportSalonProducts.getAvailableCategories)),
      this._store.pipe(select(fromImportSalonProducts.getSelectedCategories)),
      this._store.pipe(select(fromImportSalonProducts.getFilteredProducts)),
    ]).pipe(
      map(([productCategories, selectedCategories, products]) => fromProductCategories(productCategories, selectedCategories, products))
    );

    this.measurementUnit$ = _store.pipe(select(fromSalonConfig.getMeasurementUnitOrDefault));

    this.manufacturers$ = combineLatest([
      this._store.pipe(select(fromImportSalonProducts.getFilteredManufacturers)),
      this._store.pipe(select(fromImportSalonProducts.getSalonProducts)),
    ]).pipe(map(([manufacturers, salonProducts]) => manufacturers.map(fromManufacturer(salonProducts))));

    this.selectedProductsGroups$ = combineLatest([
      this._store.pipe(select(fromImportSalonProducts.getSelectedProductGroups)),
      this._store.pipe(select(fromImportSalonProducts.getManufacturers)),
    ]).pipe(
      map(([groups, manufacturers]) =>
        Object.keys(groups)
          .filter((manufacturerId) => not(isEmpty(groups[manufacturerId])))
          .map((manufacturerId) => ({
            products: groups[manufacturerId],
            manufacturer: manufacturers.find((manufacturer) => manufacturer._id === manufacturerId),
          }))
      )
    );

    this.importStep$ = this._store.pipe(select(fromImportSalonProducts.getImportStep));

    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));

    this.breadcrumbsItems$ = this._store.pipe(select(fromImportSalonProducts.getSelectedCategories));

    this._store.dispatch(load());
  }

  public selectManufacturer(manufacturer: Manufacturer): void {
    this._store.dispatch(selectManufacturer({ manufacturer }));
  }

  public toggleProduct(productVM: ProductSelectionVM): void {
    this._store.dispatch(toggleProduct({ product: productVM.product }));
  }

  public selectProducts(productVMs: ProductSelectionVM[]): void {
    const products = productVMs.filter((productVM) => not(productVM.inactive)).map((productVM) => productVM.product);

    this._store.dispatch(selectProducts({ products }));
  }

  public deselectProducts(productVMs: ProductSelectionVM[]): void {
    const products = productVMs.map((productVM) => productVM.product);

    this._store.dispatch(deselectProducts({ products }));
  }

  public setSearchFilter(filter: string): void {
    this._store.dispatch(search({ filter }));
  }

  public setFlagFilters(filters: FilterKey[]): void {
    this._store.dispatch(setFilters({ filters }));
  }

  public pushCategory(category: ProductCategory): void {
    this._store.dispatch(pushCategory({ category }));
  }

  public popToCategory(categoryId: string): void {
    this._store.dispatch(popToCategory({ categoryId }));
  }

  public clearSelectedCategories(): void {
    this._store.dispatch(clearCategories());
  }

  public confirmImportedProducts(): void {
    this._store.dispatch(confirmImport());
  }

  public previousStep(): void {
    this._store.dispatch(previousStep());
  }

  public importAdditional(): void {
    this._store.dispatch(importAdditional());
  }

  public save(productGroups: { manufacturer: Manufacturer; products: Product[] }[]): void {
    if (!this.isSaving) {
      this._store.dispatch(importProducts({ productGroups }));
    }
  }

  public dismiss(
    selectedProductsGroups: {
      manufacturer: Manufacturer;
      products: Product[];
    }[]
  ): void {
    const atLeastOneProductSelected = selectedProductsGroups.some((group) => group.products.length > 0);

    if (atLeastOneProductSelected) {
      const ref = this._confirmDialogService.open({
        title: "Exit without saving",
        message: "Are you sure you wish to exit without importing selected products?",
        cancelText: "Cancel",
        actionText: "Confirm",
      });

      const onAction$ = ref.onAction$.pipe(tap(() => this._matDialogRef.close()));
      const onCancel$ = ref.onCancel$;

      merge(onAction$, onCancel$).pipe(take(1), takeUntil(this._onDestroy$)).subscribe();
    } else {
      this._matDialogRef.close();
    }
  }

  public removeAllProductsForManufacturer(manufacturer: Manufacturer): void {
    this._store.dispatch(removeAllProductsForManufacturer({ manufacturer }));
  }

  public ngOnDestroy(): void {
    this._onDestroy$.next();
  }
}
