import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";

import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Product, ProductCategory } from "@getvish/model";
import { LeadingTrailingWhitespaceValidator } from "app/kernel";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { isNil } from "ramda";
import { ProductCategoryNode, flattenProductCategoryNode } from "../../+product-categories/common";
import { ValidateHexColorCode } from "../util";

@Component({
  selector: "edit-product-component",
  templateUrl: "edit-product.component.html",
  styleUrls: ["edit-product.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProductComponent implements OnChanges {
  @Input() public product: Product;
  @Input() public categories: ProductCategory[];
  @Input() public manufacturerId: string;
  @Input() public newMetadata: { parentCategoryId?: string; manufacturerId?: string };

  @Output() public close: EventEmitter<void>;
  @Output() public save: EventEmitter<Product>;

  public _categories: ProductCategoryNode[];
  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.save = new EventEmitter(true);
    this.close = new EventEmitter(true);
  }

  public ngOnChanges(): void {
    this._createForm();
    this._categories = this._flattenProductCategoryNodes(this.categories);
  }
  public saveForm(data: Partial<Product>, original: Product): void {
    if (this.form.valid) {
      const updated = { ...original, ...data };

      this.save.emit(updated);
    }
  }

  private _createForm(): void {
    const product = this.product;

    if (product == null) {
      return;
    }

    const manufacturerId: string = pipe(
      option.fromNullable(product),
      option.chainNullableK((p) => p.manufacturerId),
      option.getOrElse(() => this.manufacturerId)
    );
    const newMetadata = pipe(
      option.fromNullable(this.newMetadata),
      option.getOrElse(() => ({ manufacturerId: undefined, parentCategoryId: undefined }))
    );

    const categoryId = pipe(
      option.fromNullable(newMetadata.parentCategoryId),
      option.getOrElse(() =>
        pipe(
          product,
          option.fromNullable,
          option.map((p) => p.categoryId),
          option.toUndefined
        )
      )
    );

    this.form = this._fb.group({
      name: [product.name?.trim(), [Validators.required, LeadingTrailingWhitespaceValidator]],
      hexColorCode: [product.hexColorCode, [Validators.required, ValidateHexColorCode]],
      order: [product.order],
      categoryId: [categoryId, Validators.required],
      manufacturerId: [manufacturerId],
    });
  }

  private _flattenProductCategoryNodes(categories: ProductCategoryNode[]): ProductCategoryNode[] {
    const rootCategoriesOnly = (category: ProductCategoryNode) => isNil(category.parentCategoryId);
    const flattenProductCategoryNodePartial = flattenProductCategoryNode(categories);

    return categories
      .filter(rootCategoriesOnly)
      .map((category) => flattenProductCategoryNodePartial(category))
      .reduce((acc, category) => acc.concat(category), []);
  }
}
