import { createSelector, createFeatureSelector } from "@ngrx/store";
import { DeleteSalonState } from "./delete-salon.reducer";

/*** SELECTORS ***/
export const getState = createFeatureSelector<DeleteSalonState>("deleteSalon");
export const getSalon = createSelector(getState, (state) => state.salon);
export const getDeleting = createSelector(getState, (state) => state.deleting);
export const getSuccess = createSelector(getState, (state) => state.success);
export const getError = createSelector(getState, (state) => state.error);
export const getLoading = createSelector(getState, (state) => state.loading);
