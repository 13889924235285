import { ServiceCategory } from "@getvish/model";
import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { Injectable } from "@angular/core";

@Injectable()
export class ServiceCategoryService extends EntityService<ServiceCategory> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "serviceCategories" });
  }
}
