<div class="summary-report">
  <div class="action-card purple">
    <div class="icon-container">
      <div class="icon">
        <fa-icon icon="chart-bar"></fa-icon>
      </div>
    </div>
    <div class="content">
      <span class="action-card-label">Product Charges</span>
      <span class="action-card-caption">{{ metrics.productOveruseDollars | currency: currency | default: "-" }}</span>
    </div>
  </div>
  <div class="action-card blue">
    <div class="icon-container">
      <div class="icon">
        <fa-icon icon="chart-bar"></fa-icon>
      </div>
    </div>
    <div class="content">
      <span class="action-card-label">Product Dispensed</span>
      <span class="action-card-caption">{{ metrics.wholesaleCostDollars | currency: currency }}</span>
    </div>
  </div>
  <div class="action-card pink">
    <div class="icon-container">
      <div class="icon">
        <fa-icon icon="balance-scale"></fa-icon>
      </div>
    </div>
    <div class="content">
      <span class="action-card-label">Formulas Reweighed</span>
      <span class="action-card-caption">{{ metrics.completedFormulaRatio | percent | default: "-" }}</span>
    </div>
  </div>
  <div
    *ngIf="enableComplianceTracking"
    class="action-card dark-turquoise">
    <div class="icon-container">
      <div class="icon">
        <fa-icon icon="mortar-pestle"></fa-icon>
      </div>
    </div>
    <div class="content">
      <span class="action-card-label">Service Compliance</span>
      <span class="action-card-caption">{{ getComplianceRatio(metrics) | percent | default: "-" }}</span>
    </div>
  </div>
  <div class="action-card green">
    <div class="icon-container">
      <div class="icon">
        <fa-icon icon="trash"></fa-icon>
      </div>
    </div>
    <div class="content">
      <span class="action-card-label">Percent Waste</span>
      <span class="action-card-caption">{{ metrics.wasteRatio | percent | default: "-" }}</span>
    </div>
  </div>
  <div class="action-card orange">
    <div class="icon-container">
      <div class="icon">
        <fa-icon icon="trash"></fa-icon>
      </div>
    </div>
    <div class="content">
      <span class="action-card-label">Estimated Waste</span>
      <span class="action-card-caption">{{ metrics.estimatedWasteDollars | currency: currency | default: "-" }}</span>
    </div>
  </div>
</div>
