<div class="container">
  <div class="content">
    <div class="icon-container">
      <fa-icon [icon]="icon"></fa-icon>
    </div>
    <span class="title"> {{ title }}</span>
    <span class="body">
      <ng-content></ng-content>
    </span>
  </div>
</div>
