import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { isNil } from "ramda";
import { Observable, from, of } from "rxjs";
import { catchError, map, mapTo, mergeMap, switchMap } from "rxjs/operators";
import * as XLSX from "xlsx";
import { HTTP_URL } from "../../../kernel/services/common";

@Injectable()
export class SalonProductSpreadsheetService {
  private _endpointUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(HTTP_URL) private _httpUrl: string
  ) {
    this._endpointUrl = `${this._httpUrl}/salonProduct/excel`;
  }

  public downloadSpreadsheet(salonId: string, authToken: string, manufacturerId: string): Observable<Blob> {
    const headers = { "X-Salon-Id": salonId, "X-Auth-Token": authToken };
    const params = new HttpParams().set("manufacturerId", manufacturerId);

    return this._http.get(this._endpointUrl, { headers, params, responseType: "blob" });
  }

  public uploadSpreadsheet(salonId: string, authToken: string, blobUrl: string, type: string): Observable<void> {
    const headers = { "X-Salon-Id": salonId, "X-Auth-Token": authToken };

    return this._http.get(blobUrl, { responseType: "blob" }).pipe(
      switchMap((blob) => {
        if (type?.includes("spreadsheet") || type?.includes("excel")) {
          return from(blob.arrayBuffer()).pipe(
            map((buf) => {
              const workbook = XLSX.read(buf, { type: "buffer" });
              const worksheet = Object.values(workbook.Sheets)?.[0];

              if (isNil(worksheet)) {
                throw new Error("Invalid spreadsheet uploaded.");
              }

              return new Blob([XLSX.utils.sheet_to_csv(worksheet)], { type: "plain/text" });
            })
          );
        }

        return of(blob);
      }),
      mergeMap((blob) => this._http.post(this._endpointUrl, blob, { headers })),
      catchError((e) => {
        console.log(e);
        throw new Error(e.message);
      }),
      mapTo(undefined)
    );
  }
}
