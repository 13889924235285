import { ServiceCategory, ServiceDescription } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { ProductAllowanceVM } from "app/kernel/models";
import { append, complement, equals, includes, uniqBy } from "ramda";
import * as serviceMenu from "./service-menu.actions";
import { ServiceComplianceAnomaly } from "../model";

export interface ServiceMenuState {
  loading: boolean;
  loadingOne: boolean;
  saving: boolean;
  selectedRecords: ServiceDescription[];
  selectedCategories: string[];
  uncategorizedOnly: boolean;
  showInactive: boolean;
  filter: string;
  sort: JsonObject;
  paging: PagingMetadata;
  productAllowances?: { [serviceId: string]: ProductAllowanceVM };
  preparingExport;
  serviceCategories: ServiceCategory[];
  serviceDescriptions: ServiceDescription[];
  tableFilter?: JsonObject;
  complianceAnomalies: ServiceComplianceAnomaly[];
}

const initialState: ServiceMenuState = {
  loading: false,
  loadingOne: false,
  saving: false,
  selectedRecords: [],
  selectedCategories: [],
  uncategorizedOnly: false,
  showInactive: false,
  filter: undefined,
  sort: undefined,
  paging: undefined,
  preparingExport: false,
  serviceCategories: [],
  serviceDescriptions: [],
  complianceAnomalies: undefined,
};

export function serviceMenuReducer(state: ServiceMenuState = initialState, action: serviceMenu.Actions): ServiceMenuState {
  switch (action.type) {
    case serviceMenu.Types.SEARCH: {
      const filter = action.payload.filter;

      return {
        ...state,
        filter,
      };
    }

    case serviceMenu.Types.UPDATE_TABLE_FILTER: {
      const tableFilter = action.payload;

      return {
        ...state,
        tableFilter,
      };
    }

    case serviceMenu.Types.LOAD_ALL: {
      const loading = true;
      const { sort, filter, tableFilter, uncategorizedOnly, showInactive } = action.payload;

      return {
        ...state,
        loading,
        sort,
        filter,
        tableFilter,
        uncategorizedOnly,
        showInactive,
      };
    }

    case serviceMenu.Types.LOAD_SERVICE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        serviceCategories: action.payload,
      };
    }

    case serviceMenu.Types.LOAD_SERVICE_DESCRIPTIONS_SUCCESS: {
      return {
        ...state,
        paging: action.paging,
        loading: false,
        serviceDescriptions: action.payload,
      };
    }

    case serviceMenu.Types.LOAD_SERVICE_DESCRIPTIONS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case serviceMenu.Types.LOAD_PRODUCT_ALLOWANCES_SUCCESS: {
      return {
        ...state,
        productAllowances: action.payload,
      };
    }

    case serviceMenu.Types.CLEAR_SERVICE_COMPLIANCE_ANOMALIES: {
      return {
        ...state,
        complianceAnomalies: undefined,
      };
    }

    case serviceMenu.Types.LOAD_SERVICE_COMPLIANCE_ANOMALIES_SUCCESS: {
      return {
        ...state,
        complianceAnomalies: action.payload,
      };
    }

    case serviceMenu.Types.TOGGLE_SELECTED: {
      const serviceDescription = action.payload;
      const selectedRecords = state.selectedRecords;

      const updatedSelectedRecords = selectedRecords.some((s) => s._id === serviceDescription._id)
        ? selectedRecords.filter((s) => s._id !== serviceDescription._id)
        : [...selectedRecords, serviceDescription];

      return {
        ...state,
        selectedRecords: updatedSelectedRecords,
      };
    }

    case serviceMenu.Types.TOGGLE_SELECTED_CATEGORY: {
      const uniqById = (services: ServiceDescription[]) => uniqBy((service: ServiceDescription) => service._id)(services);

      const { categoryId, services } = action.payload;
      const serviceIds = services.reduce((acc, cur) => acc.add(cur._id), new Set());

      const isCategorySelected = includes(categoryId, state.selectedCategories);

      const selectedCategories = isCategorySelected
        ? state.selectedCategories.filter(complement(equals(categoryId)))
        : append(categoryId, state.selectedCategories);

      // either insert of remove all of the service descriptions in the given category
      // depending on whether the category is selected or not:
      const selectedRecords = isCategorySelected
        ? state.selectedRecords.filter((item) => !serviceIds.has(item._id)) // if the category is selected, we'll remove all of the services from the selected services
        : uniqById([...state.selectedRecords, ...services]); // otherwise the category wasn't selected, we'll add all of the given services

      return {
        ...state,
        selectedRecords,
        selectedCategories,
      };
    }

    case serviceMenu.Types.DESELECT_ALL: {
      return {
        ...state,
        selectedRecords: [],
        selectedCategories: [],
      };
    }
    case serviceMenu.Types.CLEAR_SELECTED_SERVICES: {
      return {
        ...state,
        selectedRecords: [],
        selectedCategories: [],
      };
    }

    case serviceMenu.Types.TOGGLE_UNCATEGORIZED_ONLY: {
      const uncategorizedOnly = !state.uncategorizedOnly;

      return {
        ...state,
        uncategorizedOnly,
      };
    }

    case serviceMenu.Types.TOGGLE_SHOW_INACTIVE: {
      const showInactive = !state.showInactive;

      return {
        ...state,
        showInactive,
      };
    }

    case serviceMenu.Types.DOWNLOAD_SERVICE_MENU_REPORT: {
      return {
        ...state,
        preparingExport: true,
      };
    }

    case serviceMenu.Types.DOWNLOAD_SERVICE_MENU_REPORT_SUCCESS: {
      return {
        ...state,
        preparingExport: false,
      };
    }

    case serviceMenu.Types.DOWNLOAD_SERVICE_MENU_REPORT_FAIL: {
      return {
        ...state,
        preparingExport: false,
      };
    }

    case serviceMenu.Types.TOGGLE_PARTS_AND_LABOR: {
      return {
        ...state,
        saving: true,
      };
    }

    case serviceMenu.Types.TOGGLE_PARTS_AND_LABOR_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case serviceMenu.Types.TOGGLE_PARTS_AND_LABOR_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case serviceMenu.Types.UPDATE_MANY_TOGGLE_IS_ACTIVE: {
      return {
        ...state,
        saving: true,
      };
    }

    case serviceMenu.Types.UPDATE_MANY_TOGGLE_IS_ACTIVE_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case serviceMenu.Types.UPDATE_MANY_TOGGLE_IS_ACTIVE_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    default: {
      return state;
    }
  }
}
