import { Salon } from "@getvish/model";

import * as migrateSalon from "./migrate-salon.actions";

export interface MigrateSalonState {
  salon: Salon;
  migrating: boolean;
  success: boolean;
  error: string;
  loading: boolean;
}

const initialState: MigrateSalonState = {
  salon: undefined,
  migrating: false,
  success: undefined,
  error: undefined,
  loading: undefined,
};

export function migrateSalonReducer(state: MigrateSalonState = initialState, action: migrateSalon.Actions): MigrateSalonState {
  switch (action.type) {
    case migrateSalon.Types.LOAD: {
      return {
        ...initialState,
        loading: true,
      };
    }

    case migrateSalon.Types.LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        salon: action.payload.salon,
      };
    }

    case migrateSalon.Types.LOAD_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.error.message,
      };
    }

    case migrateSalon.Types.MIGRATE: {
      return {
        ...state,
        migrating: true,
      };
    }

    case migrateSalon.Types.FAIL: {
      return {
        ...state,
        error: action.payload.error.message,
      };
    }

    case migrateSalon.Types.SUCCESS: {
      return {
        ...state,
        migrating: false,
        success: true,
      };
    }

    case migrateSalon.Types.DONE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
