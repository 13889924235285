import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";

import * as actions from "../store/reset-password.actions";
import * as fromPasswordReset from "../store/reset-password.selectors";

@Component({
  selector: "forgot-password-container",
  templateUrl: "./forgot-password.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordContainer {
  public error$: Observable<string>;

  constructor(private _store: Store<AppState>) {
    this.error$ = this._store.select(fromPasswordReset.getError);
  }

  public submit(value: { email: string }): void {
    this._store.dispatch(new actions.SendResetPasswordEmail(value));
  }
}
