import { Employee } from "@getvish/model";
import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { Action } from "@ngrx/store";
import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { createUserFilterCriteria, isDefined } from "../../kernel/util";

export enum Types {
  UPDATE_FILTER = "[Employee] Update Filter",
  UPDATE_TABLE_FILTER = "[Employee] Update Table Filter",
  UPDATE_SORT = "[Employee] Update Sort",
  LOAD_ALL = "[Employee] Load All",
  LOAD_ALL_SUCCESS = "[Employee] Load All Success",
  LOAD_ALL_FAIL = "[Employee] Load All Fail",
  RELOAD = "[Employee] Reload",
  ADD = "[Employee] Add",
  ADD_SUCCESS = "[Employee] Add Success",
  ADD_FAIL = "[Employee] Add Fail",
  UPDATE = "[Employee] Update",
  UPDATE_SUCCESS = "[Employee] Update Success",
  UPDATE_MULTIPLE_SUCCESS = "[Employee] Update Multiple Success",
  UPDATE_FAIL = "[Employee] Update Fail",
  SET_ROLES = "[Employee] Set Roles",
  SET_ROLES_SUCCESS = "[Employee] Set Roles Success",
  SET_ROLES_FAIL = "[Employee] Set Roles Fail",
  NAVIGATE_EDIT_EMPLOYEE = "[Employee] Navigate to Edit Employee",
  LOAD_EDIT_EMPLOYEE_DATA = "[Employee] Load Edit Employee Data",
  LOAD_EDIT_EMPLOYEE_DATA_SUCCESS = "[Employee] Load Edit Employee Data Success",
  LOAD_EDIT_EMPLOYEE_DATA_FAIL = "[Employee] Load Edit Employee Data Fail",
  NAVIGATE_TO_PAGE = "[Employee] Navigate to Page",
  TOGGLE_SHOW_DELETED = "[Employee] Toggle Show Deleted",
  RESTORE_EMPLOYEE = "[Employee] Restore Employee",
  RESTORE_EMPLOYEE_SUCCESS = "[Employee] Restore Employee Success",
  RESTORE_EMPLOYEE_FAIL = "[Employee] Restore Employee Fail",
  REMOVE_EMPLOYEE_SUCCESS = "[Employee] Remove Employee Success",
  TOGGLE_SELECTED = "[Employee] Toggle Selected",
  CLEAR_SELECTED = "[Employee] Clear Selected",
}

export class UpdateFilter implements Action {
  public readonly type = Types.UPDATE_FILTER;

  constructor(public payload: string) {}
}

export class UpdateTableFilter implements Action {
  public readonly type = Types.UPDATE_TABLE_FILTER;

  constructor(public payload: JsonObject) {}
}
export class UpdateSort implements Action {
  public readonly type = Types.UPDATE_SORT;

  constructor(public payload: JsonObject) {}
}
export class LoadAll implements Action {
  public readonly type = Types.LOAD_ALL;
  public payload: {
    filter: string;
    tableFilter?: JsonObject;
    criteria?: JsonObject;
    sort?: JsonObject;
    page?: number;
    limit?: number;
    showDeleted?: boolean;
  };

  constructor(payload: {
    filter?: string;
    tableFilter?: JsonObject;
    sort?: JsonObject;
    page?: number;
    limit?: number;
    showDeleted?: boolean;
  }) {
    const criteria = createUserFilterCriteria(payload.filter, payload.tableFilter);

    const sort = pipe(
      option.fromNullable(payload.sort),
      option.fold(
        () => ({ firstName: 1, lastName: 1 }),
        (sort) => sort
      )
    );

    this.payload = {
      filter: payload.filter,
      tableFilter: payload.tableFilter,
      criteria,
      sort,
      limit: payload.limit,
      page: payload.page,
      showDeleted: payload.showDeleted,
    };
  }
}

export class LoadAllSuccess implements Action {
  public readonly type = Types.LOAD_ALL_SUCCESS;

  constructor(
    public payload: Employee[],
    public paging: PagingMetadata
  ) {}
}

export class LoadAllFail implements Action {
  public readonly type = Types.LOAD_ALL_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class Reload implements Action {
  public readonly type = Types.RELOAD;
}

export class Add implements Action {
  public readonly type = Types.ADD;

  constructor(public payload: { employee: Employee; roles: string[] }) {}
}

export class AddSuccess implements Action {
  public readonly type = Types.ADD_SUCCESS;

  constructor(public payload: Employee) {}
}

export class AddFail implements Action {
  public readonly type = Types.ADD_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class Update implements Action {
  public readonly type = Types.UPDATE;

  constructor(public payload: { employee: Employee; roles: string[] }) {}
}

export class UpdateSuccess implements Action {
  public readonly type = Types.UPDATE_SUCCESS;

  constructor(public payload: Employee) {}
}

export class UpdateMultipleSuccess implements Action {
  public readonly type = Types.UPDATE_MULTIPLE_SUCCESS;

  constructor(public payload: Employee[]) {}
}

export class UpdateFail implements Action {
  public readonly type = Types.UPDATE_FAIL;

  constructor(public payload: { errors: any }) {}
}

export class SetRoles implements Action {
  public readonly type = Types.SET_ROLES;

  constructor(public payload: { employeeId: string; roles: string[] }) {}
}

export class SetRolesSuccess implements Action {
  public readonly type = Types.SET_ROLES_SUCCESS;

  constructor(public payload: Employee) {}
}

export class SetRolesFail implements Action {
  public readonly type = Types.SET_ROLES_FAIL;

  constructor(public payload: { reason: any }) {}
}

export class NavigateEditEmployee implements Action {
  public readonly type = Types.NAVIGATE_EDIT_EMPLOYEE;

  constructor(public payload?: { employeeId: string }) {}
}

export const addOrUpdate = (payload: { employee: Employee; roles: string[] }) =>
  isDefined(payload.employee._id) ? new Update(payload) : new Add(payload);

export class LoadEditEmployeeData implements Action {
  public readonly type = Types.LOAD_EDIT_EMPLOYEE_DATA;
}

export class LoadEditEmployeeDataSuccess implements Action {
  public readonly type = Types.LOAD_EDIT_EMPLOYEE_DATA_SUCCESS;
}

export class LoadEditEmployeeDataFail implements Action {
  public readonly type = Types.LOAD_EDIT_EMPLOYEE_DATA_FAIL;
}
export class NavigateToPage implements Action {
  public readonly type = Types.NAVIGATE_TO_PAGE;

  constructor(public payload: { page: number }) {}
}

export class ToggleShowDeleted implements Action {
  public readonly type = Types.TOGGLE_SHOW_DELETED;
}

export class RestoreEmployee implements Action {
  public readonly type = Types.RESTORE_EMPLOYEE;

  constructor(public payload: { employee: Employee }) {}
}

export class RestoreEmployeeSuccess implements Action {
  public readonly type = Types.RESTORE_EMPLOYEE_SUCCESS;

  constructor(public payload: { employee: Employee }) {}
}

export class RestoreEmployeeFail implements Action {
  public readonly type = Types.RESTORE_EMPLOYEE_FAIL;

  constructor(public payload: { error: any }) {}
}

export class RemoveEmployeeSuccess implements Action {
  public readonly type = Types.REMOVE_EMPLOYEE_SUCCESS;

  constructor(public employeeId: string) {}
}

export class ToggleSelected implements Action {
  public readonly type = Types.TOGGLE_SELECTED;

  constructor(public payload: Employee) {}
}

export class ClearSelected implements Action {
  public readonly type = Types.CLEAR_SELECTED;
}

export type Actions =
  | UpdateFilter
  | UpdateTableFilter
  | UpdateSort
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | Reload
  | Add
  | AddSuccess
  | Update
  | UpdateSuccess
  | UpdateMultipleSuccess
  | SetRoles
  | SetRolesSuccess
  | SetRolesFail
  | NavigateEditEmployee
  | LoadEditEmployeeData
  | LoadEditEmployeeDataSuccess
  | LoadEditEmployeeDataFail
  | NavigateToPage
  | ToggleShowDeleted
  | RestoreEmployee
  | RestoreEmployeeSuccess
  | RestoreEmployeeFail
  | RemoveEmployeeSuccess
  | ToggleSelected
  | ClearSelected;
