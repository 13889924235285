import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CustomerState } from "./customer.reducer";

import { fromNullable } from "fp-ts/Option";

/*** SELECTORS ***/
export const getCustomerState = createFeatureSelector<CustomerState>("customers");

export const getLoading = createSelector(getCustomerState, (state) => state.loading);
export const getDeleting = createSelector(getCustomerState, (state) => state.deleting);
export const getPaging = createSelector(getCustomerState, (state) => fromNullable(state.paging));
export const getCriteria = createSelector(getCustomerState, (state) => state.criteria);
export const getSort = createSelector(getCustomerState, (state) => state.sort);
export const getImporting = createSelector(getCustomerState, (state) => state.importing);
export const getNumRecordsImported = createSelector(getCustomerState, (state) => state.numRecordsImported);
export const getSelected = createSelector(getCustomerState, (state) => state.selectedRecords);
export const getTableFilter = createSelector(getCustomerState, (state) => state.tableFilter);

export const getCustomers = createSelector(getCustomerState, (state) => state.records);

export const getCustomer = (id: string) => createSelector(getCustomers, (customers) => customers.find((_customer) => _customer._id === id));

export const getFilter = createSelector(getCustomerState, (state) => state.filter);
