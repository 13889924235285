import { Injectable } from "@angular/core";
import { Salon } from "@getvish/model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { option } from "fp-ts";
import { some } from "fp-ts/Option";
import { isNil } from "ramda";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { isDefined } from "../../kernel/util";
import { SalonService } from "../services/";

import * as routerActions from "../../kernel/store/actions/router.actions";
import * as editSalonActions from "./edit-salon.actions";

@Injectable()
export class EditSalonEffects {
  public navigateEditSalon$ = createEffect(() =>
    this._actions$.pipe(
      ofType<editSalonActions.NavigateEditSalon>(editSalonActions.Types.NAVIGATE_EDIT_SALON),
      map((action) => (isDefined(action.payload) ? `edit/${action.payload.salon._id}` : "new")),
      map((fragment) => routerActions.go({ path: ["/salons", { outlets: { panel: fragment } }], extras: { queryParamsHandling: "merge" } }))
    )
  );

  public loadEditSalonData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<editSalonActions.Load>(editSalonActions.Types.LOAD),
      map((action) => action.payload.salonId),
      switchMap((id) => {
        const fetchSalon$ = isNil(id) ? of(some(new Salon())) : this._salonService.findById(id);

        return fetchSalon$.pipe(
          map(
            option.fold<Salon, Action>(
              () =>
                new editSalonActions.LoadFail({
                  error: new Error("Salon Not Found"),
                }),
              (salon) => new editSalonActions.LoadSuccess({ salon })
            )
          )
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _salonService: SalonService
  ) {}
}
