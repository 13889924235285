import * as auth from "../../../+auth/store/auth.actions";

import { ActionReducer } from "@ngrx/store";
import { AppState } from "../index";

// Clearing the state but keeping common so that slug and entry url are kept for re-login
// we also return emptyState so that typescript is okay not returning the other parts of AppState e.g. entities, ui
export function clearStateOnLogout(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state, action): AppState {
    if (action.type === auth.Types.LOGOUT) {
      const emptyState = undefined;
      const common = state.common;
      const clearedState = {
        ...emptyState,
        common,
      };
      return reducer(clearedState, action);
    }
    return reducer(state, action);
  };
}
