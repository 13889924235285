import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ForgotPasswordContainer, SetPasswordContainer } from "./containers";
import { ConfirmCodeContainer } from "./containers/confirm-code.container";
import { ResetPasswordRootContainer } from "./containers/reset-password-root.container";
import { SetPasswordSuccessContainer } from "./containers/set-password-success.container";

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: "", redirectTo: "reset", pathMatch: "full" },
      {
        path: "reset",
        component: ResetPasswordRootContainer,
        children: [
          { path: "", component: ForgotPasswordContainer },
          { path: "confirm-code", component: ConfirmCodeContainer },
          { path: "set-password", component: SetPasswordContainer },
          { path: "success", component: SetPasswordSuccessContainer },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ResetPasswordRoutingModule {}
