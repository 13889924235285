<table *ngIf="_statistics | notEmpty; else emptyState">
  <thead>
    <tr>
      <th></th>
      <th>Name</th>
      <th>Dispensed</th>
      <th>Waste</th>
      <th>Wholesale Cost</th>
      <th>Retail Price</th>
      <th># Services</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let statistic of _statistics"
      class="product-row">
      <td class="icon-container">
        <colored-icon [title]="statistic.entityName"></colored-icon>
      </td>
      <td>
        <span class="row-title">{{ statistic.entityName }}</span>
        <span class="row-subtitle">
          <!-- <product-category-with-root-tooltip
            *ngIf="statistic.product"
            [product]="statistic.product"
            [rootCategoryMap]="rootCategoryMap">
          </product-category-with-root-tooltip> -->
        </span>
      </td>
      <td>{{ statistic.metrics.totalAmountGrams | localMeasurementUnit: measurementUnit }}</td>
      <td>{{ statistic.metrics.wasteAmountGrams | localMeasurementUnit: measurementUnit }}</td>
      <td>{{ statistic.metrics.wholesaleCostDollars | currency: currency }}</td>
      <td>{{ statistic.metrics.retailCostDollars | currency: currency }}</td>
      <td>{{ statistic.metrics.numServicesPerformed }}</td>
    </tr>
  </tbody>
</table>
<ng-template #emptyState>
  <empty-state-message
    title="No data here"
    icon="calendar-day">
    <p>We couldn't find any data for the selected range, adjust the dates to view data</p>
  </empty-state-message>
</ng-template>
