import { Component, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { Observable } from "rxjs";

import { getPanelOpen } from "../../../store/reducers/ui.reducer";
import { AppState } from "../../../store";

import { Store } from "@ngrx/store";

import * as ui from "../../../store/actions/ui.actions";

@Component({
  selector: "slideout-panel",
  templateUrl: "./slideout-panel.component.html",
  styleUrls: ["./slideout-panel.component.less"],
  animations: [
    trigger("slideOutFromRight", [
      transition("void => *", [
        style({ transform: "translate3d(450px, 0, 0)" }),
        animate("0.35s linear", style({ transform: "translate3d(0, 0, 0)" })),
      ]),
      transition("* => void", [animate("0.35s linear", style({ transform: "translate3d(450px, 0, 0)" }))]),
    ]),
  ],
})
export class SlideoutPanelComponent implements AfterViewInit, OnDestroy {
  @Input() public closeable: boolean = true;
  @Output() public close: EventEmitter<void>;

  public panelOpen$: Observable<boolean>;

  constructor(private _store: Store<AppState>) {
    this.close = new EventEmitter(true);
    this.panelOpen$ = this._store.select(getPanelOpen);
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this._store.dispatch(new ui.OpenPanel()));
  }

  public ngOnDestroy(): void {
    this._store.dispatch(new ui.ClosePanel());
  }
}
