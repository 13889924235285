import { createSelector, createFeatureSelector } from "@ngrx/store";
import { hasCategorySearchTerm, hasSearchTerm } from "app/+product/common/utils";
import { featureKey, ProductSelectionState } from "./product-selection.reducer";

import { pipe } from "fp-ts/lib/function";
import { option } from "fp-ts";

/*** SELECTORS ***/
export const getState = createFeatureSelector<ProductSelectionState>(featureKey);

export const getProductSelectionMode = createSelector(getState, (state) => state.psMode);

export const getProductSelectionType = createSelector(getState, (state) => state.psType);

export const getSearchFilter = createSelector(getState, (state) => state.searchFilter);

export const getLoading = createSelector(getState, (state) => state.loading);

export const getSaving = createSelector(getState, (state) => state.saving);

export const getImportStep = createSelector(getState, (state) => state.importStep);

export const getSelectedManufacturer = createSelector(getState, (state) => state.selectedManufacturer);

export const getSelectedCategories = createSelector(getState, (state) => state.selectedProductCategories);

export const getSelectedProducts = createSelector(getState, (state) => state.selectedProducts);

export const getManufacturers = createSelector(getState, (state) => state.manufacturers);

export const getFilteredManufacturers = createSelector(getManufacturers, getSearchFilter, (manufacturers, searchFilter) =>
  manufacturers.filter(hasSearchTerm(searchFilter))
);

export const getSalonProducts = createSelector(getState, (state) => state.salonProducts);

export const getAvailableCategories = createSelector(getState, (state) => state.availableCategories);

export const getAvailableProducts = createSelector(getState, (state) => state.availableProducts);

export const getFilteredProducts = createSelector(
  getAvailableProducts,
  getSalonProducts,
  getAvailableCategories,
  getSearchFilter,
  (products, salonProducts, categories, searchFilter) =>
    (products ?? salonProducts).filter((p) => hasSearchTerm(searchFilter)(p) || hasCategorySearchTerm(searchFilter, categories)(p))
);

export const getProductsForSelectedManufacturer = createSelector(getSelectedManufacturer, getFilteredProducts, (manufacturer, products) =>
  pipe(
    option.fromNullable(manufacturer),
    option.map((manufacturer) => products.filter((salonProduct) => salonProduct.manufacturerId === manufacturer._id)),
    option.getOrElse(() => [])
  )
);
