import { Pipe, PipeTransform } from "@angular/core";
import { formatDistanceToNow } from "date-fns";
import { isNil } from "ramda";

@Pipe({ name: "formatDistance", pure: true })
export class FormatDistancePipe implements PipeTransform {
  public transform(value: Date): string {
    return isNil(value) ? value : formatDistanceToNow(value, { addSuffix: true });
  }
}
