import { Component, Input } from "@angular/core";

@Component({
  selector: "alert-component",
  template: ` <div class="alert alert-{{ type }}"><ng-content></ng-content></div> `,
  styleUrls: ["alert.component.less"],
})
export class AlertComponent {
  @Input() public type: string;
}
