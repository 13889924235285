import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RootAuthModule } from "./+auth";
import { AppRoutingModule } from "./app-routing.module";
import { KernelModule } from "./kernel";
import { SharedModule } from "./kernel/shared";

/*
 * Platform and Environment providers/directives/pipes
 */
// App is our top level component
import { AppComponent } from "./app.component";

import { NAV_LINKS, NavLinks } from "./kernel/util/nav-links";

import { registerLocaleData } from "@angular/common";
import localeArAE from "@angular/common/locales/ar-AE";
import localeArQA from "@angular/common/locales/ar-QA";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import localeEn150 from "@angular/common/locales/en-150";
import localeEnAu from "@angular/common/locales/en-AU";
import localeEnGB from "@angular/common/locales/en-GB";
import localeEnNz from "@angular/common/locales/en-NZ";
import localeEnZa from "@angular/common/locales/en-ZA";
import localeEs from "@angular/common/locales/es";
import localeEsMx from "@angular/common/locales/es-MX";
import localeFr from "@angular/common/locales/fr";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import {
  faAddressBook,
  faAngleLeft,
  faAngleRight,
  faBagsShopping,
  faBars,
  faBuilding,
  faCashRegister,
  faChartArea,
  faChartBar,
  faCircle,
  faCogs,
  faIndustry,
  faList,
  faPlug,
  faQuestionCircle,
  faSearch,
  faShelves,
  faShoppingBasket,
  faTablet,
  faTags,
  faTimes,
  faTimesCircle,
  faUserCircle,
  faUsers,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { environment } from "src/environments/environment";
import { ResetPasswordModule } from "./+reset-password";
import { SalonConfigSharedModule } from "./+salon-config";
import { DEFAULT_VALUES_PROVIDERS } from "./kernel/defaults";
import { WildcardRoutingModule } from "./wildcard-routing.module";
import { ReportingModule } from "./+reporting";
import { ProgressIndicatorModule } from "./+components";
import { BaseComponentsModule } from "./kernel/+base/base-components.module";
import { FrontDeskModule } from "./+front-desk";
import { ServiceMenuModule } from "./+service-menu";

registerLocaleData(localeEnNz);
registerLocaleData(localeEnAu);
registerLocaleData(localeEnZa);
registerLocaleData(localeEn150);
registerLocaleData(localeFr);
registerLocaleData(localeArQA);
registerLocaleData(localeArAE);
registerLocaleData(localeEn);
registerLocaleData(localeEnGB);
registerLocaleData(localeEsMx);
registerLocaleData(localeEs);
registerLocaleData(localeDe);

const moduleImports = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  HttpClientModule,
  SharedModule,
  KernelModule.forRoot(),
  SalonConfigSharedModule,
  AppRoutingModule,
  RootAuthModule.forRoot(),
  ResetPasswordModule.forRoot(),
  WildcardRoutingModule,
  ProgressIndicatorModule,
  BaseComponentsModule,
  FrontDeskModule.forRoot(),
  ServiceMenuModule.forRoot(),
];

if (environment?.version != null && environment?.LOG_ROCKET?.AppId != null) {
  moduleImports.push(ReportingModule.forRoot());
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  /**
   * Import Angular's modules.
   */
  imports: moduleImports,

  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    DEFAULT_VALUES_PROVIDERS,
    { provide: NAV_LINKS, useValue: NavLinks },
    { provide: LOCALE_ID, useValue: navigator.language },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline", floatLabel: "auto" } },
  ],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faChartArea,
      faChartBar,
      faUsers,
      faCogs,
      faShoppingBasket,
      faTablet,
      faPlug,
      faIndustry,
      faShelves,
      faTags,
      faBuilding,
      faAddressBook,
      faCircle,
      faTimesCircle,
      faSearch,
      faAngleRight,
      faAngleLeft,
      faBars,
      faQuestionCircle,
      faUserCircle,
      faList,
      faTimes,
      faCashRegister,
      faBagsShopping,
      faExclamationCircle
    );
  }
}
