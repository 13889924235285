<div *ngIf="error">
  <div class="page-content">
    <alert-component type="danger">
      <h4>An Error Has Occured</h4>

      <p>{{ error }}</p>
    </alert-component>
  </div>
</div>

<div *ngIf="!error">
  <div *ngIf="!success">
    <div class="page-content">
      <h4>
        Migrate salon <span class="highlight">{{ salon.name }}</span> to v2?
      </h4>
      <p>This will trigger migration of this salon from v1 to v2.</p>
      <p>This action <b>cannot be undone.</b></p>
    </div>
    <form
      class="migrate-form"
      [formGroup]="form"
      (submit)="migrateSalon(salon)"
      novalidate>
      <h5>
        Type <b>'{{ salon.slug }}'</b> to confirm
      </h5>
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="slug" />
        <mat-error *ngIf="form.get('slug').hasError('required')">Required</mat-error>
        <mat-error *ngIf="form.get('slug').hasError('equals')">Please enter the <i>exact</i> slug of the salon</mat-error>
      </mat-form-field>
    </form>

    <div class="controls">
      <button
        (click)="cancel.emit()"
        [disabled]="migrating"
        class="button outline">
        <span>Cancel</span>
      </button>
      <button
        (click)="migrateSalon(salon)"
        [disabled]="form.invalid"
        class="button primary">
        <span *ngIf="!migrating; else loadingIndicator">Trigger Migration</span>
        <ng-template #loadingIndicator>
          <fa-icon
            *ngIf="migrating"
            [icon]="['fas', 'circle-notch']"
            [spin]="true"></fa-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <div
    *ngIf="success"
    class="page-content">
    <div class="page-content">
      <alert-component type="success">
        <h4>Salon Migration Triggered</h4>

        <p>
          Migration of {{ salon.name }} from v1 to v2 has been triggered, and you will receive an email once the migration is complete. You
          can close this panel now.
        </p>
      </alert-component>
    </div>

    <div class="controls">
      <button
        (click)="done.emit()"
        class="button primary">
        <span>Done</span>
      </button>
    </div>
  </div>
</div>
