import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { map, switchMap, mapTo, withLatestFrom, tap } from "rxjs/operators";
import { either } from "fp-ts";
import { go } from "../../kernel/store/actions/router.actions";
import { PasswordResetToken, ResetPasswordService } from "../services";
import { HttpError } from "@getvish/stockpile";
import { AppState, WindowService } from "app/kernel";
import { AppMode } from "app/kernel/models";

import * as fromPasswordReset from "../store/reset-password.selectors";
import * as actions from "./reset-password.actions";
import * as authActions from "../../+auth/store/auth.actions";
import * as RouterActions from "../../kernel/store/actions/router.actions";

@Injectable()
export class ResetPasswordEffects {
  public navigate$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Navigate>(actions.Types.NAVIGATE),
      map(() => new RouterActions.Go({ path: ["/forgot-password"] }))
    )
  );

  public sendResetPasswordEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.SendResetPasswordEmail>(actions.Types.SEND_RESET_PASSWORD_EMAIL),
      map((action) => action.payload),
      switchMap((email) =>
        this._passwordResetService.sendResetPasswordEmail(email).pipe(
          map(
            either.fold<HttpError, PasswordResetToken, Action>(
              (fail) => new actions.SendResetPasswordEmailFail({ error: fail.payload.toString() }),
              (token) => new actions.SendResetPasswordEmailSuccess({ token })
            )
          )
        )
      )
    )
  );

  public navigateToPasswordResetEmailConfirmation$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.SendResetPasswordEmailSuccess>(actions.Types.SEND_RESET_PASSWORD_EMAIL_SUCCESS),
      mapTo(go({ path: ["reset/confirm-code"] }))
    )
  );

  public confirmCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.ConfirmCode>(actions.Types.CONFIRM_CODE),
      map((action) => action.payload),
      withLatestFrom(this._store.select(fromPasswordReset.getToken)),
      switchMap(([code, token]) =>
        this._passwordResetService.confirmCode(token, code).pipe(
          map(
            either.fold<HttpError, void, Action>(
              (fail) => new actions.ConfirmCodeFail({ error: fail.payload.toString() }),
              () => new actions.ConfirmCodeSuccess()
            )
          )
        )
      )
    )
  );

  public confirmCodeSuccess$ = createEffect(() =>
    this._actions$.pipe(ofType<actions.ConfirmCodeSuccess>(actions.Types.CONFIRM_CODE_SUCCESS), mapTo(go({ path: ["reset/set-password"] })))
  );

  public setPassword = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.SetPassword>(actions.Types.SET_PASSWORD),
      map((action) => action.payload),
      withLatestFrom(this._store.select(fromPasswordReset.getToken)),
      switchMap(([payload, token]) =>
        this._passwordResetService.resetPassword({ tokenId: token.id, ...payload }).pipe(
          map(
            either.fold<HttpError, any, Action>(
              (fail) => new actions.SetPasswordFail({ error: fail.payload.toString() }),
              () => new actions.SetPasswordSuccess()
            )
          )
        )
      )
    )
  );

  public setPasswordSuccess = createEffect(() =>
    this._actions$.pipe(ofType<actions.SetPasswordSuccess>(actions.Types.SET_PASSWORD_SUCCESS), mapTo(go({ path: ["reset/success"] })))
  );

  public done = createEffect(() =>
    this._actions$.pipe(
      ofType<actions.Done>(actions.Types.DONE),
      map(() =>
        // because the password reset module can operate in two different "modes"
        // i.e. it can operate within the context of a specific tenant
        // or it can operate in a password reset-only context where no login module has been loaded
        // in the case that we're in the "password reset-only" context, we'll want to break out of Angular's routing
        // and trigger a page reset/use the window object to navigate
        // otherwise we can just use Angular's router
        this._appMode.value === "FORGOT_PASSWORD" ? new actions.NavigateToLoginNewWindow() : new authActions.NavigateToLogin()
      )
    )
  );

  public navigateToLoginNewWindow = createEffect(
    () =>
      this._actions$.pipe(
        ofType<actions.NavigateToLoginNewWindow>(actions.Types.NAVIGATE_TO_LOGIN_NEW_WINDOW),
        tap(() => this._window.changeLocation("/login"))
      ),
    { dispatch: false }
  );

  constructor(
    private _appMode: AppMode,
    private _store: Store<AppState>,
    private _passwordResetService: ResetPasswordService,
    private _window: WindowService,
    private _actions$: Actions
  ) {}
}
