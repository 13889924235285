import * as websocket from "../actions/websocket.actions";
import { WebsocketConnectionStatus } from "@getvish/stockpile";

export interface WebsocketState {
  websocketStatus: WebsocketConnectionStatus;
}

const initialState: WebsocketState = {
  websocketStatus: undefined,
};

export function websocketReducer(state: WebsocketState = initialState, action: websocket.Actions): WebsocketState {
  switch (action.type) {
    case websocket.Types.CONNECTED: {
      return {
        ...state,
        websocketStatus: WebsocketConnectionStatus.CONNECTED,
      };
    }
    case websocket.Types.CONNECTING: {
      return {
        ...state,
        websocketStatus: WebsocketConnectionStatus.CONNECTING,
      };
    }
    case websocket.Types.RETRYING: {
      return {
        ...state,
        websocketStatus: WebsocketConnectionStatus.RETRYING,
      };
    }
    case websocket.Types.DISCONNECTED: {
      return {
        ...state,
        websocketStatus: WebsocketConnectionStatus.DISCONNECTED,
      };
    }
    default: {
      return state;
    }
  }
}
