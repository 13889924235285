<page
  pageTitle="Salons"
  [progressText]="(loading$ | async) ? 'Loading...' : undefined"
  class="full-height-table">
  <header-controls page-header>
    <div class="search-input-control">
      <search-input
        [searchFilter]="filter$ | async"
        (filter)="updateFilter($event)"
        placeholder="Search Salons..."></search-input>
    </div>
    <button
      class="button outline"
      (click)="newSalon()">
      New Salon
    </button>
    <mat-menu #moreOptionsMenu="matMenu">
      <button
        mat-menu-item
        (click)="exportUsageReport()">
        Export Salon Usage Report
      </button>
    </mat-menu>
    <button
      [matMenuTriggerFor]="moreOptionsMenu"
      class="button secondary transparent">
      <fa-icon icon="ellipsis-v"></fa-icon>
    </button>
  </header-controls>
  <card page-body>
    <data-table
      [paging]="paging$ | async"
      card-body>
      <columns>
        <column id="icon"></column>
        <column
          id="name"
          title="Name">
          <column-sort
            [direction]="(sort$ | async)?.name"
            (change)="updateSort({ name: $event.direction, slug: $event.direction })">
          </column-sort>
        </column>
        <column
          id="apiVersion"
          title="API Version"></column>
        <column
          id="salonId"
          title="Salon Id">
          <column-sort
            [direction]="(sort$ | async)?._id"
            (change)="updateSort({ _id: $event.direction })">
          </column-sort>
        </column>
        <column id="flags"></column>
        <column id="menu"></column>
      </columns>
      <body>
        <row
          *ngFor="let salon of salonVms$ | async"
          [id]="salon._id">
          <cell columnId="icon">
            <ng-template>
              <colored-icon-cell
                [title]="salon.name"
                [numChars]="1"></colored-icon-cell>
            </ng-template>
          </cell>
          <cell columnId="name">
            <ng-template>
              <text-cell>
                <text-cell-text
                  [value]="salon.name"
                  title="Visit"
                  className="primary"
                  (action)="visit(salon)">
                </text-cell-text>
                <text-cell-text
                  [value]="salon.slug"
                  className="secondary">
                </text-cell-text>
              </text-cell>
            </ng-template>
          </cell>
          <cell columnId="apiVersion">
            <ng-template>
              <text-cell>
                <text-cell-text
                  [value]="salon.apiVersion"
                  [className]="'api-version-badge ' + salon.apiVersion?.toLowerCase()">
                </text-cell-text>
              </text-cell>
            </ng-template>
          </cell>
          <cell columnId="salonId">
            <ng-template>
              <text-cell>
                <text-cell-text [value]="salon._id"> </text-cell-text>
              </text-cell>
            </ng-template>
          </cell>
          <cell columnId="flags">
            <ng-template>
              <div
                *ngIf="salon.entitlementState != null && salon.entitlementState !== 'Active'"
                class="flag">
                {{ salon.entitlementState }}
              </div>
            </ng-template>
          </cell>
          <cell columnId="menu">
            <ng-template>
              <menu-cell>
                <menu-cell-item
                  title="Edit"
                  (action)="edit(salon)">
                </menu-cell-item>
                <menu-cell-item
                  title="Visit"
                  (action)="visit(salon)">
                </menu-cell-item>
                <menu-cell-item
                  title="Delete"
                  (action)="delete(salon)">
                </menu-cell-item>
                <menu-cell-item
                  title="Generate Orders"
                  (action)="generateOrders(salon)">
                </menu-cell-item>
                <menu-cell-item
                  title="Export Formulas"
                  (action)="exportFormulas(salon)">
                </menu-cell-item>
                <menu-cell-item
                  title="Export/Import Data"
                  (action)="importData(salon)">
                </menu-cell-item>
                <menu-cell-item
                  *ngIf="salon.apiVersion === 'V1'"
                  title="Migrate to v2"
                  (action)="migrateToV2(salon)">
                </menu-cell-item>
                <menu-cell-item
                  *ngIf="salon.entitlementState === 'Active'"
                  title="Revoke Access"
                  (action)="revokeAccess(salon)">
                </menu-cell-item>
                <menu-cell-item
                  *ngIf="salon.entitlementState == null || salon.entitlementState !== 'Active'"
                  title="Restore Access"
                  (action)="restoreAccess(salon)">
                </menu-cell-item>
              </menu-cell>
            </ng-template>
          </cell>
        </row>
      </body>
      <empty-state-message
        title="No salons found"
        icon="building"
        data-table-empty-state>
        We could not find any Salons for the current criteria. Adjust your search to find what you're looking for
      </empty-state-message>
    </data-table>
  </card>
</page>
