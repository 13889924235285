<div class="weight-selector">
  <span class="options">
    <span
      *ngFor="let option of options"
      class="option"
      [ngClass]="weight?.value === option.value && !customActive ? 'selected' : ''"
      (click)="weightChange.emit(option)">
      {{ getOptionText(option) }}
    </span>
  </span>
  <span class="custom">
    <input
      type="number"
      min="0"
      [step]="step"
      placeholder="Custom"
      [ngClass]="(customValue | isDefined) ? 'selected' : ''"
      [value]="customValue"
      (change)="customChanged($event)"
      (focus)="customFocused()"
      (blur)="customBlurred()" />
    <span
      *ngIf="!customActive && (customValue | isDefined)"
      class="custom-label"
      >{{ getCustomText() }}</span
    >
  </span>
</div>
