import { ChangeDetectionStrategy, Component } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AppState } from "app/kernel";
import { Observable } from "rxjs";
import { back } from "../../../kernel/store/actions/router.actions";
import * as mergeProductsActions from "../store/merge-products.actions";
import { ProductSelection } from "../store/product.reducer";
import * as fromProduct from "../store/product.selectors";
import * as fromMergeProducts from "../store/merge-products.selector";
import { MergeProductsSuccess } from "../services";

@Component({
  selector: "merge-products-container",
  templateUrl: "merge-products.container.html",
  styleUrls: ["merge-products.container.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeProductsContainer {
  public saving$: Observable<boolean>;
  public error$: Observable<Error>;
  public result$: Observable<MergeProductsSuccess>;
  public selectedProducts$: Observable<ProductSelection[]>;
  public primary$: Observable<ProductSelection>;

  constructor(private _store: Store<AppState>) {
    this.saving$ = _store.pipe(select(fromMergeProducts.getSaving));
    this.error$ = _store.pipe(select(fromMergeProducts.getError));
    this.result$ = _store.pipe(select(fromMergeProducts.getResult));
    this.selectedProducts$ = _store.pipe(select(fromProduct.getSelected));
    this.primary$ = _store.pipe(select(fromMergeProducts.getPrimary));
  }

  updatePrimary(product: ProductSelection): void {
    this._store.dispatch(mergeProductsActions.updatePrimary({ product }));
  }

  mergeProducts(): void {
    this._store.dispatch(mergeProductsActions.merge());
  }

  close(): void {
    this._store.dispatch(back());
  }

  done(): void {
    this._store.dispatch(mergeProductsActions.done());
  }
}
