import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "add-manufacturer-card",
  templateUrl: "./add-manufacturer-card.component.html",
  styleUrls: ["./add-manufacturer-card.component.less"],
})
export class AddManufacturerCardComponent {
  @Output()
  public clicked: EventEmitter<void> = new EventEmitter<void>(true);
}
