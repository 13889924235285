<slideout-panel
  *ngLet="result$ | async as result"
  (close)="result != null ? done() : close()"
  [progressIndicator]="(saving$ | async) === true ? 'Saving...' : undefined">
  <div panel-header>Merge Products</div>
  <div
    panel-body
    class="body">
    <div *ngIf="(error$ | async) != null; else bodyTemplate">
      <alert-component type="danger">
        <h5>It looks like something bad happened. Close this panel and try again.</h5>
      </alert-component>
    </div>
    <ng-template #bodyTemplate>
      <ng-container *ngIf="(result$ | async) == null; else showResults">
        <ng-container *ngIf="(selectedProducts$ | async)?.length > 0; else noSelections">
          <div class="page-content">
            <label id="primary-radio-group-label">Select a product as <i>Primary</i>:</label>
            <mat-radio-group
              (change)="updatePrimary($event.value)"
              [value]="primary$ | async">
              <mat-radio-button
                *ngFor="let product of selectedProducts$ | async"
                [value]="product">
                <div
                  class="product"
                  [title]="product.name">
                  {{ product.name }}
                </div>
                <div
                  class="manufacturer"
                  [title]="product.manufacturer">
                  {{ product.manufacturer }}
                </div>
                <div
                  class="categories"
                  [title]="product.categories">
                  {{ product.categories }}
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="card">
            <b>When merging products, the following rules apply:</b>
            <ul>
              <li>All products' history will be merged to the <i>Primary</i></li>
              <li>All products except the <i>Primary</i> will be removed</li>
            </ul>
          </div>

          <div
            class="controls"
            (click)="mergeProducts()">
            <button
              [disabled]="(primary$ | async) == null"
              type="button"
              class="button primary">
              Continue
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
    <ng-template #showResults>
      <alert-component type="success">
        <h4>Products merged successfully</h4>
        <ul>
          <li>{{ result.numFormulasUpdated }} formula{{ result.numFormulasUpdated === 1 ? " was" : "s were" }} updated</li>
          <li>{{ result.numProductsDeleted }} product{{ result.numProductsDeleted === 1 ? " was" : "s were" }} deleted</li>
        </ul>
      </alert-component>
      <div class="controls">
        <button
          (click)="done()"
          type="button"
          class="button primary">
          Done
        </button>
      </div>
    </ng-template>
    <ng-template #noSelections>
      <alert-component type="danger">
        <h4>No Products Selected</h4>
        <p>Seems like you haven't selected any Products to merge. Close this panel and try again.</p>
      </alert-component>
    </ng-template>
  </div>
</slideout-panel>
