import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import {
  EditServiceDescriptionContainer,
  EditServicesContainer,
  ProductAllowanceCalculatorContainer,
  ServiceMenuListContainer,
} from "./containers";

export const routes: Routes = [
  {
    path: "",
    component: ServiceMenuListContainer,
    data: { breadcrumb: { label: "Service Menu" } },
  },
  { path: "new", component: EditServiceDescriptionContainer, outlet: "panel" },
  {
    path: "edit/:id",
    component: EditServiceDescriptionContainer,
    outlet: "panel",
  },
  {
    path: "pa-calculator",
    component: ProductAllowanceCalculatorContainer,
    data: { breadcrumb: { label: "Product Allowance" } },
  },
  { path: "edit-services", component: EditServicesContainer, outlet: "panel" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ServiceMenuRoutingModule {}
