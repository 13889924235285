<div
  *ngIf="productVms | isEmpty; else productsList"
  class="empty-container">
  <span>Category is empty</span>
</div>
<ng-template #productsList>
  <ul class="product-container">
    <li
      *ngFor="let product of productVms"
      [ngClass]="{ 'not-imported': product.notImported, inactive: product.flags }">
      <mat-menu #controlsMenu="matMenu">
        <button
          mat-menu-item
          (click)="selectProduct(product)">
          Edit
        </button>
        <button
          mat-menu-item
          (click)="discontinueProduct(product)">
          Discontinue
        </button>
      </mat-menu>

      <span class="fa-li">
        <colored-dot
          [color]="product.hexColorCode"
          size="small"></colored-dot>
      </span>
      <span
        class="product-name"
        [matMenuTriggerFor]="controlsMenu">
        {{ product.name }}
      </span>
      <span
        *ngFor="let flag of product.flags"
        class="label focus">
        {{ flag }}
      </span>
    </li>
  </ul>
</ng-template>
