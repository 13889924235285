import { Manufacturer, Product, ProductCategory, SalonProduct } from "@getvish/model";
import { append, dropLastWhile, not, pipe, takeWhile, uniqBy } from "ramda";

import {
  load,
  pushCategory,
  popToCategory,
  clearCategories,
  selectManufacturer,
  previousStep,
  removeAllProducts,
  loadSuccess,
  loadFail,
  toggleProduct,
  search,
  selectProducts,
  deselectProducts,
  ProductSelectionMode,
  ProductSelectionType,
  loadProductsForManufacturerSuccess,
  loadProductsForManufacturerFail,
} from "./product-selection.actions";
import { createReducer, on } from "@ngrx/store";

export interface ProductSelectionState {
  loading: boolean;
  saving: boolean;
  searchFilter: string;
  manufacturers: Manufacturer[];
  salonProducts: SalonProduct[];
  selectedManufacturer: Manufacturer;
  availableProducts: Product[];
  availableCategories: ProductCategory[];
  selectedProducts: SalonProduct[];
  selectedProductCategories: ProductCategory[];
  importStep: number;
  psMode: ProductSelectionMode;
  psType: ProductSelectionType;
}

const initialState: ProductSelectionState = {
  loading: false,
  saving: false,
  searchFilter: undefined,
  manufacturers: [],
  salonProducts: [],
  availableProducts: [],
  availableCategories: [],
  selectedManufacturer: undefined,
  selectedProducts: [],
  selectedProductCategories: [],
  importStep: 0,
  psMode: "ALL",
  psType: "SALON_PRODUCTS",
};

export const reducer = createReducer(
  initialState,
  on(load, (state, { psMode, psType }) => ({
    ...initialState,
    loading: true,
    psMode,
    psType,
  })),
  on(loadSuccess, (state, { manufacturers, salonProducts }) => ({
    ...state,
    loading: false,
    manufacturers,
    salonProducts,
  })),
  on(loadFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(loadProductsForManufacturerSuccess, (state, { products, categories }) => ({
    ...state,
    availableProducts: products,
    availableCategories: categories,
    loading: false,
  })),
  on(loadProductsForManufacturerFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(selectManufacturer, (state, { manufacturer }) => ({
    ...state,
    importStep: 1,
    selectedManufacturer: manufacturer,
    availableCategories: [],
    searchFilter: undefined,
  })),
  on(previousStep, (state) => ({
    ...state,
    importStep: Math.max(0, state.importStep - 1),
    searchFilter: undefined,
  })),
  on(search, (state, { filter }) => ({
    ...state,
    searchFilter: filter,
  })),
  on(selectProducts, (state, { products }) => {
    const selectedProducts = uniqBy((product) => product._id, [...products, ...state.selectedProducts]);

    return {
      ...state,
      selectedProducts,
    };
  }),
  on(deselectProducts, (state, { products }) => {
    const productIds = products.map((product) => product._id);

    return {
      ...state,
      selectedProducts: state.selectedProducts.filter((product) => not(productIds.includes(product._id))),
    };
  }),
  on(toggleProduct, (state, { product }) => {
    const updatedSelectedProducts = state.selectedProducts.some((selectedProduct) => product._id === selectedProduct._id)
      ? state.selectedProducts.filter((selectedProduct) => selectedProduct._id !== product._id)
      : [...state.selectedProducts, product];

    return {
      ...state,
      saving: false,
      selectedProducts: updatedSelectedProducts,
    };
  }),
  on(pushCategory, (state, { category }) => {
    const selectedProductCategories = pipe(
      // if a category is being pushed, we'll want to make sure that we're not appending that category twice
      // and, at the same time, because a category could potentially be pushed to any point in the stack
      // we'll want to remove any categories _up to and including_ the category that's being pushed
      // example:
      // Currently-selected categories: [ A, B, C, D ]
      // Category B is selected/pushed (i.e. the user wants to "jump to" category B)
      // we'll want to remove all categories "up to and including" B, so we're left with [ A ]
      // then we'll append category B, so we end up with [ A, B ]
      // this allows us to both cover the case where the user wants to "jump back up to" category B
      // and also the case where the user wants to push a new category onto the stack (let's say E)
      takeWhile<ProductCategory>((selectedCategory) => {
        return selectedCategory._id !== category._id;
      }),
      dropLastWhile<ProductCategory>((selectedCategory) => {
        return selectedCategory.parentCategoryId === category.parentCategoryId;
      }),
      append(category)
    )(state.selectedProductCategories);

    return { ...state, selectedProductCategories };
  }),
  on(popToCategory, (state, { categoryId }) => ({
    ...state,
    selectedProductCategories: dropLastWhile<ProductCategory>((productCategory) => productCategory._id !== categoryId)(
      state.selectedProductCategories
    ),
  })),
  on(removeAllProducts, (state) => ({
    ...state,
    selectedProducts: [],
  })),
  on(clearCategories, (state) => ({
    ...state,
    selectedProductCategories: [],
  }))
);

export const featureKey = "productSelection";
