<div
  tabindex="0"
  #ps>
  <div
    class="replacement"
    *ngFor="let replacement of value; let replacmentIdx = index; trackBy: replacementTrackBy">
    <div
      class="product"
      *ngFor="let product of replacement.products; trackBy: productSelectionTrackBy">
      <div class="colorIcon">
        <colored-dot
          [color]="product.hexColorCode"
          size="medium"></colored-dot>
      </div>
      <div class="description">
        <div
          class="name"
          [title]="product.name">
          {{ product.name }}
        </div>
        <div
          class="manufacturer"
          [title]="product.manufacturer.name">
          {{ product.manufacturer.name }}
        </div>
        <div class="ratio">
          <span>Ratio:</span>
          <input
            type="number"
            min="0"
            step="0.25"
            class="ratio"
            [value]="product.ratio"
            (change)="productRatioChanged(replacmentIdx, product, $event)" />
        </div>
      </div>
      <fa-icon
        class="delete"
        icon="trash"
        (click)="deleteProduct(replacmentIdx, product)"></fa-icon>
    </div>
    <a
      class="addProducts"
      title="Add Products"
      (click)="addProducts(replacmentIdx)">
      + Add Products
    </a>
  </div>
  <a
    class="addReplacement"
    title="Add Replacement"
    (click)="addReplacement()">
    + Add Replacement
  </a>
</div>
