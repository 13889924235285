import { Injectable } from "@angular/core";
import { DiscontinuedProduct, Product } from "@getvish/model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { either } from "fp-ts";
import { of } from "rxjs";
import { catchError, map, mapTo, mergeMap, switchMap } from "rxjs/operators";
import { DiscontinuedProductService, ProductService } from "../services";

import * as productCategoryActions from "../../../+product/+product-categories/store/product-category.actions";
import * as routerActions from "../../../kernel/store/actions/router.actions";
import * as SnackbarActions from "../../../kernel/store/actions/snackbar.actions";
import * as productActions from "./product.actions";
import * as mergeProductsActions from "./merge-products.actions";
import { MatDialog } from "@angular/material/dialog";
import { ProductOrderDialogComponent } from "app/+product/+salon-products/components/product-order-dialog/product-order-dialog.component";
import { fold, fromNullable } from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";

@Injectable()
export class ProductEffects {
  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.LoadAll>(productActions.Types.LOAD_ALL),
      map((action) => action.payload),
      switchMap((payload) =>
        this._productService.find(payload.criteria, payload.sort, payload.page, payload.limit).pipe(
          switchMap((result) =>
            this._discontinuedProductService.findByProductIds(result.records.map((product) => product._id)).pipe(
              mergeMap((discontinuedProducts: DiscontinuedProduct[]) => [
                new productActions.LoadAllSuccess(result.records, discontinuedProducts, result.paging),
              ]),
              catchError((reason) => of(new productActions.LoadAllFail({ errors: reason })))
            )
          )
        )
      )
    )
  );

  public selectManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.SelectManufacturer>(productActions.Types.SELECT_MANUFACTURER),
      map((action) => action.payload.manufacturer),
      map((manufacturer) => manufacturer._id),
      map((manufacturerId) => new productActions.LoadAll({ criteria: { manufacturerId } }))
    )
  );

  public loadProductCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.SelectManufacturer>(productActions.Types.SELECT_MANUFACTURER),
      map((action) => action.payload.manufacturer),
      map((manufacturer) => manufacturer._id),
      map((manufacturerId) => new productCategoryActions.LoadAll({ criteria: { manufacturerId } }))
    )
  );

  public newProduct$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.New>(productActions.Types.NEW),
      map(({ payload }) =>
        routerActions.go({
          path: ["/product/products", { outlets: { panel: "new" } }],
          query: { manufacturerId: payload.manufacturerId },
        })
      )
    )
  );

  public editProduct$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.Edit>(productActions.Types.EDIT),
      map((action) => action.payload.product),
      map((product) => product._id),
      map((productId) => new productActions.NavigateEditProduct({ productId }))
    )
  );

  public navigateEditProduct$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.NavigateEditProduct>(productActions.Types.NAVIGATE_EDIT_PRODUCT),
      map((action) => `edit/${action.payload.productId}`),
      map((fragment) =>
        routerActions.go({
          path: ["/product/products", { outlets: { panel: fragment } }],
        })
      )
    )
  );
  public navigateMergeProducts$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.Merge>(productActions.Types.MERGE),
      mergeMap(() => [
        mergeProductsActions.init(),
        routerActions.go({
          path: ["/product/products", { outlets: { panel: "merge" } }],
        }),
      ])
    )
  );

  public add$ = createEffect(() =>
    this._actions$.pipe(
      ofType(productActions.Types.ADD),
      map((action: productActions.Add) => action.payload.product),
      switchMap((payload) =>
        this._productService.insert(payload).pipe(
          mergeMap(
            either.fold<Error, Product, Action[]>(
              (error) => [new productActions.AddFail({ errors: error })],
              (product) => [new productActions.AddSuccess(product)]
            )
          )
        )
      )
    )
  );

  public update$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.Update>(productActions.Types.UPDATE),
      map((action) => action.payload.product),
      switchMap((payload) =>
        this._productService.update(payload).pipe(
          mergeMap(
            either.fold<Error, Product, Action[]>(
              (error) => [new productActions.UpdateFail({ errors: error })],
              (product) => [new productActions.UpdateSuccess(product)]
            )
          )
        )
      )
    )
  );

  public addOrUpdateSuccess$ = createEffect(() =>
    this._actions$.pipe(ofType(productActions.Types.ADD_SUCCESS, productActions.Types.UPDATE_SUCCESS), mapTo(routerActions.back()))
  );

  public showProductOrderDialog$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.ChangeProductOrder>(productActions.Types.CHANGE_PRODUCT_ORDER),
      map((action) => action.payload.products),
      map((products) =>
        this._matDialog.open<ProductOrderDialogComponent, { products: Product[] }, Product[]>(ProductOrderDialogComponent, {
          disableClose: true,
          maxHeight: "95vh",
          panelClass: "dlg-no-padding-pane",
          data: {
            products,
          },
        })
      ),
      switchMap((dialogRef) => dialogRef.afterClosed()),
      map(fromNullable),
      map(
        fold(
          () => ({ type: "EMPTY_ACTION" }),
          (products) => new productActions.UpdateProducts({ products })
        )
      )
    )
  );

  public updateProducts$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.UpdateProducts>(productActions.Types.UPDATE_PRODUCTS),
      map((action) => action.payload),
      switchMap(({ products }) => this._productService.updateMany(products)),
      map(
        E.fold<Error, Product[], Action>(
          (e) => new productActions.UpdateProductsFail({ error: e }),
          (products) => new productActions.UpdateProductsSuccess({ products })
        )
      )
    )
  );

  public updateProductsFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType<productActions.UpdateProductsFail>(productActions.Types.UPDATE_PRODUCTS_FAIL),
      map(() => new SnackbarActions.Info({ message: "Updating products failed." }))
    )
  );

  // @Effect()
  // public remove$ = this._actions$
  //   .pipe(
  //     ofType(customerActions.Types.REMOVE),
  //     map((action: customerActions.Remove) => action.payload.id),
  //     switchMap((id) => this._customerService.remove(id).pipe(
  //       map((result) => result.fold<Action>(
  //         (error) => new customerActions.RemoveFail({ errors: error }),
  //         (removedId) => new customerActions.RemoveSuccess(removedId)
  //     ))))
  //   );

  constructor(
    private _discontinuedProductService: DiscontinuedProductService,
    private _productService: ProductService,
    private _matDialog: MatDialog,
    private _actions$: Actions
  ) {}
}
