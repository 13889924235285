import { createSelector, createFeatureSelector, createReducer, on } from "@ngrx/store";

import * as app from "../actions/app.actions";

export interface State {
  loading: boolean;
  forbidden: boolean;
}

const initialState: State = {
  loading: false,
  forbidden: false,
};

export const reducer = createReducer<State, app.Actions>(
  initialState,
  on(app.loadingStarted, (state) => ({
    ...state,
    loading: true,
  })),
  on(app.loadingComplete, (state) => ({
    ...state,
    loading: false,
  })),
  on(app.loadSalonConfigForbidden, (state) => ({
    ...state,
    loading: false,
    forbidden: true,
  }))
);

export const featureKey = "app";
export const getCommonState = createFeatureSelector<State>(featureKey);
export const getLoading = createSelector(getCommonState, (state) => state.loading);
export const getForbidden = createSelector(getCommonState, (state) => state.forbidden);
