import { createFeatureSelector, createSelector } from "@ngrx/store";
import { fromNullable, getOrElse, map } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { ServiceMenuState } from "./service-menu.reducer";

export const getState = createFeatureSelector<ServiceMenuState>("serviceMenu");
export const getLoading = createSelector(getState, (state) => state.loading);
export const getLoadingOne = createSelector(getState, (state) => state.loadingOne);
export const getPaging = createSelector(getState, (state) => fromNullable(state.paging));
export const getFilter = createSelector(getState, (state) => state.filter);
export const getSort = createSelector(getState, (state) => state.sort);
export const getCurrentPage = createSelector(getPaging, (paging) =>
  pipe(
    paging,
    map((paging) => paging.page),
    getOrElse(() => 1)
  )
);

export const getServiceDescriptions = createSelector(getState, (state) => {
  const _scs = state.serviceCategories.reduce((acc, sc) => {
    acc[sc._id] = sc;
    return acc;
  }, {});

  return state.serviceDescriptions.map((sd) => {
    return {
      ...sd,
      category: _scs[sd.categoryId],
    };
  });
});

export const getServiceCategories = createSelector(getState, (state) => state.serviceCategories);
export const getSaving = createSelector(getState, (state) => state.saving);

export const getSelectedRecords = createSelector(getState, (state) => state.selectedRecords);
export const getSelectedCategories = createSelector(getState, (state) => state.selectedCategories);

export const getUncategorizedOnly = createSelector(getState, (state) => state.uncategorizedOnly);
export const getShowInactive = createSelector(getState, (state) => state.showInactive);

export const getServiceDescription = (id: string) =>
  createSelector(getServiceDescriptions, (services) => services.find((service) => service._id === id));

export const getProductAllowances = createSelector(getState, (state) => state.productAllowances);

export const isPreparingExport = createSelector(getState, (state) => state.preparingExport);

export const getTableFilter = createSelector(getState, (state) => state.tableFilter);

export const getComplianceAnomalies = createSelector(getState, (state) => state.complianceAnomalies);
