<header-controls>
  <button
    (click)="saveForm(salon, form.value)"
    [disabled]="form.invalid"
    class="button outline">
    Save
  </button>
</header-controls>
<div class="salonForm">
  <form
    [formGroup]="form"
    (submit)="saveForm(salon, form.value)"
    novalidate>
    <mat-form-field class="full-width">
      <mat-label>Salon Name</mat-label>
      <input
        matInput
        formControlName="name" />
      <mat-error *ngIf="form.get('name').hasError('required')">Required</mat-error>
    </mat-form-field>
    <div formGroupName="address">
      <mat-form-field
        class="full-width"
        [ngClass]="{ 'field-pulse': form.value.address.addressLine1 | isNil }">
        <mat-label>Address Line 1</mat-label>
        <location-autocomplete
          formControlName="addressLine1"
          (select)="locationSelected($event)"
          [required]="true" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Address Line 2</mat-label>
        <input
          matInput
          formControlName="addressLine2" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>City</mat-label>
        <input
          matInput
          formControlName="city" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Province/State</mat-label>
        <input
          matInput
          formControlName="province" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Country</mat-label>
        <input
          type="text"
          aria-label="Country"
          matInput
          formControlName="country"
          [matAutocomplete]="countryAuto" />
        <mat-error *ngIf="getCountryControl().hasError('matchesOneOf')">Not a valid Country</mat-error>
        <mat-autocomplete
          autoActiveFirstOption
          [displayWith]="displayCountry"
          #countryAuto="matAutocomplete">
          <mat-option
            *ngFor="let country of filteredCountries$ | async"
            [value]="country.value">
            {{ country.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Postal/Zip Code</mat-label>
        <input
          matInput
          formControlName="postalCode" />
      </mat-form-field>
    </div>
  </form>
  <map
    *ngIf="markers != null && markers.length > 0"
    [markers]="markers"></map>
</div>
