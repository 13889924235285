import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { AppState } from "../../kernel/store";
import { Store, select } from "@ngrx/store";
import { Observable, combineLatest, Subscription } from "rxjs";
import { ProductReport, ProductCategoryReport } from "../../kernel/models";
import { Manufacturer, MeasurementUnit, ProductCategory, SalonProduct, Employee } from "@getvish/model";
import { switchMap, map, take } from "rxjs/operators";
import { getProductReport, getLoading, getStartDate, getEndDate } from "../store/product-report.selectors";
import { getProductCategoryReport } from "../store/product-category-report.selectors";

import { UpdateProductReportFilters } from "../store/product-report.actions";
import { LoadProductReportGraph } from "../store/product-report.actions";

import { getMeasurementUnitOrDefault } from "../../+salon-config/store/salon-config.selectors";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { getRootCategoryMap } from "../../+product/+product-categories/store/product-category.selectors";

import * as fromSalonProduct from "../../+product/+salon-products/store/salon-product.selectors";
import * as fromManufacturer from "../../+product/+manufacturers/store/manufacturer.selectors";
import * as fromEmployee from "../../+employees/store/employee.selectors";
import * as fromSalonConfig from "../../+salon-config/store/salon-config.selectors";

@Component({
  selector: "products-report-container",
  templateUrl: "products-report.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsReportContainer implements OnDestroy {
  public productReport$: Observable<ProductReport>;
  public productCategoryReport$: Observable<ProductCategoryReport>;
  public manufacturer$: Observable<Manufacturer>;
  public manufacturers$: Observable<Manufacturer[]>;
  public measurementUnit$: Observable<MeasurementUnit>;
  public rootCategoryMap$: Observable<Map<string, ProductCategory>>;
  public salonProducts$: Observable<SalonProduct[]>;
  public employees$: Observable<Employee[]>;
  public loading$: Observable<boolean>;
  public startDate$: Observable<number>;
  public endDate$: Observable<number>;
  public currency$: Observable<string>;
  public timeZone$: Observable<string>;

  private _subscription: Subscription;

  constructor(
    private _store: Store<AppState>,
    route: ActivatedRoute
  ) {
    const manufacturerId$ = route.paramMap.pipe(map((params: ParamMap) => params.get("manufacturerId")));
    this.productReport$ = _store.pipe(select(getProductReport));
    this.productCategoryReport$ = _store.pipe(select(getProductCategoryReport));
    this.manufacturer$ = manufacturerId$.pipe(
      switchMap((manufacturerId) => _store.pipe(select(fromManufacturer.getManufacturer(manufacturerId))))
    );
    this.measurementUnit$ = _store.pipe(select(getMeasurementUnitOrDefault));
    this.rootCategoryMap$ = _store.pipe(select(getRootCategoryMap));
    this.salonProducts$ = _store.pipe(select(fromSalonProduct.getAll));
    this.employees$ = _store.pipe(select(fromEmployee.getEmployees));
    this.manufacturers$ = _store.pipe(select(fromManufacturer.getManufacturers));
    this.loading$ = _store.pipe(select(getLoading));
    this.startDate$ = _store.pipe(select(getStartDate));
    this.endDate$ = _store.pipe(select(getEndDate));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.timeZone$ = _store.select(fromSalonConfig.getSalonTimeZone);

    const startDateOrDefault$ = _store.pipe(select(getStartDate));
    const endDateOrDefault$ = _store.pipe(select(getEndDate));

    combineLatest(manufacturerId$, startDateOrDefault$, endDateOrDefault$)
      .pipe(
        map(([manufacturerId, startDate, endDate]) => new LoadProductReportGraph({ manufacturerId, startDate, endDate })),
        take(1)
      )
      .subscribe((action) => this._store.dispatch(action));
  }

  public updateFilters(event: { startDate?: number; endDate?: number; employeeId?: string; manufacturerId?: string }): void {
    this._store.dispatch(new UpdateProductReportFilters(event));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
