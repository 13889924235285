import { InjectionToken } from "@angular/core";
import { HttpRequestTransformer } from "@getvish/stockpile";
import { ServerConfig } from "../models/server-config";

export function buildWebsocketUrlFromConfig(config: ServerConfig): string {
  return `${config.protocol}://${config.hostname}:${config.port}/api/socket/v2/webapp`;
}

export function buildHttpUrlFromConfig(config: ServerConfig): string {
  return `${config.httpProtocol}://${config.hostname}:${config.port}/api/v2`;
}

//export const SERVER_CONFIG_WEBSOCKET_URL = buildWebsocketUrlFromConfig(SERVER_CONFIG);
//export const SERVER_CONFIG_HTTP_URL = buildHttpUrlFromConfig(SERVER_CONFIG);
export const HTTP_URL = new InjectionToken<string>("httpUrl");
export const HTTP_REQUEST_TRANSFORMER = new InjectionToken<HttpRequestTransformer>("httpRequestTransformer");
export const WEBSOCKET_URL = new InjectionToken<string>("websocketUrl");
export const SERVER_CONFIG = new InjectionToken<ServerConfig>("SERVER_CONFIG");
export const CONNECTIVITY_TEST_URLS = new InjectionToken<string[]>("CONNECTIVITY_TEST_URLS");

// eslint-disable-next-line @typescript-eslint/ban-types
export type Models = Function[];

export type Collections = { [key: string]: any };
export const MODELS = new InjectionToken<Collections>("MODELS");
