<slideout-panel
  *ngLet="product$ | async as product"
  (close)="close()"
  [progressIndicator]="(saving$ | async) === true ? 'Saving...' : (loading$ | async) === true ? 'Loading...' : null">
  <div panel-header>Discontinue Product</div>
  <div
    *ngIf="(loading$ | async) !== true"
    panel-body
    class="body">
    <div *ngIf="(error$ | async) != null; else bodyTemplate">
      <alert-component type="danger">
        <h5>It looks like something bad happened. Close this panel and try again.</h5>
      </alert-component>
    </div>
    <ng-template #bodyTemplate>
      <div class="product-name">
        <colored-dot
          class="cell-minimal-width cell-minimal-padding"
          [color]="product.hexColorCode"
          size="medium"></colored-dot>
        <div>{{ product.name }}</div>
      </div>
      <form
        [formGroup]="form"
        novalidate>
        <mat-form-field class="full-width">
          <mat-label>Discontinuation Date</mat-label>
          <input
            matInput
            formControlName="date"
            [matDatepicker]="picker" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Replacements</mat-label>
          <product-replacement formControlName="replacements" />
        </mat-form-field>
      </form>
      <div class="controls">
        <button
          *ngLet="discontinuedProduct$ | async as discontinuedProduct"
          [disabled]="form.invalid"
          (click)="saveForm(form.value, product, discontinuedProduct)"
          type="button"
          class="button primary">
          <span>Save</span>
        </button>
        <button
          *ngIf="discontinuedProduct$ | async as discontinuedProduct"
          mat-icon-button
          class="button secondary small"
          title="Delete"
          (click)="deleteDiscontinue(discontinuedProduct)">
          <fa-icon icon="trash"></fa-icon>
        </button>
      </div>
    </ng-template>
  </div>
</slideout-panel>
