import { Employee } from "@getvish/model";
import * as employee from "./employee.actions";

import { JsonObject, PagingMetadata } from "@getvish/stockpile";

export interface EmployeeState {
  loading: boolean;
  saving: boolean;
  restoring: boolean;
  records: Employee[];
  paging: PagingMetadata;
  selected: string;
  criteria: JsonObject;
  sort: JsonObject;
  filter: string;
  selectedRecords: Employee[];
  showDeleted: boolean;
  tableFilter: JsonObject;
}

const initialState: EmployeeState = {
  loading: true,
  saving: false,
  restoring: false,
  records: [],
  paging: undefined,
  selected: undefined,
  criteria: undefined,
  sort: undefined,
  filter: undefined,
  selectedRecords: [],
  showDeleted: undefined,
  tableFilter: undefined,
};

export function employeeReducer(state: EmployeeState = initialState, action: employee.Actions): EmployeeState {
  switch (action.type) {
    case employee.Types.UPDATE_FILTER: {
      const filter = action.payload;

      return {
        ...state,
        filter,
      };
    }
    case employee.Types.UPDATE_TABLE_FILTER: {
      const tableFilter = action.payload;

      return {
        ...state,
        tableFilter,
      };
    }
    case employee.Types.UPDATE_SORT: {
      const sort = action.payload;

      return {
        ...state,
        sort,
      };
    }
    case employee.Types.LOAD_ALL: {
      const loading = true;
      const { criteria, sort, filter, showDeleted, tableFilter } = action.payload;

      return {
        ...state,
        loading,
        criteria,
        sort,
        filter,
        showDeleted,
        tableFilter,
      };
    }

    case employee.Types.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.payload,
        paging: action.paging,
      };
    }

    case employee.Types.ADD:
    case employee.Types.UPDATE: {
      const saving = true;

      return {
        ...state,
        saving,
      };
    }

    case employee.Types.SET_ROLES_SUCCESS:
    case employee.Types.ADD_SUCCESS:
    case employee.Types.UPDATE_SUCCESS: {
      const saving = false;

      return {
        ...state,
        saving,
      };
    }

    case employee.Types.UPDATE_MULTIPLE_SUCCESS: {
      const saving = false;

      return {
        ...state,
        saving,
      };
    }

    case employee.Types.TOGGLE_SHOW_DELETED: {
      const showDeleted = !state.showDeleted;

      return {
        ...state,
        showDeleted,
      };
    }

    case employee.Types.RESTORE_EMPLOYEE: {
      return {
        ...state,
        restoring: true,
      };
    }

    case employee.Types.RESTORE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        restoring: false,
        records: state.records.filter((record) => record._id !== action.payload.employee._id),
      };
    }

    case employee.Types.RESTORE_EMPLOYEE_FAIL: {
      return {
        ...state,
        restoring: false,
      };
    }

    case employee.Types.REMOVE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        records: state.records.filter((record) => record._id !== action.employeeId),
      };
    }

    case employee.Types.TOGGLE_SELECTED: {
      const employee = action.payload;
      const selectedRecords = state.selectedRecords;

      const updatedSelectedRecords = selectedRecords.some((record) => record._id === employee._id)
        ? selectedRecords.filter((record) => record._id !== employee._id)
        : [...selectedRecords, employee];

      return {
        ...state,
        selectedRecords: updatedSelectedRecords,
      };
    }

    case employee.Types.CLEAR_SELECTED: {
      const selectedRecords = [];

      return {
        ...state,
        selectedRecords,
      };
    }

    default: {
      return state;
    }
  }
}
