import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "../../kernel";
import { combineLatest, Observable } from "rxjs";
import { ServiceDescription, ServiceCategory } from "@getvish/model";
import { map, take } from "rxjs/operators";

import * as ServiceMenuActions from "../store/service-menu.actions";
import * as EditServiceActions from "../store/edit-service.actions";
import * as fromEditService from "../store/edit-service.selectors";
import * as fromServiceMenu from "../store/service-menu.selectors";
import * as fromSalonConfig from "app/+salon-config/store/salon-config.selectors";
import { and, isNil, not } from "ramda";

@Component({
  selector: "edit-service-description-container",
  templateUrl: "edit-service-description.container.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditServiceDescriptionContainer {
  public serviceDescription$: Observable<ServiceDescription>;
  public serviceCategories$: Observable<ServiceCategory[]>;
  public saving$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public currency$: Observable<string>;
  public error$: Observable<string>;

  public shouldRender$: Observable<boolean>;

  constructor(
    private _store: Store<AppState>,
    _route: ActivatedRoute
  ) {
    this.serviceCategories$ = _store.pipe(select(fromServiceMenu.getServiceCategories));

    this.saving$ = _store.pipe(select(fromEditService.getSaving));
    this.loading$ = _store.pipe(select(fromEditService.getLoading));
    this.currency$ = _store.pipe(select(fromSalonConfig.getCurrency));
    this.error$ = _store.pipe(select(fromEditService.getError));

    this.shouldRender$ = combineLatest([this.error$, this.loading$]).pipe(map(([error, loading]) => and(isNil(error), not(loading))));

    const serviceDescriptionId$ = _route.params.pipe(map((params) => params["id"]));

    this.serviceDescription$ = _store.pipe(select(fromEditService.getRecord));

    serviceDescriptionId$
      .pipe(
        take(1),
        map((id) => new EditServiceActions.LoadData({ id }))
      )
      .subscribe((action) => _store.dispatch(action));
  }

  public save(serviceDescription: ServiceDescription): void {
    this._store.dispatch(EditServiceActions.addOrUpdate({ serviceDescription }));
  }

  public close(): void {
    this._store.dispatch(new ServiceMenuActions.EditServiceDone());
  }
}
