import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { map, catchError, mergeMap, switchMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { SalonProductService } from "../../+salon-products/services";
import { Manufacturer, Product, SalonProduct } from "@getvish/model";
import { MatDialog } from "@angular/material/dialog";
import { ProductService } from "app/+product/+products/services";

import {
  show,
  load,
  selectManufacturer,
  importProducts,
  importProductsSuccess,
  importProductsFail,
  loadSuccess,
  loadFail,
  loadProductsForManufacturerSuccess,
  loadProductsForManufacturerFail,
} from "./import-salon-products.actions";

import * as salonProductActions from "../../+salon-products/store/salon-product.actions";
import * as snackbarActions from "../../../kernel/store/actions/snackbar.actions";

import { ImportProductsDialogComponent } from "../components/import-products-dialog";
import { ManufacturerService } from "app/+product/+manufacturers/services";
import { ProductCategoryService } from "app/+product/+product-categories/services";
import { JsonObject } from "@getvish/stockpile";
import { either } from "fp-ts";
import { Action } from "@ngrx/store";

@Injectable()
export class ImportSalonProductEffects {
  public loadAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType(load),
      mergeMap(() =>
        forkJoin([this._manufacturerService.find({}, { name: 1 }), this._salonProductService.findAll()]).pipe(
          map(([manufacturersResult, salonProducts]) =>
            loadSuccess({
              manufacturers: manufacturersResult.records,
              salonProducts: salonProducts,
            })
          ),
          catchError((reason) => of(loadFail({ error: new Error(reason) })))
        )
      )
    )
  );

  public show$ = createEffect(() =>
    this._actions$.pipe(
      ofType(show),
      map(() =>
        this._matDialog.open<ImportProductsDialogComponent, JsonObject, { manufacturer: Manufacturer; products: Product[] }[]>(
          ImportProductsDialogComponent,
          { disableClose: true, width: "80vw", height: "95vh", maxHeight: "95vh", panelClass: "dlg-no-padding-pane" }
        )
      ),
      switchMap((dialogRef) =>
        this._actions$.pipe(ofType(importProducts)).pipe(
          mergeMap(({ productGroups }) =>
            this._salonProductService.importGroupedProducts(productGroups).pipe(
              map(
                either.fold<Error, SalonProduct[], Action>(
                  (error) => importProductsFail({ error }),
                  (importedSalonProducts) => {
                    dialogRef.close();

                    return importProductsSuccess({
                      salonProducts: importedSalonProducts,
                      manufacturers: productGroups.map((group) => group.manufacturer),
                    });
                  }
                )
              )
            )
          )
        )
      )
    )
  );

  public importProductsFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(importProductsFail),
      map(({ error }) => new snackbarActions.Info({ message: `Error importing products: ${error.message}` }))
    )
  );

  public loadProductsForManufacturer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(selectManufacturer),
      mergeMap(({ manufacturer }) =>
        forkJoin([
          this._productService.find({ manufacturerId: manufacturer._id }, { name: 1 }),
          this._productCategoryService.findForManufacturer(manufacturer._id),
        ])
      ),
      map(([productsResult, categoriesResult]) =>
        loadProductsForManufacturerSuccess({
          products: productsResult.records,
          categories: categoriesResult.records,
        })
      ),
      catchError((error) => of(loadProductsForManufacturerFail({ error })))
    )
  );

  public addDataOnImportSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(importProductsSuccess),
      map(() => new salonProductActions.LoadAll({}))
    )
  );

  constructor(
    private _salonProductService: SalonProductService,
    private _productCategoryService: ProductCategoryService,
    private _manufacturerService: ManufacturerService,
    private _productService: ProductService,
    private _actions$: Actions,
    private _matDialog: MatDialog
  ) {}
}
