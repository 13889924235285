import { Pipe, PipeTransform } from "@angular/core";
import { not, isNil } from "ramda";
import { getCurrencySymbol } from "@angular/common";

@Pipe({ name: "appendCurrencySymbol" })
export class AppendCurrencySymbolPipe implements PipeTransform {
  public transform(value: string, currency: string): string {
    const currencySymbol = not(isNil(currency)) ? getCurrencySymbol(currency, "narrow") : getCurrencySymbol("USD", "narrow");
    return `${value} (${currencySymbol})`;
  }
}
