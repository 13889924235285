import { EntityService, HttpRepositoryFactory } from "@getvish/stockpile";
import { EmployeeReport } from "../../kernel/models";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Option } from "fp-ts/Option";
import { map } from "rxjs/operators";
import { addDays } from "date-fns/esm/fp";

@Injectable()
export class EmployeeReportService extends EntityService<EmployeeReport> {
  constructor(repositoryFactory: HttpRepositoryFactory) {
    super(repositoryFactory, { entityKey: "employeeReports" });
  }

  public fetchCurrentSalonReport(): Observable<Option<EmployeeReport>> {
    const today = new Date();
    const yesterday = addDays(-1)(today);
    const criteria = { startDate: yesterday.getTime(), endDate: today.getTime() };

    return this.findOne(criteria);
  }

  public fetchForDateRange(startDate: number, endDate: number): Observable<EmployeeReport[]> {
    const criteria = { startDate, endDate };

    return this.find(criteria).pipe(map((result) => result.records));
  }

  public fetchForEmployeeInDateRange(employeeId: string, startDate: number, endDate: number): Observable<Option<EmployeeReport>> {
    const employeeIds = [employeeId];
    const criteria = { startDate, endDate, employeeIds };

    return this.findOne(criteria);
  }
}
