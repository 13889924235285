import { createAction, props, union } from "@ngrx/store";
import { Appointment, Employee } from "@getvish/model";

export enum Types {
  SEARCH_CUSTOMERS = "[Schedule Appointment] Search Customers",
  SCHEDULE = "[Schedule Appointment] Schedule",
  SUCCESS = "[Schedule Appointment] Success",
  FAIL = "[Schedule Appointment] Fail",
}

export const navigate = createAction("[Schedule Appointment] Navigate");

export const load = createAction("[Schedule Appointment] Load");

export const loadSuccess = createAction("[Schedule Appointment] Load Success", props<{ employees: Employee[] }>());

export const cancel = createAction("[Schedule Appointment] Cancel");

export const close = createAction("[Schedule Appointment] Close");

export const searchCustomers = createAction("[Schedule Appointment] Search Customers", props<{ filter: string }>());

export const schedule = createAction("[Schedule Appointment] Schedule", props<{ appointment: Appointment }>());

export const success = createAction("[Schedule Appointment] Success", props<{ appointment: Appointment }>());

export const fail = createAction("[Schedule Appointment] Fail", props<{ error: Error }>());

const actions = union({
  navigate,
  load,
  loadSuccess,
  cancel,
  close,
  searchCustomers,
  schedule,
  success,
  fail,
});

export type Actions = typeof actions;
