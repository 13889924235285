<a
  [routerLink]="disabled ? null : ['/service-menu/pa-calculator']"
  [queryParams]="{ ids: serviceId }"
  class="pa"
  [ngClass]="disabled ? 'disabled' : 'clickable'"
  title="Calculate Product Allowance">
  <fa-icon
    class="pa-icon"
    icon="calculator"></fa-icon>
  <span
    class="pa-currency"
    *ngIf="productAllowance | isDefined"
    >{{ productAllowance | currency: currency : "symbol-narrow" }}</span
  >
  <span
    class="pa-none-label"
    *ngIf="!(productAllowance | isDefined)"
    >Calculate Allowance</span
  >
</a>
