import { ServiceDescription } from "@getvish/model";

import * as editService from "./edit-service.actions";

export interface EditServiceState {
  record: ServiceDescription;
  saving: boolean;
  loading: boolean;
  error: string;
}

const initialState: EditServiceState = {
  record: undefined,
  saving: false,
  loading: false,
  error: undefined,
};

export function editServiceReducer(state: EditServiceState = initialState, action: editService.Actions): EditServiceState {
  switch (action.type) {
    case editService.Types.NAVIGATE: {
      const loading = true;

      return {
        ...state,
        loading,
      };
    }

    case editService.Types.LOAD_DATA: {
      const loading = true;

      return {
        ...state,
        loading,
      };
    }

    case editService.Types.LOAD_DATA_SUCCESS: {
      const loading = false;
      const record = action.payload;

      return {
        ...state,
        loading,
        record,
      };
    }

    case editService.Types.LOAD_DATA_FAIL: {
      const loading = false;
      const error = action.payload.error.message;

      return {
        ...state,
        loading,
        error,
      };
    }

    case editService.Types.EDIT:
    case editService.Types.ADD: {
      const saving = true;
      const error = undefined;

      return {
        ...state,
        saving,
        error,
      };
    }

    case editService.Types.ADD_SUCCESS:
    case editService.Types.EDIT_SUCCESS: {
      const saving = false;
      const error = undefined;

      return {
        ...state,
        saving,
        error,
      };
    }

    default: {
      return state;
    }
  }
}
