import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from "@angular/core";
import { Appointment, AppointmentStatus } from "@getvish/model";
import { AppointmentVM } from "../models/appointment";

@Component({
  selector: "appointment-row",
  templateUrl: "./appointment-row.component.html",
  styleUrls: ["./appointment-row.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentRowComponent {
  @Input() public appointment: AppointmentVM;
  @Input() public timeZone: string;
  @Output() public delete: EventEmitter<Appointment>;
  @Output() public resolve: EventEmitter<AppointmentVM>;
  @Output() public select: EventEmitter<Appointment>;

  constructor() {
    this.delete = new EventEmitter(true);
    this.resolve = new EventEmitter(true);
    this.select = new EventEmitter(true);
  }

  public isScheduled(appointment: Appointment): boolean {
    return appointment.status === AppointmentStatus.SCHEDULED;
  }
}
