import { Salon } from "@getvish/model";
import { createReducer, on, createFeatureSelector, createSelector } from "@ngrx/store";

import * as actions from "../actions/admin.actions";

export interface AdminState {
  salonFilter: string;
  salons: Salon[];
}

export const initialState: AdminState = {
  salonFilter: undefined,
  salons: [],
};

export const reducer = createReducer(
  initialState,
  on(actions.navigate, () => ({
    ...initialState,
  })),
  on(actions.searchSalons, (state, { filter }) => ({
    ...state,
    searchFilter: filter,
  })),
  on(actions.searchSalonsSuccess, (state, { salons }) => ({
    ...state,
    salons,
  })),
  on(actions.clearSalonFilter, (state) => ({
    ...state,
    filter: undefined,
    salons: [],
  }))
);

export const featureKey = "admin";

/** SELECTORS **/
export const getState = createFeatureSelector<AdminState>(featureKey);
export const getSalons = createSelector(getState, (state) => state.salons);
export const getSalonFilter = createSelector(getState, (state) => state.salonFilter);
