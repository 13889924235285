import { Action } from "@ngrx/store";
import { WebsocketConnectionStatus } from "@getvish/stockpile";

export enum Types {
  CONNECT = "[Websocket] Connect",
  CONNECTING = "[Websocket] Connecting",
  CONNECTED = "[Websocket] Connected",
  DISCONNECTING = "[Websocket] Disconnecting",
  DISCONNECTED = "[Websocket] Disconnected",
  FORCEFULLY_DISCONNECTED = "[Websocket] Forcefully Disconnected",
  RETRYING = "[Websocket] Retrying",
  SOCKET_OPENED = "[Websocket] Socket Opened",
  RAW_SOCKET_OPENED = "SocketOpened",
}

export class Connect implements Action {
  public readonly type = Types.CONNECT;
}

export class Connecting implements Action {
  public readonly type = Types.CONNECTING;
}

export class Connected implements Action {
  public readonly type = Types.CONNECTED;
}

export class Disconnecting implements Action {
  public readonly type = Types.DISCONNECTING;
}

export class Disconnected implements Action {
  public readonly type = Types.DISCONNECTED;
}

export class Retrying implements Action {
  public readonly type = Types.RETRYING;
}

export class ForcefullyDisconnected implements Action {
  public readonly type = Types.FORCEFULLY_DISCONNECTED;
}

export class SocketOpened implements Action {
  public readonly type = Types.SOCKET_OPENED;

  constructor(public sessionId: string) {}
}

export const fromStatus = (status: WebsocketConnectionStatus): Action => {
  switch (status) {
    case WebsocketConnectionStatus.CONNECTING: {
      return new Connecting();
    }
    case WebsocketConnectionStatus.CONNECTED: {
      return new Connected();
    }
    case WebsocketConnectionStatus.DISCONNECTING: {
      return new Disconnecting();
    }
    case WebsocketConnectionStatus.DISCONNECTED: {
      return new Disconnected();
    }
    case WebsocketConnectionStatus.RETRYING: {
      return new Retrying();
    }
  }
};

export type Actions = Connect | Connecting | Connected | Disconnecting | Disconnected | ForcefullyDisconnected | Retrying | SocketOpened;
