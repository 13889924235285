import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppointmentVM } from "../models/appointment";
import { ServiceDescription } from "@getvish/model";

@Component({
  selector: "resolve-appointments",
  templateUrl: "resolve-appointments.component.html",
  styleUrls: ["resolve-appointments.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResolveAppointmentsComponent implements OnInit {
  @Input() public loading: boolean;
  @Input() public appointments: AppointmentVM[];
  @Input() public serviceDescriptions: ServiceDescription[];
  @Input() public timeZone: string;
  @Output() public resolve: EventEmitter<AppointmentVM[]> = new EventEmitter<AppointmentVM[]>();

  public expandedAppointmentId!: string;

  ngOnInit(): void {
    if (this.appointments?.length === 1) {
      this.expandedAppointmentId = this.appointments[0]._id;
    }
  }

  public setExpandedAppointmentId(appointmentId: string) {
    if (this.expandedAppointmentId === appointmentId) {
      this.expandedAppointmentId = undefined;
    } else {
      this.expandedAppointmentId = appointmentId;
    }
  }
}
