import { AppState } from "./index";
import { createSelector } from "@ngrx/store";

import * as ui from "../actions/ui.actions";

export interface UiState {
  menuExpanded: boolean;
  panelOpen: boolean;
}

const initialState: UiState = {
  menuExpanded: true,
  panelOpen: false,
};

export function uiReducer(state: UiState = initialState, action: ui.Actions): UiState {
  switch (action.type) {
    case ui.Types.TOGGLE_MENU: {
      return {
        ...state,
        menuExpanded: !state.menuExpanded,
      };
    }

    case ui.Types.OPEN_PANEL: {
      return {
        ...state,
        panelOpen: true,
      };
    }

    case ui.Types.CLOSE_PANEL: {
      return {
        ...state,
        panelOpen: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const selectUiState = (state: AppState) => state.ui;
export const getMenuExpanded = createSelector(selectUiState, (state: UiState) => state.menuExpanded);
export const getPanelOpen = createSelector(selectUiState, (state: UiState) => state.panelOpen);
