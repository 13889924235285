import { NgModule } from "@angular/core";
import { NavbarComponent, SidebarComponent } from "./components";
import { SharedModule } from "../shared";
import { NotificationsModule } from "app/+notifications/notifications.module";

@NgModule({
  declarations: [NavbarComponent, SidebarComponent],
  imports: [NotificationsModule.forRoot(), SharedModule],
  exports: [NavbarComponent, SidebarComponent],
})
export class BaseComponentsModule {}
