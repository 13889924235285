import { createFeatureSelector, createSelector } from "@ngrx/store";
import { fromNullable } from "fp-ts/lib/Option";
import { equals } from "ramda";
import { ManufacturerState } from "./manufacturer.reducer";

/*** SELECTORS ***/
export const getManufacturerState = createFeatureSelector<ManufacturerState>("manufacturers");

export const getLoading = createSelector(getManufacturerState, (state) => state.loading);
export const getPaging = createSelector(getManufacturerState, (state) => fromNullable(state.paging));
export const getSort = createSelector(getManufacturerState, (state) => state.sort);
export const getManufacturers = createSelector(getManufacturerState, (state) => state.records);

export const getManufacturer = (id: string) =>
  createSelector(getManufacturers, (manufacturers) => manufacturers.find((manufacturer) => equals(manufacturer._id, id)));

export const getSearchFilter = createSelector(getManufacturerState, (state: ManufacturerState): string => state.searchFilter);
