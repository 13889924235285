import { Injectable } from "@angular/core";
import { Employee } from "@getvish/model";
import { HttpError, HttpRequestHandler, JsonObject, PagedResult } from "@getvish/stockpile";
import escapeStringRegexp from "escape-string-regexp";
import { either, option } from "fp-ts";
import { Either } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import { isNil } from "ramda";
import { Observable, of, throwError } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable()
export class GlobalEmployeeService {
  public controllerKey: string;

  constructor(private _requestHandler: HttpRequestHandler) {
    this.controllerKey = "global/employees";
  }

  public find(criteria: JsonObject = {}, sort = {}, page = 1, limit: number = 1000): Observable<PagedResult<Employee>> {
    const urlCriteria = JSON.stringify(criteria);
    const urlSort = JSON.stringify(sort);

    const url = `${this.controllerKey}?criteria=${urlCriteria}&sort=${urlSort}&page=${page}&limit=${limit}`;

    return this._requestHandler.get<Employee[]>(url).pipe(
      map(option.toUndefined),
      map((records) => new PagedResult(records, undefined))
    );
  }

  public search(filter: string, excludedSalonId: string): Observable<PagedResult<Employee>> {
    const emptyCriteria = {};

    const makeCriteria = (filter: string) => {
      const inner = filter.split(" ").map((term) => {
        return {
          $or: [
            { firstName: { $regex: `^${escapeStringRegexp(term)}`, $options: "i" } },
            { lastName: { $regex: `^${escapeStringRegexp(term)}`, $options: "i" } },
            { email: { $regex: `^${escapeStringRegexp(term)}`, $options: "i" } },
          ],
        };
      });

      return {
        $and: [...inner, { salonIds: { $ne: excludedSalonId } }],
      };
    };

    const criteria = pipe(
      filter,
      option.fromNullable,
      option.fold(() => emptyCriteria, makeCriteria)
    );

    return this.find(criteria);
  }

  public changePin(employeeId: string, pinCode: number): Observable<Either<HttpError, void>> {
    const payload = { employeeId, pinCode };

    return this._requestHandler.post(`${this.controllerKey}/${employeeId}/changePin`, payload).pipe(map(either.map(() => undefined)));
  }

  public checkUniquePinCode(employeeId: string, pinCode: number | undefined, salonId?: string): Observable<boolean> {
    // it's possible that the employee doesn't have a @pinCode
    // so in that case there's no possibility that there could be a conflict
    // and we can just return `true` immediately
    if (isNil(pinCode)) {
      return of(true);
    }

    const payload = {
      employeeId,
      pinCode,
      salonId,
    };

    return this._requestHandler.post(`${this.controllerKey}/uniquePincode`, payload).pipe(
      mergeMap(
        either.fold(
          (error) => (error.code === 409 ? of(false) : throwError(error)),
          () => of(true)
        )
      )
    );
  }

  public importEmployee(employeeId: string): Observable<Either<HttpError, void>> {
    const payload = { employeeId };
    const endpoint = `${this.controllerKey}/${employeeId}/tenant`;

    return this._requestHandler.post<void>(endpoint, payload);
  }
}
