<div class="container">
  <div class="content-wrapper">
    <login-form-component
      *ngIf="renderLoginForm$ | async"
      [loggingIn]="loggingIn$ | async"
      [error]="error$ | async"
      (login)="login($event)"
      (forgotPassword)="forgotPassword()">
    </login-form-component>
    <login-auth-token *ngIf="loggingInFromAuthToken$ | async"></login-auth-token>
    <div
      *ngIf="loggedIn$ | async"
      id="already-logged-in-container">
      <div id="success-icon-container">
        <fa-icon
          [icon]="['fas', 'check-circle']"
          size="6x"
          [classes]="['success']"></fa-icon>
      </div>
      <span>Looks like you've already logged in.</span>
      <div id="home-link-container">
        <a [routerLink]="['/home']"> Go To Home Page </a>
      </div>
    </div>
  </div>
</div>
