import { DiscontinuedProduct, Product } from "@getvish/model";
import * as product from "./product.actions";

import { JsonObject, PagingMetadata } from "@getvish/stockpile";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { append } from "ramda";

export interface ProductSelection extends Product {
  manufacturer: string;
  categories: string;
}
export interface ProductState {
  loading: boolean;
  saving: boolean;
  error: Error;
  records: Product[];
  discontinuedProducts: { [key: string]: DiscontinuedProduct };
  paging: PagingMetadata;
  selected: string;
  criteria: JsonObject;
  sort: JsonObject;
  selectedManufacturerId: string | undefined;
  searchFilter: string;
  newMetadata: {
    parentCategoryId?: string;
    manufacturerId?: string;
  };
  selectedRecords: ProductSelection[];
}

const initialState: ProductState = {
  loading: false,
  saving: false,
  error: undefined,
  records: [],
  discontinuedProducts: {},
  paging: undefined,
  selected: undefined,
  criteria: undefined,
  sort: undefined,
  selectedManufacturerId: undefined,
  searchFilter: null,
  newMetadata: undefined,
  selectedRecords: [],
};

export function productReducer(state: ProductState = initialState, action: product.Actions): ProductState {
  switch (action.type) {
    case product.Types.LOAD_ALL: {
      const loading = true;
      const { criteria, sort } = action.payload;
      const records = [];

      return {
        ...state,
        loading,
        criteria,
        sort,
        records,
      };
    }

    case product.Types.LOAD_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        records: action.products,
        discontinuedProducts: action.discontinuedProducts.reduce((acc, dp) => ({ ...acc, [dp.productId]: dp }), {}),
        paging: action.paging,
      };
    }

    case product.Types.NEW: {
      const parentCategoryId: string = pipe(
        option.fromNullable(action.payload),
        option.chain((payload) => option.fromNullable(payload.category)),
        option.map((category) => category._id),
        option.toUndefined
      );

      const manufacturerId = pipe(
        option.fromNullable(action.payload),
        option.chain((payload) => option.fromNullable(payload.manufacturerId)),
        option.toUndefined
      );

      const newMetadata = { manufacturerId, parentCategoryId };

      return {
        ...state,
        newMetadata,
      };
    }

    case product.Types.EDIT: {
      // newMetadata is used only for a new product, need to make sure that it`s cleaned up before editing a product
      // otherwise it can override the category on the product to edit
      return {
        ...state,
        newMetadata: undefined,
      };
    }

    case product.Types.SELECT: {
      const product = action.payload.product;
      const selectedRecords = state.selectedRecords;

      const updatedSelectedRecords = selectedRecords.some((record) => record._id === product._id)
        ? selectedRecords.filter((record) => record._id !== product._id)
        : [...selectedRecords, product];

      return {
        ...state,
        selectedRecords: updatedSelectedRecords,
      };
    }

    case product.Types.CLEAR_SELECTIONS: {
      const selectedRecords = [];

      return {
        ...state,
        selectedRecords,
      };
    }

    case product.Types.ADD: {
      const saving = true;

      return {
        ...state,
        saving,
      };
    }

    case product.Types.UPDATE: {
      return {
        ...state,
        saving: true,
      };
    }

    case product.Types.SET_FILTER: {
      const searchFilter = action.payload.filter;

      return {
        ...state,
        searchFilter,
      };
    }

    case product.Types.ADD_SUCCESS: {
      return {
        ...state,
        saving: false,
        records: append(action.payload, state.records),
      };
    }

    case product.Types.UPDATE_SUCCESS: {
      return {
        ...state,
        saving: false,
        records: state.records.map((p) => (p._id === action.payload._id ? action.payload : p)),
      };
    }

    case product.Types.UPDATE_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    case product.Types.REMOVE_SUCCESS: {
      const records = state.records;
      const updatedRecords = records.filter((record) => record._id !== action.payload);

      return {
        ...state,
        records: updatedRecords,
      };
    }

    case product.Types.SELECT_MANUFACTURER: {
      const selectedManufacturerId = action.payload.manufacturer._id;

      return {
        ...state,
        selectedManufacturerId,
      };
    }

    case product.Types.CLEAR_MANUFACTURER: {
      const selectedManufacturerId = undefined;
      const records = [];

      return {
        ...state,
        records,
        selectedManufacturerId,
      };
    }

    case product.Types.UPDATE_PRODUCTS: {
      return {
        ...state,
        saving: true,
      };
    }

    case product.Types.UPDATE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        saving: false,
        records: state.records.map((p) => ({
          ...p,
          order: action.payload.products.find((_p) => _p._id === p._id)?.order ?? p.order,
        })),
      };
    }

    case product.Types.UPDATE_PRODUCTS_FAIL: {
      return {
        ...state,
        saving: false,
      };
    }

    default: {
      return state;
    }
  }
}
