<dialog-contents
  dialogTitle="Select Products"
  id="dialog-container"
  (close)="dismiss()">
  <div class="dialog-content">
    <mat-stepper [selectedIndex]="importStep$ | async">
      <mat-step
        class="manufacturer-select-step"
        label="Select a manufacturer">
        <div
          *ngIf="loading$ | async"
          class="loading-container">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
        <div
          *ngIf="(loading$ | async) === false"
          class="manufacturers-container">
          <manufacturers-list
            class="manufacturers-container-panel small"
            [filteredManufacturers]="manufacturers$ | async"
            (searchedByName)="setSearchFilter($event)"
            (manufacturerSelected)="selectManufacturer($event)"
            [options]="{ showNumProducts: false, showPricingIncomplete: false }">
          </manufacturers-list>
        </div>
      </mat-step>
      <mat-step label="Select a product">
        <ng-container *ngIf="(loading$ | async) === false">
          <div
            *ngIf="salonProducts$ | async as salonProducts"
            class="select-container">
            <div class="product-search-container">
              <search-input
                class="product-name-search"
                placeholder="Search for a product or category"
                (filter)="setSearchFilter($event)"></search-input>
              <ng-container *ngIf="!disableSelectAll">
                <button
                  *ngIf="(allProductsSelected$ | async) === false"
                  class="button outline small"
                  (click)="selectProducts(salonProducts)">
                  Select All
                </button>
                <button
                  *ngIf="allProductsSelected$ | async"
                  class="button outline small"
                  (click)="deselectProducts(salonProducts)">
                  Deselect All
                </button>
              </ng-container>
            </div>
            <breadcrumbs-line
              class="breadcrumbs-line"
              [items]="breadcrumbsItems$ | async"
              (itemSelected)="popToCategory($event._id)">
              <span
                class="breadcrumb-item"
                (click)="clearSelectedCategories()"
                >{{ (selectedManufacturer$ | async)?.name }}</span
              >
            </breadcrumbs-line>
            <div class="products-details-section">
              <products-categories-list
                class="categories-filter"
                [productCategories]="productCategories$ | async"
                (categorySelected)="pushCategory($event)"></products-categories-list>
              <select-products-grid
                class="product-grid"
                [products]="salonProducts"
                [disableSelectAll]="disableSelectAll"
                [showInactive]="false"
                (toggleProduct)="toggleProduct($event)"
                (selectProducts)="selectProducts($event)"
                (deselectProducts)="deselectProducts($event)"></select-products-grid>
            </div>
            <div
              class="btn-container"
              *ngIf="selectedProducts$ | async as selectedProducts">
              <button
                (click)="previousStep()"
                class="button inverted small back-btn">
                Back
              </button>
              <button
                *ngLet="selectedManufacturer$ | async as selectedManufacturer"
                (click)="save(selectedProducts, selectedManufacturer)"
                class="button small"
                [disabled]="false">
                {{ saveButtonText }}
              </button>
            </div>
          </div>
        </ng-container>
      </mat-step>
    </mat-stepper>
  </div>
</dialog-contents>
