import { Component, Input, ChangeDetectionStrategy, OnChanges } from "@angular/core";
import { not, isNil } from "ramda";
import { getCurrencySymbol } from "@angular/common";

@Component({
  selector: "currency-suffix",
  templateUrl: "currency-suffix.component.html",
  styleUrls: ["currency-suffix.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencySuffixComponent implements OnChanges {
  @Input() public currency: string;

  public currencySymbol: string;

  ngOnChanges(): void {
    this.currencySymbol = not(isNil(this.currency)) ? getCurrencySymbol(this.currency, "narrow") : getCurrencySymbol("USD", "narrow");
  }
}
