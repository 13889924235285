import { Injectable } from "@angular/core";
import { SalonConfig } from "@getvish/model";
import { fold, fromNullable } from "fp-ts/lib/Option";
import { Observable, of, throwError } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SalonConfigService } from "app/+salon-config/services";
import { getUtcDateRangeDaysFromZonedTime } from "app/kernel/util/zoned-time-utils";
import { pipe } from "fp-ts/lib/function";
import { option } from "fp-ts";

@Injectable()
export class AnalyticsService {
  constructor(private _salonConfigService: SalonConfigService) {}

  public loadSalonConfigAndDateRange(
    startDate: number,
    endDate: number
  ): Observable<{ salonConfig: SalonConfig; startDate: number; endDate: number }> {
    return this._fetchSalonConfigAndTimeZone().pipe(
      map(({ salonConfig, timeZone }) => {
        const defaultDateRange = getUtcDateRangeDaysFromZonedTime(timeZone, 30);

        const startDateOrDefault = pipe(
          option.fromNullable(startDate),
          option.getOrElse(() => defaultDateRange.startDate)
        );

        const endDateOrDefault = pipe(
          option.fromNullable(endDate),
          option.getOrElse(() => defaultDateRange.endDate)
        );

        return {
          salonConfig,
          startDate: startDateOrDefault,
          endDate: endDateOrDefault,
        };
      })
    );
  }

  private _fetchSalonConfigAndTimeZone(): Observable<{ salonConfig: SalonConfig; timeZone: string }> {
    return this._salonConfigService.findOne({}).pipe(
      mergeMap(
        fold<SalonConfig, Observable<{ salonConfig: SalonConfig; timeZone: string }>>(
          () => throwError("No SalonConfig found"),
          (salonConfig) => {
            return fold<string, Observable<{ salonConfig: SalonConfig; timeZone: string }>>(
              () => throwError("No Time Zone configured"),
              (timeZone) => of({ timeZone, salonConfig })
            )(fromNullable(salonConfig.timeZone));
          }
        )
      )
    );
  }
}
